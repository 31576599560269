import * as Sentry from '@sentry/vue'
import { isBefore } from 'date-fns'
import { latLngBounds } from 'leaflet'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import mp from 'mixpanel-browser'
import { Auth, Config, Maps, Overview, Trends } from './api'
import {
  locations,
  mapData,
  cityMapData,
  operators,
  nationalStats,
  overviewStats,
  polygons,
  hero,
  chart,
  chartFrm
} from './arrayModules'
import competitive from './modules/competitive'
import metrics from './modules/metrics'
import {
  ciConfig,
  shapes,
  cityShapes,
  user,
  dashboardInfo
} from './treeModules'
import { DEFAULT_DASHBOARD, LATEST_STRING, OPERATORS_COUNTRY_OVERRIDES } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import { networksWithColors } from '@/utils/config'
import { get5stepsPercentiles } from '@/utils/data'
import { getRangeFromSelectedDate, getSafeDate } from '@/utils/date'
import { captureException } from '@/utils/error'

const types = {
  AUTH_LOAD_USER_ASYNC_FAILURE: 'auth/LOAD_USER_ASYNC_FAILURE',
  AUTH_LOAD_USER_ASYNC_PENDING: 'auth/LOAD_USER_ASYNC_PENDING',
  AUTH_LOAD_USER_ASYNC_SUCCESS: 'auth/LOAD_USER_ASYNC_SUCCESS',
  AUTH_LOGIN_ASYNC_FAILURE: 'auth/LOGIN_ASYNC_FAILURE',
  AUTH_LOGIN_ASYNC_PENDING: 'auth/LOGIN_ASYNC_PENDING',
  AUTH_LOGIN_ASYNC_SUCCESS: 'auth/LOGIN_ASYNC_SUCCESS'
}

const TOKEN = window.localStorage.getItem('os_token')
let config = new Config(TOKEN)
let trends = new Trends(TOKEN)
let overview = new Overview(TOKEN)
let maps = new Maps(TOKEN)
let auth

export default {
  // move to user store all together
  // actions file should only cover generic modules
  authenticate ({ commit }, { user, pass }) {
    auth = new Auth(user, pass)
    commit(types.AUTH_LOGIN_ASYNC_PENDING)
    return auth.getToken().then(res => {
      commit(types.AUTH_LOGIN_ASYNC_SUCCESS, res.token)
      config = new Config(res.token)
      trends = new Trends(res.token)
      overview = new Overview(res.token)
      maps = new Maps(res.token)
      window.localStorage.setItem('os_token', res.token)
    }).catch(e => {
      captureException(e)
      commit(types.AUTH_LOGIN_ASYNC_FAILURE, e)
    })
  },

  validateToken ({ state, dispatch }) {
    return config.validateToken()
      // return true for all basic succesfull validations
      .then(() => true)
      .catch(() => {
        // remove invalid token
        window.localStorage.removeItem('os_token')

        // attempt custom login methods when query params present
        // as token could be abailable but not yet retrieved
        if (router.currentRoute.value.query.username) {
          return dispatch('loginCustom', router.currentRoute.value.query)
        } else {
          // return to log in for any failure
          router.replace({ name: ROUTES.Login }).catch(() => {})
        }
      })
  },

  loginCustom ({ commit }, { username, token }) {
    auth = new Auth(username, '', token)
    commit(types.AUTH_LOGIN_ASYNC_PENDING)
    return auth.getCustomToken().then(res => {
      if (res.token) {
        commit(types.AUTH_LOGIN_ASYNC_SUCCESS, res.token)
        config = new Config(res.token)
        trends = new Trends(res.token)
        overview = new Overview(res.token)
        maps = new Maps(res.token)
        window.localStorage.setItem('os_token', res.token)
        router.go()
        return res.token
      } else {
        throw new Error()
      }
    }).catch(e => {
      e.name = `[ERROR] Custom Login Failure`
      e.message = `user ${username}`
      captureException(e)
      commit(types.AUTH_LOGIN_ASYNC_FAILURE, e)
      window.localStorage.removeItem('os_token')
      // TODO have a page that instructs contacting OS
      // and announces that this failure is being tracked
      // and shortly someone is looking into it
      router.replace({ name: ROUTES.Login }).catch(() => {})
    })
  },

  // mixpanel tracking action
  trackRoute ({ state, getters }, dashboard) {
    mp.track(dashboard, {
      // locationId is for maintaining history, TODO release removal on 01.01.2020
      locationId: router.currentRoute.value.params.location,
      ...router.currentRoute.value.params,
      url: router.currentRoute.value.path,
      route: router.currentRoute.value.name,
      organization: getters.user.organization,
      username: getters.user.username,
      locationName: getters['location/currentLocation'].name,
      locationCountry: getters['location/currentLocation'].iso3,
      metricSubtype: getters['metrics/primaryMetric'].subtype,
      metricType: getters['metrics/primaryMetric'].type
    })
  },

  // mixpanel tracking action
  track ({ getters }, { dashboard, params, url, route }) {
    const [ metricSubtype, metricType ] = params.metric || params.chart ? (params.metric || params.chart).split('_') : [null, null]
    // temporary needed as ci map page doesn't have location on route
    const location = params.location || getters.ciConfig.location
    const { name, iso3 } = getters.locations.find(l => location === l.key)

    mp.track(dashboard, {
      // locationId is for maintaining history, TODO release removal on 01.01.2020
      locationId: location,
      ...params,
      // temporary needed as ci map page doesn't have location on route
      location: location,
      url,
      route,
      organization: getters.user.organization,
      username: getters.user.username,
      locationName: name,
      locationCountry: iso3,
      metricSubtype,
      metricType
    })
  },

  // move to user store all together
  // actions file should only cover generic modules
  resetUser ({ commit }) {
    window.localStorage.removeItem('os_token')
    auth = new Auth()
    config = new Config()
    trends = new Trends()
    overview = new Overview()
    maps = new Maps()
    commit(user.types.SET, {})
    commit(chart.types.SET, { array: [] })
    commit(overviewStats.types.SET, { array: [] })
    commit(operators.types.SET, [])
  },

  setMGParams ({ commit }, params) {
    if (params.metric) {
      commit(metrics.types.SET_PRIMARY, params.metric)
    }
  },

  // used in CI
  setRouteParam ({ commit }, params) {
    Object.keys(params).map(key => {
      commit(ciConfig.types.SET_KEY, { key: key, data: params[key] })
    })

    const primary = params.chart || params.hero || params.metric
    if (primary) {
      commit(metrics.types.SET_PRIMARY, primary)
    }

    if (params.my) {
      commit(metrics.types.SET_SUPPORTING, params.my)
    }
  },

  // used in PI
  setHero ({ commit }, { metric, location, agg, dashboard }) {
    commit(hero.types.SET_CHART_PENDING)
    commit(locations.types.SET_CURRENT, location)

    if (!metric) {
      commit(hero.types.SET_CHART_FAILURE)
      return
    }

    return trends.getTrends({
      metric: metric.key || metric,
      location: location,
      agg: agg,
      days: 180,
      dashboard,
      comparison: true
    }).then(response => {
      commit(operators.types.SET, networksWithColors(Object.values(response.operators)))
      commit(hero.types.SET, {
        array: response.results,
        metric: response.metric
      })
      commit(metrics.types.SET_SUPPORTING, response.metric)
      commit(hero.types.SET_CHART_SUCCESS)
    }).catch((e) => {
      captureException(e)

      commit(hero.types.SET_CHART_FAILURE)
      commit(locations.types.SET_CURRENT, '')
    })
  },

  // used in PI
  setChart ({ commit, getters }, { metric, location, agg, dashboard, date }) {
    commit(chart.types.SET_CHART_PENDING)

    if (!metric) {
      commit(hero.types.SET_CHART_FAILURE)
      return
    }

    let days
    let endDate

    switch (metric.structure) {
      case 'binned':
      case 'binnedcdnres':
        days = 0
        endDate = date
        break
      default:
        days = 180
    }

    if (metric.subcategory === 'hourly' || metric.category === 'devices') {
      days = 0
      endDate = date
    }

    if (metric.subcategory === 'cpip' || metric.subcategory === 'ip') {
      days = 30
      const lastDateAvailable = getters.dashboardInfo.last_date_available
      const range = getRangeFromSelectedDate(date, 30, lastDateAvailable)
      endDate = range[1]
    }

    return trends.getTrends({
      metric: metric.key,
      location: location,
      agg: agg,
      days,
      dashboard,
      comparison: false,
      date: endDate
    }).then(response => {
      commit(chart.types.SET, {
        array: response.results,
        metric: response.metric
      })
      commit(metrics.types.SET_PRIMARY, response.metric)
      commit(chart.types.SET_CHART_SUCCESS)
    }).catch((e) => {
      captureException(e)

      commit(chart.types.SET_CHART_FAILURE)
    })
  },

  // used in PI
  setFrmchart ({ commit, getters }, { metric, location, agg, dashboard, date }) {
    commit(chartFrm.types.SET_CHART_PENDING)

    if (!metric || !metric.has_frm) {
      return
    }

    return trends.getTrends({
      metric: metric.key,
      location: location,
      agg: '7days',
      days: 28,
      dashboard,
      comparison: false,
      date
    }).then(response => {
      if (response) {
        commit(chartFrm.types.SET, {
          array: response.results,
          metric: response.metric
        })
        commit(chartFrm.types.SET_CHART_SUCCESS)
      }
    }).catch((e) => {
      captureException(e)
      commit(chartFrm.types.SET_CHART_FAILURE)
    })
  },

  // used in CI
  setCiTrend ({ commit, dispatch }, {
    metric,
    location,
    agg,
    country,
    date,
    geocoding,
    network,
    countryISO3
  }) {
    commit(chart.types.SET_CHART_PENDING)
    commit(hero.types.SET_CHART_PENDING)
    commit(locations.types.SET_CURRENT, location)
    commit(competitive.types.SET_WARNING, false)

    return Promise.all([
      trends.getTrends({
        metric: metric,
        location: location,
        agg: agg,
        days: 365,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false
      }),
      trends.getTrends({
        metric: metric,
        location: country,
        agg: agg,
        days: 365,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false
      })
    ]).then(response => {
      commit(chart.types.SET, {
        array: response[0].results,
        metric: response[0].metric
      })
      // filter national data to data available
      const minDate = get(response[0].results.find(a => Number.isFinite(a.mean)), 'date', date)

      let reversedResponse = [...response[0].results]
      reversedResponse.reverse()
      const maxDate = get(reversedResponse.find(a => Number.isFinite(a.mean)), 'date', date)

      commit(hero.types.SET, {
        array: response[1].results.filter(p => !isBefore(
          getSafeDate(p.date), getSafeDate(minDate))
        ),
        metric: response[1].metric
      })
      let newOperators
      if (metric.includes('coverage')) {
        newOperators = networksWithColors(Object.values(response[1].operators))
      } else {
        newOperators = networksWithColors(Object.values(response[0].operators))
      }
      dispatch('setCiOperators', { newOperators, country: countryISO3 })
      // We have to explicitly set chart operators to display the relevant operators in sidebar
      dispatch('setChartOperators', newOperators.map(operator => operator.canonical_network_id))
      commit(chart.types.SET_CHART_SUCCESS)
      commit(hero.types.SET_CHART_SUCCESS)

      if (!response[0].results.find(a => a.date === date && Number.isFinite(a.mean)) && (minDate !== date)) {
        commit(competitive.types.SET_WARNING, true)
        router.replace({
          name: ROUTES.CompetitiveDetails,
          params: {
            location,
            metric,
            agg,
            date: maxDate,
            countryid: country,
            country: countryISO3,
            geocoding,
            network
          }
        })

        return false
      }

      return true
    }).catch(e => {
      captureException(e)

      commit(chart.types.SET_CHART_FAILURE)
      commit(hero.types.SET_CHART_FAILURE)
      commit(locations.types.SET_CURRENT, '')
    })
  },

  // used in CI
  fetchOlderCiTrends ({ commit, state }, {
    metric,
    location,
    agg,
    country,
    days = 365,
    endDate
  }) {
    commit(chart.types.SET_CHART_PENDING)

    return Promise.all([
      trends.getTrends({
        metric: metric,
        location: location,
        agg: agg,
        days,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
        date: endDate
      }),
      trends.getTrends({
        metric: metric,
        location: country,
        agg: agg,
        days,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
        date: endDate
      })
    ]).then(response => {
      commit(chart.types.SET, {
        array: [
          ...response[0].results,
          ...state.chart.array
        ],
        metric: response[0].metric
      })

      commit(chart.types.SET_CHART_SUCCESS)
    }).catch(e => {
      captureException(e)
    })
  },

  // used in CI for the first page
  setOverview ({ commit, state, getters }, {
    location,
    date,
    metricString,
    singleMetric = false,
    dashboard = DEFAULT_DASHBOARD
  }) {
    if (singleMetric) {
      commit(overviewStats.types.SET_CHART_PENDING, metricString)
    } else {
      commit(overviewStats.types.SET_CHART_PENDING)
      commit(overviewStats.types.SET, { array: [] })
      commit(operators.types.SET, [])
      commit(locations.types.SET_CURRENT, location)
    }

    return overview.getDataByLocation(
      location,
      date,
      metricString,
      dashboard,
      true
    ).then(response => {
      if (singleMetric) {
        commit(overviewStats.types.SET_CHART_SUCCESS, metricString)
      } else {
        commit(overviewStats.types.SET_CHART_SUCCESS)
      }

      const responseOperators = Object.keys(response.operators).length > 0
        ? response.operators : response.operators
      commit(overviewStats.types.SET_OVERVIEW, [
        ...getters['overviewStats'],
        ...response.results
      ])
      if (!singleMetric) {
        commit(operators.types.SET, networksWithColors(Object.values(responseOperators)))
      }
      commit(metrics.types.SET_PRIMARY, state.ciConfig.data.metric)
      commit(metrics.types.SET_SUPPORTING, state.ciConfig.data.my)
    }).catch(e => {
      captureException(e)

      commit(locations.types.SET_CURRENT, '')
    })
  },

  // used in CI for National Stats widget
  setNational ({ commit, state, getters }, { location, date, metric, dashboard = DEFAULT_DASHBOARD, aggregation = '90days' }) {
    commit(nationalStats.types.SET, { array: [] })

    return overview.getDataByLocation(
      location,
      date,
      metric,
      dashboard,
      false,
      aggregation
    ).then(response => {
      commit(nationalStats.types.SET, { array: response.results })
    }).catch(e => {
      captureException(e)

      commit(locations.types.SET_CURRENT, '')
    })
  },

  setMGMap ({ commit, state, getters }, { country, geocoding, agg, brand, competitor }) {
    if (!brand || !competitor) {
      return
    }

    commit(shapes.types.SET, {})
    commit(mapData.types.SET_CHART_PENDING)

    return Promise.all([
      maps.getPolygons({
        country: country,
        polygon: geocoding,
        dashboard: 'market-growth'
      }),
      maps.getData({
        polygon: geocoding,
        brand,
        competitor,
        agg,
        dashboard: 'market-growth'
      }),
      maps.getData({
        polygon: geocoding,
        brand,
        competitor,
        agg,
        dashboard: 'market-growth',
        endDate: '2023-06-30', // TODO: Remove hardcoded data once backend MG PR is ready
        metricList: 'mgstarratings_all'
      })
    ]).then(responses => {
      const provinces = getters['marketGrowth/selectedProvinces']
      const selectedLocations = getters.locations.filter(l => provinces.includes(l.parent_id))
      const selectedStrategies = router.currentRoute.value.params.strategy.toLowerCase()

      let features = responses[0].features.map(f => ({
        ...f,
        item: responses[1].results.filter(x => x.location === f.id && selectedStrategies.includes(x.strategic_position[0]))
      })).filter(f => {
        return f.item.length &&
          selectedLocations.find(l => {
            return l.key === f.id + ''
          })
      })

      const bboxes = features.map(f => [
        [f.bbox[0], f.bbox[1]],
        [f.bbox[2], f.bbox[3]]
      ])
      const minBBoxCoordinates = latLngBounds(bboxes)

      if (!minBBoxCoordinates.getNorthEast()) {
        // Fix for now data, so map doesn't crash
        commit(mapData.types.SET_CHART_SUCCESS)

        return
      }

      const minBBoxArray = [
        minBBoxCoordinates.getNorthEast().lat,
        minBBoxCoordinates.getNorthEast().lng,
        minBBoxCoordinates.getSouthWest().lat,
        minBBoxCoordinates.getSouthWest().lng
      ]

      commit(shapes.types.SET_KEY, {
        key: 'geoJson',
        data: {
          ...responses[0],
          bbox: minBBoxArray,
          features
        }
      })

      commit(mapData.types.SET, {
        array: responses[1].results.sort((a, b) => b.mean - a.mean)
      })

      commit('marketGrowth/STAR_RATINGS_FETCHED', responses[2].results)

      commit(mapData.types.SET_CHART_SUCCESS)
    }).catch(e => {
      captureException(e)
      commit(mapData.types.SET_CHART_FAILURE)
    })
  },

  // used in CI for the map in Details page
  setMap ({ commit, state, getters, dispatch }, { metric, country, geocoding = 2, agg, date, dashboard = null, altVersion = false }) {
    commit(shapes.types.SET, {})
    commit(mapData.types.SET_CHART_PENDING)
    commit(cityMapData.types.SET_CHART_PENDING)

    const combinedView = getters['competitive/regionsAndCities'] && (parseInt(geocoding) === 2 || parseInt(geocoding) === 3)

    return Promise.all([
      maps.getPolygons({
        country: country,
        polygon: combinedView ? '2' : geocoding,
        dashboard: dashboard || DEFAULT_DASHBOARD
      }),
      maps.getData({
        country: country,
        polygon: combinedView ? '2' : geocoding,
        metric: metric,
        agg: agg,
        date: date || get(state, ['dashboardInfo', 'data', 'last_date_available']),
        dashboard: dashboard || DEFAULT_DASHBOARD,
        altVersion
      }),
      (combinedView ? maps.getPolygons({
        country: country,
        polygon: '3',
        dashboard: dashboard || DEFAULT_DASHBOARD
      }) : null),
      (combinedView ? maps.getData({
        country: country,
        polygon: '3',
        metric: metric,
        agg: agg,
        date: date || get(state, ['dashboardInfo', 'data', 'last_date_available']),
        dashboard: dashboard || DEFAULT_DASHBOARD,
        altVersion
      }) : null)
    ]).then(responses => {
      const dataForPercentiles = combinedView ? [...responses[1].results, ...responses[3].results] : responses[1].results

      commit(competitive.types.SET_METRIC_BUCKETS, get5stepsPercentiles(dataForPercentiles).reduce((ac, point, index, self) => {
        switch (index) {
          case 0:
            ac.push([Math.max(point - 1, 0), self[index + 1]])
            break
          case 4:
            ac.push([point, (self[index + 1] + 0.1).toFixed(2)])
            break
          case 5:
            break
          default:
            ac.push([point, self[index + 1]])
        }

        return ac
      }, []))

      const resultsObject = {}
      responses[1].results.forEach(result => {
        const { location } = result
        if (resultsObject[location]) {
          resultsObject[location].push(result)
        } else {
          resultsObject[location] = [result]
        }
      })

      let features = responses[0].features.map(f => {
        return {
          ...f,
          item: resultsObject[f.id] ? resultsObject[f.id].map(item => {
            return {
              value: item.mean || item.estimate,
              network: `${item.canonical_network_id}`,
              operatorColor: '#' + responses[1].operators[item.canonical_network_id].hex_color,
              color: '#' + responses[1].operators[item.canonical_network_id].hex_color,
              label: responses[1].operators[item.canonical_network_id].name_mapped,
              operatorInitial: responses[1].operators[item.canonical_network_id].name_mapped[0],
              letter: responses[1].operators[item.canonical_network_id].name_mapped[0],
              lci: item.lci,
              uci: item.uci,
              rank: item.rank,
              comparisonRank: item.comparison && item.comparison.rank
            }
          }) : []
        }
      })
      const data = {
        ...responses[0],
        features
      }
      commit(shapes.types.SET_KEY, {
        key: 'geoJson',
        data: Object.freeze(data)
      })

      const routeParams = router.currentRoute.value.params

      const mapActualData = responses[1].results.sort((a, b) => b.mean - a.mean)

      commit(mapData.types.SET, {
        array: mapActualData.map(Object.freeze)
      })

      if (combinedView) {
        let features2 = responses[2].features.map(f => ({
          ...f,
          item: responses[3].results.filter(x => x.location === f.id).map(item => ({
            value: item.mean || item.estimate,
            network: `${item.canonical_network_id}`,
            operatorColor: '#' + responses[3].operators[item.canonical_network_id].hex_color,
            color: '#' + responses[3].operators[item.canonical_network_id].hex_color,
            label: responses[3].operators[item.canonical_network_id].name_mapped,
            operatorInitial: responses[3].operators[item.canonical_network_id].name_mapped[0],
            letter: responses[3].operators[item.canonical_network_id].name_mapped[0],
            lci: item.lci,
            uci: item.uci,
            rank: item.rank,
            comparisonRank: item.comparison && item.comparison.rank
          }))
        }))

        commit(cityShapes.types.SET_KEY, {
          key: 'geoJson',
          data: {
            ...responses[2],
            features: features2
          }
        })

        const cityActualData = responses[3].results.sort((a, b) => b.mean - a.mean)
        commit(cityMapData.types.SET, {
          array: cityActualData.map(Object.freeze)
        })
      }

      if (!metric.includes('coverage')) {
        const resp = (routeParams.location !== routeParams.countryid && responses[3]) ? responses[3] : responses[1]
        const newOperators = networksWithColors(Object.values(resp.operators))
        dispatch('setCiOperators', { newOperators, country })
      }

      commit(mapData.types.SET_CHART_SUCCESS)
      commit(cityMapData.types.SET_CHART_SUCCESS)
    }).catch(e => {
      captureException(e)
      commit(mapData.types.SET_CHART_FAILURE)
      commit(cityMapData.types.SET_CHART_FAILURE)
    })
  },

  setCiOperators ({ commit, state, getters, rootGetters }, { newOperators, country }) {
    const operatorIds = getters.operators.map(operator => operator.canonical_network_id)
    // Don't override operators set by setCiTrend unless they're from a different country
    const currentCountry = OPERATORS_COUNTRY_OVERRIDES[country] || [country]
    commit(operators.types.SET, [
      ...getters.operators.filter(operator => currentCountry.includes(operator.country_iso3) && operator.canonical_network_id !== -1),
      ...newOperators.filter(operator => !operatorIds.includes(operator.canonical_network_id) || operator.canonical_network_id === -1)
    ])
  },

  // move to user store
  setUser ({ commit, dispatch }) {
    commit(types.AUTH_LOAD_USER_ASYNC_PENDING)
    return config.getUser().then(res => {
      commit(types.AUTH_LOAD_USER_ASYNC_SUCCESS, res)
      if (res) {
        mp.identify(res.id)
        mp.people.set(res)
        mp.identify(res.id)
        Sentry.setUser(res)
        dispatch('trackRoute', 'user')
        commit(user.types.SET, res)
        commit(metrics.types.SET_LICENCES, res.licences)
      }

      return res
    }).catch(e => {
      captureException(e)

      commit(types.AUTH_LOAD_USER_ASYNC_FAILURE, e)
    })
  },

  // move to user store
  // merge ciConfig to user
  setUserSettings ({ commit, dispatch }, mode) {
    return Promise.all([
      config.getMetrics(mode),
      config.getLocations(mode),
      config.getDashboardInfo(mode)
    ]).then(config => {
      commit(metrics.types.SET, config[0])
      commit(locations.types.SET, config[1])
      commit(dashboardInfo.types.SET, config[2])
      commit(polygons.types.SET, config[2].geoconfigs_available.map(g => {
        switch (g.client) {
          case 'opensignal':
            return {
              id: `${g.id}`,
              key: g.client + '_' + g.granularity,
              name: capitalize(g.granularity),
              prefer_points: g.prefer_points
            }
          case 'tmobileusa':
            return {
              id: `${g.id}`,
              key: g.client + '_' + g.granularity,
              name: `T-mobile ${capitalize(g.granularity)}`,
              prefer_points: g.prefer_points
            }
          case 'verizonusa':
            const granularity = g.granularity.split('-').map(s => capitalize(s)).join(' ')
            return {
              id: `${g.id}`,
              key: g.client + '_' + g.granularity,
              name: `Verizon ${capitalize(granularity)}`,
              prefer_points: g.prefer_points
            }
          default:
            return {
              id: `${g.id}`,
              key: g.client + '_' + g.granularity,
              name: `${capitalize(g.client)} ${capitalize(g.granularity)}`,
              prefer_points: g.prefer_points
            }
        }
      }))
      commit(ciConfig.types.SET_KEY, { key: 'country', data: config[1][0].iso3 })
      commit(ciConfig.types.SET_KEY, { key: 'location', data: config[1][0].key })
      commit(ciConfig.types.SET_KEY, { key: 'date', data: config[2].last_date_available })
      commit(ciConfig.types.SET_KEY, {
        key: 'geocoding',
        data: `${config[2].geoconfigs_available.find(g =>
          g.granularity !== 'countries'
        ).id}`
      })
      commit(
        competitive.types.SET_SHOW_REGIONS_CITIES,
        config[2].geoconfigs_available.find(g => g.id === 2) &&
        config[2].geoconfigs_available.find(g => g.id === 3)
      )
    }).catch(e => {
      captureException(e)
      dispatch('validateToken')
    })
  },

  // everywhere
  setAsCurrentLocation ({ commit }, location) {
    commit(locations.types.SET_CURRENT, location)
  },

  navigateToDashboard ({ commit, dispatch, getters }, { mode, category, isWeekly }) {
    commit(operators.types.SET, [])

    return dispatch('setUserSettings', mode).then(() => {
      const location = get(getters.dashboardInfo, ['countries_visible_full', '0', 'id'])

      if (!location) {
        router.replace({ name: ROUTES.Login }).catch(() => {})
        return
      }

      switch (mode) {
        case 'performance':
          router.push({
            name: ROUTES.PerformanceIntelligence,
            params: {
              category,
              isWeekly
            }
          })
          break
        case 'competitive':
          router.push({
            name: ROUTES.CompetitiveOverview,
            params: {
              date: LATEST_STRING,
              location: `${location}`,
              compareTo: '90days'
            }
          })
          break
      }
    }).catch(e => captureException(e))
  },

  setDisclaimerTimestamp () {
    window.localStorage.setItem('os_disclaimer', (new Date()).getTime())
  },

  checkDisclaimer ({ dispatch }) {
    const gracePeriod = 3 * 30 * 24 * 60 * 60 * 1000 // 3 months
    const lastTimestamp = window.localStorage.getItem('os_disclaimer')

    if (!lastTimestamp || (lastTimestamp && (new Date()).getTime() - lastTimestamp >= gracePeriod)) {
      document.getElementById('disclaimer').style.display = 'block'
    }
  },

  getUserGuide ({ dispatch }, url) {
    return config.getUserGuide(url)
  },

  setUserDefaultMetricType ({ dispatch }, type) {
    window.localStorage.setItem('os_default_metric_type', type)
  },
  getAllCpipMetrics ({ state }, { location, agg, dashboard, date }) {
    const params = router.currentRoute.value.params
    const technology = params.chart.split('_').pop()
    const commonParams = {
      location,
      agg,
      days: 0,
      dashboard,
      comparison: false,
      date
    }
    return Promise.all([
      trends.getTrends({
        metric: `cpipgamesrtt_${technology}`,
        ...commonParams
      }),
      trends.getTrends({
        metric: `cpipgamespacketloss_${technology}`,
        ...commonParams
      }),
      trends.getTrends({
        metric: `cpipgamesjitter_${technology}`,
        ...commonParams
      })
    ])
  },
  getMetricsData ({ state }, { metrics, location, agg, dashboard, date }) {
    const commonParams = {
      location,
      agg,
      days: 0,
      dashboard,
      comparison: false,
      date
    }
    return Promise.all(
      metrics.map(key =>
        trends.getTrends({
          metric: `${key}`,
          ...commonParams
        })
      )
    )
  }
}
