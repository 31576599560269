import arrayStore from './array'
import chartStore from './chart'

// stores: raw data for Competitive Overview pills with same date and location
// populated from: {dashboard}/overview/location/
const overviewStats = chartStore('overviewStats')
const nationalStats = chartStore('nationalStats')
// stores: CI - raw ordered data with same date, metric, country and geocoding
// populated from: {dashboard}/overview/geocoding_config/

// stores: AI - filtered data based on route with same metric and parent location plus route variations
// populated from: {dashboard}/polygons_by_parent/
const mapData = chartStore('mapData')

// stores: CI - raw ordered data with same date, metric, country and geocoding 3 when both geocoding 2 and 3 are available
// populated from: {dashboard}/overview/geocoding_config/
const cityMapData = chartStore('cityMapData')

// stores: CI - national trend for details comparison
// populated from {dashboard}/metrics/
// stores: PI - helper metric data for page top row data
// populated from {dashboard}/metrics/
const hero = chartStore('hero')

// stores: CI - raw trend data
// populated from {dashboard}/metrics/
// stores: PI - raw chart data
// populated from {dashboard}/metrics/
const chart = chartStore('chart')

// Faster response time metrics
const chartFrm = chartStore('chartFrm')

// stores the list of network operators
// populated from the relevant data request in each case
const operators = arrayStore('operators')

// stores all locations the org has access to
// populated from {dashboard}/locations/
// not available in AI
const locations = arrayStore('locations')

// stores all cities available in AI
// populated from {dashboard}/info/
const cities = arrayStore('cities')

// stores all geocoding configs available to org
// populated from {dashboard}/info/
const polygons = arrayStore('polygons')

export {
  hero,
  chart,
  chartFrm,
  nationalStats,
  overviewStats,
  operators,
  locations,
  cities,
  polygons,
  mapData,
  cityMapData
}
