<template>
  <div
    class="ChartWrapperExtended"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="!loading">
      <CompetitiveChartTitle
        v-if="titleLabels && !disableTitle"
        :title-labels="titleLabels"
        :tooltip="tooltip"
      />
      <div
        ref="chartwrapperExtended"
        class="ChartWrapperExtended__chart"
      >
        <NoData v-if="empty" />

        <slot v-else />

        <div
          v-show="displayTools"
          :class="{'ChartWrapperExtended__tools--bottom-right': smallLayout}"
          class="ChartWrapperExtended__tools"
        >
          <TimeframeSettings
            class="ChartWrapperExtended__tool"
            @show="popoverOpen = true"
            @hide="popoverOpen = false"
          />

          <div
            v-if="enableCSVExport"
            class="ChartWrapperExtended__tool"
          >
            <div v-if="enableCSVExport">
              <CsvExport @click="exportCSVData" />
            </div>
          </div>

          <div
            v-if="displayExport"
            class="ChartWrapperExtended__tool"
          >
            <tooltip-wrapper
              v-if="displayExport"
              title="Export chart as image"
            >
              <div class="ChartWrapperExtended__image">
                <image-export
                  :content="content"
                  :title="exportFileName"
                  :canvas-container-node="canvasContainer"
                  :product="pictureMeta.product"
                  :selected-point="selectedPoint"
                  :confidence-state="showConfidenceRate"
                  :legend-disabled="legendDisabled"
                />
              </div>
            </tooltip-wrapper>
          </div>
        </div>

        <div v-if="displayCompareSwitch">
          <SwitchInput
            v-model="showNational"
            label="vs National Trends"
            theme="dark"
            :disabled="isNationalLevel"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="ChartWrapperExtended__placeholder"
    >
      <loader-grid size="large" />
    </div>
  </div>
</template>

<script>
import { format, min, max, eachDayOfInterval } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import CompetitiveChartTitle from '@/components/competitive/CompetitiveChartTitle'
import CsvExport from '@/components/CsvExport'
import ImageExport from '@/components/ImageExport'
import LoaderGrid from '@/components/LoaderGrid'
import { SwitchInput } from '@/components/menu'
import NoData from '@/components/NoData'
import TimeframeSettings from '@/components/TimeframeSettings'
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper'
import { AGGREGATIONS, METRIC_TYPE_NAMES } from '@/constants/constants'
import { API_DEFAULT_DATE_FORMAT, PROGRAMMATIC_FORMAT } from '@/constants/dateFormats'
import router from '@/router'
import { getSafeDate } from '@/utils/date'
import { exportToCsv } from '@/utils/files'

export default {
  name: 'ChartWrapperExtended',
  components: {
    TooltipWrapper,
    LoaderGrid,
    ImageExport,
    CompetitiveChartTitle,
    SwitchInput,
    NoData,
    TimeframeSettings,
    CsvExport
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    unit: {
      type: String,
      default: undefined
    },
    pictureMeta: {
      type: Object,
      default: () => {}
    },
    titleLabels: {
      type: Object,
      default: () => {}
    },
    tooltip: {
      type: String,
      default: undefined
    },
    disableExport: {
      type: Boolean,
      default: false
    },
    legendDisabled: {
      type: Boolean,
      default: false
    },
    disableTitle: {
      type: Boolean,
      default: false
    },
    smallLayout: {
      type: Boolean,
      default: false
    },
    showConfidenceRate: {
      type: Boolean,
      default: false
    },
    selectedPoint: {
      type: Array,
      default: () => []
    },
    enableCompare: {
      type: Boolean,
      default: false
    },
    compare: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hover: false,
      popoverOpen: false,
      canvasContainer: null
    }
  },
  computed: {
    ...mapGetters({
      currentMetric: 'metrics/primaryMetric',
      networks: 'charts/networks'
    }),
    isMetricWeekly () {
      return this.currentMetric.category === 'coverage'
    },
    displayTools () {
      return this.hover || this.popoverOpen
    },
    displayExport () {
      return !this.disableExport && !this.empty
    },
    enableCSVExport () {
      return this.chartData && Object.values(this.chartData).length
    },
    displayCompareSwitch () {
      return this.enableCompare && !this.empty
    },
    showNational: {
      get () {
        return this.compare
      },
      set () {
        this.$emit('compare')
      }
    },
    chartTitle () {
      return this.titleLabels.chartTitle ? this.titleLabels.chartTitle.fullTitle : this.titleLabels.fullTitle
    },
    content () {
      if (!this.pictureMeta || !this.titleLabels) return []

      const unit = this.unit ? ` (${this.unit})` : ''
      const updated = this.titleLabels.lastUpdated === 0 ? 'yesterday' : `${this.titleLabels.lastUpdated} days ago`
      const optional = this.compare ? ' vs. National Trends as dotted lines' : ''
      return [
        `${this.pictureMeta.geography} | Competitive Trends`,
        `${this.subtitle}${unit}${optional}`,
        `${this.titleLabels.startDate} - ${this.titleLabels.endDate} | ${this.titleLabels.aggregation} | Data set updated ${updated}`
      ]
    },
    exportFileName () {
      if (!this.pictureMeta || !this.titleLabels) return 'export'

      return `${this.titleLabels.endDate}-${this.titleLabels.aggregation}-${this.subtitle}-${this.pictureMeta.geography}-Competitive Trends`
    },
    confidenceIntervals: {
      get () {
        return this.confidenceState
      },
      set (value) {
        this.$emit('compare')
      }
    },
    isNationalLevel () {
      return router.currentRoute.value.params.location === router.currentRoute.value.params.countryid
    }
  },
  mounted () {
    this.canvasContainer = this.$refs.chartwrapperExtended
  },
  updated () {
    this.canvasContainer = this.$refs.chartwrapperExtended
  },
  methods: {
    ...mapActions([
      'getMetricsData'
    ]),
    exportCSVData () {
      const networks = Object.values(this.chartData)
      const dates = []
      networks.forEach(network => dates.push(...network.data.map(i => i.x)))

      const dataByNetworkByDate = {}
      networks.forEach(network => {
        dataByNetworkByDate[network.label] = {}
        network.data.forEach(item => {
          dataByNetworkByDate[network.label][format(getSafeDate(item.x), PROGRAMMATIC_FORMAT)] = item
        })
      })

      let formattedData = []
      const safeDates = dates.map(date => getSafeDate(date))
      const minDate = min([...safeDates])
      const maxDate = max([...safeDates])
      const allDates = eachDayOfInterval({ start: minDate, end: maxDate })

      const parseValue = (value) => {
        if (value === 0) {
          return 0
        }
        return value || ''
      }
      allDates.forEach((unsafeDate, i) => {
        const date = format(getSafeDate(unsafeDate), PROGRAMMATIC_FORMAT)
        formattedData[i] = {
          Date: format(unsafeDate, API_DEFAULT_DATE_FORMAT)
        }

        formattedData[i][`Technology`] = METRIC_TYPE_NAMES[this.currentMetric.type] || this.currentMetric.type
        formattedData[i][`Aggregation`] = AGGREGATIONS.find(agg => agg.value === router.currentRoute.value.params.agg).label

        networks.forEach(network => {
          const itemData = dataByNetworkByDate[network.label][date]
          formattedData[i][`${network.label} mean`] = itemData ? parseValue(itemData.y) : ''
          formattedData[i][`${network.label} lci`] = itemData ? parseValue(itemData.lci) : ''
          formattedData[i][`${network.label} uci`] = itemData ? parseValue(itemData.uci) : ''
        })

        if (this.chartData.cdn) {
          formattedData[i][`CDN`] = this.chartData.cdn
        }
        if (this.chartData.operator) {
          formattedData[i][`Operator`] = this.chartData.operator
        }
        if (this.chartData.type && this.chartData.agg) {
          formattedData[i][`Technology`] = this.chartData.type
          formattedData[i][`Aggregation`] = this.chartData.agg
        }
        if (this.chartData.resolution) {
          formattedData[i][`Resolution`] = this.chartData.resolution
        }
      })

      if (this.isMetricWeekly) {
        formattedData = formattedData.filter(row => {
          let meanKeys = Object.keys(row).filter(key => key.includes(' mean'))
          let rowHasData = false

          meanKeys.map(key => {
            if (row[key]) {
              rowHasData = true
            }
          })

          return rowHasData
        })
      }

      this.exportFormattedData(formattedData)
    },
    exportFormattedData (formattedData) {
      if (!formattedData || formattedData.length === 0) {
        return
      }
      const titles = Object.keys(formattedData[0])
      const exportData = formattedData.map(data => titles.map(key => data[key]))
      const chartTitle = this.titleLabels.chartTitle || this.titleLabels
      const fullTitle = (
        this.titleLabels.name ||
        (this.titleLabels.chartTitle && this.titleLabels.chartTitle.fullTitle) ||
        this.titleLabels.fullTitle
      ).trim()
      const csvTitle = `${fullTitle} ${this.title} for ${chartTitle.geography} ${chartTitle.aggregation} ${this.titleLabels.endDate}.csv`
      exportToCsv(csvTitle, [titles, ...exportData])
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.ChartWrapperExtended {
  padding: 1.5em;
  position: relative;
  background-color: $color-white;
  margin-bottom: 0.6em;
  border-radius: $control-border-radius;

  // TODO delete after bar chart ci delete
  .Details__chartView {
    margin-top: 0;
    margin-bottom: 0;
  }

  .SwitchInput {
    margin: 0 0 -13px -13px;
  }

  &__title {
    padding: .5em;
    margin-right: 2.5em;
    font-size: $font-size-18;
    font-weight: $font-weight-roboto-bold;
    color: $color-brand-blue;
  }

  &__tools {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    text-align: center;
    z-index: $z-index-chart;
    @include xy-grid(horizontal, $wrap: false);
    display: flex;

    &--bottom-right {
      top: 0.3rem;
      right: 0.3rem;
    }
  }

  &__tool {
    @include xy-cell($size: shrink, $gutter-type: padding, $gutter-position: left, $gutters: 8px)
  }

  &__empty {
    padding: .5em;
    text-align: center;
  }

  &__icon {
    opacity: .1;
  }

  &__placeholder {
    width: auto;
    height: 100%;
    background-color: rgb(222, 229, 236);
    color: $color-white;
    font-size: 10em;
    padding: 92px;
    text-align: center;

    svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
