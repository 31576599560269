<template>
  <div>
    <canvas
      :id="chartId"
      width="400"
      height="400"
    />
    <ranking-table-tooltip
      :pos-class="tooltip.posClass"
      :tooltip-style="tooltip.tooltipStyle"
      :datapoints="tooltip.datapoints"
      :display="tooltip.display"
      :title="tooltip.title"
      :unit="tooltip.unit"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Chart from 'chart.js/auto'
import chroma from 'chroma-js'
import get from 'lodash.get'
import RankingTableTooltip from './RankingTableTooltip'
import {
  X_AXIS_BASE_STYLE,
  Y_AXIS_BASE_STYLE,
  SCALE_TITLE_STYLE
} from '@/constants/chartjs'
import {
  getDatapointsByIndex,
  getTooltipState
} from '@/utils/charts'
import colors from '@/utils/colorPalette'
import { getTextColor } from '@/utils/colors'

export default {
  name: 'MultiBarChart',
  components: {
    RankingTableTooltip
  },
  props: {
    chartId: {
      default: 'multi-bar-chart',
      type: String
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 75,
      type: Number
    },
    labels: {
      type: Object,
      default: () => {}
    },
    dataSet: {
      type: Array,
      default: () => []
    },
    xAxisLabel: {
      type: String,
      default: undefined
    },
    yAxisLabel: {
      type: String,
      default: undefined
    },
    rainbow: {
      type: Boolean,
      default: false
    },
    barLabels: {
      type: Boolean,
      default: false
    },
    showVerticalLabels: {
      type: Boolean,
      default: false
    },
    showConfidenceRate: {
      type: Boolean,
      default: false
    },
    showScoreRanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      tooltip: {
        datapoints: undefined,
        display: false,
        posClass: '',
        tooltipStyle: {},
        unit: ''
      }
    }
  },
  computed: {
    formattedLabels () {
      if (this.rainbow) {
        return this.dataSet.map(set => this.labels.cdns).flat()
      }

      return this.dataSet[0] && this.dataSet[0].data.map(e => `${e.cdn} ${e.res}`)
    },
    formattedDataSet () {
      if (this.rainbow) {
        return this.labels.res.map(res => ({
          label: res,
          borderWidth: 1,
          backgroundColor: this.dataSet.map(net => Array(this.labels.cdns.length).fill(net.color)).flat(),
          borderColor: this.dataSet.map(net => Array(this.labels.cdns.length).fill(net.color)).flat(),
          data: this.dataSet.map(set => set.data.map(
            point => ({
              ...point,
              x: point.cdn
            })
          )).flat().filter(datum => datum.res === res)
        }))
      }

      return this.dataSet.map(set => {
        return {
          label: set.label,
          borderWidth: 1,
          backgroundColor: set.color,
          borderColor: set.color,
          data: set.data.map(point => ({
            ...point,
            x: `${point.cdn} ${point.res}`
          }))
        }
      })
    }
  },
  watch: {
    dataSet () {
      this.getChart().data.datasets = this.formattedDataSet
      this.getChart().data.labels = this.formattedLabels
      this.getChart().update()
    },
    min () {
      this.getChart().options.scales.yAxes[0].ticks.min = this.min
      this.getChart().options.scales.yAxes[0].ticks.max = this.max
      this.getChart().update()
    },
    rainbow () {
      this.getChart().data.datasets = this.formattedDataSet
      this.getChart().data.labels = this.formattedLabels
      this.getChart().data.rainbowView = this.rainbow
      this.getChart().update()
    },
    barLabels () {
      this.getChart().data.showBarLabels = this.barLabels
      this.getChart().update()
    },
    showConfidenceRate () {
      this.getChart().data.displayConfidenceRate = this.showConfidenceRate
      this.getChart().update()
    }
  },
  mounted () {
    this.renderChart()
  },
  methods: {
    getChart () {
      return Chart.getChart(this.chartId)
    },
    renderChart () {
      const generalPlugin = {
        afterDatasetsDraw (chart, args, options) {
          const ctx = chart.ctx
          chart.data.datasets.map(function (dataset, i) {
            const meta = chart.getDatasetMeta(i)
            if (!meta.hidden) {
              meta.data.map(function (element, index) {
                ctx.fillStyle = colors.colorBlack
                ctx.strokeStyle = colors.colorBlack
                ctx.lineWidth = 1

                const barWidth = element.width
                const sizeLabel = dataset.label
                const labelFontSize = Math.min(14, barWidth * 0.8)

                const a = 58 / 167
                const b = 12 - 33 * a
                const valueFontSize = Math.min(barWidth * a + b, 18)
                const minMaxFontSize = (valueFontSize + 2) / 2
                const topLabelDistance = minMaxFontSize + 5

                const value = dataset.data[index].y
                const minValue = dataset.data[index].uci
                const maxValue = dataset.data[index].lci

                const top = dataset.data[index].uci - dataset.data[index].y
                const bot = dataset.data[index].y - dataset.data[index].lci
                const position = element.tooltipPosition()
                const vScale = chart.scales.y.maxHeight / chart.scales.y.max

                const ptop = position.y - top * vScale
                const pbot = position.y + bot * vScale

                const barBottom = chart.scales.y.bottom
                const textX = position.x + labelFontSize * 0.3
                const textY = barBottom - 10
                const textBarHeight = textY - ptop

                const valuePosX = position.x
                const valuePosY = ptop - minMaxFontSize - topLabelDistance

                const barColor = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[index] : dataset.backgroundColor
                const dpi = window.devicePixelRatio || 1

                const res = dataset.data[index].res
                let diff
                switch (res) {
                  case '4K':
                    diff = (97.5 - value) * vScale
                    break
                  case '1080p':
                    diff = (87.5 - value) * vScale
                    break
                  case '720p':
                    diff = (75 - value) * vScale
                    break
                  default:
                    diff = (45 - value) * vScale
                }

                if (chart.data.showScoreRanges) {
                  ctx.fillStyle = chroma(element.options.backgroundColor).alpha(0.2)
                  ctx.fillRect(
                    position.x - barWidth / 2, // x
                    position.y - diff, // y
                    barWidth, // width
                    diff // height
                  )
                }

                // drow threshhold
                if (chart.data.displayConfidenceRate) {
                  const confLineWidth = (barWidth > 10) ? 5 : (barWidth / 2)
                  ctx.beginPath()
                  ctx.moveTo(position.x - confLineWidth, ptop)
                  ctx.lineTo(position.x + confLineWidth, ptop)
                  ctx.moveTo(position.x, ptop)
                  ctx.lineTo(position.x, pbot)
                  ctx.moveTo(position.x - confLineWidth, pbot)
                  ctx.lineTo(position.x + confLineWidth, pbot)
                  ctx.stroke()
                  ctx.restore()
                }

                if (chart.data.showVerticalLabels && barWidth > 12) {
                  // verical labels in bars
                  ctx.fillStyle = getTextColor(barColor)

                  let text = ''

                  ctx.font = `${labelFontSize}px sans-serif`

                  if (textBarHeight > ctx.measureText(sizeLabel).width * dpi + 2 - (ptop - pbot) / 2) {
                    text = sizeLabel
                  } else if (textBarHeight > ctx.measureText(sizeLabel[0]).width * dpi + 2 - (ptop - pbot) / 2) {
                    text = sizeLabel[0]
                  }

                  ctx.textAlign = 'left'

                  ctx.save()
                  ctx.translate(textX, textY)
                  ctx.rotate(-90 * Math.PI / 180)
                  ctx.fillText(text, 0, 0)
                  ctx.restore()
                }

                if ((chart.data.showBarLabels && chart.data.displayConfidenceRate)) {
                  // values on top of bars
                  ctx.fillStyle = colors.colorBlack
                  ctx.font = `${valueFontSize}px sans-serif`
                  ctx.textAlign = 'center'
                  ctx.fillText(value, valuePosX, valuePosY)

                  // min and max values on top of bars
                  ctx.fillStyle = colors.colorNavItemtext
                  ctx.font = `${minMaxFontSize}px sans-serif`
                  ctx.textAlign = 'center'
                  ctx.fillText(`${minValue}/${maxValue}`, valuePosX, valuePosY + minMaxFontSize + 2)
                }
              })
            }
          })
        }
      }

      const chart = new Chart(this.chartId, {
        plugins: [generalPlugin],
        type: 'bar',
        data: {
          labels: this.formattedLabels,
          datasets: this.formattedDataSet,
          showBarLabels: this.barLabels,
          displayConfidenceRate: this.showConfidenceRate,
          showVerticalLabels: this.showVerticalLabels,
          showScoreRanges: this.showScoreRanges
        },
        options: {
          height: 400,
          animation: {
            duration: 0
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              display: this.showVerticalLabels,
              color: 'white',
              anchor: 'start',
              align: 'end',
              offset: 8,
              rotation: -90,
              labels: {
                title: {
                  font: {
                    size: 16
                  }
                }
                // value: {
                //   // color: 'white' // make dynamic
                // }
              },
              formatter: function (value, context) {
                return context.dataset.label
              }
            },
            tooltip: {
              enabled: false,
              mode: 'x',
              intersect: false,
              position: 'nearest',
              callbacks: {
                title: (item) => item
              },
              external: (context) => {
                const tooltip = context.tooltip
                const tooltipState = getTooltipState(this.getChart(), tooltip)
                const datapoints = getDatapointsByIndex(this.formattedDataSet, get(tooltip, ['title', 0, 'dataIndex']))
                if (datapoints && datapoints.length < 10) {
                  this.tooltip = {
                    ...this.tooltip,
                    ...tooltipState,
                    datapoints,
                    title: `${this.xAxisLabel}: ${get(tooltip, ['title', 0, 'label'])}`
                  }
                } else {
                  this.tooltip.display = false
                }
              }
            }
          },
          scales: {
            y: {
              ...Y_AXIS_BASE_STYLE,
              ticks: {
                ...Y_AXIS_BASE_STYLE.ticks,
                callback: (c, i) => {
                  return i > 0 ? c : ''
                },
                padding: 10
              },
              min: this.min,
              max: this.max,
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.yAxisLabel,
                text: this.yAxisLabel
              },
              grid: {
                offsetGridLines: false,
                drawTicks: false
              }
            },
            x: {
              ticks: {
                ...X_AXIS_BASE_STYLE.ticks,
                autoSkip: true
              },
              grid: {
                ...X_AXIS_BASE_STYLE.gridLines,
                display: false,
                drawTicks: false
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.xAxisLabel,
                text: this.xAxisLabel
              }
            }
          }
        }
      })
    }
  }
}
</script>
