<template>
  <div class="Details">
    <competitive-details-layout>
      <template #operators>
        <div
          class="Details__sidebar"
        >
          <div class="Details__sidebar-wrapper">
            <competitive-title class="Details__sidebar-title">
              <div :class="{ 'Details__sidebar-title-inner': display5GBadge }">
                <img
                  v-show="display5GBadge"
                  class="Details__sidebar-5gIcon"
                  :src="icons.FiveGIcon"
                >
                <span>
                  <span class="Details__inner-title">
                    <template v-if="compare">
                      Compare {{ currentMetric.name }}
                    </template>
                    <template v-else-if="currentOperator">
                      {{ currentOperator.name }}
                    </template>
                    <template v-else-if="currentMetric">
                      {{ currentMetric.name }}
                    </template>

                    <span class="Details__tooltip">
                      <template v-if="availabilityTooltip">
                        <img
                          class="Details__award"
                          src="../../assets/product/award_blue.svg"
                        >
                        <CustomTooltip
                          :message="availabilityTooltip"
                          placement="top"
                        >
                          <QuestionMark theme="ci" />
                        </CustomTooltip>
                      </template>
                      <template v-else-if="displayVideoTooltip">
                        <CustomTooltip
                          :message="videoTooltip"
                          placement="top"
                        >
                          <QuestionMark theme="ci" />
                        </CustomTooltip>
                      </template>
                    </span>
                    <!--  -->
                  </span>

                </span>
              </div>
            </competitive-title>
          </div>

          <NationalMetricResults />

          <MetricGeographies />
        </div>
      </template>

      <template #charts>
        <div>
          <ChartWrapper
            v-if="!metric.includes('coverage')"
            :loading="isChartLoading || !shapes.geoJson"
            :empty="isMapEmpty"
            class="Details__mapWrapper"
            disable-title
            disable-export
          >
            <div
              v-if="compare"
              class="Details__mapView"
              :class="detailsMapViewClass"
            >
              <!-- This is false so the map is empty while this is loading -->
              <RankingMap
                v-model="currentRegion"
                :geo-json="shapes.geoJson"
                :geo-json-overlay="zoomedLayer"
                :choropleth-data="winners"
                :networks="displayOps"
                :markers="displayMarkers"
                :zoomed="currentLocation.granularityId !== '1'"
                :locations-with-rank="locationsWithRank"
                :current-country="currentCountry"
                display-rank
              />
            </div>
            <div
              v-else
              class="Details__mapView"
              :class="detailsMapViewClass"
            >
              <ChoroplethMap
                v-model="currentRegion"
                :geo-json="shapes.geoJson"
                :geo-json-overlay="zoomedLayer"
                :choropleth-data="networkMapData"
                :choropleth-cities-data="networkCitiesMapData"
                :bigger-is-better="currentMetric.bigger_is_better"
                :colour-scale-labels="colourScaleLabels"
                :markers="displayMarkers"
                :zoomed="currentLocation.granularityId !== '1'"
                display-rank
              />
            </div>
            <div
              class="Details__mapViewCollapseExpand"
              @click="onCollapseExpand()"
            >
              <FontAwesomeIcon
                slot="icon"
                :icon="isExpanded ? icons.acorn : icons.corn"
                :style="{backgroundColor:'white'}"
                size="sm"
              />
            </div>
          </ChartWrapper>
          <br>
          <ChartWrapperExtended
            :loading="pending"
            :empty="isChartEmpty"
            :picture-meta="exportInfo"
            :selected-point="selectedPoint"
            :title="'Competitive Trends'"
            :title-labels="exportInfo"
            :subtitle="currentMetric.name"
            :show-confidence-rate="showConfidenceRate"
            :unit="unit"
            :compare="displayNational"
            :chart-data="trendSeries"
            enable-compare
            @compare="displayNational = !displayNational"
          >
            <line-chart
              :data-set="Object.values(trendSeries)"
              :compare-set="nationalValues"
              :date="date"
              :title="currentMetric && currentMetric.name"
              :aggregation="currentAgg && currentAgg.name && `${currentAgg.name} agg.`"
              :y-axis-unit="unit"
              :end-date="lastDateAvailable"
              :show-confidence-rate="showConfidenceRate"
              :height="350"
              :show-compare="showCompare"
              chart-id="trend"
              class="Details__chartView"
              show-rank-gaps
              @point="navigateToPoint"
            />
          </ChartWrapperExtended>
        </div>
      </template>
    </competitive-details-layout>
  </div>
</template>

<script>
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isBefore } from 'date-fns'
import get from 'lodash.get'
import { mapActions, mapGetters } from 'vuex'
import FiveGIcon from '@/assets/5g.svg'
import {
  NationalMetricResults,
  MetricGeographies
} from '@/components/comparison'
import CompetitiveDetailsLayout from '@/components/competitive/CompetitiveDetailsLayout'
import CompetitiveTitle from '@/components/competitive/CompetitiveTitle'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'
import {
  LineChart,
  ChartWrapper,
  ChartWrapperExtended,
  ChoroplethMap,
  RankingMap
} from '@/components/visual'
import { METRIC_TYPES } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'
import { getSafeDate } from '@/utils/date'

export default {
  name: 'CompetitiveDetails',
  components: {
    MetricGeographies,
    ChoroplethMap,
    RankingMap,
    ChartWrapper,
    ChartWrapperExtended,
    CompetitiveDetailsLayout,
    CompetitiveTitle,
    LineChart,
    NationalMetricResults,
    FontAwesomeIcon,
    CustomTooltip,
    QuestionMark
  },
  beforeRouteUpdate (to, from, next) {
    // give the $router a way to know about all params
    this.setRouteParam(to.params)

    if (router.currentRoute.value.params.countryid !== to.params.countryid ||
        router.currentRoute.value.params.metric !== to.params.metric ||
        router.currentRoute.value.params.date !== to.params.date ||
        router.currentRoute.value.params.agg !== to.params.agg
    ) {
      this.setNational({
        location: to.params.countryid,
        date: to.params.date,
        metric: to.params.metric,
        aggregation: to.params.agg
      })
    }

    // linked navigation to handle new regions and new metrics
    if (this.location !== to.params.location || this.metric !== to.params.metric) {
      this.mapLoading = (this.metric !== to.params.metric ||
        !(
          this.mapData.find(p => to.params.location === `${p.location}`) ||
          this.cityMapData.find(p => to.params.location === `${p.location}`) ||
          (this.country === to.params.country && to.params.countryid === to.params.location)
        )) &&
        !to.params.metric.includes('coverage')

      this.setCiTrend({
        metric: to.params.metric,
        location: to.params.location,
        country: to.params.countryid,
        countryISO3: to.params.country,
        agg: to.params.agg,
        date: to.params.date,
        geocoding: to.params.geocoding,
        network: to.params.network
      }).then(reply => {
        if (reply && this.mapLoading) {
          this.setMap({
            metric: to.params.metric,
            agg: to.params.agg,
            geocoding: to.params.geocoding,
            country: to.params.country,
            date: to.params.date,
            altVersion: true
          })
          this.mapLoading = false
        }
      })
    } else {
      if (to.params.date === this.date) {
        this.setCiTrend({
          metric: to.params.metric,
          location: to.params.location,
          country: to.params.countryid,
          countryISO3: to.params.country,
          agg: to.params.agg,
          date: to.params.date,
          geocoding: to.params.geocoding,
          network: to.params.network
        })
      }

      if (
        (this.country !== to.params.country ||
        this.metric !== to.params.metric ||
        this.network !== to.params.network ||
        this.agg !== to.params.agg ||
        (
          this.geocoding !== to.params.geocoding &&
          !this.mapIsUnchanged(this.geocoding, to.params.geocoding)
        ) ||
        this.date !== to.params.date) &&
        !to.params.metric.includes('coverage')
      ) {
        this.setMap({
          metric: to.params.metric,
          agg: to.params.agg,
          geocoding: to.params.geocoding,
          country: to.params.country,
          date: to.params.date,
          altVersion: true
        })
        this.mapLoading = false
      }
    }

    // Reset metric geographies focused view
    this.setFocusedView(true)

    next()
  },
  props: {
    location: {
      type: String,
      default: '212'
    },
    country: {
      type: String,
      default: 'USA'
    },
    countryid: {
      type: String,
      default: '212'
    },
    geocoding: {
      type: String,
      default: '1'
    },
    agg: {
      type: String,
      default: '90days'
    },
    metric: {
      type: String,
      default: 'availability_lte'
    },
    date: {
      type: String,
      default: ''
    },
    network: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      displayAll: false,
      displayNational: false,
      mapLoading: false,
      isExpanded: false,
      videoTooltip: TOOLTIP_MESSAGES.videoexperience,
      icons: {
        acorn: faCompressArrowsAlt,
        corn: faExpandArrowsAlt,
        FiveGIcon
      }
    }
  },
  computed: {
    ...mapGetters([
      'locations',
      'polygons',
      'operators',
      'metrics',
      'mapData',
      'cityMapData',
      'shapes',
      'cityShapes'
    ]),
    isChartEmpty () {
      return this.isEmpty || this.empty
    },
    ...mapGetters({
      actualTimeRange: 'competitive/actualTimeRange',
      displayOps: 'charts/selectedNetworkOperators',
      isEmpty: 'competitive/isEmpty',
      compare: 'competitive/compare',
      winners: 'competitive/winners',
      locationsWithRank: 'competitive/locationsWithRank',
      currentMetric: 'metrics/primaryMetric',
      lastDateAvailable: 'auth/getLastDateAvailable',
      empty: 'chart/chartEmpty',
      mapEmpty: 'mapData/chartEmpty',
      cityMapEmpty: 'cityMapData/chartEmpty',
      pending: 'chart/chartPending',
      mapPending: 'mapData/chartPending',
      currentLocation: 'location/currentLocation',
      currentCountry: 'location/currentCountry',
      getTitleLabels: 'charts/getTitleLabels',
      minDate: 'charts/organizationStartDate',
      currentAgg: 'competitive/agg',
      dateRange: 'competitive/dateRange',
      trendSeries: 'competitive/trendSeries',
      national: 'competitive/national',
      focusData: 'competitive/focusChart',
      showConfidenceRate: 'dashboard/getConfidenceState',
      selectedPoint: 'dashboard/selectedPoint',
      unit: 'metrics/primaryUnit',
      networkMapData: 'competitive/networkMapData',
      networkCitiesMapData: 'competitive/networkCitiesMapData',
      networkStats: 'competitive/networkStats',
      networkItems: 'competitive/networkItems',
      citiesAsPolygons: 'competitive/showPolygons',
      locationsInCountry: 'location/inCountry',
      showWarning: 'competitive/showWarning',
      showRegionsAndCities: 'competitive/regionsAndCities',
      groupings: 'location/byGroupings',
      timeRange: 'competitive/timeRange',
      userGroup: 'competitive/userGroup'
    }),
    displayVideoTooltip () {
      return this.currentMetric.kind === 'video'
    },
    availabilityTooltip () {
      switch (this.metric) {
        case 'availability5guser_5g':
          return 'This metric corresponds to the “5G Availability” award metric'
        case 'availability4guser_lte':
          return 'This metric corresponds to the “4G Availability” award metric'
        case 'availability_3glte5g':
          return 'This metric corresponds to the “Availability” award metric and represents the sum of 3G Availability, 4G Availability and 5G Availability for All Users.'
        default:
          return false
      }
    },
    isChartLoading () {
      const basicCondition = this.mapPending || this.mapLoading
      let extraCondition
      if (this.compare) {
        extraCondition = this.mapGeoJson && this.winners.length && this.operators.length
      } else {
        extraCondition = this.mapGeoJson && this.networkMapDataAvailable && this.locations.length
      }
      return basicCondition || !extraCondition
    },
    display5GBadge () {
      return this.currentMetric.type === METRIC_TYPES.FiveG
    },
    currentCountry () {
      return parseInt(this.countryid)
    },
    currentRegion: {
      get () {
        return parseInt(this.currentLocation.key)
      },
      set (region) {
        this.locationsInCountry.find(r => r.id === region)
        this.update({
          location: `${region}`,
          geocoding: get(this.locationsInCountry.find(r => r.key === `${region}`), 'granularityId', router.currentRoute.value.params.geocoding)
        })
      }
    },
    networkMapDataAvailable () {
      return this.networkMapData.length || this.networkCitiesMapData.length
    },
    useRegionsData () {
      return this.shapes.geoJson && this.shapes.geoJson.features.length > 0
    },
    isMapEmpty () {
      if (this.useRegionsData) {
        return this.mapEmpty
      }
      return this.cityMapEmpty
    },
    // Use city json data if there's no region data
    mapGeoJson () {
      if (this.useRegionsData) {
        return this.shapes.geoJson
      }
      return this.cityShapes.geoJson
    },
    zoomedLayer () {
      switch (this.currentLocation.granularityId) {
        case '1':
        case '2':
        case '3':
          if (this.geocoding === '2' || this.geocoding === '3') {
            return this.cityShapes.geoJson
          }
          return
        default:
          return this.shapes.geoJson
      }
    },
    displayMarkers () {
      return this.currentGeocoding.prefer_points &&
        !this.citiesAsPolygons &&
        !(this.showRegionsAndCities && parseInt(this.currentGeocoding.id) < 4)
    },
    displayAllEnabled () {
      return this.networkItems.length <= 10 || this.displayAll
    },
    currentGeocoding () {
      return this.polygons.find(l => l.id === this.geocoding)
    },
    currentOperator () {
      const network = this.operators.find(
        o => `${o.canonical_network_id}` === this.network
      )
      return network ? {
        key: `${network.canonical_network_id}`,
        name: network.name_mapped,
        color: network.hex_color
      } : {
        key: 'all',
        name: 'All'
      }
    },
    colourScaleLabels: function () {
      const labelUnit = this.unit === '0 - 100' ? '' : this.unit
      return [
        `${this.networkStats.maxValue}${labelUnit}`,
        `${this.networkStats.median}${labelUnit}`,
        `${this.networkStats.minValue}${labelUnit}`
      ]
    },
    exportInfo () {
      return {
        ...this.getTitleLabels(this.currentMetric, this.actualTimeRange.start, this.actualTimeRange.end),
        product: 'competitive',
        warning: this.showWarning,
        name: this.currentMetric.name
      }
    },
    detailsMapViewClass () {
      return {
        'Details__mapView--expanded': this.isExpanded
      }
    },
    nationalValues () {
      if (this.displayNational) {
        return Object.values(this.national)
      } else {
        return null
      }
    },
    isNationalLevel () {
      return router.currentRoute.value.params.location === router.currentRoute.value.params.countryid
    },
    showCompare () {
      return this.displayNational && !this.isNationalLevel
    }
  },
  watch: {
    mapEmpty () {
      // Redirect to national level view if it's the only option available (when switching countries)
      if (router.currentRoute.value.params.geocoding !== '1' && this.groupings.length === 0) {
        this.setMap({
          metric: router.currentRoute.value.params.metric,
          agg: router.currentRoute.value.params.agg,
          geocoding: '1',
          country: router.currentRoute.value.params.country,
          date: router.currentRoute.value.params.date,
          altVersion: true
        })
      } else {
        this.mapLoading = false
      }
    }
  },
  mounted () {
    this.setRouteParam(router.currentRoute.value.params)

    this.setNational({
      location: router.currentRoute.value.params.countryid,
      date: router.currentRoute.value.params.date,
      metric: router.currentRoute.value.params.metric,
      aggregation: router.currentRoute.value.params.agg
    })

    Promise.all([
      this.setCiTrend({
        metric: this.metric,
        location: this.location,
        country: this.countryid,
        agg: this.agg,
        date: this.date,
        geocoding: this.geocoding,
        network: this.network,
        countryISO3: this.country
      }),
      (!router.currentRoute.value.params.metric.includes('coverage') ? this.setMap({
        metric: this.metric,
        agg: this.agg,
        geocoding: this.geocoding,
        country: this.country,
        date: this.date,
        altVersion: true
      }) : null)
    ]).then(() => {
      this.trackRoute('competitive')
      this.setInitialTimeframe()
    })
  },
  methods: {
    ...mapActions([
      'setCiTrend',
      'setMap',
      'setRouteParam',
      'trackRoute',
      'setNational',
      'setFocusedView',
      'setTimeRange'
    ]),
    setInitialTimeframe () {
      const { start, end } = this.timeRange
      const isBeforeDate = isBefore(
        getSafeDate(router.currentRoute.value.params.date),
        getSafeDate(start)
      )
      if (isBeforeDate) {
        this.setTimeRange({
          start: new Date(router.currentRoute.value.params.date),
          end
        })
      }
    },

    update ({
      location = this.location,
      agg = this.agg,
      metric = this.metric,
      date = this.date,
      country = this.country,
      geocoding = this.geocoding,
      network = this.network
    }) {
      this.$router.push({
        name: ROUTES.CompetitiveDetails,
        params: {
          location,
          metric,
          agg,
          date,
          country,
          geocoding,
          network
        }
      })
    },
    navigateToPoint (date) {
      this.update({ date })
    },
    mapIsUnchanged (from, to) {
      return this.showRegionsAndCities && parseInt(from) < 4 && parseInt(to) < 4
    },
    onCollapseExpand () {
      this.isExpanded = !this.isExpanded
      this.$emit('collapseExpand')
      window.dispatchEvent(new Event('resize'))
    }
  }
}
</script>
<style lang="scss">
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

.Details__sidebar {
  @include xy-grid(vertical, $wrap: false);
  height: calc(100vh - 2rem);

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-5gIcon {
    margin-right: 10px;
    width: 47px;
    height: 44px;
  }

  &-title {
    padding-bottom: 0 !important;
  }

  &-title-inner {
    display: flex;
    align-items: center;
    margin-left: -15px;
  }
}

@media screen and (max-width: $ci-breakpoint) {
  .Details__sidebar {
    height: initial;
  }
}

.Details__mapWrapper {
  min-height: 368px;

  .ChartWrapper__placeholder {
    width: auto;
    padding: 92px;
  }
}

.Details__mapView {
  height: 400px;
  margin: -1rem;

  &--expanded {
    height: calc(100vh - 3rem);
  }

  &CollapseExpand {
    position: absolute;
    bottom: 97px;
    right: 18px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    text-align: center;
    line-height: 26px;
    background: $color-white;
    background-clip: padding-box;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65)
  }
}

.Details__chartView {
  margin: 0 -1rem;
}

.Details {
  &__award {
    height: 30px;
  }
  &__tooltip {
    display: inline-flex;
  }
}

</style>
