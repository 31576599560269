<template>
  <div class="FRMCard">
    <ChartWrapper
      :title="chartData.title"
      :tooltip="chartData.tooltip"
      :selected-point="cdnSelectedPoints[chartData.chartId]"
      :loading="chartPending"
      :empty="isEmpty"
      :title-labels="titleLabels"
      :unit="chartUnit"
      disable-export
      :chart-tooltip="chartTooltip"
      display-flat-tooltip
    >
      <line-chart
        v-if="chartData.loaded"
        :data-set="chartData.dataFrm"
        :chart-id="`${chartData.chartId}-frm`"
        :x-axis-label="chartData.xAxisLabel"
        :y-axis-label="chartData.yAxisLabel"
        :y-axis-unit="chartData.yAxisUnit"
        :end-date="dashboardInfo.last_date_available"
        :sync-range="syncRange"
        :height="260"
        disable-tooltip
        @updateTooltip="chartTooltip = $event"
      />
      <div class="FRMCard__label">
        Early Indication based on 7 day rolling average.
      </div>
    </ChartWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LineChart } from '@/components/visual'
import { getLinechartRange } from '@/utils/charts'

export default {
  name: 'FRMCard',
  components: {
    LineChart
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      chartTooltip: {}
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      focusChartData: 'charts/getCdnFocusChart',
      range: 'charts/cdnRange',
      titleLabels: 'charts/titleLabels',
      cdnSelectedPoints: 'dashboard/cdnSelectedPoints'
    }),
    syncRange () {
      return getLinechartRange(...this.range, 30)
    },
    isEmpty () {
      return this.focusChartData.empty || !this.chartData.dataFrm || this.chartData.dataFrm.length === 0
    }
  },
  beforeCreate: function () {
    this.$options.components.ChartWrapper = require('@/components/visual/index.js').ChartWrapper
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

::v-deep() {
  .ChartWrapper {
    background-color: #dfe5eb !important;
    height: auto !important;
  }
}

.FRMCard {
  width: 544px;
  border-radius: 12px;
  box-shadow: 0 2px 48px 0 rgba(223, 229, 235, 0.99);
  background-color: #dfe5eb;
  padding: 5px;
  text-align: left;

  &__label {
    font-size: 11px;
    letter-spacing: -0.3px;
    color: $color-blue-header;
    padding: 1em;
  }
}

</style>
