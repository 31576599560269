<template>
  <div v-if="ready">
    <div
      v-for="(titleLabels, x) in metricsEnhanced"
      :key="x"
      class="TypographyShowcase"
    >
      <performance-title
        :geography="titleLabels.geography"
        :metric-name="titleLabels.metricName"
        :metric-type="titleLabels.metricType"
        :metric-unit="titleLabels.metricUnit"
        :start-date="titleLabels.startDate"
        :end-date="titleLabels.endDate"
        :aggregation="titleLabels.aggregation"
        :last-updated="titleLabels.lastUpdated"
        :full-title="titleLabels.fullTitle"
        :by-cdn="titleLabels.byCdn"
      />
      <p>{{ titleLabels.chartTitle }}</p>
      <p>{{ titleLabels.chartTitleUnit }}</p>
      <p>{{ titleLabels.metric }}</p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PerformanceTitle from '@/components/performance/PerformanceTitle'
import {
  titleLabels,
  chartExtendedTitles,
  chartTitleWithUnits
} from '@/utils/titles'

export default {
  name: 'TypographyShowcase',
  components: {
    PerformanceTitle
  },
  props: {
    date: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    location: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  data: () => ({
    ready: false
  }),
  computed: {
    ...mapGetters({
      locations: 'locations',
      dashboardInfo: 'dashboardInfo',
      metrics: 'metrics'
    }),
    currentLocation () {
      return this.locations.find(l => l.key === this.location)
    },
    metricsEnhanced () {
      return this.metrics.map(m => ({
        ...titleLabels(
          this.currentLocation,
          m,
          this.date,
          this.agg,
          this.dashboardInfo
        ),
        chartTitle: chartExtendedTitles(this.currentLocation, m, 'Chart Variation Label'),
        chartTitleUnit: chartTitleWithUnits(m),
        metric: m.key
      }))
    }
  },
  mounted () {
    this.setUser()
      .then(() => this.setUserSettings(this.mode))
      .then(() => { this.ready = true })
  },
  methods: {
    ...mapActions([
      'setUser',
      'setUserSettings'
    ])
  }
}
</script>
<style lang="scss" scoped>
.TypographyShowcase {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;

  & > p {
    padding-left: 2rem;
  }
}
</style>
