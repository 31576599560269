<template>
  <div class="wrapper">
    <div class="wrapper__title">
      <h4>{{ title }}</h4>
    </div>
    <p class="wrapper__content">
      <slot />
    </p>
  </div>
</template>

<script>

export default {
  name: 'ListHeaderTooltipContent',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  &__title {
    background: lightblue;
    h4 {
      margin: 0;
      padding: 10px;
    }
  }
  &__content {
    padding: 10px;
    background: white;
    margin: 0;
  }
}
</style>
