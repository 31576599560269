<template>
  <div class="DynamicColourScale">
    <div
      v-if="hasSemanticMeaning"
      class="DynamicColourScale__label"
    >
      HIGHER
    </div>
    <div
      v-if="importance"
      class="DynamicColourScale__label"
    >
      MORE IMPORTANT
    </div>
    <div
      v-for="(range, index) in ranges"
      :key="index"
      class="DynamicColourScale__row"
    >
      <div
        v-if="range.type !== 'operator'"
        :style="{backgroundColor: range.color}"
        class="DynamicColourScale__color"
      >
        <svg
          v-if="range.type === 'nonoperator-svg'"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 18 18"
          width="18"
          height="18"
        >
          <g>
            <path
              :fill="'url(#' + range.id + ')'"
              d="M0 0L18 0L18 18L0 18L0 0Z"
            />
          </g>
        </svg>
      </div>
      <OperatorAvatar
        v-if="range.type === 'operator'"
        :letter="range.label[0]"
        :background-color="range.color"
      />
      <div
        v-if="range.label"
        class="DynamicColourScale__value"
      >
        <span>{{ range.label }}</span>
      </div>
      <div
        v-else
        class="DynamicColourScale__value"
      >
        <span v-if="range.max === undefined && range.min !== 0">&gt; </span>
        <span v-if="range.min === undefined">&lt; </span>
        <span v-if="range.min !== undefined">{{ round(range.min) }}</span>
        <span v-if="range.min !== undefined && range.max !== undefined && range.max >= 0"> - </span>
        <span v-if="range.min !== undefined && range.max !== undefined && range.max < 0"> to </span>
        <span v-if="range.max !== undefined">{{ round(range.max) }}</span>
      </div>
    </div>
    <div
      v-if="hasSemanticMeaning"
      class="DynamicColourScale__label"
    >
      LOWER
    </div>
    <div
      v-if="importance"
      class="DynamicColourScale__label"
    >
      LESS IMPORTANT
    </div>
    <div
      v-if="unit"
      class="DynamicColourScale__unit"
    >
      in {{ unit }}
    </div>
  </div>
</template>

<script>
import OperatorAvatar from '@/components/OperatorAvatar'

export default {
  name: 'DynamicColourScale',
  components: {
    OperatorAvatar
  },
  props: {
    ranges: {
      type: Array,
      default: () => []
    },
    unit: {
      type: String,
      default: ''
    },
    importance: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSemanticMeaning () {
      return this.ranges.find(p => p.negative)
    }
  },
  methods: {
    round (value) {
      return Math.round(value * 100) / 100
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';
  @import '@material/elevation';

  .DynamicColourScale {
    @include elevation($elevation-2);
    background-color: rgba(222, 229, 236, 0.8);
    border: 1px solid $color-white;
    padding: pxToRem(5);
    font-size: pxToRem(10);
    line-height: pxToRem(19);

    &__label {
      @include xy-grid();
      color: $color-text-default;
    }

    &__row {
      @include xy-grid();
    }
    &__color {
      $square-size: pxToRem(18);
      @include xy-cell(shrink, $gutters: 0);
      width: $square-size;
      height: $square-size;
    }
    &__value {
      @include xy-cell(auto, $gutter-position: left);
      text-align: right;
      flex: 1 0 auto;
    }
    &__unit {
      border-top: 1px solid $color-text-default;
      text-align: right;
      margin-top: 0.2em;
      padding-top: 0.2em;
      line-height: 1em;
    }
  }
</style>
