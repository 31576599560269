import get from 'lodash.get'
import Vue from 'vue'
import { FREQUENCY_RANGES } from '../../constants/constants'
import { checkIfMobile } from '../page'
import { V1_CDN_RES_LABELS, V2_CDN_RES_LABELS } from '@/constants/cdnRes'
import { cdnColorMap, frColorMap } from '@/constants/colorScales'
import { METRIC_TYPE_MAP, CPS, WEEKLY_METRICS_CATEGORIES, LATEST_STRING } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import { multiChartId } from '@/utils/charts'
import { sortByMetricMeanAndRank } from '@/utils/data'
import { getWeeklyDate } from '@/utils/date'

const types = {
  DATE: 'dashboard/date',
  GET_LATEST_DATE: 'dashboard/getLatestDate',
  IS_WEEKLY_METRIC: 'dashboard/isPrimaryMetricWeekly',
  ADJUSTABLE_SCALE: 'dashboard/getAdjustableScale',
  CONFIDENCE_STATE: 'dashboard/getConfidenceState',
  IS_MENU_VISIBLE: 'dashboard/isMenuVisible',
  SELECTED_POINT: 'dashboard/selectedPoint',
  BY_OP_SELECTED_POINTS: 'dashboard/opSelectedPoints',
  BY_CP_SELECTED_POINTS: 'dashboard/cpSelectedPoints',
  BY_CP_OPS_SELECTED_POINTS: 'dashboard/cpOpsSelectedPoints',
  BY_CDN_SELECTED_POINTS: 'dashboard/cdnSelectedPoints',
  BY_RANGES_SELECTED_POINTS: 'dashboard/rangesSelectedPoints',
  BY_CDNRES_SELECTED_POINTS: 'dashboard/cdnResSelectedPoints',
  BY_OP_CDNRES_SELECTED_POINTS: 'dashboard/cdnOpResSelectedPoints',
  SELECTED_POINT_PER_NETWORK: 'dashboard/selectedPointNetwork',
  GET_EXPORT_POINT: 'dashboard/getExportPoint',
  GET_BREAKDOWN_EXPORT_POINT: 'dashboard/getBreakdownExportPoint',
  SET_ADJUSTABLE_SCALE: 'dashboard/setAdjustableScale',
  SET_CONFIDENCE_STATE: 'dashboard/setConfidenceState',
  SET_MENU_STATE: 'dashboard/setMenuState',
  TOGGLE_ADJUSTABLE_SCALE: 'dashboard/toggleAdjustableScale',
  TOGGLE_CONFIDENCE_STATE: 'dashboard/toggleConfidenceState',
  TOGGLE_MENU: 'dashboard/toggleMenu'
}

const rootTypes = {
  CDN_RES_V2: `chart/isCdnResV2`
}

const state = () => ({
  adjustableScale: false,
  confidenceState: true,
  isMenuVisible: !checkIfMobile()
})

const getters = {
  [types.IS_WEEKLY_METRIC]: (state, getters, rootState, rootGetters) => {
    const chart = router.currentRoute.value.params.chart
    const hero = router.currentRoute.value.params.hero
    const heroCat = get(rootGetters.metrics.find(m => m.key === hero), 'category')
    const chartCat = get(rootGetters.metrics.find(m => m.key === chart), 'category')
    return WEEKLY_METRICS_CATEGORIES.includes(chartCat) ||
    WEEKLY_METRICS_CATEGORIES.includes(heroCat) ||
    router.currentRoute.value.params.isWeekly
  },
  [types.DATE]: (state, getters, rootState, rootGetters) => {
    const { dashboardInfo } = getters
    const lastDateAvailable = dashboardInfo.last_date_available
    const date = router.currentRoute.value.params.date
    if (!date || date === LATEST_STRING) {
      if (getters[types.IS_WEEKLY_METRIC]) {
        return getWeeklyDate(lastDateAvailable, 3, dashboardInfo.first_date_available)
      }
      return lastDateAvailable
    }
    return date
  },
  [types.GET_LATEST_DATE]: (state, getters, rootState, rootGetters) => (data) => {
    if (!data || !data[0]) {
      return null
    }

    let maxDate = data[0].date

    data.map(d => {
      maxDate = maxDate > d.date ? maxDate : d.date
    })

    return maxDate
  },
  [types.ADJUSTABLE_SCALE]: (state, getters, rootState, rootGetters) => state.adjustableScale,
  [types.CONFIDENCE_STATE]: (state, getters, rootState, rootGetters) => state.confidenceState,
  [types.BY_OP_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCdnOps &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cdnFocus'], true),
  [types.BY_OP_CDNRES_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCdnResOps &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cdnResFocus'], true),
  [types.BY_CP_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCp &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cpFocus'], false, 'metrics/cps'),
  [types.BY_CP_OPS_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCpOps &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cpFocus'], true, 'metrics/cps'),
  [types.BY_CDN_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCdn &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cdnFocus']),
  [types.BY_CDNRES_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceCdnRes &&
    getters[types.GET_BREAKDOWN_EXPORT_POINT](rootGetters['chart/cdnResFocus']),
  [types.BY_RANGES_SELECTED_POINTS]: (state, getters, { route }, rootGetters) =>
    getters[types.GET_FR_BREAKDOWN_EXPORT_POINT](rootGetters['chart/ranges']),
  [types.SELECTED_POINT]: (state, getters, { route }, rootGetters) => {
    if (!router.currentRoute.value.params.date) return []

    if (rootGetters['chart/trend'].length) {
      return getters[types.GET_EXPORT_POINT]({
        data: rootGetters['chart/trend'],
        metric: rootGetters['metrics/primaryMetric'],
        singleNetwork: Number.isFinite(parseInt(router.currentRoute.value.params.network)) && parseInt(router.currentRoute.value.params.network)
      })
    }

    if (rootGetters['hero/hero'].length) {
      return getters[types.GET_EXPORT_POINT]({
        data: rootGetters['hero/hero'],
        metric: rootGetters['metrics/supportingMetric'],
        noData: true
      })
    }
  },
  [types.SELECTED_POINT_PER_NETWORK]: (state, getters, { route }, rootGetters) =>
    router.currentRoute.value.name === ROUTES.PerformanceIntelligenceNByN &&
    getters[types.SELECTED_POINT] &&
    getters[types.SELECTED_POINT].reduce((ac, p) => ({
      ...ac,
      [multiChartId(p.canonical_network_id)]: p
    }), {}),
  [types.GET_EXPORT_POINT]: (state, getters, { route }, rootGetters) => ({ data, metric, noData, singleNetwork }) => {
    let filteredData = data.filter(d => {
      const hasData = d[METRIC_TYPE_MAP[metric.structure]]
      const isSameDate = d.date === getters[types.DATE]
      const isNetworkActive = singleNetwork
        ? d.canonical_network_id === singleNetwork
        : !rootGetters['charts/hiddenNetworksIds'].includes(d.canonical_network_id)
      return hasData && isSameDate && isNetworkActive
    })

    if (!filteredData.length) {
      let latestDate = getters[types.GET_LATEST_DATE](data)

      filteredData = data.filter(d => {
        const hasData = d[METRIC_TYPE_MAP[metric.structure]]
        const isSameDate = d.date === latestDate
        const isNetworkActive = singleNetwork
          ? d.canonical_network_id === singleNetwork
          : !rootGetters['charts/hiddenNetworksIds'].includes(d.canonical_network_id)
        return hasData && isSameDate && isNetworkActive
      })
    }

    const result = sortByMetricMeanAndRank(
      filteredData,
      metric.bigger_is_better,
      METRIC_TYPE_MAP[metric.structure]
    ).map(p => {
      const network = getters.operators.find(o => o.canonical_network_id === p.canonical_network_id)
      return network && {
        ...p,
        ...network,
        y: noData ? null : p[METRIC_TYPE_MAP[metric.structure]],
        uci: noData ? null : p.uci,
        lci: noData ? null : p.lci
      }
    })

    return result
  },
  [types.GET_BREAKDOWN_EXPORT_POINT]: (state, getters, { route }, rootGetters) => (data, byOp, providers) => sortByMetricMeanAndRank(data.filter(d =>
    !rootGetters['charts/hiddenNetworksIds'].includes(d.canonical_network_id)
  ).map(p => {
    const network = getters.operators.find(o => o.canonical_network_id === p.canonical_network_id) || {}
    const cdnLabels = getters[rootTypes.CDN_RES_V2] ? V2_CDN_RES_LABELS : V1_CDN_RES_LABELS
    return rootGetters[providers || 'metrics/cdns'].map(cdn => ({
      y: p.mean && p.mean[cdn],
      uci: p.uci && p.uci[cdn],
      lci: p.lci && p.lci[cdn],
      name_mapped: byOp ? cdnLabels[cdn] || CPS[cdn] : network.name_mapped,
      hex_color: byOp ? cdnColorMap[cdn] : `#${network.hex_color}`,
      id: multiChartId(byOp ? network.canonical_network_id : cdn),
      canonical_network_id: network.canonical_network_id
    }))
  }).flat(), rootGetters['metrics/primaryMetric'].bigger_is_better, 'y').reduce((ac, item) => {
    if (!ac[item.id]) ac[item.id] = []

    ac[item.id].push(item)

    return ac
  }, {}),
  [types.GET_FR_BREAKDOWN_EXPORT_POINT]: (state, getters, { route }, rootGetters) => (data, byOp, providers) => {
    let filteredByDate

    let filteredByOp = data.filter(d => {
      return !rootGetters['charts/hiddenNetworksIds'].includes(d.canonical_network_id)
    })

    filteredByDate = filteredByOp.filter(point => point.date === getters[types.DATE])

    if (!filteredByDate.length) {
      const maxDate = getters[types.GET_LATEST_DATE](filteredByOp)

      filteredByDate = filteredByOp.filter(point => point.date === maxDate)
    }

    return sortByMetricMeanAndRank(filteredByDate.map(p => {
      return {
        y: p.mean,
        uci: p.uci,
        lci: p.lci,
        name_mapped: FREQUENCY_RANGES[p.nr_frequency_range - 1],
        hex_color: frColorMap[FREQUENCY_RANGES[p.nr_frequency_range - 1]],
        id: multiChartId(p.canonical_network_id),
        canonical_network_id: p.canonical_network_id
      }
    }).flat(), rootGetters['metrics/primaryMetric'].bigger_is_better, 'y').reduce((ac, item) => {
      if (!ac[item.id]) ac[item.id] = []

      ac[item.id].push(item)

      return ac
    }, {})
  },
  [types.IS_MENU_VISIBLE]: (state, getters, rootState, rootGetters) => state.isMenuVisible
}

const actions = {
  [types.TOGGLE_ADJUSTABLE_SCALE]: ({ commit, state }) => {
    commit(types.SET_ADJUSTABLE_SCALE, !state.adjustableScale)
  },
  [types.TOGGLE_CONFIDENCE_STATE]: ({ commit, state }) => {
    commit(types.SET_CONFIDENCE_STATE, !state.confidenceState)
  },
  [types.TOGGLE_MENU]: ({ commit, state }, dispatchResizeEvent = true) => {
    commit(types.SET_MENU_STATE, !state.isMenuVisible)
    if (dispatchResizeEvent) {
      window.dispatchEvent(new Event('resize'))
    }
  }
}

const mutations = {
  [types.SET_ADJUSTABLE_SCALE]: (state, enabled) => {
    state.adjustableScale = enabled
  },
  [types.SET_CONFIDENCE_STATE]: (state, enabled) => {
    state.confidenceState = enabled
  },
  [types.SET_MENU_STATE]: (state, isVisible) => {
    state.isMenuVisible = isVisible
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
