<template>
  <div
    v-click-outside="onClickOutside"
    class="MarketGrowthMenu"
    :class="{ 'MarketGrowthMenu--collapsed': !isMenuVisible }"
  >
    <dashboard-switch
      :products="products"
      :current="options.dashboard"
      theme="mg"
      @navigate="navigateToDashboard"
      @signout="signOut"
    />

    <MenuToggle :trigger-resize="false" />

    <NavigationButton
      @click="toggleViews"
    >
      <template v-if="$route.name === ROUTES.MarketGrowthOverview">
        go to Details
      </template>
      <template v-else>
        go back to Overview
      </template>
    </NavigationButton>

    <div class="MarketGrowthMenu__content">
      <menu-title
        label="Parameters"
        theme="mg"
      />

      <FieldGroup
        label="Select Provinces"
        theme="mg"
      >
        <SelectCheckboxInput
          v-model="selectedProvinces"
          :options="provinceOptions"
          theme="mg"
          all-selected-text="All provinces"
          @update="(newValue) => selectedProvinces = newValue"
        />
      </FieldGroup>

      <FieldGroup
        v-if="$route.name === ROUTES.MarketGrowthOverview"
        label="Select Type"
        theme="mg"
      >
        <SelectCheckboxInput
          v-model="selectedGranularities"
          :options="availableGranularities"
          theme="mg"
          all-selected-text="All Types"
          @update="(newValue) => selectedGranularities = newValue"
        />
      </FieldGroup>

      <template v-if="$route.name === ROUTES.MarketGrowthDetails">
        <FieldGroup
          label="Select type"
          theme="mg"
        >
          <SelectInput
            :options="availableGranularities"
            :selected-value="selectedGranularity"
            theme="mg"
            @select="selectGranularity"
          />
        </FieldGroup>

        <FieldGroup
          label="Brand"
          theme="mg"
        >
          <BrandSelector
            v-model="selectedBrand"
            :networks="brands"
            theme="mg"
          />
        </FieldGroup>
        <FieldGroup
          label="Competitor"
          theme="mg"
        >
          <BrandSelector
            v-model="selectedCompetitor"
            :networks="competitors"
            theme="mg"
          />
        </FieldGroup>
        <FieldGroup
          label="Map Visualisation Metric"
          theme="mg"
        >
          <SelectInput
            :options="metrics"
            :selected-value="selectedMetric"
            theme="mg"
            @select="selectMetric"
          />
        </FieldGroup>
      </template>

      <FieldGroup
        label="Select Strategy"
        theme="mg"
        :class="{ 'margined': $route.name === ROUTES.MarketGrowthOverview }"
      >
        <SelectStrategy
          v-model="selectedStrategies"
        />
      </FieldGroup>

      <FieldGroup
        v-if="$route.name === ROUTES.MarketGrowthDetails"
        theme="mg"
      >
        <SwitchInput
          v-model="displayStores"
          label="Display Stores"
          theme="mg"
        />
      </FieldGroup>

      <FieldGroup
        v-if="$route.name === ROUTES.MarketGrowthDetails"
        theme="mg"
      >
        <SwitchInput
          v-model="displayStarRatings"
          label="Display Star Ratings"
          theme="mg"
        />
      </FieldGroup>

      <div v-show="displayExportButton">
        <menu-title
          label="Export"
          theme="mg"
        />
        <button
          class="export__button"
          :disabled="isLoading"
          @click="exportData"
        >
          <loader-grid v-if="isLoading" />
          <span v-else>Export as CSV</span>
        </button>
      </div>
    </div>
    <div class="MarketGrowthMenu__footer">
      <StrategyLegend />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardSwitch from './DashboardSwitch'
import LoaderGrid from '@/components/LoaderGrid'
import BrandSelector from '@/components/market-growth/BrandSelector'
import SelectStrategy from '@/components/market-growth/SelectStrategy'
import StrategyLegend from '@/components/market-growth/StrategyLegend'
import {
  FieldGroup,
  MenuTitle,
  MenuToggle,
  SelectInput,
  SwitchInput
} from '@/components/menu'
import NavigationButton from '@/components/menu/NavigationButton'
import SelectCheckboxInput from '@/components/SelectCheckboxInput'
import { MG_METRICS, MG_STRATEGIES } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import colors from '@/utils/colorPalette'
import { getDefaultGranularity } from '@/utils/market-growth'

export default {
  name: 'MarketGrowthMenu',
  components: {
    DashboardSwitch,
    FieldGroup,
    MenuTitle,
    BrandSelector,
    MenuToggle,
    SelectInput,
    StrategyLegend,
    SelectCheckboxInput,
    SelectStrategy,
    SwitchInput,
    NavigationButton,
    LoaderGrid
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    licences: {
      type: Array,
      default: () => []
    },
    dashboards: {
      type: Array,
      default: () => []
    },
    networks: {
      type: Array,
      default: () => []
    },
    confidence: {
      type: Boolean,
      default: true
    },
    dashboardInfo: {
      type: Object,
      default: () => {}
    },
    agg: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    },
    lteOnly: {
      type: Boolean,
      default: false
    },
    brand: {
      type: String,
      default: ''
    },
    competitor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      strategies: MG_STRATEGIES,
      metrics: Object.values(MG_METRICS),
      isLoading: false,
      ROUTES
    }
  },
  computed: {
    ...mapGetters({
      displayMgStores: 'marketGrowth/displayStores',
      displayMgStarRatings: 'marketGrowth/displayStarRatings',
      provinces: 'marketGrowth/provinces',
      isMenuVisible: 'dashboard/isMenuVisible',
      storeSelectedProvinces: 'marketGrowth/selectedProvinces',
      metric: 'metrics/primaryMetricKey',
      overviewFormattedSubmarkets: 'marketGrowth/overviewFormattedSubmarkets',
      detailsFormattedSubmarkets: 'marketGrowth/detailsFormattedSubmarkets',
      selectedOverviewGranularities: 'marketGrowth/overviewGranularities',
      availableGranularities: 'marketGrowth/selectableGranularities'
    }),
    ...mapGetters([
      'locations',
      'operators',
      'marketGrowthConfig'
    ]),
    displayExportButton () {
      // TODO: #1
      if (router.currentRoute.value.name === ROUTES.MarketGrowthOverview) {
        return this.overviewFormattedSubmarkets.length > 0
      }
      return this.detailsFormattedSubmarkets.length > 0
    },
    brands () {
      return this.dashboardInfo.brands
    },
    competitors () {
      if (!this.selectedBrand || !this.brands) {
        return []
      }
      return this.getBrand(this.selectedBrand).competitors
    },
    selectedProvinces: {
      get: function () {
        return this.storeSelectedProvinces
      },
      set: function (newValue) {
        this.selectMarketGrowthProvinces(newValue)
      }
    },
    selectedMetric: {
      get: function () {
        return this.metric
      },
      set: function (metric) {
        this.setMGParams({ metric })
      }
    },
    displayStores: {
      get () {
        return this.displayMgStores
      },
      set () {
        this.toggleMarketGrowthStores()
      }
    },
    displayStarRatings: {
      get () {
        return this.displayMgStarRatings
      },
      set () {
        this.toggleMarketGrowthStarRatings()
      }
    },
    provinceOptions () {
      return this.provinces.map(province => ({
        label: province.name,
        value: province.key
      }))
    },
    selectedBrand: {
      get () {
        if (!router.currentRoute.value.params.brand) {
          return null
        }
        return parseInt(router.currentRoute.value.params.brand)
      },
      set (brand) {
        // See if competitor exists in new brand
        const brandObject = this.getBrand(brand)
        const competitor = brandObject.competitors.find(competitor => competitor.canonical_network_id === this.selectedCompetitor)
        if (competitor) {
          this.update({ brand })
        } else {
          this.update({
            brand,
            competitor: brandObject.competitors[0].canonical_network_id
          })
        }
      }
    },

    selectedGranularities: {
      get: function () {
        return this.selectedOverviewGranularities
      },
      set: function (newValue) {
        this.selectOverviewGranularities(newValue)
      }
    },

    selectedGranularity () {
      if (!router.currentRoute.value.params.granularity) {
        return getDefaultGranularity(this.dashboardInfo)
      }
      return router.currentRoute.value.params.granularity
    },
    selectedCompetitor: {
      get () {
        if (!router.currentRoute.value.params.competitor) {
          return null
        }
        return parseInt(router.currentRoute.value.params.competitor)
      },
      set (competitor) {
        this.update({ competitor })
      }
    },
    selectedStrategies: {
      get: function () {
        if (!router.currentRoute.value.params.strategy) {
          return []
        }
        return router.currentRoute.value.params.strategy.split('')
      },
      set: function (strategies) {
        if (strategies.length === 0) {
          return
        }
        this.update({ strategy: strategies.join('') })
      }
    }
  },
  methods: {
    ...mapActions([
      'selectMarketGrowthProvinces',
      'selectOverviewGranularities',
      'setMGParams',
      'toggleMarketGrowthStores',
      'toggleMarketGrowthStarRatings',
      'exportMarketGrowthOverviewList',
      'exportMarketGrowthDetailsList'
    ]),
    ...mapActions({
      toggleMenu: 'dashboard/toggleMenu'
    }),
    selectGranularity (value) {
      this.update({ granularity: value })
    },
    exportData () {
      this.isLoading = true

      if (router.currentRoute.value.name === ROUTES.MarketGrowthOverview) {
        this.exportMarketGrowthOverviewList().then(() => {
          this.isLoading = false
        })
      } else {
        this.exportMarketGrowthDetailsList().then(() => {
          this.isLoading = false
        })
      }
    },
    onClickOutside (event) {
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      if (this.isMenuVisible && viewportWidth <= parseInt(colors.ciBreakpoint, 10)) {
        this.toggleMenu()
      }
    },
    toggleViews () {
      if (router.currentRoute.value.name === ROUTES.MarketGrowthOverview) {
        const defaultGranularity =
        this.selectedOverviewGranularities.length > 0
          ? this.selectedOverviewGranularities[0]
          : getDefaultGranularity(this.dashboardInfo)
        this.$router.push({
          name: ROUTES.MarketGrowthDetails,
          params: {
            country: router.currentRoute.value.params.country,
            granularity: `${defaultGranularity}`,
            strategy: router.currentRoute.value.params.strategy,
            metric: this.marketGrowthConfig.metric,
            brand: this.dashboardInfo.brands[0].canonical_network_id,
            competitor: this.dashboardInfo.brands[0].competitors[0].canonical_network_id
          }
        })
      } else {
        this.$router.push({
          name: ROUTES.MarketGrowthOverview,
          params: {
            country: router.currentRoute.value.params.country,
            strategy: router.currentRoute.value.params.strategy
          }
        })
      }
    },
    getBrand (networkId) {
      return this.brands.find(brand => brand.canonical_network_id === parseInt(networkId))
    },
    navigateToDashboard (mode) {
      this.update({ mode })
    },
    signOut () {
      this.$emit('signout')
    },
    selectMetric (metric) {
      this.selectedMetric = metric
      this.update({ metric })
    },
    update ({
      mode,
      metric = this.metric,
      brand = this.selectedBrand,
      competitor = this.selectedCompetitor,
      granularity = this.selectedGranularity,
      strategy = this.selectedStrategies.join('')
    }) {
      if (mode) {
        this.$router.push('/' + mode)
      } else {
        if (router.currentRoute.value.name === ROUTES.MarketGrowthOverview) {
          this.$emit('navigate', {
            country: router.currentRoute.value.params.country,
            strategy: strategy || router.currentRoute.value.params.strategy
          })
        } else {
          this.$emit('navigate', {
            country: router.currentRoute.value.params.country,
            granularity: granularity || router.currentRoute.value.params.granularity,
            metric: metric || router.currentRoute.value.params.metric,
            brand: brand || router.currentRoute.value.params.brand,
            competitor: competitor || router.currentRoute.value.params.competitor,
            strategy: strategy || router.currentRoute.value.params.strategy
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MarketGrowthMenu {
  background-color: $mg-base;
  height: auto;
  min-height: 100%;
  width: $sidebar-width;
  padding: $sidebar-padding;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
  @include xy-grid(vertical);

  &:hover {
    .MenuToggle {
      opacity: 1;
    }
  }

  &--collapsed {
    .DashboardSwitch,
    .MarketGrowthMenu__content {
      opacity: 0;
    }
  }

  &__content {
    flex: 1;
  }

    &__nav-group {
    width: 100%;
    background: $color-white;
    border-radius: 3px;
    margin-top: 7px;
  }

    &__nav-item {
    float: left;
    width: 33.33%;
    height: 50px;

    & a {
      color: $color-blue-primary;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 12px;
      text-align: center;
    }

    &--active {
      background: #A389D4;
      border-radius: 3px;

      & a {
        color: $color-white;
      }

    }
  }

  &__navItem--back {
    width: 100%;
    background: $winner-gold;
    text-align: center;
    border-radius: $border-radius-small;
    padding: 5px 0;
    cursor: pointer;
  }

  &__nav-icon {
    font-size: 18px;
    padding: 7px 0 0;
  }

  &__nav-group:after {
    display: block;
    content: '';
    clear: both;
  }

  .CompetitiveMenu__nav-group,
  .CompetitiveMenu__content {
    transition: $sidebar-transition;
  }

  .margined {
    margin-top: 30px;
  }

  .export__button {
      margin-bottom: 10px;
      height: 2rem;
      width: 100%;
      background: $active-mg;
      border: none;
      font-size: $font-size-12;
  }
}
</style>
