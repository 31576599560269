<template>
  <div
    v-click-outside="close"
    :class="componentClass"
    class="Pill"
  >
    <div
      class="Pill__header"
      @click.prevent="onHeaderClick"
    >
      <div class="Pill__title">
        {{ title }}
      </div>
      <div class="Pill__header-icon">
        <FontAwesomeIcon
          :icon="icons.faChartBar"
          size="lg"
        />
      </div>
    </div>
    <div class="Pill__main">
      <div
        :class="dropdownClass"
        class="Pill__dropdown"
      >
        <div
          v-for="option in options"
          :key="option.key"
          class="Pill__dropdown-option"
          @click="() => onOptionClick(option)"
        >
          {{ option.name }}
        </div>
      </div>
      <div class="Pill__main-icon">
        <img :src="mainIconSrc">
      </div>
      <div class="Pill__main-value">
        {{ value | numberToTwoDecimals }}<span class="Pill__unit">{{ value ? unit : '' }}</span>
      </div>
      <div class="Pill__rank">
        <span class="Pill__rank-icon">
          <FontAwesomeIcon
            v-if="rankComparisonIcon"
            :icon="icons[rankComparisonIcon]"
            size="sm"
          />
          <span v-else>&nbsp;</span>
        </span>
        <span class="Pill__rank-value">{{ rank || '-' }}</span>
      </div>
    </div>
    <div class="Pill__footer">
      <div class="Pill__detail">
        <div class="Pill__detail-title">
          Previous
        </div>
        <div class="Pill__detail-value">
          {{ previousValue | numberToTwoDecimals }}
        </div>
      </div>
      <div class="Pill__detail Pill__detail--center">
        <div class="Pill__detail-title">
          Difference
        </div>
        <div
          :class="detailValueClass"
          class="Pill__detail-value"
        >
          <span class="Pill__caret-icon">
            <FontAwesomeIcon
              v-if="valueComparisonPositive || valueComparisonNegative"
              :icon="valueComparisonPositive ? icons.faCaretUp : icons.faCaretDown"
              size="sm"
            />
          </span>
          <span>
            {{ valueComparisonPositive ? '+' : '' }}{{ valueComparison | numberToTwoDecimals }}
          </span>
        </div>
      </div>
      <div class="Pill__detail Pill__detail--right">
        <div class="Pill__detail-title">
          Confidence
        </div>
        <div class="Pill__detail-value Pill__detail-value--small">
          <span v-if="lci && uci">{{ lci | numberToTwoDecimals }}/{{ uci | numberToTwoDecimals }}</span>
          <span v-else>-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  faCaretDown,
  faCaretUp,
  faChartBar,
  faEquals
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  isRankComparisonNegative,
  isRankComparisonPositive,
  isValueComparisonNegative,
  isValueComparisonPositive
} from '@/utils/data'
import { numberToTwoDecimals } from '@/utils/filters'

export default {
  name: 'Pill',
  components: { FontAwesomeIcon },
  filters: {
    numberToTwoDecimals
  },
  props: {
    lci: { type: Number, default: undefined },
    metricKey: { type: String, default: undefined },
    options: { type: Array, default: () => [] },
    previousValue: { type: Number, default: undefined },
    rank: { type: String, default: undefined },
    rankComparison: { type: Number, default: undefined },
    selectOption: { type: Function, required: true },
    theme: { type: String, default: undefined },
    title: { type: String, default: undefined },
    uci: { type: Number, default: undefined },
    unit: { type: String, default: undefined },
    value: { type: Number, default: undefined },
    valueComparison: { type: Number, default: undefined }
  },
  data () {
    return {
      open: false,
      icons: {
        faChartBar,
        faCaretDown,
        faCaretUp,
        faEquals
      }
    }
  },
  computed: {
    componentClass () {
      return {
        [`Pill--${this.theme}`]: true
      }
    },
    dropdownClass () {
      return {
        'Pill__dropdown--open': this.open
      }
    },
    mainIconSrc () {
      return require(`../assets/icons/${this.metricKey}.svg`)
    },
    valueComparisonPositive () {
      return isValueComparisonPositive(this.valueComparison)
    },
    valueComparisonNegative () {
      return isValueComparisonNegative(this.valueComparison)
    },
    detailValueClass () {
      return {
        'Pill__detail-value--positive': isValueComparisonPositive(this.valueComparison),
        'Pill__detail-value--negative': isValueComparisonNegative(this.valueComparison)
      }
    },
    rankComparisonIcon () {
      const { rankComparison } = this
      if (isRankComparisonPositive(rankComparison)) {
        return 'faCaretUp'
      } else if (isRankComparisonNegative(rankComparison)) {
        return 'faCaretDown'
      }

      return ''
    }
  },
  methods: {
    onHeaderClick () {
      this.open = !this.open
    },
    onOptionClick (option) {
      this.open = false
      this.selectOption(option)
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .Pill {
    @include xy-cell(1 of 3, $gutters: .5em);

    $padding: .25em;

    @include card-container($border: none);
    flex-grow: 0;

    border-radius: $border-radius-medium $border-radius-medium;
    position: relative;
    overflow: visible;

    &__header {
      @include xy-grid($wrap: false);
      align-content: center;
      align-items: center;
      background-color: dimgray;
      background-color: rgba($color-black, 0.15);
      border-radius: $border-radius-medium $border-radius-medium 0 0;
      color: $color-white;
      cursor: pointer;
      height: $headers-height;

      &:hover {
        background-color: rgba($color-black, 0.5);
      }
    }

    &__title {
      @include xy-cell(auto);
      font-size: $font-size-12;
      font-weight: $font-weight-roboto-bold;
    }

    &__header-icon {
      @include xy-cell(shrink);
    }

    &__main {
      @include xy-grid($wrap: false);
      align-content: center;
      align-items: center;
      color: $color-white;
      padding: $padding*2 0;
      height: calc(#{$headers-height} * 1.5);
    }

    &__main-icon {
      @include xy-cell(shrink);
      padding: 0 $padding;
      padding-bottom: $padding*3;
      padding-top: $padding*3;
      width: 30px;
      text-align: center;
    }

    &__main-value {
      @include xy-cell(auto, $gutters: 0);
      font-size: $font-size-36;
      font-weight: $font-weight-roboto-light;
    }

    &__unit {
      margin-left: .25em;
      font-size: $font-size-12;
      font-weight: $font-weight-roboto-light;
    }

    &__rank {
      @include xy-cell(shrink);
      align-self: end;
      font-size: $font-size-18;
      font-weight: $font-weight-roboto-light;
      margin-left: 0;
      position: absolute;
      right: 0;
      text-align: center;
    }

    &__rank-icon {
      font-size: $font-size-14;
      padding-right: 3px;
    }

    &__footer {
      @include xy-grid($wrap: false);
      height: $headers-height;
      background-color: $color-white;
      padding: $padding;
      padding-top: $padding * 2;
      border-radius: 0 0 $border-radius-medium $border-radius-medium;
    }

    &__detail {
      @include xy-cell(1 of 3, $gutter-type: padding);
      @include xy-grid(vertical);

      &--center {
        @include xy-cell(1 of 3, $gutter-type: padding, $gutters: 0);
      }
    }

    &__detail-title {
      @include xy-cell(auto);
      font-size: $font-size-10;
      font-weight: $font-weight-roboto-regular;
    }

    &__detail-value {
      @include xy-cell(auto);
      font-weight: $font-weight-roboto-light;
      font-size: $font-size-14;
      line-height: $font-size-14/2;
      white-space: nowrap;

      &--small {
        font-size: $font-size-10;
        line-height: $font-size-12;
      }

      &--positive {
        color: $color-positive-green;
      }

      &--negative {
        color: $color-negative-red;
      }
    }

    &__caret-icon {
      font-size: $icon-font-size-medium;
    }

    &__dropdown {
      @include dropdown-container();
      width: 100%;
      border-radius: $border-radius-medium;
      padding: 0;
      top: $control-height;
      margin-top: $dropdown-padding-top;
      overflow: hidden;
      background-color: rgba($color-white, $dropdown-background-opacity);

      &--open {
        display: block;
        visibility: visible;
        @include elevation($elevation-4);
      }
    }

    &__dropdown-option  {
      font-size: $font-size-11;
      line-height: $control-height;
      padding: 0 .5em;
      border-bottom: 1px solid $color-gray-light;
      color: $color-text-black;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: $control-dark-bg-color--hover;
        color: $control-dark-text-color--hover;
      }
    }

    /* themes */

    @mixin Pill-theme($theme-name, $color) {
      &--#{"" + $theme-name} {
        background-image: $color;
      }
    }

    @include Pill-theme(blue, linear-gradient(-225deg, #3BB1FF 0%, #0F99E0 100%));
    @include Pill-theme(purple, linear-gradient(-225deg, #A389D4 0%, #899ED4 100%));
    @include Pill-theme(green,  linear-gradient(-225deg, #41BA9C 0%, #1DC4E9 100%));
    @include Pill-theme(orange, linear-gradient(-132deg, #E7625F 0%, #FF7D58 100%));
    @include Pill-theme(teal, linear-gradient(-225deg, #90D1D3 0%, #7B9EC0 100%));
    @include Pill-theme(lime, linear-gradient(-225deg, #8DC044 0%, #B9D791 100%));
  }
</style>
