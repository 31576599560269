<template>
  <div class="image-export">
    <a
      ref="download"
      :download="`${title}.png`"

      @click="download"
    >
      <FontAwesomeIcon
        :icon="icons.faCameraRetro"
        size="1x"
      />
    </a>
  </div>
</template>

<script>
import { faCameraRetro } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import chroma from 'chroma-js'
import capitalize from 'lodash.capitalize'
import logo from '@/assets/logo.svg'
import watermarkCompetitive from '@/assets/product/competitive-logo-round.svg'
import watermarkPerformance from '@/assets/product/performance-logo-round.svg'
import colors from '@/utils/colorPalette'

export default {
  name: 'ImageExport',
  components: {
    FontAwesomeIcon
  },
  props: {
    title: {
      type: String,
      default: 'export'
    },
    content: {
      type: Array,
      default: () => []
    },
    canvasContainerNode: {
      type: Object,
      default: () => {}
    },
    product: {
      type: String,
      default: 'performance'
    },
    selectedPoint: {
      type: Array,
      default: () => []
    },
    confidenceState: {
      type: Boolean,
      default: false
    },
    legendDisabled: {
      type: Boolean,
      default: false
    },
    legendTitle: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      debug: false,
      icons: {
        faCameraRetro,
        competitive: watermarkCompetitive,
        performance: watermarkPerformance,
        logo
      },
      dpi: window.devicePixelRatio || 1,
      scale: window.devicePixelRatio < 2 ? 2 : 1,
      picture: null,
      OFF_SET: 10,
      WATERMARK_PADDING: 10,
      TOP_PADDING: 20
    }
  },
  computed: {
    rawNodes () {
      return Array.from(this.canvasContainerNode.childNodes)
    },
    original () {
      return this.findCanvas(this.rawNodes) ||
        this.rawNodes.map(node => this.findCanvas(Array.from(node.childNodes))).find(n => n)
    },
    context () {
      return this.picture.getContext('2d')
    },
    ASSIDE () {
      return this.legendDisabled ? 0 : 600 / this.scale
    },
    FONT_JUMBO () {
      return 50 / this.scale
    },
    FONT_REGULAR () {
      return 24 / this.scale
    },
    SPACE_UNIT () {
      return 50 / this.scale
    },
    TOP_OFFSET () {
      const { content, SPACE_UNIT, TOP_PADDING, OFF_SET } = this
      return content.length * SPACE_UNIT * 0.7 + TOP_PADDING + OFF_SET
    },
    WATERMARK_SIZE () {
      return 90 / this.scale
    },
    AFTER_WATERMARK_PADDING () {
      return 2 * this.WATERMARK_PADDING + this.WATERMARK_SIZE
    },
    LOGO_SIZE () {
      return 42 / this.scale
    },
    DISCLAIMER_WIDTH () {
      return 450 / this.scale
    },
    H_WHITE_SPACE () {
      return this.SPACE_UNIT * 3 + this.ASSIDE
    },
    V_WHITE_SPACE () {
      return this.TOP_OFFSET + (this.SPACE_UNIT * 4.5)
    },
    FOOTER_TOP () {
      return this.picture.height - this.WATERMARK_SIZE - this.WATERMARK_PADDING
    },
    FOOTER_BOTTOM () {
      return this.picture.height - 25 / this.scale
    },
    LEGEND_LEFT () {
      return this.picture.width - this.ASSIDE - this.SPACE_UNIT
    },
    LEGEND_TOP () {
      return this.TOP_OFFSET + 2 * this.SPACE_UNIT
    },
    Y_VALUE_START () {
      return 200 / this.scale
    },
    Y_CI_START () {
      return 65 / this.scale
    },
    singleNetworkLegend () {
      return this.selectedPoint.length > 1 &&
        this.selectedPoint[0].canonical_network_id === this.selectedPoint[1].canonical_network_id
    },
    operatorHeight () {
      const { FONT_REGULAR } = this
      return FONT_REGULAR * (3 * (this.selectedPoint.length) + 4) + FONT_REGULAR / 3
    },
    actualHeights () {
      const { original, operatorHeight } = this
      return original.height > operatorHeight ? original.height : operatorHeight
    }
  },
  methods: {
    findCanvas (nodes) {
      return nodes.find(node => node.localName === 'canvas')
    },
    setExportDimentions () {
      const { original } = this
      this.picture.width = Math.max(original.width, 1400 / this.scale) + this.H_WHITE_SPACE
      this.picture.height = this.actualHeights + this.V_WHITE_SPACE
    },
    drawBackground () {
      const { context, original, SPACE_UNIT, TOP_OFFSET } = this
      context.fillStyle = '#fff'
      context.fillRect(0, 0, this.picture.width, this.picture.height)
      context.drawImage(original, SPACE_UNIT, TOP_OFFSET + (2 * SPACE_UNIT), original.width, this.actualHeights)
    },
    drawLegendBackground () {
      const { context, LEGEND_LEFT, LEGEND_TOP, OFF_SET, ASSIDE } = this
      context.fillStyle = colors.colorBlueSecondary
      context.fillRect(LEGEND_LEFT, LEGEND_TOP - OFF_SET, ASSIDE, this.actualHeights + OFF_SET)
    },
    addTitle () {
      const { FONT_JUMBO, SPACE_UNIT, TOP_OFFSET, TOP_PADDING } = this
      this.writeText(
        this.content[0],
        FONT_JUMBO,
        colors.colorBrandBlue,
        (TOP_PADDING * 2) + (SPACE_UNIT * 2),
        TOP_OFFSET
      )
    },
    addSubtitle () {
      const { FONT_REGULAR, SPACE_UNIT, TOP_OFFSET, TOP_PADDING } = this
      this.writeText(
        this.content[1].replace(/<[^>]*>/g, ''),
        FONT_REGULAR,
        colors.colorBlueHeader,
        (TOP_PADDING * 2) + (SPACE_UNIT * 2),
        TOP_OFFSET + SPACE_UNIT
      )
    },
    addInfoLine () {
      const { FONT_REGULAR, SPACE_UNIT, TOP_OFFSET } = this
      this.writeText(
        this.content[2],
        FONT_REGULAR,
        colors.colorBlueHeader,
        this.picture.width - SPACE_UNIT,
        TOP_OFFSET - (1.5 * SPACE_UNIT),
        'right'
      )
    },
    addProductName () {
      this.writeText(
        `${capitalize(this.product)} Intelligence`,
        this.FONT_REGULAR,
        colors.colorBlueHeader,
        this.AFTER_WATERMARK_PADDING,
        this.FOOTER_BOTTOM
      )
    },
    addDisclaimer () {
      const { SPACE_UNIT } = this
      this.writeText(
        '© Copyright ' + (new Date().getYear() + 1900) + ' Opensignal',
        this.FONT_REGULAR,
        colors.colorBlueHeader,
        this.picture.width - SPACE_UNIT,
        this.FOOTER_BOTTOM,
        'right'
      )
    },
    addLegendTitle (title) {
      const { FONT_REGULAR, LEGEND_LEFT, LEGEND_TOP } = this
      this.writeText(
        title,
        FONT_REGULAR,
        colors.colorBlueHeader,
        LEGEND_LEFT + FONT_REGULAR,
        LEGEND_TOP + FONT_REGULAR + FONT_REGULAR / 3
      )
    },
    writeText (content, font, color, left, top, align) {
      if (content) {
        const { context } = this
        context.font = `bold ${font}px Roboto`
        context.fillStyle = color

        if (align) {
          context.textAlign = align
        } else {
          context.textAlign = 'left'
        }

        context.fillText(content, left, top)
      }
    },
    drawWatermark () {
      return this.drawImage(
        this.icons[this.product],
        this.WATERMARK_PADDING,
        this.FOOTER_TOP,
        this.WATERMARK_SIZE
      )
    },
    drawLogo () {
      return this.drawImage(
        this.icons.logo,
        this.AFTER_WATERMARK_PADDING,
        this.FOOTER_TOP,
        this.LOGO_SIZE
      )
    },
    drawImage (image, left, top, size) {
      return this.loadImage(image).then(img => {
        const proportionalWidth = img.width / img.height
        this.context.drawImage(img, left, top, size * proportionalWidth, size)
      })
    },
    loadImage (url) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.addEventListener('load', e => resolve(img))
        img.addEventListener('error', () => {
          reject(new Error(`Failed to load image's URL: ${url}`))
        })
        img.src = url
      })
    },
    drawLegendSymbol (top, x, color, label) {
      const { context, FONT_REGULAR, LEGEND_LEFT, LEGEND_TOP } = this

      context.beginPath()
      context.arc(LEGEND_LEFT + FONT_REGULAR * 4, LEGEND_TOP + FONT_REGULAR * (3 * x + 4), FONT_REGULAR, 0, 2 * Math.PI, false)
      context.fillStyle = chroma(color).hex()
      context.fill()
      context.fillStyle = colors.colorWhite
      // 3 and 2 thirds of font size to center the letter in the circle
      context.fillText(label[0], LEGEND_LEFT + FONT_REGULAR / 3 * 11, top)
      context.fillStyle = colors.colorBlueHeader
      context.fillText(label, LEGEND_LEFT + FONT_REGULAR * 6, top)
    },
    drawLegendRank (top, x, rank) {
      const { context, FONT_REGULAR, LEGEND_LEFT } = this

      context.fillStyle = colors.colorBlueHeader
      context.fillText(rank, LEGEND_LEFT + FONT_REGULAR, top)
    },
    drawLegendInfo (top, foundPoint) {
      const { context, FONT_REGULAR, LEGEND_LEFT, ASSIDE, Y_VALUE_START, Y_CI_START } = this
      const { y, uci, lci } = foundPoint
      if (y) {
        context.save()
        context.font = `${FONT_REGULAR * 1.6}px Roboto`
        context.fillText(y, LEGEND_LEFT + ASSIDE - Y_VALUE_START, top)
        if (this.confidenceState) {
          context.font = `bold ${FONT_REGULAR * 0.75}px Roboto`
          context.fillText(uci, LEGEND_LEFT + ASSIDE - Y_CI_START, top - FONT_REGULAR * 0.7)
          context.fillText(lci, LEGEND_LEFT + ASSIDE - Y_CI_START, top)
        }
        context.restore()
      }
    },
    drawLegendItem (index, item) {
      const { LEGEND_TOP, FONT_REGULAR } = this
      const FONT_TOP = LEGEND_TOP + FONT_REGULAR * (3 * index + 4) + FONT_REGULAR / 3

      if (item.rank) {
        this.drawLegendRank(FONT_TOP, index, item.rank)
      }
      this.drawLegendSymbol(FONT_TOP, index, item.hex_color, item.name_mapped)
      this.drawLegendInfo(FONT_TOP, item)
    },
    drawLegend () {
      this.selectedPoint.map((i, x) => {
          this.drawLegendItem(x, i)
      })
    },
    addLegend () {
      if (this.legendDisabled) return

      const title = this.legendTitle || (this.singleNetworkLegend ? 'CDNs' : 'Operators')
      this.drawLegendBackground()
      this.addLegendTitle(title)
      this.drawLegend()
    },
    async download () {
      if (this.$refs.download.href) return

      this.picture = document.createElement('canvas')
      this.original.getContext('2d')
      this.setExportDimentions()

      /* white background */
      this.drawBackground()

      /* product logo */
      await this.drawWatermark()

      /* OS logo */
      await this.drawLogo()

      /* title */
      this.addTitle()

      /* subtitle */
      this.addSubtitle()

      /* info line */
      this.addInfoLine()

      /* Legend distribution */
      this.addLegend()

      /* product name */
      this.addProductName()

      /* disclaimer */
      this.addDisclaimer()

      this.context.scale(this.scale, this.scale)

      if (this.debug) {
        this.exportOnScreen()
      } else {
        this.$refs.download.href = this.picture.toDataURL()
        this.$refs.download.click()
        this.$refs.download.removeAttribute('href')
      }
    },
    exportOnScreen () {
      this.picture.style.position = 'fixed'
      this.picture.style.top = '20px'
      this.picture.style.left = '20px'
      this.picture.style.zIndex = '100'
      this.picture.style.zoom = 1 / this.dpi
      this.picture.style.boxShadow = '0 0 4px #000'
      let c = document.querySelector('body > canvas')
      if (c) {
        c.parentElement.removeChild(c)
      }
      document.body.appendChild(this.picture)
    }
  }
}
</script>
<style lang="scss">
@import 'scss/variables';
@import 'scss/components';

.image-export {
  @extend %boxIcon;
  a {
    color: $color-blue-header;
  }
  &:hover {
    a {
      color: $color-white;
    }
    background-color: $color-blue-header;
  }
}
</style>
