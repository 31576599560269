import capitalize from 'lodash.capitalize'
import { METRIC_TYPES } from '@/constants/constants'
/*
 *  metrics {Object}  {[], []} Object of arrays of metrics grouped by subtype
 *  @return {Array}  [{}, {}] array of objects for configuring the menu
 */
export function menuListItems (metrics, selected) {
  const typeMetrics = Object.keys(METRIC_TYPES).map(key => METRIC_TYPES[key])

  return Object.values(metrics)
    .map(list => {
      const groupSelected = list[0].subtype === selected.subtype
      const displayMetric = groupSelected
        ? selected
        : list.find(m => typeMetrics.includes(m.type))

      return {
        label: displayMetric.name,
        active: groupSelected,
        value: displayMetric.key,
        children: list.map(metric => ({
          label: metric.name,
          active: metric.key === selected.key,
          value: metric.key
        }))
      }
    })
}

export function geocodingListItems (geocodings, selected) {
  const displayGeocoding = geocodings.find(l => l.id === selected)
  return [{
    label: capitalize(displayGeocoding.name),
    children: geocodings.filter(l => l.id !== '1').map(g => ({
      label: capitalize(g.name),
      active: g.id === selected,
      value: g.id
    }))
  }]
}
