<template>
  <ul class="SelectStrategy">
    <li
      v-for="(strategy, key) in strategies"
      :key="key"
      class="SelectStrategy__item"
      :class="{ 'SelectStrategy__item--selected': isOptionSelected(key) }"
    >
      <Indicator
        :strategy="key"
        :label="key[0]"
        size="normal"
        @click="toggleOption(key)"
      />
    </li>
  </ul>
</template>

<script>
import Indicator from '@/components/market-growth/Indicator'
import { MG_STRATEGIES } from '@/constants/constants'

export default {
  name: 'MarketGrowthSelectStrategy',
  components: {
    Indicator
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      strategies: MG_STRATEGIES
    }
  },

  methods: {
    updateValue (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    toggleOption (option) {
      const formattedOption = option[0].toUpperCase()
      if (this.modelValue.includes(formattedOption)) {
        const tempValueArray = this.modelValue.filter(
          selectedOption => selectedOption !== formattedOption
        )
        this.updateValue(tempValueArray)
      } else {
        const tempValueArray = [...this.modelValue]
        tempValueArray.push(formattedOption)
        this.updateValue(tempValueArray)
      }
    },
    isOptionSelected (option) {
      const result = this.modelValue.find(
        selectedOption => selectedOption.toUpperCase() === option[0].toUpperCase()
      )
      return Boolean(result)
    }
  }
}
</script>

<style scoped lang="scss">
@import "scss/variables";
@import "~foundation-sites/scss/foundation";

ul {
  padding: 0;
  margin: 0;
  > li {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}

.SelectStrategy {
  &__item {
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: $font-size-12;
    cursor: pointer;
    filter: brightness(50%);

    &--selected {
      filter: brightness(100%);
    }
  }
}
</style>
