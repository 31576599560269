<template>
  <ChartWrapper
    :title="chartData.title"
    :tooltip="chartData.tooltip"
    :picture-meta="exportData"
    :selected-point="selectedPoint"
    :loading="chartPending"
    :empty="!chartData.loaded"
    :title-labels="titleLabels"
    :show-confidence-rate="showConfidenceRate && metricWithConfidence"
    :unit="chartUnit"
    :y-zoom="chartYZoom"
    enable-y-zoom
    :display-frm="displayFrm"
    :chart-data="chartData"
    @toggleYZoom="chartYZoom = !chartYZoom"
  >
    <line-chart
      v-if="chartData.loaded"
      :date="date"
      :data-set="chartData.data"
      :x-axis-unit="chartUnit"
      :x-axis-label="chartData.xAxisLabel"
      :y-axis-label="chartData.yAxisLabel"
      :end-date="dashboardInfo.last_date_available"
      :show-confidence-rate="showConfidenceRate && metricWithConfidence"
      :no-decimals="noDecimals"
      :y-zoom="chartYZoom"
      chart-id="trendChart"
      @point="navigateDate"
    />
  </ChartWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { LineChart, ChartWrapper } from '@/components/visual'
import router from '@/router'
import { getWeeklyDate } from '@/utils/date'

export default {
  name: 'ChartSimple',
  components: {
    ChartWrapper,
    LineChart
  },
  beforeRouteUpdate (to, from, next) {
    if (router.currentRoute.value.params.chart !== to.params.chart) {
      this.chartYZoom = false
    }

    next()
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartYZoom: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      displayFrm: 'chartFrm/displayFrm',
      chartData: 'charts/getSecondaryPeakChart',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      currentLocation: 'locations/current',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    exportData () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    metricWithConfidence () {
      return this.chartMetric &&
        this.chartMetric.subtype !== 'cell' &&
        this.chartMetric.subtype !== 'enodeb'
    },
    noDecimals () {
      return this.chartMetric.category === 'infrastructure'
    },
    weekly () {
      return this.chartMetric.category === 'infrastructure'
    }
  },
  methods: {
    navigateDate (date) {
      if (this.weekly) {
        date = getWeeklyDate(date, 3, this.dashboardInfo.first_date_available)
      }

      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          hero: this.hero,
          chart: this.chart,
          location: this.location,
          agg: this.agg,
          date
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
