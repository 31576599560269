<template>
  <div class="RankingTable">
    <div
      v-for="(bucket, i) in rankBuckets"
      :key="i"
      class="RankingTable__itemWrapper"
      :class="{'RankingTable__itemWrapper--draw': bucket.length > 1}"
      :style="`height:${bucket.length * 50}px`"
    >
      <div class="RankingTable__media--left">
        <div
          v-if="bucket.length > 1"
          class="RankingTable__drawRank"
        >
          <div
            class="RankingTable__itemRank"
            :class="{'RankingTable__itemRank--draw': bucket.length > 1}"
            :style="bucket.length > 1 ? `flex-basis:${25 * (bucket.length - 1)}px` : ''"
          >
            <span class="RankingTable__itemRankLabel">
              Rank:
            </span>
            <span class="RankingTable__itemRankValue">
              {{ bucket[0].rank }}
            </span>
          </div>
          <div class="RankingTable__drawOperators">
            <div class="RankingTable__drawOpLabel">
              <TileTooltip
                :content="'Results are statistically not significantly different between operators.'"
              >
                <template #tooltipAnchor>
                  <span>
                    DRAW
                  </span>
                </template>
              </TileTooltip>
            </div>
            <div
              v-for="(op, index) in bucket"
              :key="op.operatorName"
              class="RankingTable__drawOperator"
              :class="rankClass(op.comparisonRank, 'RankingTable__drawOperator')"
              :style="{ zIndex: 10 - index }"
            >
              <OperatorAvatar
                :letter="op.operatorInitial"
                :background-color="op.operatorColor"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="bucket.length"
          :set="op = bucket[0]"
        >
          <OperatorAvatar
            :letter="op.operatorInitial"
            :background-color="op.operatorColor"
          />
          <div
            :class="rankClass(op.comparisonRank, 'RankingTable__itemRank')"
            class="RankingTable__itemRank"
          >
            <span class="RankingTable__itemRankLabel">
              Rank:
            </span>
            <span
              v-if="op.rank"
              class="RankingTable__itemRankValue"
            >
              {{ op.rank }}
            </span>
            <tileTooltip
              v-else
              :title="'No rank available'"
              :content="'Minimum criteria for establishing a rank were not met'"
            >
              <template #tooltipAnchor>
                <div class="RankingTable__itemRankValue RankingTable__itemRankValue--noRank" />
              </template>
            </tileTooltip>
          </div>
        </div>
      </div>
      <div class="RankingTable__media--right RankingTable__stats">
        <div
          v-for="op in bucket"
          :key="op.operatorName"
          class="RankingTable__itemStats"
        >
          <!-- TODO add a v-else in for no data message -->
          <div>
            <span class="RankingTable__itemValue">
              {{ op.value || 0.00 }}
            </span>
            <span class="RankingTable__itemCis">
              {{ op.lci }} <span v-if="Number.isFinite(op.lci)">/</span> {{ op.uci }}
            </span>
          </div>
          <BarChartElement
            :value="op.value"
            :lci="op.lci"
            :uci="op.uci"
            :min="minAndMax.min"
            :max="minAndMax.max"
            :color="op.operatorColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChartElement from './BarChartElement'
import TileTooltip from './TileTooltip'
import OperatorAvatar from '@/components/OperatorAvatar'
import { sortAscendent, sortDescendent } from '@/utils/data'

export default {
  name: 'RankingTable',
  components: {
    OperatorAvatar,
    BarChartElement,
    TileTooltip
  },
  props: {
    ranks: {
      type: Array,
      default: () => []
    },
    minAndMax: {
      type: Object,
      default: () => {}
    },
    biggerBetter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isFirst () {
      return this.heroData && this.heroData.rank === 1
    },
    isDraw () {
      const buchet = this.rankBuckets.find(i => i.length && i[0].rank === this.heroData.rank)
      return this.heroData && buchet && buchet.length > 1
    },
    rankBuckets () {
      if (!this.ranks.length) return [[]]

      return Object.values(this.ranks.reduce((ac, item) => {
        if (item.rank) {
          if (!ac[item.rank]) {
            ac[item.rank] = []
          }

          ac[item.rank].push(item)
        } else {
          ac['X-' + item.operatorName] = [item]
        }

        return ac
      }, {})).sort((a, b) => {
        if (a[0].rank && b[0].rank) {
          return sortAscendent(a[0].rank, b[0].rank)
        } else if (!a[0].rank && !b[0].rank) {
          if (this.biggerBetter) {
            return sortDescendent(a[0].value, b[0].value)
          }

          return sortAscendent(a[0].value, b[0].value)
        } else if (!a[0].rank) {
          return 1
        } else if (!b[0].rank) {
          return -1
        }
      })
    }
  },
  methods: {
    rankClass (comparisonRank, baseClass) {
      if (Number.isFinite(comparisonRank)) {
        if (comparisonRank < 0) {
          return baseClass + '--diff ' + baseClass + '--up'
        } else if (comparisonRank > 0) {
          return baseClass + '--diff ' + baseClass + '--down'
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

@mixin extraSpace($isAdded: true) {
  $multiplier: 1;

  @if $isAdded {
    $multiplier: 2
  }

  .RankingTable__content {
    padding: (($multiplier - 1) * $small-space) 0;
  }

  .RankingTable__itemWrapper {
    &:first-child {
      .RankingTable__stats {
        &::before {
          top: -$multiplier * ($small-space);
        }
      }
    }

    &:last-child {
      .RankingTable__stats {
        &::before {
          bottom: -$multiplier * ($small-space);
        }
      }
    }
  }
}

// TODO remove
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-right {
  text-align: right;
}

$min-card-size: 335px;
$large-space: 20px;
$small-space: 10px;
$top-rounded-corners: 2px 2px 0 0;
$border-color: #D6DAE6;

@include extraSpace();

.RankingTable {
  box-sizing: border-box;
  width: 100%;
  background-color: #FFFFFF;

  &__media--left {
    flex: 0 0 120px;
  }
  &__media--right {
    flex: 1;
    padding-left: $small-space;
  }

  // TODO add different class to not target all elements
  .OperatorAvatar {
    width: 20px;
    height: 20px;
    line-height: $font-size-20;
    font-size: $font-size-12;
    float: left;
    margin-right: $small-space;
    border: 0;
  }

  &__itemWrapper {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    padding: $small-space $large-space;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $border-color;

    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &--draw.RankingTable__itemWrapper {
      height: initial;
    }

    &--draw {
      .RankingTable__media--left {
        height: 100%;
      }

      .RankingTable__itemRank {
        align-self: flex-start;
      }
    }
  }
  &__itemRank {
    line-height: $font-size-20;

    &--draw {
      padding: 0 0 $small-space;
    }

    &--diff:after {
      display: inline-block;
      content: "";
      width: 13px;
      height: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--up {
      font-weight: $font-weight-roboto-medium-bold;
      color: $arrow-green;

      &:after {
        background-image: url('../../assets/rank-up.svg');
      }
    }
    &--down {
      font-weight: $font-weight-roboto-medium-bold;
      color: $arrow-red;

      &:after {
        background-image: url('../../assets/rank-down.svg');
      }
    }
  }
  &__itemRankLabel {
    font-size: $font-size-12;
  }
  &__itemRankValue {
    font-size: $font-size-14;

    &--noRank {
      float: right;
      position: relative;
      width: 15px;
      height: 15px;

      &:after {
        content: "x";
        display: block;
        position: absolute;
        background-color: $icon-light-grey;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        line-height: 1.2rem;
        text-align: center;
        bottom: 6px;
        left: -4px;
      }
    }
  }

  &__stats {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -$small-space;
      left: 0;
      width: 1px;
      bottom: -$small-space;
      background: $border-color;
    }
  }

  &__itemStats + .RankingTable__itemStats {
    margin-top: 7px;
  }
  &__itemValue {
    display: inline-block;
    margin-right: $small-space;
  }
  &__itemCis {
    color: grey;
    font-size: $font-size-12;
  }
  &__drawRank {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__drawOperators {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }
  &__drawOpLabel {
    position: absolute;
    top: 50%;
    right: 50%;
    font-weight: 500;
    letter-spacing: .8px;
    font-size: $font-size-14;
    transform: translate(30px, calc(-100% - 7px));
    z-index: 11;

    span {
      display: block;
      position: relative;

      &:after {
        content: "?";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        line-height: 1.2rem;
        font-size: $font-size-10;
        font-weight: bold;
        border-radius: 50%;
        font-weight: normal;
        background-color: $winner-gold;
        color: $icon-light-grey;
        text-align: center;
        top: calc(100% + 4px);
        right: 30%;
      }
    }
  }
  &__drawOperator {
    position: relative;
    margin-top: -5px;

    &--diff {
      .OperatorAvatar {
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 8px;
          top: 6px;
          right: -10px;
        }
      }
    }

    &--up {
      .OperatorAvatar:after {
        background-image: url('../../assets/rank-up.svg');
      }
    }

    &--down {
      .OperatorAvatar:after {
        background-image: url('../../assets/rank-down.svg');
      }
    }
  }
}

@media screen and (max-width: 1545px) {
  @include extraSpace(false);

  .RankingTable__itemWrapper {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .Card {
    &__media--left {
      flex: 1;
    }

    &__media--right {
      flex: 2; //2.2 //1.7
    }
  }
}

@media screen and (max-width: 1425px) {
  @include extraSpace();

  .RankingTable__itemWrapper {
    padding-left: $large-space;
    padding-right: $large-space;
  }

  .Card {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  @include extraSpace(false);

  .RankingTable__itemWrapper {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .RankingTable__heroValue {
    font-size: $font-size-40;
    line-height: $font-size-52;
  }
}

@media screen and (max-width: 1023px) {
  @include extraSpace();

  .Card {
    width: 100%;
  }

  .RankingTable__itemWrapper {
    padding-left: $large-space;
    padding-right: $large-space;
  }

  .RankingTable__heroValue {
    font-size: $font-size-46;
  }
}

@media screen and (max-width: 740px) {
  @include extraSpace(false);

  .RankingTable__itemWrapper {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .RankingTable__heroValue {
    font-size: $font-size-40;
    line-height: $font-size-52;
  }
}
</style>
