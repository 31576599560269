import capitalize from 'lodash.capitalize'
import get from 'lodash.get'

const types = {
  ALL_LOCATIONS: 'location/allLocations',
  BY_GROUPINGS: 'location/byGroupings',
  COUNTRIES: 'location/countries',
  CURRENT_COUNTRY: 'location/currentCountry',
  CURRENT_LOCATION: 'location/currentLocation',
  CURRENT_GEOCODING: 'location/currentGeocoding',
  GROUPINGS: 'location/groupings',
  GROUP_LABEL: 'location/groupLabel',
  GET_GEOCODING_SINGLE_LABEL: 'location/getSingleLabel',
  IN_COUNTRY: 'location/inCountry',
}
const rootTypes = {
  CURRENT: 'locations/current'
}

const state = () => ({
  // TODO: set state in actions so module is independent
  // locations: []
  // countries: []
})
const getters = {
  /*
   * User's full countries list
   * @return { Array }
   */
  [types.COUNTRIES]: (state, getters, rootState, rootGetters) => rootGetters.locations.filter(l =>
    l.granularity === 'opensignal_countries'
  ),
  /*
   * User's full locations list excluding countries
   * @return { Array }
   */
  [types.ALL_LOCATIONS]: (state, getters, rootState, rootGetters) => rootGetters.locations.filter(l =>
    l.granularity !== 'opensignal_countries'
  ),
  /*
   * Curently selected country, or country of curently selected location
   * @return { Object }
   */
  [types.CURRENT_COUNTRY]: (state, getters, rootState, rootGetters) => rootGetters.locations.find(c =>
    c.iso3 === rootGetters[rootTypes.CURRENT].iso3 &&
    c.granularity === 'opensignal_countries'
  ),
  /*
   * Curently selected location
   * @return { Object }
   */
  [types.CURRENT_LOCATION]: (state, getters, rootState, rootGetters) => rootGetters[rootTypes.CURRENT],
  /*
   * The geocoding object that curent location belongs to
   * @return { Object }
   */
  [types.CURRENT_GEOCODING]: (state, getters, rootState, rootGetters) => get(rootGetters, ['dashboardInfo', 'geoconfigs_available'], []).find(g =>
    rootGetters[rootTypes.CURRENT].granularity === `${g.client}_${g.granularity}`
  ) || {},
  /*
   * All available locations from current's location country
   * @return { Array }
   */
  [types.IN_COUNTRY]: (state, getters, rootState, rootGetters) => getters[types.ALL_LOCATIONS].filter(l =>
    l.iso3 === rootGetters[rootTypes.CURRENT].iso3
  ),
  /*
   * All available groupings (polygons) from current's location country
   * @return { Array }
   */
  [types.GROUPINGS]: (state, getters, rootState, rootGetters) => get(rootGetters, ['dashboardInfo', 'geoconfigs_available'], []).filter(g =>
    g.countries.includes(rootGetters[rootTypes.CURRENT].iso3) &&
    !(g.client === 'opensignal' && g.granularity === 'countries')
  ),
  /*
   * All available groupings (polygons) from current's location country
   *   populated with coresponding locations
   * @return { Array }
   */
  [types.BY_GROUPINGS]: (state, getters) => getters[types.GROUPINGS].map(g => ({
    ...g,
    locations: getters[types.IN_COUNTRY].filter(l => l.granularity === `${g.client}_${g.granularity}`),
    name: getters[types.GROUP_LABEL](g.client, g.granularity)
  })),
  /*
   * Given a clinet name and a granularity return a label(name) for it
   * args  { String }        client - a clinet db name
   * args  { String }        granularity - a geocoding string
   * @return { String }
   */
  [types.GROUP_LABEL]: () => (client, granularity) => {
    switch (client) {
      case 'opensignal':
        return capitalize(granularity)
      case 'tmobileusa':
        return `T-mobile ${capitalize(granularity)}`
      case 'verizonusa':
        const gran = granularity.split('-').map(s => capitalize(s)).join(' ')
        return `Verizon ${capitalize(gran)}`
      default:
        return `${capitalize(client)} ${capitalize(granularity)}`
    }
  },
  /*
   * Given a geocoding string returns a label(name) for one element of that type
   *  i.e.
   *  regions => Region
   *  cities => City
   *  countries => National
   *  ops-markets => Ops-market
   *  any-other-non-basic-english-plural-name => Any-other-non-basic-english-plural-name
   * args  { String }        granularity - a geocoding string
   * @return { String }
   */
  [types.GET_GEOCODING_SINGLE_LABEL]: () => granularity => {
    if (!granularity) return ''
    if (granularity === 'countries') return 'National'
    if (granularity.substring(granularity.length - 3) === 'ies') {
      return capitalize(granularity.substring(0, granularity.length - 3) + 'y')
    } else if (granularity[granularity.length - 1] === 's') {
      return capitalize(granularity.substring(0, granularity.length - 1))
    } else {
      return capitalize(granularity)
    }
  }
}

const actions = {}
const mutations = {
  // TODO: add mutations to support state update
}

export default {
  state,
  getters,
  actions,
  mutations
}
