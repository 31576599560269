<template>
  <div
    v-if="ready"
    class="Landing"
  >
    <div class="Landing__middle">
      <div class="Landing__products">
        <div
          v-for="product in displayProducts"
          :key="product.title"
          class="Landing__product"
        >
          <product-card
            :title="product.title"
            :label="product.label"
            :disabled="product.disabled"
            :loading="userPending"
            :product-key="product.name"
            @card-click="navigateToDashboard(product.route)"
          />
        </div>
      </div>
    </div>
    <div class="Landing__top">
      <div>
        <a
          href="https://opensignal.com/"
          target="_blank"
        ><img
          class="Landing__logo"
          src="../assets/logo.svg"
        ></a>
      </div>
      <div class="Landing__title">
        Analytics Solutions
      </div>
    </div>
    <div class="Landing__bottom">
      <blurred-disclaimer />
    </div>
    <div class="Landing__top-right">
      <custom-button
        transparent
        @click="signOut()"
      >
        Logout
      </custom-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BlurredDisclaimer from '@/components/BlurredDisclaimer'
import CustomButton from '@/components/CustomButton'
import ProductCard from '@/components/landing/ProductCard'
import ROUTES from '@/constants/routes'

export default {
  name: 'Landing',
  components: {
    CustomButton,
    BlurredDisclaimer,
    ProductCard
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    ...mapGetters({
      products: 'auth/getProducts',
      userPending: 'auth/isUserPending'
    }),
    displayProducts () {
      return [
        ...this.products.filter(product => product.theme),
        {
          name: 'insights',
          title: 'Insights',
          disabled: false,
          label: ['Tutela', 'Insights'],
          theme: 'red',
          keys: ['insights'],
          route: 'https://insights.tutela.com'
        }
      ]
    }
  },
  mounted () {
    this.validateToken().then(res => {
      if (res) {
        this.ready = true

        this.setUser().then(() => {
          if (this.user.dashboards.includes('market-growth')) {
            this.$router.push({
              name: ROUTES.MarketGrowth
            })
          }

          this.checkDisclaimer()
        })
      }
    })
  },
  methods: {
    ...mapActions([
      'loginCustom',
      'resetUser',
      'setUser',
      'validateToken',
      'checkDisclaimer'
    ]),
    ...mapActions({
      navigate: 'navigateToDashboard'
    }),
    navigateToDashboard (mode) {
      if (mode.includes('https://')) {
        window.open(mode)
      } else if (!this.userPending) {
        this.navigate({
          mode: mode
        })
      }
    },
    signOut () {
      this.resetUser()
      this.$router.replace({
        name: 'Login'
      })
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .Landing {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    min-width: pxToRem(800px);
    min-height: pxToRem(300px);

    @media screen and (max-width: $ci-breakpoint-tablet) {
      min-width: initial;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &__top {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      padding: 2em 0;
      font-size: $font-size-13;
      font-weight: $font-weight-roboto-bold;
      text-align: center;
      background: url('../assets/round-bg.svg');
      background-position-y: calc(100% + 1px);
      background-position-x: 50%;
      background-repeat: no-repeat;
      background-size: 150%;
      z-index: 1;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        position: relative;
        order: 1;
        margin-bottom: -16px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background-color: #fff;
          z-index: -1;
        }
      }
    }

    &__top-right {
      position: absolute;
      top:0;
      right: 0;
      padding: pxToRem(10px);
      z-index: 2;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        padding: pxToRem(5px);
      }
    }

    &__title {
      font-weight: $font-weight-roboto-regular;
      color: $color-blue-header;
    }

    &__middle {
      height: 100%;
      @include xy-grid-container($width: 100%, $padding: none);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        order: 2;
        width: 100%;
        height: auto;
        flex: 1;
      }
    }

    &__bottom {
      position: absolute;
      bottom:0;
      left: 0;
      width: 100%;
      max-width: pxToRem(625px);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        position: static;
        order: 3;
        background: $color-background-grey;
        max-width: initial;
      }
    }

    &__products {
      @include xy-grid($direction: horizontal);
      height: 100%;
      margin: 0 -20px;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        @include xy-grid($direction: vertical);
        flex-wrap: nowrap;
        height: auto;
        min-height: 100%;
        margin: 0;
      }
    }

    &__product {
      @include xy-cell($size: 1 of 3, $gutters: 4px);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        flex: 1 0 240px;
        width: 100%;
        margin: 0;
      }
    }

    &__text-blue {
      color: $color-electric-blue;
    }

    &__logo {
      height: 3em;
    }
  }
</style>
