<template>
  <div
    class="RankingTableHeader"
    @click="onHeaderClick"
  >
    <div class="RankingTableHeader__title">
      {{ title }} <span v-if="unit">| {{ unit }}</span>
    </div>
    <div class="RankingTableHeader__icon">
      <FontAwesomeIcon
        :icon="icons.faChartBar"
        size="lg"
      />
    </div>
    <slot name="dropdown" />
  </div>
</template>

<script>
import {
  faChartBar
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'RankingTableHeader',
  components: {
    FontAwesomeIcon
  },
  props: {
    title: { type: String, default: undefined },
    unit: { type: String, default: undefined }
  },
  data () {
    return {
      icons: { faChartBar }
    }
  },
  methods: {
    onHeaderClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style global lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .RankingTableHeader {
      @include xy-grid();
      align-items: center;
      border-radius: $border-radius-medium $border-radius-medium 0 0;
      color: $color-white;
      cursor: pointer;
      height: $headers-height;
      position: relative;

      &:hover {
        background-color: rgba($color-black, 0.15);
      }

    &__title {
      @include xy-cell(auto);
      font-size: $font-size-12;
      font-weight: $font-weight-roboto-bold;
    }

    &__icon {
      @include xy-cell(shrink)
    }
  }
</style>
