
export const V1_RESOLUTIONS = ['360p', '720p']
export const V2_RESOLUTIONS = ['360p', '720p', '1080p', '4K']

export const V1_CDN_RES = [
  'OPENSIGNAL_360p',
  'OPENSIGNAL_720p',
  'YOUTUBE_360p',
  'YOUTUBE_720p'
]

export const V2_CDN_RES = [
  'AKAMAI_360p',
  'AKAMAI_720p',
  'AKAMAI_1080p',
  'AKAMAI_4K',
  'CLOUDFRONT_360p',
  'CLOUDFRONT_720p',
  'CLOUDFRONT_1080p',
  'CLOUDFRONT_4K',
  'GOOGLECLOUD_360p',
  'GOOGLECLOUD_720p',
  'GOOGLECLOUD_1080p',
  'GOOGLECLOUD_4K'
]

export const V1_CDN_RES_LABELS = {
  'OPENSIGNAL_360p': 'Akamai 360p',
  'OPENSIGNAL_720p': 'Akamai 720p',
  'YOUTUBE_360p': 'YouTube 360p',
  'YOUTUBE_720p': 'YouTube 720p',
  'akamai': 'Akamai',
  'cloudfront': 'Cloudfront',
  'google storage': 'Google Storage',
  'ec2': 'EC2',
  'gce': 'Google Compute Engine',
  'google.com': 'google.com'
}

export const V2_CDN_RES_LABELS = {
  'AKAMAI_4K': 'Akamai 4K',
  'AKAMAI_360p': 'Akamai 360p',
  'AKAMAI_720p': 'Akamai 720p',
  'AKAMAI_1080p': 'Akamai 1080p',
  'CLOUDFRONT_4K': 'CloudFront 4K',
  'CLOUDFRONT_360p': 'CloudFront 360p',
  'CLOUDFRONT_720p': 'CloudFront 720p',
  'CLOUDFRONT_1080p': 'CloudFront 1080p',
  'GOOGLECLOUD_4K': 'Google Cloud 4K',
  'GOOGLECLOUD_360p': 'Google Cloud 360p',
  'GOOGLECLOUD_720p': 'Google Cloud 720p',
  'GOOGLECLOUD_1080p': 'Google Cloud 1080p'
}

export const V1_CDN_RES_ENDPOINT_LABELS = {
  'OPENSIGNAL': 'Akamai',
  'YOUTUBE': 'YouTube'
}

export const V2_CDN_RES_ENDPOINT_LABELS = {
  'AKAMAI': 'Akamai',
  'CLOUDFRONT': 'CloudFront',
  'GOOGLECLOUD': 'Google Cloud'
}
