<template>
  <div class="CategoriesTabs">
    <div
      v-for="item in displayTabs"
      :key="item.key"
      :class="{'CategoriesTabs__item--active': modelValue === item.key}"
      class="CategoriesTabs__item"
      @click="select(item.key)"
    >
      <FontAwesomeIcon
        v-if="item.icon"
        :icon="item.icon"
        class="CategoriesTabs__icon"
      />
      <img
        v-else
        :src="getSvgIcon(item.key)"
        class="CategoriesTabs__icon"
      >
      <div
        :class="{'CategoriesTabs__label--active': modelValue === item.key}"
        class="CategoriesTabs__label"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  faCity,
  faMobile,
  faBroadcastTower
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'CategoriesTabs',
  components: {
    FontAwesomeIcon
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    licences: {
      type: Array,
      default: () => []
    },
    dashboards: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        faCity,
        faMobile,
        faBroadcastTower
      },
      tabs: [
        {
          icon: faCity,
          key: 'experience',
          label: 'User Experience',
          dashboard: 'performance'
        },
        {
          icon: faMobile,
          key: 'devices',
          label: 'Devices',
          licence: 'pi_devices'
        },
        {
          icon: faBroadcastTower,
          key: 'infrastructure',
          label: 'Infrastructure',
          licence: 'pi_infra'
        }
      ]
    }
  },
  computed: {
    displayTabs () {
      return this.tabs.filter(item => {
        if (!item.licence && !item.dashboard) {
          return true
        }

        return this.licences.includes(item.licence) || this.dashboards.includes(item.dashboard)
      })
    }
  },
  methods: {
    getSvgIcon (name) {
      if (name === this.modelValue) {
        return require(`../../assets/technology-${name}.white.svg`)
      }

      return require(`../../assets/technology-${name}.svg`)
    },
    select (value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.CategoriesTabs {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  justify-content: space-around;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #85A8C4;

  &__item {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0.2rem 0 0.2rem;
    justify-content: space-around;
    font-size: pxToRem(12);
    color: #85A8C4;
    text-align: center;
    border-radius: $control-border-radius $control-border-radius 0 0;
    height: 3rem;
    cursor: pointer;

    &--active.CategoriesTabs__item {
      @extend %active;
    }
  }

  &__icon {
    display: flex;
    padding: 0 1rem 0 1rem;
    font-size: pxToRem(20);
    height: pxToRem(20);
  }

  &__label {
    margin: 0;
    height: pxToRem(12);
    line-height: pxToRem(12);
    white-space: nowrap;
    width: 100%;

    &--active.CategoriesTabs__label {
      @extend %active;
    }
  }
}
</style>
