import { format } from 'date-fns'
import * as L from 'leaflet'
import capitalize from 'lodash.capitalize'
import { STAR_RATING_CATEGORY_TITLES, STAR_RATING_CATEGORY_KEYS } from '@/constants/constants'
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats'
import ROUTES from '@/constants/routes'
import router from '@/router'
import { Maps, MarketGrowth } from '@/store/api'
import { getSafeDate } from '@/utils/date'
import { exportToCsv } from '@/utils/files'
import {
  getSelectableGranularities,
  getStarRatingAsProperties,
  filterRatingsByLocation
} from '@/utils/market-growth'

const types = {
  PROVINCES: 'marketGrowth/provinces',
  SELECTED_PROVINCES: 'marketGrowth/selectedProvinces',
  TOGGLE_STORES: 'marketGrowth/TOGGLE_STORES',
  TOGGLE_STAR_RATINGS: 'marketGrowth/TOGGLE_STAR_RATINGS',
  SELECT_OVERVIEW_GRANULARITIES: 'marketGrowth/selectOverviewGranularities',
  OVERVIEW_GRANULARITIES: 'marketGrowth/overviewGranularities',
  SELECTABLE_GRANULARITIES: 'marketGrowth/selectableGranularities',
  STORES_FETCHED: 'marketGrowth/STORES_FETCHED',
  STAR_RATINGS_FETCHED: 'marketGrowth/STAR_RATINGS_FETCHED',
  MAP_BOX: 'marketGrowth/mapBox',
  SET_MAP_BOX: 'marketGrowth/SET_MAP_BOX',
  SET_SUBMARKETS_DATA: 'marketGrowth/SET_SUBMARKETS_DATA',
  SUBMARKETS: 'marketGrowth/submarkets',
  SELECTED_SUBMARKETS: 'marketGrowth/selectedSubmarkets',
  STRATEGIES: 'marketGrowth/strategies',
  OVERVIEW_FORMATTED_SUBMARKETS: 'marketGrowth/overviewFormattedSubmarkets',
  DETAILS_FORMATTED_SUBMARKETS: 'marketGrowth/detailsFormattedSubmarkets',
  DISPLAY_STORES: 'marketGrowth/displayStores',
  STORES: 'marketGrowth/stores',
  DISPLAY_STAR_RATINGS: 'marketGrowth/displayStarRatings',
  STAR_RATINGS: 'marketGrowth/starRatings'
}

const getProvinceData = (provinces, id) => provinces.find(province => province.key === id)

const isParentLocation = (dashboardInfo, location) => {
  const parentGeocoding = dashboardInfo.parent_geocoding
  return parseInt(location.granularityId) === parentGeocoding
}

const state = () => ({
  displayStores: true,
  displayStarRatings: true,
  starRatings: [],
  selectedProvinces: null,
  mapBBox: null,
  stores: {
    results: [],
    count: 0
  },
  submarketsData: {
    results: [],
    end_date: ''
  },
  selectedOverviewGranularities: null
})

const getters = {
  [types.MAP_BOX]: (state, getters, rootState, rootGetters) => state.mapBBox,
  [types.DISPLAY_STORES]: (state, getters, rootState, rootGetters) => state.displayStores,
  [types.DISPLAY_STAR_RATINGS]: (state, getters, rootState, rootGetters) => state.displayStarRatings,
  [types.OVERVIEW_GRANULARITIES]: (state, getters, rootState, rootGetters) => {
    if (state.selectedOverviewGranularities === null) {
      const lsGranularities = window.localStorage.getItem('os_mg_overview_granularities')
      let storedGranularities

      if (lsGranularities) {
        try {
          storedGranularities = JSON.parse(lsGranularities)
        } catch (error) {
          console.error(error)
        }
      }

      if (storedGranularities && storedGranularities.length) {
        return storedGranularities
      }

      return getters[types.SELECTABLE_GRANULARITIES].map(granularity => granularity.value)
    }
    return state.selectedOverviewGranularities
  },
  [types.SELECTABLE_GRANULARITIES]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters.dashboardInfo || !rootGetters.dashboardInfo.geoconfigs_available || !rootGetters.dashboardInfo.parent_geocoding) {
      return []
    }
    return getSelectableGranularities(rootGetters.dashboardInfo)
      .map(geoconfig => ({
        label: capitalize(geoconfig.granularity),
        value: `${geoconfig.id}`
      }))
  },
  [types.PROVINCES]: (state, getters, rootState, rootGetters) => {
    const dashboardInfo = rootGetters.dashboardInfo
    if (!dashboardInfo) {
      return []
    }
    return rootGetters.locations
      .filter(location => isParentLocation(dashboardInfo, location))
  },
  [types.SELECTED_PROVINCES]: (state, getters, rootState, rootGetters) => {
    const dashboardInfo = rootGetters.dashboardInfo

    if (!dashboardInfo) {
      return []
    }

    if (state.selectedProvinces === null) {
      const lsProvinces = window.localStorage.getItem('os_mg_provinces')
      let storedProvinces

      if (lsProvinces) {
        try {
          storedProvinces = JSON.parse(lsProvinces)
        } catch (error) {
          console.error(error)
        }
      }

      if (storedProvinces && storedProvinces.length) {
        return storedProvinces
      }

      return getters[types.PROVINCES]
        .map(province => province.key)
    }

    return state.selectedProvinces
  },
  [types.SUBMARKETS]: (state, getters, rootState, rootGetters) => {
    const route = router.currentRoute.value
    if (route.name === ROUTES.MarketGrowthOverview) {
      return rootGetters.locations.filter(location => {
          // TODO: #1 - getters[types.OVERVIEW_GRANULARITIES] called excessive number of times from Download button on MG
          return getters[types.OVERVIEW_GRANULARITIES].includes(String(location.granularityId))
        }
      )
    }
    const { granularity } = route.params
    return rootGetters.locations.filter(location => location.granularityId === granularity)
  },
  [types.SELECTED_SUBMARKETS]: (state, getters, rootState, rootGetters) => {
    const selectedProvinces = getters[types.SELECTED_PROVINCES]
    return getters[types.SUBMARKETS].filter(submarket => selectedProvinces.includes(submarket.parent_id))
  },
  [types.STORES]: (state, getters, rootState, rootGetters) => state.stores.results,
  [types.STAR_RATINGS]: (state, getters, rootState, rootGetters) => state.starRatings,
  [types.STRATEGIES]: () => router.currentRoute.value.params.strategy ? router.currentRoute.value.params.strategy.toLowerCase() : '',
  [types.OVERVIEW_FORMATTED_SUBMARKETS]: (state, getters, rootState, rootGetters) => {
    const isStrategySelected = strategy => getters[types.STRATEGIES].includes(strategy[0])
    return getters[types.SELECTED_SUBMARKETS].reduce((result, submarket) => {
      const geoConfig = rootGetters.dashboardInfo.geoconfigs_available
        .find(geoconfig => geoconfig.id === parseInt(submarket.granularityId))
      if (!geoConfig) {
        return result
      }
      const formattedObject = {
        parent_id: submarket.parent_id,
        key: submarket.key,
        name: submarket.name,
        type: capitalize(geoConfig.granularity),
        population: parseInt(submarket.population_size),
        date: state.submarketsData.end_date.split('-').join('/') // replace - with /
      }
      const locationDataItems = state.submarketsData.results.filter(dataItem => `${dataItem.location}` === submarket.key)
      if (locationDataItems.length === 0) {
        return result
      }
      const anyStrategyFound = locationDataItems.find(item => isStrategySelected(item.strategic_position))
      if (!anyStrategyFound) {
        return result
      }
      locationDataItems.forEach(item => {
        formattedObject[`${item.canonical_network_id}-${item.canonical_network_competitor}`] = item.strategic_position
        formattedObject.rank = item.superset_rank || 0
        formattedObject.granularityId = submarket.granularityId
      })
      return result.concat({
        ...formattedObject,
        ...getStarRatingAsProperties(filterRatingsByLocation(state.starRatings, submarket.key))
      })
    }, [])
  },
  [types.DETAILS_FORMATTED_SUBMARKETS]: (state, getters, rootState, rootGetters) => {
    if (!state.mapBBox) {
      return []
    }
    const geoJson = rootGetters.shapes.geoJson
    const getDataForLocation = (id) => {
      if (!geoJson || !geoJson.features) return
      return geoJson.features.find(x => x.id === parseInt(id))
    }
    return getters[types.SELECTED_SUBMARKETS].reduce((acc, submarket, index, arr) => {
      const submarketObject = getDataForLocation(submarket.key)
      if (!submarketObject) {
        return acc
      }
      // Contains center
      if (!state.mapBBox.contains(L.latLng(submarketObject.center[1], submarketObject.center[0]))) {
        return acc
      }
      const item = submarketObject.item[0]
      const strategyKey = item.strategic_position[0]
      if (!getters[types.STRATEGIES].includes(strategyKey)) {
        return acc
      }
      acc.push({
        parent_id: submarket.parent_id,
        key: item.location,
        rank: item.rank,
        name: submarket.name,
        strategy: item.strategic_position,
        availability: item.good_availability_delta,
        population: parseInt(submarket.population_size),
        market_share: item.device_share_delta,
        date: item.date,
        ...getStarRatingAsProperties(filterRatingsByLocation(state.starRatings, item.location))
      })
      return acc
    }, [])
  }
}

const actions = {
  async fetchMarketGrowthStores ({ commit }, { brand, competitor }) {
    const marketGrowth = new MarketGrowth(window.localStorage.getItem('os_token'))
    const response = await marketGrowth.getStores({
      dashboard: 'market-growth',
      brandId: brand,
      competitorId: competitor
    })
    commit(types.STORES_FETCHED, response)
  },
  async fetchMarketGrowthOverviewData ({ commit, rootGetters }) {
    if (!rootGetters.dashboardInfo.geoconfigs_available) {
      return
    }
    const geoConfigIds = getSelectableGranularities(rootGetters.dashboardInfo)
      .map(geoconfig => geoconfig.id)
    const maps = new Maps(window.localStorage.getItem('os_token'))
    const responses = await Promise.all(
      geoConfigIds.map(id => maps.getData({
        polygon: id,
        agg: '180days',
        dashboard: 'market-growth'
      }))
    )
    const combinedResult = []
    responses.forEach((response, index) => {
      combinedResult.push(...response.results.map(result => ({ ...result, geoconfig: geoConfigIds[index] })))
    })

    const submarketsData = { ...responses[0], results: combinedResult }
    commit(types.SET_SUBMARKETS_DATA, Object.freeze(submarketsData))
  },
  async fetchMarketGrowthStarRatings ({ commit, rootGetters }) {
    if (!rootGetters.dashboardInfo.geoconfigs_available) {
      return
    }
    const geoConfigIds = getSelectableGranularities(rootGetters.dashboardInfo)
      .map(geoconfig => geoconfig.id)
    const maps = new Maps(window.localStorage.getItem('os_token'))
    const brands = rootGetters.dashboardInfo.brands || []

    const ratingCalls = []
    geoConfigIds.forEach(geoConfigId => {
      brands.forEach(brand => {
        brand.competitors.forEach(competitor => {
          ratingCalls.push(maps.getData({
            polygon: geoConfigId,
            brand: brand.canonical_network_id,
            competitor: competitor.canonical_network_id,
            agg: '180days',
            dashboard: 'market-growth',
            endDate: '2023-06-30', // TODO: Remove hardcoded date
            metricList: 'mgstarratings_all'
          }))
        })
      })
    })

    const ratingResponses = await Promise.all(ratingCalls)
    const ratingCombinedResult = []
    ratingResponses.forEach((response, index) => {
      ratingCombinedResult.push(...response.results.map(result => ({ ...result, geoconfig: geoConfigIds[index] })))
    })
    commit('marketGrowth/STAR_RATINGS_FETCHED', Object.freeze(ratingCombinedResult))
  },
  async exportMarketGrowthOverviewList ({ state, getters, rootGetters, dispatch }) {
    await dispatch('fetchMarketGrowthStarRatings')

    const provinces = getters[types.PROVINCES]
    const brands = rootGetters.dashboardInfo.brands || []
    const flatBrandNames = brands.flatMap(brand => [
      ...brand.competitors
        .map(competitor => `${brand.name_mapped} - ${competitor.name_mapped}`)
    ])
    const flatBrandKeys = brands.flatMap(brand => [
      ...brand.competitors
        .map(competitor => `${brand.canonical_network_id}-${competitor.canonical_network_id}`)
    ])
    const titles = [
      'Province',
      'Superset Rank',
      'Market',
      'Type',
      ...flatBrandNames,
      'Population',
      'Date'
    ]
    const isStrategySelected = strategy => getters[types.STRATEGIES].includes(strategy[0])

    const allNetworks = []
    brands.forEach(brand => {
      allNetworks.push(brand)
      brand.competitors.forEach(competitor => allNetworks.push(competitor))
    })

    STAR_RATING_CATEGORY_TITLES.forEach(title => {
      allNetworks.forEach(network => {
        titles.push(`${network.name_mapped} - ${title} - 4G`)
        titles.push(`${network.name_mapped} - ${title} - 5G`)
      })
    })

    const data = getters[types.OVERVIEW_FORMATTED_SUBMARKETS].map(submarket => {
      const provinceData = getProvinceData(provinces, submarket.parent_id)
      const values = [
        provinceData ? provinceData.name : '',
        submarket.rank,
        submarket.name,
        submarket.type,
        ...flatBrandKeys.map(key => submarket[key] && isStrategySelected(submarket[key]) ? submarket[key] : 'No strategy'),
        submarket.population,
        format(new Date(submarket.date), API_LONG_DATE_FORMAT)
      ]
      STAR_RATING_CATEGORY_KEYS.forEach(category => {
        allNetworks.forEach(network => {
          let metric = `${network.canonical_network_id}-${category}`

          values.push(submarket[metric] ? submarket[metric].lte || '' : '')
          values.push(submarket[metric] ? submarket[metric]['5g'] || '' : '')
        })
      })

      return values
    })
    const fileDate = format(getSafeDate(state.submarketsData.end_date), API_LONG_DATE_FORMAT)
    const fileTitle = `Market Growth Submarkets ${fileDate}.csv`
    exportToCsv(fileTitle, [titles, ...data])
  },
  exportMarketGrowthDetailsList ({ getters, rootGetters }) {
    const titles = [
      'Province',
      'Rank',
      'Market',
      'Strategy',
      'Good Availability Δ',
      'Market Share Δ',
      'Population',
      'Brand',
      'Competitor',
      'Date'
    ]
    const brand = rootGetters.dashboardInfo.brands.find(brand => brand.canonical_network_id + '' === router.currentRoute.value.params.brand + '')
    const competitor = brand.competitors.find(competitor => competitor.canonical_network_id + '' === router.currentRoute.value.params.competitor + '')
    STAR_RATING_CATEGORY_TITLES.forEach(title => {
      titles.push(`${brand.name_mapped} - ${title} - 4G`)
      titles.push(`${brand.name_mapped} - ${title} - 5G`)
      titles.push(`${competitor.name_mapped} - ${title} - 4G`)
      titles.push(`${competitor.name_mapped} - ${title} - 5G`)
    })
    titles.push('Store Locations')

    const provinces = getters[types.PROVINCES]
    const storeLocations = getters[types.STORES]
    const data = getters[types.DETAILS_FORMATTED_SUBMARKETS].map(submarket => {
      const provinceData = getProvinceData(provinces, submarket.parent_id)
      const values = [
        provinceData ? provinceData.name : '',
        submarket.rank,
        submarket.name,
        submarket.strategy,
        submarket.availability + '%',
        submarket.market_share + '%',
        submarket.population,
        brand.name_mapped,
        competitor.name_mapped,
        format(getSafeDate(submarket.date), API_LONG_DATE_FORMAT)
      ]
      STAR_RATING_CATEGORY_KEYS.forEach(category => {
        let brandMetric = `${brand.canonical_network_id}-${category}`
        let competitorMetric = `${competitor.canonical_network_id}-${category}`

        values.push(submarket[brandMetric] ? submarket[brandMetric].lte || '' : '')
        values.push(submarket[brandMetric] ? submarket[brandMetric]['5g'] || '' : '')
        values.push(submarket[competitorMetric] ? submarket[competitorMetric].lte || '' : '')
        values.push(submarket[competitorMetric] ? submarket[competitorMetric]['5g'] || '' : '')
      })
      values.push(
        storeLocations.filter(
          item => item.locations.includes(submarket.key)
        ).map(store => {
          const storeBrand = brand.canonical_network_id === store.canonical_network_id ? brand.name_mapped : competitor.name_mapped
          return `${storeBrand} Store ${store.client_unique_id}: ${store.address}, ${store.postal_code}, ${store.city}`
        }).join(' | ')
      )
      return values
    })
    const date = new Date(getters[types.DETAILS_FORMATTED_SUBMARKETS][0].date)
    exportToCsv('Market Growth Submarkets ' + format(date, API_LONG_DATE_FORMAT) + '.csv', [titles, ...data])
  },
  selectMarketGrowthProvinces ({ commit }, provinces) {
    commit(types.SELECTED_PROVINCES, provinces)
    window.localStorage.setItem('os_mg_provinces', JSON.stringify(provinces))
  },
  setMarketGrowthMapBBox ({ commit }, bbox) {
    commit(types.SET_MAP_BOX, bbox)
  },
  toggleMarketGrowthStores ({ commit }) {
    commit(types.TOGGLE_STORES)
  },
  toggleMarketGrowthStarRatings ({ commit }) {
    commit(types.TOGGLE_STAR_RATINGS)
  },
  selectOverviewGranularities ({ commit }, granularities) {
    commit(types.SELECT_OVERVIEW_GRANULARITIES, granularities)
    window.localStorage.setItem('os_mg_overview_granularities', JSON.stringify(granularities))
  }
}

const mutations = {
  [types.SELECTED_PROVINCES]: (state, provinces) => {
    state.selectedProvinces = provinces
  },
  [types.SET_MAP_BOX]: (state, bbox) => {
    state.mapBBox = bbox
  },
  [types.SET_SUBMARKETS_DATA]: (state, data) => {
    state.submarketsData = data
  },
  [types.TOGGLE_STORES]: (state) => {
    state.displayStores = !state.displayStores
  },
  [types.TOGGLE_STAR_RATINGS]: (state) => {
    state.displayStarRatings = !state.displayStarRatings
  },
  [types.STAR_RATINGS_FETCHED]: (state, data) => {
    state.starRatings = data
  },
  [types.STORES_FETCHED]: (state, data) => {
    state.stores = data
  },
  [types.SELECT_OVERVIEW_GRANULARITIES]: (state, data) => {
    state.selectedOverviewGranularities = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  types
}
