<template>
  <div class="ChartBinnedCdnRes">
    <div
      v-for="(cdnRes, i) in chartDataCdnRes"
      :key="cdnRes.name"
    >
      <ChartWrapper
        :title="cdnRes.title"
        :picture-meta="pictureMeta"
        :selected-point="selectedPoint"
        :loading="chartPending"
        :empty="cdnRes.empty"
        :tooltip="cdnRes.tooltip"
        :unit="chartUnit"
        :title-labels="titleLabels"
        :percentile="percentile"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <template #scale>
          <DistributionScale
            v-if="cdnRes.loaded && i === 0 && hover"
            v-model="percentile"
          />
        </template>
        <simple-bar-chart
          v-if="cdnRes.loaded"
          :data-set="cdnRes.data"
          :labels="cdnRes.label"
          :x-axis-label="chartXAxisInfo.title"
          :x-axis-unit="chartXAxisInfo.unit"
          :y-axis-label="cdnRes.yAxisLabel"
          :y-axis-max="cdnRes.yAxisMax"
          :percentile="percentile"
          :chart-id="cdnRes.chartId"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SimpleBarChart, ChartWrapper } from '@/components/visual'
import DistributionScale from '@/components/visual/tools/DistributionScale'
import { DEFAULT_DISTRIBUTION_PERCENTILE } from '@/constants/constants'
import { metricToFullTitle } from '@/utils/titles'

export default {
  name: 'ChartBinnedCdnRes',
  components: {
    ChartWrapper,
    DistributionScale,
    SimpleBarChart
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      hover: false,
      percentile: DEFAULT_DISTRIBUTION_PERCENTILE
    }
  },
  computed: {
    ...mapGetters({
      chartDataCdnRes: 'charts/getDistributionChartsByCdnRes',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    chartXAxisInfo () {
      if (!this.chartMetric) return {}

      switch (this.chartMetric.subtype) {
        case 'enodebcellcountdist':
          return {
            title: `${metricToFullTitle(this.chartMetric)}`,
            unit: ''
          }
        case 'binnedvideothroughput':
          return {
            title: `${metricToFullTitle(this.chartMetric)} (Mbps)`,
            unit: 'Mbps'
          }
        case 'binnedvideodataconsumed':
          return {
            title: `${metricToFullTitle(this.chartMetric)} (MB)`,
            unit: 'MB'
          }
        case 'binnedcdnresvideoloadtime':
          return {
            title: `${metricToFullTitle(this.chartMetric)} (s)`,
            unit: 's'
          }
        case 'binnedcdnresvideoexperience':
          return {
            title: `${metricToFullTitle(this.chartMetric)} 0-45 for 360p, 0-75 for 720p, 0-87.5 for 1080p, 0-97.5 for 4K`,
            unit: ''
          }
        default:
          return {
            title: this.chartDataCdnRes[0].xAxisLabel,
            unit: this.chartDataCdnRes[0].xAxisUnit
          }
      }
    }
  }
}
</script>
