import get from 'lodash.get'
import {
  METRIC_HERO_LIST,
  METRIC_CHART_INFO,
  METRIC_GROUP_LABEL,
  METRIC_GROUP_ALTERNATE_LABEL,
  CHART_MODIFIERS,
  FIVEG_METRIC_SUBTYPES
} from '@/constants/constants'

import ROUTES from '@/constants/routes'

function getMetricAndSufix (hero) {
  return hero ? hero.split('_') : []
}

function isHero (metricString) {
  const [ metric ] = getMetricAndSufix(metricString)
  return METRIC_HERO_LIST.includes(metric)
}

function toMainMetric (metric) {
  if (!metric) {
    return metric
  }

  if (metric.includes('5gmmwave')) {
    return metric
  }

  return metric.replace(/5g(.)*$/, '5g')
}

function getHeroOfChart (metricString) {
  const [ metric, sufix ] = getMetricAndSufix(metricString)
  if (METRIC_CHART_INFO[metric]) {
    let heroSufix = METRIC_CHART_INFO[metric].heroSufix ? METRIC_CHART_INFO[metric].heroSufix : sufix

    if (FIVEG_METRIC_SUBTYPES.includes(heroSufix)) {
      heroSufix = '5g'
    }

    return `${METRIC_CHART_INFO[metric].hero}_${heroSufix}`
  } else {
    return 'no_metric'
  }
}

function getChartOfHero (metricString) {
  const [ metric, sufix ] = getMetricAndSufix(metricString)
  const chartMetric = Object.keys(METRIC_CHART_INFO).find(key =>
    (METRIC_CHART_INFO[key].hero === metric &&
    (
      METRIC_CHART_INFO[key].parent === metric ||
      METRIC_CHART_INFO[key].parent === 'infrastructure'
    )) ||
    (metric === 'gamesexperience' && key === 'cpgamesrtt')
  )

  return `${chartMetric}_${sufix}`
}

function getRawSiblings (metric) {
  return METRIC_CHART_INFO[getMetricAndSufix(metric)[0]].siblings
}

function getSiblingsOfChart (metricString) {
  const [ metric, sufix ] = getMetricAndSufix(metricString)
  const { hero, parent, siblings } = METRIC_CHART_INFO[metric]

  return siblings.map(i => {
    switch (i) {
      case 'trend':
        return `${parent}_${sufix}`
      case 'hourly':
      case 'cp':
      case 'cpip':
      case 'cdn':
      case 'ip':
      case 'cdnresip':
      case 'cdnres':
      case 'binned':
      case 'binnedcdnres':
      case 'consistency':
      case 'peak':
        return `${i}${parent}_${sufix}`
      case 'throughput':
        return `binnedvideo${i}_${sufix}`
      case 'consumed':
        return `binnedvideodata${i}_${sufix}`
      case 'enodeb':
      case 'cell':
      case 'enodebcellcountdist':
      case 'cellbanddist':
      case 'categorydownload':
      case 'categorypeakdownload':
      case 'categoryupload':
      case 'categorypeakupload':
      case 'categorylatency':
      case 'categorypeaklatency':
      case 'hourlydownloadstallingoccurrence':
      case 'downloadstallingoccurrence':
      case 'voicertt':
      case 'voicepacketloss':
      case 'gamesrtt':
      case 'gamespacketloss':
        return `${i}_${sufix}`
      case 'hourlypoorsignalquality':
      case 'poorsignalquality':
      case 'hourlypoorsignallevel':
      case 'poorsignallevel':
        return `${i}_lte`
      case 'frdownload':
      case 'frupload':
        return `${i}_5g`
      case 'mmdownload':
      case 'mmpeakdownload':
      case 'mmupload':
      case 'mmpeakupload':
        if (parent.includes('ios')) return `iosmodel${i.substring(2)}_${sufix}`
        return `androidmodel${i.substring(2)}_${sufix}`
      case 'cpops':
        return `cp${parent}_${sufix}`
      case 'cdnops':
        return `cdn${hero}_${sufix}`
      case 'cdnresops':
        return `cdnres${parent}_${sufix}`
      default:
        return `${i}${hero}_${sufix}`
    }
  })
}

function getParentOfChart (metricString) {
  const [ metric, sufix ] = getMetricAndSufix(metricString)
  const metricConfig = METRIC_CHART_INFO[metric] || {}
  if (!metricConfig.parent) {
    return `${metric}_${sufix}`
  }
  return `${metricConfig.parent}_${sufix}`
}

function isChartInMenu (metricString) {
  return METRIC_CHART_INFO[getMetricAndSufix(metricString)[0]].menu
}

function findMetricGroupForRouteName (metric) {
  if (metric.includes('category')) return 'category'
  if (metric.includes('model')) return 'model'
  if (metric.includes('enodebcellcountdist')) return 'enodebcellcountdist'
  if (metric.includes('enodeb')) return 'enodeb'
  if (metric.includes('cellbanddist')) return 'cellbanddist'
  if (metric.includes('cell')) return 'cell'
  if (metric.includes('throughput')) return 'throughput'
  if (metric.includes('consumed')) return 'consumed'
  if (metric.includes('binnedcdnres')) return 'binnedcdnres'
  if (metric.includes('cdnresip')) return 'cdnresip'
  if (metric.includes('cdnres')) return 'cdnres'
  if (metric.includes('cdn')) return 'cdn'
  if (metric.includes('cpip')) return 'cpip'
  if (metric.includes('cp')) return 'cp'
  if (metric.includes('binned')) return 'binned'
  if (metric.includes('hourly')) return 'hourly'
  if (metric.includes('peak')) return 'peak'
  if (metric.includes('consistency')) return 'consistency'
  if (metric.includes('ip')) return 'ip'
  if (metric.includes('frdownload')) return 'ranges'
  if (metric.includes('frupload')) return 'ranges'

  return 'trend'
}

function findAlternateMetricGroupForRouteName (metric) {
  if (metric.includes('cdnres')) return 'cdnresops'
  if (metric.includes('cp')) return 'cpops'
  if (metric.includes('cdn')) return 'cdnops'
}

function findMetricTypeForTabLabel (metric) {
  const specialMetrics = [
    // unique labels
    'hourlypoorsignalquality',
    'poorsignalquality',
    'hourlypoorsignallevel',
    'poorsignallevel',
    'hourlydownloadstallingoccurrence',
    'downloadstallingoccurrence',
    'categorydownload',
    'categorypeakdownload',
    'categoryupload',
    'categorypeakupload',
    'categorylatency',
    'categorypeaklatency',
    'enodebcellcountdist',
    'enodeb',
    'cellbanddist',
    'cell',
    'voicertt',
    'voicepacketloss',
    // 'gamesrtt',
    // 'gamespacketloss',

    // pattern based labels
    'throughput',
    'consumed',
    'binnedcdnres',
    'cpops',
    'cpip',
    'cdnresip',
    'ip',
    'cdnops',
    'cdnres',
    'cdn',
    'cp',
    'binned',
    'hourly',
    'consistency'
  ]

  const special = specialMetrics.find(m => metric.includes(m))
  if (special) return special

  if (metric.includes('modeldownload')) return 'mmdownload'
  if (metric.includes('modelpeakdownload')) return 'mmpeakdownload'
  if (metric.includes('modelupload')) return 'mmupload'
  if (metric.includes('modelpeakupload')) return 'mmpeakupload'

  if (metric.includes('peakdownload')) return 'peakspeed'
  if (metric.includes('peakupload')) return 'peakspeed'
  if (metric.includes('peaklatency')) return 'minimumlatency'
  if (metric.includes('frdownload')) return 'frdownload'
  if (metric.includes('frupload')) return 'frupload'

  return 'trend'
}

function findAlternateMetricTypeForTabLabel (metric) {
  if (metric.includes('cdnres')) return 'cdnresops'
  if (metric.includes('cp')) return 'cpops'
  if (metric.includes('cdn')) return 'cdnops'
}

function findPerfomanceRoute (metric, isAlternate) {
  if (isAlternate) {
    switch (findAlternateMetricGroupForRouteName(metric)) {
      case 'cpops':
        return ROUTES.PerformanceIntelligenceCpOps
      case 'cdnops':
        return ROUTES.PerformanceIntelligenceCdnOps
      case 'cdnresops':
        return ROUTES.PerformanceIntelligenceCdnResOps
      default:
        return ROUTES.PlainJson
    }
  }

  switch (findMetricGroupForRouteName(metric)) {
    case 'enodeb':
    case 'cell':
    case 'trend':
    case 'peak':
    case 'consistency':
      return ROUTES.PerformanceIntelligenceSimple
    case 'cp':
      return ROUTES.PerformanceIntelligenceCp
    case 'cpip':
      return ROUTES.PerformanceIntelligenceCpIp
    case 'cdnresip':
      return ROUTES.PerformanceIntelligenceCdnResIp
    case 'cdn':
      return ROUTES.PerformanceIntelligenceCdn
    case 'ip':
      return ROUTES.PerformanceIntelligenceIp
    case 'binned':
      return ROUTES.PerformanceIntelligenceBinned
    case 'hourly':
      return ROUTES.PerformanceIntelligenceHourly
    case 'cdnres':
      return ROUTES.PerformanceIntelligenceCdnRes
    case 'binnedcdnres':
    case 'consumed':
    case 'throughput':
      return ROUTES.PerformanceIntelligenceBinnedCdnRes
    case 'cellbanddist':
      return ROUTES.PerformanceIntelligenceDist
    case 'enodebcellcountdist':
      return ROUTES.PerformanceIntelligenceBar
    case 'category':
      return ROUTES.PerformanceIntelligenceCombCat
    case 'model':
      return ROUTES.PerformanceIntelligenceNByN
    case 'ranges':
      return ROUTES.PerformanceIntelligenceRanges
    default:
      return ROUTES.PlainJson
  }
}

// TODO refactor group labels to take duplication
// and remove function use
function getGroupLabel (metric, alternateLabel) {
  if (alternateLabel) {
    return METRIC_GROUP_ALTERNATE_LABEL[findAlternateMetricTypeForTabLabel(metric)]
  }
  return METRIC_GROUP_LABEL[findMetricTypeForTabLabel(metric)]
}

// change the labels of LTE to 4G
function toTypeLabel (type) {
  switch (type.toUpperCase()) {
    case 'LTE':
      return '4G'
    case '3GLTE':
      return '3G/4G'
    default:
      return type.toUpperCase()
  }
}

function getChartModifier (metric) {
  return metric && CHART_MODIFIERS[metric.split('_')[0]]
}

function getUnit (metric) {
  return get(metric, ['units', 'short'], '')
}

function getMetric (metrics, key) {
  return metrics.find(m => m.key === key) || {}
}

function getUserDefaultMetricType () {
  return window.localStorage.getItem('os_default_metric_type')
}
function setUserDefaultUserGroup (type) {
  window.localStorage.setItem('os_default_user_group', type)
}
function getUserDefaultUserGroup () {
  return window.localStorage.getItem('os_default_user_group')
}

export {
  isHero,
  getHeroOfChart,
  getRawSiblings,
  getSiblingsOfChart,
  getParentOfChart,
  getChartOfHero,
  isChartInMenu,
  findPerfomanceRoute,
  getGroupLabel,
  toTypeLabel,
  getChartModifier,
  getUnit,
  getMetric,
  findMetricGroupForRouteName,
  getUserDefaultMetricType,
  getUserDefaultUserGroup,
  setUserDefaultUserGroup,
  toMainMetric
}
