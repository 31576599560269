<template>
  <ChartWrapper
    :picture-meta="pictureMeta"
    :selected-point="selectedPoint"
    :loading="chartPending"
    :empty="chartData.empty"
    :tooltip="chartData.tooltip"
    :unit="chartUnit"
    :title-labels="titleLabels"
    :show-confidence-rate="false"
    title="Distribution"
    toggle-layer
    @toggle="chartToggle = !chartToggle"
  >
    <group-bar-chart
      v-if="chartData.loaded"
      :data-set="trimmedData.data"
      :labels="trimmedLabels"
      :x-axis-max-tick-value="chartData.xAxisMaxTickValue"
      :x-axis-label="chartXAxisInfo.title"
      :x-axis-unit="chartXAxisInfo.unit"
      :max="chartData.max"
      :min="0"
      :rainbow="chartToggle"
      :bar-labels="chartNumbers"
      :show-confidence-rate="false"
      y-axis-label="Proportion of devices (%)"
      chart-id="bar"
      show-vertical-labels
    />
  </ChartWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { GroupBarChart, ChartWrapper } from '@/components/visual'
import { metricToFullTitle } from '@/utils/titles'

export default {
  name: 'ChartBar',
  components: {
    ChartWrapper,
    GroupBarChart
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/getPrimaryHourlyChart',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      currentLocation: 'locations/current',
      selectedNetworks: 'charts/selectedNetworkOperators',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    trimmedLabels () {
      return this.chartData.label && this.chartData.label.slice(1, 22)
    },
    trimmedData () {
      return {
        ...this.chartData,
        data: this.chartData.data.map(i => ({
          ...i,
          data: i.data.slice(1, 22)
        }))
      }
    },
    chartXAxisInfo () {
      if (!this.chartMetric) return {}

      switch (this.chartMetric.subtype) {
        case 'enodebcellcountdist':
          return {
            title: `${metricToFullTitle(this.chartMetric)}`,
            unit: ''
          }
        default:
          return {
            title: this.chartDataCdnRes[0].xAxisLabel,
            unit: this.chartDataCdnRes[0].xAxisUnit
          }
      }
    }
  }
}
</script>

<style lang="css">
</style>
