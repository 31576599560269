<template>
  <DataTable
    :columns="headers"
    :data="formattedSubmarkets"
    default-sorting-column="rank"
    default-sorting-order="asc"
  >
    <template #default="{ headerGroups, rows, sortBy, isSortingBy, isSortingOrderDesc }">
      <div class="table-wrapper">
        <table class="table table-scrollable">
          <thead class="table__head">
            <!-- First row should be small for styling porpuses -->
            <tr
              v-for="(group, index) in headerGroups"
              :key="group.key"
              class="table__head__row"
              :class="{ 'table__head__row--small': index === 0 }"
            >
              <th
                v-for="header in group.headers"
                :key="header.key"
                class="table__header"
                :class="{
                  'name-column': header.key === ColumnKeys.NAME,
                  'parent-header': Boolean(header.children) && index !== 0,
                  'strategic-position': header.key === ColumnKeys.STRATEGIC_POSITION,
                }"
                v-bind="header.props"
                @click="!isNetworkColumn(header.key) ? sortBy(header.key) : null"
              >
                <div class="header">
                  <template v-if="!isNetworkColumn(header.key)">
                    <span>{{ header.label }}</span>
                    <div
                      v-if="header.key !== ColumnKeys.STRATEGIC_POSITION"
                      class="header__icons"
                    >
                      <FontAwesomeIcon
                        :icon="icons.faAngleUp"
                        size="lg"
                        class="header__sort-up-icon"
                        :class="{ active: isSortingBy(header.key) && !isSortingOrderDesc() }"
                      />
                      <FontAwesomeIcon
                        :icon="icons.faAngleDown"
                        size="lg"
                        :class="{ active: isSortingBy(header.key) && isSortingOrderDesc() }"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <OperatorAvatar
                      :key="header.label"
                      :letter="header.label[0]"
                      :background-color="`#${getNetworkInfo(header.label).hex_color}`"
                      class="header__operator-avatar"
                    />
                    <span
                      class="header__operator-name"
                      :class="{ 'header__operator-name--title': index === 1}"
                    >
                      {{ header.label }}
                    </span>
                  </template>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="table__body">
            <div
              v-if="isListEmpty(rows)"
              class="table__empty"
            >
              <NoData />
            </div>
            <tr
              v-for="item in rows"
              v-else
              :key="item.key"
              class="table__row"
            >
              <td
                v-for="cell in item.cells"
                :key="cell.key"
                class="table__data"
                :class="{
                  'name-column': cell.key === ColumnKeys.NAME,
                  clickable: isNetworkColumn(cell.key) && Boolean(cell.value) && isStrategySelected(cell.value)
                }"
                @click="cell.value && isNetworkColumn(cell.key) && isStrategySelected(cell.value) ? strategyClickHandler(cell) : null"
              >
                <span v-if="cell.key === ColumnKeys.POPULATION">{{ $filters.numberFormat(cell.value) }}</span>
                <span v-else-if="!isNetworkColumn(cell.key)">{{ cell.value }}</span>
                <Indicator
                  v-else
                  :strategy="cell.value"
                  :label="cell.value && isStrategySelected(cell.value) ? cell.value[0] : null"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </DataTable>
</template>

<script>
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapGetters } from 'vuex'
import DataTable from './DataTable'
import Indicator from './Indicator'
import NoData from '@/components/NoData'
import OperatorAvatar from '@/components/OperatorAvatar'
import ROUTES from '@/constants/routes'
import router from '@/router'

const ColumnKeys = {
  RANK: 'rank',
  NAME: 'name',
  TYPE: 'type',
  STRATEGIC_POSITION: 'strategic_position',
  POPULATION: 'population'
}

export default {
  name: 'MarketGrowthOverviewList',
  components: {
    DataTable,
    Indicator,
    FontAwesomeIcon,
    NoData,
    OperatorAvatar
  },
  props: {
    dashboardInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      icons: {
        faAngleUp,
        faAngleDown
      },
      isLoading: true,
      ColumnKeys
    }
  },
  computed: {
    ...mapGetters({
      formattedSubmarkets: 'marketGrowth/overviewFormattedSubmarkets',
      marketGrowthConfig: 'marketGrowthConfig'
    }),
    brands () {
      return this.dashboardInfo.brands || []
    },
    formattedBrands () {
      return this.brands.map(brand => ({
        label: brand.name_mapped,
        key: brand.canonical_network_id,
        children: brand.competitors.map(competitor => ({
          label: competitor.name_mapped,
          key: `${brand.canonical_network_id}-${competitor.canonical_network_id}`
        }))
      }))
    },
    headers () {
      return [
        {
          label: 'Superset Rank',
          key: ColumnKeys.RANK
        },
        {
          label: 'Market',
          key: ColumnKeys.NAME
        },
        {
          label: 'Type',
          key: ColumnKeys.TYPE
        },
        {
          label: 'Strategic Position',
          key: ColumnKeys.STRATEGIC_POSITION,
          children: this.formattedBrands
        },
        {
          label: 'Population',
          key: ColumnKeys.POPULATION
        }
      ]
    },
    strategies () {
      return router.currentRoute.value.params.strategy ? router.currentRoute.value.params.strategy.split('') : []
    }
  },
  watch: {
    async dashboardInfo () {
      await this.fetchMarketGrowthOverviewData()
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions([
      'fetchMarketGrowthOverviewData'
    ]),
    isStrategySelected (strategy) {
      return this.strategies.includes(strategy[0].toUpperCase())
    },
    isNetworkColumn (columnName) {
      return ![ColumnKeys.RANK, ColumnKeys.NAME, ColumnKeys.POPULATION, ColumnKeys.TYPE, ColumnKeys.STRATEGIC_POSITION].includes(columnName)
    },
    strategyClickHandler (cell) { // brand-competitor. eg 3020-30213
      const [brandId, competitorId] = cell.key.split('-')
      this.$router.push({
        name: ROUTES.MarketGrowthDetails,
        params: {
          country: router.currentRoute.value.params.country || 'CAN',
          granularity: cell.meta.granularityId,
          metric: this.marketGrowthConfig.metric,
          strategy: cell.value[0].toUpperCase(),
          brand: brandId,
          competitor: competitorId
        }
      })
    },
    getNetworkInfo (name) {
      let network = this.brands.find(brand => brand.name_mapped === name)
      if (network) {
        return network
      }
      this.brands.forEach(brand =>
        brand.competitors.forEach(competitor => {
          if (competitor.name_mapped === name) {
            network = competitor
          }
        })
      )
      return network
    },
    isListEmpty (rows) {
      return rows.length === 0 && !this.isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
@import "scss/variables";
@import "scss/components";

$header-height: 50px;
$export-height: 70px;
$table-extra-height: $header-height + $export-height;

.list-wrapper {
  height: 100%;
  .table-wrapper {
    height: calc(100vh - 70px);
    border: none;
  }
  .export {
    height: $export-height;
    display: flex;
    justify-content: center;
    align-items: center;
    &__button {
      height: 50px;
      width: 290px;
      background: $active-mg;
      border: none;
      font-size: $font-size-12;
    }
  }
}
.table {
  background: $mg-base;
  height: 100%;
  &__head {
    height: 120px;
    border: none;
    &__row {
      &--small {
        height: auto;

        .header {
          margin-bottom: -20px;
          position: relative;
        }
        .strategic-position {
          text-transform: uppercase;
          div{
            z-index: 1;
          }
          span {
            font-size: 14px;
            opacity: .5;
            font-style: italic;
          }
        }
      }
    }
  }
  &__header {
    cursor: default;
    user-select: none;
    padding: 0;
    margin: 0;
    font-size: $font-size-12;
    font-weight: $font-weight-roboto-light;
    text-align: center;
    color: white;
    background: $mg-base;
  }
  &__body {
    display: block;
    height: calc(100vh - #{$table-extra-height});
    overflow-y: scroll;
    border: none;
  }
  &__data {
    text-align: center;
  }
  &__row {
    height: 53px;
    font-size: $font-size-12;
    font-weight: $font-weight-roboto-regular;
  }
  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.parent-header {
  position: relative;
  &:after {
    content: "";
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    left: 5%;
    width: 90%;
    border-bottom:1px solid #D8D8D8;
    opacity: 0.2;
  }
}

.name-column {
  width: 200px;
  padding: 0;
}

.table,
.table__header,
.table__data {
  border-top: 1px solid $color-gray-light;
  border-collapse: collapse;
}
.table,
.table__header {
  border-top: none;
}
.header {
  align-items: center;
  justify-content: center;
  position: relative;
  &__icons {
    margin-top: -20px;
    right: 0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    svg {
      color: $mg-sort-symbol;
    }
    .active {
      color: $mg-sort-active;
    }
  }
  &__sort-up-icon {
    margin-bottom: -10px;
  }
  &__operator-avatar {
    display: inline-block;
  }
  &__operator-name {
    margin-left: 5px;
    font-weight: 400;
    &--title {
      text-transform: uppercase;
      font-style: italic;
    }
  }
}
// Make table scrollable with fixed header
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
thead {
  width: calc(100% - 1em); /* scrollbar is 1em/16px width */
}
.clickable {
  cursor: pointer;
  &:hover {
    background: #F5F0E7;
  }
}
</style>
