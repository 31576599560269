<template>
  <div
    v-if="dashboardInfo.data_last_updated"
    class="PerfomanceMenu"
    :class="{ 'PerfomanceMenu--collapsed': !isMenuVisible }"
  >
    <MenuToggle />

    <dashboard-switch
      :products="products"
      :current="options.dashboard"
      @navigate="navigateToDashboard"
      @signout="signOut"
    />

    <NavigationButton
      v-if="displayOverviewButton"
      class="PerfomanceMenu__toggle"
      :class="toggleClass"
      @click="toggleViews"
    >
      <template #icon>
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-if="isOverview" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#fff" stroke-width="1.5"><path d="M10 18a8 8 0 100-16M10 15a5 5 0 10-5-5M8 10a2 2 0 102-2"/></g></svg>
        <svg width="48" height="48" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-else-if="technologyName === '5G'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-270%" y="-270%" width="640%" height="640%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="36" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.342877956 0 0 0 0 0.822067482 0 0 0 0 0.676614193 0 0 0 0.839515953 0" in="shadowBlurOuter1"/></filter><rect id="b" x="7" y="7" width="40" height="40" rx="20"/></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/><g fill="#99B1C8" font-family="Roboto" font-size="17" font-weight="bold"><text transform="translate(15 15)"><tspan x="0" y="18.4">5G</tspan></text></g></g></svg>
        <svg width="48" height="48" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><filter x="-270%" y="-270%" width="640%" height="640%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="36" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.342877956 0 0 0 0 0.822067482 0 0 0 0 0.676614193 0 0 0 0.839515953 0" in="shadowBlurOuter1"/></filter><rect id="b" x="7" y="7" width="40" height="40" rx="20"/></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/><g fill="#99B1C8" font-family="Roboto" font-size="17" font-weight="bold"><text transform="translate(15 15.4)"><tspan x="0" y="18">4G</tspan></text></g></g></svg>
      </template>
      <template v-if="isOverview">
        Drill Down
      </template>
      <template v-else>
        <span>See {{ technologyName }} Overview</span>
      </template>
    </NavigationButton>

    <div class="SegmentationMenu__content">
      <menu-title label="Parameters" />
      <div class="ParametersLayout">
        <div class="ParametersLayout__grid">
          <div class="ParametersLayout__geographies">
            <FieldGroup label="Geographies">
              <LocationSearch
                :enable-group-navigation="isOverview"
                :display-top-locations-only="isOverview"
                @navigate="selectLocation"
                @navigateGeocoding="selectGeocoding"
              />
            </FieldGroup>
          </div>
          <div class="ParametersLayout__end-date">
            <FieldGroup
              :tooltip="datePickerTooltipMessage"
              label="End Date"
            >
              <div>
                <flatpickr
                  :min="parsedFirstDateAvailable"
                  :max="parsedLastDateAvailable"
                  :date="parsedCurrentDate"
                  :disable="filterEndDates"
                  @selected="choseNewDate"
                />
              </div>
            </FieldGroup>
          </div>
          <div class="ParametersLayout__aggregation">
            <FieldGroup
              slot="aggregation"
              :tooltip="aggregationTooltipMessage"
              :disabled="aggregationOptions.length < 2"
              label="Aggregation"
            >
              <SelectInput
                :tooltip="aggregationTooltipMessage"
                :options="aggregationOptions"
                :selected-value="aggregation"
                @select="selectAggregation"
              />
            </FieldGroup>
          </div>
        </div>
      </div>
      <template v-if="!isOverview">
        <menu-title label="Operators &amp; Visualisation" />
        <OperatorsSelector v-model="currentNetworks" />
        <SwitchInput
          v-model="confidenceIntervals"
          label="View Confidence Intervals"
          tooltip="Show/hide confidence intervals for metrics, where applicable."
        />
        <menu-title label="Categories" />
        <CategoriesTabs
          v-if="licences.length"
          v-model="currentCategory"
          :licences="licences"
          :dashboards="dashboards"
        />
        <MetricsSelector
          v-model="currentMetric"
          :lte-only="lteOnly"
          :licences="licences"
          :category="currentCategory"
        />
      </template>
      <template v-else>
        <MetricsSelector
          v-model="currentMetric"
          :lte-only="lteOnly"
          :licences="licences"
          :category="currentCategory"
          slim
        />
      </template>
    </div>
  </div>
</template>

<script>
import { max, subDays, endOfToday, subMonths } from 'date-fns'
import { mapGetters } from 'vuex'
import DashboardSwitch from './DashboardSwitch'
import LocationSearch from '@/components/location-search/LocationSearch'
import {
  Flatpickr,
  FieldGroup,
  MenuTitle,
  OperatorsSelector,
  SwitchInput,
  MenuToggle,
  SelectInput
} from '@/components/menu'
import NavigationButton from '@/components/menu/NavigationButton'
import CategoriesTabs from '@/components/performance/CategoriesTabs'
import MetricsSelector from '@/components/performance/MetricsSelector'
import {
  AGGREGATIONS,
  WEEKLY_METRICS_CATEGORIES,
  PI_METRICS_MENU,
  PI_OVERVIEW_METRICS_MENU,
  METRIC_TYPES,
  FIVEG_METRICS_TYPES
} from '@/constants/constants'
import ROUTES from '@/constants/routes'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'

export default {
  name: 'PerfomanceMenu',
  components: {
    DashboardSwitch,
    Flatpickr,
    FieldGroup,
    MenuTitle,
    OperatorsSelector,
    MetricsSelector,
    LocationSearch,
    CategoriesTabs,
    SwitchInput,
    MenuToggle,
    SelectInput,
    NavigationButton
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    licences: {
      type: Array,
      default: () => []
    },
    dashboards: {
      type: Array,
      default: () => []
    },
    networks: {
      type: Array,
      default: () => []
    },
    confidence: {
      type: Boolean,
      default: true
    },
    dashboardInfo: {
      type: Object,
      default: () => {}
    },
    agg: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    metric: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    },
    lteOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      aggregations: AGGREGATIONS,
      aggregationTooltipMessage: TOOLTIP_MESSAGES.aggregation,
      datePickerTooltipMessage: TOOLTIP_MESSAGES.datePicker,
      weeklyMetricsCategories: WEEKLY_METRICS_CATEGORIES,
      setCategory: null,
      metrics: PI_METRICS_MENU
    }
  },
  computed: {
    ...mapGetters({
      isPrimaryMetricWeekly: 'dashboard/isPrimaryMetricWeekly',
      mainMetric: 'metrics/primaryMetric',
      category: 'metrics/category',
      isMenuVisible: 'dashboard/isMenuVisible',
      homeNetwork: 'charts/homeNetwork',
      availableMetricCategories: 'metrics/availableMetricCategories',
      defaultTechnology: 'metrics/defaultTechnology'
    }),
    technology () {
      return this.mainMetric.type
    },
    isOverview () {
      return router.currentRoute.value.name === ROUTES.PerformanceIntelligenceOverview
    },
    toggleIcon () {
      if (this.isOverview) {
        return '../../assets/icons/drilldown.svg'
      }
      return '../../assets/icons/5goverview.svg'
    },
    confidenceIntervals: {
      get () {
        return this.confidence
      },
      set (value) {
        this.$emit('confidence', value)
      }
    },
    currentNetworks: {
      get () {
        return this.networks
      },
      set (n) {
        this.$emit('toggleOperator', n.id)
      }
    },
    currentMetric: {
      get () {
        return this.metric
      },
      set (metric) {
        if (metric.key) {
          this.update({ metric: metric.key, keepSameRoute: true })
        } else {
          this.update({ metric, keepSameRoute: true })
        }
      }
    },
    currentCategory: {
      get () {
        return this.category
      },
      set (cat) {
        this.setCategory = cat

        if (cat === 'maps') {
          this.$router.push('/performance-pro')
        } else {
          let displayMenuItems = this.metrics.filter(menuItem => {
            const hasLicence = !menuItem.metricCategory || this.availableMetricCategories().includes(menuItem.metricCategory)
            return menuItem.category === cat && hasLicence
          })

          if (displayMenuItems.length && cat === 'infrastructure') {
            this.update({ agg: '90days', metric: displayMenuItems[0].main + '_lte' })
          } else if (displayMenuItems.length && cat === 'devices') {
            this.update({ metric: displayMenuItems[0].main + '_lte' })
          } else if (displayMenuItems.length) {
            this.update({ metric: displayMenuItems[0].main + '_' + this.defaultTechnology })
          } else if (cat === 'infrastructure') {
            this.update({ agg: '90days' })
          }
        }
      }
    },
    focusedItem: {
      get () {
        return this.locations.find(i => `${i.id}` === this.location)
      },
      set (location) {
        this.update({ location: `${location.id}` })
      }
    },
    aggregationOptions () {
      if (this.currentCategory === 'infrastructure') {
        return this.aggregations.filter(cat => cat.value === '90days')
      }

      return this.aggregations
    },
    aggregation: {
      get () {
        return this.agg
      },
      set (agg) {
        this.update({ agg })
      }
    },
    parsedFirstDateAvailable () {
      const HARD_FIRST_DATE_AVAILABLE = '2018-05-31T00:00:00.000Z'
      const minPossibleDate = subDays(subMonths(endOfToday(), 6), 5) // 6 months + 5 days
      const firstDateAvailable = this.dashboardInfo.first_date_available || HARD_FIRST_DATE_AVAILABLE

      return max([
        new Date(HARD_FIRST_DATE_AVAILABLE),
        new Date(firstDateAvailable),
        minPossibleDate
      ])
    },
    parsedLastDateAvailable () {
      const lastDateAvalailable = this.dashboardInfo.last_date_available
      return lastDateAvalailable && new Date(lastDateAvalailable)
    },
    parsedCurrentDate () {
      return this.date && new Date(this.date)
    },
    filterEndDates () {
      if (this.isPrimaryMetricWeekly) {
        return [
          function (date) {
            return (date.getDay() !== 3)
          }
        ]
      }

      return undefined
    },
    displayOverviewButton () {
      const isOverviewMetric = () => {
        const metricKinds = PI_OVERVIEW_METRICS_MENU.map(metric => metric.kind)
        return metricKinds.includes(this.mainMetric.kind)
      }
      if (!this.homeNetwork || !this.dashboardInfo.pi_overview_page || this.technology === METRIC_TYPES.ThreeG || this.technology === METRIC_TYPES.Mmwave) {
        return false
      }
      if (this.isOverview) {
        return true
      }
      if (FIVEG_METRICS_TYPES.includes(this.technology) && this.dashboardInfo.pi_overview_page !== '5G') {
        return false
      }
      return isOverviewMetric()
    },
    toggleClass () {
      if (this.isOverview) {
        return ''
      }
      return 'PerformanceMenu__toggle--details'
    },
    technologyName () {
      if (this.technology === METRIC_TYPES.FiveG) {
        return '5G'
      }
      return '4G'
    },
    technologyIcon () {
      if (this.technology === METRIC_TYPES.FiveG) {
        return require('../../assets/icons/5goverviewcircle.svg')
      }
      return require('../../assets/icons/4goverviewcircle.svg')
    }
  },
  methods: {
    navigateToDashboard (mode) {
      this.update({ mode })
    },
    signOut () {
      this.$emit('signout')
    },
    choseNewDate (date) {
      this.update({ date, keepSameRoute: true })
    },
    selectAggregation (agg) {
      this.update({ agg, keepSameRoute: true })
    },
    selectGeocoding (geocoding) {
      this.update({
        route: router.currentRoute.value.name,
        geocoding: String(geocoding),
        keepSameRoute: true
      })
    },
    selectLocation (location) {
      this.update({
        location,
        geocoding: 2, // Set geocoding to regions
        keepSameRoute: true
      })
    },
    toggleViews () {
      if (!this.isOverview) {
        this.update({
          route: ROUTES.PerformanceIntelligenceOverview,
          keepSameRoute: false
        })
      } else {
        this.update({
          keepSameRoute: false
        })
      }
    },
    update ({
      mode = this.mode,
      layer = this.currentLayer,
      metric = this.metric,
      location = this.location,
      agg = this.agg,
      date = router.currentRoute.value.params.date,
      rank = this.rank,
      route = null,
      geocoding = null,
      keepSameRoute = false
    }) {
      this.$emit('navigate', {
        mode,
        layer,
        metric,
        location,
        agg,
        date,
        rank,
        route,
        geocoding,
        keepSameRoute
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.PerfomanceMenu {
  background-color: $color-nav-bg;
  height: auto;
  min-height: 100%;
  width: $sidebar-width;
  padding: $sidebar-padding;
  border-bottom-right-radius: $border-radius-medium;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;

  &:hover {
    .MenuToggle {
      opacity: 1;
    }
  }

  &--collapsed {
    .DashboardSwitch,
    .SegmentationMenu__content {
      opacity: 0;
    }
  }

  &__content {
    padding: 0 $padding-medium;
  }
  &__toggle {
    margin: 20px 0;
    background: $color-background-grey;
    color: white;
    position: relative;
    span {
      margin-top: 10px
    }
  }
  &__toggleIcon {
    height: 44px;
  }
}

.ParametersLayout {
  @include xy-grid-container($padding: 0);

  &__grid {
      @include xy-grid($direction: horizontal);
      justify-content: space-between;
  }

  &__geographies {
      @include xy-cell($gutters: 0)
  }

  &__end-date {
      @include xy-cell($gutters: 0);
  }

  &__aggregation {
      @include xy-cell($gutters: 0);
  }
}

.PerformanceMenu__toggle--details {
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep() {
    .nav-item__icon {
      position: absolute;
      top: -25px;
      right: 0;
      left: 0;
    }
  }
}
</style>
