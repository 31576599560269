import includes from 'lodash.includes'
import some from 'lodash.some'
import Vue from 'vue'

const types = {
  GET_LAST_DATE_AVAILABLE: 'auth/getLastDateAvailable',
  GET_PRODUCTS: 'auth/getProducts',
  GET_SUPPORTED_DASHBOARDS: 'auth/getSupportedDashboards',
  IS_LOGIN_ERROR: 'auth/isLoginError',
  IS_LOGIN_PENDING: 'auth/isLoginPending',
  IS_USER_PENDING: 'auth/isUserPending',
  LOAD_USER_ASYNC_FAILURE: 'auth/LOAD_USER_ASYNC_FAILURE',
  LOAD_USER_ASYNC_PENDING: 'auth/LOAD_USER_ASYNC_PENDING',
  LOAD_USER_ASYNC_SUCCESS: 'auth/LOAD_USER_ASYNC_SUCCESS',
  LOGIN_ASYNC_FAILURE: 'auth/LOGIN_ASYNC_FAILURE',
  LOGIN_ASYNC_PENDING: 'auth/LOGIN_ASYNC_PENDING',
  LOGIN_ASYNC_SUCCESS: 'auth/LOGIN_ASYNC_SUCCESS'
}

const state = () => ({
  loginError: null,
  loginPending: false,
  userError: null,
  userPending: false
})

const getters = {
  [types.IS_LOGIN_ERROR]: (state) => state.loginError,
  [types.IS_LOGIN_PENDING]: (state) => state.loginPending,
  [types.IS_USER_PENDING]: (state) => state.userPending,
  [types.GET_LAST_DATE_AVAILABLE]: (state, getters, rootState, rootGetters) => {
    return rootGetters.dashboardInfo.last_date_available
  },
  [types.GET_SUPPORTED_DASHBOARDS]: (state, getters, rootState, rootGetters) => {
    return rootGetters.user.dashboards || []
  },
  [types.GET_PRODUCTS]: (state, getters) => {
    const supportedDashboards = getters[types.GET_SUPPORTED_DASHBOARDS]
    const products = [
      {
        name: 'competitive',
        title: 'Competitive',
        label: ['Competitive', 'Intelligence'],
        theme: 'blue',
        keys: ['competitive'],
        route: 'competitive'
      },
      {
        name: 'performance',
        title: 'Performance',
        label: ['Performance', 'Intelligence'],
        theme: 'orange',
        keys: ['performance'],
        route: 'performance'
      },
      {
        name: 'market-growth',
        title: 'Market Growth Solutions',
        label: ['Market', 'Growth', 'Solutions'],
        keys: ['market-growth'],
        route: 'market-growth'
      }
    ]
    return products.map((product) => ({
      ...product,
      disabled: typeof product.disabled !== 'undefined' ? product.disabled : !some(product.keys.map((k) => includes(supportedDashboards, k)))
    }))
  }
}

const actions = {}

const mutations = {
  [types.LOAD_USER_ASYNC_PENDING]: (state) => {
    state.userPending = true
    state.userError = false
  },
  [types.LOAD_USER_ASYNC_SUCCESS]: (state) => {
    state.userPending = false
    state.userError = false
  },
  [types.LOAD_USER_ASYNC_FAILURE]: (state) => {
    state.userPending = false
    state.userError = true
  },
  [types.LOGIN_ASYNC_PENDING]: (state) => {
    state.loginPending = true
  },
  [types.LOGIN_ASYNC_SUCCESS]: (state) => {
    state.loginPending = false
    state.loginError = false
  },
  [types.LOGIN_ASYNC_FAILURE]: (state) => {
    state.loginPending = false
    state.loginError = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
