<template>
  <span
    class="MarketGrowthDelta"
    :class="cssClass"
  >
    {{ displayValue }}%
  </span>
</template>

<script>
export default {
  name: 'MarketGrowthDelta',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    displayValue () {
      return (this.value <= 0 ? '' : '+') + this.value
    },
    cssClass () {
      return {
        'MarketGrowthDelta--positive': this.value > 0,
        'MarketGrowthDelta--negative': this.value < 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MarketGrowthDelta {
  &--positive {
    color: $color-positive-green;
  }
  &--negative {
    color: $color-negative-red;
  }
}
</style>
