<template>
  <div class="PerformanceTabsPanel is-active">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PerformanceTabsPanel',
  props: { }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .PerformanceTabsPanel {
    @include tabs-panel;
    padding: 0;
  }
</style>
