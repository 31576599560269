<template>
  <tileTooltip
    :content="'Export this view as image'"
  >
    <template #tooltipAnchor>
      <div
        class="HtmlImageExport"
        data-html2canvas-ignore
      >
        <a
          ref="print"
          :download="`${title}.png`"
          @click="print"
        >
          <FontAwesomeIcon
            v-show="!isLoading"
            :icon="icons.faCamera"
            size="1x"
          />
          <loader-grid
            v-show="isLoading"
            theme="dark"
          />
        </a>
        <img
          ref="watermark"
          class="HtmlImageExport__watermark"
          :src="icons.competitive"
        >
      </div>
    </template>
  </tileTooltip>
</template>

<script>
import { faCamera } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import TileTooltip from './comparison/TileTooltip'
import watermarkCompetitive from '@/assets/product/competitive-export.svg'
import LoaderGrid from '@/components/LoaderGrid'
import colors from '@/utils/colorPalette'

export default {
  name: 'HtmlImageExport',
  components: {
    FontAwesomeIcon,
    LoaderGrid,
    TileTooltip
  },
  props: {
    reference: {
      type: HTMLElement,
      default: () => {}
    },
    height: {
      type: Number,
      default: () => 900,
      validator: a => Number.isFinite(a)
    },
    title: {
      type: String,
      default: 'title'
    }
  },
  data () {
    return {
      debug: false,
      icons: {
        faCamera,
        competitive: watermarkCompetitive
      },
      // window.devicePixelRation enforced to 2 for retina like
      // rendering on any screen
      dpi: 2,
      footer: 16,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible'
    }),
    width () {
      return this.isMenuVisible ? 1350 : 1640
    }
  },
  methods: {
    async print () {
      this.isLoading = true
      if (!this.$refs.print.href) {
        let picture = document.createElement('canvas')
        picture.width = this.width * this.dpi
        picture.height = (this.height + this.footer) * this.dpi

        let ctx = picture.getContext('2d')

        this.drawBackgroundAndWatermark(ctx)

        let screenShot = new Image()
        screenShot.onload = () => {
          ctx.drawImage(screenShot, 0, 0, this.width * this.dpi, this.height * this.dpi)

          if (this.debug) {
            this.exportOnScreen(picture)
          } else {
            this.$refs.print.href = picture.toDataURL()
            this.$refs.print.click()
            this.$refs.print.removeAttribute('href')
          }
        }
        screenShot.src = await this.captureScreen()
      }
      this.isLoading = false
    },
    drawBackgroundAndWatermark (ctx) {
      ctx.fillStyle = '#DBDFEA'
      ctx.fillRect(0, 0, this.width * this.dpi, (this.height + this.footer) * this.dpi)

      let img = this.$refs.watermark
      ctx.drawImage(
        img,
        (this.width * this.dpi) - (295 * this.dpi),
        40 * this.dpi,
        img.clientWidth * this.dpi * 1.5,
        img.clientHeight * this.dpi * 1.5
      )

      ctx.font = `bold 32px Roboto`
      ctx.fillStyle = colors.colorBlueHeader
      ctx.textAlign = 'hanging'
      ctx.fillText(
        '© Copyright ' + (new Date().getYear() + 1900) + ' Opensignal',
        (this.width - 257) * this.dpi,
        this.height * this.dpi
      )
    },
    async captureScreen () {
      const data = await html2canvas(this.reference, {
        windowWidth: '1650px',
        windowHeight: `${this.height}px`,
        width: this.width,
        height: this.height,
        backgroundColor: 'transparent',
        scale: this.dpi
      })

      return data.toDataURL()
    },
    exportOnScreen (canvas) {
      canvas.style.position = 'fixed'
      canvas.style.top = '100px'
      canvas.style.left = '20px'
      canvas.style.zIndex = '100'
      canvas.style.boxShadow = '0 0 4px #000'
      let c = document.querySelector('body > canvas')
      if (c) {
        c.parentElement.removeChild(c)
      }
      document.body.appendChild(canvas)
    }
  }
}
</script>
<style lang="scss">
@import 'scss/variables';
@import 'scss/components';

.HtmlImageExport {
  border-radius: 0;
  border: none;
  background-color: $color-white;
  cursor: pointer;
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
  text-align: center;

  a {
    color: $color-text-dark-blue;
  }

  &:hover {
    background-color: $color-text-gold;

    a {
      color: $color-white;
    }
  }
  &__watermark {
    position: fixed;
    top: -100px;
  }
}
</style>
