<template>
  <PerformanceLayout
    v-if="ready"
    class="PerfomanceIntelligence"
  >
    <template #sidebar>
      <PerformanceMenu
        :products="products"
        :dashboard-info="dashboardInfo"
        :options="{dashboard: 'performance'}"
        :metric="menuMetric.key"
        :agg="$route.params.agg"
        :date="date"
        :networks="networks"
        :confidence="confidenceState"
        :licences="user.licences"
        :dashboards="user.dashboards"
        :lte-only="isMetricLteOnly"
        @navigate="navigateToChart"
        @toggleOperator="toggleOperator"
        @confidence="toggleConfidence"
        @signout="signOut"
      />
    </template>

    <template #header>
      <PerformanceHero
        :display-operator-colors="false"
        hero="yes"
        class="PerfomanceIntelligence__hero"
        slim
      />
    </template>

    <template #content>
      <div class="PerfomanceIntelligence__charts">
        <div
          v-if="!isMapEmpty"
          class="Details__Geographies"
        >
          <MetricGeographies
            style="margin:0"
            is-national-level-always
            display-home-network-name
            enable-scrolling
            display-national
            @selectLocation="selectLocation"
          />
        </div>

        <ChartWrapper
          :loading="isChartLoading"
          :empty="isMapEmpty"
          class="Details__mapWrapper"
          disable-title
          disable-export
        >
          <div class="Details__mapView">
            <ChoroplethMap
              v-model="currentRegion"
              :geo-json="shapes.geoJson"
              :geo-json-overlay="zoomedLayer"
              :choropleth-data="networkMapData"
              :choropleth-cities-data="networkCitiesMapData"
              :bigger-is-better="currentMetric.bigger_is_better"
              :colour-scale-labels="colourScaleLabels"
              :markers="displayMarkers"
              :zoomed="currentLocation.granularityId !== '1'"
              display-tooltip-on-the-right
              use-geo-ranking-colours
              @click="goToDetails"
            />
          </div>
        </ChartWrapper>
      </div>
    </template>
  </PerformanceLayout>
  <div
    v-else
    class="LoadingWrapper"
  >
    <loader-grid
      size="large"
      theme="dark"
    />
  </div>
</template>

<script>
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt
} from '@fortawesome/pro-regular-svg-icons'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import FiveGIcon from '@/assets/5g.svg'
import { MetricGeographies } from '@/components/comparison'
import LoaderGrid from '@/components/LoaderGrid'
import PerformanceLayout from '@/components/performance/PerformanceLayout'
import { PerformanceMenu } from '@/components/specialized'
import { ChartWrapper, ChoroplethMap } from '@/components/visual'
import {
  WEEKLY_METRICS_CATEGORIES,
  DAILY_LTE_ONLY_METRICS,
  ALTERNATE_CHART_VIEWS,
  ALTERNATE_ROUTES,
  PI_METRICS_MENU,
  LATEST_STRING,
  PI_OVERVIEW_METRICS_MENU
} from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import {
  getWeeklyDate
} from '@/utils/date'
import {
  getHeroOfChart,
  getChartOfHero,
  isHero,
  findPerfomanceRoute,
  getGroupLabel,
  getRawSiblings,
  getSiblingsOfChart
} from '@/utils/metrics'
import PerformanceHero from '@/views/performance/PerformanceHero'

export default {
  name: 'PerfomanceIntelligenceOverview',
  components: {
    MetricGeographies,
    PerformanceLayout,
    LoaderGrid,
    PerformanceMenu,
    PerformanceHero,
    ChartWrapper,
    ChoroplethMap
  },
  beforeRouteUpdate (to, from, next) {
    this.checkDisclaimer()

    // give the $router a way to know about all params
    this.setRouteParam(to.params)

    this.track({
      dashboard: 'performance',
      params: to.params,
      url: to.path,
      route: to.name
    })

    if (
      to.params.date === router.currentRoute.value.params.date &&
      to.params.location === router.currentRoute.value.params.location &&
      to.params.agg === router.currentRoute.value.params.agg &&
      to.params.hero === router.currentRoute.value.params.hero &&
      to.params.chart === router.currentRoute.value.params.chart &&
      to.params.geocoding === router.currentRoute.value.params.geocoding
    ) {
      next()
    } else {
      const category = this.category

      try {
        this.setDataOfParams(to.params).then(() => {
          if (category !== this.category) {
            this.resetOperators()
          }
        })
      } catch (e) {
        //
      }

      next()
    }
  },
  data () {
    return {
      ready: false,
      mode: 'performance',
      menuConfig: {
        dashboard: 'performance',
        metrics: PI_METRICS_MENU
      },
      icons: {
        acorn: faCompressArrowsAlt,
        corn: faExpandArrowsAlt,
        FiveGIcon
      },
      geocoding: '1', // TODO: Convert to prop
      mapLoading: false, // TODO: Extra work
      unit: 'xxx' // WTF, it was missing
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo',
      'locations',
      'metrics',
      'polygons',
      'user',
      'shapes',
      'cityShapes'
    ]),
    ...mapGetters({
      date: 'dashboard/date',
      winners: 'competitive/winners',
      displayOps: 'charts/selectedNetworkOperators',
      pending: 'chart/chartPending',
      compare: 'competitive/compare',
      mapEmpty: 'mapData/chartEmpty',
      cityMapEmpty: 'cityMapData/chartEmpty',
      currentMetric: 'metrics/primaryMetric',
      adjustableScale: 'dashboard/getAdjustableScale',
      chartMetric: 'metrics/primaryMetric',
      menuMetric: 'metrics/menuMetric',
      category: 'metrics/category',
      networkCitiesMapData: 'competitive/networkCitiesMapData',
      confidenceState: 'dashboard/getConfidenceState',
      heroMetric: 'metrics/supportingMetric',
      networkOperators: 'charts/networks',
      products: 'auth/getProducts',
      getRank: 'metrics/getRank',
      resolveLicence: 'metrics/getLicence',
      currentLocation: 'location/currentLocation',
      networkMapData: 'competitive/networkMapData',
      networkStats: 'competitive/networkStats',
      mapPending: 'mapData/chartPending',
      groupings: 'location/byGroupings',
      defaultGeocoding: 'competitive/defaultGeocoding'
    }),
    currentRegion: {
      get () {
        return parseInt(this.currentLocation.key)
      },
      set (region) {
        // We already have a selectLocation handler

      }
    },
    isMapEmpty () {
      const geocoding = router.currentRoute.value.params.geocoding
      if (geocoding === '3') {
        return this.cityMapEmpty
      }
      return this.mapEmpty
    },
    displayMarkers () {
      return this.currentGeocoding.prefer_points &&
        !this.citiesAsPolygons &&
        !(this.showRegionsAndCities && parseInt(this.currentGeocoding.id) < 4)
    },
    currentGeocoding () {
      return this.polygons.find(l => l.id === this.geocoding)
    },
    // Use city json data if there's no region data
    mapGeoJson () {
      return this.shapes.geoJson
    },
    zoomedLayer () {
      const geocoding = router.currentRoute.value.params.geocoding
      switch (this.currentLocation.granularityId) {
        case '1':
        case '2':
        case '3':
          if (geocoding === '2' || geocoding === '3') {
            return this.cityShapes.geoJson
          }
          return
        default:
          return this.shapes.geoJson
      }
    },
    networkMapDataAvailable () {
      return this.networkMapData.length || this.networkCitiesMapData.length
    },
    isChartLoading () {
      // TODO: debugging
      if (this.isMapEmpty) {
        return false
      }
      const basicCondition = this.mapPending || this.mapLoading
      const extraCondition = this.mapGeoJson && this.networkMapDataAvailable && this.locations.length
      return basicCondition || !extraCondition
    },
    networks () {
      return this.networkOperators.map(o => {
        return {
          letter: o.name_mapped[0],
          label: o.name_mapped,
          id: o.canonical_network_id,
          color: `#${o.hex_color}`,
          selected: o.selected
        }
      })
    },
    isMetricLteOnly () {
      return WEEKLY_METRICS_CATEGORIES.includes(this.chartMetric.category) ||
        DAILY_LTE_ONLY_METRICS.includes(router.currentRoute.value.params.chart)
    },
    tabActiveLabel () {
      return getGroupLabel(router.currentRoute.value.params.chart, ALTERNATE_ROUTES.includes(router.currentRoute.value.name))
    },
    tabs () {
      if (!metric || !router.currentRoute.value.params.chart) {
        return []
      }

      const siblings = getRawSiblings(router.currentRoute.value.params.chart)

      return getSiblingsOfChart(router.currentRoute.value.params.chart).map((metric, index) => {
        const label = getGroupLabel(metric, ALTERNATE_CHART_VIEWS.includes(siblings[index]))

        return {
          metric: metric,
          active: label === this.tabActiveLabel,
          label: label,
          isAlternate: ALTERNATE_CHART_VIEWS.includes(siblings[index])
        }
      })
    },
    colourScaleLabels: function () {
      const labelUnit = this.unit === '0 - 100' ? '' : this.unit
      return [
        `${this.networkStats.maxValue}${labelUnit}`,
        `${this.networkStats.median}${labelUnit}`,
        `${this.networkStats.minValue}${labelUnit}`
      ]
    }
  },
  mounted () {
    this.mapLoading = true

    this.setRouteParam(router.currentRoute.value.params)

    if (!this.user.id) {
      this.setUser().then(res => res && this.checkDisclaimer())
    }

    if (router.currentRoute.value.path === '/performance/' || router.currentRoute.value.path === '/performance') {
      this.setUserSettings(this.mode)
        .then(() => {
          let dashboardDefaultView = this.getDashboardDefaults()
          this.setAsCurrentLocation(`${this.dashboardInfo.countries_visible_full[0].id}`)
          this.setDataOfParams({
            hero: router.currentRoute.value.params.hero || dashboardDefaultView.hero,
            chart: router.currentRoute.value.params.chart || dashboardDefaultView.chart,
            location: `${this.dashboardInfo.countries_visible_full[0].id}`,
            agg: '90days',
            date: router.currentRoute.value.params.isWeekly ? getWeeklyDate(this.dashboardInfo.last_date_available, 3, this.dashboardInfo.first_date_available) : this.dashboardInfo.last_date_available
          })
        })
        .then(() => this.resetOperators())
        .then(() => {
          let dashboardDefaultView = this.getDashboardDefaults()
          router.replace({
            name: router.currentRoute.value.params.chart || dashboardDefaultView.chart ? findPerfomanceRoute(router.currentRoute.value.params.chart || dashboardDefaultView.chart) : ROUTES.PerformanceIntelligenceCdn,
            params: {
              hero: router.currentRoute.value.params.hero || dashboardDefaultView.hero,
              chart: router.currentRoute.value.params.chart || dashboardDefaultView.chart,
              location: `${this.dashboardInfo.countries_visible_full[0].id}`,
              agg: '90days',
              date: router.currentRoute.value.params.isWeekly ? getWeeklyDate(this.dashboardInfo.last_date_available, 3, this.dashboardInfo.first_date_available) : this.dashboardInfo.last_date_available
            }
          })
        })
        .then(() => {
          this.trackRoute('performance')
          this.ready = true
        })
    } else {
      const params = router.currentRoute.value.params
      this.setUserSettings(this.mode)
        .then(() => this.setDataOfParams(params))
        .then(() => this.resetOperators())
        .then(() => {
          this.trackRoute('performance')
          this.ready = true
        })
    }

    this.mapLoading = false
  },
  methods: {
    ...mapActions([
      'setMap',
      'setRouteParam',
      'navigateToDashboard',
      'resetOperators',
      'resetUser',
      'setChart',
      'setHero',
      'setUser',
      'setUserSettings',
      'toggleOperator',
      'track',
      'trackRoute',
      'checkDisclaimer',
      'setPIMap',
      'setAsCurrentLocation'
    ]),
    ...mapActions({
      toggleAdjustableScale: 'dashboard/toggleAdjustableScale',
      toggleConfidence: 'dashboard/toggleConfidenceState'
    }),
    checkLocation (locationParam) {
      const currentLocation = this.locations.find(l => l.key === locationParam)
      if (!currentLocation.granularityId || String(currentLocation.granularityId) === '1') {
        return false
      }
      //  TODO: Workaround while parent_id issue is fixed
      const country = this.locations.find(location =>
        location.iso3 === currentLocation.iso3 && String(location.granularityId) === '1')

      return country.key
    },
    isGeocodingAvailable (geocoding) {
      return this.groupings.some(group => group.id === parseInt(geocoding))
    },
    setDataOfParams (params) {
      if (!params) return Promise.resolve({ cancelled: true })
      const redirectParams = {}

      let geocoding = params.geocoding
      this.setAsCurrentLocation(params.location)

      const type = params.chart.split('_')[1]

      if (!PI_OVERVIEW_METRICS_MENU.some(metricGroup => `${metricGroup.metric}_${type}` === params.chart)) {
        redirectParams.metric = params.hero
        redirectParams.keepSameRoute = true
      }

      if (!this.isGeocodingAvailable(geocoding) && router.currentRoute.value.params.location === params.location) {
        redirectParams.metric = params.hero
        redirectParams.geocoding = String(this.defaultGeocoding)
        redirectParams.keepSameRoute = true
      }

      if (this.checkLocation(params.location)) {
        redirectParams.location = this.checkLocation(params.location)
      }

      if (Object.keys(redirectParams).length) {
        this.navigateToChart(redirectParams)
        return Promise.resolve({ cancelled: true })
      }

      const locationId = this.locations.find(l => l.key === params.location)
      const date = params.date === LATEST_STRING
        ? this.isWeekly(params)
          ? getWeeklyDate(this.dashboardInfo.last_date_available, 3, this.dashboardInfo.first_date_available)
          : this.dashboardInfo.last_date_available
        : params.date

      return Promise.all(['hero', 'Map'].map(p => {
        return this[`set${capitalize(p)}`]({
          metric: params.chart,
          location: params.location,
          agg: params.agg,
          date,
          dashboard: 'performance',
          geocoding,
          country: locationId.iso3,
          altVersion: true
        })
      })).then(() => Promise.resolve({ cancelled: false }))
    },
    signOut () {
      this.resetUser()
      router.replace({
        name: ROUTES.Login
      })
    },
    selectLocation (location, keepSameRoute = false) {
      if (typeof location !== 'number') {
        return
      }

      this.navigateToChart({
        metric: router.currentRoute.value.params.chart,
        location: location,
        keepSameRoute
      })
    },
    goToDetails (locationId) {
      if (typeof locationId === 'number') {
        this.navigateToChart({
          metric: router.currentRoute.value.params.chart,
          location: locationId
        })
      }
    },
    navigateToChart ({
      mode,
      metric,
      location = router.currentRoute.value.params.location,
      date = router.currentRoute.value.params.date,
      agg = router.currentRoute.value.params.agg,
      hero = router.currentRoute.value.params.hero,
      geocoding = router.currentRoute.value.params.geocoding,
      isAlternate,
      keepSameRoute = false
    }) {
      if (mode && mode !== 'performance') {
        this.navigateToDashboard({
          mode: mode,
          router: router.currentRoute.value
        })
      } else {
        if (location === 'undefined') {
          location = router.currentRoute.value.params.location
        }

        const metricCat = get(this.metrics.find(m => m.key === metric), 'category')
        const heroCat = get(this.metrics.find(m => m.key === hero), 'category')
        const isWeekly = WEEKLY_METRICS_CATEGORIES.includes(metricCat) ||
          WEEKLY_METRICS_CATEGORIES.includes(heroCat)

        let weeklyDate

        if (isWeekly) {
          weeklyDate = getWeeklyDate(date, 3, this.dashboardInfo.first_date_available)
        }

        let chart = null

        // when metric changes
        if (metric && date === router.currentRoute.value.params.date && agg === router.currentRoute.value.params.agg) {
          if (metric.split('_')[0] === this.menuMetric.key.split('_')[0]) {
            hero = `${hero.split('_')[0]}_${metric.split('_')[1]}`
            // TODO: Does this always work
            chart = getChartOfHero(metric)
          } else {
            if (isHero(metric)) {
              hero = metric
              chart = getChartOfHero(metric)
            } else {
              hero = getHeroOfChart(metric)
              chart = metric
            }
          }
        }
        if (keepSameRoute || hero !== metric) {
          chart = metric
        }
        const routeName = keepSameRoute ? router.currentRoute.value.name : findPerfomanceRoute(chart, isAlternate)
        const params = {
          hero,
          location: `${location}`,
          agg,
          chart,
          date: weeklyDate || date,
          geocoding: geocoding || router.currentRoute.value.params.geocoding
        }

        router.push({
          name: routeName,
          params
        })
      }
    },
    isWeekly ({
      metric = false,
      chart = router.currentRoute.value.params.chart,
      hero = router.currentRoute.value.params.hero
    }) {
      const heroCat = get(this.metrics.find(m => m.key === hero), 'category')
      const chartCat = get(this.metrics.find(m => m.key === chart), 'category')
      const isWeekly =
          WEEKLY_METRICS_CATEGORIES.includes(chartCat) ||
          WEEKLY_METRICS_CATEGORIES.includes(heroCat) ||
          router.currentRoute.value.params.isWeekly

      if (metric) {
        const metricCat = get(this.metrics.find(m => m.key === metric), 'category')
        return isWeekly || WEEKLY_METRICS_CATEGORIES.includes(metricCat)
      }

      return isWeekly
    },
    getDashboardDefaults () {
      let defaultTechnology = '_lte'
      let presentation, chart, hero

      let category = (router.currentRoute.value.params && router.currentRoute.value.params.category) || 'experience'

      let displayMenuItems = this.menuConfig.metrics.reduce((acc, menuItem) => {
        if (menuItem.category === category &&
          (!menuItem.licence || this.user.licences.includes(menuItem.licence))
        ) {
          acc.push(menuItem)
        }

        return acc
      }, [])

      if (displayMenuItems.length) {
        chart = displayMenuItems[0].main + defaultTechnology
        hero = getHeroOfChart(chart)

        if (hero === 'no_metric') {
          hero = displayMenuItems[0].main + defaultTechnology
          chart = getChartOfHero(hero)
        }

        presentation = getRawSiblings(chart)[0]
      }

      return {
        presentation,
        hero,
        chart
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

 $winner-gold: #FF0000;

.LoadingWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PerfomanceIntelligence {
  &__menu {
    overflow-y: scroll;
    z-index: $z-index-ai-left-menu;
    max-height: 100vh;
  }

  &__hero {
    // whatever we need for the top bar into
  }

  &__chart {
    position: relative;
    padding: $padding-medium 0;
  }

  &__charts {
    position: relative;
    height: 99%;
  }
}

.charts {
    position: relative;
    overflow: hidden;
}

.Details {
  &__mapWrapper {
    height: 100%;
    padding: 1em !important;
  }
  &__mapView {
    height: 100%;
  }
  &__Geographies {
    max-height: 90%;
    height: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    z-index: 10;
    width: 400px;
    background: white;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>
