<template>
  <div class="Colors">
    <h3 class="Colors__title">
      Colors
    </h3>
    <h4 class="Colors__title">
      Auto text color
    </h4>
    <div
      v-for="(color, name) in style"
      :key="name"
      :style="{ backgroundColor: color, color: getTextColor(color) }"
      class="Colors__item-text-auto"
    >
      {{ name }} <br> {{ color }}
    </div>

    <h4 class="Colors__title">
      Black text color
    </h4>
    <div
      v-for="(color, name) in style"
      :key="name"
      :style="{ backgroundColor: color }"
      class="Colors__item-text-dark"
    >
      {{ name }} <br> {{ color }}
    </div>

    <h4 class="Colors__title">
      White text color
    </h4>
    <div
      v-for="(color, name, index) in style"
      :key="index"
      :style="{ backgroundColor: color }"
      class="Colors__item-text-light"
    >
      {{ name }} <br> {{ color }}
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colorPalette'
import { getTextColor } from '@/utils/colors'

export default {
  name: 'Colors',
  data () {
    return {
      style: colors,
      getTextColor: getTextColor
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.Colors {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__title {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
  }

  &__item-text-auto,
  &__item-text-dark,
  &__item-text-light {
    padding: 2rem;
    margin-top: 0.5rem;
    border-radius: 1rem;
  }

  &__item-text-dark {
    color: $color-text-black;
  }

  &__item-text-light {
    color: $color-white;
  }
}
</style>
