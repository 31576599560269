<template>
  <div
    class="SelectInput"
    :class="selectInputCssClass"
  >
    <div class="SelectInput__targetWrapper">
      <div
        class="SelectInput__inputWrapper"
        @mouseenter="expanded = !disabled"
        @mouseleave="expanded = false"
      >
        <div
          class="LocationSearch__country-select"
        >
          <div class="LocationSearch__filter-icon">
            <FontAwesomeIcon
              :icon="icons.faMapMarkerAlt"
              size="1x"
            />
          </div>
          <span>{{ selectedText }}</span>
        </div>
        <FontAwesomeIcon
          v-if="!disabled"
          :icon="icons.faChevronRight"
          size="1x"
        />
        <div
          v-if="expanded"
          class="SelectInput__options"
        >
          <div
            key="All"
            class="SelectInput__option"
            @click="toggleAll"
          >
            <div>
              <input
                class="province-checkbox"
                type="checkbox"
                :checked="allSelected"
                :indeterminate.prop="isAllIndeterminate"
              >
              <label>All</label>
            </div>
          </div>
          <div
            v-for="option in options"
            :key="option.value"
            class="SelectInput__option"
            @click="toggleProvince(option.value)"
          >
            <div>
              <input
                v-model="modelValue"
                class="province-checkbox"
                type="checkbox"
                :value="option.value"
              >
              <label>{{ option.label }}</label>
            </div>
          </div>
        </div>
      </div>

      <CustomTooltip
        v-if="tooltip"
        :message="tooltip"
      >
        <QuestionMark :theme="theme" />
      </CustomTooltip>
    </div>
  </div>
</template>

<script>
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'

export default {
  name: 'SelectCheckboxInput',
  components: {
    FontAwesomeIcon,
    CustomTooltip,
    QuestionMark
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    tooltip: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    allSelectedText: {
      type: String,
      default: 'All selected'
    }
  },
  data () {
    return {
      expanded: false,
      icons: {
        faChevronRight,
        faMapMarkerAlt
      }

    }
  },
  computed: {
    allSelected  () {
      return this.modelValue.length === this.options.length
    },
    isAllIndeterminate () {
      if (this.allSelected) {
        return false
      }
      return this.modelValue.length > 0
    },
    selectedText () {
      if (this.allSelected) {
        return this.allSelectedText
      }

      return this.modelValue.reduce((acc, province) => {
        let label = this.getLabelById(province)

        if (label) {
          acc.push(label)
        }

        return acc
      }, []).sort().join(',')
    },
    selectInputCssClass () {
      let cssClasses = {}
      if (this.theme) {
        cssClasses['SelectInput--' + this.theme] = true
      }
      cssClasses['SelectInput--disabled'] = this.disabled
      return cssClasses
    }
  },
  methods: {
    getLabelById (id) {
      return (this.options.find(option => option.value === id) || {}).label
    },
    updateValue (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    toggleAll () {
      if (this.allSelected) {
        this.updateValue([])
      } else {
        this.updateValue(this.options.map(p => p.value))
      }
    },
    toggleProvince (province) {
      let index = this.modelValue.indexOf(province)

      if (index !== -1) {
        this.updateValue(this.modelValue.filter(p => p !== province))
      } else {
        this.updateValue([...this.modelValue, province])
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.province-checkbox {
    margin-right: 10px;
}

.LocationSearch__country-select {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SelectInput {
  @extend %defaultLabel;
  width: pxToRem($sidebar-width - $sidebar-padding * 2);

  &--disabled {
    opacity: .75;

    .SelectInput__inputWrapper {
      cursor: default;
    }
  }

  &__targetWrapper {
    display: flex;
  }

  &__inputWrapper {
    @extend %controlElement;
  }

  .CustomTooltip {
    margin: 7px 0 0 5px;
  }

  &__options {
    position: absolute;
    top: -1px;
    left: 100%;
    min-width: 100%;
    margin-left: 1px;
    z-index: 21;
  }

  &__option {
    @extend %controlElement;
    white-space: nowrap;
    height: 2em;
    &--active.SelectInput__option {
      @extend %active;
    }
  }

  &--ci {
    .SelectInput__inputWrapper {
      @extend %controlElement-ci;
      margin-bottom: 0;

      &:hover {
        @extend %hover-ci;
      }
    }

    .SelectInput__options {
      top: 0;
      background-color: $ci-dark-blue;
    }

    .SelectInput__option {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
      }

      &--active.SelectInput__option {
        @extend %active-ci;

        &:hover {
          @extend %active-ci;
        }
      }
    }
  }

  &--mg {
    .SelectInput__inputWrapper {
      @extend %controlElement-ci;
      margin-bottom: 0;
      // background-color: $mg-base;
      background: $hover-mg;
      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }

    .SelectInput__options {
      top: 0;
      background-color: $mg-base;
    }

    .SelectInput__option {
      @extend %controlElement-ci;
      background-color: $mg-base;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }

      &--active.SelectInput__option {
        @extend %active-ci;
        background: $active-mg;
        color: $mg-text-color;

        &:hover {
          @extend %active-ci;
          background: $active-mg;
          color: $mg-text-color;
        }
      }
    }

    //
    &:hover {
      .BrandSelector__input {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }
    .BrandSelector__input {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }
    .BrandSelector__options {
      background-color: $mg-base;
      padding: 0;
      top: 0;
    }
    .BrandSelector__item {
      @extend %controlElement-ci;
      background-color: $mg-base;
      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }
      &--title.BrandSelector__item{
        &:hover {
          @extend %controlElement-ci;
        }
      }
      &--active.BrandSelector__item--selectable {
        background: $active-mg;
        color: $mg-text-color;
        &:hover {
          background: $active-mg;
          color: $mg-text-color;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
