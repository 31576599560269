<template>
  <div
    v-show="displayModal"
    class="modal"
  >
    <div class="content">
      <span class="title">
        Explaining
        <span class="highlight">User Group</span>
        metrics
      </span>

      <p class="description">
        By default, Opensignal metrics are based on All Users for whom Opensignal collected any data.
        <br>
        <br>

        For Download Speed, Upload Speed, Games Experience, Voice App Experience, Video Experience and Availability metrics, data can also be analysed by group of users: 5G Users, 4G Users and 3G Users.

        <br>
        <br>

        User Groups are defined as follows:

        <br>
        <br>

        <span class="highlight">All Users:</span> all users for whom Opensignal collected any data.

        <br>
        <br>

        <span class="highlight">5G Users:</span> users who have seen 5G at least once on their current network. They might have also seen 4G, 3G, 2G and No signal. In other words, this user group is 5G or Below Users.

        <br>
        <br>
        <span class="highlight">4G Users:</span> users who have seen 4G on their current network at least once and have never seen 5G. They might have also seen 3G, 2G and No signal. In other words, this user group is 4G or Below Users.

        <br>
        <br>
        <span class="highlight">3G Users:</span> users who have never seen 5G or 4G on their current network. They might have also seen 2G and No signal. In other words, this user group is 3G or Below Users.
      </p>

      <br>
      <br>

      <span class="title">
        Explaining
        <span class="highlight">5G</span>
        and
        <span class="highlight">5G User</span>
        metrics
      </span>

      <div class="content__wrapper">
        <div class="item">
          <span class="subtitle">
            5G Download Speed Experience (All Users)
          </span>
          <div class="picture">
            <img :src="icons.fiveGDownloadSpeed">
          </div>
          <p class="description">
            Shows the average download speed experienced by Opensignal users across an operator’s 5G network.
          </p>
        </div>
        <p />
        <div class="item">
          <span class="subtitle">
            Overall Download Speed Experience - 5G Users
          </span>
          <div class="picture">
            <img :src="icons.downloadSpeedExperience5gUsers">
          </div>
          <p class="description">
            Shows the average download speeds experienced by Opensignal users with a 5G device and a 5G subscription across an operator’s networks.
          </p>
          <p class="description">
            It factors in 2G, 3G, 4G and 5G download speeds along with the availability of each technology (No Signal, 2G, 3G, 4G and 5G).
          </p>
        </div>
      </div>
      <p class="description description__full-width">
        The Download Speed Experience metrics is used as an example to explain the differences between 5G and 5G Users metrics. The same logic applies to all 5G and 5G Users metrics.
      </p>
      <button
        class="button button__secondary"
        @click="confirm()"
      >
        Close
      </button>
      <a
        href="https://www.opensignal.com/methodology-overview"
        target="_blank"
      >
        <button
          class="button button__primary"
          @click="confirm()"
        >
          More about metrics
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import fiveGDownloadSpeed from '@/assets/5g-download-speed.svg'
import downloadSpeedExperience5gUsers from '@/assets/download-speed-experience-5g-users.svg'

export default {
  name: 'UserGroupInfoModal',
  props: {
    displayModal: {
      type: Boolean,
      defualt: false
    }
  },
  data () {
    return {
      icons: {
        fiveGDownloadSpeed,
        downloadSpeedExperience5gUsers
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('update:displayModal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "scss/variables";

// TODO: fix this
.title {
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1;
  letter-spacing: normal;
  color: #34507b;
}
.highlight {
  color: #b0853d;
}
.subtitle {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #bb9558;
}
.description {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.26px;
  color: #34507b;
  text-align: justify;
  padding: 0 10px;

  &__full-width {
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
    font-style: italic !important;
  }
}
.button {
  width: 199px;
  height: 57px;
  border: 0;
  background: $color-electric-blue;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  margin: 0 5px;
  margin-bottom: 0;
  cursor: pointer;

&__primary {
    background: #b0853d;
    color: #ffffff;
    border: 1px solid #b0853d;
  }
  &__secondary {
    background: #ffffff;
    color: #b0853d;
    border: 1px solid #b0853d;
  }
}
.picture {
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    max-width: 100%;
  }
}

.item {
  flex: 1 1 0px;
  text-align: center;
  margin-bottom: 30px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(80, 100, 130, 0.5);
}
.content {
  text-align: center;
  width: 940px;
  height: 890px;
  max-width: 80%;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  @media screen and (max-width: $ci-breakpoint-tablet) {
    max-width: 90%;
    padding: 20px;
    max-height: calc(100vh - 40px);
  }

  &__wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 32px
  }
}
</style>
