<template>
  <div class="BarChartElement">
    <div class="BarChartElement__bar">
      <div
        :style="uciBarStyle"
        class="BarChartElement__uci-bar"
      />
      <div
        :style="valueBarStyle"
        class="BarChartElement__value-bar"
      />
      <div
        v-if="lci && uci"
        :style="confidenceIntervalStyle"
        class="BarChartElement__confidenceInterval"
      >
        <div class="BarChartElement__confidenceInterval-inner">
          <div class="BarChartElement__confidenceInterval-line" />
          <div class="BarChartElement__confidenceInterval-left" />
          <div class="BarChartElement__confidenceInterval-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarChartElement',
  props: {
    value: {
      type: Number,
      default: undefined,
      validator: a => Number.isFinite(a)
    },
    lci: {
      type: Number,
      default: undefined,
      validator: a => Number.isFinite(a)
    },
    uci: {
      type: Number,
      default: undefined,
      validator: a => Number.isFinite(a)
    },
    min: {
      type: Number,
      required: true,
      validator: a => Number.isFinite(a)
    },
    max: {
      type: Number,
      required: true,
      validator: a => Number.isFinite(a)
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    absoluteValue () {
      return this.value - this.min
    },
    absoluteLci () {
      return this.lci - this.min
    },
    absoluteUci () {
      return this.uci - this.min
    },
    absoluteMaxValue () {
      return this.max - this.min
    },
    valueBarStyle () {
      return {
        width: `${(this.absoluteValue / this.absoluteMaxValue) * 100}%`,
        backgroundColor: this.color
      }
    },
    uciBarStyle () {
      return {
        width: `${(this.absoluteUci / this.absoluteMaxValue) * 100}%`,
        backgroundColor: this.color
      }
    },
    confidenceIntervalStyle () {
      const leftPerc = (this.absoluteLci / this.absoluteMaxValue) * 100
      const widthPerc = ((this.absoluteUci - this.absoluteLci) / this.absoluteMaxValue) * 100
      return {
        left: `${Math.abs(leftPerc)}%`,
        width: `${Math.abs(widthPerc)}%`
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
$confidence-color: #607592;
$default-background-color: #D7DAE5;
$border-radius: 2px;
.BarChartElement {
  border-radius: $border-radius;

  &__bar {
    border: 1px solid $default-background-color;
    background-color: $default-background-color;
    position: relative;
    height: 10px;
  }
  &__uci-bar {
    border-radius: $border-radius;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: .25;
  }
  &__value-bar {
    border-radius: $border-radius;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__confidenceInterval {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }
  &__confidenceInterval-inner {
    height: 100%;
    position: relative;
    background: transparent;
  }
  &__confidenceInterval-line {
    border: .5px solid $confidence-color;
    height: 1px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  &__confidenceInterval-left, &__confidenceInterval-right {
    border: .5px solid $confidence-color;
    width: 1px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  &__confidenceInterval-right {
    right: 0;
  }
  &__confidenceInterval-left {
    left: 0;
  }
}
</style>
