<template>
  <MarketGrowthDetailsLayout>
    <template #list>
      <MarketGrowthList
        :geo-json="shapes.geoJson"
        :dashboard-info="dashboardInfo"
      />
    </template>

    <template #map>
      <MarketGrowthMap
        :geo-json="shapes.geoJson"
        :geo-json-overlay="shapes.geoJson"
        :zoomed="true"
      />
    </template>
  </MarketGrowthDetailsLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MarketGrowthMap from '@/components/market-growth/map/MarketGrowthMap'
import MarketGrowthDetailsLayout from '@/components/market-growth/MarketGrowthDetailsLayout'
import MarketGrowthList from '@/components/market-growth/MarketGrowthList'
import ROUTES from '@/constants/routes'
import router from '@/router'

export default {
  name: 'MarketGrowthDetails',
  components: {
    MarketGrowthDetailsLayout,
    MarketGrowthList,
    MarketGrowthMap
  },
  data () {
    return {
      ROUTES: ROUTES
    }
  },
  computed: {
    ...mapGetters([
      'marketGrowthConfig',
      'dashboardInfo',
      'locations',
      'shapes'
    ]),
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible',
      selectedProvinces: 'marketGrowth/selectedProvinces'
    })
  },
  watch: {
    selectedProvinces () {
      this.setMap()
    },
    $route (to, from) {
      if (
        from.params.metric !== to.params.metric ||
        from.params.brand !== to.params.brand ||
        from.params.competitor !== to.params.competitor ||
        from.params.strategy !== to.params.strategy ||
        from.params.granularity !== to.params.granularity
      ) {
        this.setMap()
        this.fetchMarketGrowthStores({
          brand: router.currentRoute.value.params.brand,
          competitor: router.currentRoute.value.params.competitor
        })
      }
    }
  },
  mounted () {
    this.setUserSettings('market-growth').then(() => {
      this.setMGParams({ metric: router.currentRoute.value.params.metric })
      this.setMap()
      this.fetchMarketGrowthStores({
        brand: router.currentRoute.value.params.brand,
        competitor: router.currentRoute.value.params.competitor
      })
      this.trackRoute('marketGrowth')
    })
  },
  methods: {
    ...mapActions([
      'setUser',
      'trackRoute',
      'setUserSettings',
      'setMGMap',
      'setMGParams',
      'fetchMarketGrowthStores'
    ]),
    setMap () {
      this.setMGMap({
        geocoding: router.currentRoute.value.params.granularity,
        agg: this.marketGrowthConfig.agg,
        country: router.currentRoute.value.params.country,
        brand: router.currentRoute.value.params.brand,
        competitor: router.currentRoute.value.params.competitor
      })
    }
  }
}
</script>
