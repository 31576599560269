<template>
  <div class="PerformanceTabsContent">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PerformanceTabsContent',
  props: { }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .PerformanceTabsContent {
    @include tabs-content();
    border: none;
    max-height: calc(100vh - 195px);
    overflow-y: auto;
    background: transparent;
  }
</style>
