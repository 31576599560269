export default {
  CompetitiveDetails: 'r/CompetitiveDetails',
  CompetitiveIntelligence: 'r/CompetitiveIntelligence',
  CompetitiveOverview: 'r/CompetitiveOverview',
  KitchenSink: 'r/KitchenSink',
  LocationShowcase: 'r/LocationShowcase',
  Colors: 'r/Colors',
  Landing: 'Landing',
  Login: 'Login',
  MarketGrowth: 'r/MarketGrowth',
  MarketGrowthDetails: 'r/MarketGrowthDetails',
  MarketGrowthOverview: 'r/MarketGrowthOverview',
  PerformanceIntelligence: 'r/PerformanceIntelligence',
  PerformanceIntelligenceOverview: 'r/PerformanceIntelligenceOverview',
  PerformanceIntelligenceSimple: 'r/PerformanceIntelligenceSimple',
  PerformanceIntelligenceBinned: 'r/PerformanceIntelligenceBinned',
  PerformanceIntelligenceHourly: 'r/PerformanceIntelligenceHourly',
  PerformanceIntelligenceCp: 'r/PerformanceIntelligenceCp',
  PerformanceIntelligenceCdn: 'r/PerformanceIntelligenceCdn',
  PerformanceIntelligenceCpOps: 'r/PerformanceIntelligenceCpOps',
  PerformanceIntelligenceCdnOps: 'r/PerformanceIntelligenceCdnOps',
  PerformanceIntelligenceCpIp: 'r/PerformanceIntelligenceCpIp',
  PerformanceIntelligenceIp: 'r/PerformanceIntelligenceIp',
  PerformanceIntelligenceCdnResIp: 'r/PerformanceIntelligenceCdnResIp',
  PerformanceIntelligenceCdnRes: 'r/PerformanceIntelligenceCdnRes',
  PerformanceIntelligenceCdnResOps: 'r/PerformanceIntelligenceCdnResOps',
  PerformanceIntelligenceBinnedCdnRes: 'r/PerformanceIntelligenceBinnedCdnRes',
  PerformanceIntelligenceDist: 'r/PerformanceIntelligenceDist',
  PerformanceIntelligenceBar: 'r/PerformanceIntelligenceBar',
  PerformanceIntelligenceCombCat: 'r/PerformanceInteligenceCombCat',
  PerformanceIntelligenceNByN: 'r/PerformanceIntelligenceNByN',
  PerformanceIntelligenceRanges: 'r/PerformanceIntelligenceRanges',
  PlainJson: 'r/PlainJson'
}
