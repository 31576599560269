<template>
  <div
    v-if="submarket"
    class="MGMapTooltip"
  >
    <div class="MGMapTooltip__header">
      {{ metrics[metric].label }}
    </div>

    <div class="MGMapTooltip__stats">
      <div class="MGMapTooltip__location">
        <div
          class="MGMapTooltip__locationGADColor"
          :style="{ backgroundColor: getDeltaColor(metric, submarket) }"
        />
        <div class="MGMapTooltip__locationNameAndValue">
          {{ submarket.locationData.name }}
        </div>
      </div>
      <div class="MGMapTooltip__statsItem">
        <div class="MGMapTooltip__statsLabel">
          Strategy
        </div>
        <div class="MGMapTooltip__statsIndicators">
          <Indicator
            :label="submarket.rank"
          />
          <Indicator
            :label="submarket.strategic_position[0].toUpperCase()"
            :strategy="submarket.strategic_position"
          />
        </div>
      </div>

      <template v-if="displayMgStarRatings">
        <div class="MGMapTooltip__statsItemLabels--stars">
          <div class="MGMapTooltip__statsLabel">
            &nbsp;
          </div>
          <div class="MGMapTooltip__statsValue--rating">
            <div class="operator-rating">
              <div class="operator-rating__value">
                4G
              </div>
              <div class="operator-rating__value">
                5G
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="rating in ratings"
          :key="rating.title"
          class="MGMapTooltip__statsItem--stars"
        >
          <div class="MGMapTooltip__statsLabel">
            {{ rating.title }}
          </div>
          <div class="MGMapTooltip__statsValue--rating">
            <div
              v-for="(value, operator) in rating.values"
              :key="operator"
              class="operator-rating"
            >
              <OperatorAvatar
                :key="operator"
                :letter="getNetworkInfo(operator) ? getNetworkInfo(operator).name_mapped[0] : null"
                :background-color="getNetworkInfo(operator) ? `#${getNetworkInfo(operator).hex_color}` : null"
                class="header__operator-avatar"
              />
              <span class="operator-rating__value">{{ value.lte ? value.lte : '-' }}</span>
              <span class="operator-rating__value">{{ value['5g'] ? value['5g'] : '-' }}</span>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="MGMapTooltip__statsItem">
          <div class="MGMapTooltip__statsLabel">
            Good Availability Δ
          </div>
          <div class="MGMapTooltip__statsValue">
            <MarketGrowthDelta
              :value="submarket.good_availability_delta"
            />
          </div>
        </div>
        <div class="MGMapTooltip__statsItem">
          <div class="MGMapTooltip__statsLabel">
            Market Share Δ
          </div>
          <div class="MGMapTooltip__statsValue">
            <MarketGrowthDelta
              :value="submarket.device_share_delta"
            />
          </div>
        </div>
        <div class="MGMapTooltip__statsItem">
          <div class="MGMapTooltip__statsLabel">
            Population
          </div>
          <div class="MGMapTooltip__statsValue MGMapTooltip__statsValue--population">
            {{ $filters.numberFormat(submarket.locationData.population_size) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Indicator from '../Indicator'
import MarketGrowthDelta from '../MarketGrowthDelta'
import OperatorAvatar from '@/components/OperatorAvatar'
import { MG_METRICS, STAR_RATING_CATEGORY_TITLES, STAR_RATING_CATEGORY_KEYS } from '@/constants/constants'
import router from '@/router'
import { getDeltaColor } from '@/utils/viewHelpers'

export default {
  name: 'MapTooltip',
  components: {
    Indicator,
    MarketGrowthDelta,
    OperatorAvatar
  },
  props: {
    submarket: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      metrics: MG_METRICS,
      getDeltaColor
    }
  },
  computed: {
    ...mapGetters({
      metric: 'metrics/primaryMetricKey',
      displayMgStarRatings: 'marketGrowth/displayStarRatings'
    }),
    ...mapGetters(['dashboardInfo']),
    brands () {
      return this.dashboardInfo.brands
    },
    competitors () {
      if (!this.selectedBrand || !this.brands) {
        return []
      }
      return this.getBrand(this.selectedBrand).competitors
    },
    selectedBrand () {
      return parseInt(router.currentRoute.value.params.brand || '')
    },
    selectedCompetitor () {
      return parseInt(router.currentRoute.value.params.competitor || '')
    },
    ratings () {
      return STAR_RATING_CATEGORY_KEYS.map((categoryKey, i) => {
        return {
          title: STAR_RATING_CATEGORY_TITLES[i],
          values: {
            [this.selectedBrand]: this.submarket[`${this.selectedBrand}-${categoryKey}`],
            [this.selectedCompetitor]: this.submarket[`${this.selectedCompetitor}-${categoryKey}`]
          }
        }
      })
    }
  },
  methods: {
    getBrand (networkId) {
      return this.brands.find(brand => brand.canonical_network_id === parseInt(networkId))
    },
    getNetworkInfo (id) {
      let network = this.brands.find(brand => brand.canonical_network_id === parseInt(id))
      if (network) {
        return network
      }
      this.brands.forEach(brand =>
        brand.competitors.forEach(competitor => {
          if (competitor.canonical_network_id === parseInt(id)) {
            network = competitor
          }
        })
      )

      return network
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MGMapTooltip {
  background: $color-white;
  padding: 20px;
  color: $mg-text-color;
  width: 400px;

  &__header {
    margin-bottom: 20px;
    font-size: $font-size-18;
  }
  &__stats {
    display: flex;
    flex-wrap: wrap;
  }

  &__location {
    display: flex;
    flex: 0 0 66.666666%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: $font-size-18;
  }

  &__locationGADColor {
    flex: 0 0 30px;
    min-height: 54px;
    height: 100%;
    margin-right: 10px;
  }

  &__statsItem {
    flex: 0 0 33.333333%;
    text-align: center;
  }
  &__statsLabel {
    // color: blue;
    font-size: $font-size-12;
    white-space: nowrap;
  }

  &__statsIndicators {
    display: flex;
    justify-content: center;
    .Indicator {
      margin: 0;
    }
  }

  &__statsValue {
    &--population {
        font-size: $font-size-18;
    }
    &--rating {
      padding: 10px;
      display: flex;
      justify-content: center;
    }
  }
  .operator-rating {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    &__value {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__statsItemLabels--stars {
    flex: 0 0 10%;
    text-align: center;

    .MGMapTooltip__statsValue--rating {
      padding-left: 0;
      padding-right: 0;
    }
    .operator-rating {
      margin-top: 26px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__statsItem--stars {
    flex: 0 0 30%;
    text-align: center;
  }
}
</style>
