<template>
  <div class="DistributionScale">
    <tooltip-wrapper
      class="DistributionScale__tooltip"
      title="Set percentile"
    >
      <vue-slider
        v-model="percentileValue"
        marks
        adsorb
        :data="percentileOptions"
        tooltip="none"
        :width="150"
        contained
      />
    </tooltip-wrapper>

    <tooltip-wrapper :title="isGrouped ? 'Ungroup' : 'Group'">
      <div
        class="DistributionScale__groupIcon"
        :class="{'DistributionScale__groupIcon--active': isGrouped}"
        @click="isGrouped = !isGrouped"
      >
        <FontAwesomeIcon
          :icon="icons.faLayerGroup"
          size="1x"
        />
      </div>
    </tooltip-wrapper>
  </div>
</template>

<script>

import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSlider from 'vue-slider-component'
import { mapGetters, mapActions } from 'vuex'
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper'
import 'vue-slider-component/theme/default.css'

// NOTE: The slider will not render correctly if you hide it with display: none. Use v-if instead for hover effect
export default {
  name: 'DistributionScale',
  components: {
    TooltipWrapper,
    VueSlider,
    FontAwesomeIcon
  },
  props: {
    modelValue: {
      type: Number,
      default: 90
    }
  },
  data () {
    return {
      percentileOptions: [
        10,
        25,
        50,
        75,
        90,
        100
      ],
      icons: {
        faLayerGroup
      }
    }
  },
  computed: {
    ...mapGetters({
      isDistributionGrouped: 'charts/isDistributionGrouped'
    }),
    isGrouped: {
      get () {
        return this.isDistributionGrouped
      },
      set (value) {
        this.setDistributionGrouped(value)
      }
    },
    percentileValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    ...mapActions([
      'setDistributionGrouped'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @import 'scss/variables';
  @import 'scss/components';

  .DistributionScale {
    display: flex;
    &__tooltip {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    &__groupIcon {
      @extend %boxIcon;
      position: relative;
      color: $color-blue-header;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover, &--active {
        color: $color-white;
        background-color: $color-blue-header;
      }
    }
  }
</style>
