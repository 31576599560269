<template>
  <div class="flex-radio-buttons">
    <div
      v-for="option in options"
      :key="option[valueKey]"
      :class="{'flex-radio-buttons__item--active': option[valueKey] === modelValue[valueKey]}"
      class="flex-radio-buttons__item"
      @click="$emit('update:modelValue', option)"
    >
      <OperatorAvatar
        :letter="option[nameKey][0]"
        :background-color="`#${option.hex_color}`"
      />
      {{ option[nameKey] }}
    </div>
  </div>
</template>

<script>
import OperatorAvatar from '../OperatorAvatar'

export default {
  name: 'FlexRadioButtons',
  components: { OperatorAvatar },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Object,
      default: () => {}
    },
    valueKey: {
      type: String,
      default: 'key'
    },
    nameKey: {
      type: String,
      default: 'name'
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  .flex-radio-buttons {
    display: flex;
    flex-flow: row wrap;
    background-color: $color-white;
    border-radius: $control-border-radius;
    overflow: hidden;
    border: $control-border;
    color: $color-blue-primary;
    cursor: pointer;
    &__item {
      overflow: hidden;
      box-sizing: border-box;
      min-width: 50%;
      padding: 0 $padding-medium;
      flex-grow: 2;
      font-size: $font-size-12;
      border: $control-border;
      font-weight: $font-weight-roboto-regular;
      white-space: nowrap;
      height: $control-height;
      line-height: $control-height;
      &:hover {
        background-color: $color-electric-blue;
        color: $color-white;
      }
      &--active {
        background-color: $color-blue-primary;
        color: $color-white;
      }
    }
  }
</style>
