<template>
  <div
    :class="cardClass"
    class="product-card"
    @click="handleCardClick"
  >
    <div class="product-card__overlay" />
    <div class="product-card__main">
      <div class="product-card__content">
        <div class="product-card__spacer" />
        <div class="product-card__product-logo">
          <img :src="iconSrc">
        </div>
        <div class="product-card__title">
          {{ label[0] }}
        </div>
        <div class="product-card__subtitle">
          {{ label[1] }} {{ label[2] }}
        </div>
        <div class="product-card__controls">
          <div
            v-if="loading"
            class="product-card__loader"
          >
            <loader-grid />
          </div>
          <div
            v-else-if="!disabled"
            class="product-card__button"
          >
            <div
              :class="`ci-${productKey}`"
              class="product-card__icon-next"
            >
              <FontAwesomeIcon :icon="icons.faChevronRight" />
            </div>
          </div>
        </div>
        <div class="product-card__spacer" />
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoaderGrid from '../LoaderGrid'

export default {
  name: 'ProductCard',
  components: {
    FontAwesomeIcon,
    LoaderGrid
  },
  props: {
    title: { type: String, required: true },
    label: { type: Array, required: true },
    productKey: { type: String, required: true },
    product: { type: Object, default: undefined },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data: function () {
    return {
      icons: { faChevronRight }
    }
  },
  computed: {
    cardClass: function () {
      return {
        [`product-card--${this.productKey}`]: true,
        [`product-card--disabled`]: this.disabled
      }
    },
    iconSrc: function () {
      const { productKey } = this
      let image
      try {
        image = require(`../../assets/product/${productKey}-logo.svg`)
      } catch (e) {
        image = null
      }
      return image
    }
  },
  methods: {
    handleCardClick () {
      if (this.disabled) {
        return
      }
      this.$emit('card-click', this.productKey)
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';
  $icon-size: 35px;
  $icon-size-mobile: 20px;
  $lighter-overlay-color: #008DFF;

  .product-card {
    $default-text-shadow: 0 0 5px $color-blue-header;
    $default-border-radius: .5em;
    transition: background-image 300ms ease-in 200ms;
    position: relative;
    background-size: cover;
    height: 100%;
    cursor: pointer;
    @include xy-grid();

    &--disabled {
      cursor: default;
    }
    &__overlay {
      @include xy-cell(full, $gutters: 0);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 50%;
      mix-blend-mode: soft-light;
    }

    &__main {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__content {
      @include xy-grid($direction: vertical);
      padding-top: pxToRem(50px);
      height: 100%;
      text-align: center;
      border-radius: $default-border-radius;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0;
      }
    }

    &__spacer {
      @include xy-cell($size: auto);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        display: none;
      }
    }

    &__title {
      @include xy-cell($size: shrink);
      font-size: pxToRem(34px);
      font-weight: $font-weight-roboto-light;
      text-shadow: $default-text-shadow;
      line-height: 1.1;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        font-size: pxToRem(26px);
      }
    }

    &__subtitle {
      @include xy-cell($size: shrink);
      font-size: pxToRem(20px);
      text-shadow: $default-text-shadow;
      color: $color-gray-light;
      font-weight: $font-weight-roboto-medium;
      line-height: 1.5;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        font-size: pxToRem(16px);
      }
    }

    &__controls {
      @include xy-cell($size: shrink, $gutter-position: top bottom);
      @include xy-grid();
      align-items: center;
      height: $icon-size;
      padding-top: pxToRem(15px);
    }

    &__loader {
      @include xy-cell($size: 1 of 1);
    }

    &__button {
      @include xy-cell($size: auto);
    }

    &__product-logo {
      height: pxToRem(100px);
      padding: pxToRem(10px);

      img {
        height: 100%;
      }

      @media screen and (max-width: $ci-breakpoint-tablet) {
        height: pxToRem(50px);
      }
    }

    &__icon-next {
      border-radius: 50%;
      border: 2px solid white;
      color: $color-white;
      display: inline-block;
      height: $icon-size;
      line-height: $icon-size;
      width: $icon-size;
      cursor: pointer;
      opacity: .6;
    }

    &:hover {
      .product-card__icon-next {
        opacity: 1;
        background-color: $color-white;
        color: $color-brand-blue;
      }
    }
  }

  @mixin product-theme($product-key, $color) {
    .product-card--#{$product-key} {
      background-image: url("../../assets/product/#{$product-key}-bg.jpg");

      .product-card__title {
        color: $color;
      }

      .product-card__product-logo {
        fill: $color;
        stroke: $color;
      }

      &:hover {
        .product-card__title {
          color: $color-white;
        }

        .product-card__subtitle {
          color: $color-white;
        }

        .product-card__overlay {
          background: rgba($color, 1);
          opacity: .5;
        }
      }
    }
  }

  @include product-theme(competitive, $product-theme-color-purple);
  @include product-theme(performance, $product-theme-color-orange);
  @include product-theme(insights, $product-theme-color-tutela);

</style>
