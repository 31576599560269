<template>
  <div class="ChartBinned">
    <ChartWrapper
      :title="chartData.title"
      :picture-meta="titleLabels"
      :selected-point="selectedPoint"
      :loading="chartPending"
      :empty="chartData.empty"
      :unit="'%'"
      :tooltip="chartData.tooltip"
      :title-labels="titleLabels"
      :percentile="percentile"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <template #scale>
        <DistributionScale
          v-if="chartData.loaded && hover"
          v-model="percentile"
        />
      </template>
      <simple-bar-chart
        v-if="chartData.loaded"
        :data-set="chartData.data"
        :labels="chartData.label"
        :x-axis-unit="chartData.xAxisUnit"
        :x-axis-label="chartData.xAxisLabel"
        :y-axis-label="chartData.yAxisLabel"
        :y-axis-max="chartData.yAxisMax"
        :percentile="percentile"
        chart-id="distribution"
      />
    </ChartWrapper>
    <div class="ChartBinned__charts">
      <div
        v-for="operatorChartData in chartDataByNetwork"
        :key="operatorChartData.data && operatorChartData.data[0] && operatorChartData.data[0].label"
        class="ChartBinned__chart"
      >
        <ChartWrapper
          :percentile="percentile"
          :title="operatorChartData.title"
          :picture-meta="titleLabels"
          :loading="chartPending"
          :empty="operatorChartData.empty"
          :title-labels="titleLabels"
          :unit="'%'"
          :legend-disabled="true"
        >
          <simple-bar-chart
            v-if="operatorChartData.loaded"
            :data-set="operatorChartData.data"
            :labels="operatorChartData.label"
            :x-axis-unit="operatorChartData.xAxisUnit"
            :x-axis-label="operatorChartData.xAxisLabel"
            :y-axis-label="operatorChartData.yAxisLabel"
            :chart-id="operatorChartData.chartId"
            :percentile="percentile"
          />
        </ChartWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SimpleBarChart, ChartWrapper } from '@/components/visual'
import DistributionScale from '@/components/visual/tools/DistributionScale'
import { DEFAULT_DISTRIBUTION_PERCENTILE } from '@/constants/constants'

export default {
  name: 'ChartBinned',
  components: {
    ChartWrapper,
    SimpleBarChart,
    DistributionScale
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      hover: false,
      percentile: DEFAULT_DISTRIBUTION_PERCENTILE
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/getPrimaryDistributionChart',
      chartDataByNetwork: 'charts/getDistributionChartsByOperator',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      currentLocation: 'locations/current',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    scale: {
      get () {
        return this.distributionChartScale
      },
      set (v) {
        this.setDistributionChartScale(parseInt(v))
      }
    }
  },
  methods: {
    ...mapActions([
      'setDistributionChartScale'
    ])
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import 'scss/components';

  .ChartBinned {
    &__charts {
      @include xy-grid(horizontal)
    }
    &__chart {
      width: calc(50% - 0.3em);
      &:nth-child(2n-1) {
        margin-right: 0.6em;
      }
    }
  }
  .ChartWrapper__zoom {
    @extend %boxIcon;
    position: relative;
    color: $color-blue-header;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $color-white;
      background-color: $color-blue-header;
    }
  }
</style>
