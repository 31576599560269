<template>
  <MarketGrowthOverviewList :dashboard-info="dashboardInfo" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MarketGrowthOverviewList from '@/components/market-growth/MarketGrowthOverviewList'

export default {
  name: 'MarketGrowthOverview',
  components: {
    MarketGrowthOverviewList
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ])
  },
  mounted () {
    this.setUserSettings('market-growth').then(() => {
      this.ready = true
    })
  },
  methods: {
    ...mapActions([
      'setUserSettings'
    ])
  }
}
</script>
