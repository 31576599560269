import * as Sentry from '@sentry/vue'
import onClickOutside from 'click-outside-vue3'
import FloatingVue from 'floating-vue'
import Vue, { createApp } from 'vue'
import App from './App'
import store from './store'
import router from '@/router'
import '@/utils/polyfills'
import 'floating-vue/dist/style.css'

if (!process.env.VUE_APP_SENTRY_DISABLED) {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    attachProps: true,
    logErrors: true,
    attachStacktrace: true
  })
}

const app = createApp(App)

app.use(router)
app.use(store)
app.use(FloatingVue)
app.use(onClickOutside)

app.config.globalProperties.$filters = {
  numberFormat(value) {
    const formattedValue = new Intl.NumberFormat('en-EN').format(value)
    return formattedValue !== 'NaN' ? formattedValue : value
  }
}

app.mount('#app')
