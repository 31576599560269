import Base from './base'

export class Auth extends Base {
  constructor (user, pass, token) {
    super()
    this.url = 'api-token-auth/'
    this.custom = 'api-token-auth-login/'
    this.user = user
    this.pass = pass
    this.token = token
  }

  getToken () {
    return this.post(this.url, {
      username: this.user,
      password: this.pass
    })
  }

  getCustomToken () {
    return this.post(this.custom, {
      username: this.user,
      auth_token: this.token
    })
  }
}
