<template>
  <div>
    <div
      v-for="cdnChart in chartData"
      :key="cdnChart.chartId"
    >
      <ChartWrapper
        :title="cdnChart.title"
        :tooltip="focusChartData.tooltip"
        :picture-meta="titleLabels"
        :selected-point="selectedPoints[cdnChart.chartId]"
        :loading="chartPending"
        :empty="!cdnChart.loaded"
        :show-confidence-rate="showConfidenceRate"
        :title-labels="titleLabels"
        :unit="chartUnit.short"
        :y-zoom="chartYZoom"
        enable-y-zoom
        :display-frm="displayFrm"
        :chart-data="cdnChart"
        @toggleYZoom="chartYZoom = !chartYZoom"
      >
        <LineChart
          v-if="cdnChart.loaded"
          :date="date"
          :data-set="cdnChart.data"
          :chart-id="cdnChart.chartId"
          :x-axis-label="cdnChart.xAxisLabel"
          :y-axis-label="cdnChart.yAxisLabel"
          :y-axis-unit="cdnChart.yAxisUnit"
          :end-date="dashboardInfo.last_date_available"
          :show-confidence-rate="showConfidenceRate"
          :sync-range="cdnChart.chartId.includes('360p') ? syncRanges('bounds360') : syncRanges('bounds720')"
          :y-zoom="chartYZoom"
          show-legend
          @point="navigateDate"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LineChart, ChartWrapper } from '@/components/visual'
import router from '@/router'
import { getLinechartRange } from '@/utils/charts'

export default {
  name: 'ChartCdnResOps',
  components: {
    ChartWrapper,
    LineChart
  },
  beforeRouteUpdate (to, from, next) {
    if (router.currentRoute.value.params.chart !== to.params.chart) {
      this.chartYZoom = false
    }

    next()
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartNumbers: false,
      chartToggle: false,
      chartYZoom: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/cdnResOpsTrends',
      chartMetric: 'chart/metric',
      chartPending: 'chart/chartPending',
      chartUnit: 'chart/unit',
      currentLocation: 'locations/current',
      focusChartData: 'charts/cdnResFocus',
      ranges: 'charts/cdnResRanges',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoints: 'dashboard/cdnOpResSelectedPoints',
      displayFrm: 'chartFrm/displayFrm'
    })
  },
  methods: {
    navigateDate (date) {
      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          hero: this.hero,
          chart: this.chart,
          location: this.location,
          agg: this.agg,
          date
        }
      })
    },
    syncRanges (variant) {
      return getLinechartRange(...this.ranges[variant], 30)
    }
  }
}
</script>

<style lang="css">
</style>
