<template>
  <PerformanceLayout
    v-if="ready && hasParams"
    class="PerfomanceIntelligence PerfomanceIntelligenceDetails"
  >
    <template #sidebar>
      <PerformanceMenu
        :products="products"
        :dashboard-info="dashboardInfo"
        :options="{dashboard: 'performance'}"
        :metric="menuMetric.key"
        :agg="$route.params.agg"
        :date="date"
        :networks="networks"
        :confidence="confidenceState"
        :licences="user.licences"
        :dashboards="user.dashboards"
        :lte-only="isMetricLteOnly"
        @navigate="navigateToChart"
        @toggleOperator="toggleOperator"
        @confidence="toggleConfidence"
        @signout="signOut"
      />
    </template>

    <template #header>
      <router-view
        name="hero"
        class="PerfomanceIntelligence__hero"
      />
    </template>

    <template #content>
      <div class="PerfomanceIntelligence__charts">
        <PerformanceTabs>
          <PerformanceTabsTitle
            v-for="tab in tabs"
            :key="tab.label"
            :active="tab.active"
            @clicked="navigateToChart({chart:tab.metric, isAlternate: tab.isAlternate})"
          >
            {{ tab.label }}
          </PerformanceTabsTitle>
        </PerformanceTabs>
        <PerformanceTabsContent>
          <PerformanceTabsPanel>
            <router-view name="charts" />
          </PerformanceTabsPanel>
        </PerformanceTabsContent>
      </div>
    </template>
  </PerformanceLayout>
  <div
    v-else
    class="LoadingWrapper"
  >
    <LoaderGrid
      size="large"
      theme="dark"
    />
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import LoaderGrid from '@/components/LoaderGrid'
import PerformanceLayout from '@/components/performance/PerformanceLayout'
import PerformanceTabs from '@/components/performance/PerformanceTabs'
import PerformanceTabsContent from '@/components/performance/PerformanceTabsContent'
import PerformanceTabsPanel from '@/components/performance/PerformanceTabsPanel'
import PerformanceTabsTitle from '@/components/performance/PerformanceTabsTitle'
import { PerformanceMenu } from '@/components/specialized'
import {
  WEEKLY_METRICS_CATEGORIES,
  DAILY_LTE_ONLY_METRICS,
  ALTERNATE_CHART_VIEWS,
  ALTERNATE_ROUTES,
  PI_METRICS_MENU,
  LATEST_STRING,
  FIVEG_SUBTYPES_METRICS,
  FIVEG_METRICS_TYPES
} from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import {
  getWeeklyDate
} from '@/utils/date'
import {
  getHeroOfChart,
  getChartOfHero,
  isHero,
  findPerfomanceRoute,
  getGroupLabel,
  getRawSiblings,
  getSiblingsOfChart,
  toMainMetric
} from '@/utils/metrics'

export default {
  name: 'PerfomanceIntelligence',
  components: {
    PerformanceTabsPanel,
    PerformanceTabsContent,
    PerformanceTabsTitle,
    PerformanceTabs,
    PerformanceLayout,
    LoaderGrid,
    PerformanceMenu
  },
  beforeRouteUpdate (to, from, next) {
    this.checkDisclaimer()

    this.track({
      dashboard: 'performance',
      params: to.params,
      url: to.path,
      route: to.name
    })

    if (
      to.params.date === router.currentRoute.value.params.date &&
      to.params.location === router.currentRoute.value.params.location &&
      to.params.agg === router.currentRoute.value.params.agg &&
      to.params.hero === router.currentRoute.value.params.hero &&
      to.params.chart === router.currentRoute.value.params.chart
    ) {
      next()
    } else if (
      to.params.date !== router.currentRoute.value.params.date &&
      to.name !== ROUTES.PerformanceIntelligenceNByN &&
      to.name !== ROUTES.PerformanceIntelligenceHourly &&
      to.name !== ROUTES.PerformanceIntelligenceCombCat &&
      to.name !== ROUTES.PerformanceIntelligenceBinned &&
      to.name !== ROUTES.PerformanceIntelligenceBinnedCdnRes &&
      to.name !== ROUTES.PerformanceIntelligenceCpIp &&
      to.name !== ROUTES.PerformanceIntelligenceCdnResIp &&
      to.name !== ROUTES.PerformanceIntelligenceIp &&
      to.name !== ROUTES.PerformanceIntelligenceRanges &&
      to.params.hero === router.currentRoute.value.params.hero &&
      to.params.chart === router.currentRoute.value.params.chart
    ) {
      this.setDataOfParams(to.params, true)

      next()
    } else {
      const category = this.category

      this.setDataOfParams(to.params).then(() => {
        if (category !== this.category) {
          this.resetOperators()
        }
      })

      next()
    }
  },
  data () {
    return {
      ready: true,
      mode: 'performance',
      menuConfig: {
        dashboard: 'performance',
        metrics: PI_METRICS_MENU
      }
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo',
      'locations',
      'metrics',
      'polygons',
      'user'
    ]),
    ...mapGetters({
      homeNetwork: 'charts/homeNetwork',
      mainMetric: 'metrics/primaryMetric',
      display5G: 'metrics/display5GPIUserExperience',
      date: 'dashboard/date',
      adjustableScale: 'dashboard/getAdjustableScale',
      chartMetric: 'metrics/primaryMetric',
      menuMetric: 'metrics/menuMetric',
      category: 'metrics/category',
      confidenceState: 'dashboard/getConfidenceState',
      heroMetric: 'metrics/supportingMetric',
      networkOperators: 'charts/networks',
      products: 'auth/getProducts',
      getRank: 'metrics/getRank',
      resolveLicence: 'metrics/getLicence',
      groupings: 'location/byGroupings',
      defaultGeocoding: 'competitive/defaultGeocoding',
      metricCategoriesByTech: 'metrics/metricCategoriesByTech'
    }),
    networks () {
      return this.networkOperators.map(o => {
        return {
          letter: o.name_mapped[0],
          label: o.name_mapped,
          id: o.canonical_network_id,
          color: `#${o.hex_color}`,
          selected: o.selected
        }
      })
    },
    isMetricLteOnly () {
      return WEEKLY_METRICS_CATEGORIES.includes(this.chartMetric.category) ||
        DAILY_LTE_ONLY_METRICS.includes(router.currentRoute.value.params.chart)
    },
    tabActiveLabel () {
      return getGroupLabel(router.currentRoute.value.params.chart, ALTERNATE_ROUTES.includes(router.currentRoute.value.name))
    },
    tabs () {
      const siblings = getRawSiblings(router.currentRoute.value.params.chart)

      return getSiblingsOfChart(router.currentRoute.value.params.chart).map((metric, index) => {
        const label = getGroupLabel(metric, ALTERNATE_CHART_VIEWS.includes(siblings[index]))

        return {
          metric: metric,
          active: label === this.tabActiveLabel,
          label: label,
          isAlternate: ALTERNATE_CHART_VIEWS.includes(siblings[index])
        }
      }).filter(tab => {
        return this.metrics.find(metric => {
          return metric.key === tab.metric && this.mainMetric.type === metric.type
        })
      })
    },
    hasParams () {
      return Object.keys(router.currentRoute.value.params).length > 0
    }
  },
  mounted () {
    if (!this.user.id) {
      this.setUser().then(res => res && this.checkDisclaimer())
    }

    if (router.currentRoute.value.path === '/performance/' || router.currentRoute.value.path === '/performance') {
      this.setUserSettings(this.mode)
        .then(() => this.resetOperators())
        .then(() => {
          this.setAsCurrentLocation(`${this.dashboardInfo.countries_visible_full[0].id}`)
          let dashboardDefaultView = this.getDashboardDefaults()
          const chart = router.currentRoute.value.params.chart || dashboardDefaultView.chart

          const routeName = dashboardDefaultView.routeName

          router.replace({
            name: routeName,
            params: {
              hero: router.currentRoute.value.params.hero || dashboardDefaultView.hero,
              chart,
              location: `${this.dashboardInfo.countries_visible_full[0].id}`,
              agg: '90days',
              geocoding: this.defaultGeocoding,
              date: router.currentRoute.value.params.isWeekly ? getWeeklyDate(this.dashboardInfo.last_date_available, 3, this.dashboardInfo.first_date_available) : LATEST_STRING
            }
          })
        })
        .then(() => {
          this.trackRoute('performance')
          this.ready = true
        })
    } else {
      this.setUserSettings(this.mode)
        .then(() => this.setDataOfParams(router.currentRoute.value.params))
        .then(() => this.resetOperators())
        .then(() => {
          this.trackRoute('performance')
          this.ready = true
        })
    }
  },
  methods: {
    ...mapActions([
      'navigateToDashboard',
      'resetOperators',
      'resetUser',
      'setChart',
      'setFrmchart',
      'setHero',
      'setUser',
      'setUserSettings',
      'toggleOperator',
      'track',
      'trackRoute',
      'checkDisclaimer',
      'setAsCurrentLocation'
    ]),
    ...mapActions({
      toggleAdjustableScale: 'dashboard/toggleAdjustableScale',
      toggleConfidence: 'dashboard/toggleConfidenceState'
    }),
    checkIndividualLicence (metric) {
      if (this.metrics.find(m => metric === m.key)) {
        return
      }

      // Redirect to first tab metric
      // This probably doesn't work - getSiblingsOfChart returns an array
      const firstTabMetric = getSiblingsOfChart(router.currentRoute.value.params.chart)
      if (this.metrics.find(m => m.key === firstTabMetric)) {
        this.navigateToChart({ metric: firstTabMetric })
        return
      }

      const type = metric.split('_')[1]

      // Redirect to the first metric from the same type that the user has licence for or no licence required
      const firstTabNewType = getSiblingsOfChart(router.currentRoute.value.params.chart)[0].split('_')[0] + '_' + metric.split('_')[1]
      if (this.metrics.find(m => m.key === firstTabNewType)) {
        this.navigateToChart({
          route: findPerfomanceRoute(firstTabNewType),
          metric: firstTabNewType
        })
        return
      }

      const alternative = this.metrics.find(metric =>
        metric.type === type &&
          (
            !metric.licences ||
            this.user.licences.includes(metric.licences[type])
          )
      )

      if (alternative) {
        this.navigateToChart({ metric: alternative.key })
      }
    },
    checkLicences (params) {
      this.checkIndividualLicence(params['hero'])
      this.checkIndividualLicence(params['chart'])
    },
    setDataOfParams (params, onlyFrm = false) {
      if (!params) return

      this.checkLicences(params)

      const methods = onlyFrm ? ['frmchart'] : ['hero', 'chart', 'frmchart']

      return Promise.all(methods.map(p => {
        const metricKey = p === 'frmchart' ? 'chart' : p

        return this[`set${capitalize(p)}`]({
          metric: this.metrics.find(m => params[metricKey] === m.key),
          location: params.location,
          agg: params.agg,
          date: params.date === LATEST_STRING
            ? this.isWeekly(params)
              ? getWeeklyDate(this.dashboardInfo.last_date_available, 3, this.dashboardInfo.first_date_available)
              : this.dashboardInfo.last_date_available
            : params.date,
          dashboard: 'performance'
        })
      }))
    },
    signOut () {
      this.resetUser()
      router.replace({
        name: ROUTES.Login
      })
    },
    isWeekly ({
      metric = false,
      chart = router.currentRoute.value.params.chart,
      hero = router.currentRoute.value.params.hero
    }) {
      const heroCat = get(this.metrics.find(m => m.key === hero), 'category')
      const chartCat = get(this.metrics.find(m => m.key === chart), 'category')
      const isWeekly =
          WEEKLY_METRICS_CATEGORIES.includes(chartCat) ||
          WEEKLY_METRICS_CATEGORIES.includes(heroCat) ||
          router.currentRoute.value.params.isWeekly

      if (metric) {
        const metricCat = get(this.metrics.find(m => m.key === metric), 'category')
        return isWeekly || WEEKLY_METRICS_CATEGORIES.includes(metricCat)
      }

      return isWeekly
    },
    navigateToChart ({
      mode,
      metric,
      location = router.currentRoute.value.params.location,
      date = router.currentRoute.value.params.date,
      agg = router.currentRoute.value.params.agg,
      chart = router.currentRoute.value.params.chart,
      hero = router.currentRoute.value.params.hero,
      route = null,
      geocoding = null,
      isAlternate
    }) {
      if (mode && mode !== 'performance') {
        this.navigateToDashboard({
          mode: mode,
          router: router.currentRoute.value
        })
      } else {
        if (location === 'undefined') {
          location = router.currentRoute.value.params.location
        }

        const isWeekly = this.isWeekly({ metric, hero, chart })

        let weeklyDate

        if (isWeekly) {
          if (date === LATEST_STRING) {
            weeklyDate = LATEST_STRING
          } else {
            weeklyDate = getWeeklyDate(date, 3, this.dashboardInfo.first_date_available)
          }
        }

        // when metric changes
        if (metric && date === router.currentRoute.value.params.date && agg === router.currentRoute.value.params.agg) {
          if (metric.split('_')[0] === this.menuMetric.key.split('_')[0]) {
            hero = `${hero.split('_')[0]}_${metric.split('_')[1]}`
            chart = `${chart.split('_')[0]}_${metric.split('_')[1]}`
          } else {
            if (isHero(metric)) {
              hero = metric
              chart = getChartOfHero(metric)
            } else {
              hero = getHeroOfChart(metric)
              chart = metric
            }
          }
        } else {
          hero = getHeroOfChart(chart)
        }

        // when aggregation, metric type, location or date changes keep route name unchanged
        let keepRouteName = false
        if (
          chart.split('_')[0] === router.currentRoute.value.params.chart.split('_')[0] && (
            agg !== router.currentRoute.value.params.agg ||
            chart.split('_')[1] !== router.currentRoute.value.params.chart.split('_')[1] ||
            location !== router.currentRoute.value.params.location ||
            date !== router.currentRoute.value.params.date
          )
        ) {
          keepRouteName = true
        }

        const routeName = keepRouteName ? router.currentRoute.value.name : findPerfomanceRoute(chart, isAlternate)
        const name = route || routeName

        if (name === ROUTES.PerformanceIntelligenceOverview) {
          if (this.dashboardInfo.pi_overview_page !== '5G') {
            hero = `${hero.split('_')[0]}_lte`
            chart = `${chart.split('_')[0]}_lte`
          }
        }

        hero = toMainMetric(hero)

        if (
          !FIVEG_SUBTYPES_METRICS.includes(hero.split('_')[0]) &&
          FIVEG_METRICS_TYPES.includes(chart.split('_')[1])
        ) {
          chart = toMainMetric(chart)
        }

        router.push({
          name,
          params: {
            hero,
            chart,
            location,
            agg: agg,
            date: weeklyDate || date,
            geocoding: geocoding || this.defaultGeocoding
          }
        })
      }
    },
    getDashboardDefaults () {
      const overviewPageConfig = this.dashboardInfo.pi_overview_page
      const isOverview = this.homeNetwork && overviewPageConfig

      let defaultTechnology = 'lte'

      if (this.display5G && (!isOverview || overviewPageConfig === '5G')) {
        defaultTechnology = '5g'
      }

      let presentation, chart, hero

      let category = (router.currentRoute.value.params && router.currentRoute.value.params.category) || 'experience'

      let displayMenuItems = this.menuConfig.metrics.reduce((acc, menuItem) => {
        const availableCategories = this.metricCategoriesByTech[defaultTechnology]
        const hasLicence = !menuItem.metricCategory || availableCategories.includes(menuItem.metricCategory)

        if (menuItem.category === category && hasLicence) {
          acc.push(menuItem)
        }

        return acc
      }, [])

      if (displayMenuItems.length) {
        chart = displayMenuItems[0].main + '_' + defaultTechnology
        hero = getHeroOfChart(chart)

        if (hero === 'no_metric') {
          hero = displayMenuItems[0].main + '_' + defaultTechnology
          chart = getChartOfHero(hero)
        }

        presentation = getRawSiblings(chart)[0]
      }

      const routeChart = router.currentRoute.value.params.chart || chart
      const routeName = isOverview
        ? ROUTES.PerformanceIntelligenceOverview
        : findPerfomanceRoute(routeChart, false)

      return {
        presentation,
        hero,
        chart,
        routeName
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

.LoadingWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PerfomanceIntelligence {
  &__menu {
    overflow-y: scroll;
    z-index: $z-index-ai-left-menu;
    max-height: 100vh;
  }

  &__hero {
    // whatever we need for the top bar into
  }

  &__chart {
    position: relative;
    padding: $padding-medium 0;
  }
}
</style>
