<template>
  <div
    :class="componentClass"
    :style="componentStyle"
    class="OperatorAvatar"
  >
    {{ letter }}
  </div>
</template>

<script>
import { getTextColor } from '@/utils/colors'

export default {
  name: 'OperatorAvatar',
  props: {
    letter: { type: String, required: true },
    backgroundColor: { type: String, required: true },
    borderLight: { type: Boolean, default: false },
    size: { type: String, default: 'md' }
  },
  computed: {
    componentStyle () {
      return {
        backgroundColor: this.backgroundColor,
        color: getTextColor(this.backgroundColor)
      }
    },
    componentClass () {
      return {
        'OperatorAvatar--border-light': this.borderLight
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .OperatorAvatar {
    $size: 1.6em;

    border-radius: 50%;
    display: inline-block;
    line-height: $size;
    text-align: center;
    width: $size;
    background-color: $color-gray-light;
    color: white !important;
    border:  1px solid transparent;
    &--border-light {
      border: 1px solid $color-white;
    }
  }
</style>
