<template>
  <div
    v-if="label"
    class="Indicator"
    :class="cssClass"
    @click="$emit('click')"
  >
    {{ label }}
  </div>
  <div
    v-else
    class="Indicator--empty"
  />
</template>

<script>
import { MG_STRATEGIES } from '@/constants/constants'

export default {
  name: 'Indicator',
  props: {
    label: {
      type: [String, Number],
      default: undefined
    },
    strategy: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      strategies: MG_STRATEGIES
    }
  },
  computed: {
    cssClass () {
      let classes = {
        'Indicator--small': this.size === 'small'
      }

      if (this.strategy) {
        classes['Indicator--' + this.strategy] = true
        classes['Indicator--strategy'] = true
      }

      return classes
    }
  }
}
</script>

<style scoped lang="scss">
@import "scss/variables";
@import "~foundation-sites/scss/foundation";

.Indicator {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 30px;
  height: 30px;
  border: 2px solid $color-white;
  background: $mg-base;
  color: $color-white;
  font-size: $font-size-14;

  &--small {
    width: 22px;
    height: 22px;
    font-size: $font-size-12;
  }

  &--strategy {
    font-size: $font-size-18;
  }

  &--attack {
    background: $mg-attack;
  }

  &--harvest {
    background: $mg-harvest;
  }

  &--defend {
    background: $mg-defend;
  }

  &--opportunistic {
    background: $mg-opportunistic;
  }

  &--empty {
    margin: 0 auto;
    width: 6px;
    height: 6px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: $mg-no-strategy;
    opacity: .6;
  }
}
</style>
