<template>
  <div
    id="disclaimer"
    class="modal"
    style="display: none"
  >
    <div class="modalContent">
      <h1>Welcome to Opensignal Analytics Solutions</h1>
      <p><em>Your access to Opensignal Analytics Solutions are subject to the licensing terms and conditions between Opensignal and your organisation or employer. Please refer to the license terms and conditions for details on the permitted use of the content and restrictions applying to your use or distribution of the content. In particular please note you must not disclose or distribute any of the content contained within or share your username or password to the Analytics Solutions with anyone. You will promptly inform Opensignal of any unauthorized use of your user name or password. You will be solely responsible for all activities that occur under your username and password.</em></p>
      <p><em><strong>If you obtained access to the Analytics Solutions and neither you, your organisation or employer have a valid license from Opensignal, you should immediately <a href="https://www.opensignal.com/contact">contact us</a> and must not proceed to access or use the content.</strong></em></p>
      <button @click="confirm()">
        Agree
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Disclaimer',
  methods: {
    ...mapActions([
      'setDisclaimerTimestamp'
    ]),
    confirm () {
      this.setDisclaimerTimestamp()
      this.$el.style.display = 'none'
    }
  }
}
</script>

<style lang="scss">
    @import 'scss/variables';

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
        background: rgba(80,100,130,.5);
    }
    .modalContent {
        width: 675px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        background: #fff;
        border-radius: 10px;
        padding: 40px;
        transform: translate(-50%, -50%);
        box-sizing: border-box;

        h1 {
            font-weight: 300;
            font-size: 40px;
            margin: 16px 0 32px;
        }

        p {
            line-height: 24px;
            font-size: 17px;
            margin: 25px 0;
        }

        button {
            width: 177px;
            height: 62px;
            border: 0;
            background: $color-electric-blue;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            color: #fff;
            margin-top: 10px;
        }

        a {
            color: $color-electric-blue;
        }
    }
</style>
