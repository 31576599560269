<template>
  <div
    class="BarListItem"
    @click="e => $emit('click', e)"
    @mouseenter="e => $emit('mouseenter', e)"
    @mouseleave="e => $emit('mouseleave', e)"
  >
    <div class="BarListItem__name">
      <CustomText truncate>
        {{ name }}
      </CustomText>
    </div>
    <div>
      <span class="BarListItem__value">
        {{ label || value }}<span v-if="isImportance">%</span><span v-if="!isImportance">{{ standardUnit }}</span>
      </span>
      <span
        v-if="confidenceVisible"
        class="BarListItem__confidence"
      >
        &nbsp;{{ lci }} / {{ uci }}</span>
    </div>
    <div class="BarListItem__bar">
      <div
        v-if="confidenceVisible"
        :style="uciBarStyle"
        class="BarListItem__uci-bar"
      />
      <div
        :style="valueBarStyle"
        class="BarListItem__value-bar"
      />
      <div
        v-if="confidenceVisible"
        :style="confidenceIntervalStyle"
        class="BarListItem__confidence-interval"
      >
        <div class="BarListItem__confidence-interval-inner">
          <div class="BarListItem__confidence-interval-line" />
        </div>
      </div>
    </div>
    <div v-if="isImportance">
      <span
        class="BarListItem__value"
      >{{ metricValue }}{{ standardUnit }}</span>
    </div>
    <div
      v-if="isImportance"
      class="BarListItem__bar"
    >
      <div
        :style="baseMetricValueBarStyle"
        class="BarListItem__value-bar"
      />
    </div>
  </div>
</template>

<script>
import min from 'lodash.min'
import CustomText from '../CustomText'
import router from '@/router'

const isFinite = Number.isFinite

export default {
  name: 'BarListItem',
  components: { CustomText },
  props: {
    name: { type: String, required: true },
    value: { type: Number, default: undefined },
    baseValue: { type: Number, default: undefined },
    label: { type: String, default: undefined },
    lci: { type: Number, default: undefined },
    uci: { type: Number, default: undefined },
    unit: { type: String, default: '' },
    min: { type: Number, required: true },
    baseMin: { type: Number, default: undefined },
    max: { type: Number, required: true },
    baseMax: { type: Number, default: undefined },
    color: { type: String, required: true },
    baseColor: { type: String, default: undefined },
    metricValue: { type: String, default: '' }
  },
  computed: {
    standardUnit () {
      // units are used as labels and can not be numerical scales
      // as design does not support it
      return Number.isFinite(parseInt(this.unit[0])) ? '' : this.unit
    },
    lowerExtreme () {
      return min([this.min, 0])
    },
    baseLowerExtreme () {
      return min([this.baseMin, 0])
    },
    valueFromLowerExtreme () {
      return this.value - this.lowerExtreme
    },
    baseValueFromLowerExtreme () {
      return this.baseValue - this.baseLowerExtreme
    },
    lciFromLowerExtreme () {
      return this.lci - this.lowerExtreme
    },
    uciFromLowerExtreme () {
      return this.uci - this.lowerExtreme
    },
    upperExtremeFromLowerExtreme () {
      return (this.max || 100) - this.lowerExtreme
    },
    baseUpperExtremeFromLowerExtreme () {
      return (this.baseMax || 100) - this.baseLowerExtreme
    },
    valueBarStyle () {
      const { color, valueFromLowerExtreme, upperExtremeFromLowerExtreme } = this
      return {
        width: (isFinite(valueFromLowerExtreme) && isFinite(upperExtremeFromLowerExtreme)) &&
          `${(valueFromLowerExtreme / upperExtremeFromLowerExtreme) * 100}%`,
        backgroundColor: color
      }
    },
    metricValueBarStyle () {
      const { color, valueFromLowerExtreme, upperExtremeFromLowerExtreme } = this
      return {
        width: (isFinite(valueFromLowerExtreme) && isFinite(upperExtremeFromLowerExtreme)) &&
          `${(valueFromLowerExtreme / upperExtremeFromLowerExtreme) * 100}%`,
        backgroundColor: color
      }
    },
    baseMetricValueBarStyle () {
      const { baseColor, baseValueFromLowerExtreme, baseUpperExtremeFromLowerExtreme } = this
      return {
        width: (isFinite(baseValueFromLowerExtreme) && isFinite(baseUpperExtremeFromLowerExtreme)) &&
          `${(baseValueFromLowerExtreme / baseUpperExtremeFromLowerExtreme) * 100}%`,
        backgroundColor: baseColor
      }
    },
    uciBarStyle () {
      const { color, uciFromLowerExtreme, upperExtremeFromLowerExtreme } = this
      return {
        width: (isFinite(uciFromLowerExtreme) && isFinite(upperExtremeFromLowerExtreme)) &&
          `${(uciFromLowerExtreme / upperExtremeFromLowerExtreme) * 100}%`,
        backgroundColor: color
      }
    },
    confidenceIntervalStyle () {
      const { uciFromLowerExtreme, lciFromLowerExtreme, upperExtremeFromLowerExtreme } = this
      const leftPerc = (isFinite(lciFromLowerExtreme) && isFinite(upperExtremeFromLowerExtreme)) &&
        (lciFromLowerExtreme / upperExtremeFromLowerExtreme) * 100
      const widthPerc = (isFinite(lciFromLowerExtreme) && isFinite(upperExtremeFromLowerExtreme) && isFinite(uciFromLowerExtreme)) &&
        ((uciFromLowerExtreme - lciFromLowerExtreme) / upperExtremeFromLowerExtreme) * 100
      return {
        left: leftPerc && `${Math.abs(leftPerc)}%`,
        width: widthPerc && `${Math.abs(widthPerc)}%`
      }
    },
    confidenceVisible () {
      return isFinite(this.uci) && isFinite(this.lci)
    },
    isImportance () {
      return router.currentRoute.value.rank === 'weight'
    }
  }
}
</script>

<style lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';

  $confidence-color: black;
  $default-background-color: gray;
  $border-radius: 2px;

  .BarListItem {
    padding: 3px;
    border-radius: $border-radius;

    &:hover {
      @include elevation($elevation-2);
      background-color: rgba($color-black, .1);
      cursor: pointer;
    }
    &__confidence {
      font-size: $font-size-10;
      line-height: $font-size-10;
    }
    &__value {
      font-size: $font-size-14;
      line-height: $font-size-14;

      small {
        display: inline-block;
        margin-left: 1rem;
        color: $color-blue-primary;
      }
    }
    &__bar {
      position: relative;
      height: 10px;
    }
    &__name {
      font-weight: $font-weight-roboto-bold;
      font-size: $font-size-10;
      line-height: $font-size-10;
    }
    &__uci-bar {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $default-background-color;
      opacity: .25;
      border-radius: $border-radius;
    }
    &__value-bar {
      background-color: $default-background-color;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
      border-radius: $border-radius;
    }
    &__confidence-interval {
      border: 1px solid $confidence-color;
      border-bottom: 0;
      border-top: 0;
      bottom: 0;
      left: 50%;
      position: absolute;
      top: 0;
      width: 0;
      box-sizing: border-box;
    }
    &__confidence-interval-inner {
      height: 100%;
      position: relative;
    }
    &__confidence-interval-line {
      border-bottom: 1px solid $confidence-color;
      border-top: 0;
      height: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
