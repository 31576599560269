<template>
  <div
    :class="{'RadioItem--selected': selected}"
    class="RadioItem"
    @click="$emit('click')"
  >
    <div class="RadioItem__input">
      <input
        :name="name"
        :checked="selected"
        type="radio"
        style="display:none"
      >
    </div>
    <div
      v-if="$slots['icon']"
      class="RadioItem__icon"
    >
      <slot name="icon" />
    </div>
    <div class="RadioItem__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {}
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .RadioItem {
    $radio-items-gutters: 16px;
    @include xy-grid(horizontal, $wrap: false);
    padding: $padding-small;
    border-radius: $border-radius-small;
    align-items: center;
    font-size: $font-size-13;
    margin-bottom: 1px;
    &:hover {
      background-color: $color-electric-blue;
      color: $white;
      cursor: pointer;
    }
    &--selected {
      background-color: $color-blue-primary;
      color: $white;
      &:hover {
        cursor: default;
      }
    }
    &__input {
      @include xy-cell(shrink, $gutters: $radio-items-gutters);
    }
    &__icon {
      @include xy-cell(shrink, $gutter-position: right, $gutters: $radio-items-gutters)
    }
    &__content {
      @include xy-cell(auto, $gutter-position: right, $gutters: $radio-items-gutters);
      white-space: nowrap;
    }
  }
</style>
