<template>
  <div class="CustomTooltip">
    <div
      ref="tooltipDirective"
      v-click-outside="close"
      v-tooltip="{
        content: message,
        html: true,
        triggers: [isMobile ? 'click' : 'hover'],
        placement,
        distance,
        delay: {
          show: 0,
          hide: 0,
        },
        hideTriggers: [isMobile ? 'click' : 'hover'],
        popperClass: 'tooltip'
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomTooltip',
  props: {
    message: { type: String, required: true },
    distance: { type: Number, default: 16},
    placement: { type: String, default: 'right' }
  },
  computed: {
    ...mapGetters({
      isMobile: 'page/isMobile'
    })
  },
  methods: {
    close () {
      this.$refs.tooltipDirective?._tooltip?.hide()
    }
  }
}
</script>

<style lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';

  $background-color: $color-blue-bg-dark;
  $font-color: $color-white;
  $padding: $padding-medium;
  $padding-alt: 5px;
  $box-shadow: 0 $padding-alt $padding-alt*2 rgba(black, .1);

  .CustomTooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip.v-popper--theme-tooltip {
    display: block !important;
    z-index: $z-index-tooltip;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      z-index: $z-index-tooltip-mobile;
    }

    .v-popper__inner {
      @include elevation($elevation-2);
      background: $background-color;
      color: $color-white;
      border-radius: $border-radius-medium;
      padding: $padding-large;
      max-width: pxToRem(252);
      font-size: $font-size-12;
      line-height: $font-size-14;
    }

    .v-popper__arrow-outer {
      border-color: $background-color;
    }
  }
</style>
