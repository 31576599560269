<template>
  <div>
    <IpTable
      :columns="columns"
      :loading="chartPending"
      :formatted-data="formattedData"
    >
      <template #headerTools>
        <CsvExport @click="exportData" />
      </template>
    </IpTable>
    <div
      v-for="trendChart in trendCharts"
      :key="trendChart.id"
    >
      <ChartWrapper
        :title="getGroupTitle(trendChart)"
        :loading="chartPending"
        :empty="formattedData.length === 0"
        :disable-export="true"
        :title-labels="titleLabels.chartTitle"
        unit="%"
        legend-title="Cloud Providers"
      >
        <stacked-dist
          :data-set="trendChart.data"
          :labels="trendChart.labels"
          y-axis-label="IP utilization by CDN by operator (%)"
          :chart-id="`${trendChart.id}`"
          tooltip-metric="IP Address"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'
import CsvExport from '@/components/CsvExport'
import IpTable from '@/components/performance/IpTable'
import { ChartWrapper, StackedDist } from '@/components/visual'
import { CDN_LABELS } from '@/constants/constants'
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats'
import router from '@/router'
import { getRangeFromSelectedDate, getSafeDate } from '@/utils/date'
import { exportToCsv } from '@/utils/files'
import { formatIpDataForDistCharts, formatIpTableData, mergeDataByIps } from '@/utils/ip'

export default {
  name: 'ChartIp',
  components: {
    IpTable,
    ChartWrapper,
    StackedDist,
    CsvExport
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      date: 'dashboard/date',
      chartData: 'chart/ip',
      networks: 'charts/networks',
      chartPending: 'chart/chartPending',
      chartMetric: 'chart/metric',
      titleLabels: 'charts/titleLabels'
    }),
    dataForSelectedDate () {
      return this.chartData.filter(scan => scan.date === this.date)
    },
    selectedMetricTitle () {
      return this.chartMetric.kind === 'upload'
        ? 'Mean Upload Speed (Mbps)'
        : 'Mean Download Speed (Mbps)'
    },
    columns () {
      return [
        {
          label: 'Operator',
          key: 'operator'
        },
        {
          label: 'End Point',
          key: 'endPoint'
        },
        {
          label: 'Speed IP',
          key: 'ip'
        },
        {
          label: '% Of Total Tests',
          key: 'percentageOfTests'
        },
        {
          label: this.selectedMetricTitle,
          key: 'value'
        }
      ]
    },
    formattedData () {
      return formatIpTableData(this.dataForSelectedDate, CDN_LABELS, this.getNetwork)
    },
    trendCharts () {
      const [selectedStartDate, selectedEndDate] = getRangeFromSelectedDate(this.date, 30, this.dashboardInfo.last_date_available)
      const trendCharts = formatIpDataForDistCharts(this.chartData, selectedStartDate, selectedEndDate)
      return trendCharts.filter(trendChart => Boolean(this.getGroupTitle(trendChart)))
    }
  },
  methods: {
    ...mapActions([
      'getMetricsData'
    ]),
    getGroupTitle (group) {
      const [canonicalNetworkId, endpoint] = group.id.split('-')
      const network = this.getNetwork(canonicalNetworkId)
      if (!network) {
        return null
      }
      return `${network.name_mapped} - ${CDN_LABELS[endpoint]}`
    },
    getNetwork (id) {
      return this.networks.find(network => network.canonical_network_id === parseInt(id))
    },
    async exportData () {
      const params = router.currentRoute.value.params
      const responses = await this.getMetricsData({
        metrics: [this.chartMetric.key],
        location: params.location,
        agg: params.agg,
        date: this.date,
        dashboard: 'performance'
      })
      const allMetricsData = []
      responses.forEach(metricData => {
        const formattedData = formatIpTableData(metricData.results, CDN_LABELS, this.getNetwork, metricData.metric)
        allMetricsData.push(...formattedData)
      })
      const mergedData = mergeDataByIps(allMetricsData)
      this.exportFormattedData(Object.values(mergedData))
    },
    exportFormattedData (formattedData) {
      if (!formattedData || formattedData.length === 0) {
        return
      }
      const titles = [
        'Operator',
        'End Point',
        'IP',
        '% OF Total Tests',
        this.selectedMetricTitle,
        'Date'
      ]
      const exportData = formattedData.map(data => ([
        data.operator,
        data.endPoint,
        data.ip,
        data.percentageOfTests,
        data.ipdownload || data.ipupload,
        format(getSafeDate(data.date), API_LONG_DATE_FORMAT)
      ]))
      const date = getSafeDate(formattedData[0].date)
      exportToCsv(this.chartMetric.name + ' ' + format(date, API_LONG_DATE_FORMAT) + '.csv', [titles, ...exportData])
    }
  }
}
</script>
