<template>
  <div class="NetworkBarList">
    <div v-if="overviewEnabled">
      <bar-list :title="`Top ${itemsDataTopFive.length}`">
        <bar-list-item
          v-for="item in itemsDataTopFive"
          :key="item.name"
          :name="item.name"
          :value="item.value"
          :base-value="item.baseValue"
          :label="item.label"
          :lci="item.lci"
          :uci="item.uci"
          :min="min"
          :base-min="baseMin"
          :max="max"
          :base-max="baseMax"
          :color="item.color"
          :base-color="item.baseColor"
          :unit="unit"
          :metric-value="item.metricValue"
          @click="handleClick(item, item.index)"
          @mouseenter="handleMouseEnter(item, item.index)"
          @mouseleave="handleMouseLeave(item, item.index)"
        />
      </bar-list>
      <bar-list
        v-if="!isImportance"
        :title="`Bottom ${itemsDataBottomFive.length}`"
      >
        <bar-list-item
          v-for="item in itemsDataBottomFive"
          :key="item.name"
          :name="item.name"
          :value="item.value"
          :label="item.label"
          :lci="item.lci"
          :uci="item.uci"
          :min="min"
          :max="max"
          :color="item.color"
          :unit="unit"
          :metric-value="item.metricValue"
          @click="handleClick(item, item.index)"
          @mouseenter="handleMouseEnter(item, item.index)"
          @mouseleave="handleMouseLeave(item, item.index)"
        />
      </bar-list>
    </div>
    <bar-list
      v-else
      :title="title"
    >
      <bar-list-item
        v-for="item in processedItemsData"
        :key="item.name"
        :name="item.name"
        :value="item.value"
        :base-value="item.baseValue"
        :label="item.label"
        :lci="item.lci"
        :uci="item.uci"
        :min="min"
        :base-min="baseMin"
        :max="max"
        :base-max="baseMax"
        :color="item.color"
        :base-color="item.baseColor"
        :unit="unit"
        :metric-value="item.metricValue"
        @click="handleClick(item, item.index)"
        @mouseenter="handleMouseEnter(item, item.index)"
        @mouseleave="handleMouseLeave(item, item.index)"
      />
    </bar-list>
  </div>
</template>

<script>
import take from 'lodash.take'
import takeRight from 'lodash.takeright'
import BarList from './BarList'
import BarListItem from './BarListItem'
import router from '@/router'

export default {
  name: 'NetworkBarList',
  components: { BarListItem, BarList },
  props: {
    title: { type: String, required: true },
    itemsData: { type: Array, required: true },
    min: { type: Number, required: true },
    baseMin: { type: Number, default: undefined },
    max: { type: Number, required: true },
    baseMax: { type: Number, default: undefined },
    overviewEnabled: { type: Boolean, default: true },
    unit: { type: String, default: '' }
  },
  computed: {
    processedItemsData () {
      return this.itemsData.map((i, index) => ({
        ...i,
        index,
        metricValue: this.getMetricValue(i)
      }))
    },
    itemsDataTopFive () {
      const itemData = this.processedItemsData
      return take(itemData, itemData.length > 10 ? 5 : Math.ceil(itemData.length / 2))
    },
    itemsDataBottomFive () {
      const itemData = this.processedItemsData
      return takeRight(itemData, itemData.length > 10 ? 5 : Math.floor(itemData.length / 2))
    },
    isImportance () {
      return router.currentRoute.value.params.rank === 'weight'
    }
  },
  methods: {
    handleMouseEnter (item) {
      return this.$emit('mouseEnterItem', item)
    },
    handleMouseLeave (item) {
      return this.$emit('mouseLeaveItem', item)
    },
    handleClick (item) {
      return this.$emit('clickItem', item)
    },
    getMetricValue (item) {
      const { lb, ub, mean } = item

      if (Number.isFinite(ub) && Number.isFinite(lb)) return `${lb} - ${ub}`
      if (!Number.isFinite(lb) && Number.isFinite(ub)) return `< ${ub}`
      if (!Number.isFinite(ub) && Number.isFinite(lb)) return `> ${lb}`

      return `${mean}`
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  .NetworkBarList {}
</style>
