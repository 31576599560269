<template>
  <div
    v-click-outside="close"
    class="button-dropdown"
  >
    <div
      :class="{'button-dropdown__button--light': lightTheme}"
      class="button-dropdown__button"
      @click="handleButtonClick"
    >
      {{ selectedOption[dataName] }}
      <span
        v-if="selectedOption.faIcon"
        class="button-dropdown__icon"
      >
        <FontAwesomeIcon
          :icon="selectedOption.faIcon"
          size="lg"
        />
      </span>
    </div>
    <div
      :class="{'button-dropdown__dropdown--visible': menuOpen}"
      class="button-dropdown__dropdown"
    >
      <div
        v-for="option in options"
        :key="option[dataKey]"
        class="button-dropdown__option"
      >
        <div
          class="button-dropdown__content"
          @click="handleOptionSelect(option)"
        >
          <CustomText truncate>
            {{ option[dataName] }}
            <span
              v-if="option.faIcon"
              class="button-dropdown__icon"
            >
              <FontAwesomeIcon
                :icon="option.faIcon"
                size="lg"
              />
            </span>
          </CustomText>
        </div>
        <label
          v-if="option.checkbox"
          :class="`button-dropdown__checkbox--${option.checkbox.position}`"
          class="button-dropdown__checkbox"
        >
          <input
            :checked="option.checkbox.checked"
            type="checkbox"
            @change="handleOptionCheck(option)"
          >
          {{ option.checkbox.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomText from '../CustomText'

export default {
  name: 'ButtonDropdown',
  components: {
    CustomText,
    FontAwesomeIcon
  },
  props: {
    selectedOptionKey: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    dropdownOpen: {
      type: Boolean,
      default: false
    },
    dataKey: {
      type: String,
      default: 'key'
    },
    dataName: {
      type: String,
      default: 'title'
    },
    lightTheme: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      menuOpen: false,
      addCheckbox: false
    }
  },
  computed: {
    selectedOption: function () {
      const { options, selectedOptionKey, dataKey } = this
      return options && options.find((o) => o[dataKey] === selectedOptionKey)
    }
  },
  methods: {
    close () {
      this.menuOpen = false
    },
    handleButtonClick () {
      this.menuOpen = !this.menuOpen
    },
    handleOptionSelect (option) {
      this.handleButtonClick()
      this.$emit('select-option', option[this.dataKey])
    },
    handleOptionCheck (option) {
      this.handleButtonClick()
      this.$emit('select-option-checkbox', {
        key: option[this.dataKey],
        checked: !option.checkbox.checked
      })
    }
  }
}
</script>

<style lang="scss">
@import '@material/elevation';
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

.button-dropdown {
  border-radius: $control-border-radius;
  cursor: pointer;
  font-size: $font-size-13;
  position: relative;

  &__button {
    background-color: $color-blue-primary;
    border-radius: $control-border-radius;
    color: $color-white;
    height: $control-height;
    line-height: $control-height;
    padding: 0 $padding-medium;
    border: $control-border;

    &:hover {
      background-color: $control-dark-bg-color--hover;
    }

    &--light {
      background-color: $color-white;
      color: $color-blue-primary;

      &:hover {
        background-color: $control-dark-bg-color--hover;
        color: $control-dark-text-color--hover;
      }
    }
  }

  &__dropdown {
    @include dropdown-container;
    border-radius: $control-border-radius;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: $dropdown-max-height;
    margin-top: 5px;
    top: $control-height;
    border: none;
    @include elevation($elevation-4);

    &--visible {
      display: block;
      visibility: visible;
    }
  }

  &__option  {
    font-size: $font-size-13;
    line-height: $control-height;
    padding: 0 $padding-medium;
    border-bottom: 1px solid $color-gray-light;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: $control-dark-bg-color--hover;
      color: $control-dark-text-color--hover;
    }
  }

  &__content {
    width: 100%;
  }

  &__icon {
    float: right;
  }

  &__checkbox {
    cursor: pointer;
    font-size: $font-size-10;

    &--below {
      display: block;
      width: 100%;
    }
  }
}
</style>
