<template>
  <div class="MetricsSelector">
    <div class="ParametersLayout__aggregation">
      <template v-if="slim">
        <FieldGroup
          label="Metric"
        >
          <list-dropdown
            :items="metricList"
            @selected="selectMetric"
          />

          <div
            v-if="displayTechnologyToggle"
            class="MetricsSelector__technologySwitch"
            @click="setTechnology(altTechnology)"
          >
            <a>Switch to <span class="MetricsSelector__technologySwitch__technologyLabel">{{ altTechnologyLabel }}</span></a>
          </div>
        </FieldGroup>
        <FieldGroup
          v-if="overallMetricList.length > 0"
          label="Overall"
        >
          <list-dropdown
            :items="overallMetricList"
            @selected="selectMetric"
          />
        </FieldGroup>
      </template>
      <template v-else>
        <div class="MetricsSelector__technologyTab">
          <FieldGroup
            :disabled="lteOnly"
            label="Technology"
          >
            <AccordionGroup
              v-model="technology"
              class="technologyAccordion"
              :options="technologies"
              active
            />
          </FieldGroup>
          <FieldGroup
            v-show="displayFrequencyRanges"
            label="Frequency Range"
            :tooltip="frequencyRangeTooltipMessage"
          >
            <AccordionGroup
              v-model="technology"
              class="frAccordion"
              :options="frequencyRanges"
              active
            />
          </FieldGroup>
        </div>
        <div
          v-for="metric in displayAllMetrics"
          :key="metric.main"
          :class="{'MetricsSelector__metric--expanded': metric.expanded}"
          class="MetricsSelector__metric"
        >
          <div
            :class="{
              'MetricsSelector__mainmetric--expanded': metric.expanded,
              'MetricsSelector__mainmetric--active': isActive(metric.main, 'main') && !metric.expanded
            }"
            class="MetricsSelector__mainmetric"
            @click="toggle(metric)"
          >
            {{ metric.label }}
            <FontAwesomeIcon
              v-if="metric.expand"
              :icon="metric.expanded ? icons.faMinusCircle : icons.faPlusCircle"
              class="MetricsSelector__mainmetric--icon"
            />
          </div>
          <div v-if="metric.expanded">
            <div
              v-for="sub in metric.sub"
              :key="sub.metric"
              :class="{'MetricsSelector__submetric--active': isActive(sub.metric, 'sub')}"
              class="MetricsSelector__submetric"
              @click="selectSubMetric(metric, sub.metric)"
            >
              {{ sub.label }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  faPlusCircle,
  faMinusCircle
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import mp from 'mixpanel-browser'
import { mapGetters } from 'vuex'
import {
  AccordionGroup,
  FieldGroup,
  ListDropdown
} from '@/components/menu'
import {
  PI_METRICS_MENU,
  PI_OVERVIEW_METRICS_MENU,
  PI_OVERVIEW_PREV_BROADBAND_TECH,
  METRIC_TYPES,
  METRIC_TYPE_NAMES,
  DEPLOYMENT_OPTIONS,
  FREQUENCY_RANGES,
  FIVEG_SUBTYPES_METRICS,
  FIVEG_METRIC_SUBTYPES,
  FIVEG_METRICS_TYPES
} from '@/constants/constants'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import { findMetricGroupForRouteName } from '@/utils/metrics'

export default {
  name: 'MetricsSelector',
  components: {
    FontAwesomeIcon,
    AccordionGroup,
    FieldGroup,
    ListDropdown
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    lteOnly: {
      type: Boolean,
      default: false
    },
    licences: {
      type: Array,
      default: () => []
    },
    category: {
      type: String,
      default: null
    },
    slim: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      deployment: ['Standalone', 'Non-standalone'],
      frequencyRangeTooltipMessage: TOOLTIP_MESSAGES.frequencyRange,
      icons: {
        faPlusCircle,
        faMinusCircle
      }
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo',
      'user'
    ]),
    ...mapGetters({
      mainMetric: 'metrics/primaryMetric',
      metric: 'metrics/menuMetric',
      metricCategory: 'metrics/category',
      display3GUserExperience: 'metrics/display3GPIUserExperience',
      display4GUserExperience: 'metrics/display4GPIUserExperience',
      display5GUserExperience: 'metrics/display5GPIUserExperience',
      display5GMmWaveUserExperience: 'metrics/display5GMmWavePIUserExperience',
      availableMetricCategories: 'metrics/availableMetricCategories',
      metricCategoriesByTech: 'metrics/metricCategoriesByTech',
      defaultTechnology: 'metrics/defaultTechnology'
    }),
    metrics () {
      if (this.slim) {
        return PI_OVERVIEW_METRICS_MENU
      }
      return PI_METRICS_MENU
    },
    ismmWaveEnabled () {
      const mmWaveCategories = this.metricCategoriesByTech[METRIC_TYPES.Mmwave]

      return mmWaveCategories && mmWaveCategories.length
    },
    technologies () {
      const threeG = {
        title: '3G',
        key: '3g',
        disabled: !this.display3GUserExperience
      }
      const fourG = {
        title: '4G',
        key: 'lte',
        disabled: !this.display4GUserExperience
      }
      const fiveG = {
        title: '5G',
        key: '5g',
        highlighted: FIVEG_METRIC_SUBTYPES.includes(this.technology),
        disabled: !this.display5GUserExperience
      }
      const mmWave = {
        title: 'mmWave',
        key: '5gmmwave',
        disabled: !this.ismmWaveEnabled || !this.display5GMmWaveUserExperience
      }
      const overall = {
        title: 'Overall',
        key: 'overall',
        disabled: true
      }

      if (this.technology === METRIC_TYPES.Overall) {
        return [overall]
      }

      if (this.lteOnly) {
        fourG.disabled = true
        return [fourG]
      }

      return [mmWave, fiveG, fourG, threeG]
    },
    deploymentOptions () {
      return DEPLOYMENT_OPTIONS.map(option => ({
        title: option,
        key: option,
        disabled: true
      }))
    },
    frequencyRanges () {
      return FREQUENCY_RANGES.map(option => ({
        title: option,
        key: '5g' + option.toLowerCase(),
        disabled: !FIVEG_SUBTYPES_METRICS.includes(this.metric.kind) || !FIVEG_METRICS_TYPES.includes(this.metric.type)
      }))
    },
    displayDeploymentOptions () {
      return this.technology && (this.technology.includes(METRIC_TYPES.FiveG) || this.technology === METRIC_TYPES.Mmwave)
    },
    displayFrequencyRanges () {
      return this.technology && this.licences.indexOf('pi_frequency_range') !== -1
    },
    displayTechnologyToggle () {
      return this.technology !== METRIC_TYPES.Overall &&
        this.slim &&
        this.display5GUserExperience &&
        this.dashboardInfo &&
        this.dashboardInfo.pi_overview_page === '5G'
    },
    displayMetrics () {
      return this.metrics.filter(m => !m.overall).filter(m => this.displayMetric(m))
    },
    displayOverallMetrics () {
      return this.metrics.filter(m => m.overall).filter(m => this.displayOverallMetric(m))
    },
    displayAllMetrics () {
      return this.metrics.filter(m => this.displayMetric(m) || this.displayOverallMetric(m))
    },
    catObj () {
      const mainMetric = this.metrics.find(m => m.main === this.metric.subtype)
      const subMetric = this.metrics.filter(m => m.sub).find(m => m.sub.find(a => a.metric.includes(this.metric.subtype)))

      return mainMetric || subMetric || this.metrics.find(m =>
        m.sub && m.sub.find(a => this.modelValue.includes(
          a.metric.replace(findMetricGroupForRouteName(a.metric), '')
        ))
      )
    },
    submetric () {
      if (!(this.catObj && this.catObj.sub)) {
        return
      }

      if (this.catObj.overall) {
        return this.catObj.sub.find(a => a.metric === this.mainMetric.subtype).metric
      }

      const submetric = this.catObj.sub.find(a => {
        let metric = a.metric

        if (this.category !== 'devices') {
          metric = metric.replace(findMetricGroupForRouteName(metric), '')
        }

        return this.modelValue.includes(metric)
      })

      return submetric.metric
    },
    technology: {
      get () {
        return this.mainMetric.type
      },
      set (tech) {
        this.$emit('update:modelValue', `${this.metric.subtype}_${tech}`)
      }
    },
    frequencyRange: {
      get () {
        return this.mainMetric.type
      },
      set (tech) {
        this.$emit('update:modelValue', `${this.metric.subtype}_${tech}`)
      }
    },
    altTechnology () {
      if (this.technology === METRIC_TYPES.FourG) {
        return METRIC_TYPES.FiveG
      } else {
        return METRIC_TYPES.FourG
      }
    },
    altTechnologyLabel () {
      return METRIC_TYPE_NAMES[this.altTechnology]
    },
    selectedMetric () {
      return this.modelValue
    },
    metricList () {
      return this.createMetricsMenuTree(this.displayMetrics, false)
    },
    overallMetricList () {
      return this.createMetricsMenuTree(this.displayOverallMetrics, true)
    },
    broadbandTech () {
      return this.technology === METRIC_TYPES.Overall
        ? (window.localStorage.getItem(PI_OVERVIEW_PREV_BROADBAND_TECH) || this.defaultTechnology) : this.technology
    }
  },
  watch: {
    metricCategory () {
      this.expandCategory()
    },
    metric () {
      this.expandCategory()
    },
    technology (newTech, oldTech) {
      if (newTech === METRIC_TYPES.Overall) {
        window.localStorage.setItem(PI_OVERVIEW_PREV_BROADBAND_TECH, oldTech)
      } else {
        window.localStorage.removeItem(PI_OVERVIEW_PREV_BROADBAND_TECH)
      }
    }
  },
  mounted () {
    this.expandCategory()
  },
  methods: {
    selectMetric (m) {
      this.$emit('update:modelValue', m)
    },
    setTechnology (technology) {
      this.technology = technology
      mp.track('pi overview technology change', {
        technology: technology
      })
    },
    expandCategory () {
      if (this.catObj && this.catObj.expand) {
        this.catObj.expanded = true
      }
    },
    displayMetric (metric) {
      let itemLicence
      let category
      let hasTechnology = true

      const tech = this.broadbandTech

      if (FIVEG_METRIC_SUBTYPES.includes(tech)) {
        itemLicence = 'pi_frequency_range'
      } else {
        switch (metric.metricCategory) {
          case 'service_reliability':
            category = 'servicerel'
            hasTechnology = false
            break
          case 'responsiveness':
            category = 'latency'
            break

          default: category = metric.metricCategory
            break
        }

        itemLicence = 'pi' +
          ((hasTechnology && !['lte', '4g', '3g'].includes(tech))
            ? ('_' + tech)
            : '') +
          '_' + category
      }

      const userLicence = this.user.licences.includes(itemLicence)

      const hasLicence = !metric.metricCategory ||
            this.availableMetricCategories(tech).includes(metric.metricCategory)

      return !metric.disabled &&
          this.metricCategory === metric.category &&
          hasLicence && (this.category === 'experience' ? userLicence : true)
    },
    displayOverallMetric (m) {
      if (this.category !== 'experience') {
        return false
      }

      const requiredLicense = `pi_${m.metricCategory}_overall`
      return this.user.licences.includes(requiredLicense)
    },
    isActive (metric, type) {
      switch (type) {
        case 'type':
          return this.modelValue.split('_')[1] === metric
        case 'sub':
          if (this.catObj.overall) {
            return this.mainMetric.subtype === metric
          }

          if (this.category !== 'devices') {
            metric = metric.replace(findMetricGroupForRouteName(metric), '')
          }

          return (this.modelValue.includes(metric) && !this.modelValue.includes('stalling')) ||
            (this.modelValue.includes(metric) && this.modelValue.includes('videostalling'))
        case 'main':
          if (this.category !== 'devices') {
            metric = metric.replace(findMetricGroupForRouteName(metric), '')
          }

          // this needs a better rule as download rule clashes with service reliabily
          if (metric === 'download') {
            return this.modelValue.includes(metric) && !this.modelValue.includes('stalling')
          }

          return this.modelValue.includes(metric) || (this.submetric && metric === this.catObj.main)
        default:
          return false
      }
    },
    selectSubMetric (metric, subMetric) {
      const tech = metric.overall ? METRIC_TYPES.Overall : this.broadbandTech
      this.$emit('update:modelValue', `${subMetric}_${tech}`)
    },
    toggle (metric) {
      const tech = metric.overall ? METRIC_TYPES.Overall : this.broadbandTech
      if (metric.expand) {
        metric.expanded = !metric.expanded

        if (metric.expanded && !(this.catObj && this.catObj.main === metric.main)) {
          this.$emit('update:modelValue', `${metric.main}_${tech}`)
        }
      } else {
        this.$emit('update:modelValue', `${metric.main}_${tech}`)
      }
    },
    /**
     * @param metrics
     * @param {boolean} overall - false to use type by tech (5g, lte) / true for '_overall'
     */
    createMetricsMenuTree (metrics, overall) {
      const list = []
      const broadbandTech = this.broadbandTech
      metrics.forEach(metric => {
        const metricKey = `${metric.main}_${overall ? 'overall' : broadbandTech}`
        const category = metric.group || metric.metricCategory
        const item = {
          metricCategory: category,
          label: metric.label,
          ogLabel: metric.label,
          value: metricKey,
          active: metricKey === this.modelValue
        }

        if (list.some(item => item.metricCategory === category)) {
          const mainItem = list.find(item => item.metricCategory === category)
          mainItem.children.push(item)
          if (item.active) {
            mainItem.active = true
            mainItem.label = item.label
          }
        } else {
          list.push({ ...item, children: [item] })
        }
      })

      return list
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

$padding: pxToRem(10);
$optionSpacing: 2px;

::v-deep() {
  .AccordionGroup {
    flex-wrap: wrap;
    &__option {
      margin: 0;
    }
    &__option-wrapper {
      margin: 0 1px;
      flex-grow: 1;
      justify-content: space-between;
    }
  }
  .technologyAccordion .AccordionGroup {
     &__option-wrapper {
      margin: 0px;
      width: calc(50% - 1px);
      &:nth-child(2n+1) {
        margin-right: $optionSpacing;
      }
      &:nth-child(n+3) {
        margin-top: $optionSpacing;
      }
    }
  }
}
.MetricsSelector {
  @extend %defaultLabel;
  width: pxToRem($sidebar-width - $sidebar-padding * 2);
  margin-bottom: 30px;

  &__metric {
    z-index: 0;
    position: relative;
    border-radius: pxToRem(2);
    &--expanded.MetricsSelector__metric {
      margin-bottom: pxToRem(20);
    }
    &--expanded::after {
      content: '';
      display: block;
      position: absolute;
      top: pxToRem(20);
      bottom: -5px;
      left: pxToRem(5);
      right: pxToRem(5);
      border: 1px solid #99B1C8;
      border-radius: pxToRem(2);
      z-index: -1;
    }
  }
  &__mainmetric {
    @extend %commonButton;
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    height: 0.7rem;
    line-height: 0.7rem;
    border-radius: $control-border-radius;
    padding: pxToRem(13) $padding pxToRem(13) $padding;
    margin: pxToRem(16) $padding pxToRem(16) $padding;
    color: $color-blue-header;
    font-size: pxToRem(16);
    cursor: pointer;
    &:first-child {
      margin-top: $padding;
    }
    &--active.MetricsSelector__mainmetric {
      @extend %active;
    }
    &--expanded.MetricsSelector__mainmetric {
      color: $color-white;
      background-color: $color-background-grey;
      margin-bottom: $padding;
    }
    &--icon {
      font-size: pxToRem(20);
      transform: translateY(-20%);
    }
  }
  &__submetric {
    @extend %commonButton;
    margin: pxToRem(3);
    margin-left: $padding;
    margin-right: $padding;
    border-radius: $control-border-radius;
    cursor: pointer;
    &:last-child {
      margin-bottom: $padding;
    }
    &:first-child {
      margin-top: $padding;
    }
    &--active.MetricsSelector__submetric {
      @extend %active;
    }
  }
  &__technologyTab {
    margin: $padding;
    width: auto;
    &--disabled {

    }
  }
  &__technologySwitch {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &__technologyLabel {
      text-transform: uppercase;
    }
  }
}
</style>
