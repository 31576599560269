<template>
  <div
    class="MarketGrowthLayout"
    :class="{ 'MarketGrowthLayout--menuCollapsed': !isMenuVisible}"
  >
    <div class="MarketGrowthLayout__sidebar">
      <slot name="sidebar" />
    </div>

    <div class="MarketGrowthLayout__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MarketGrowthOverviewLayout',
  computed: {
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible'
    })
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .MarketGrowthLayout {
    width: 100vw;
    height: 100vh;
    @include xy-grid(horizontal);
    // TODO: we need min width here based on the usual table data

    &--menuCollapsed {
      .MarketGrowthLayout__sidebar {
        margin-left: $sidebar-collapsed-offset;
      }
    }

    &__sidebar {
      width: $sidebar-width;

      @media screen and (max-width: $ci-breakpoint) {
        position: absolute;
        z-index: 1;
        height: 100%;
      }
    }

    &__content {
      width: 1%; // I don't know why this works
      flex: 5;
      position: relative;
    }
  }

</style>
