<template>
  <div class="SingleMetricArea__row">
    <div class="SingleMetricArea__title">
      {{ name }}
    </div>
    <div
      v-if="displayStats"
      class="SingleMetricArea__stats"
    >
      <div>
        <span class="SingleMetricArea__value">
          {{ value }}
        </span>
        <span class="SingleMetricArea__cis">
          {{ lci }} <span v-if="Number.isFinite(lci)">/</span> {{ uci }}
        </span>
      </div>

      <BarChartElement
        :value="value"
        :lci="lci"
        :uci="uci"
        :color="color"
        :min="min"
        :max="max"
      />
    </div>
  </div>
</template>

<script>
import BarChartElement from './BarChartElement'

export default {
  name: 'SingleMetricArea',
  components: {
    BarChartElement
  },
  props: {
    displayStats: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: null
    },
    lci: {
      type: Number,
      default: null
    },
    uci: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';

  .SingleMetricArea {
    &__row {
      cursor: pointer;
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;
      margin-bottom: 1px;
      position: relative;
      clear: both;

      &:hover {
        .SingleMetricArea__tooltip {
          display: block;
          overflow: hidden;

          @media screen and (max-width: $ci-breakpoint) {
            border-top: 1px solid $color-sidebar-grey;
          }
        }
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      flex: 8;
      padding: 5px 9px;
      font-size: $font-size-14;
      line-height: $font-size-20;
      font-weight: $font-weight-roboto-regular;
      background: $color-white;
      align-items: center;
    }

    &__stats {
      height: 100%;
      padding: 5px;
      flex: 10;
      border-left: 1px solid $color-sidebar-grey;
      background: $color-white;
    }

    &__value {
      display: inline-block;
      margin-right: 10px;
    }

    &__cis {
      color: $color-sidebar-grey;
      font-size: $font-size-12;
    }

    &__tooltip {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      z-index: 2;
      margin-left: 3px;
      background: $color-sidebar-grey;
      box-shadow: 0 0 10px $color-nav-bg;

      @media screen and (max-width: $ci-breakpoint) {
        position: static;
        box-shadow: none;
        margin: 0;
        flex-basis: 100%;
      }
    }
  }

  .noRank {
     float: right;
      position: relative;
      width: 15px;
      height: 15px;

      &:after {
        content: "x";
        display: block;
        position: absolute;
        background-color: $icon-light-grey;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        line-height: 1.2rem;
        text-align: center;
        bottom: 0px;
        left: -4px;
      }
  }
@media screen and (max-width: $ci-breakpoint) {
  .SingleMetricArea--compare {
    .SingleMetricArea__row {
      &:hover {
        padding: 6px 0;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }

      }
    }

    .SingleMetricArea__tooltip {
      border-top: 1px solid $color-sidebar-grey;
    }
  }
}

</style>
