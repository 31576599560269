<template>
  <div>
    <div
      v-for="rangesChart in chartData"
      :key="rangesChart.chartId"
    >
      <ChartWrapper
        :title="rangesChart.title"
        :tooltip="rangesChart.tooltip"
        :picture-meta="exportData"
        :selected-point="rangesSelectedPoints[rangesChart.chartId]"
        :loading="chartPending"
        :empty="rangesChart.empty"
        :show-confidence-rate="showConfidenceRate"
        :title-labels="titleLabels"
        :legend-title="'Frequency Ranges'"
        :unit="chartUnit"
        :y-zoom="chartYZoom"
        enable-y-zoom
        :display-frm="displayFrm"
        :chart-data="rangesChart"
        @toggleYZoom="chartYZoom = !chartYZoom"
      >
        <line-chart
          v-if="rangesChart.loaded"
          :date="date"
          :data-set="rangesChart.data"
          :chart-id="rangesChart.chartId"
          :x-axis-label="rangesChart.xAxisLabel"
          :y-axis-label="rangesChart.yAxisLabel"
          :y-axis-unit="rangesChart.yAxisUnit"
          :end-date="dashboardInfo.last_date_available"
          :show-confidence-rate="showConfidenceRate"
          :sync-range="syncRange"
          :y-zoom="chartYZoom"
          :title="'Ranges'"
          show-legend
          @point="navigateDate"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  LineChart,
  ChartWrapper
} from '@/components/visual'
import router from '@/router'
import { getLinechartRange } from '@/utils/charts'

export default {
  name: 'ChartRanges',
  components: {
    ChartWrapper,
    LineChart
  },
  beforeRouteUpdate (to, from, next) {
    if (router.currentRoute.value.params.chart !== to.params.chart) {
      this.chartYZoom = false
    }

    next()
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false,
      chartYZoom: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/getRangesCharts',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      range: 'charts/cdnRange',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      rangesSelectedPoints: 'dashboard/rangesSelectedPoints',
      displayFrm: 'chartFrm/displayFrm'
    }),
    exportData () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    syncRange () {
      return getLinechartRange(...this.range, 30)
    }
  },
  methods: {
    navigateDate (date) {
      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          hero: this.hero,
          chart: this.chart,
          location: this.location,
          agg: this.agg,
          date
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
