<template>
  <div
    ref="chartTooltip"
    :class="posClass"
    class="FlatTableTooltip"
  >
    <div
      v-show="display"
      class="FlatTableTooltip__wrapper"
    >
      <div class="FlatTableTooltip__list">
        <FlatTableItem
          v-for="datum in tooltipDatapoints"
          :key="datum.label"
          :value="datum.value"
          :operator-initial="Array.isArray(datum.color) ? '-' : datum.label && datum.label[0]"
          :operator-color="Array.isArray(datum.color) ? colors.colorBrandBlue : datum.color"
        />
      </div>
    </div>
  </div>
</template>
<script>
import uniqBy from 'lodash.uniqby'
import FlatTableItem from '@/components/comparison/FlatTableItem'
import colors from '@/utils/colorPalette'

export default {
  name: 'FlatTableTooltip',
  components: {
    FlatTableItem
  },
  props: {
    posClass: {
      type: String,
      default: ''
    },
    datapoints: {
      type: Array,
      default: () => []
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colorBrandBlue: colors.colorBrandBlue
    }
  },
  computed: {
    tooltipDatapoints () {
      if (!this.datapoints || !this.datapoints.length) {
        return []
      }
      return uniqBy(this.datapoints, point => {
        return point.label
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

.FlatTableTooltip {
  position: relative;
  background: transparent;
  color: $color-text-black;
  transition: all .1s ease;
  pointer-events: none;
  font-size: $font-size-13;
  padding-left: 10px;
  margin: 0;

  &__list {
    padding-left: 1em;
    display: flex;
    list-style-type: none;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
</style>
