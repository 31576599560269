<template>
  <div
    :class="elClass"
    class="HighlightCard"
  >
    <div class="HighlightCard__background" />
    <div class="HighlightCard__content">
      <div class="HighlightCard__top">
        <div class="HighlightCard__value">
          <span v-if="valueVisible">{{ value }}</span>
          <span v-else>-</span>
        </div>
        <div
          :class="topSideClass"
          class="HighlightCard__top-side"
        >
          <div class="HighlightCard__space" />
          <div
            class="HighlightCard__arrow"
          >
            <FontAwesomeIcon
              v-if="arrowVisible"
              :icon="positive ? icons.faCaretUp : icons.faCaretDown"
            />
            <div v-else>
&nbsp;
            </div>
          </div>
          <div
            class="HighlightCard__percentage"
          >
            <span v-if="percentageVisible">{{ percentage > 0 ? `+${percentage}` : `${percentage}` }}</span>
            <div v-else>
&nbsp;
            </div>
            <span
              v-if="arrowVisible && tooltipEnabled"
              class="HighlightCard__QuestionMark"
            >
              <CustomTooltip :message="'Difference between current metric value and value calculated 30/90 days ago, based on selected aggregation period.'"><QuestionMark /></CustomTooltip></span>
          </div>
          <div class="HighlightCard__unit">
            <span v-if="unit">{{ unit }}</span>
            <span v-else>&nbsp;</span>
          </div>
        </div>
      </div>
      <div class="HighlightCard__confidence">
        <span v-if="confidenceVisible">{{ lci }} / {{ uci }}</span>
        <span v-else>&nbsp;</span>
      </div>
      <div
        :style="lineStyle"
        class="HighlightCard__line"
      />
      <div class="HighlightCard__label">
        {{ label || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  faCaretDown,
  faCaretUp
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'

const isFinite = Number.isFinite

export default {
  name: 'HighlightCard',
  components: {
    CustomTooltip,
    QuestionMark,
    FontAwesomeIcon
  },
  props: {
    color: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    lci: { type: Number, default: undefined },
    percentage: { type: Number, default: undefined },
    positive: { type: Boolean, default: true },
    uci: { type: Number, default: undefined },
    unit: { type: String, default: undefined },
    value: { type: Number, default: undefined },
    tooltipEnabled: { type: Boolean, default: false }
  },
  data () {
    return { icons: { faCaretUp, faCaretDown } }
  },
  computed: {
    elClass () {
      return {
        'HighlightCard--disabled': this.disabled
      }
    },
    lineStyle () {
      return {
        'background-color': this.color
      }
    },
    topSideClass () {
      const validPercentage = isFinite(this.percentage) && this.percentage !== 0
      return {
        'HighlightCard__top-side--positive': this.positive && validPercentage,
        'HighlightCard__top-side--negative': !this.positive && validPercentage
      }
    },
    confidenceVisible () {
      return isFinite(this.lci) && isFinite(this.uci)
    },
    percentageVisible () {
      const { percentage } = this
      return percentage > 0 || percentage < 0
    },
    valueVisible () {
      return isFinite(this.value)
    },
    arrowVisible () {
      return isFinite(this.percentage) && this.percentage !== 0
    }
  }
}
</script>
<style lang="scss">
  @import 'scss/variables';

  $bg-color: $color-nav-bg;
  $confidence-height: pxToRem(18);
  $top-height: pxToRem(50);
  $line-height: pxToRem(3);

  .HighlightCard {
    @include xy-cell(auto);
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    z-index: $z-index-highlights-item;
    color: $color-text-black;
    position: relative;
    &--disabled {
      color: rgba($color-text-black, .2);
      .HighlightCard__line {
        opacity: .2;
      }
      .HighlightCard__top-side {
        opacity: .2;
      }
    }
    &__content {
      display: inline-block;
      margin: 0 auto;
      padding: 0 $padding-medium;
      z-index: $z-index-dropdown;
    }
    &__background {
      position: absolute;
      top: 0;
      right: -0.625rem;
      left: -0.625rem;
      height: calc(#{$top-height} + #{$confidence-height} + #{$line-height});
      background-color: $color-nav-bg;
    }
    &__space {
      padding-top: $padding-small;
    }
    &__line {
      height: $line-height;
      background-color: rgba($color-black, .5)
    }
    &__top {
      height: $top-height;
      background-color: $color-nav-bg;
    }
    &__value {
      display: inline-block;
      vertical-align: baseline;
      font-size: pxToRem(46);
      text-align: right;

      @media screen and (max-width: 1440px) {
        font-size: pxToRem(41);
      }
    }
    &__top-side {
      padding-left: $padding-small;
      display: inline-block;
      font-size: $font-size-12;
      &--positive {
        color: $color-positive-green;
      }
      &--negative {
        color: $color-negative-red;
      }
    }
    &__unit {
      font-weight: $font-weight-roboto-medium;
      color: $color-text-black;
    }
    &__confidence {
      font-size: $font-size-10;
      line-height: $font-size-18;
      height: $confidence-height;
      background-color: $color-nav-bg;
    }
    &__arrow {
      font-size: pxToRem(22);
      height: pxToRem(18);
    }
    &__percentage {
      font-weight: $font-weight-roboto-bold;
    }
    &__label {
      font-size: $font-size-12;
      line-height: $font-size-18;
      height: pxToRem(26);
      font-weight: $font-weight-roboto-medium;
      text-align: center;
    }
    &__QuestionMark {
      padding-left: $padding-small;
    }
  }
</style>
