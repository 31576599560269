<template>
  <div
    v-if="ready"
    class="Ci"
    :class="{ 'Ci--menuCollapsed': !isMenuVisible}"
  >
    <div class="Ci__sidebar">
      <competitive-menu />
    </div>
    <div class="Ci__main">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CompetitiveMenu } from '@/components/specialized'
import { LATEST_STRING } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'

export default {
  name: 'CompetitiveIntelligence',
  components: {
    CompetitiveMenu
  },
  beforeRouteUpdate (to, from, next) {
    this.checkDisclaimer()

    if (from.name !== to.name && from.name === ROUTES.CompetitiveMap) {
      this.setRouteParam({
        network: 'all'
      })
    }

    if (from.name === to.name) {
      this.track({
        dashboard: 'competitive',
        params: to.params,
        url: to.path,
        route: to.name
      })
    }

    next()
  },
  data () {
    return {
      ready: false,
      ROUTES
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo',
      'user'
    ]),
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible'
    })
  },
  mounted () {
    if (!this.user || !this.user.id) {
      this.setUser().then(res => res && this.checkDisclaimer())
    }

    if (router.currentRoute.value.path === '/competitive/' || router.currentRoute.value.path === '/competitive') {
      this.setUserSettings('competitive').then(() => {
        this.$router.replace({
          name: ROUTES.CompetitiveOverview,
          params: {
            date: LATEST_STRING,
            location: `${this.dashboardInfo.countries_visible_full[0].id}`,
            compareTo: '90days'
          }
        })
      }).then(() => {
        this.ready = true
      })
    } else {
      this.setUserSettings('competitive').then(() => {
        this.ready = true
      })
    }
  },
  methods: {
    ...mapActions([
      'setUser',
      'trackRoute',
      'track',
      'setUserSettings',
      'resetUser',
      'checkDisclaimer',
      'setRouteParam'
    ])
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import 'scss/components';

  .Ci {
    width: 100vw;
    height: 100vh;
    display: flex;

    &--menuCollapsed {
      .Ci__sidebar {
        margin-left: $sidebar-collapsed-offset;

        @media screen and (max-width: $ci-breakpoint-tablet) {
          margin-left: 0;
          left: $sidebar-collapsed-offset;
        }
      }

      .Ci__main {
        left: 15px;
        position: static;
        width: 100%;
      }
    }
  }

  .Ci__sidebar {
    @extend %sideBar;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      position: fixed;
      top: 0;
      height: 100vw;
      width: $sidebar-width;
    }
  }

  .Ci__main {
    @extend %mainContent;
    background: $ci-content-background;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      padding-left: 15px;
      position: static !important;
    }
  }
</style>
