<template>
  <div class="ColourScale">
    <div class="ColourScale__labels">
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="ColourScale__label"
      >
        {{ label }}
      </div>
    </div>
    <div
      :class="{ 'ColourScale__bar--inverted': inverted }"
      class="ColourScale__bar"
    />
  </div>
</template>

<script>
export default {
  name: 'ColourScale',
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .ColourScale {
    position: absolute;
    bottom: $padding-large;
    left: $padding-large;
    width: pxToRem(180);
    z-index: $z-index-on-map-layer;

    &__labels {
      display: flex;
      justify-content: space-between;
    }
    &__label {
      color: $color-nav-item-text;
      font-size: $font-size-10;
      line-height: $font-size-16;
      text-shadow: $text-shadow-light;
    }
    &__bar {
      @include elevation($elevation-2);
      background-image: linear-gradient(to right, #13E5A3, #E20074);
      border-radius: calc(#{$control-height}/2);
      height: pxToRem(12);

      &--inverted {
        background-image: linear-gradient(to right, #E20074, #13E5A3);
      }
    }
  }
</style>
