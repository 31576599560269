// metric.subtype
export const TOOLTIP_MESSAGES = {
  aggregation: 'Time period of historical data that is used for calculation of the metric',
  compareTo: 'Comparison of latest metric values to 7/30/90 days ago or last public report',
  technology: 'Set your default layer view for all metrics here',
  datePicker: 'Dashboard timezone is UTC. For weekly metrics, date selected will be adjusted to fit the last available date closest to selected End Date',
  peakdownload: 'Measures the fastest speeds users experience on the network in real world situations, based on speed experienced by the 98th percentile of users, in Mbps.',
  peakupload: 'Measures the fastest speeds users experience on the network in real world situations, based on speed experienced by the 98th percentile of users, in Mbps.',
  peaklatency: 'Lowest value of latency measured on the network, in ms',
  consistencydownload: 'Proportion of speed measurements (0-100%) that are above 3 Mbps for 3G and 4G or 10 Mbps for 5G',
  consistencyupload: 'Proportion of speed measurements (0-100%) that are above 1 Mbps',
  binneddownload: 'Distribution of download speed samples',
  frdownload: 'Average 5G download speed by frequency range',
  frupload: 'Average 5G upload speed by frequency range',
  binnedupload: 'Distribution of upload speed samples',
  binnedlatency: 'Distribution of latency samples',
  hourlyvideoexperience: 'Average video score measured in the Opensignal (0-100) scale, across all resolutions and providers',
  v1cdnresvideoexperience: 'Average video score (0-100) measured for particular provider and resolution. Different scaling method (similar to MOS scale) is applied here, as compared to overall video experience metric. Max. possible score for 360p resolution video is 45, 75 for 720p resolution',
  v2cdnresvideoexperience: 'Average video score (0-100) measured for particular provider and resolution. Different scaling method (similar to MOS scale) is applied here, as compared to overall video experience metric. Max. possible score for 360p resolution video is 45, 75 for 720p resolution, 87.5 for 1080p and 97.5 for 4K ',
  videoexperience: 'Opensignal have broadened the number of services & resolutions that are used in our Video Experience metric with the addition of AWS CloudFront & Google Cloud Platform, and 1080p & 4K (2160p) resolutions for all providers. The changes are effective from February 2022.',
  videostalling: 'Proportion of users that have experienced a stalling event during the video test (0-100%)',
  videoloadtime: 'Time taken for initial buffer to load and for the picture to start moving in seconds',
  binnedvideothroughput: 'Average throughput/data rate (Mbps) experienced by the user during the video test',
  binnedvideodataconsumed: 'Average data consumption (MB) for the video test',
  enodeb: 'Count of distinct eNodeB’s observed in the network',
  cell: 'Count of distinct 4G Cells observed in the network',
  cellbanddist: 'Proportion of 4G cells detected per frequency band in the network. Metric values are rounded to two decimal places.',
  enodebcellcountdist: 'Distribution of 4G Cells per eNodeB observed in the network',
  categorydownload: 'Average 4G download speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeakdownload: 'Peak 4G download speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeakupload: 'Peak 4G upload speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categoryupload: 'Average 4G upload speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorylatency: 'Average 4G latency by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeaklatency: 'Minimum 4G latency by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  androidmodeldownload: 'Average 4G download speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelpeakdownload: 'Peak 4G download speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelpeakupload: 'Peak 4G upload speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelupload: 'Average 4G upload speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  iosmodeldownload: 'Average 4G download speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelpeakdownload: 'Peak 4G download speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelpeakupload: 'Peak 4G upload speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelupload: 'Average 4G upload speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  downloadstallingoccurrence: 'Percentage of devices that have experienced at least one stalling event during the download speed test. Stalling event is defined as average speed falling below 500 kbps for the duration of 1 second.',
  poorsignallevel: 'Percentage of LTE RSRP readings that were below specified threshold (-115 dBm)',
  hourlydownloadstallingoccurrence: 'Percentage of devices that have experienced at least one stalling event during the download speed test. Stalling event is defined as average speed falling below 500 kbps for the duration of 1 second.',
  hourlypoorsignallevel: 'Percentage of LTE RSRP readings that were below specified threshold (-115 dBm)',
  poorsignalquality: 'Percentage of LTE RSRQ readings that were below specified threshold (-15 dB)',
  hourlypoorsignalquality: 'Percentage of LTE RSRQ readings that were below specified threshold (-15 dB)',
  voicertt: 'Average one-way UDP packet delay per device between client and the server',
  voicepacketloss: 'Average one-way UDP packet loss per device between client and the server',
  gamesrtt: 'Average one-way UDP packet delay per device between client and the server',
  gamespacketloss: 'Average one-way UDP packet loss per device between client and the server',
  metricGeographies: 'The "Top and Bottom" location view displays Regions/Cities with ranks only - sorted by the mean value of the metric. <br><br>To see all ranked and un-ranked Regions/Cities click on the "Reveal All" view where Regions/Cities without ranks are displayed at the bottom of the list.',
  competitiveEndDateInfo: 'Competitive Intelligence Dashboard is using an "exclusive" end date convention.<br><br> This means that for a 90 day aggregation time period from January 1st to March 31st, Competitive Intelligence Dashboard will show "date ending April 1st."',
  performanceEndDateInfo: 'Performance Intelligence Dashboard is using an "exclusive" end date convention.<br><br> This means that for a 90 day aggregation time period from January 1st to March 31st, Performance Intelligence Dashboard will show "date ending April 1st."',
  frequencyRange: `
    5G Frequency Range segmentation allows you to see how Frequency Ranges deployed impact on User Experience for key 5G Experiential Metrics.
    <p>Frequency Ranges are defined as follows:</p>
    <ul>
      <li>Low Range: <1 Ghz</li>
      <li>Mid Range: 1-3 Ghz</li>
      <li>High Range: 3-6 Ghz</li>
    </ul>
    mmWave corresponds to Range >6 Ghz. It is reported as a separate Technology and is not included in High Range bucket.`,
  ccq: 'Core Consistent Quality'
}
