<template>
  <div class="BlurredDisclaimer">
    <div class="BlurredDisclaimer__bg" />
    <div class="BlurredDisclaimer__text">
      Opensignal data or analysis can not be used, or sold, for the production of public reports, media announcements, investor relations materials or marketing campaigns on a global, regional, national or local basis.
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlurredDisclaimer'
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .BlurredDisclaimer {
    position: relative;
    font-size: $font-size-11;
    opacity: .9;
    color: $color-gray-light;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      background-color: $color-blue-header;
    }

    &__text {
      padding: pxToRem(10);
      line-height: pxToRem(16);
      letter-spacing: pxToRem(0.4);
    }

    &__bg {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
      border-top-right-radius: $border-radius-medium;
    }

    &:hover {
      .BlurredDisclaimer__bg {
        background-color: $color-blue-header;
        display: block;
      }
    }
  }
</style>
