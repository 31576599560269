<template>
  <div class="CompetitiveTitle">
    <div class="CompetitiveTitle__title">
      <slot />
    </div>
    <div class="CompetitiveTitle__subtitle">
      <span v-if="currentLocation">
        Average Results for {{ currentLocation.name }}
        <br>
        {{ currentAgg.label }} aggregation ending on the day before
        <br>
        <span class="CompetitiveTitle__date">
          {{ dateRange }}

          <CustomTooltip
            :message="message"
            placement="top"
          >
            <QuestionMark theme="ci" />
          </CustomTooltip>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'

export default {
  name: 'CompetitiveTitle',
  components: {
    CustomTooltip,
    QuestionMark
  },
  data () {
    return {
      message: TOOLTIP_MESSAGES.competitiveEndDateInfo
    }
  },
  computed: {
    ...mapGetters({
      currentAgg: 'competitive/agg',
      dateRange: 'competitive/dateRange',
      currentLocation: 'location/currentLocation'
    })
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .CompetitiveTitle {
    padding-bottom: 1em;
    color: $color-text-dark-blue;

    &__title {
      font-size: $font-size-26;
      font-weight: $font-weight-roboto-medium;
      font-style: italic;
    }
    &__subtitle {
      font-size: $font-size-14;
      line-height: $font-size-20;
      font-weight: $font-weight-roboto-regular;
    }
    &__date {
      font-weight: $font-weight-roboto-medium;
    }
  }
</style>
