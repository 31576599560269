<template>
  <DataTable
    :columns="columns"
    :data="formattedSubmarkets"
    default-sorting-column="rank"
    default-sorting-order="asc"
  >
    <template
      #default="{
        headerGroups,
        rows,
        sortBy,
        isSortingBy,
        isSortingOrderDesc,
        filterColumnBy,
        getColumnFilterValue
      }"
    >
      <div class="list-wrapper">
        <div class="table-wrapper">
          <table class="table table-scrollable">
            <thead
              class="table__head"
              :class="{ 'table__head--slim': headerGroups.length === 1}"
            >
              <tr
                v-for="(group, index) in headerGroups"
                :key="group.key"
                class="table__head__row"
                :class="{ 'table__head__row--small': index === 0 }"
              >
                <th
                  v-for="column in group.headers"
                  :key="column.key"
                  v-bind="column.props"
                  class="table__header"
                  :class="{
                    'name-column': column.key === 'name',
                    'parent-details-header': Boolean(column.children) && index !== 0,
                  }"
                  @click="column.key !== 'name' && allowSorting(column) ? sortBy(column.key) : null"
                >
                  <div class="header">
                    <div v-if="column.key === 'name'">
                      <SubmarketSearch
                        :m-value="getColumnFilterValue(column.key)"
                        @updateFilter="(newFilterValue) => { filterColumnBy(column.key, newFilterValue) }"
                      />
                    </div>
                    <template v-else-if="isOperatorColumn(column)">
                      <OperatorAvatar
                        :key="column.label"
                        :letter="column.label"
                        :background-color="`#${column.network.hex_color}`"
                        class="header__operator-avatar"
                      />
                    </template>
                    <span v-else>{{ column.label }}</span>
                    <div
                      v-if="allowSorting(column)"
                      class="header__icons"
                      @click="column.key === 'name' ? sortBy(column.key) : null"
                    >
                      <FontAwesomeIcon
                        :icon="icons.faAngleUp"
                        size="lg"
                        style="margin-bottom: -10px"
                        :class="{ active: isSortingBy(column.key) && !isSortingOrderDesc() }"
                      />
                      <FontAwesomeIcon
                        :icon="icons.faAngleDown"
                        size="lg"
                        :class="{ active: isSortingBy(column.key) && isSortingOrderDesc() }"
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="table__body">
              <div
                v-if="isListEmpty(rows)"
                class="table__empty"
              >
                <NoData />
              </div>
              <tr
                v-for="item in rows"
                v-else
                :key="item.key"
                class="table__row"
              >
                <td
                  v-for="cell in item.cells"
                  :key="cell.key"
                  class="table__data"
                  :class="{'name-column': cell.key === 'name'}"
                >
                  <Indicator
                    v-if="cell.key === 'rank'"
                    :label="cell.value"
                  />
                  <Indicator
                    v-else-if="cell.key === 'strategy'"
                    :strategy="cell.value"
                    :label="cell.value[0]"
                  />
                  <span v-else-if="cell.key === 'technologies'">
                    <div class="star_value">4G:</div>
                    <div class="star_value">5G:</div>
                  </span>
                  <span v-else-if="cell.key === 'market_share' || cell.key === 'availability'">
                    <MarketGrowthDelta
                      :value="cell.value"
                    />
                  </span>
                  <span v-else-if="cell.key === 'population'">
                    {{ $filters.numberFormat(cell.value) }}
                  </span>
                  <div v-else-if="isStarCell(cell.key)">
                    <div class="star_value">
                      {{ cell.value?.lte ? cell.value.lte : '-' }}
                    </div>
                    <div class="star_value">
                      {{ cell.value && cell.value['5g'] ? cell.value['5g'] : '-' }}
                    </div>
                  </div>
                  <span v-else>{{ cell.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </DataTable>
</template>

<script>
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import DataTable from './DataTable'
import Indicator from './Indicator'
import MarketGrowthDelta from './MarketGrowthDelta'
import SubmarketSearch from './SubmarketSearch'
import NoData from '@/components/NoData'
import OperatorAvatar from '@/components/OperatorAvatar'
import { STAR_RATING_CATEGORY_TITLES, STAR_RATING_CATEGORY_KEYS } from '@/constants/constants'
import router from '@/router'

export default {
  name: 'MarketGrowthList',
  components: {
    DataTable,
    Indicator,
    SubmarketSearch,
    FontAwesomeIcon,
    MarketGrowthDelta,
    NoData,
    OperatorAvatar
  },
  props: {
    dashboardInfo: {
      type: Object,
      default: () => {}
    },
    geoJson: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      icons: {
        faAngleUp,
        faAngleDown
      }
    }
  },
  computed: {
    ...mapGetters({
      displayMgStarRatings: 'marketGrowth/displayStarRatings',
      mapPending: 'mapData/chartPending',
      formattedSubmarkets: 'marketGrowth/detailsFormattedSubmarkets'
    }),
    brands () {
      return this.dashboardInfo.brands
    },
    competitors () {
      if (!this.selectedBrand || !this.brands) {
        return []
      }
      return this.getBrand(this.selectedBrand).competitors
    },
    selectedBrand () {
      return parseInt(router.currentRoute.value.params.brand || '')
    },
    selectedCompetitor () {
      return parseInt(router.currentRoute.value.params.competitor || '')
    },
    brand () {
      return this.getNetworkInfo(this.selectedBrand)
    },
    competitor () {
      return this.getNetworkInfo(this.selectedCompetitor)
    },
    columns () {
      const commonColumns = [
        {
          label: 'Name',
          key: 'name'
        },
        {
          label: 'Strategy',
          key: 'strategy',
          sortingDisabled: true
        }
      ]

      if (this.displayMgStarRatings) {
        const categories = [...STAR_RATING_CATEGORY_KEYS, 'all_stars']
        const titles = [...STAR_RATING_CATEGORY_TITLES, 'Total Stars']
        return [
          ...commonColumns,
          {
            label: 'Tech',
            key: 'technologies',
            sortingDisabled: true
          },
          ...titles.map((title, i) => {
            return {
              label: title,
              key: categories[i],
              sortingDisabled: true,
              children: [
                {
                  label: this.brand ? this.brand.name_mapped[0] : null,
                  network: this.brand,
                  key: `${this.brand ? this.brand.canonical_network_id : 'brand'}-${categories[i]}`
                },
                {
                  label: this.competitor ? this.competitor.name_mapped[0] : null,
                  network: this.competitor,
                  key: `${this.competitor ? this.competitor.canonical_network_id : 'competitor'}-${categories[i]}`
                }
              ]

            }
          })
        ]
      }
      return [
        {
          label: 'Rank',
          key: 'rank'
        },
        ...commonColumns,
        {
          label: 'Good Availability Δ',
          key: 'availability'
        },
        {
          label: 'Market Share Δ',
          key: 'market_share'
        },
        {
          label: 'Population',
          key: 'population'
        }
      ]
    }
  },
  methods: {
    isOperatorColumn (column) {
      return (this.brand && column.label === this.brand.name_mapped[0]) || (this.competitor && column.label === this.competitor.name_mapped[0])
    },
    allowSorting (column) {
      return !this.isOperatorColumn(column) && !column.sortingDisabled
    },
    isListEmpty (rows) {
      return rows.length === 0 && !this.mapPending
    },
    isStarCell (key) {
      if (!this.displayMgStarRatings) {
        return false
      }

      let match = false;

      [...STAR_RATING_CATEGORY_KEYS, 'all_stars'].map(cat => {
        if (key.includes(cat)) {
          match = true
        }
      })

      return match
    },
    getNetworkInfo (id) {
      if (!this.brands) {
        return
      }
      let network = this.brands.find(brand => brand.canonical_network_id === parseInt(id))
      if (network) {
        return network
      }
      this.brands.forEach(brand =>
        brand.competitors.forEach(competitor => {
          if (competitor.canonical_network_id === id) {
            network = competitor
          }
        })
      )

      return network
    }
  }
}
</script>

<style lang="scss" scoped>
@import "scss/variables";
@import "scss/components";

$header-height: 75px;
$header-slim-height: 52px;

.list-wrapper {
  height: 100%;
  .table-wrapper {
    border: 1px solid $mg-base;
    border-top: none;
    border: none;
  }
}
.table {
  background: $mg-base;
  height: 100%;
  &__head {
    border: none;
  }
  &__header {
    cursor: default;
    user-select: none;
    padding: 0;
    margin: 0;
    font-size: $font-size-12;
    font-weight: $font-weight-roboto-light;
    text-align: center;
    color: white;
    background: $mg-base;
  }
  &__body {
    display: block;
    height: calc(100vh - #{$header-height});
    overflow-y: scroll;
    border: none;

    .table__head--slim + & {
      height: calc(100vh - #{$header-slim-height});
    }
  }
  &__data {
    text-align: center;
  }
  &__row {
    height: 40px;
    font-size: $font-size-12;
    font-weight: $font-weight-roboto-regular;
  }
  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.name-column {
  width: 200px;
  padding: 0;
}

.table,
.table__header,
.table__data {
  border-top: 1px solid $color-gray-light;
  border-collapse: collapse;
}
.table,
.table__header {
  border-top: none;
}
.header {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  &__icons {
    right: -10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    svg {
      color: $mg-sort-symbol;
    }
    .active {
      color: $mg-sort-active;
    }
  }
}
// Make table scrollable with fixed header
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
thead {
  width: calc(100% - 1em); /* scrollbar is 1em/16px width */
}

.parent-details-header {
  position: relative;
  &:after {
    content: "";
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    left: 5%;
    width: 90%;
    border-bottom:1px solid #D8D8D8;
    opacity: 0.2;
  }
}

.star_value {
  + .star_value {
    margin-top: 5px;
  }
}
</style>
