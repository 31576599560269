import chroma from 'chroma-js'
import { max as maxDate, addDays, subDays, differenceInDays, isBefore, isAfter } from 'date-fns'
import groupBy from 'lodash.groupby'
import map from 'lodash.map'
import min from 'lodash.min'
import reduce from 'lodash.reduce'
import {
  getBucketColor,
  getMin,
  getMax
} from '@/components/visual/map/helpers'
import { AGGREGATIONS, GEO_POSITION } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'
import { sortByMetricMean, sortByMetricMeanAndRank } from '@/utils/data'
import { getLongDate, getSafeDate } from '@/utils/date'
import { setUserDefaultUserGroup } from '@/utils/metrics'
import {
  hasRank,
  getBarsByDate,
  getTrendSeries,
  getMinAndMaxDateFromSeries
} from '@/utils/viewHelpers'

const types = {
  AGGREGATION: 'competitive/agg',
  SHOW_WARNING: 'competitive/showWarning',
  SHOW_POLYGONS: 'competitive/showPolygons',
  COMPARE: 'competitive/compare',
  DATE_RANGE: 'competitive/dateRange',
  METRIC_THEME: 'competitive/metricTheme',
  TREND: 'competitive/trendSeries',
  NATIONAL: 'competitive/national',
  SELECTED: 'competitive/selectedPoint',
  FOCUS: 'competitive/focusChart',
  WINNERS: 'competitive/winners',
  LOCATIONS_WITH_RANK: 'competitive/locationsWithRank',
  FOCUSED_NETWORK_ID: 'competitive/focusedNetworkId',
  RANKED_GEOGRAPHIES: 'competitive/rankedGeographies',
  IS_FOCUSED_VIEW: 'competitive/isFocusedView',
  SELECTED_GROUPING: 'competitive/selectedGrouping',
  NETWORK_MAP_DATA: 'competitive/networkMapData',
  NETWORK_CITIES_MAP_DATA: 'competitive/networkCitiesMapData',
  NETWORK_STATS: 'competitive/networkStats',
  NETWORK_ITEMS: 'competitive/networkItems',
  REGION_DETAIL: 'competitive/regionDetail',
  SELECTED_DETAIL: 'competitive/selectedDetail',
  TIMEFRAME: 'competitive/timeframeDays',
  TIMERANGE: 'competitive/timeRange',
  ACTUAL_TIMERANGE: 'competitive/actualTimeRange',
  DEFAULT_METRIC_TYPE: 'competitive/defaultMetricType',
  USER_GROUP: 'competitive/userGroup',
  DEFAULT_GEOCODING: 'competitive/defaultGeocoding',
  SHOW_REGIONS_AND_CITIES: 'competitive/regionsAndCities',
  IS_COVERAGE: 'competitive/isCoverage',
  IS_EMPTY: 'competitive/isEmpty',
  GET_RANKING_TABLE: 'competitive/getRankingTable',
  GET_ORDERED_VALUES: 'competitive/getOrderedValues',
  GET_FILTERED_TREND: 'competitive/getFilteredTrend',
  SET_SHOW_POLYGONS: 'competitive:SET_SHOW_POLYGONS',
  SET_TIMEFRAME: 'competitive:SET_TIMEFRAME',
  SET_TIMERANGE: 'competitive:SET_TIMERANGE',
  SET_DEFAULT_METRIC_TYPE: 'competitive:SET_DEFAULT_METRIC_TYPE',
  SET_DEFAULT_USER_GROUP: 'competitive:SET_DEFAULT_USER_GROUP',
  SET_WARNING: 'competitive:SET_WARNING',
  SET_METRIC_BUCKETS: 'competitive:SET_METRIC_BUCKETS',
  SET_SHOW_REGIONS_CITIES: 'competitive:SET_SHOW_REGIONS_CITIES',
  SET_FOCUSED_VIEW: 'competitive:SET_FOCUSED_VIEW'
}

const state = () => ({
  showPolygons: false,
  timeframe: '180',
  noDataRedirectWarning: false,
  regionsAndCities: false,
  metricBuckets: [[0, 20], [20, 40], [40, 60], [60, 80], [80, 100]],
  timeRange: {
    start: null,
    end: null
  },
  manualIsFocusedView: true,
  userGroup: null
})

const getters = {
  // maybe move to dashboard store
  [types.AGGREGATION]: (state, getters, rootState, rootGetters) =>
    AGGREGATIONS.find(f => f.value === router.currentRoute.value.params.agg) ||
    AGGREGATIONS.find(f => f.value === '90days'),
  [types.SHOW_REGIONS_AND_CITIES]: (state, getters, rootState, rootGetters) => state.regionsAndCities,
  [types.SHOW_POLYGONS]: (state, getters, rootState, rootGetters) => state.showPolygons,
  [types.SHOW_WARNING]: (state, getters, rootState, rootGetters) => state.noDataRedirectWarning,
  [types.COMPARE]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name !== ROUTES.CompetitiveDetails) return false

    return router.currentRoute.value.params.network === 'all'
  },
  [types.FOCUSED_NETWORK_ID]: (state, getters, rootState, rootGetters) => {
    const isSingleOperator = router.currentRoute.value.params.network !== 'all'
    if (isSingleOperator && router.currentRoute.value.params.network) {
      return router.currentRoute.value.params.network
    }
    if (!rootGetters['charts/homeNetwork']) {
      return ''
    }
    return String(rootGetters['charts/homeNetwork'].canonical_network_id)
  },
  [types.SELECTED_GROUPING]: (state, getters, rootState, rootGetters) => {
    const groupings = rootGetters['location/byGroupings']
    return groupings.find(grouping => grouping.id + '' === String(router.currentRoute.value.params.geocoding))
  },
  [types.IS_FOCUSED_VIEW]: (state, getters, rootState, rootGetters) => {
    return state.manualIsFocusedView &&
    getters[types.RANKED_GEOGRAPHIES]
      .every(dataItem => hasRank(dataItem, getters[types.FOCUSED_NETWORK_ID]))
  },
  [types.RANKED_GEOGRAPHIES]: (state, getters, rootState, rootGetters) => {
    const metric = rootGetters['metrics/primaryMetric']
    const shapes = rootGetters['shapes']
    const cityShapes = rootGetters['cityShapes']
    const selectedGrouping = getters[types.SELECTED_GROUPING]
    const hiddenNetworkIds = rootGetters['charts/hiddenNetworksIds']
    const isSingleOperator = router.currentRoute.value.params.network !== 'all'
    const isOverview = router.currentRoute.value.name === ROUTES.PerformanceIntelligenceOverview
    const isCIDetails = router.currentRoute.value.name === ROUTES.CompetitiveDetails
    const theme = getters[types.METRIC_THEME]

    if (!isOverview && !isCIDetails) {
      return []
    }

    if (metric.kind === 'coverage') {
      return []
    }

    let data = []

    if (selectedGrouping && selectedGrouping.id) {
      if (selectedGrouping.id === 3) {
        data = cityShapes.geoJson ? cityShapes.geoJson.features : []
      } else {
        data = shapes.geoJson ? shapes.geoJson.features : []
      }
      // filter the data based on selected operators by the user from the Compare Operators menu
      data = data.map(area => {
        return {
          ...area,
          item: area.item.filter(item => !hiddenNetworkIds.includes(parseInt(item.network)))
        }
      })
      if ((isSingleOperator && data.length) || isOverview) {
        data = data.map(area => {
          return {
            ...area,
            item: area.item.filter(item => item.network === getters[types.FOCUSED_NETWORK_ID]).map(item => {
              return {
                ...item,
                color: isOverview ? null : getBucketColor(item.value, theme.ranges)
              }
            })
          }
        })
      }
    }

    const rankedOnly = []
    const allResults = []

    data.forEach(dataItem => {
      if (!dataItem.item.length) {
        return
      }
      dataItem.homeArea = dataItem.item.find(item => item.network === getters[types.FOCUSED_NETWORK_ID])
      if (dataItem.homeArea && dataItem.homeArea.rank != null) {
        rankedOnly.push(dataItem)
        allResults.push(dataItem)
        return
      }
      allResults.push(dataItem)
    })

    const unsortedData = rankedOnly.length > 0 && state.manualIsFocusedView
      ? rankedOnly : allResults

    const result = unsortedData
      .sort((a, b) => {
        const areaA = a.homeArea
        const areaB = b.homeArea
        if (!areaA) {
          // sort b before a
          return 1
        }
        if (!areaB) {
          // sort a before b
          return -1
        }
        if (areaA.rank == null && areaB.rank == null) {
          if (metric.bigger_is_better) {
            return (areaA && areaB) && areaB.value - areaA.value
          }
          return (areaA && areaB) && areaA.value - areaB.value
        }
        if (areaA.rank == null) {
          return 1
        }
        if (areaB.rank == null) {
          return -1
        }
        if (metric.bigger_is_better) {
          return (areaA && areaB) && areaB.value - areaA.value
        }
        return (areaA && areaB) && areaA.value - areaB.value
      })

    // Divide between top, middle and bottom
    const divisionNumber = result.length / 3
    const lastTopIndex = divisionNumber > 5 ? 4 : divisionNumber
    const firstBottomIndex = divisionNumber > 5
      ? result.length - 5
      : result.length - (divisionNumber + 1)

    let topColor
    let middleColor
    let bottomColor

    getters[types.METRIC_THEME]
      .ranges.forEach(range => {
        if (range.position === GEO_POSITION.TOP) {
          topColor = range.color
        } else if (range.position === GEO_POSITION.BOTTOM) {
          bottomColor = range.color
        } else {
          middleColor = range.color
        }
      })

    return result.map((area, index) => {
      area.index = index + 1
      area.item = area.item.map(op => {
        op.operatorName = op.label
        return op
      })

      if (index <= lastTopIndex) {
        area.position = GEO_POSITION.TOP
        area.item = area.item.map(op => {
          op.operatorName = op.label
          if (isOverview) {
            op.color = topColor
          }
          return op
        })
        return area
      }
      if (index >= firstBottomIndex) {
        area.position = GEO_POSITION.BOTTOM
        area.item = area.item.map(op => {
          op.operatorName = op.label
          if (isOverview) {
            op.color = bottomColor
          }
          return op
        })
        return area
      }
      area.item = area.item.map(op => {
        op.operatorName = op.label
        if (isOverview) {
          op.color = middleColor
        }
        return op
      })
      return area
    })
  },
  [types.DATE_RANGE]: (state, getters, rootState, rootGetters) => {
    const date = router.currentRoute.value.name === ROUTES.CompetitiveDetails
      ? router.currentRoute.value.params.date
      : getters.dashboardInfo.last_date_available
    if (!date) {
      return
    }
    return getLongDate(date)
  },
  [types.METRIC_THEME]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name === ROUTES.PerformanceIntelligenceOverview && getters[types.SELECTED_GROUPING]) {
      const grouping = getters[types.SELECTED_GROUPING].name
      const operatorColor = rootGetters['charts/homeNetwork'].hex_color
      return {
        ranges: [
          {
            position: GEO_POSITION.TOP,
            color: chroma(operatorColor).darken(2).hex(),
            label: `Top performing ${grouping}`
          },
          {
            position: GEO_POSITION.BOTTOM,
            color: chroma(operatorColor).brighten(2).hex(),
            label: `Least performing ${grouping}`
          },
          {
            color: chroma(operatorColor).hex(),
            label: `Other ${grouping}`
          }
        ]
      }
    }

    const biggerIsBetter = rootGetters['metrics/primaryMetric'].bigger_is_better
    const colors = ['#AB4C7D', '#C5787D', '#E5B608', '#7FB982', '#56A67D']
    return {
      unit: rootGetters['metrics/primaryUnit'],
      ranges: state.metricBuckets.map((value, index) => ({
        color: biggerIsBetter ? colors[index] : colors[4 - index],
        min: value[0],
        max: value[1],
        order: biggerIsBetter ? 4 - index : index
      })).sort((a, b) => a.order - b.order)
    }
  },
  [types.TREND]: (state, getters, rootState, rootGetters) => getters[types.GET_FILTERED_TREND](getters.chart),
  [types.NATIONAL]: (state, getters, rootState, rootGetters) => getters[types.GET_FILTERED_TREND](getters.hero),
  [types.SELECTED]: (state, getters, rootState, rootGetters) => getters.chart.filter(point => point.date === router.currentRoute.value.params.date),
  [types.FOCUS]: (state, getters, rootState, rootGetters) => getBarsByDate(
    Number.isFinite(parseInt(router.currentRoute.value.params.network))
      ? rootGetters['charts/selectedNetworkOperators'].filter(n => n.canonical_network_id === parseInt(router.currentRoute.value.params.network))
      : rootGetters['charts/selectedNetworkOperators'],
    router.currentRoute.value.params.date,
    getters[types.SELECTED]
  ) || { data: [], labels: [], colors: [], max: NaN },
  [types.WINNERS]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name !== ROUTES.CompetitiveDetails) return []

    const currentMetric = rootGetters['metrics/primaryMetric']
    const providerAvatars = rootGetters['charts/selectedNetworkOperators']
    const mapData = getters.mapData
    const cityMapData = getters.cityMapData

    const data = mapData.filter(providerData => {
      return providerAvatars.find(p => p.canonical_network_id === providerData.canonical_network_id)
    })

    const cityData = cityMapData.filter(providerData => {
      return providerAvatars.find(p => p.canonical_network_id === providerData.canonical_network_id)
    })

    // TODO remove reduce when hashing not needed - new map coloring as in AI
    return reduce(groupBy([...data, ...cityData], 'location'), (acc, dataByLocation, location) => {
      const maxRank = min(map(dataByLocation, 'rank'))
      const dataByHigherRas = maxRank > 0 ? dataByLocation.filter(d => d.rank === maxRank) : dataByLocation
      return [...acc, ...sortByMetricMeanAndRank(dataByHigherRas, currentMetric.bigger_is_better)]
    }, [])
  },
  [types.LOCATIONS_WITH_RANK]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name !== ROUTES.CompetitiveDetails) return []

    const providerAvatars = rootGetters['charts/selectedNetworkOperators']
    const mapData = getters.mapData
    const cityMapData = getters.cityMapData

    const data = mapData.filter(providerData => {
      return providerAvatars.find(p => p.canonical_network_id === providerData.canonical_network_id)
    })

    const cityData = cityMapData.filter(providerData => {
      return providerAvatars.find(p => p.canonical_network_id === providerData.canonical_network_id)
    })

    const allDataByLocation = groupBy([...data, ...cityData], 'location')

    const locationsWithRanks = {}
    Object.keys(allDataByLocation).forEach((location) => {
      const dataByLocation = allDataByLocation[location]
      const maxRank = min(map(dataByLocation, 'rank'))
      locationsWithRanks[location] = Boolean(maxRank)
    })
    return locationsWithRanks
  },
  [types.NETWORK_MAP_DATA]: (state, getters, rootState, rootGetters) => {
    if (
      (router.currentRoute.value.name !== ROUTES.CompetitiveDetails &&
        router.currentRoute.value.name !== ROUTES.PerformanceIntelligenceOverview) ||
        getters[types.COMPARE]
    ) return []

    if (!router.currentRoute.value.params.network) {
      return sortByMetricMean(getters.mapData.filter(d => {
        return `${d.canonical_network_id}` === String(getters['charts/homeNetwork'].canonical_network_id)
      }), rootGetters['metrics/primaryMetric'].bigger_is_better)
    }

    return sortByMetricMean(getters.mapData.filter(d => {
      return `${d.canonical_network_id}` === router.currentRoute.value.params.network
    }), rootGetters['metrics/primaryMetric'].bigger_is_better)
  },
  [types.NETWORK_CITIES_MAP_DATA]: (state, getters, rootState, rootGetters) => {
    if (
      (router.currentRoute.value.name !== ROUTES.CompetitiveDetails &&
        router.currentRoute.value.name !== ROUTES.PerformanceIntelligenceOverview) ||
      getters[types.COMPARE]
    ) return []

    if (!router.currentRoute.value.params.network) {
      return sortByMetricMean(getters.cityMapData.filter(d => {
        return `${d.canonical_network_id}` === String(rootGetters['charts/homeNetwork'].canonical_network_id)
      }), rootGetters['metrics/primaryMetric'].bigger_is_better)
    }

    return sortByMetricMean(getters.cityMapData.filter(d => {
      return `${d.canonical_network_id}` === router.currentRoute.value.params.network
    }), rootGetters['metrics/primaryMetric'].bigger_is_better)
  },
  [types.NETWORK_STATS]: (state, getters, rootState, rootGetters) => {
    const dataUsed = router.currentRoute.value.params.geocoding === '3' ? getters[types.NETWORK_CITIES_MAP_DATA] : getters[types.NETWORK_MAP_DATA]
    if (!dataUsed.length) return { data: [] }

    return {
      maxUci: getMax(dataUsed, 'uci'),
      minLci: getMin(dataUsed, 'lci'),
      maxValue: getMax(dataUsed, 'mean'),
      minValue: getMin(dataUsed, 'mean'),
      data: dataUsed,
      // TODO check if needed
      unit: rootGetters['metrics/primaryUnit']
    }
  },
  [types.NETWORK_ITEMS]: (state, getters, rootState, rootGetters) => {
    if (!getters[types.NETWORK_STATS].data.length ||
      router.currentRoute.value.name === ROUTES.PerformanceIntelligenceOverview
    ) {
      return { data: [] }
    }

    const singleNetwork = getters[types.NETWORK_STATS]

    return singleNetwork.data.reduce((acc, data) => {
      const name = getters.locations.find(l => l.key === `${data.location}`).name
      return (Number.isFinite(data.lci) && Number.isFinite(data.uci) && Number.isFinite(data.mean))
        ? [
          ...acc,
          {
            lci: data.lci,
            uci: data.uci,
            value: data.mean,
            color: getBucketColor(data.mean, getters[types.METRIC_THEME].ranges),
            name
          }
        ]
        : acc
    }, [])
  },
  [types.REGION_DETAIL]: (state, getters, rootState, rootGetters) => {
    if (!getters[types.COMPARE]) return []

    return getters[types.GET_RANKING_TABLE](getters.mapData)
  },
  [types.SELECTED_DETAIL]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name !== ROUTES.CompetitiveDetails) return []

    return getters[types.GET_RANKING_TABLE](getters[types.SELECTED])
  },
  [types.TIMEFRAME]: (state, getters, rootState, rootGetters) => state.timeframe,
  [types.IS_EMPTY]: (state, getters, rootState, rootGetters) => {
    if (rootGetters['chart/chartPending']) {
      return false
    }
    const { start, end } = getters[types.TIMERANGE]
    const minMax = getMinAndMaxDateFromSeries(getters.chart)
    if (!minMax) {
      return true
    }
    const [minAvailableDateString, maxAvailableDateString] = minMax
    const minAvailableDate = getSafeDate(minAvailableDateString)
    const maxAvailableDate = getSafeDate(maxAvailableDateString)
    return (isBefore(getSafeDate(start), minAvailableDate) &&
    isBefore(getSafeDate(end), minAvailableDate)) ||
    (isAfter(getSafeDate(start), maxAvailableDate))
  },
  [types.TIMERANGE]: (state, getters, rootState, rootGetters) => {
    if (router.currentRoute.value.name !== ROUTES.CompetitiveDetails) return false
    if (state.timeRange.start && state.timeRange.end) {
      return state.timeRange
    }
    let start
    let end
    let selectedDate
    const minMaxDates = getMinAndMaxDateFromSeries(getters.chart)
    if (minMaxDates) {
      selectedDate = getSafeDate(minMaxDates[1])
    } else {
      selectedDate = getSafeDate(router.currentRoute.value.params.date)
    }
    var timeframe = getters[types.TIMEFRAME]
    var orgStart = rootGetters['charts/organizationStartDate']
    var orgEnd = rootGetters['charts/organizationEndDate']
    if (orgEnd < addDays(selectedDate, timeframe / 2)) {
      // higher end or data is less then timeframe
      end = orgEnd
      start = maxDate([subDays(getSafeDate(end), timeframe), getSafeDate(orgStart)])
    } else if (orgStart > subDays(selectedDate, timeframe / 2)) {
      // lower end
      start = orgStart
      end = addDays(getSafeDate(orgStart), timeframe)
    } else {
      start = subDays(selectedDate, timeframe / 2)
      end = addDays(selectedDate, timeframe / 2)
    }
    return { start, end }
  },
  // Time range taking into account the data's min and max date
  [types.ACTUAL_TIMERANGE]: (state, getters, rootState, rootGetters) => {
    const { start, end } = getters[types.TIMERANGE]
    const minMaxDates = getMinAndMaxDateFromSeries(getters.chart)
    if (!minMaxDates) {
      return getters[types.TIMERANGE]
    }
    const [min, max] = minMaxDates
    return {
      start: isBefore(getSafeDate(start), getSafeDate(min)) ? new Date(min) : start,
      end: isAfter(getSafeDate(end), getSafeDate(max)) ? new Date(max) : end
    }
  },
  [types.DEFAULT_METRIC_TYPE]: (state, getters, rootState, rootGetters) => state.defaultMetricType,
  [types.USER_GROUP]: (state, getters, rootState, rootGetters) => {
    return state.userGroup
  },
  [types.IS_COVERAGE]: (state, getters, rootState, rootGetters) => router.currentRoute.value.params.metric && router.currentRoute.value.params.metric.includes('coverage'),
  [types.GET_RANKING_TABLE]: (state, getters, rootState, rootGetters) => data => {
    return sortByMetricMeanAndRank(data
      .filter(d => d.location === parseInt(rootGetters['location/currentLocation'].key) && d.mean)
      .map(d => ({
        ...d,
        operator: getters.operators.find(o => o.canonical_network_id === d.canonical_network_id),
        selected: !!rootGetters['charts/selectedNetworkOperators'].find(p => p.canonical_network_id === d.canonical_network_id)
      })), rootGetters['metrics/primaryMetric'].bigger_is_better)
      .filter(d => d.operator)
  },
  [types.GET_ORDERED_VALUES]: (state, getters, rootState, rootGetters) => (data, metric) => {
    return sortByMetricMeanAndRank(data
      .filter(p => p.metric === metric.key)
      .map(d => ({
        ...d,
        operator: getters.operators.find(o => o.canonical_network_id === d.canonical_network_id)
      })), metric.bigger_is_better)
      .filter(d => d.operator)
  },
  [types.GET_FILTERED_TREND]: (state, getters, rootState, rootGetters) => data => {
    const { start, end } = getters[types.TIMERANGE]
    return getTrendSeries(
      Number.isFinite(parseInt(router.currentRoute.value.params.network))
        ? getters.operators.filter(n => n.canonical_network_id === parseInt(router.currentRoute.value.params.network))
        : rootGetters['charts/selectedNetworkOperators'],
      data,
      start,
      end
    )
  },
  [types.DEFAULT_GEOCODING]: (state, getters, rootState, rootGetters) => {
    const groupings = rootGetters['location/byGroupings']
    const openSignalGrouping = groupings.find(group => group.id === 2 || group.id === 3)
    const customGrouping = groupings.find(group => group.id !== 2 && group.id !== 3)
    // Make sure Regions is the default
    if (groupings.find(group => group.id === 2)) {
      return '2'
    }
    if (openSignalGrouping) {
      return openSignalGrouping.id
    }
    if (customGrouping) {
      return customGrouping.id
    }
    return '2'
  }
}

const actions = {
  toggleShowPolygons ({ commit, getters }) {
    commit(types.SET_SHOW_POLYGONS, !getters[types.SHOW_POLYGONS])
  },
  setTimeframe ({ commit }, timeframe) {
    commit(types.SET_TIMEFRAME, timeframe)
    commit(types.SET_TIMERANGE, { start: null, end: null })
  },
  setDefaultMetricType ({ commit }, type) {
    commit(types.SET_DEFAULT_METRIC_TYPE, type)
  },
  setUserGroup ({ commit }, group) {
    commit(types.SET_DEFAULT_USER_GROUP, group)
    setUserDefaultUserGroup(group)
  },
  async setTimeRange ({ commit, getters, dispatch, rootGetters }, range) {
    const { start, end } = range
    const [minAvailableDateString, maxAvailableDateString] = getMinAndMaxDateFromSeries(getters.chart)
    const minAvailableDate = getSafeDate(minAvailableDateString)
    const maxAvailableDate = getSafeDate(maxAvailableDateString)
    // If data is missing inside the time range, fetch new data
    if (isBefore(getSafeDate(start), minAvailableDate) || isAfter(getSafeDate(end), maxAvailableDate)) {
      const { metric, location, countryid: country, agg } = router.currentRoute.value.params
      const daysDifference = Math.abs(differenceInDays(getSafeDate(start), minAvailableDate))
      await dispatch('fetchOlderCiTrends', {
        metric,
        location,
        agg,
        country,
        days: daysDifference,
        endDate: subDays(getSafeDate(minAvailableDateString), 1).toISOString()
      })
    }
    commit(types.SET_TIMEFRAME, null)
    commit(types.SET_TIMERANGE, range)
  },
  setFocusedView ({ commit }, newValue) {
    commit(types.SET_FOCUSED_VIEW, newValue)
  }
}

const mutations = {
  [types.SET_SHOW_REGIONS_CITIES]: (state, regionsAndCitites) => {
    state.regionsAndCities = regionsAndCitites
  },
  [types.SET_SHOW_POLYGONS]: (state, showPolygons) => {
    state.showPolygons = showPolygons
  },
  [types.SET_TIMEFRAME]: (state, timeframe) => {
    state.timeframe = timeframe
  },
  [types.SET_DEFAULT_METRIC_TYPE]: (state, type) => {
    state.defaultMetricType = type
  },
  [types.SET_DEFAULT_USER_GROUP]: (state, type) => {
    state.userGroup = type
  },
  [types.SET_WARNING]: (state, warning) => {
    state.noDataRedirectWarning = warning
  },
  [types.SET_METRIC_BUCKETS]: (state, buckets) => {
    state.metricBuckets = [...buckets]
  },
  [types.SET_TIMERANGE]: (state, range) => {
    state.timeRange = range
  },
  [types.SET_FOCUSED_VIEW]: (state, newValue) => {
    state.manualIsFocusedView = newValue
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  types
}
