import Base from './base'

export class Maps extends Base {
  constructor (token) {
    super()
    this.token = `Token ${token}`
    this.polygons = 'polygon/'
    this.polygonsByParent = 'polygons_by_parent/'
    this.data = 'overview/geocoding_config/'
    this.dataByParent = 'overview/parent/'
  }

  getPolygons ({
    polygon = 2,
    country,
    dashboard = this.defaultDashboard
  }) {
    let params = ''

    if (country) {
      params = `?country=${country}`
    }

    return this.get(
      `${this.getApiVersion(dashboard)}${dashboard}/${this.polygons}${polygon}/${params}`,
      this.token
    )
  }

  getData ({
    polygon = 2,
    metric = 'availability_lte',
    agg = '90days',
    date,
    country,
    location,
    dashboard = this.defaultDashboard,
    brand,
    competitor,
    altVersion = false,
    endDate = null,
    metricList = null
  }) {
    if (dashboard === 'market-growth') {
      let ratingParams = ''
      if (endDate && metricList) {
        ratingParams = `end_date=${endDate}&metric_list=${metricList}`
      }

      if (!brand || !competitor) {
        return this.get(
          `${this.getApiVersion(dashboard)}${dashboard}/${this.data}${polygon}/${agg}/?${ratingParams}`,
          this.token
        )
      }
      return this.get(
        `${this.getApiVersion(dashboard)}${dashboard}/${this.data}${polygon}/${agg}/?brand=${brand}&competitor=${competitor}&${ratingParams}`,
        this.token
      )
    }

    let params = `?operator_info=yes&metric_list=${metric}`

    if (country) {
      params += `&country=${country}`
    }

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`
    }

    if (location) {
      params += `&location_id=${location}`
    }

    const version = altVersion ? this.v2 : this.v1
    return this.get(
      `${version}${dashboard}/${this.data}${polygon}/${agg}/${params}`,
      this.token
    )
  }

  getPolygonsByParent (parent, dashboard = this.defaultDashboard) {
    return this.get(
      `${this.getApiVersion(dashboard)}${dashboard}/${this.polygonsByParent}${parent}/`,
      this.token
    )
  }

  getDataByParent ({
    parent,
    metrics = [],
    date,
    dashboard = this.defaultDashboard
  }) {
    let params = `?operator_info=yes`

    if (metrics.length) {
      params += `&metric_list=${metrics.join(',')}`
    }

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`
    }

    return this.get(
      `${this.v1}${dashboard}/${this.dataByParent}${parent}/90days/${params}`,
      this.token
    )
  }
}
