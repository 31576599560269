<template>
  <div class="DataTableWrapper">
    <slot
      :header-groups="headerGroups"
      :headers="headers"
      :rows="rows"
      :sort-by="sortTableBy"
      :is-sorting-by="isSortingBy"
      :is-sorting-order-desc="isSortingOrderDesc"
      :filter-column-by="filterColumnBy"
      :get-column-filter-value="getColumnFilterValue"
    />
  </div>
</template>

<script>
import * as JsSearch from 'js-search'
import orderby from 'lodash.orderby'

const SortingOrderEnum = {
  ASC: 'asc',
  DESC: 'desc'
}

function getHeadersArray (header) {
  if (!header.children) {
    return header
  }
  return [
    header,
    ...header.children.map(getHeadersArray)
  ]
}
function getChildrenLength (header) {
  return getHeadersArray(header)
    .flat(Infinity)
    .filter(header => !header.children)
    .length
}
function countArray (header, accumulatedAcount = 1) {
  if (!header.children || header.children.length === 0) {
    return accumulatedAcount
  }
  return Math.max(...header.children.map(h => countArray(h, accumulatedAcount + 1)), accumulatedAcount)
}

function countObjectsDepth (headers = []) {
  return Math.max(...headers.map(header => countArray(header)), 0)
}

export default {
  name: 'DataTable',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    defaultSortingColumn: {
      type: String,
      default: ''
    },
    defaultSortingOrder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sortingBy: '',
      sortingOrder: '',
      filters: {}
    }
  },
  computed: {
    headers () {
      return this.columns
    },
    flatHeaders () {
      return this.headers.map(getHeadersArray).flat(Infinity)
    },
    actualHeaders () {
      return this.flatHeaders.filter(header => !header.children)
    },
    headerGroups () {
      const totalDepth = countObjectsDepth(this.headers)
      const groups = []
      const formatHeader = (header, depthPosition) => ({
        ...header,
        props: {
          align: 'center',
          rowspan: header.children || depthPosition !== 0 ? null : totalDepth,
          colspan: header.children ? getChildrenLength(header) : null
        }
      })
      for (let i = 0; i < totalDepth; i++) {
        const previousGroup = groups[i - 1]
        let headers = []
        if (previousGroup) {
          const secondRowHeaders = previousGroup.headers.filter(header => header.children)
          secondRowHeaders.forEach(headerGroup => {
            headerGroup.children.forEach(header => {
              headers.push(formatHeader(header, i))
            })
          })
        } else {
          headers = this.headers.map(header => formatHeader(header, i))
        }
        groups.push({ key: `${i}-group`, headers })
      }
      return groups
    },
    sortedData () {
      return orderby(this.data, [this.sortingBy], [this.sortingOrder])
    },
    filteredData () {
      const sortedData = this.sortedData
      if (Object.keys(this.filters).length === 0) {
        return sortedData
      }
      const filterKeys = Object.keys(this.filters)
      const filterValues = filterKeys.map(key => this.filters[key])
      return this.filterByMultiple(sortedData, filterKeys, filterValues)
    },
    rows () {
      return this.filteredData.map(item => {
        const cells = this.actualHeaders.map(header => ({
          key: header.key,
          value: item[header.key],
          meta: item
        }))
        return {
          key: item.key,
          cells
        }
      })
    }
  },
  mounted () {
    if (this.defaultSortingColumn && this.defaultSortingOrder) {
      this.sortingBy = this.defaultSortingColumn
      this.sortingOrder = this.defaultSortingOrder
    }
  },
  methods: {
    filter (arrayToFilter, filterKey, filterValue) {
      if (!filterValue) {
        return arrayToFilter
      }
      const search = new JsSearch.Search('key')
      search.addIndex(filterKey)
      search.addDocuments(arrayToFilter)
      return search.search(filterValue)
    },
    filterByMultiple (arrayToFilter, filterKeys, filterValues) {
      if (filterKeys.length === 0) {
        return arrayToFilter
      }
      const newArrayToFilter = this.filter(
        arrayToFilter,
        filterKeys[0],
        filterValues[0]
      )
      return this.filterByMultiple(
        newArrayToFilter,
        filterKeys.slice(1),
        filterValues.slice(1)
      )
    },

    sortTableBy (columnKey) {
      if (this.sortingBy !== columnKey) {
        this.sortingOrder = null
      }
      this.sortingBy = columnKey
      if (!this.sortingOrder) {
        this.sortingOrder = SortingOrderEnum.ASC
      } else if (this.sortingOrder === SortingOrderEnum.ASC) {
        this.sortingOrder = SortingOrderEnum.DESC
      } else {
        this.sortingOrder = null
        this.sortingBy = null
      }
    },
    isSortingBy (columnKey) {
      return this.sortingBy === columnKey
    },
    isSortingOrderDesc () {
      return this.sortingOrder === SortingOrderEnum.DESC
    },
    getColumnFilterValue (columnKey) {
      if (!this.filters[columnKey]) {
        return ''
      }
      return this.filters[columnKey]
    },
    filterColumnBy (columnKey, value) {
      this.filters[columnKey] = value
    }
  }
}

</script>

<style lang="scss" scoped>
.DataTableWrapper {
  height: 100%;
}
</style>
