import colors from '@/utils/colorPalette'

export const checkIfMobile = () => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.visualViewport.width || 0)

  const isMobile = viewportWidth <= parseInt(colors.ciBreakpointTablet, 10)

  return isMobile
}

const types = {
  IS_MOBILE: 'page/isMobile',
  SET_IS_MOBILE: 'page/SET_IS_MOBILE'
}

const state = () => ({
  isMobile: checkIfMobile()
})

const getters = {
  [types.IS_MOBILE]: (state) => state.isMobile
}

const actions = {

}

const mutations = {
  [types.SET_IS_MOBILE]: (state, isMobile) => {
    state.isMobile = isMobile
  }
}

const plugins = [
  (store) => {
    window.addEventListener('resize', () => {
      const prevValue = state.isMobile
      const newValue = checkIfMobile()

      store.commit(types.SET_IS_MOBILE, checkIfMobile())

      if (typeof prevValue !== 'undefined' && prevValue !== newValue) {
        window.location.reload()
      }
    })

    return store
  }
]

export default {
  state,
  getters,
  actions,
  mutations,
  plugins
}
