import { faChartBar, faChartLine, faPlayCircle } from '@fortawesome/pro-regular-svg-icons'
import colors from '@/utils/colorPalette'

export const DEFAULT_DASHBOARD = 'competitive'
export const LATEST_STRING = 'latest'

export const AGGREGATIONS = [
  {
    label: '30 days',
    value: '30days',
    days: 30,
    full: '30 days aggregation'
  },
  {
    label: '90 days',
    value: '90days',
    days: 90,
    full: '90 days aggregation'
  }
]

export const COMPARE_TO = [
  {
    label: '7 days ago',
    tileLabel: '7 days ago',
    value: '7days'
  },
  {
    label: '30 days ago',
    tileLabel: '30 days ago',
    value: '30days'
  },
  {
    label: '90 days ago',
    tileLabel: '90 days ago',
    value: '90days'
  },
  {
    label: 'last public report',
    tileLabel: 'last report',
    value: 'lpr'
  }
]

export const LICENCES_BY_TECHNOLOGY = {
  video: {
    FourG: {
      CI: '', // no equivalent
      PI: 'pi_video'
    },
    FiveG: {
      CI: '', // no equivalent
      PI: 'pi_5g_video'
    },
    mmWave: {
      CI: 'ci_5gmmwave_video',
      PI: 'pi_5gmmwave_video'
    }
  },
  games: {
    FourG: {
      CI: 'ci_games',
      PI: 'pi_games'
    },
    FiveG: {
      CI: 'ci_5g_games',
      PI: 'pi_5g_games'
    },
    mmWave: {
      CI: 'ci_5gmmwave_games',
      PI: 'pi_5gmmwave_games'
    }
  },
  voice: {
    FourG: {
      CI: 'ci_voice',
      PI: 'pi_voice'
    },
    FiveG: {
      CI: 'ci_5g_voice',
      PI: 'pi_5g_voice'
    },
    mmWave: {
      CI: 'ci_5gmmwave_voice',
      PI: 'pi_5gmmwave_voice'
    }
  },
  latency: {
    FourG: {
      CI: '',
      PI: 'pi_latency'
    },
    FiveG: {
      CI: 'ci_5g_latency',
      PI: 'pi_5g_latency'
    }
  },
  download: {
    FourG: {
      CI: '',
      PI: 'pi_speed'
    },
    FiveG: {
      CI: '',
      PI: 'pi_5g_speed'
    },
    mmWave: {
      CI: 'ci_5gmmwave_download',
      PI: 'pi_5gmmwave_speed'
    }
  },
  upload: {
    FourG: {
      CI: '',
      PI: 'pi_speed'
    },
    FiveG: {
      CI: '',
      PI: 'pi_5g_speed'
    },
    mmWave: {
      CI: 'ci_5gmmwave_upload',
      PI: 'pi_5gmmwave_speed'
    }
  },
  extent: {
    FourG: {
      CI: 'ci_4g_reach',
      PI: ''
    },
    FiveG: {
      CI: 'ci_5g_reach',
      PI: ''
    }
  }
}

export const METRIC_TYPE_MAP = {
  mean: 'mean',
  estimate_simple: 'estimate',
  ranked_simple: 'mean',
  simple_count: 'count',
  simple: 'mean',
  predictedmean: 'quant',
  simple_median: 'median',
  simple_threshold: 'threshold',
  count: 'count',
  percentage_simple: 'percentage_simple'
}

export const DEPLOYMENT_OPTIONS = [
  'Standalone',
  'Non-standalone'
]

export const FREQUENCY_RANGES = [
  'Low',
  'Mid',
  'High'
]

export const STAR_RATING_CATEGORY_TITLES = [
  'Download Speed',
  'Video Experience',
  'Coverage'
]
export const STAR_RATING_CATEGORY_KEYS = [
  'download_speed',
  'video_experience',
  'coverage'
]

export const METRIC_TYPES = {
  All: '3glte5g',
  Overall: 'overall',
  Mmwave: '5gmmwave',
  FiveGUser: '5guser',
  FiveG: '5g',
  FourG: 'lte',
  ThreeG: '3g',
  TwoG: '2g'
}

export const METRIC_TYPE_NAMES = {
  '3glte5g': 'Overall',
  'overall': 'Overall',
  '5gmmwave': 'mmWave',
  '5guser': '5G User',
  '5g': '5G',
  'lte': '4G',
  '3g': '3G',
  '2g': '2G',
  'wifi': 'WiFi',
  'nosignal': 'No Signal'
}

export const FIVEG_METRIC_SUBTYPES = [
  '5glow',
  '5gmid',
  '5ghigh'
]

export const FIVEG_METRICS_TYPES = [
  METRIC_TYPES.FiveG,
  ...FIVEG_METRIC_SUBTYPES
]

export const FIVEG_SUBTYPES_METRICS = [
  'download',
  'upload'
]

export const METRIC_TYPE_OPTIONS = [
  {
    label: 'Overall',
    value: METRIC_TYPES.Overall
  },
  {
    label: 'mmWave',
    value: METRIC_TYPES.Mmwave
  },
  {
    label: '5G',
    value: METRIC_TYPES.FiveG
  },
  {
    label: '4G',
    value: METRIC_TYPES.FourG
  },
  {
    label: '3G',
    value: METRIC_TYPES.ThreeG
  },
  {
    label: '2G',
    value: METRIC_TYPES.TwoG
  }
]

export const METRIC_CATEGORY_OPTIONS = [{
  title: 'Speed',
  key: 'speed',
  faIcon: faChartBar /* TODO: pick icon */
},
{
  title: 'Responsiveness',
  key: 'responsiveness',
  faIcon: faChartLine /* TODO: pick icon */
},
{
  title: 'Video',
  key: 'video',
  faIcon: faPlayCircle
}]

export const METRIC_CPS = {
  voice: ['ec2', 'gce'],
  rtt: ['ec2', 'gce'],
  packet_loss: ['ec2', 'gce'],
  jitter: ['ec2', 'gce']
}

export const CPS_LIST = [
  'ec2',
  'gce'
]
export const CP_LABELS = {
  'ec2': 'EC2',
  'gce': 'Google Compute Engine'
}

export const GEO_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom'
}

export const CPS = {
  ...CP_LABELS
}

export const METRIC_CDNS = {
  upload: ['akamai', 'cloudfront'],
  download: ['akamai', 'cloudfront', 'google storage'],
  latency: ['akamai', 'cloudfront', 'google storage', 'google.com']
}

export const CDNS_LIST = [
  'akamai',
  'cloudfront',
  'google storage',
  'google.com'
]

export const CDN_LABELS = {
  'akamai': 'Akamai',
  'cloudfront': 'CloudFront',
  'google storage': 'Google',
  'google.com': 'google.com'
}

export const CDNS = {
  ...CDN_LABELS
}

export const PILLS = [
  {
    key: 'video',
    metric: 'videoexperience_overall'
  },
  {
    key: 'games',
    metric: 'gamesexperience_overall'
  },
  {
    key: 'voice',
    metric: 'voiceexperience_overall'
  },
  {
    key: 'download',
    metric: 'download_overall'
  },
  {
    key: 'upload',
    metric: 'upload_overall'
  },
  {
    key: 'latency',
    metric: 'latency_overall'
  },
  {
    key: 'availability',
    metric: 'availability_lte'
  },
  {
    key: 'coverage',
    metric: 'coverageexperience_lte'
  },
  {
    key: 'extent',
    metric: 'extent_5g'
  },
  {
    key: 'ccq',
    metric: 'ccq_overall'
  },
  {
    key: 'ecq',
    metric: 'ecq_overall'
  }
]

export const WEEKLY_METRICS_CATEGORIES = ['infrastructure', 'devices']
export const DAILY_LTE_ONLY_METRICS = [
  'hourlypoorsignalquality_lte',
  'poorsignalquality_lte',
  'hourlypoorsignallevel_lte',
  'poorsignallevel_lte'
]

export const METRIC_HERO_LIST = [
  'download',
  'upload',
  'latency',
  'videoexperience',
  'voiceexperience',
  'gamesexperience',
  'enodeb',
  'availability',
  'ccq',
  'ecq'
]

export const DEFAULT_DISTRIBUTION_PERCENTILE = 90

export const CHART_MODIFIERS = {
  enodeb: 'Overview',
  cellbanddist: 'Distribution',
  enodebcellcountdist: 'Distribution'
}

export const PRIMARY_SIBLINGS = ['cdn', 'cdnops', 'binned', 'hourly', 'peak', 'consistency', 'ip', 'fr']
export const LATENCY_SIBLINGS = ['cdn', 'cdnops', 'binned', 'hourly', 'peak']
export const VIDEO_LOAD_TIME_SIBLINGS = ['trend', 'cdnres', 'cdnresops', 'binnedcdnres', 'hourly', 'cdnresip']
export const VIDEO_EXPERIENCE_SIBLINGS = ['cdnres', 'cdnresops', 'binnedcdnres', 'hourly', 'throughput', 'cdnresip']
export const VIDEO_STALLING_SIBLINGS = ['trend', 'cdnres', 'cdnresops', 'hourly', 'cdnresip']
export const INFRA_SIBLINGS = ['enodeb', 'cell', 'cellbanddist', 'enodebcellcountdist']
export const LTE_3GPP_SIBLINGS = ['categorydownload', 'categorypeakdownload', 'categoryupload', 'categorypeakupload', 'categorylatency', 'categorypeaklatency']
export const MAKE_MODEL_SIBLINGS = ['mmdownload', 'mmpeakdownload', 'mmupload', 'mmpeakupload']
export const SERVICE_RELIABILITY_SIBLINGS = [
  'downloadstallingoccurrence',
  'hourlydownloadstallingoccurrence',
  'poorsignallevel',
  'hourlypoorsignallevel',
  'poorsignalquality',
  'hourlypoorsignalquality'
]
export const VOICE_SIBLINGS = ['trend', 'cp', 'cpops', 'binned']

export const GAMES_SIBLINGS = ['gamesrtt', 'cp', 'cpops', 'binned', 'cpip']
export const GAMES_LOSS_SIBLINGS = ['gamespacketloss', 'cp', 'cpops', 'binned', 'cpip']
export const GAMES_JITTER_SIBLINGS = ['trend', 'cp', 'cpops', 'binned', 'cpip']
export const CCQ_SIBLINGS = ['trend']

export const METRIC_CHART_INFO = {
  hourlypoorsignalquality: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  poorsignalquality: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  hourlypoorsignallevel: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  poorsignallevel: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  hourlydownloadstallingoccurrence: {
    hero: 'download',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  downloadstallingoccurrence: {
    hero: 'download',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS
  },
  categorydownload: {
    hero: 'download',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  categorypeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  categorypeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  categoryupload: {
    hero: 'upload',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  categorypeaklatency: {
    hero: 'latency',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  categorylatency: {
    hero: 'latency',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS
  },
  androidmodeldownload: {
    hero: 'download',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  androidmodelpeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  androidmodelpeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  androidmodelupload: {
    hero: 'upload',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  iosmodeldownload: {
    hero: 'download',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  iosmodelpeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  iosmodelpeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  iosmodelupload: {
    hero: 'upload',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS
  },
  enodeb: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS
  },
  cell: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS
  },
  enodebcellcountdist: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS
  },
  cellbanddist: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS
  },
  cdnupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  cdndownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  ipupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  ipdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  frupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  frdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  cdnlatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS
  },

  hourlyupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  hourlydownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  hourlylatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS
  },
  binnedupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  binneddownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  binnedlatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS
  },
  peakupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  peakdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  peaklatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS
  },
  consistencyupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS
  },
  consistencydownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS
  },
  cdnresvideoexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  cdnresipvideoexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  hourlyvideoexperience: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  binnedcdnresvideoexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  videoloadtime: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videoloadtime',
    siblings: VIDEO_LOAD_TIME_SIBLINGS
  },
  hourlyvideoloadtime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoloadtime',
    siblings: VIDEO_LOAD_TIME_SIBLINGS
  },
  cdnresvideoloadtime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoloadtime',
    siblings: VIDEO_LOAD_TIME_SIBLINGS
  },
  cdnresipvideoloadtime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoloadtime',
    siblings: VIDEO_LOAD_TIME_SIBLINGS
  },
  binnedcdnresvideoloadtime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoloadtime',
    siblings: VIDEO_LOAD_TIME_SIBLINGS
  },
  videostalling: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videostalling',
    siblings: VIDEO_STALLING_SIBLINGS
  },
  hourlyvideostalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videostalling',
    siblings: VIDEO_STALLING_SIBLINGS
  },
  cdnresvideostalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videostalling',
    siblings: VIDEO_STALLING_SIBLINGS
  },
  cdnresipvideostalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videostalling',
    siblings: VIDEO_STALLING_SIBLINGS
  },
  binnedvideothroughput: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  binnedvideodataconsumed: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS
  },
  voiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },
  cpvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },
  cpopsvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },
  binnedvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },
  voicertt: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },
  voicepacketloss: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS
  },

  cpgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS
  },
  gamesexperience: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesexperience',
    siblings: GAMES_SIBLINGS
  },
  gamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS
  },
  cpipgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS
  },
  cpopsgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS
  },
  binnedgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS
  },

  gamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS
  },
  cpgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS
  },
  cpipgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS
  },
  cpopsgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS
  },
  binnedgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS
  },
  gamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS
  },
  cpgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS
  },
  cpipgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS
  },
  cpopsgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS
  },
  binnedgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS
  },
  ccqdownload: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqupload: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqlatency: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqicmplatency: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqjitter: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqpacketloss: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ccqdownloadtimefirstbyte: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS
  },
  ecqdownload: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqupload: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqlatency: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqicmplatency: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqjitter: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqpacketloss: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  },
  ecqdownloadtimefirstbyte: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS
  }
}

export const METRIC_GROUP_LABEL = {
  throughput: 'Throughput Rate',
  consumed: 'Data Consumption',
  consistency: 'Speed Consistency',
  peakspeed: 'Peak Speed',
  minimumlatency: 'Minimum Latency',
  trend: 'Trend',
  cp: 'By Cloud Platform',
  cpip: 'By Cloud Provider IP Address',
  cdn: 'By CDN',
  ip: 'By CDN IP Address',
  binned: 'Distribution',
  binnedcdnres: 'Distribution',
  hourly: 'Hour of Day',
  cdnres: 'Provider and Resolution',
  cdnresip: 'By CDN IP Address',
  enodeb: 'eNB Count',
  cell: '4G Cells Count',
  enodebcellcountdist: '4G Cells per eNB',
  cellbanddist: '4G Cells per Frequency Band',
  categorydownload: 'Download Speed',
  categorypeakdownload: 'Download Peak Speed',
  categoryupload: 'Upload Speed',
  categorypeakupload: 'Upload Peak Speed',
  categorylatency: 'Latency',
  categorypeaklatency: 'Minimum Latency',
  mmdownload: 'Download Speed',
  mmpeakdownload: 'Download Peak Speed',
  mmupload: 'Upload Speed',
  mmpeakupload: 'Upload Peak Speed',
  downloadstallingoccurrence: 'Stalling Occurrence',
  hourlydownloadstallingoccurrence: 'Stalling Occurrence - Hourly',
  poorsignalquality: 'Poor Signal Quality',
  hourlypoorsignalquality: 'Poor Signal Quality - Hourly',
  poorsignallevel: 'Poor Signal Strength',
  hourlypoorsignallevel: 'Poor Signal Strength - Hourly',
  voicertt: 'Voice App Packet Delay',
  voicepacketloss: 'Voice App Packet Loss',
  frdownload: 'By Frequency Range',
  frupload: 'By Frequency Range'
}

export const METRIC_GROUP_ALTERNATE_LABEL = {
  cpops: 'By Operator - Cloud Platform',
  cdnops: 'By Operator - CDN',
  cdnresops: 'By Operator - Provider & Resolution'
}

export const ALTERNATE_CHART_VIEWS = [
  'cdnresops',
  'cpops',
  'cdnops'
]

export const ALTERNATE_ROUTES = [
  'r/PerformanceIntelligenceCpOps',
  'r/PerformanceIntelligenceCdnOps',
  'r/PerformanceIntelligenceCdnResOps'
]

export const PI_METRICS_MENU = [
  {
    metricCategory: 'speed',
    main: 'download',
    category: 'experience',
    label: 'Speed',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Download Speed',
        metric: 'download'
      },
      {
        label: 'Upload Speed',
        metric: 'upload'
      }
    ]
  },
  {
    metricCategory: 'responsiveness',
    main: 'latency',
    category: 'experience',
    label: 'Latency',
    expanded: false
  },
  {
    metricCategory: 'video',
    main: 'videoexperience',
    category: 'experience',
    label: 'Video',
    expand: true,
    expanded: false,
    sub: [{
      label: 'Video Experience',
      metric: 'videoexperience'
    }, {
      label: 'Video Stalling Occurrence',
      metric: 'videostalling'
    }, {
      label: 'Video Load Time',
      metric: 'videoloadtime'
    }]
  },
  {
    metricCategory: 'voice',
    main: 'voiceexperience',
    category: 'experience',
    label: 'Voice App Experience'
  },
  {
    metricCategory: 'games',
    main: 'gamesrtt',
    category: 'experience',
    label: 'Games Experience',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Games Experience Packet Delay',
        metric: 'gamesrtt'
      },
      {
        label: 'Games Experience Packet Loss',
        metric: 'gamespacketloss'
      },
      {
        label: 'Games Experience Jitter',
        metric: 'gamesjitter'
      }
    ]
  },
  {
    metricCategory: 'service_reliability',
    main: 'downloadstallingoccurrence',
    category: 'experience',
    label: 'Service Reliability',
    expanded: false
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownload',
    category: 'experience',
    label: 'Core Consistent Quality',
    expand: true,
    expanded: false,
    overall: true,
    sub: [
      {
        label: 'Raw Average Download Speed',
        metric: 'ccqdownload'
      },
      {
        label: 'Raw Average Upload Speed',
        metric: 'ccqupload'
      },
      {
        label: 'Raw Average Latency',
        metric: 'ccqlatency'
      },
      {
        label: 'Raw ICMP Latency',
        metric: 'ccqicmplatency'
      },
      {
        label: 'Raw Average Jitter',
        metric: 'ccqjitter'
      },
      {
        label: 'Raw Average Packet Loss',
        metric: 'ccqpacketloss'
      },
      {
        label: 'Raw Average Download Time to First Byte',
        metric: 'ccqdownloadtimefirstbyte'
      }
    ]
  },
  {
    metricCategory: 'ecq',
    main: 'ecq',
    category: 'experience',
    label: 'Excellent Consistent Quality',
    expand: true,
    expanded: false,
    overall: true,
    sub: [
      {
        label: 'Raw Average Download Speed',
        metric: 'ecqdownload'
      },
      {
        label: 'Raw Average Upload Speed',
        metric: 'ecqupload'
      },
      {
        label: 'Raw Average Latency',
        metric: 'ecqlatency'
      },
      {
        label: 'Raw ICMP Latency',
        metric: 'ecqicmplatency'
      },
      {
        label: 'Raw Average Jitter',
        metric: 'ecqjitter'
      },
      {
        label: 'Raw Average Packet Loss',
        metric: 'ecqpacketloss'
      },
      {
        label: 'Raw Average Download Time to First Byte',
        metric: 'ecqdownloadtimefirstbyte'
      }
    ]
  },
  {
    main: 'enodeb',
    category: 'infrastructure',
    disabled: true
  },
  {
    main: 'categorydownload',
    category: 'devices',
    label: 'By 3GPP LTE Category',
    expanded: false
  },
  {
    main: 'androidmodeldownload',
    category: 'devices',
    label: 'By Make & Model',
    expand: true,
    expanded: true,
    sub: [{
      label: 'Android',
      metric: 'androidmodeldownload'
    }, {
      label: 'iOS',
      metric: 'iosmodeldownload'
    }]
  }
]

export const PI_OVERVIEW_METRICS_MENU = [
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'download',
    category: 'experience',
    kind: 'download',
    label: 'Download Speed Experience',
    metric: 'download'
  },
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'peakdownload',
    category: 'experience',
    kind: 'download',
    label: 'Peak Speed',
    submetric: true,
    metric: 'peakdownload'
  },
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'consistencydownload',
    category: 'experience',
    kind: 'download',
    label: 'Consistency',
    submetric: true,
    metric: 'consistencydownload'
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'upload',
    category: 'experience',
    kind: 'upload',
    label: 'Upload Speed Experience',
    expanded: false,
    metric: 'upload'
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'peakupload',
    category: 'experience',
    kind: 'upload',
    label: 'Peak Speed',
    submetric: true,
    metric: 'peakupload'
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'consistencyupload',
    category: 'experience',
    kind: 'upload',
    label: 'Consistency',
    submetric: true,
    metric: 'consistencyupload'
  },
  {
    metricCategory: 'responsiveness',
    main: 'latency',
    category: 'experience',
    kind: 'latency',
    label: 'Latency Experience',
    expanded: false,
    metric: 'latency'
  },
  {
    metricCategory: 'responsiveness',
    main: 'peaklatency',
    category: 'experience',
    kind: 'latency',
    label: 'Minimum Latency',
    submetric: true,
    metric: 'peaklatency'
  },
  {
    metricCategory: 'video',
    main: 'videoexperience',
    category: 'experience',
    kind: 'video',
    label: 'Video Experience',
    expanded: false,
    metric: 'videoexperience'
  },
  {
    metricCategory: 'video',
    main: 'videostalling',
    category: 'experience',
    kind: 'video',
    label: 'Video Stalling Occurrence',
    submetric: true,
    metric: 'videostalling'
  },
  {
    metricCategory: 'video',
    main: 'videoloadtime',
    category: 'experience',
    kind: 'video',
    label: 'Video Load Time',
    submetric: true,
    metric: 'videoloadtime'
  },
  {
    metricCategory: 'voice',
    main: 'voiceexperience',
    category: 'experience',
    kind: 'voice',
    label: 'Voice App Experience',
    expanded: false,
    metric: 'voiceexperience'
  },
  {
    metricCategory: 'games',
    main: 'gamesexperience',
    category: 'experience',
    kind: 'rtt',
    label: 'Games Experience',
    expanded: false,
    metric: 'gamesexperience'
  },
  {
    metricCategory: 'games',
    main: 'gamesrtt',
    category: 'experience',
    kind: 'games',
    label: 'Packet Delay',
    submetric: true,
    metric: 'gamesrtt'
  },
  {
    metricCategory: 'games',
    main: 'gamespacketloss',
    category: 'experience',
    kind: 'packet_loss',
    label: 'Packet Loss',
    submetric: true,
    metric: 'gamespacketloss'
  },
  {
    metricCategory: 'games',
    main: 'gamesjitter',
    category: 'experience',
    kind: 'jitter',
    label: 'Jitter',
    submetric: true,
    metric: 'gamesjitter'
  },
  {
    metricCategory: 'ccq',
    main: 'ccq',
    category: 'experience',
    kind: 'ccq',
    label: 'Core Consistent Quality',
    submetric: false,
    metric: 'ccq',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownload',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Average Download Speed',
    submetric: true,
    metric: 'ccqdownload',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqupload',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Average Upload Speed',
    submetric: true,
    metric: 'ccqupload',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqlatency',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Latency',
    submetric: true,
    metric: 'ccqlatency',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqicmplatency',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw ICMP Latency',
    submetric: true,
    metric: 'ccqicmplatency',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqjitter',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Jitter',
    submetric: true,
    metric: 'ccqjitter',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqpacketloss',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Packet Loss',
    submetric: true,
    metric: 'ccqpacketloss',
    overall: true
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownloadtimefirstbyte',
    category: 'experience',
    kind: 'ccq',
    label: 'Raw Time To First Byte',
    submetric: true,
    metric: 'ccqdownloadtimefirstbyte',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecq',
    category: 'experience',
    kind: 'ecq',
    label: 'Excellent Consistent Quality',
    submetric: false,
    metric: 'ecq',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqdownload',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Average Download Speed',
    submetric: true,
    metric: 'ecqdownload',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqupload',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Average Upload Speed',
    submetric: true,
    metric: 'ecqupload',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqlatency',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Latency',
    submetric: true,
    metric: 'ecqlatency',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqicmplatency',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw ICMP Latency',
    submetric: true,
    metric: 'ecqicmplatency',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqjitter',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Jitter',
    submetric: true,
    metric: 'ecqjitter',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqpacketloss',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Packet Loss',
    submetric: true,
    metric: 'ecqpacketloss',
    overall: true
  },
  {
    metricCategory: 'ecq',
    main: 'ecqdownloadtimefirstbyte',
    category: 'experience',
    kind: 'ecq',
    label: 'Raw Time To First Byte',
    submetric: true,
    metric: 'ecqdownloadtimefirstbyte',
    overall: true
  }
]

export const CI_TO_PI_MAPPINGS = {
  // availability: {
  //   key: 'poorsignallevel',
  //   type: 'trend'
  // },
  videoexperience: {
    key: 'cdnresvideoexperience',
    type: 'cdnres'
  },
  voiceexperience: {
    key: 'voiceexperience',
    type: 'trend'
  },
  gamesexperience: {
    key: 'cpgamesrtt',
    type: 'cp'
  },
  download: {
    key: 'cdndownload',
    type: 'cdn'
  },
  upload: {
    key: 'cdnupload',
    type: 'cdn'
  },
  latency: {
    key: 'cdnlatency',
    type: 'cdn'
  }
}

export const MG_STRATEGIES = {
  attack: {
    label: 'Attack'
  },
  harvest: {
    label: 'Harvest'
  },
  defend: {
    label: 'Defend'
  },
  opportunistic: {
    label: 'Opportunistic'
  }
}

export const MG_METRICS = {
  good_availability_delta: {
    label: 'Good Availability Δ',
    value: 'good_availability_delta',
    scale: [
      {
        label: '≥ 0',
        color: '#378e3d'
      },
      {
        label: '< 0',
        color: colors.noDataColor
      }
    ]
  },
  device_share_delta: {
    label: 'Market Share Δ',
    value: 'device_share_delta',
    scale: [
      {
        label: '> 5%',
        color: '#378e3d',
        range: {
          min: 5
        }
      },
      {
        label: '0 to 5%',
        color: '#83e587',
        range: {
          min: 0,
          max: 5
        }
      },
      {
        label: '-1% to -5%',
        color: '#fda5a0',
        range: {
          min: -5,
          max: 0
        }
      },
      {
        label: '< -5%',
        color: '#fe433a',
        range: {
          max: -5
        }
      }
    ]
  },
  strategy_delta: {
    label: 'Strategy',
    value: 'strategy_delta',
    scale: [
      {
        label: 'Attack',
        color: colors.colorMgAttack
      },
      {
        label: 'Harvest',
        color: colors.colorMgHarvest
      },
      {
        label: 'Defend',
        color: colors.colorMgDefend
      },
      {
        label: 'Opportunistic',
        color: colors.colorMgOpportunistic
      }
    ]
  }
}

export const USER_GROUPS = [
  {
    label: 'All Users',
    value: 'main',
    supportedTypes: Object.values(METRIC_TYPES)
      .filter(type => type !== METRIC_TYPES.Mmwave && type !== METRIC_TYPES.All)
  },
  {
    label: '5G Users',
    value: '5guser',
    supportedTypes: Object.values(METRIC_TYPES)
      .filter(type => type !== METRIC_TYPES.Mmwave && type !== METRIC_TYPES.All)
  },
  {
    label: '4G Users',
    value: '4guser',
    supportedTypes: [
      METRIC_TYPES.Overall,
      METRIC_TYPES.FourG,
      METRIC_TYPES.ThreeG,
      METRIC_TYPES.TwoG
    ]
  },
  {
    label: '3G Users',
    value: '3guser',
    supportedTypes: [METRIC_TYPES.Overall, METRIC_TYPES.ThreeG, METRIC_TYPES.TwoG]
  }
]

export const DEFAULT_USER_GROUP = 'main'

export const UNSUPPORTED_TYPES_FOR_GROUPS = [
  'latency',
  'extent',
  'coverage'
]

export const TIMEFRAME_DAYS_OPTIONS = [
  {
    title: '30 days',
    key: '30'
  },
  {
    title: '90 days',
    key: '90'
  },
  {
    title: '180 days',
    key: '180'
  },
  {
    title: '1 year',
    key: '365'
  }
]

// for PRI we need to be able to display operators from both PRI and USA,
// this config can be used in these type of cases
export const OPERATORS_COUNTRY_OVERRIDES = {
  'PRI': ['PRI', 'USA']
}

export const PI_OVERVIEW_PREV_BROADBAND_TECH = 'pi_overview_prev_broadband_tech'
