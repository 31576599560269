<template>
  <div class="SubmarketSearch">
    <div class="SubmarketSearch__filter">
      <div class="SubmarketSearch__filter-icon">
        <FontAwesomeIcon
          :icon="icons.faMapMarkerAlt"
          size="1x"
        />
      </div>
      <div>
        <input
          type="text"
          class="SubmarketSearch__input"
          placeholder="Search Market"
          :value="mValue"
          @input="$emit('updateFilter', $event.target.value)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SubmarketSearch',
  components: {
    FontAwesomeIcon
  },
  props: {
    mValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: {
        faMapMarkerAlt
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.SubmarketSearch {
  padding-bottom: $padding-medium;
  background: transparent;
  position: relative;
  padding: 0;
  width: 100%;
  &__filter {
    display: flex;
    position: relative;
    background-color: #3F3F3F;
    border-radius: 0.25rem;
    color: $control-light-text-color;
    &--active.SubmarketSearch__filter,
    &:hover {
      @extend %active;
      background: $hover-mg;
    }
    &-icon   {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
    }
  }
  &__input {
    @extend %inputText;
    @extend %inputBox;

    width: 100%;
    padding-right: 1em;
    color: inherit;
    background: transparent;
    text-overflow: ellipsis;
    &--no-padding {
      padding-right: 0;
    }
    &:focus {
      outline: none;
      color: inherit;
    }
    &::placeholder {
      color: rgba(255,255,255,0.6);
      text-overflow: ellipsis;
    }
  }
}
</style>
