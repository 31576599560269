<template>
  <div class="TableWrapper">
    <div v-if="!loading">
      <div class="TableWrapper__Header">
        <performance-title
          class="TableWrapper__Header__Title"
          :geography="titleLabels.geography"
          :metric-unit="titleLabels.chartTitle.metricUnit"
          :full-title="chartTitle"
          transparent
        />
        <div class="TableWrapper__Tools">
          <slot name="headerTools" />
        </div>
      </div>
      <DataTable
        :columns="columns"
        :data="formattedData"
        class="TableWrapper__Table"
      >
        <template #default="{ headers, rows }">
          <table class="table table-scrollable">
            <thead class="table__head">
              <tr class="table__head__row">
                <th
                  v-for="column in headers"
                  :key="column.key"
                  class="table__header"
                >
                  <span>{{ column.label }}</span>
                </th>
              </tr>
            </thead>
            <tbody class="table__body">
              <tr
                v-for="item in rows"
                :key="item.key"
                class="table__row"
              >
                <td
                  v-for="cell in item.cells"
                  :key="cell.key"
                  class="table__data"
                >
                  <span>{{ cell.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </DataTable>
      <div
        v-if="formattedData.length === 0"
        class="table__empty"
      >
        <NoData />
      </div>
    </div>
    <div
      v-else
      class="placeholder"
    >
      <loader-grid size="large" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderGrid from '@/components/LoaderGrid'
import DataTable from '@/components/market-growth/DataTable'
import NoData from '@/components/NoData'
import PerformanceTitle from '@/components/performance/PerformanceTitle'

export default {
  name: 'IpTable',
  components: {
    DataTable,
    NoData,
    PerformanceTitle,
    LoaderGrid
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    formattedData: {
      type: Array,
      default: () => []
    }

  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters({
      titleLabels: 'charts/titleLabels'
    }),
    chartTitle () {
      return this.titleLabels.chartTitle
        ? this.titleLabels.chartTitle.fullTitle
        : this.titleLabels.fullTitle
    }
  }
}
</script>

<style lang="scss" scoped>
@import "scss/variables";
@import "scss/components";

.TableWrapper {
  padding: 1.5em;
  position: relative;
  background-color: $color-white;
  margin-bottom: 10px;
  &__Table {
    padding: 1em;
  }
  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
     &__Export {
       padding: 0;
     }
     &__Title {
       padding-bottom: 0;
     }
  }
  &__Tools {
    @include xy-cell($size: shrink, $gutter-type: padding, $gutter-position: left, $gutters: 8px);
    &__Export {
      @extend %boxIcon;
      position: relative;
      color: $color-blue-header;
      &:hover {
        color: $color-white;
        background-color: $color-blue-header;
      }
    }
  }
}
.table {
  width: 100%;
  border-collapse: collapse;
  width: 100%;
  font-size: $font-size-13;
  &__data,
  &__header {
    border: 1px solid $color-nav-bg;
    padding: 8px;
  }
  &__row:nth-child(even) {
    background-color: $color-blue-bg-light;
  }
  &__row:hover {
    background-color: $color-nav-bg;
  }
  &__header {
    color: $color-blue-header;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }
  &__data {
    color: $color-nav-item-text;
  }
}

.placeholder {
  width: 100%;
  height: 100%;
  background-color: rgb(222, 229, 236);
  color: $color-white;
  font-size: 10em;
  padding: 37.5px 0;
  text-align: center;

  svg {
    animation: spin 3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
