<template>
  <div class="CompetitiveChartTitle">
    <div class="CompetitiveChartTitle__title">
      {{ titleLabels.chartTitle.geography }} | Competitive Trends

      <CustomTooltip
        v-if="tooltip"
        :message="tooltip"
      >
        <QuestionMark />
      </CustomTooltip>
    </div>
    <div class="CompetitiveChartTitle__subtitle">
      <span>
        {{ titleLabels.name }} ({{ titleLabels.chartTitle.metricUnit }})
      </span>
      <span v-if="titleLabels.warning">
        | No data available for selected end date. The closest available date was automatically selected.
      </span>
    </div>
  </div>
</template>

<script>
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'

export default {
  name: 'CompetitiveChartTitle',
  components: {
    QuestionMark,
    CustomTooltip
  },
  props: {
    titleLabels: {
      type: Object,
      default: undefined
    },
    tooltip: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .CompetitiveChartTitle {
    padding-bottom: 1em;
    color: $color-text-dark-blue;

    &__title {
      font-size: $font-size-26;
      font-weight: $font-weight-roboto-regular;
      font-style: italic;
      color: $color-text-gold-chart-title;
    }
    &__subtitle {
      font-size: $font-size-14;
      line-height: $font-size-20;
      font-weight: $font-weight-roboto-regular;
      color: $color-text-dark-blue;
    }
    &__date {
      font-weight: $font-weight-roboto-medium;
    }
  }
</style>
