<template>
  <div
    class="os-flatpickr"
    :class="inputCssClass"
  >
    <flat-pickr
      v-model="givenDate"
      :config="config"
    />
    <div class="os-flatpickr__icon">
      <FontAwesomeIcon
        :icon="faCalendarAlt"
        size="1x"
      />
    </div>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Flatpickr',
  components: {
    flatPickr,
    FontAwesomeIcon
  },
  props: {
    min: { type: Date, default: () => new Date() },
    max: { type: Date, default: () => new Date() },
    date: { type: Date, required: true },
    disable: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      faCalendarAlt,
      isDisabled: false
    }
  },
  computed: {
    inputCssClass () {
      let cssClasses = {}

      if (this.theme) {
        cssClasses['os-flatpickr--' + this.theme] = true
      }

      return cssClasses
    },
    parsedMin () {
      return this.getDatePickerAdjustedDate(this.min)
    },
    parsedMax () {
      return this.getDatePickerAdjustedDate(this.max)
    },
    givenDate: {
      get () {
        return this.getDatePickerAdjustedDate(this.date)
      },
      set (dates) {
        // eventhough we could get Z dates
        // the only way to know what the user seen is to get a naive date
        // that we format to ISO Z standard
        // this.date is the actual URL date
        const rawDate = `${dates}T00:00:00.000Z`

        if (!dates) {
          return
        }

        if (this.date.toISOString() !== rawDate) {
          this.$emit('selected', rawDate)
        }
      }
    },
    config () {
      return {
        dateFormat: 'Y-m-d',
        altInput: true,
        defaultHour: 0,
        altFormat: 'd F Y',
        minDate: this.getDatePickerAdjustedDate(this.min),
        maxDate: this.getDatePickerAdjustedDate(this.max),
        disable: this.disable,
        disableMobile: true
      }
    }
  },
  methods: {
    getDatePickerAdjustedDate (date) {
      // date picker always shows local time so we need to adjust the date to force
      // the picker to display the URL equivalent UTC time
      if (date.getTimezoneOffset() <= 0) {
        return new Date(date.toDateString())
      } else {
        return new Date(new Date(date.toDateString()).getTime() + 1000 * 60 * 60 * 24)
      }
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import 'scss/components';

  .os-flatpickr {
    position: relative;

    &:hover {
      .os-flatpickr__icon {
        color: $control-dark-text-color--hover;
      }
    }

    input {
      @extend %controlElement;
      padding: 0 0 0 2.4rem;

      &:hover {
        @extend %active
      }
    }

    &__icon {
      color: $color-blue-primary;
      position: absolute;
      left: 1.3rem;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    &--ci {
      input {
        cursor: default !important; // Fix visual bug where cursor becomes pointer for a second
        @extend %controlElement-ci;

        outline: none;

        &:hover {
          @extend %hover-ci;
        }
      }

      // Fix visual bug where cursor becomes default for a second when calendar is open
      .active {
        @extend %hover-ci;
        cursor: pointer !important;
      }

      .os-flatpickr__icon {
        color: white;
        opacity: .9;
      }
    }
  }

  .flatpickr-day.selected {
    @extend %active
  }
</style>
