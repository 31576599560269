<template>
  <div>
    <div
      :class="componentClass"
      class="SimpleRankingTable"
    >
      <slot name="header" />
      <table
        :class="tableClass"
        class="SimpleRankingTable__table"
      >
        <tbody
          :class="bodyClass"
          class="SimpleRankingTable__body"
        >
          <slot />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleRankingTable',
  props: {
    backgroundTransparent: { type: Boolean, default: undefined }
  },
  computed: {
    bodyClass () {
      return { 'SimpleRankingTable__body--transparent': this.backgroundTransparent }
    },
    componentClass () {
      return { 'SimpleRankingTable--transparent': this.backgroundTransparent }
    },
    tableClass () {
      return { 'SimpleRankingTable__table--round-corners': !this.$slots['header'] }
    }
  }
}
</script>

<style global lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .SimpleRankingTable {
    border-radius: $border-radius-medium;
    background-color: $color-blue-header;
    position: relative;

    &--transparent {
      background-color: transparent;
    }

    &__table {
      @include table($stripe: false);
      border-radius: 0 0 $border-radius-medium $border-radius-medium;
      border: 0;
      overflow: hidden;
      width: 100%;
      &--round-corners {
        border-radius: $border-radius-medium;
      }
    }

    &__body {
      background-color: $color-white;
      border-radius: 0 0 $border-radius-medium $border-radius-medium;

      &--transparent {
        background-color: transparent;
      }
    }
  }
</style>
