<template>
  <div
    v-if="ready"
    class="LocationShowcase"
  >
    <LocationSearch
      @navigate="setAsCurrentLocation"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import LocationSearch from '@/components/location-search/LocationSearch'

export default {
  name: 'LocationShowcase',
  components: {
    LocationSearch
  },
  data: () => ({
    ready: false
  }),
  computed: {
  },
  mounted () {
    this.setUser()
      .then(() => this.setUserSettings('performance'))
      .then(() => {
        this.setAsCurrentLocation('212')
        this.ready = true
      })
  },
  methods: {
    ...mapActions({
      setUser: 'setUser',
      setUserSettings: 'setUserSettings',
      setAsCurrentLocation: 'setAsCurrentLocation'
    })
  }
}
</script>
<style lang="scss" scoped>
.LocationShowcase {
  margin: 50px;
  width: 275px;
}
</style>
