const state = () => ({
  leafletStyleGreyedOut: {
    color: '#444A59',
    opacity: 0,
    fillColor: 'white',
    fillOpacity: 0,
    dashArray: '1 3'
  },
  leafletStyleFocused: {
    color: '#00FBFF',
    opacity: 1,
    fillOpacity: 0.95,
    dashArray: ''
  },
  leafletStyleDefault: {
    weight: 0.7,
    color: '#444A59',
    opacity: 0.7,
    fillOpacity: 0.75,
    dashArray: '1 3'
  },
  leafletStyleHighlighted: {
    color: '#00FBFF',
    opacity: 1,
    fillOpacity: 0.95,
    dashArray: ''
  }
})

const types = {
  FOCUS_TARGET_TOOLTIP: 'leaflet/focusTargetTooltip',
  HIDE_AND_REMOVE_TARGETS: 'leaflet/hideAndRemoveTargets',
  HIDE_TARGET_TOOLTIP: 'leaflet/hideTargetTooltip',
  LEAFLET_STYLE_DEFAULT: 'leaflet/leafletStyleDefault',
  LEAFLET_STYLE_FOCUSED: 'leaflet/leafletStyleFocused',
  LEAFLET_STYLE_GREYED_OUT: 'leaflet/leafletStyleGreyedOut',
  LEAFLET_STYLE_HIGHLIGHTED: 'leaflet/leafletStyleHighlighted',
  ON_MOUSE_OUT: 'leaflet/onMouseOut',
  ON_MOUSE_OVER: 'leaflet/onMouseOver'
}

const tooltipTargets = []

const getters = {
  [types.LEAFLET_STYLE_GREYED_OUT]: (state, getters, rootState, rootGetters) => state.leafletStyleGreyedOut,
  [types.LEAFLET_STYLE_FOCUSED]: (state, getters, rootState, rootGetters) => state.leafletStyleFocused,
  [types.LEAFLET_STYLE_DEFAULT]: (state, getters, rootState, rootGetters) => state.leafletStyleDefault,
  [types.LEAFLET_STYLE_HIGHLIGHTED]: (state, getters, rootState, rootGetters) => state.leafletStyleHighlighted
}

const actions = {
  [types.HIDE_TARGET_TOOLTIP]: (_, target) => {
    target.setStyle(getItemStyle(getters.feature))
    // [Edge] manual update of the container visibility is needed for Edge browser compatibility
    target._tooltip._container.style.visibility = 'hidden'
  },
  [types.FOCUS_TARGET_TOOLTIP]: (_, target) => {
    target.setStyle(getItemStyle({ ...target.feature, highlighted: true }))
    // [Edge] manual update of the container visibility is needed for Edge browser compatibility
    target._tooltip._container.style.visibility = 'visible'
  },
  [types.HIDE_AND_REMOVE_TARGETS]: ({ dispatch }) => {
    tooltipTargets.map((target) => {
      dispatch(types.HIDE_TARGET_TOOLTIP, target)
    })
    tooltipTargets.length = 0
  },
  [types.ON_MOUSE_OVER]: ({ dispatch }, { target }) => {
    dispatch(types.HIDE_AND_REMOVE_TARGETS)
    tooltipTargets.push(target)
    dispatch(types.FOCUS_TARGET_TOOLTIP, target)
    target.bringToFront()
  },
  [types.ON_MOUSE_OUT]: ({ dispatch }, { target }) => {
    dispatch(types.HIDE_AND_REMOVE_TARGETS)
    if (!target.feature.selected) { // TODO: ??? repeated line
      dispatch(types.HIDE_AND_REMOVE_TARGETS, target)
    }
  }
}

function getItemStyle (feature) {
  const { disabled, highlighted, selected, fillColor } = feature
  const baseStyle = fillColor ? { fillColor } : {}
  if (disabled) {
    return state.leafletStyleGreyedOut
  } else if (highlighted) {
    return { ...baseStyle, ...state.leafletStyleHighlighted }
  } else if (selected) {
    return { ...baseStyle, ...state.leafletStyleFocused }
  } else {
    return { ...baseStyle, ...state.leafletStyleDefault }
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
