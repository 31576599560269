<template>
  <tooltip-wrapper title="Export as CSV">
    <div
      class="export"
      @click="onClick"
    >
      <FontAwesomeIcon
        :icon="icons.faFileCsv"
        size="1x"
      />
    </div>
  </tooltip-wrapper>
</template>

<script>
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper'

export default {
  name: 'CsvExportButton',
  components: {
    TooltipWrapper,
    FontAwesomeIcon
  },
  data () {
    return {
      icons: {
        faFileCsv
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "scss/variables";
@import "scss/components";

.export {
  @extend %boxIcon;
  position: relative;
  color: $color-blue-header;
  &:hover {
    color: $color-white;
    background-color: $color-blue-header;
  }
}
</style>
