// TODO implement when spec and user configured
// there is curently no such pair
// usecase: on first load navigate to the first city that has
// a user home network
export const findCityNetworkPair = (cities, networks) => {
  let city
  let network
  return { city, network }
}

export const networksWithColors = networks => networks.map(n =>
  n.hex_color ? n : { ...n, hex_color: 'cccccc' }
)
