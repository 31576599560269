<template>
  <div
    v-if="itemsData.length"
    class="MetricGeographies"
    :class="{ 'MetricGeographies--scroll': !isFocusedView || enableScrolling }"
  >
    <template v-if="isNationalLevel">
      <!-- National  -->
      <div
        v-if="displayNationalInfo"
        class="MetricGeographies__national"
      >
        <div class="MetricGeographies__title">
          <span class="MetricGeographies__titleLabel">
            <span v-if="displayHomeNetworkName">
              {{ homeNetwork.name_mapped }}:
            </span>
            National
          </span>

          <CustomTooltip
            v-if="tooltipMessage"
            :message="tooltipMessage"
          >
            <QuestionMark />
          </CustomTooltip>
        </div>

        <div class="MetricGeographies__body">
          <SingleMetricArea v-bind="nationalArea" />
        </div>
      </div>

      <!-- Regions/Cities -->

      <div class="MetricGeographies__title">
        <span class="MetricGeographies__titleLabel">
          <span v-if="displayHomeNetworkName">
            {{ homeNetwork.name_mapped }}:
          </span>
          {{ selectedGrouping.name }}
        </span>

        <CustomTooltip
          v-if="tooltipMessage"
          :message="tooltipMessage"
        >
          <QuestionMark />
        </CustomTooltip>
      </div>

      <div
        v-if="availableStandardGroupings && availableStandardGroupings.length > 1"
        class="MetricGeographies__topButtons"
      >
        <button
          v-for="grouping in availableStandardGroupings"
          :key="grouping.id"
          class="MetricGeographies__button"
          :class="{ 'MetricGeographies__button--active': selectedGrouping.id === grouping.id }"
          @click="selectGrouping(grouping)"
        >
          {{ groupLabel(grouping.client, grouping.granularity) }}
        </button>
      </div>

      <div class="MetricGeographies__body">
        <template v-if="isFocusedView && selectedGrouping">
          <div class="MetricGeographies__bodyTitle">
            Your Top {{ itemsDataTopSlice.length }} {{ selectedGrouping.name }}
          </div>
          <MetricAreaListFocused
            :areas-data="itemsDataTopSlice"
            :class="{ 'MetricAreaListFocused--compare': !isSingleOperator }"
            @selectLocation="selectLocation"
          />

          <div
            v-if="itemsDataBottomSlice.length"
            class="MetricGeographies__bodyTitle"
          >
            Your Bottom {{ itemsDataBottomSlice.length }} {{ selectedGrouping.name }}
          </div>
          <MetricAreaListFocused
            :areas-data="itemsDataBottomSlice"
            :class="{ 'MetricAreaListFocused--compare': !isSingleOperator }"
            @selectLocation="selectLocation"
          />
        </template>

        <template v-else>
          <template v-if="isSingleOperator">
            <MetricAreaListFocused
              :areas-data="itemsData"
              @selectLocation="selectLocation"
            />
          </template>
          <template v-else>
            <MetricAreaListFull
              :areas-data="itemsData"
              @selectLocation="selectLocation"
            />
          </template>
        </template>
      </div>

      <div
        v-if="displayToggle"
        class="MetricGeographies__bottomButtons"
      >
        <button
          class="MetricGeographies__button"
          @click="toggleFocusedView"
        >
          <span v-if="isFocusedView">Reveal All</span>
          <span v-else>Top and Bottom</span>
        </button>
      </div>
    </template>

    <template v-else>
      <template v-if="zoomedItemData">
        <div class="MetricGeographies__title">
          <span class="MetricGeographies__titleLabel">
            {{ getGroupingLabelByLocation(zoomedItemData.properties.id).name }} |  {{ zoomedItemData.properties.name }}
          </span>
        </div>

        <div class="MetricGeographies__place">
          Place in sorted list:
          <span class="MetricGeographies__placeNumber">{{ zoomedItemData.index }}</span>
        </div>

        <template v-if="isSingleOperator">
          <MetricAreaListFocused
            :areas-data="[zoomedItemData]"
            @selectLocation="selectLocation"
          />
        </template>
        <template v-else>
          <RankingTable
            :bigger-better="metric.bigger_is_better"
            :ranks="zoomedItemData.item"
            :min-and-max="minAndMax(zoomedItemData)"
          />
        </template>

        <div class="MetricGeographies__drillDown">
          <router-link
            v-if="hasPI && hasDrillDown"
            :to="getDrillDownLink(zoomedItemData)"
            target="_blank"
            class="MetricGeographies__drillDownButton"
          >
            Drill Down in Performance Intelligence
          </router-link>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import get from 'lodash.get'
import mp from 'mixpanel-browser'
import { mapGetters, mapActions } from 'vuex'
import CustomTooltip from '../tooltip/CustomTooltip'
import QuestionMark from '../tooltip/QuestionMark'
import { metricMinAndMax } from '../visual/map/helpers'
import MetricAreaListFocused from './MetricAreaListFocused'
import MetricAreaListFull from './MetricAreaListFull'
import RankingTable from './RankingTable'
import SingleMetricArea from './SingleMetricArea'
import { CI_TO_PI_MAPPINGS, GEO_POSITION, LICENCES_BY_TECHNOLOGY } from '@/constants/constants'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'

export default {
  name: 'MetricGeographies',
  components: {
    CustomTooltip,
    QuestionMark,
    MetricAreaListFocused,
    MetricAreaListFull,
    RankingTable,
    SingleMetricArea
  },
  props: {
    isNationalLevelAlways: {
      type: Boolean,
      default: false
    },
    displayHomeNetworkName: {
      type: Boolean,
      default: false
    },
    enableScrolling: {
      type: Boolean,
      default: false
    },
    displayNational: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tooltipMessage: TOOLTIP_MESSAGES.metricGeographies
    }
  },

  computed: {
    ...mapGetters({
      isFocusedView: 'competitive/isFocusedView',
      focusedNetworkId: 'competitive/focusedNetworkId',
      rankedGeographies: 'competitive/rankedGeographies',
      homeNetwork: 'charts/homeNetwork',
      groupings: 'location/byGroupings',
      metric: 'metrics/primaryMetric',
      theme: 'competitive/metricTheme',
      products: 'auth/getProducts',
      selectedPoints: 'dashboard/selectedPoint',
      hiddenNetworkIds: 'charts/hiddenNetworksIds',
      groupLabel: 'location/groupLabel',
      titleLabels: 'charts/titleLabels',
      homeHighlight: 'charts/homeHighlight',
      location: 'location/currentLocation'
    }),
    ...mapGetters([
      'shapes',
      'cityShapes',
      'dashboardInfo',
      'user'
    ]),
    displayNationalInfo () {
      return this.homeHighlight && this.displayNational
    },
    geocoding: {
      get () {
        return router.currentRoute.value.params.geocoding
      },
      set (geocoding) {
        this.$router.push({
          name: this.$router.name,
          params: {
            ...this.$router.params,
            geocoding
          }
        })
      }
    },
    chartTitle () {
      if (!this.displayHomeNetworkName) {
        return ''
      }
      return this.titleLabels.chartTitle ? this.titleLabels.chartTitle.fullTitle : this.titleLabels.fullTitle
    },
    displayToggle () {
      if (this.isFocusedView) {
        return true
      }
      return this.rankedGeographies.some(geography => {
        const area = geography.item.find(item => item.network === this.focusedNetworkId)
        return area && area.rank != null
      })
    },
    itemsDataTopSlice () {
      return this.itemsData.filter(item => item.position === GEO_POSITION.TOP)
    },
    itemsDataBottomSlice () {
      return this.itemsData.filter(item => item.position === GEO_POSITION.BOTTOM)
    },
    isSingleOperator () {
      return router.currentRoute.value.params.network !== 'all'
    },
    isNationalLevel () {
      if (this.isNationalLevelAlways) {
        return true
      }
      return router.currentRoute.value.params.location === router.currentRoute.value.params.countryid
    },
    isOsGranularity () {
      return [2, 3].includes(Number(this.geocoding))
    },
    availableStandardGroupings () {
      let buttonsGeocoding = [2, 3]
      if (this.isOsGranularity) {
        return this.groupings.filter(group => buttonsGeocoding.includes(group.id))
      }
      return []
    },
    zoomedItemData () {
      return this.itemsData.find(item => item.id + '' === router.currentRoute.value.params.location)
    },
    selectedGrouping: {
      get () {
        return this.groupings.find(grouping => grouping.id + '' === String(this.geocoding))
      },
      set (grouping) {
        this.geocoding = `${grouping.id}`
      }
    },
    hasPI () {
      const metric = LICENCES_BY_TECHNOLOGY[this.metric.kind]
      const getTechnology = (type) => {
        switch (type) {
          case '5gmmwave':
            return 'mmWave'
          case '5g':
            return 'FiveG'
          default:
            return 'FourG'
        }
      }
      const PILicence = get(metric, [
        getTechnology(this.metric.type),
        'PI'
      ])
      const hasPILicence = this.user.licences.includes(PILicence)
      return this.metric.dashboards && this.metric.dashboards.includes('performance') && hasPILicence
    },
    hasDrillDown () {
      if (this.metric.type === 'overall') {
        return false
      }

      return CI_TO_PI_MAPPINGS[this.metric.subtype]
    },
    itemsData () {
      return this.rankedGeographies
    },
    nationalArea () {
      if (!this.homeHighlight) {
        return
      }
      const { lci, uci } = this.homeHighlight
      const color = this.homeNetwork && this.homeNetwork.hex_color
      const nationalArea = {
        name: this.location.name,
        value: this.homeHighlight.estimate || this.homeHighlight.mean,
        lci,
        uci,
        color: `#${color}`
      }
      return {
        ...nationalArea,
        ...metricMinAndMax([nationalArea], this.metric)
      }
    }
  },
  mounted () {
    this.setAsCurrentLocation(router.currentRoute.value.params.location)
  },
  methods: {
    ...mapActions([
      'setAsCurrentLocation',
      'setFocusedView'
    ]),
    toggleFocusedView () {
      const newValue = !this.isFocusedView
      this.setFocusedView(newValue)

      mp.track('metric geographies focused toggle', {
        isFocusedView: newValue,
        route: this.$router.name
      })
    },
    selectLocation (location) {
      if (typeof location !== 'number') {
        return
      }

      this.$router.push({
        name: this.$router.name,
        params: {
          ...this.$router.params,
          location: `${location}`
        }
      })

      this.$emit('selectLocation', location)
    },
    selectGrouping (grouping) {
      this.selectedGrouping = grouping
      this.setFocusedView(true)
    },
    minAndMax (area) {
      return metricMinAndMax(area.item, this.metric)
    },
    getFocusedStats (items) {
      return items.find(item => item.network === this.focusedNetworkId)
    },
    getGroupingLabelByLocation (locationId) {
      return this.groupings.find(group => {
        return group.locations.find(l => {
          return l.key === locationId + ''
        })
      })
    },
    getDrillDownLink (itemData) {
      let metricType = ['5gmmwave', '5glow', '5gmid', '5ghigh', '5g', 'lte', '3g'].includes(this.metric.type) ? this.metric.type : 'lte'

      let params = {
        chartType: CI_TO_PI_MAPPINGS[this.metric.subtype].type,
        location: itemData.properties.id,
        agg: router.currentRoute.value.params.agg,
        date: this.dashboardInfo.last_date_available,
        hero: this.metric.subtype + '_' + metricType,
        chart: CI_TO_PI_MAPPINGS[this.metric.subtype].key + '_' + metricType
      }

      return `/performance/${params.chartType}/${params.location}/${params.agg}/${params.date}/${params.hero}/${params.chart}`
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';

  .MetricGeographies {
    @include xy-grid(vertical, $wrap: false);
    margin: 30px 0 0;
    flex: 1;
    height: 100%;

    &__wrapper {
      @include xy-grid(vertical, $wrap: false);
      flex: 1;
    }

    &__national {
      margin-bottom: 23px;
    }

    &__title {
      display: flex;
      flex: 0 0 23px;
      margin-bottom: 10px;
      justify-content: flex-start;
      align-items: center;
    }

    &__titleLabel {
      font-size: $font-size-20;
      font-weight: $font-weight-roboto-medium;
      font-style: italic;
      text-transform: capitalize;
    }

    &__topButtons {
      display: flex;
      flex: 0 0 37px;
      margin: 0 -3px 6px;
    }

    &__bottomButtons {
      display: flex;
      flex: 0 0 37px;
      margin: 12px -3px 0;
      justify-content: center;

      .MetricGeographies__button {
        margin: 0;
        flex: 0 1 50%;
        height: 35px;
      }
    }

    &__button {
      flex: 1;
      border: 0;
      box-shadow: none;
      line-height: 32px;
      border-radius: $border-radius-medium;
      margin: 0 3px;
      font-size: $font-size-12;
      cursor: pointer;
      outline: 0;
      background: $color-sidebar-grey;

      &--active {
        color: #fff;
        background: $color-blue-header;
      }
    }

    &__body {
      flex-flow: column;
      background: $color-sidebar-grey;
      border: 6px solid $color-sidebar-grey;
      border-radius: $border-radius-medium;
    }

    &--scroll {
      overflow: hidden;

      .MetricGeographies__body {
        overflow: auto;
        display: flex;

        > div {
          width: 100%;
        }
      }

    }
    &__bodyTitle {
      font-size: $font-size-16;
      font-weight: $font-weight-roboto-medium;
      font-style: italic;
      text-align: center;
      padding: 10px 0;

      &:first-child {
        padding-top: 4px;
      }
    }

    &__place {
      background: #fff;
      margin-bottom: 1rem;
      float: left;
      clear: both;
      padding: 10px;
      align-self: flex-start;
    }

    &__placeNumber {
      font-style: italic;
      font-size: 1.5rem;
    }

    &__drillDown {
      margin-top: 1rem;
      text-align: center;
    }
    &__drillDownButton {
      display: inline-block;
      background: $winner-gold;
      text-decoration: none;
      color: $color-text-default;
      padding: .5rem 1rem;
      font-size: $font-size-13;
      border-radius: $border-radius-small;
    }
  }
</style>
