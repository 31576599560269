import Vue from 'vue'
import { V1_CDN_RES, V1_CDN_RES_ENDPOINT_LABELS } from '@/constants/cdnRes'
import router from '@/router'
import { filterHiddenData } from '@/utils/data'
import { isHero, getUnit } from '@/utils/metrics'

export default function create (namespace, initial = [], metric = '') {
  if (!namespace) {
    throw new TypeError('No namespace provided for the chart-store 💣')
  }

  const types = {
    METRIC: `${namespace}/metric`,
    BY_METRIC: `${namespace}/byMetric`,
    BY_NETWORK_AND_METRIC: `${namespace}/byNetworkAndMetric`,
    BY_REGION: `${namespace}/byRegion`,
    CP: `${namespace}/cp`,
    CPIP: `${namespace}/cpip`,
    IP: `${namespace}/ip`,
    CDN: `${namespace}/cdn`,
    CP_FOCUS: `${namespace}/cpFocus`,
    CDN_FOCUS: `${namespace}/cdnFocus`,
    CDN_RES: `${namespace}/cdnRes`,
    CDN_RES_V2: `${namespace}/isCdnResV2`,
    CDN_RES_IP: `${namespace}/cdnResIp`,
    CDN_RES_FOCUS: `${namespace}/cdnResFocus`,
    CHART_EMPTY: `${namespace}/chartEmpty`,
    CHART_ERROR: `${namespace}/chartError`,
    CHART_PENDING: `${namespace}/chartPending`,
    DEVICES: `${namespace}/devices`,
    DIST: `${namespace}/dist`,
    DIST_ALL: `${namespace}/dist_all`,
    DISTRIBUTION: `${namespace}/distribution`,
    DISTRIBUTION_CDN_RES: `${namespace}/distributionCdnRes`,
    RANGES: `${namespace}/ranges`,
    HERO: `${namespace}/hero`,
    CHART: `${namespace}/chart`,
    HOURLY: `${namespace}/hourly`,
    TREND: `${namespace}/trend`,
    LOADING_METRICS: `${namespace}/loadingMetrics`,
    UNIT: `${namespace}/unit`,
    SET: `${namespace}:SET`,
    SET_OVERVIEW: `${namespace}:SET_OVERVIEW`,
    SET_CHART_PENDING: `${namespace}:SET_CHART_PENDING`,
    SET_CHART_SUCCESS: `${namespace}:SET_CHART_SUCCESS`,
    SET_CHART_FAILURE: `${namespace}:SET_CHART_FAILURE`
  }

  const state = () => ({
    array: initial,
    metric: metric,
    loadingMetrics: [],
    chartPending: true,
    chartError: false
  })

  const mutations = {
    [types.SET] (state, { array, metric }) {
      state.array = [...array]
      state.metric = metric
    },
    [types.SET_OVERVIEW] (state, array) {
      const savedKeys = []
      const generateKey = item => `${item.canonical_network_id}_${item.metric}`

      state.array = array.reduce((accumulator, item) => {
        const key = generateKey(item)

        if (savedKeys.includes(key)) {
          return accumulator
        }

        savedKeys.push(key)
        return [
          ...accumulator,
          item
        ]
      }, [])
    },
    [types.SET_CHART_PENDING] (state, metricKey = null) {
      if (metricKey) {
        state.loadingMetrics = [
          ...state.loadingMetrics,
          metricKey
        ]
      } else {
        state.chartPending = true
        state.chartError = false
      }
    },
    [types.SET_CHART_SUCCESS] (state, metricKey = null) {
      if (metricKey) {
        state.loadingMetrics = state.loadingMetrics.filter(metric => metric !== metricKey)
      } else {
        state.chartPending = false
      }
      state.chartError = false
    },
    [types.SET_CHART_FAILURE] (state) {
      state.chartPending = false
      state.chartError = true
    }
  }

  // TODO add local types to them
  const getters = {
    [namespace]: (state) => state.array,
    [types.METRIC]: (state, getters, rootState, rootGetters) => rootGetters.metrics.find(m => m.key === state.metric) || { structure: '' },
    [types.CHART_EMPTY]: (state) => !state.array.length && !state.chartPending,
    [types.CHART_PENDING]: (state) => state.chartPending ? 'loading' : false,
    [types.CHART_ERROR]: (state) => state.chartError,
    [types.LOADING_METRICS]: (state) => state.loadingMetrics,
    [types.UNIT]: (_, getters) => ({
      short: getUnit(getters[types.METRIC])
    }),
    [types.BY_METRIC]: (_, getters, { route }) => {
      if (!router.currentRoute.value.params.metric) return []

      return state.array.filter(datum => datum.metric === router.currentRoute.value.params.metric)
    },
    [types.BY_REGION]: (state, getters, { route }) => {
      if (!router.currentRoute.value.params.focused || !getters['dashboard/date']) return []

      return state.array.filter(datum => `${datum.location}` === router.currentRoute.value.params.focused)
    },
    [types.BY_NETWORK_AND_METRIC]: (state, getters, { route }) => {
      if (!router.currentRoute.value.params.network || !router.currentRoute.value.params.metric) return []

      return getters[types.BY_METRIC].filter(datum => `${datum.canonical_network_id}` === router.currentRoute.value.params.network)
    },
    [types.CHART]: ({ metric, array }, getters, { route }, rootGetters) => {
      if (!metric) return []

      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    },
    [types.HERO]: ({ metric, array }, getters, { route }, rootGetters) => {
      if (!metric || !isHero(metric)) return []

      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    },
    [types.TREND]: ({ array }, getters) => {
      if (
        getters[types.METRIC].structure !== 'estimate_simple' &&
        getters[types.METRIC].structure !== 'ranked_simple' &&
        getters[types.METRIC].structure !== 'mean' &&
        getters[types.METRIC].structure !== 'simple_count' &&
        getters[types.METRIC].structure !== 'simple' &&
        getters[types.METRIC].structure !== 'percentage_simple' &&
        getters[types.METRIC].structure !== 'median_simple'
      ) return []

      return array
    },
    [types.CP]: ({ array }, getters) => {
      if (getters[types.METRIC].subcategory !== 'cp') return []

      return array
    },
    [types.IP]: ({ array }, getters, rootState, rootGetters) => {
      if (getters[types.METRIC].subcategory !== 'ip') return []
      return filterHiddenData(array, rootGetters['charts/hiddenNetworksIds'])
    },
    [types.CPIP]: ({ array }, getters, rootState, rootGetters) => {
      if (getters[types.METRIC].subcategory !== 'cpip') return []
      return filterHiddenData(array, rootGetters['charts/hiddenNetworksIds'])
    },
    [types.CDN]: ({ array }, getters) => {
      if (getters[types.METRIC].subcategory !== 'cdn') return []

      return array
    },
    [types.CP_FOCUS]: ({ array }, getters, { route }, rootGetters) => {
      if (!getters[types.CP].length) return []

      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    },
    [types.CDN_FOCUS]: ({ array }, getters, { route }, rootGetters) => {
      if (!getters[types.CDN].length) return []

      let filteredByDate = array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])

      if (!filteredByDate.length) {
        let maxDate = rootGetters['dashboard/getLatestDate'](array)

        filteredByDate = array.filter(datum => datum.date && datum.date === maxDate)
      }

      return filteredByDate
    },
    [types.HOURLY]: ({ array }, getters, { route }) => {
      if (
        getters[types.METRIC].subcategory !== 'hourly' &&
        getters[types.METRIC].subcategory !== 'enodebcellcountdist'
      ) return []

      return array.filter(datum => datum.date && datum.date === getters['dashboard/date'])
    },
    [types.DISTRIBUTION]: ({ array }, getters, { route }) => {
      if (getters[types.METRIC].structure !== 'binned') return []

      return array.filter(datum => datum.date && datum.date === getters['dashboard/date'])
    },
    [types.DISTRIBUTION_CDN_RES]: ({ array }, getters, { route }) => {
      if (getters[types.METRIC].structure !== 'binnedcdnres') return []

      return array.filter(datum => datum.date && datum.date === getters['dashboard/date'])
    },
    [types.RANGES]: ({ array }, getters, { route }) => {
      if (getters[types.METRIC].structure !== 'ranked_simple') return []

      return array
    },
    [types.CDN_RES]: ({ array }, getters) => {
      if (getters[types.METRIC].subcategory !== 'cdnres') return []
      // format here
      return array
    },
    [types.CDN_RES_V2]: ({ array }, getters) => {
      if (getters[types.METRIC].category !== 'video') return false

      if (getters[types.METRIC].subcategory === 'binnedcdnres') {
        const labels = Object.keys(V1_CDN_RES_ENDPOINT_LABELS)
        if (!array[0]) {
          return false
        }
        const elementCdns = array[0].cdn.map(cdn => cdn.key)
        return !labels.some(label => elementCdns.includes(elementCdns))
      }

      const dataIndex = array.findIndex(element => {
        return element && Object.keys(element.mean).length > 0
      })

      if (dataIndex === -1) {
        return true
      }

      return !V1_CDN_RES.some(cdnRes => array[dataIndex].mean.hasOwnProperty(cdnRes))
    },
    [types.CDN_RES_IP]: ({ array }, getters, rootState, rootGetters) => {
      if (getters[types.METRIC].subcategory !== 'ip') return []
      return filterHiddenData(array, rootGetters['charts/hiddenNetworksIds'])
    },
    [types.CDN_RES_FOCUS]: ({ array }, getters, { route }, rootGetters) => {
      if (!getters[types.CDN_RES].length) return []
      // format here
      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    },
    [types.DIST]: ({ array }, getters, { route }, rootGetters) => {
      if (getters[types.METRIC].subcategory !== 'cellbanddist') return []
      // format here
      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    },
    [types.DIST_ALL]: ({ array }, getters, { route }) => {
      if (getters[types.METRIC].subcategory !== 'cellbanddist') return []
      return array
    },
    [types.DEVICES]: ({ array }, getters, { route }, rootGetters) => {
      if (getters[types.METRIC].category !== 'devices') return []
      // format here
      return array.filter(datum => datum.date && datum.date === rootGetters['dashboard/date'])
    }
  }

  return {
    types,
    state,
    mutations,
    getters
  }
}
