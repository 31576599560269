<template>
  <li
    :class="elClass"
    class="PerformanceTabsTitle"
    @click="propagateEvent()"
  >
    <a>
      <div
        v-if="active"
        class="PerformanceTabsTitle__active-corner"
      />
      <slot />
      <div
        v-if="active"
        class="PerformanceTabsTitle__active-corner PerformanceTabsTitle__active-corner--right"
      />
    </a>
  </li>
</template>

<script>
export default {
  name: 'PerformanceTabsTitle',
  props: {
    active: { type: Boolean, default: false }
  },
  computed: {
    elClass: function () {
      return { 'PerformanceTabsTitle--active': this.active }
    }
  },
  methods: {
    propagateEvent () {
      this.$emit('clicked')
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .PerformanceTabsTitle {
    @include tabs-title($padding: pxToRem(10) pxToRem(10), $font-size: $font-size-12);
    margin-left: pxToRem(1);
    position: relative;
    a {
      background-color: $color-nav-bg;
      border-radius: $border-radius-small $border-radius-small 0 0;
      border: none;
      color: $color-blue-header;
    }
    &:hover a {
      background-color: $color-brand-blue;
      color: $color-white;
      cursor: pointer;
    }
    &--active a, &--active:hover a {
      background-color: $color-white;
      color: $color-blue-header;
    }
    &__active-corner {
      $active-corner-size: pxToRem(4);
      background-image: radial-gradient(circle at 0 0, transparent $active-corner-size, $color-white $active-corner-size);
      bottom: 0;
      height: $active-corner-size;
      left: - $active-corner-size;
      position: absolute;
      width: $active-corner-size;
      z-index: $z-index-highlights-item;
      &--right {
        background-image: radial-gradient(circle at 100% 0, transparent $active-corner-size, $color-white $active-corner-size);
        left: unset;
        right: - $active-corner-size;
      }
    }
  }
</style>
