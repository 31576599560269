<template>
  <div class="BarList">
    <div class="BarList__title">
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BarList',
  props: {
    title: { type: String, required: true }
  },
  computed: {}
}
</script>

<style lang="scss">
  @import 'scss/variables';
  .BarList {
    &__title {
      color: $color-electric-blue;
      font-size: $font-size-18;
      padding: 5px;
    }
    padding-bottom: 5px;
  }
</style>
