<template>
  <div class="ColourScalesExample">
    <h2>Colour Scales</h2>
    <div class="ColourScalesExample__horizontal-grid">
      <div class="ColourScalesExample__col-shrink">
        <h3>Colour scale</h3>
        <div class="ColourScalesExample__horizontal-grid">
          <div class="ColourScalesExample__slider-ext">
            Name:
          </div>
          <div class="ColourScalesExample__col-auto">
            <select
              v-model="selectedColorScaleName"
              :style="{width: '100%'}"
            >
              <option
                v-for="(cs, i) in colorScales"
                :key="i"
              >
                {{ i }}
              </option>
            </select>
          </div>
        </div>
        <br>
        <div class="ColourScalesExample__horizontal-grid">
          <div class="ColourScalesExample__col-shrink ColourScalesExample__col-shrink--no-gutters">
            Test value:
          </div>
          <div class="ColourScalesExample__col-auto">
            <input
              v-model="selectedValue"
              type="text"
              style="width: 100%"
            >
          </div>
        </div>
        <br>
        <div class="ColourScalesExample__horizontal-grid">
          <div class="ColourScalesExample__col-shrink ColourScalesExample__col-shrink--no-gutters">
            {{ min }}
          </div>
          <div class="ColourScalesExample__col-auto">
            <input
              id="start"
              v-model="selectedValue"
              :min="min"
              :max="max"
              style="width: 100%"
              type="range"
              name="volume"
            >
          </div>
          <div class="ColourScalesExample__col-shrink ColourScalesExample__col-shrink--no-gutters">
            {{ max }}
          </div>
        </div>
      </div>
      <div class="ColourScalesExample__col-shrink">
        <h3> Colour preview</h3>
        <div
          :style="{ backgroundColor: selectedColor}"
          :class="{'ColourScalesExample__color-preview--invalid': !selectedColor}"
          class="ColourScalesExample__color-preview"
        >
          <div
            v-if="!selectedColor"
            class="ColourScalesExample__color-preview-message"
          >
            VALUE OUT OF RANGE
          </div>
        </div>
      </div>
      <div
        v-if="selectedColorScale"
        class="ColourScalesExample__col-shrink"
      >
        <div class="ColourScalesExample__colour-scale">
          <h3>DynamicColourScale component</h3>
          <dynamic-colour-scale
            :ranges="selectedColorScale.ranges"
            :unit="selectedColorScale.unit"
          />
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as colorScales from '../../constants/colorScales'
import { getScaleMax, getScaleMin, getColor } from '../../utils/colors'
import DynamicColourScale from '../visual/map/DynamicColourScale'

export default {
  name: 'ColourScalesExample',
  components: { DynamicColourScale },
  data () {
    return {
      colorScales,
      selectedColorScaleName: Object.keys(colorScales)[0],
      selectedValue: 0
    }
  },
  computed: {
    selectedColorScale () {
      const { colorScales, selectedColorScaleName } = this
      return colorScales[selectedColorScaleName]
    },
    min () {
      const { selectedColorScale } = this
      return getScaleMin(selectedColorScale) - 1
    },
    max () {
      const { selectedColorScale } = this
      return getScaleMax(selectedColorScale) + 1
    },
    selectedColor () {
      const { selectedColorScale, selectedValue } = this
      return getColor(selectedColorScale, Number.parseFloat(selectedValue))
    }
  },
  watch: {
    selectedColorScaleName () {
      this.selectedValue = 0
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';

  .ColourScalesExample {
    &__horizontal-grid {
      @include xy-grid(horizontal);
    }
    &__col-shrink {
      @include xy-cell(shrink);
      position: relative;

      &--no-gutters {
        @include xy-cell(shrink, $gutters: 0);
      }
    }
    &__col-auto {
      @include xy-cell(auto);
    }

    &__color-preview {
      $size: 100px;
      @include elevation($elevation-4);
      border: 1px solid transparent;
      display: flex;
      font-weight: $font-weight-roboto-bold;
      height: $size;
      justify-content: center;
      text-align: center;
      transition: background-color 250ms linear;
      width: $size;
      &--invalid {
        border: 1px solid rgba(black, 0.5)
      }
    }
    &__color-preview-message {
      padding: 20px;
    }
    &__colour-scale {
      @include xy-cell(shrink)
    }

  }
</style>
