<template>
  <div
    id="app"
  >
    <router-view />
    <disclaimer />
  </div>
</template>
<script>
import mp from 'mixpanel-browser'
import { mapGetters } from 'vuex'
import Disclaimer from './components/disclaimer/Disclaimer'

export default {
  name: 'App',
  components: {
    Disclaimer
  },
  data () {
    return {
      mixpanel: process.env.VUE_APP_MIXPANEL_TOKEN
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    ...mapGetters({
      info: 'dashboardInfo'
    })
  },
  mounted () {
    mp.init(this.mixpanel, { persistence: 'localStorage' })
    mp.register({ env: process.env.VUE_APP_ENV_LOCATION })
    if (process.env.VUE_APP_ENV_LOCATION !== 'prod') {
      mp.disable()
    }
    mp.track('app loaded')
  }
}
</script>
<style global lang="scss">
@import 'scss/variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,900');

@import '~foundation-sites/scss/vendor/normalize';
@include foundation-normalize();

html, body, #app {
  height: 100%;
}

body {
  background-color: $color-blue-secondary !important;
  color: $color-text-default;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: $base-font-size;
  font-weight: $font-weight-roboto-regular;
}
</style>
