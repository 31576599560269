<template lang="html">
  <div class="TooltipWrapper">
    <slot />
    <div class="TooltipWrapper__tooltip">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipWrapper',
  props: {
    title: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';
  @import 'scss/components';
  .TooltipWrapper {
    position: relative;
    display: inline-block;
    min-height: 2rem;
    line-height: 2rem;
    &:hover {
      .TooltipWrapper__tooltip {
        display: block;
      }
    }
    &__tooltip {
      @extend %titleTooltip;
      @include elevation($elevation-2);
      top: -85%;
      right: 100%;
      display: none;
    }
  }
</style>
