<template>
  <div
    class="SwitchInput"
    :class="switchInputClasses"
  >
    <div
      class="SwitchInput__inputWrapper"
      @click="!disabled && $emit('update:modelValue', !modelValue)"
    >
      <div
        :class="{ 'SwitchInput__input--checked': modelValue }"
        class="SwitchInput__input"
      />

      <span class="SwitchInput__label">{{ label }}</span>
    </div>

    <CustomTooltip
      v-if="tooltip"
      :message="tooltip"
      class="SwitchInput__tooltip"
      placement="bottom"
    >
      <QuestionMark :theme="theme" />
    </CustomTooltip>
  </div>
</template>

<script>
import { CustomTooltip, QuestionMark } from '@/components/tooltip'

export default {
  name: 'SwitchInput',
  components: {
    CustomTooltip,
    QuestionMark
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Placeholder text'
    },
    tooltip: {
      type: String,
      default: null,
      required: false
    },
    negativeMargin: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    switchInputClasses: function () {
      var cssClasses = {}

      if (this.theme) {
        cssClasses['SwitchInput--' + this.theme] = true
      }

      cssClasses['SwitchInput--disabled'] = this.disabled

      return cssClasses
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.SwitchInput {
  font-size: $control-font-size;
  line-height: 16px;
  margin: .5rem 0;
  color: $control-light-text-color;

  &--disabled {
    opacity: .5;
  }

  &.SwitchInput--dark {
    .SwitchInput {
      &__input {
        &::before {
          background-color: #dde3e9;
        }
        &::after {
          background: #bbc8d4;
        }

        &--checked {
          + .SwitchInput__label {
            color: $winner-gold;
          }

          &.SwitchInput__input:before {
            background-color: lighten($winner-gold, 20%);
          }

          &.SwitchInput__input:after {
            background-color: $winner-gold;
          }
        }
      }
    }
  }

  &__inputWrapper {
    display: inline;
  }

  &__input {
    position: relative;
    float: left;
    padding: 0 8px;
    width: 23px;
    height: 16px;
    cursor: pointer;

    &:before {
      display: block;
      margin-top: 2px;
      width: 23px;
      height: 12px;
      border-radius: 12px;
      background: #fff;
      content: "";
      transition: all .3s ease-in-out;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 2px 2px 5px #999;
      content: "";
      transition: all .3s ease-in-out;
      box-sizing: border-box;
    }

    &--checked.SwitchInput__input:before {
      background-color: #6dcded;
    }

    &--checked.SwitchInput__input:after {
      left: calc(100% - 16px);
      background-color: #00b5f0;
    }
  }

  &__label {
    padding-left: 7px;
    cursor: pointer;
  }

  &--ci {
    color: $color-white;

    .SwitchInput {
      &__input {
        padding: 0;
        width: 27px;

        &::before {
          background-color: rgba($color-white, .5);
          width: 27px;
          height: 10px;
          margin-top: 3px;
        }

        &::after {
          background: #bbc8d4;
          box-shadow: none;
          border: 1px solid #707070;
        }

        &--checked {
          &.SwitchInput__input:after {
            background-color: $color-white;
          }
        }
      }
    }
  }
  &--mg {
    color: $color-white;

    .SwitchInput {
      &__input {
        padding: 0;
        width: 27px;

        &::before {
          background-color: rgba($color-white, .5);
          width: 27px;
          height: 10px;
          margin-top: 3px;
        }

        &::after {
          background: #bbc8d4;
          box-shadow: none;
          border: 1px solid #707070;
        }

        &--checked {
          &.SwitchInput__input:after {
            background-color: $active-mg;
          }
        }
      }
    }
  }
}
</style>
