import Vue from 'vue'
import Router, { createRouter, createWebHistory } from 'vue-router'

import ROUTES from '@/constants/routes'
import Colors from '@/views/Colors'
import CompetitiveIntelligence from '@/views/competitive/CompetitiveIntelligence'
import CompetitiveDetails from '@/views/competitive/Details'
import CompetitiveOverview from '@/views/competitive/Overview'
import KitchenSink from '@/views/KitchenSink'
import Landing from '@/views/Landing'
import LocationShowcase from '@/views/LocationShowcase'
import Login from '@/views/Login'
import MarketGrowthDetails from '@/views/market-growth/Details'
import MarketGrowth from '@/views/market-growth/MarketGrowth'
import MarketGrowthOverview from '@/views/market-growth/Overview'
import ChartBar from '@/views/performance/ChartBar'
import ChartBinned from '@/views/performance/ChartBinned'
import ChartBinnedCdnRes from '@/views/performance/ChartBinnedCdnRes'
import ChartCdn from '@/views/performance/ChartCdn'
import ChartCdnOps from '@/views/performance/ChartCdnOps'
import ChartCdnRes from '@/views/performance/ChartCdnRes'
import ChartCdnResIp from '@/views/performance/ChartCdnResIp'
import ChartCdnResOps from '@/views/performance/ChartCdnResOps'
import ChartCombinedCategories from '@/views/performance/ChartCombinedCategories'
import ChartCp from '@/views/performance/ChartCp'
import ChartCpIp from '@/views/performance/ChartCpIp'
import ChartCpOps from '@/views/performance/ChartCpOps'
import ChartDist from '@/views/performance/ChartDist'
import ChartHourly from '@/views/performance/ChartHourly'
import ChartIp from '@/views/performance/ChartIp'
import ChartJson from '@/views/performance/ChartJson'
import ChartNetworkByNetwork from '@/views/performance/ChartNetworkByNetwork'
import ChartRanges from '@/views/performance/ChartRanges'
import ChartSimple from '@/views/performance/ChartSimple'
import PerformanceIntelligenceOverview from '@/views/performance/Overview'
import PerformanceHero from '@/views/performance/PerformanceHero'
import PerformanceIntelligence from '@/views/performance/PerformanceIntelligence'
import TypographyShowcase from '@/views/TypographyShowcase'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/test',
      component: Login
    },
    {
      path: '/login',
      name: ROUTES.Login,
      component: Login,
      meta: {
        title: 'Opensignal | Login'
      },
      props: true
    },
    // Competitive Intelligence
    {
      path: '/competitive',
      component: CompetitiveIntelligence,
      meta: {
        title: 'Competitive Intelligence'
      },
      children: [
        {
          path: 'overview/:location/:compareTo/:date',
          name: ROUTES.CompetitiveOverview,
          component: CompetitiveOverview,
          props: true
        },
        {
          path: 'details/:location/:geocoding/:country/:countryid/:agg/:metric/:network/:date',
          name: ROUTES.CompetitiveDetails,
          component: CompetitiveDetails,
          props: true
        }
      ]
    },
    // Performance Intelligence
    {
      path: '/performance/overview/:location/:geocoding/:agg/:date/:hero/:chart',
      name: ROUTES.PerformanceIntelligenceOverview,
      component: PerformanceIntelligenceOverview,
      meta: {
        title: 'Performance Intelligence Overview'
      },
      props: false
    },
    {
      path: '/performance',
      component: PerformanceIntelligence,
      meta: {
        title: 'Performance Intelligence'
      },
      children: [
        {
          path: 'hourly/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceHourly,
          components: {
            hero: PerformanceHero,
            charts: ChartHourly
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'trend/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceSimple,
          components: {
            hero: PerformanceHero,
            charts: ChartSimple
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'binned/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceBinned,
          components: {
            hero: PerformanceHero,
            charts: ChartBinned
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cp/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCp,
          components: {
            hero: PerformanceHero,
            charts: ChartCp
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cpip/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCpIp,
          components: {
            hero: PerformanceHero,
            charts: ChartCpIp
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cdn/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCdn,
          components: {
            hero: PerformanceHero,
            charts: ChartCdn
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'ip/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceIp,
          components: {
            hero: PerformanceHero,
            charts: ChartIp
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cpops/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCpOps,
          components: {
            hero: PerformanceHero,
            charts: ChartCpOps
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cdnops/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCdnOps,
          components: {
            hero: PerformanceHero,
            charts: ChartCdnOps
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cdnres/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCdnRes,
          components: {
            hero: PerformanceHero,
            charts: ChartCdnRes
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cdnresops/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCdnResOps,
          components: {
            hero: PerformanceHero,
            charts: ChartCdnResOps
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'cdnresip/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCdnResIp,
          components: {
            hero: PerformanceHero,
            charts: ChartCdnResIp
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'binnedcdnres/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceBinnedCdnRes,
          components: {
            hero: PerformanceHero,
            charts: ChartBinnedCdnRes
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'dist/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceDist,
          components: {
            hero: PerformanceHero,
            charts: ChartDist
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'bar/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceBar,
          components: {
            hero: PerformanceHero,
            charts: ChartBar
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'json/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PlainJson,
          components: {
            hero: PerformanceHero,
            charts: ChartJson
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'categories/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceCombCat,
          components: {
            hero: PerformanceHero,
            charts: ChartCombinedCategories
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'nbn/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceNByN,
          components: {
            hero: PerformanceHero,
            charts: ChartNetworkByNetwork
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: 'ranges/:location/:agg/:date/:hero/:chart',
          name: ROUTES.PerformanceIntelligenceRanges,
          components: {
            hero: PerformanceHero,
            charts: ChartRanges
          },
          props: {
            hero: true,
            charts: true
          }
        },
        {
          path: '',
          name: ROUTES.PerformanceIntelligence,
          components: {
            hero: PerformanceHero,
            charts: ChartBar
          },
          props: {
            hero: false,
            charts: false
          }
        }
      ]
    },
    // Market Growth
    {
      path: '/market-growth',
      name: ROUTES.MarketGrowth,
      component: MarketGrowth,
      meta: {
        title: 'Market Growth'
      },
      children: [
        {
          path: 'overview/:country/:strategy',
          name: ROUTES.MarketGrowthOverview,
          component: MarketGrowthOverview,
          props: true
        },
        {
          path: 'details/:country/:granularity/:metric/:strategy/:brand/:competitor',
          name: ROUTES.MarketGrowthDetails,
          component: MarketGrowthDetails,
          props: false
        }
      ]
    },
    {
      path: '/kitchen-sink',
      name: ROUTES.KitchenSink,
      component: process.env.VUE_APP_DEV_PAGES ? KitchenSink : Login,
      meta: {
        title: 'Kitchen Sink'
      },
      props: false
    },
    {
      path: '/location-showcase',
      name: ROUTES.LocationShowcase,
      component: process.env.VUE_APP_DEV_PAGES ? LocationShowcase : Login,
      meta: {
        title: 'Location Showcase'
      },
      props: false
    },
    {
      path: '/colors',
      name: ROUTES.Colors,
      component: process.env.VUE_APP_DEV_PAGES ? Colors : Login,
      meta: {
        title: 'Colors'
      },
      props: false
    },
    {
      path: '/titles/:location/:agg/:date/:mode',
      name: 'TypographyShowcase',
      component: process.env.VUE_APP_DEV_PAGES ? TypographyShowcase : Login,
      meta: {
        title: 'Typography Showcase'
      },
      props: true
    },
    {
      path: '/:params?',
      name: ROUTES.Landing,
      component: Landing,
      meta: {
        title: 'Opensignal'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.matched.length ? to.matched[0].meta.title : 'Opensignal'
  next()
})

export default router
