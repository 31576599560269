<template>
  <div>
    <canvas
      :id="chartId"
      width="400"
      height="400"
    />
    <ranking-table-tooltip
      :pos-class="tooltip.posClass"
      :tooltip-style="tooltip.tooltipStyle"
      :datapoints="tooltip.datapoints"
      :display="tooltip.display"
      :title="tooltip.title"
      :unit="tooltip.unit"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
// TODO: Don't remove this import, even if it's not used here. Otherwise it'll break other charts
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import get from 'lodash.get'
import RankingTableTooltip from './RankingTableTooltip'
import {
  X_AXIS_BASE_STYLE,
  Y_AXIS_BASE_STYLE,
  SCALE_TITLE_STYLE
} from '@/constants/chartjs'
import {
  getDatapointsByIndex,
  getTooltipState
} from '@/utils/charts'
import colors from '@/utils/colorPalette'
import { getTextColor } from '@/utils/colors'

export default {
  name: 'GroupBarChart',
  components: { RankingTableTooltip },
  props: {
    chartId: {
      default: 'group-bar-chart',
      type: String
    },
    max: {
      default: 150,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    labels: {
      type: Array,
      default: () => []
    },
    dataSet: {
      type: Array,
      default: () => []
    },
    xAxisLabel: {
      type: String,
      default: undefined
    },
    yAxisLabel: {
      type: String,
      default: undefined
    },
    xAxisMaxTickValue: {
      type: Number,
      default: undefined
    },
    rainbow: {
      type: Boolean,
      default: false
    },
    barLabels: {
      type: Boolean,
      default: false
    },
    showVerticalLabels: {
      type: Boolean,
      default: false
    },
    showConfidenceRate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      tooltip: {
        datapoints: undefined,
        display: false,
        posClass: '',
        tooltipStyle: {},
        unit: ''
      }
    }
  },
  computed: {
    formattedLabels () {
      return this.rainbow ? this.dataSet.map(set => set.label) : this.labels
    },
    formattedDataSet () {
      const formatDataPoint = (point, index) => {
        return {
          ...point,
          x: this.formattedLabels[index]
        }
      }

      if (this.rainbow) {
        let colors = this.dataSet.map(set => set.color)
        return this.dataSet[0].data.map((firstOp, i) => {
          let data = this.dataSet.map(set => set.data[i])
          return {
            label: this.labels[i],
            borderWidth: 1,
            backgroundColor: colors,
            borderColor: colors,
            data: data.map(formatDataPoint)
          }
        })
      }

      return this.dataSet.map(set => ({
        label: set.label,
        borderWidth: 1,
        backgroundColor: set.color,
        borderColor: set.color,
        data: set.data.map(formatDataPoint)
      }))
    }
  },
  watch: {
    dataSet () {
      this.getChart().data.datasets = this.formattedDataSet
      this.getChart().data.labels = this.formattedLabels
      this.getChart().options.scales.y.max = this.max <= 1 ? this.max : Math.ceil(this.max + this.max / 5)
      this.getChart().update()
    },
    min () {
      this.getChart().options.scales.y.min = this.min
      this.getChart().update()
    },
    rainbow () {
      this.getChart().data.datasets = this.formattedDataSet
      this.getChart().data.labels = this.formattedLabels
      this.getChart().update()
    },
    barLabels () {
      this.getChart().data.showBarLabels = this.barLabels
      this.getChart().update()
    },
    showConfidenceRate () {
      this.getChart().data.displayConfidenceRate = this.showConfidenceRate
      this.getChart().update()
    }
  },
  mounted () {
    this.renderChart()
  },
  methods: {
    getChart () {
      return Chart.getChart(this.chartId)
    },
    renderChart () {
      const vm = this
      const generalPlugin = {
        afterDatasetsDraw (chart, args, options) {
          const ctx = chart.ctx
          chart.data.datasets.map(function (dataset, i) {
            const meta = chart.getDatasetMeta(i)
            if (!meta.hidden) {
              meta.data.map(function (element, index) {
                ctx.fillStyle = colors.colorBlack
                ctx.strokeStyle = colors.colorBlack
                ctx.lineWidth = 1

                const top = dataset.data[index].uci - dataset.data[index].y
                const bot = dataset.data[index].y - dataset.data[index].lci

                const position = element.tooltipPosition()
                const vScale = chart.scales.y.maxHeight / chart.scales.y.max

                const ptop = position.y - top * vScale
                const pbot = position.y + bot * vScale

                const barWidth = element.width

                const a = 13 / 31
                const b = 7 - 6 * a
                const valueFontSize = Math.max(Math.min(barWidth * a + b, 18), 7)

                const value = dataset.data[index].y

                const minMaxFontSize = (valueFontSize + 2) / 2

                const valuePosX = position.x
                const valuePosY = ptop - minMaxFontSize

                const sizeLabel = dataset.label
                const labelFontSize = Math.min(14, barWidth * 0.8)
                const barBottom = chart.scales.y.bottom
                const textX = position.x + labelFontSize * 0.3
                const textY = barBottom - 10
                const textBarHeight = textY - pbot
                const barColor = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[index] : dataset.backgroundColor

                // confidence range interval
                if (chart.data.displayConfidenceRate) {
                  const confLineWidth = (barWidth > 10) ? 5 : (barWidth / 2)
                  ctx.beginPath()
                  ctx.moveTo(position.x - confLineWidth, ptop)
                  ctx.lineTo(position.x + confLineWidth, ptop)
                  ctx.moveTo(position.x, ptop)
                  ctx.lineTo(position.x, pbot)
                  ctx.moveTo(position.x - confLineWidth, pbot)
                  ctx.lineTo(position.x + confLineWidth, pbot)
                  ctx.stroke()
                  ctx.restore()
                }

                if (chart.data.showBarLabels && chart.data.displayConfidenceRate && value) {
                  // values on top of bars
                  ctx.fillStyle = colors.colorBlack
                  ctx.font = `${valueFontSize}px sans-serif`
                  ctx.textAlign = 'center'
                  ctx.fillText(value.toFixed(1), valuePosX, valuePosY)
                }

                if (chart.data.showVerticalLabels && barWidth > 12) {
                  // verical labels in bars
                  ctx.fillStyle = getTextColor(barColor)

                  let text = ''

                  ctx.font = `${labelFontSize}px sans-serif`

                  if (textBarHeight > ctx.measureText(sizeLabel).width + 2) {
                    text = sizeLabel
                  } else if (textBarHeight > ctx.measureText(sizeLabel[0]).width + 2) {
                    text = sizeLabel[0]
                  }

                  ctx.textAlign = 'left'

                  ctx.save()
                  ctx.translate(textX, textY)
                  ctx.rotate(-90 * Math.PI / 180)
                  ctx.fillText(text, 0, 0)
                  ctx.restore()
                }
              })
            }
          })
        }
      }
      const newChart = new Chart(this.chartId, {
        plugins: [generalPlugin],
        type: 'bar',
        data: {
          labels: this.formattedLabels,
          datasets: this.formattedDataSet,
          showBarLabels: this.barLabels,
          showVerticalLabels: this.showVerticalLabels,
          rainbowView: this.rainbow,
          displayConfidenceRate: this.showConfidenceRate
        },
        options: {
          animation: false,
          height: 400,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              mode: 'x',
              intersect: false,
              position: 'nearest',
              callbacks: {
                title: (item) => item
              },
              external: (context) => {
                const tooltip = context.tooltip
                if (!this.formattedDataSet) return

                const tooltipState = getTooltipState(this.getChart(), tooltip)
                const datapoints = getDatapointsByIndex(vm.formattedDataSet, get(tooltip, ['title', 0, 'dataIndex']))

                if (datapoints) {
                  if (datapoints.length < 10) {
                    const tooltipTitle = get(tooltip, ['title', 0, 'label'])
                    this.tooltip = {
                      ...this.tooltip,
                      ...tooltipState,
                      datapoints,
                      title: `${this.xAxisLabel}: ${tooltipTitle}`
                    }
                  } else {
                    const evPosition = this.getChart().tooltip._eventPosition && this.getChart().tooltip._eventPosition.x

                    this.getChart().data.datasets
                      .map((d, i) => this.getChart().getDatasetMeta(i).data)
                      .flat()
                      .sort((a, b) => { return a.x - b.x })
                      .map((e, i, arr) => {
                        const barLocation = e.x - e.width / 2
                        const nextBarLocation = arr[i + 1] ? arr[i + 1].x - e.width / 2 : Infinity
                        if (datapoints && evPosition >= barLocation && evPosition < nextBarLocation) {
                          let pointFound = {
                            index: null,
                            label: null
                          }

                          this.tooltip = {
                            ...this.tooltip,
                            ...tooltipState,
                            // point and label index
                            datapoints: datapoints.filter(d => {
                              if (!d) return false
                              if (!context.tooltip.getActiveElements()[0]) return false
                              const compIndex = this.labels.findIndex(x => x === d.label)

                              if (compIndex === context.tooltip.getActiveElements()[0].datasetIndex) {
                                pointFound.label = this.labels.find(x => x === d.label)
                                pointFound.index = compIndex
                                return true
                              }

                              return false
                            }),
                            title: `${this.xAxisLabel}: ${pointFound.label}`
                          }
                        }
                      })
                  }
                } else {
                  this.tooltip.display = false
                }
              }
            }
          },
          scales: {
            y: {
              min: this.min,
              max: this.max <= 1 ? this.max : Math.ceil(this.max + this.max / 5),
              ticks: {
                ...Y_AXIS_BASE_STYLE.ticks,
                callback: (c, i) => {
                  return i > 0 ? c : ''
                },
                padding: 10
              },
              grid: {
                offsetGridLines: false,
                drawTicks: false
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.yAxisLabel,
                text: this.yAxisLabel
              }
            },
            x: {
              ticks: {
                ...X_AXIS_BASE_STYLE.ticks,
                autoSkip: true
              },
              grid: {
                ...X_AXIS_BASE_STYLE.gridLines,
                display: false,
                drawTicks: false
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.xAxisLabel,
                text: this.xAxisLabel
              }
            }
          }
        }
      })
    }

  }
}
</script>
