<template>
  <div
    :class="menuCssClass"
    class="MenuTitle"
  >
    <div class="MenuTitle__title">
      {{ label }}
      <span @click.stop="tooltipClick">
        <QuestionMark
          v-if="tooltip"
          class="MenuTitle__tooltip"
          theme="ci"
        />
      </span>
    </div>
  </div>
</template>

<script>
import QuestionMark from '@/components/tooltip/QuestionMark'

export default {
  name: 'MenuTitle',
  components: {
    QuestionMark
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menuCssClass () {
      if (this.theme) {
        return 'MenuTitle--' + this.theme
      }

      return ''
    }
  },
  methods: {
    tooltipClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MenuTitle {
  margin-bottom: .5em;
  margin-top: .5em;
  overflow: hidden;
  margin-right: -10px;

  &__title {
    color: $color-blue-header;
    display: inline-block;
    position: relative;
    font-size: $font-size-14;
    font-weight: $font-weight-roboto-medium;
    font-style: italic;

    &::after {
      border-bottom: 1px solid $color-blue-separator;
      content: '';
      margin: 0 .5rem;
      position: absolute;
      left: 100%;
      top: 50%;
      width: 50vw;
    }
  }

  &__tooltip {
    font-style: normal;
  }

  &--ci {
    .MenuTitle__title {
      color: $gold;

      &::after {
        border-bottom-color: $gold;
      }
    }
  }

  &--mg {
    .MenuTitle__title {
      color: $mg-gold;

      &::after {
        border-bottom-color: $mg-gold;
      }
    }
  }
}
</style>
