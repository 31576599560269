<template>
  <div
    v-click-outside="close"
    :class="networksMenuCssClass"
    class="NetworksMenu"
    @click="() => toggleDropdown()"
  >
    <div
      :class="{ 'NetworksMenu__input--active': isActive }"
      class="NetworksMenu__input"
    >
      <span
        v-if="!displayNetworks || !displayNetworks.length"
        class="NetworksMenu__placeholder"
      >
        Please select
      </span>

      <div class="NetworksMenu__selections">
        <OperatorAvatar
          v-for="network in displayNetworks.slice(0, maxNetworksDisplayed)"
          :key="network.name_mapped"
          :letter="network.name_mapped[0]"
          :background-color="`#${network.hex_color}`"
        />
        <span v-if="displayNetworks.length > maxNetworksDisplayed">
          ...
        </span>
      </div>

      <FontAwesomeIcon
        :icon="icons.faSignal"
        size="1x"
      />

      <FontAwesomeIcon
        :icon="isMobile ? icons.faChevronDown : icons.faChevronRight"
        size="1x"
      />
    </div>
    <ul
      class="NetworksMenu__options"
      :style="isMobile && expanded ? 'display: block' : ''"
    >
      <li class="NetworksMenu__item NetworksMenu__item--title">
        {{ single ? 'Select one Operator' : 'Select multiple Operators' }}
      </li>
      <li
        v-for="network in availableNetworks"
        :key="network.newtworkLetter"
        :class="{
          'NetworksMenu__item--active': itemIsActive(network.canonical_network_id, network.selected),
          'NetworksMenu__item--home': !single && isHomeNetwork(network),
          'NetworksMenu__item--selectable': single || isSelectable(network)
        }"
        class="NetworksMenu__item"
        @click="single || isSelectable(network) ? changeSelection(network.canonical_network_id, network.selected) : null"
      >
        <OperatorAvatar
          :letter="network.name_mapped[0]"
          :background-color="`#${network.hex_color}`"
        />
        {{ network.name_mapped }}
        {{ isHomeNetwork(network) ? '(you)' : '' }}
      </li>
    </ul>
  </div>
</template>

<script>
import { faChevronDown, faChevronRight, faSignal } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapGetters } from 'vuex'
import OperatorAvatar from '@/components/OperatorAvatar'
import router from '@/router'

export default {
  name: 'NetworksMenu',
  components: {
    FontAwesomeIcon,
    OperatorAvatar
  },
  props: {
    single: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: { faChevronDown, faChevronRight, faSignal },
      maxNetworksDisplayed: 7,
      expanded: false
    }
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/ci_networks',
      compare: 'competitive/compare',
      homeNetwork: 'charts/homeNetwork',
      hiddenNetworkIds: 'charts/hiddenNetworksIds',
      isMobile: 'page/isMobile'
    }),
    selected () {
      const networkOperators = this.availableNetworks
      return networkOperators.filter(o => o.selected)
    },
    displayNetworks () {
      return this.single
        ? this.availableNetworks.filter(n => `${n.canonical_network_id}` === router.currentRoute.value.params.network)
        : this.selected
    },
    isActive () {
      return this.single ? !this.compare : this.compare
    },
    networksMenuCssClass () {
      if (this.theme) {
        return 'NetworksMenu--' + this.theme
      }

      return ''
    }
  },
  methods: {
    ...mapActions([
      'toggleOperator'
    ]),
    itemIsActive (network, selected) {
      return this.single ? parseInt(router.currentRoute.value.params.network) === network : selected
    },
    changeSelection (network, selected) {
      if (this.single) {
        router.push({
          name: router.currentRoute.value.name,
          params: {
            ...router.currentRoute.value.params,
            network: `${network}`
          }
        })
      } else {
        if (!this.compare) {
          this.changeMode()
        }
        this.toggleOperator(network)
      }
    },
    isHomeNetwork (network) {
      return this.homeNetwork && network.canonical_network_id === this.homeNetwork.canonical_network_id
    },
    isSelectable (network) {
      if (this.isHomeNetwork(network)) {
        return false
      }

      if (this.selected.length === 2 && network.selected) {
        return false
      }

      return true
    },
    toggleDropdown (isExpanded) {
      if (isExpanded !== undefined) {
        this.expanded = isExpanded
      } else {
        this.expanded = !this.expanded
      }
    },
    close () {
      this.toggleDropdown(false)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.NetworksMenu {
  position: relative;

  .OperatorAvatar {
    margin-right: 10px;
    border: 1px solid #fff;
  }

  &:hover {
    .NetworksMenu__input {
        @extend %active;
    }
    .NetworksMenu__options {
      @media screen and (min-width: $ci-breakpoint-tablet + 1) {
        display: block;
      }
    }
  }

  &__placeholder {
    flex: 1;
  }

  &__input {
    @extend %controlElement;
    margin-bottom: 0;

    .svg-inline--fa + .svg-inline--fa {
      margin-left: 10px;
    }

    &--active.NetworksMenu__input {
      @extend %active;
    }
  }

  &__selections {
    flex: 1;
  }

  &__options {
    display: none;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 100%;
    z-index: 1;

    margin: 0 0 0 1px;
    padding: 0 0 .5rem;
    list-style: none;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      left: -1px;
    }
  }

  &__item {
    @extend %controlElement;
    justify-content: flex-start;

    &--active.NetworksMenu__item {
      @extend %active;
    }

    &:hover {
      @extend %active;
    }

    &--title.NetworksMenu__item{
      font-style: italic;

      &:hover {
        @extend %controlElement;
        cursor: default;
      }
    }

    &--home.NetworksMenu__item {
      @extend %active;
      background-color: grey;
    }

    &--selectable.NetworksMenu__item:hover {
      cursor: pointer;
    }
  }

  &--ci {
    &:hover {
      .NetworksMenu__input {
        @extend %hover-ci;
      }
    }

    .NetworksMenu__input {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
      }
    }

    .NetworksMenu__options {
      background: $ci-dark-blue;
      padding: 0;
      top: 0;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        top: 100%;
      }
    }

    .NetworksMenu__item {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
      }

      &--title.NetworksMenu__item{
        &:hover {
          @extend %controlElement-ci;
        }
      }

      &--active.NetworksMenu__item:not(.NetworksMenu__item--home) {
        @extend %active-ci;

        &:hover {
          @extend %active-ci;
        }
      }

      &--active.NetworksMenu__item--selectable {
        &:hover {
          cursor: pointer;
        }
      }

      &--home.NetworksMenu__item {
        @extend %active-ci;

        &:hover {
          @extend %active-ci;
        }
      }
    }
  }
}
</style>
