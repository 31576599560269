import Base from './base'

export class Overview extends Base {
  constructor (token) {
    super()
    this.token = `Token ${token}`
    this.endpoint = 'overview/location/'
  }

  getDataByLocation (
    location,
    date,
    metrics,
    dashboard = this.defaultDashboard,
    compareTo,
    aggregation = '90days'
  ) {
    let params = `?operator_info=yes&metric_list=${metrics}`

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`
    }

    if (compareTo) {
      params += `&compare_all_intervals=true`
    }

    return this.get(
      `${this.v2}${dashboard}/${this.endpoint}${location}/${aggregation}/${params}`,
      this.token
    )
  }
}
