<template>
  <div>
    <performance-title
      :geography="geography"
      :metric-unit="titleLabels.metricUnit"
      :full-title="titleLabels.fullTitle"
      :tooltip="tooltip"
    />
    <div class="InfoLabel">
      <span class="InfoLabel__date">{{ titleLabels.startDate }} - {{ titleLabels.endDate }}</span>
      <CustomTooltip
        :message="message"
        placement="top"
      >
        <QuestionMark
          class="questionMark"
          theme="pi"
        />
      </CustomTooltip>

      | {{ titleLabels.aggregation }}
      | Data set updated <span v-if="titleLabels.lastUpdated === 0">yesterday </span><span v-else>{{ titleLabels.lastUpdated }} days ago </span>
      <p
        v-if="showDateWarning"
        class="InfoLabel__warning"
      >
        Data for last available date closest to the selected End Date
      </p>
    </div>
    <highlight-bar v-if="!slim">
      <highlight-card
        v-for="(h, i) in highlights.data"
        :key="h.x"
        :value="h.y"
        :lci="h.lci"
        :uci="h.uci"
        :unit="heroUnit === '0 - 100' ? '' : heroUnit"
        :color="displayOperatorColors ? h.color : 'transparent'"
        :label="h.x"
        :positive="h.positive"
        :percentage="h.percentage"
        :disabled="h.disabled"
        :tooltip-enabled="i === 0"
      />
    </highlight-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HighlightCard from '@/components/performance/HighlightCard'
import HighlightBar from '@/components/performance/HighlightsBar'
import PerformanceTitle from '@/components/performance/PerformanceTitle'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'
import { WEEKLY_METRICS_CATEGORIES } from '@/constants/constants'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'

export default {
  name: 'PerfomanceHero',
  components: {
    HighlightCard,
    HighlightBar,
    PerformanceTitle,
    CustomTooltip,
    QuestionMark
  },
  props: {
    date: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    location: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      required: true
    },
    displayOperatorColors: {
      type: Boolean,
      default: true
    },
    slim: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: TOOLTIP_MESSAGES.performanceEndDateInfo
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo',
      'locations'
    ]),
    ...mapGetters({
      chartMetric: 'metrics/primaryMetric',
      currentLocation: 'locations/current',
      heroMetric: 'metrics/supportingMetric',
      heroUnit: 'metrics/supportingUnit',
      highlights: 'charts/getHighlights',
      titleLabels: 'charts/titleLabels',
      tooltip: 'metrics/supportingTooltip'
    }),
    geography () {
      if (this.slim) {
        return ''
      }
      return this.titleLabels.geography
    },
    showDateWarning () {
      return WEEKLY_METRICS_CATEGORIES.includes(this.chartMetric.category)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.InfoLabel {
  font-size: $font-size-11;
  position: absolute;
  top: 1rem;
  right: 1rem;
  &__date {
    font-weight: 600;
  }
  &__warning {
    margin-top: 0.3em;
    margin-left: 0.5em;
    font-style: italic;
    font-size: $font-size-10;
    color: $color-blue-header;
  }
}

.questionMark {
   display: flex;
   align-items: flex-end;
   justify-content: center;
}
</style>
