<template>
  <div
    v-if="dashboardInfo.data_last_updated"
    class="CompetitiveMenu"
    :class="{ 'CompetitiveMenu--collapsed': !isMenuVisible }"
  >
    <MenuToggle />

    <UserGroupInfoModal v-model:displayModal="displayModal" />

    <dashboard-switch
      :products="products"
      :current="options.dashboard"
      theme="ci"
      @navigate="mode => navigateToDashboard({ mode })"
      @signout="signOut"
    />

    <div
      v-if="$route.name === ROUTES.CompetitiveDetails"
      class="CompetitiveMenu__nav-group"
    >
      <div
        class="CompetitiveMenu__nav-item CompetitiveMenu__navItem--back"
        @click="toOverview()"
      >
        <div class="CompetitiveMenu__nav-icon">
          <img src="../../assets/icons/home.svg">
        </div>
        go back to Overview
      </div>
    </div>

    <div class="CompetitiveMenu__content">
      <menu-title
        label="Parameters"
        theme="ci"
      />

      <div class="ParametersLayout">
        <div class="ParametersLayout__grid">
          <div
            v-if="!isCoverage"
            class="ParametersLayout__geographies"
          >
            <FieldGroup
              label="Geographies"
              theme="ci"
            >
              <LocationSearch
                :enable-group-navigation="$route.name === ROUTES.CompetitiveDetails"
                theme="ci"
                @navigate="location => update({ location, geocodingOverwrite: true })"
                @navigateGeocoding="(geocoding, location) => update({
                  geocoding,
                  location,
                  geocodingOverwrite: false,
                  operatorModeOverwrite: true
                })"
              />
            </FieldGroup>

            <div v-if="$route.name === ROUTES.CompetitiveOverview">
              <FieldGroup
                label="End Date"
                theme="ci"
              >
                <div>
                  <flatpickr
                    :min="parsedFirstDateAvailable"
                    :max="parsedLastDateAvailable"
                    :date="parsedCurrentDate"
                    theme="ci"
                    @selected="choseNewDate"
                  />
                </div>
              </FieldGroup>
            </div>

            <FieldGroup
              v-if="$route.name === ROUTES.CompetitiveDetails"
              theme="ci"
            >
              <SwitchInput
                v-model="viewPolygons"
                label="View as Polygons"
                tooltip="Change display of small areas on the map, usually cities, from pins to polygon representation."
                theme="ci"
              />
            </FieldGroup>
          </div>
          <div
            v-if="$route.name === ROUTES.CompetitiveDetails && !isCoverage"
            class="ParametersLayout__aggregation"
          >
            <FieldGroup
              slot="aggregation"
              label="Aggregation"
              theme="ci"
              :disabled="isAggregationDisabled"
            >
              <SelectInput
                :tooltip="aggregationTooltipMessage"
                :options="aggregationOptions"
                :selected-value="aggregation"
                theme="ci"
                :disabled="isAggregationDisabled"
                @select="agg => update({ agg })"
              />
            </FieldGroup>
          </div>
          <div v-if="$route.name === ROUTES.CompetitiveOverview">
            <FieldGroup
              label="Compare to"
              theme="ci"
            >
              <SelectInput
                :tooltip="compareToTooltipMessage"
                :options="compareToOptions"
                :selected-value="compareTo"
                theme="ci"
                @select="compareTo => update({ compareTo })"
              />
            </FieldGroup>
          </div>
          <div v-if="$route.name === ROUTES.CompetitiveOverview">
            <FieldGroup
              label="Layer"
              theme="ci"
            >
              <SelectInput
                :tooltip="technologyTooltipMessage"
                :options="getDefaultMetricTypeOptions"
                :selected-value="selectedDefaultMetricType"
                theme="ci"
                @select="selectLayer"
              >
                <template #option="{ option }">
                  {{ option.label }}
                </template>
              </SelectInput>
            </FieldGroup>
            <FieldGroup
              v-if="displayOverviewUserGroups"
              label="User Groups"
              theme="ci"
            >
              <SelectInput
                :options="userGroupOptions"
                :selected-value="userGroup"
                theme="ci"
                @select="setSelectedUserGroup"
              >
                <template #tooltip>
                  <!-- @click.stop="displayUserGroupModal" -->
                  <div
                    class="group-tooltip"
                    @click="displayUserGroupModal"
                  >
                    <QuestionMark theme="ci" />
                  </div>
                </template>
              </SelectInput>
            </FieldGroup>
          </div>
        </div>
      </div>
      <menu-title
        v-if="$route.name === ROUTES.CompetitiveDetails"
        label="Operators &amp; Visualisation"
        theme="ci"
      />
      <FieldGroup
        v-if="$route.name === ROUTES.CompetitiveDetails"
        :label="singleOperatorMode ? 'Single Operator' : 'Compare Operators'"
        theme="ci"
      >
        <networks-menu
          :single="singleOperatorMode"
          theme="ci"
        />
      </FieldGroup>
      <FieldGroup
        v-if="$route.name === ROUTES.CompetitiveDetails && !isCoverage"
        theme="ci"
      >
        <SwitchInput
          v-model="singleOperatorMode"
          label="Single Operator Mode"
          theme="ci"
        />
      </FieldGroup>
      <FieldGroup
        v-if="$route.name === ROUTES.CompetitiveDetails && !isCoverage"
        theme="ci"
      >
        <SwitchInput
          v-model="confidenceIntervals"
          label="View Confidence Intervals"
          tooltip="Show/hide confidence intervals for metrics, where applicable."
          theme="ci"
        />
      </FieldGroup>
      <template v-if="$route.name === ROUTES.CompetitiveDetails">
        <menu-title
          label="Metrics"
          theme="ci"
        />
        <FieldGroup theme="ci">
          <list-dropdown
            :items="listDropdownItems"
            theme="ci"
            @selected="metric => update({ metric })"
          />
        </FieldGroup>
        <template v-if="displayDetailUserGroups">
          <menu-title
            label="User Groups"
            theme="ci"
            tooltip
            @click="displayUserGroupModal"
          />

          <FieldGroup theme="ci">
            <list-dropdown
              :items="userGroupOptions"
              theme="ci"
              @selected="selectUserGroup"
            />
          </FieldGroup>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { max } from 'date-fns'
import get from 'lodash.get'
import mp from 'mixpanel-browser'
import { mapGetters, mapActions } from 'vuex'
import DashboardSwitch from './DashboardSwitch'
import UserGroupInfoModal from '@/components/competitive/UserGroupInfoModal'
import LocationSearch from '@/components/location-search/LocationSearch'
import {
  Flatpickr,
  FieldGroup,
  MenuTitle,
  ListDropdown,
  NetworksMenu,
  SelectInput,
  SwitchInput,
  MenuToggle
} from '@/components/menu'
import QuestionMark from '@/components/tooltip/QuestionMark'
import {
  AGGREGATIONS,
  COMPARE_TO,
  METRIC_TYPES,
  METRIC_TYPE_OPTIONS,
  LATEST_STRING,
  USER_GROUPS,
  DEFAULT_USER_GROUP,
  UNSUPPORTED_TYPES_FOR_GROUPS
} from '@/constants/constants'
import ROUTES from '@/constants/routes'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'
import {
  menuListItems,
  geocodingListItems
} from '@/utils/menu'
import { getUserDefaultMetricType, getUserDefaultUserGroup } from '@/utils/metrics'

export default {
  name: 'CompetitiveMenu',
  components: {
    Flatpickr,
    DashboardSwitch,
    FieldGroup,
    MenuTitle,
    LocationSearch,
    ListDropdown,
    NetworksMenu,
    SelectInput,
    SwitchInput,
    MenuToggle,
    QuestionMark,
    UserGroupInfoModal
  },
  props: {},
  data () {
    return {
      aggregationTooltipMessage: TOOLTIP_MESSAGES.aggregation,
      compareToOptions: COMPARE_TO,
      compareToTooltipMessage: TOOLTIP_MESSAGES.compareTo,
      technologyTooltipMessage: TOOLTIP_MESSAGES.technology,
      datePickerTooltipMessage: TOOLTIP_MESSAGES.datePicker,
      setCategory: null,
      options: {
        dashboard: 'competitive'
      },
      ROUTES: ROUTES,
      defaultMetricTypeOptions: METRIC_TYPE_OPTIONS,
      displayModal: false
    }
  },
  computed: {
    ...mapGetters({
      mainMetric: 'metrics/primaryMetric',
      category: 'metrics/category',
      products: 'auth/getProducts',
      country: 'location/currentCountry',
      location: 'location/currentLocation',
      userGeocodings: 'location/groupings',
      metricsGroupedBySubtypes: 'metrics/subtypes',
      citiesAsPolygons: 'competitive/showPolygons',
      confidenceState: 'dashboard/getConfidenceState',
      minDateOrganization: 'charts/organizationStartDate',
      defaultMetricType: 'competitive/defaultMetricType',
      selected: 'charts/selectedNetworkOperators',
      networkOperators: 'charts/networks',
      isCoverage: 'competitive/isCoverage',
      isMenuVisible: 'dashboard/isMenuVisible',
      isMobile: 'page/isMobile',
      userGroup: 'competitive/userGroup'
    }),
    ...mapGetters([
      'metrics',
      'dashboardInfo',
      'ciConfig',
      'locations',
      'polygons',
      'user'
    ]),
    viewPolygons: {
      get () {
        return this.citiesAsPolygons
      },
      set (areShownAsPolygons) {
        this.toggleShowPolygons(areShownAsPolygons)
      }
    },
    singleOperatorMode: {
      get () {
        return router.currentRoute.value.params.network !== 'all'
      },
      set (isInSingleOperatorMode) {
        this.$router.push({
          name: router.currentRoute.value.name,
          params: {
            ...router.currentRoute.value.params,
            network: isInSingleOperatorMode ? `${this.selected[0].canonical_network_id}` : 'all'
          }
        })
      }
    },
    confidenceIntervals: {
      get () {
        return this.confidenceState
      },
      set (value) {
        this.toggleConfidence()
      }
    },
    metricUserGroup () {
      return this.mainMetric.subcategory
    },
    userGroupOptions () {
      const isDetailsPage = router.currentRoute.value.name === ROUTES.CompetitiveDetails
      const metricType = isDetailsPage
        ? this.mainMetric.type : this.defaultMetricType
      const allMetricVariants = isDetailsPage
        ? this.metrics.filter(m => m.subtype === this.mainMetric.subtype)
        : this.metrics

      const isGroupAvailable = group => allMetricVariants.some(m => m.subcategory === group.value)

      return USER_GROUPS
        .filter(group => group.supportedTypes.includes(metricType))
        .filter(isGroupAvailable)
        .map(group => {
          return {
            ...group,
            active: group.value === this.metricUserGroup
          }
        })
    },
    listDropdownItems () {
      // coverage is a country only metric, we hide it for any other geocoding
      let metricsGroupedBySubtypes = Object.assign({}, this.metricsGroupedBySubtypes)
      if (this.ciConfig.location !== this.ciConfig.countryid) {
        delete metricsGroupedBySubtypes['coverageexperience']
      }

      Object.keys(metricsGroupedBySubtypes).forEach(groupKey => {
        metricsGroupedBySubtypes[groupKey] = metricsGroupedBySubtypes[groupKey].filter(metric =>
          metric.subcategory === this.metricUserGroup)

        if (metricsGroupedBySubtypes[groupKey].length === 0) {
          delete metricsGroupedBySubtypes[groupKey]
        }
      })

      return menuListItems(metricsGroupedBySubtypes, this.mainMetric)
    },
    geocodingsConfig () {
      return geocodingListItems(this.polygons, this.ciConfig.geocoding)
    },
    parsedFirstDateAvailable () {
      const HARD_FIRST_DATE_AVAILABLE = '2018-05-31T00:00:00.000Z'
      const firstDateAvailable = this.dashboardInfo.first_date_available || HARD_FIRST_DATE_AVAILABLE

      return max([new Date(HARD_FIRST_DATE_AVAILABLE), new Date(firstDateAvailable)])
    },
    parsedLastDateAvailable () {
      const lastDateAvalailable = this.dashboardInfo.last_date_available
      return lastDateAvalailable && new Date(lastDateAvalailable)
    },
    parsedCurrentDate () {
      if (router.currentRoute.value.params.date === LATEST_STRING) {
        return new Date(this.dashboardInfo.last_date_available)
      }
      return new Date(router.currentRoute.value.params.date)
    },
    aggregation: {
      get () {
        return router.currentRoute.value.params.agg
      },
      set (agg) {
        this.update({ agg })
      }
    },
    compareTo: {
      get () {
        return router.currentRoute.value.params.compareTo
      },
      set (compareTo) {
        this.update({ compareTo })
      }
    },
    selectedDefaultMetricType: {
      get () {
        return this.defaultMetricType
      },
      set (type) {
        this.setDefaultMetricType(type)
        this.setUserDefaultMetricType(type)
      }
    },
    aggregationOptions () {
      return AGGREGATIONS
    },
    isAggregationDisabled () {
      return this.aggregationOptions.length < 2
    },
    getDefaultMetricTypeOptions () {
      const metricsHasTech = type => this.metrics.some(m => m.type === type)

      return this.defaultMetricTypeOptions.filter(tech => metricsHasTech(tech.value))
    },
    displayDetailUserGroups () {
      return !UNSUPPORTED_TYPES_FOR_GROUPS.includes(this.mainMetric.kind) &&
      this.defaultMetricType !== METRIC_TYPES.Mmwave && this.userGroupOptions.length > 0
    },
    displayOverviewUserGroups () {
      return this.defaultMetricType !== METRIC_TYPES.Mmwave
    }
  },
  created () {
    const type = getUserDefaultMetricType()
    const allTypeOptions = this.getDefaultMetricTypeOptions.map(option => option.value)

    if (type && allTypeOptions.includes(type)) {
      this.selectedDefaultMetricType = type
    } else {
      this.selectedDefaultMetricType = 'overall'
    }
    // select user group
    this.setUserGroup(getUserDefaultUserGroup() || DEFAULT_USER_GROUP)
  },
  methods: {
    ...mapActions([
      'navigateToDashboard',
      'resetUser',
      'setRouteParam',
      'setAsCurrentLocation',
      'toggleShowPolygons',
      'setDefaultMetricType',
      'setUserDefaultMetricType',
      'setUserGroup'
    ]),
    ...mapActions({
      toggleConfidence: 'dashboard/toggleConfidenceState',
      toggleMenu: 'dashboard/toggleMenu'
    }),
    selectUserGroup (userGroup) {
      const metric = this.metrics.find(m => m.subcategory === userGroup &&
        m.subtype === this.mainMetric.subtype &&
        m.type === this.mainMetric.type
      )

      if (!metric) {
        return
      }

      this.update({ metric: metric.key })
    },
    selectLayer (type) {
      this.selectedDefaultMetricType = type
      mp.track('ci overview layer change', {
        layer: type
      })
      this.setUserGroup(DEFAULT_USER_GROUP)

      if (this.isMobile) {
        this.toggleMenu()
      }
    },
    setSelectedUserGroup (group) {
      this.setUserGroup(group)

      if (this.isMobile) {
        this.toggleMenu()
      }
    },
    signOut () {
      this.resetUser()
      this.$router.replace({
        name: ROUTES.Login
      })
    },
    active (name) {
      return router.currentRoute.value.name === name ? 'CompetitiveMenu__nav-item--active' : ''
    },
    choseNewDate (date) {
      this.update({ date })
    },
    toOverview () {
      this.$router.push({
        name: ROUTES.CompetitiveOverview,
        params: {
          location: router.currentRoute.value.params.location,
          compareTo: this.ciConfig.compareTo
        }
      })
    },
    getGeocoding (location) {
      return get(location, 'granularityId') === '1' && this.userGeocodings[0] ? `${this.userGeocodings[0].id}` : get(location, 'granularityId')
    },
    update ({
      metric = this.ciConfig.metric,
      location = this.ciConfig.location,
      agg = this.ciConfig.agg,
      compareTo = this.ciConfig.compareTo,
      date = this.ciConfig.date,
      geocoding = this.ciConfig.geocoding,
      geocodingOverwrite = false,
      operatorModeOverwrite = false
    }) {
      const locationId = this.locations.find(l => l.key === location)
      const country = this.locations.find(l => locationId && locationId.iso3 === l.iso3 && l.granularityId === '1')
      const network = operatorModeOverwrite ? 'all' : get(locationId, 'iso3') !== this.ciConfig.country ? 'all' : this.ciConfig.network

      if (locationId) {
        this.setRouteParam({
          location: locationId.key,
          compareTo,
          network,
          country: locationId.iso3,
          countryid: country.key,
          geocoding: this.getGeocoding(locationId)
        })
      }

      const isCoverage = metric.includes('coverage')
      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          ...router.currentRoute.value.params,
          location: locationId.key,
          agg: isCoverage ? '90days' : agg,
          compareTo,
          date,
          metric,
          country: get(locationId, 'iso3'),
          countryid: get(country, 'key'),
          geocoding: geocodingOverwrite ? this.getGeocoding(locationId) : geocoding + '',
          network
        }
      })

      if (this.isMobile) {
        this.toggleMenu()
      }
    },
    displayUserGroupModal () {
      this.displayModal = true
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.CompetitiveMenu {
  float: left;
  background-color: $ci-dark-blue;
  min-height: 100%;
  width: 100%;
  padding: $sidebar-padding $sidebar-padding ($sidebar-padding + 70px);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: $ci-breakpoint-tablet) {
    height: 100vh;
    padding-bottom: $sidebar-padding;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    .MenuToggle {
      opacity: 1;
    }
  }

  &--collapsed {
    .DashboardSwitch,
    .CompetitiveMenu__nav-group,
    .CompetitiveMenu__content {
      opacity: 0;
    }
  }

  &__content {
    @media screen and (max-width: $ci-breakpoint-tablet) {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__nav-group {
    width: 100%;
    background: $color-white;
    border-radius: 3px;
    margin-top: 7px;
  }

  &__nav-item {
    float: left;
    width: 33.33%;
    height: 50px;

    & a {
      color: $color-blue-primary;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 12px;
      text-align: center;
    }

    &--active {
      background: #A389D4;
      border-radius: 3px;

      & a {
        color: $color-white;
      }

    }
  }

  &__navItem--back {
    width: 100%;
    background: $winner-gold;
    text-align: center;
    border-radius: $border-radius-small;
    padding: 5px 0;
    cursor: pointer;
  }

  &__nav-icon {
    font-size: 18px;
    padding: 7px 0 0;
  }

  &__nav-group:after {
    display: block;
    content: '';
    clear: both;
  }

  .CompetitiveMenu__nav-group,
  .CompetitiveMenu__content {
    transition: $sidebar-transition;
  }
}

.ParametersLayout {
  @include xy-grid-container($padding: 0);

  &__grid {
      @include xy-grid($direction: horizontal);
      justify-content: space-between;
  }

  &__geographies {
      @include xy-cell($gutters: 0)
  }

  &__end-date {
      @include xy-cell($gutters: 0);
  }

  &__aggregation {
      @include xy-cell($gutters: 0)
  }
}

.group-tooltip {
  margin: 7px 0 0 5px;
}
</style>
