<template>
  <div class="empty">
    <div class="empty__icon">
      <FontAwesomeIcon
        :icon="icons.faChartBar"
        size="4x"
      />
    </div>
    <div>No data</div>
  </div>
</template>

<script>
import { faChartBar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'NoData',
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      icons: {
        faChartBar
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  padding: .5em;
  text-align: center;
  &__icon {
    opacity: .1;
  }
}
</style>
