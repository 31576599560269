<template>
  <div
    v-if="areasData.length"
    class="MetricAreaListFull"
  >
    <div
      v-for="area in areasData"
      :key="area.index"
      class="MetricAreaListFull__area"
      @click="selectLocation(area)"
    >
      <div class="MetricAreaListFull__bodyTitle">
        {{ area.properties.name }}
      </div>

      <RankingTable
        :bigger-better="metric.bigger_is_better"
        :ranks="area.item"
        :min-and-max="minAndMax(area)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { metricMinAndMax } from '../visual/map/helpers'
import RankingTable from './RankingTable'

export default {
  name: 'MetricAreaListFull',
  components: {
    RankingTable
  },
  props: {
    areasData: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      metric: 'metrics/primaryMetric'
    })
  },
  methods: {
    selectLocation (area) {
      this.$emit('selectLocation', area.properties.id)
    },
    minAndMax (area) {
      return metricMinAndMax(area.item, this.metric)
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';

  .MetricAreaListFull {
    &__area {
      cursor: pointer;
    }
    &__bodyTitle {
      font-size: $font-size-16;
      font-weight: $font-weight-roboto-medium;
      font-style: italic;
      text-align: center;
      padding: 10px 0;
    }

    &__area:first-child .MetricAreaListFull__bodyTitle {
      padding-top: 4px;
    }
  }
</style>
