<template>
  <div class="AccordionGroup">
    <div
      v-for="option in enhancedOptions"
      :key="option.key"
      class="AccordionGroup__option-wrapper"
    >
      <div
        :class="{
          'AccordionGroup__option--disabled': option.disabled,
          'AccordionGroup__option--selected': option.selected && !active || disabled,
          'AccordionGroup__option--active': option.selected && active && !disabled,
          'AccordionGroup__option--highlighted': option.highlighted
        }"
        class="AccordionGroup__option"
        @click="$emit('update:modelValue', option.key)"
      >
        {{ option.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionGroup',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [String, Array],
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {}
  },
  computed: {
    disabled () {
      return this.options.length < 2
    },
    enhancedOptions: function () {
      const options = this.options
      const value = this.modelValue
      return options && options.map((option) => ({
        ...option,
        selected: this.multiple ? value.includes(option.key) : option.key === value
      }))
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.AccordionGroup {
  $default-space: 1px;
  display: flex;
  color: $color-blue-primary;
  font-size: $control-font-size;
  overflow: hidden;

  &__option-wrapper {
    margin-top: $default-space;
  }

  &__option {
    cursor: pointer;
    margin-right: 3px;
    min-width: 4rem;
    border-radius: $control-border-radius;
    background-color: $color-white-background;
    border: 2px solid transparent;
    height: $control-height;
    line-height: $control-height;
    padding: 0 $control-horizontal-padding;
    box-sizing: border-box;
    display: inline-flex;
    width: 100%;
    align-items: center;

    &--selected.AccordionGroup__option {
      background-color: $color-background-grey;
      color: $color-white;
    }

    &--active.AccordionGroup__option {
      @extend %active;
    }
    &--disabled {
      pointer-events:none;
      cursor: default;
      opacity: .5;
      user-select: none;
    }
    &--highlighted {
      background-color: $color-white-background;
      border-color: $color-brand-blue;
    }

    &:hover {
      background-color: $control-dark-bg-color--hover;
      color: $control-dark-text-color--hover;
    }
  }
}
</style>
