const cache = {};

export default function create (namespace, initial = []) {
  if (!namespace) {
    throw new TypeError('No namespace provided for the array-store 💣')
  }

  cache[namespace] = {};
  const types = {
    SET: `${namespace}:SET`,
    SET_KEY: `${namespace}:SET_KEY`,
    SET_CURRENT: `${namespace}:SET_CURRENT`,
    ADD: `${namespace}:ADD`,
    REPLACE: `${namespace}:REPLACE`
  }

  const state = () => ({
    array: initial,
    current: null,
    key: 'key'
  })

  const mutations = {
    [types.SET] (state, array) {
      state.array = [...array]
    },
    [types.SET_KEY] (state, key) {
      state.key = key
    },
    [types.SET_CURRENT] (state, current) {
      state.current = current
    },
    [types.ADD] (state, array) {
      state.array = state.array.concat(array)
    },
    [types.REPLACE] (state, [index, item]) {
      state.array.splice(index, 1, item)
    }
  }

  const getters = {
    [namespace]: state => state.array,
    [`${namespace}/current`]: state => cache[state.current] || state.array.find(i => {
      if (i[state.key] === state.current) {
          cache[state.current] = i
          return true
      }

      return false
    }) || {},
    /* DO NOT USE ME - Deprecated */
    [`${namespace}Current`]: (state, getters) => getters[`${namespace}/current`]
  }

  return {
    types,
    state,
    mutations,
    getters
  }
}
