import colors from '@/utils/colorPalette'

export const SCALE_TITLE_STYLE = {
  color: colors.colorBlueHeader,
  font: { weight: 'bold' }
}

export const Y_AXIS_BASE_STYLE = {
  grid: {
    color: colors.chartGuidelineInnerColor,
    drawTicks: false,
    offset: true,
    borderDashOffset: 20
  },
  ticks: {
    fontColor: colors.colorBlueHeader,
    padding: 10
  }
}

export const X_AXIS_BASE_STYLE = {
  grid: {
    color: colors.chartGuidelineInnerColor,
    drawTicks: false,
    offset: true
  },
  ticks: {
    color: colors.colorBlueHeader,
    padding: 10
  }
}

export const LABEL_OVERRIDES = {
  'Low': 'Low <1 Ghz',
  'Mid': 'Mid 1-3 Ghz',
  'High': 'High 3-6 Ghz'
}
