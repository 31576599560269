<template>
  <div class="MarketGrowthDetailsLayout">
    <div class="MarketGrowthDetailsLayout__table">
      <slot name="list" />
    </div>

    <div class="MarketGrowthDetailsLayout__map">
      <slot name="map" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketGrowthDetailsLayout'
}
</script>

<style scoped lang="scss">
@import "scss/variables";
@import "~foundation-sites/scss/foundation";

.MarketGrowthDetailsLayout {
  @include xy-grid(horizontal);
  height: 100%;

  &__table {
    flex: 4 0 230px;
    height: 100%;
    overflow: hidden;
  }

  &__map {
    flex: 5;
    position: relative;
  }
}

</style>
