<template>
  <div
    v-if="store"
    class="MGMapTooltip"
  >
    <div class="MGMapTooltip__header">
      <h2>
        {{ storeName }}
      </h2>
      <span>
        {{ store.store_type }}

        <FontAwesomeIcon
          :icon="storeIcon"
          size="sm"
        />
      </span>
    </div>
    <br>
    <p class="MGMapTooltip__body">
      <span>
        {{ store.address }}
      </span>
      <br>
      <span>
        {{ store.postal_code }}, {{ store.city }}
      </span>
    </p>
  </div>
</template>

<script>
import { faStore, faStoreAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import { getNetworksFromBrands } from '@/utils/market-growth'

export default {
  name: 'MapTooltip',
  components: {
    FontAwesomeIcon
  },
  props: {
    store: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        faStore,
        faStoreAlt
      }
      //
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      networks: 'operators',
      metric: 'metrics/primaryMetricKey'
    }),
    allNetworks () {
      return getNetworksFromBrands(this.dashboardInfo.brands)
    },
    storeName () {
      if (!this.store) {
        return ''
      }
      const network = this.allNetworks.find(network => network.canonical_network_id === this.store.canonical_network_id)
      if (!network) {
        return ''
      }
      return network.name_mapped
    },
    storeIcon () {
      if (!this.store) {
        return
      }
      if (this.store.store_type === 'Destination') {
        return this.icons.faStore
      }
      return this.icons.faStoreAlt
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MGMapTooltip {
  background: $color-white;
  padding: 20px;
  color: $mg-text-color;
  width: 400px;
  pointer-events: none;

  &__header {
    font-size: $font-size-18;
    h2 {
      margin: 0;
    }
    span {
    font-size: $font-size-16;
    }
  }
   &__body {
    margin-top: 20px;
    font-size: $font-size-14;
  }

}
</style>
