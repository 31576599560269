<template>
  <button
    :class="elClass"
    class="CustomButton"
    @click="e => $emit('click', e)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    transparent: { type: Boolean, default: false }
  },
  computed: {
    elClass: function () {
      const { transparent } = this
      return {
        'CustomButton--transparent': transparent
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .CustomButton {
    background-color: $color-electric-blue;
    border-radius: pxToRem(4);
    border: $control-border;
    color: $color-white;
    cursor: pointer;
    font-size: $font-size-12;
    height: $control-height;
    padding: 0 $padding-medium;
    position: relative;
    &:hover {
      background-color: $color-blue-header;
    }
    &:focus {
      outline: none;
    }
    &--transparent {
      background-color: transparent;
      color: $color-brand-blue;
      border: 1px solid $color-brand-blue;

      &:hover {
        color: $color-white;
        background-color: $color-brand-blue;
      }
    }
  }
</style>
