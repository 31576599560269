import toPath from 'lodash.topath'
import Vue from 'vue'

/**
 * Creates a store module that contains an object structured data
 * State has these properties:
 * {
 *   data: {},    // The object
 * }
 *
 * Mutations:
 * SET            // Sets the object
 * SET_KEY        // Sets the specified key
 */
export default function create (namespace, value = {}) {
  if (!namespace) {
    throw new TypeError('No namespace provided for the tree-store 💣')
  }

  const types = {
    SET: `${namespace}:SET`,
    SET_KEY: `${namespace}:SET_KEY`
  }

  const state = () => ({
    data: value
  })

  const mutations = {
    [types.SET] (state, data) {
      state.data = data
    },
    [types.SET_KEY] (state, { key, data }) {
      // Can't use _.set because we need to make new objects/arrays reactive
      // Instead iterate through key path and use Vue.set
      const keyPath = toPath(key)
      const lastIx = keyPath.length - 1
      const isNum = n => /^[0-9]+$/.test(n)

      keyPath.reduce((object, key, index) => {
        if (index < lastIx) {
          // Go through all keys and create objects for undefined values.
          // Convert primitive values to objects (e.g. number -> new Number)
          const curVal = object[key]
          if ((typeof curVal !== 'object') || (curVal === null)) {
            // If the next key is a number, create an empty array at this key
            const newVal = isNum(keyPath[index + 1]) ? [] : Object(curVal)
            object[key] = newVal
          }

          // Pass created/existing object to next iteration
          return object[key]
        } else {
          // At the last index just set the value
          object[key] = data
        }
      },
      state.data)
    }
  }

  const getters = {
    [namespace]: state => state.data
  }

  return {
    types,
    state,
    mutations,
    getters
  }
}
