<template>
  <div
    class="ChartWrapper"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
  >
    <template v-if="!loading">
      <performance-title
        v-if="titleLabels && !disableTitle"
        :geography="titleLabels.geography"
        :metric-unit="unit"
        :full-title="chartTitle"
        :chart-modifier="title"
        :tooltip="tooltip"
        :date="chartTooltip && chartTooltip.date"
        transparent
      />

      <div
        v-if="displayFlatTooltip"
        class="ChartWrapper__tooltip"
      >
        <flat-table-tooltip
          :pos-class="chartTooltip.posClass"
          :datapoints="chartTooltip.datapoints"
          :display="true || (Boolean(chartTooltip && Object.keys(chartTooltip).length))"
        />
      </div>

      <NoData v-if="empty" />
      <div
        v-else
        ref="chartwrapper"
        class="ChartWrapper__chart"
      >
        <slot />
        <div
          :class="{'ChartWrapper__tools--bottom-right': smallLayout}"
          class="ChartWrapper__tools"
        >
          <div
            v-if="displayFrm"
            class="ChartWrapper__tool ChartWrapper__tool--frm"
          >
            <tooltip-wrapper title="Toggle faster response indicators">
              <div
                :class="{'ChartWrapper__toggle--active': frmActive}"
                class="ChartWrapper__toggle"
                @click="toggleFRM"
              >
                <FontAwesomeIcon
                  v-show="!frmActive"
                  :icon="icons.faChartLine"
                  size="1x"
                />
                <FontAwesomeIcon
                  v-show="frmActive"
                  :icon="icons.faTimes"
                  size="1x"
                />
              </div>
            </tooltip-wrapper>

            <FRMCard
              v-if="frmActive && !loading"
              :chart-data="chartData"
            />
          </div>
          <div
            v-if="toggleLayer"
            class="ChartWrapper__tool"
          >
            <tooltip-wrapper title="Toggle group view">
              <div
                class="ChartWrapper__toggle"
                @click="$emit('toggle')"
              >
                <FontAwesomeIcon
                  :icon="icons.faLayerGroup"
                  size="1x"
                />
              </div>
            </tooltip-wrapper>
          </div>
          <div
            v-if="$slots.scale"
            class="ChartWrapper__tool"
          >
            <slot name="scale" />
          </div>
          <div
            v-if="toggleNumbers && showConfidenceRate"
            class="ChartWrapper__tool"
          >
            <tooltip-wrapper title="Toggle values">
              <div
                class="ChartWrapper__numbers"
                @click="$emit('numbers')"
              >
                <FontAwesomeIcon
                  :icon="icons.faRuler"
                  size="1x"
                />
              </div>
            </tooltip-wrapper>
          </div>
          <div
            v-if="enableCompare"
            class="ChartWrapper__tool"
          >
            <tooltip-wrapper title="vs. National Trends">
              <div
                class="ChartWrapper__zoom"
                @click="$emit('compare')"
              >
                <FontAwesomeIcon
                  :icon="icons.faGripLines"
                  size="1x"
                />
              </div>
            </tooltip-wrapper>
          </div>
          <div
            v-if="!disableExport"
            class="ChartWrapper__tool"
          >
            <tooltip-wrapper title="Export chart as image">
              <div class="ChartWrapper__image">
                <image-export
                  :content="content"
                  :title="exportFileName"
                  :canvas-container-node="canvasContainer"
                  :product="pictureMeta.product"
                  :selected-point="selectedPoint"
                  :confidence-state="showConfidenceRate"
                  :legend-disabled="legendDisabled"
                  :legend-title="legendTitle"
                />
              </div>
            </tooltip-wrapper>
          </div>
          <div
            v-if="enableCSVExport"
            class="ChartWrapper__tool"
          >
            <CsvExport @click="exportCSVData" />
          </div>
          <div
            v-if="enableYZoom"
            class="ChartWrapper__tool"
          >
            <tooltip-wrapper
              :title="'Adaptive y axis scale'"
            >
              <div
                class="ChartWrapper__yZoom"
                @click="$emit('toggleYZoom')"
              >
                <FontAwesomeIcon
                  v-if="!yZoom"
                  :icon="icons.faSearchPlus"
                  size="1x"
                />
                <FontAwesomeIcon
                  v-if="yZoom"
                  :icon="icons.faSearchMinus"
                  size="1x"
                />
              </div>
            </tooltip-wrapper>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="ChartWrapper__placeholder"
    >
      <loader-grid size="large" />
    </div>
  </div>
</template>

<script>
import {
  faLayerGroup,
  faRuler,
  faSearchPlus,
  faSearchMinus,
  faGripLines,
  faChartLine,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { format, min, max, eachDayOfInterval } from 'date-fns'
import get from 'lodash.get'
import mp from 'mixpanel-browser'
import CsvExport from '@/components/CsvExport'
import ImageExport from '@/components/ImageExport'
import LoaderGrid from '@/components/LoaderGrid'
import NoData from '@/components/NoData'
import FRMCard from '@/components/performance/FRMCard'
import PerformanceTitle from '@/components/performance/PerformanceTitle'
import FlatTableTooltip from '@/components/visual/chart/FlatTableTooltip'
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper'
import { API_DEFAULT_DATE_FORMAT, PROGRAMMATIC_FORMAT } from '@/constants/dateFormats'
import { getSafeDate } from '@/utils/date'
import { exportToCsv } from '@/utils/files'

export default {
  name: 'ChartWrapper',
  components: {
    TooltipWrapper,
    LoaderGrid,
    FontAwesomeIcon,
    ImageExport,
    PerformanceTitle,
    NoData,
    FRMCard,
    FlatTableTooltip,
    CsvExport
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    toggleLayer: {
      type: Boolean,
      default: false
    },
    toggleNumbers: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: undefined
    },
    pictureMeta: {
      type: Object,
      default: () => {}
    },
    titleLabels: {
      type: Object,
      default: () => {}
    },
    tooltip: {
      type: String,
      default: undefined
    },
    disableExport: {
      type: Boolean,
      default: false
    },
    legendDisabled: {
      type: Boolean,
      default: false
    },
    legendTitle: {
      type: String,
      default: undefined
    },
    disableTitle: {
      type: Boolean,
      default: false
    },
    smallLayout: {
      type: Boolean,
      default: false
    },
    showConfidenceRate: {
      type: Boolean,
      default: false
    },
    selectedPoint: {
      type: Array,
      default: () => []
    },
    enableCompare: {
      type: Boolean,
      default: false
    },
    enableYZoom: {
      type: Boolean,
      default: false
    },
    yZoom: {
      type: Boolean,
      default: false
    },
    displayFrm: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    chartTooltip: {
      type: Object,
      default: () => ({})
    },
    displayFlatTooltip: {
      type: Boolean,
      default: false
    },
    percentile: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      icons: {
        faTimes,
        faChartLine,
        faLayerGroup,
        faRuler,
        faSearchPlus,
        faSearchMinus,
        faGripLines: {
          ...faGripLines,
          prefix: 'fad'
        }
      },
      frmActive: false,
      canvasContainer: null
    }
  },

  computed: {
    enableCSVExport () {
      return this.chartData && this.chartData.data && this.chartData.data.length > 0
    },
    exportFileName () {
      if (!this.pictureMeta) return this.title

      return `${(get(this.pictureMeta, ['chartTitle', 'fullTitle']) || this.pictureMeta.fullTitle).trim()} ${this.title}-${this.pictureMeta.geography}-${this.pictureMeta.endDate}`
    },
    chartTitle () {
      return this.titleLabels.chartTitle ? this.titleLabels.chartTitle.fullTitle : this.titleLabels.fullTitle
    },
    content () {
      if (!this.pictureMeta || !this.titleLabels) return []

      const unit = this.unit ? ` | in ${this.unit}` : ''
      const optional = this.percentile ? ` up to ${this.percentile}th percentile` : ''
      const updated = this.titleLabels.lastUpdated === 0 ? 'yesterday' : `${this.titleLabels.lastUpdated} days ago`
      const title = this.pictureMeta.product !== 'performance' ? this.title : `${this.chartTitle} | ${this.title}`
      return [
        title,
        `${this.pictureMeta.geography}${unit}${optional}`,
        `${this.titleLabels.startDate} - ${this.titleLabels.endDate} | ${this.titleLabels.aggregation} | Data set updated ${updated}`
      ]
    }
  },
  watch: {
    loading () {
      // Prevent chart rendering issues
      this.frmActive = false
    }
  },
  mounted () {
    this.canvasContainer = this.$refs.chartwrapper
  },
  updated () {
    this.canvasContainer = this.$refs.chartwrapper
  },
  methods: {
    toggleFRM () {
      this.frmActive = !this.frmActive
      if (!this.titleLabels) {
        return
      }
      const chartTitle = this.titleLabels.chartTitle || this.titleLabels
      mp.track('pi details frm toggle', {
        active: this.frmActive,
        geography: chartTitle.geography,
        metricType: chartTitle.metricType,
        metricName: chartTitle.metricName,
        fullTitle: chartTitle.fullTitle
      })
    },
    exportCSVData () {
      const networks = this.chartData.data
      const dates = []
      networks.forEach(network => dates.push(...network.data.map(i => i.x)))

      const dataByNetworkByDate = {}
      networks.forEach(network => {
        dataByNetworkByDate[network.label] = {}
        network.data.forEach(item => {
          dataByNetworkByDate[network.label][format(getSafeDate(item.x), PROGRAMMATIC_FORMAT)] = item
        })
      })

      const formattedData = []
      const safeDates = dates.map(date => getSafeDate(date))
      const minDate = min([...safeDates])
      const maxDate = max([...safeDates])
      const allDates = eachDayOfInterval({ start: minDate, end: maxDate })

      const parseValue = (value) => {
        if (value === 0) {
          return 0
        }
        return value || ''
      }
      allDates.forEach((unsafeDate, i) => {
        const date = format(getSafeDate(unsafeDate), PROGRAMMATIC_FORMAT)
        formattedData[i] = {
          Date: format(unsafeDate, API_DEFAULT_DATE_FORMAT)
        }
        networks.forEach(network => {
          const itemData = dataByNetworkByDate[network.label][date]
          formattedData[i][`${network.label} mean`] = itemData ? parseValue(itemData.y) : ''
          formattedData[i][`${network.label} uci`] = itemData ? parseValue(itemData.uci) : ''
          formattedData[i][`${network.label} lci`] = itemData ? parseValue(itemData.lci) : ''
        })

        if (this.chartData.cdn) {
          formattedData[i][`CDN`] = this.chartData.cdn
        }
        if (this.chartData.operator) {
          formattedData[i][`Operator`] = this.chartData.operator
        }
        if (this.chartData.type && this.chartData.agg) {
          formattedData[i][`Technology`] = this.chartData.type
          formattedData[i][`Aggregation`] = this.chartData.agg
        }
        if (this.chartData.resolution) {
          formattedData[i][`Resolution`] = this.chartData.resolution
        }
      })

      this.exportFormattedData(formattedData)
    },
    exportFormattedData (formattedData) {
      if (!formattedData || formattedData.length === 0) {
        return
      }
      const titles = Object.keys(formattedData[0])
      const exportData = formattedData.map(data => titles.map(key => data[key]))
      const chartTitle = this.titleLabels.chartTitle || this.titleLabels
      const fullTitle = chartTitle.fullTitle.trim()
      const csvTitle = `${fullTitle} ${this.title} ${chartTitle.geography} ${chartTitle.aggregation} ${this.titleLabels.endDate}.csv`
      exportToCsv(csvTitle, [titles, ...exportData])
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.ChartWrapper {
  height: 100%;
  padding: 1.5em;
  position: relative;
  background-color: $color-white;
  margin-bottom: 0.6em;
  border-radius: $control-border-radius;

  &__title {
    padding: .5em;
    margin-right: 2.5em;
    font-size: $font-size-18;
    font-weight: $font-weight-roboto-bold;
    color: $color-brand-blue;
  }

  &__tooltip {
    min-height: 30px;
    padding-bottom: 10px;
  }

  &__tools {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    text-align: center;
    z-index: $z-index-chart;
    @include xy-grid(horizontal, $wrap: false);
    display: none;

    &--bottom-right {
      top: 0.3rem;
      right: 0.3rem;
    }
  }

  &:hover &__tools {
    display: flex;
  }

  &__tool {
    @include xy-cell($size: shrink, $gutter-type: padding, $gutter-position: left, $gutters: 8px);

    &--frm {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  &__toggle,
  &__numbers,
  &__zoom,
  &__yZoom {
    @extend %boxIcon;
    position: relative;
    color: $color-blue-header;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $color-white;
      background-color: $color-blue-header;
    }
  }
  &__toggle--active {
    color: $color-white;
    background-color: $color-blue-header;
    &:hover {
      color: $color-blue-header;
      background-color: $color-white;
    }
  }

  &__empty {
    padding: .5em;
    text-align: center;
  }

  &__icon {
    opacity: .1;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    background-color: rgb(222, 229, 236);
    color: $color-white;
    font-size: 10em;
    padding: 37.5px 0;
    text-align: center;

    svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &__chart {
    height: 100%;
  }
}
</style>
