<template>
  <div
    class="FlatTableItem"
    @click="$emit('click')"
  >
    <span class="FlatTableItem__OperatorAvatar">
      <OperatorAvatar
        v-if="operatorInitial"
        :letter="operatorInitial"
        :background-color="operatorColor"
      />
    </span>
    <span class="FlatTableItem__value">
      {{ value === null ? '-' : value }}
    </span>
  </div>
</template>

<script>
import { numberToTwoDecimals } from '../../utils/filters'
import OperatorAvatar from '../OperatorAvatar'

export default {
  name: 'FlatTableItem',
  components: {
    OperatorAvatar
  },
  filters: {
    numberToTwoDecimals
  },
  props: {
    operatorColor: { type: String, default: undefined },
    operatorInitial: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .FlatTableItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray-border-table;
    align-items: center;
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 $border-radius-medium $border-radius-medium;
    }

    &__OperatorAvatar {
      padding: 0;
    }

    &__value {
      @include xy-cell(shrink);
      font-size: $font-size-18;
      font-weight: $font-weight-roboto-light;
      padding: 0;

      &--compare {
        border-left: 1px solid $color-gray-border-table;
        padding: 0 $table-padding;
      }
    }
  }
</style>
