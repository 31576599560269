<template>
  <div>
    <div
      v-for="cpChart in chartData"
      :key="`${cpChart.chartId}-k`"
    >
      <ChartWrapper
        :title="cpChart.title"
        :tooltip="focusChartData.tooltip"
        :picture-meta="exportData"
        :selected-point="selectedPoint[cpChart.chartId]"
        :loading="chartPending"
        :empty="cpChart.empty"
        :show-confidence-rate="showConfidenceRate"
        :title-labels="titleLabels"
        :unit="chartUnit.short"
        :y-zoom="chartYZoom"
        legend-title="Cloud Providers"
        enable-y-zoom
        :display-frm="displayFrm"
        :chart-data="cpChart"
        @toggleYZoom="chartYZoom = !chartYZoom"
      >
        <line-chart
          v-if="cpChart.loaded"
          :date="date"
          :data-set="cpChart.data"
          :chart-id="cpChart.chartId"
          :x-axis-label="cpChart.xAxisLabel"
          :y-axis-label="cpChart.yAxisLabel"
          :y-axis-unit="cpChart.yAxisUnit"
          :end-date="dashboardInfo.last_date_available"
          :show-confidence-rate="showConfidenceRate"
          :sync-range="syncRange"
          :y-zoom="chartYZoom"
          show-legend
          @point="navigateDate"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LineChart, ChartWrapper } from '@/components/visual'
import { CP_LABELS } from '@/constants/constants'
import router from '@/router'
import { getLinechartRange } from '@/utils/charts'

export default {
  name: 'ChartCpOps',
  components: {
    ChartWrapper,
    LineChart
  },
  beforeRouteUpdate (to, from, next) {
    if (router.currentRoute.value.params.chart !== to.params.chart) {
      this.chartYZoom = false
    }

    next()
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false,
      chartYZoom: false,
      cpLabels: CP_LABELS
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      displayFrm: 'chartFrm/displayFrm',
      chartDataRaw: 'charts/getByOperatorCpCharts',
      chartPending: 'chart/chartPending',
      chartUnit: 'chart/unit',
      currentLocation: 'locations/current',
      focusChartData: 'charts/getCpFocusChart',
      range: 'charts/cpRange',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/cpOpsSelectedPoints'
    }),
    exportData () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    syncRange () {
      return getLinechartRange(...this.range, 30)
    },
    chartData () {
      return this.chartDataRaw.map(chart => {
        chart.data = chart.data.map(dataSet => {
          dataSet.label = this.cpLabels[dataSet.label]

          return dataSet
        })

        return chart
      })
    }
  },
  methods: {
    navigateDate (date) {
      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          hero: this.hero,
          chart: this.chart,
          location: this.location,
          agg: this.agg,
          date
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
