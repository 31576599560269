<template lang="html">
  <div
    :style="`background-color:${color}`"
    class="TooltipContent"
  >
    <p
      :style="`color:${getTextColor(color)}`"
      class="TooltipContent__line"
    >
      {{ area.name }}
    </p>
    <p
      v-if="!importance"
      :style="`color:${getTextColor(color)}`"
      class="TooltipContent__line"
    >
      {{ label }}: {{ item }}{{ unit }}
    </p>
    <p
      v-if="importance"
      :style="`color:${getTextColor(color)}`"
      class="TooltipContent__line"
    >
      {{ label }}: {{ mean }}{{ unit }}
      <br>Importance: {{ item }}%
    </p>
  </div>
</template>

<script>
import { getTextColor } from '@/utils/colors'

export default {
  name: 'TooltipContent',
  props: {
    area: {
      type: Object,
      default: () => {}
    },
    item: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    },
    label: {
      type: String,
      default: 'Value'
    },
    unit: {
      type: String,
      default: ''
    },
    mean: {
      type: String,
      default: ''
    },
    importance: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTextColor (color) {
      return getTextColor(color)
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
.TooltipContent {
  padding: 1rem;
  line-height: 1rem;
  font-size: 0.8rem;
  border-radius: 5%;
  color: $color-white;
  &__line {
    margin: 0;
    padding: 0;
  }
}
</style>
