<template>
  <VDropdown
    :distance="8"
    :triggers="['click']"
    popper-class="timeframe-popover"
  >
    <div
      class="timeframe-button"
      :class="{ 'timeframe-button--active': displayed}"
    >
      <a>
        Timeframe settings | {{ timeframeLabel }}
      </a>
    </div>

    <template #popper>
      <div class="timeframe-popover__inner">
        <div class="timeframe-popover__header">
          <accordion-group
            v-model="timeframe"
            :options="dates"
            active
          />
          <button
            v-close-popper
            class="timeframe-popover__cancel"
          >
            x
          </button>
        </div>
        <flat-pickr
          v-model="range"
          :config="config"
          class="picker-input"
        />
      </div>
    </template>
  </VDropdown>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import mp from 'mixpanel-browser'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters, mapActions } from 'vuex'
import { AccordionGroup } from '@/components/menu'
import { TIMEFRAME_DAYS_OPTIONS } from '@/constants/constants'

export default {
  name: 'TimeframeSettings',
  components: {
    flatPickr,
    AccordionGroup
  },
  data () {
    return {
      displayed: false,
      displayedOnce: false,
      dates: TIMEFRAME_DAYS_OPTIONS
    }
  },
  computed: {
    ...mapGetters({
      timeframeDays: 'competitive/timeframeDays',
      timeRange: 'competitive/timeRange',
      minDate: 'charts/organizationStartDate',
      maxDate: 'charts/organizationEndDate',
      isMobile: 'page/isMobile'
    }),
    config () {
      const vm = this
      return {
        mode: 'range',
        minDate: this.minDate,
        maxDate: this.maxDate,
        altInputClass: 'invisible',
        showMonths: this.isMobile ? 1 : 2,
        inline: true,
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length === 1) {
            return
          }
          const [start, end] = selectedDates
          if (start.getTime() === vm.range[0].getTime() && end.getTime() === vm.range[1].getTime()) {
            return
          }
          vm.setTimeRange({ start, end })

          mp.track('ci details timeframe change', {
            timePeriod: 'custom',
            start,
            end
          })
        }
      }
    },
    timeframeLabel: {
      get () {
        if (this.timeframe && this.dates) {
          const selectedTimeframe = this.dates.find(date => date.key === this.timeframe)
          return selectedTimeframe.title
        }
        return 'Custom selection'
      },
      set (days) {
        this.setTimeframe(days)
      }
    },
    timeframe: {
      get () {
        return this.timeframeDays
      },
      set (days) {
        mp.track('ci details timeframe change', {
          timePeriod: days
        })

        this.setTimeframe(days)
      }
    },
    range () {
      const { start, end } = this.timeRange
      return [start, end]
    }
  },
  methods: {
    ...mapActions([
      'setTimeframe',
      'setTimeRange'
    ]),
    displayCalendar () {
      const vm = this
      vm.$emit('show')
      // Tooltip needs to be open before rendering calendar. $nextTick didn't work
      setTimeout(() => {
        vm.displayed = true
        vm.displayedOnce = true
      }, 1)
    },
    hideCalendar () {
      this.displayed = false
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'scss/variables';
@import 'scss/components';

.timeframe-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $color-blue-header;
  background: white;
  border-radius: $border-radius-medium;
  border: 1px solid $color-blue-bg;
  cursor: pointer;
  height: $box-icon-height;
  text-align: center;
  a {
    font-size: 12px;
    padding: 0 10px;
  }
  &:hover, &--active {
    border: 1px solid white;
    background-color: $color-blue-header;
    color: white;
  }
}

::v-deep() {
  .AccordionGroup {
    flex: 1;
  }
  .AccordionGroup__option {
    background: white;
    color: $color-text-dark-blue;
    text-align: center;
    white-space: nowrap;

    &:hover {
      background-color: $color-text-gold-chart-title;
      color: white;
    }

    &--selected.AccordionGroup__option {
      background-color: $color-text-gold-chart-title;
      color: white;
    }

    &--active.AccordionGroup__option {
      background-color: $color-text-gold-chart-title;
      color: white;
    }
  }
  .AccordionGroup__option-wrapper {
    flex: 1;
    &:last-child {
      .AccordionGroup__option {
        margin-right: 0;
      }
    }
  }

  /* Calendar styles */

  $dayWidth: 32px; /*pixel square for each day - default is 39 */
  $width: $dayWidth * 7;

  .picker-input {
    display: none;
    visibility: hidden;
    width: 0;
  }
  .flatpickr-calendar {
    width: $width;
    margin-top: 5px;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .dayContainer {
    width: $width;
    min-width: $width;
    max-width: $width;
  }
  .flatpickr-days {
    width: $width;
  }
  .flatpickr-day {
    color: $color-text-dark-blue;
    max-width: $dayWidth;
    height: $dayWidth;
    line-height: $dayWidth;
    box-shadow: none !important;
  }
  .flatpickr-disabled {
    color: rgba(57,57,57,0.1);
  }
  .flatpickr-month {
    color: $color-text-dark-blue;
  }
  .flatpickr-prev-month, .flatpickr-next-month {
    color: $color-text-dark-blue;
    &:hover {
      svg {
        fill: $color-text-gold-chart-title;
      }
    }
  }
  .flatpickr-weekday {
    color: $color-text-gold-chart-title;
  }
  .selected, .startRange, .endRange {
    color: white !important;
    background: $color-text-gold-chart-title !important;
    border-color: $color-text-gold-chart-title !important;
  }
}
</style>

<style lang="scss">
// FOR POPOVER ONLY
@import 'scss/variables';

.timeframe-popover {
  display: flex;

  .timeframe-popover__inner {
      background: $ci-content-background;
      color: $color-text-dark-blue;
      padding: 5px;
      border-radius: 5px;
      max-width: 90vw;
    }

    .popover-arrow {
      border-color: $ci-content-background;
    }

    &__header {
      display: flex;
    }

    &__cancel {
      margin-left: 10px;
      border: none;
      background-color: white;
      border-radius: 0.25rem;
      color: $color-text-dark-blue;
    }
}
</style>
