import { differenceInDays, format, subDays } from 'date-fns'
import capitalize from 'lodash.capitalize'
import get from 'lodash.get'
import includes from 'lodash.includes'
import startCase from 'lodash.startcase'
import { CDNS, AGGREGATIONS } from '@/constants/constants'
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats'
import { getSafeDate } from '@/utils/date'
import { getUnit } from '@/utils/metrics'

const deviceLabel = (metric = {}) => {
  let [ base, modifier ] = metric.name ? metric.name.split(' by ') : ['', '']
  base = base.replace('4G ', '')

  return {
    base,
    modifier
  }
}

const trimTechnology = (str = {}) => str.replace('4G ', '').replace('3G ', '').replace('5G ', '').replace('mmWave ', '')
const trimBeta = (str = {}) => str.replace('BETA', '')

/*
  e.g.: ['Download Speed', 'Minimum Latency', 'Upload Peak Speed',
          'Video Experience', 'Video Loadtime'...]
 */
const metricToBaseTitle = (metric = {}) => {
  const secondaryMetric = includes(['speed', 'peak'], metric.subcategory)
  const kind = metric.kind && capitalize(metric.kind)
  const category = metric.category && capitalize(metric.category)
  if (metric.subcategory === 'stalling' || metric.kind === 'stalling') return `${category} Stalling Occurrence`
  if (metric.subcategory === 'loadtime' || metric.kind === 'loadtime') return `${category} Load Time`
  if (metric.subcategory === 'cell') return 'Cells Count'
  if (metric.subcategory === 'enodeb') return 'eNodeB Count'
  if (metric.subcategory === 'cellbanddist') return 'Cells detected per Frequency Band'
  if (metric.subcategory === 'enodebcellcountdist') return 'Cells per eNB'
  if (metric.subcategory === 'frequency_range' && metric.kind === 'download') return 'Download'
  if (metric.subcategory === 'frequency_range' && metric.kind === 'upload') return 'Upload'
  if (metric.kind === 'latency') return secondaryMetric ? `Minimum ${kind}` : `${kind}`
  if (metric.kind === 'video') return `${kind} ${capitalize(metric.subtype.replace('video', ''))}`
  if (metric.kind === 'extent') return 'Reach'
  if (metric.category === 'voice') return trimBeta(trimTechnology(metric.name))
  if (metric.category === 'games') return trimBeta(trimTechnology(metric.name))
  if (metric.kind === 'experience') {
    if (metric.category === 'video' && metric.structure !== 'breakdown') {
      return 'Video Score'
    }

    return `${category} ${kind}`
  }
  if (metric.kind === 'dataconsumed') return `${category} Data Consumption`
  if (metric.kind === 'throughput') return `${category} Throughput Rate`
  if (metric.category === 'devices') return deviceLabel(metric).base
  if (metric.category === 'ccq' || metric.category === 'ecq') return metric.name

  return secondaryMetric ? `${kind} ${capitalize(metric.subcategory)}` : `${kind}`
}

/*
  e.g.: ['Download Speed LTE', 'Latency LTE by CDN', 'Peak Latency 3G' ...]
  format: `${baseTitle} ${category} ${type} ${cdn}`
 */
const metricToFullTitle = (metric) => {
  if (metric && metric.category === 'service_reliability') {
    return metric.name
  }

  if (metric && metric.type) {
    let type
    switch (metric.type.toUpperCase()) {
      case 'LTE':
        type = '4G'
        break
      case '5GMMWAVE':
        type = 'mmWave'
        break
      case '5GLOW':
        type = '5G Low Frequency Range'
        break
      case '5GMID':
        type = '5G Mid Frequency Range'
        break
      case '5GHIGH':
        type = '5G High Frequency Range'
        break
      case '3GLTE5G':
      case 'OVERALL':
        type = 'Overall'
        break
      default:
        type = metric.type.toUpperCase()
    }

    let category

    switch (metric.category) {
      case 'responsiveness':
      case 'video':
      case 'voice':
      case 'games':
      case 'devices':
      case 'infrastructure':
      case 'availability':
      case 'coverage':
      case 'extent':
      case 'ccq':
      case 'ecq':
        category = ''
        break
      case 'speed':
        category = capitalize(metric.category)
        break
    }
    const modifier = includes(['consistency'], metric.subcategory) ? ' Consistency' : ''
    const baseTitle = metricToBaseTitle(metric)
    const betaLabel = metric.name.includes('BETA') ? ' BETA' : ''
    return `${type} ${baseTitle} ${category}${modifier}${betaLabel}`.replace(/\s\s+/g, ' ')
  }

  return ''
}

const trendsByCdnTitle = (metric, cdn) => {
  const fullTitle = metricToFullTitle(metric)
  return `${fullTitle} Trends for ${CDNS[cdn] || cdn}`
}
/*
  'video', 'download', 'latency', 'download binned'
 */
const getMetricNameLabel = (kind, subcategory, structure) => {
  if (kind === 'video') return kind
  if (subcategory === 'main') return kind
  if (structure === 'breakdown') return kind

  return (kind && subcategory) && `${kind} ${subcategory}`
}

/*
   All [Country]: Download Speed LTE/3G Overview
   All [Country]: Download Peak Speed LTE/3G Overview
   All [Country]: Download Speed LTE/3G by CDN Overview
   All [Country]: Upload Speed LTE/3G Overview
   All [Country]: Upload Peak Speed LTE/3G Overview
   All [Country]: Upload Speed LTE/3G by CDN Overview
   All [Country]: Latency LTE/3G Overview
   All [Country]: Minimum Latency LTE/3G Overview
   All [Country]: Latency LTE/3G by CDN Overview
 */

const titleLabels = (currentLocation, metric, date, agg, dashboardInfo = {}) => {
  if (!date) {
    return
  }
  const aggregation = AGGREGATIONS.find(a => a.value === agg)
  const startDate = aggregation && aggregation.days && subDays(getSafeDate(date), aggregation.days)
  return getCustomTitleLabels(currentLocation, metric, startDate, date, agg, dashboardInfo)
}

const getCustomTitleLabels = (currentLocation, metric, startDate = false, endDate, agg, dashboardInfo = {}) => {
  const DATE_FORMAT = API_LONG_DATE_FORMAT
  const aggregation = AGGREGATIONS.find(a => a.value === agg)
  const metricName = getMetricNameLabel(get(metric, 'kind'), get(metric, 'subcategory'), get(metric, 'structure'))
  const metricType = get(metric, 'type')
  const lastUpdated = dashboardInfo.data_last_updated

  const finalStartDate = startDate ? format(getSafeDate(startDate), DATE_FORMAT) : null
  const finalEndDate = format(getSafeDate(endDate), DATE_FORMAT)

  return {
    geography: geographyLabel(currentLocation),
    metricName: metricName && startCase(metricName),
    metricType: metricType && metricType.toUpperCase(),
    metricUnit: getUnit(metric),
    startDate: finalStartDate,
    endDate: finalEndDate,
    aggregation: aggregation && aggregation.full,
    lastUpdated: lastUpdated && differenceInDays(new Date(), getSafeDate(lastUpdated)),
    baseTitle: metricToBaseTitle(metric),
    fullTitle: metricToFullTitle(metric),
    byCdn: get(metric, 'subcategory') === 'cdn' ? 'by CDN' : undefined
  }
}

function geographyLabel (location) {
  if (!location) return ''

  return location.granularity === 'opensignal_countries'
    ? `All ${location.name}`
    : `${location.iso3},  ${location.name}`
}

const chartExtendedTitles = (currentLocation, metric, extension) => {
  const { geography, fullTitle } = titleLabels(currentLocation, metric)
  const unit = getUnit(metric)
  const showedUnit = unit ? `in <i>${unit}</i>` : ''
  return metric && `${geography}: ${fullTitle} ${showedUnit} | ${extension}`
}

const chartTitleWithUnits = (metric) => {
  const unit = getUnit(metric)
  const fullTitle = metricToFullTitle(metric)
  return unit ? `${fullTitle} (${unit})` : fullTitle
}

export {
  getMetricNameLabel,
  titleLabels,
  getCustomTitleLabels,
  metricToBaseTitle,
  metricToFullTitle,
  trendsByCdnTitle,
  chartExtendedTitles,
  chartTitleWithUnits,
  deviceLabel
}
