<template>
  <div
    :class="{
      'PerformanceTitle--dark': !transparent
    }"
    class="PerformanceTitle ci-title"
  >
    <div class="PerformanceTitle__title">
      {{ fullTitle }}<span
        class="PerformanceTitle__title-modifier"
      > | {{ chartModifier }}</span>
      <CustomTooltip
        v-if="tooltip"
        :message="tooltip"
      >
        <QuestionMark />
      </CustomTooltip>
    </div>
    <div
      v-if="geography && fullTitle"
      class="PerformanceTitle__subtitle"
    >
      {{ geography }}
      <span v-if="metricUnit">
        <span class="PerformanceTitle__subtitle-modifier"> | in {{ metricUnit }}</span>
      </span>
      <span
        v-if="date"
        class="PerformanceTitle__subtitle-modifier"
      > |
        <span class="PerformanceTitle__subtitle-date">{{ date }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'

export default {
  name: 'PerformanceTitle',
  components: {
    QuestionMark,
    CustomTooltip
  },
  props: {
    geography: { type: String, default: undefined },
    fullTitle: { type: String, default: undefined },
    metricUnit: { type: String, default: undefined },
    chartModifier: { type: String, default: 'Overview' },
    tooltip: { type: String, default: undefined },
    transparent: { type: Boolean, default: false },
    date: { type: String, default: undefined }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.PerformanceTitle {
  padding: 1em;
  &--dark {
    background-color: $color-nav-bg;
  }
  &__title {
    color: $color-brand-blue;
    font-weight: $font-weight-roboto-bold;
    font-size: $font-size-18;
  }
  &__title-modifier {
    color: $color-blue-header;
  }
  &__subtitle {
    color: $color-brand-blue;
    font-size: $font-size-13;
  }
  &__subtitle-modifier {
    color: $color-blue-header;
  }
  &__subtitle-date {
    color: $color-blue-header;
    font-weight: bold;
  }
}
</style>
