<template>
  <ul
    v-click-outside="() => expanded = null"
    :class="listDropdownCssClass"
    class="ListDropdown"
  >
    <li
      v-for="item in items"
      :key="item.value"
      class="ListDropdown__itemWrapper"
      :class="{'ListDropdown__itemWrapper--expanded': expanded === item.value}"
    >
      <div
        :class="{'ListDropdown__item--active': item.active}"
        class="ListDropdown__item"
        @click="handleItemClick(item.value, !item.children)"
      >
        {{ item.label }}

        <FontAwesomeIcon
          v-if="item.children && item.children.length"
          :icon="isMobile ? icons.faChevronDown : icons.faChevronRight"
          size="1x"
        />
      </div>

      <list-dropdown
        v-if="item.children && item.children.length"
        :items="item.children"
        @selected="metric => handleItemClick(metric, true)"
      />
    </li>
  </ul>
</template>

<script>
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'

export default {
  name: 'ListDropdown',
  components: {
    FontAwesomeIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: { faChevronDown, faChevronRight },
      selected: null,
      expanded: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'page/isMobile'
    }),
    listDropdownCssClass () {
      let classList = []

      if (this.theme) {
        return 'ListDropdown--' + this.theme
      }

      return classList.join(' ')
    }
  },
  methods: {
    handleItemClick (value, isLeaf) {
      if (this.isMobile) {
        if (!isLeaf) {
          if (this.expanded === value) {
            this.expanded = null
          } else {
            this.expanded = value
          }
        } else {
          this.$emit('selected', value)
          this.expanded = null
        }
      } else {
        this.$emit('selected', value)
        this.expanded = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.ListDropdown {
  margin: 0;
  padding: 0 0 .5rem;
  list-style: none;

  .ListDropdown {
    display: none;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      top: 100%;
      left: 0;
      position: relative;
      flex: 0 0 100%;
    }
  }

  &__itemWrapper {
    position: relative;

    &:hover {
      .ListDropdown {
        @media screen and (min-width: $ci-breakpoint-tablet + 1) {
          display: block
        }
      }
    }

    &--expanded {
      .ListDropdown {
        display: block !important;
        left: -1px;

        .ListDropdown__item {
          padding-left: 1.5rem;
        }
      }
    }
  }

  &__item {
    @extend %controlElement;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      flex-wrap: wrap;
    }

    &--active.ListDropdown__item {
      @extend %active;
    }
  }

  &__icon {
    float: right;
  }

  &--ci {
    .ListDropdown {
      background: $ci-dark-blue;
      top: 0;
      padding: 0;
      margin-left: 1px;
      margin-bottom: 1px;
    }

    .ListDropdown__item {
      @extend %controlElement-ci;

      @media screen and (max-width: $ci-breakpoint-tablet) {
        height: initial;
        min-height: $control-height;
      }

      &:hover {
        cursor: pointer;

        &:not(.ListDropdown__item--active) {
          @extend %hover-ci;
        }
      }

      &--active.ListDropdown__item {
        @extend %active-ci;
      }
    }
  }
}
</style>
