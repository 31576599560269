<template>
  <div class="login">
    <div class="login__content">
      <div class="login__card">
        <div class="login__section">
          <div class="login__header">
            <div>
              <img
                class="login__logo"
                src="../assets/logo.svg"
              >
            </div>
            <div class="login__title">
              Analytics Solutions
            </div>
          </div>
          <div class="login__form-wrapper">
            <form
              class="login__form"
              @submit.prevent="login"
            >
              <div class="login__form-title">
                Sign in
              </div>
              <div>
                <label>Username</label>
                <input
                  v-model="username"
                  name="username"
                  type="text"
                >
              </div>
              <div>
                <label>Password</label>
                <input
                  v-model="password"
                  name="password"
                  type="password"
                >
              </div>
              <div class="login__button-wrapper">
                <div
                  v-if="loginPending"
                  class="login__loader"
                >
                  <loader-grid />
                </div>
                <button
                  :disabled="loginPending"
                  class="login__button"
                  type="submit"
                >
                  <span v-if="!loginPending">Login</span>
                  <span v-else>&nbsp;</span>
                </button>
              </div>
              <div
                v-if="loginError"
                class="login__error"
              >
                Unable to log in with the provided credentials
              </div>
            </form>
            <div class="login__copyright">
              © Copyright by Opensignal {{ (new Date).getFullYear() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login__bottom">
      <blurred-disclaimer />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BlurredDisclaimer from '@/components/BlurredDisclaimer'
import LoaderGrid from '@/components/LoaderGrid'
import ROUTES from '@/constants/routes'

export default {
  name: 'Login',
  components: {
    BlurredDisclaimer,
    LoaderGrid
  },
  data () {
    return {
      username: '',
      password: '',
      ROUTES: ROUTES
    }
  },
  computed: {
    ...mapGetters({
      loginError: 'auth/isLoginError',
      loginPending: 'auth/isLoginPending'
    }),
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    // TODO create a plugin for auth redirect
    this.validateToken().then(res => res ? this.redirect() : '')
  },
  methods: {
    ...mapActions([
      'authenticate',
      'validateToken',
      'setUser'
    ]),
    login () {
      this.authenticate({
        user: this.username,
        pass: this.password
      }).then(() => {
        if (!this.loginError) {
          this.redirect()
        }
      })
    },
    redirect () {
      this.setUser().then(() => {
        if (this.user.dashboards.includes('market-growth')) {
          this.$router.push({
            name: ROUTES.MarketGrowth
          })
        } else {
          this.$router.push({
            name: ROUTES.Landing
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  @include foundation-button();

  /* TODO remove Bootstrap and make it global */
  @include foundation-forms();
  input[type='text'], input[type='password'] {
    border-radius: $control-horizontal-padding;
  }

  label {
    font-size: $font-size-10;
  }

  .login {
    align-items: center;
    background-image: url("../assets/landing-bg.jpg");
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    min-height: 100vh;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      flex-direction: column;
    }

    @media screen and (max-height: 540px) {
      flex-direction: column;
    }

    &__bottom {
      position: absolute;
      bottom:0;
      left: 0;
      width: 100%;
      max-width: pxToRem(625px);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        position: static;
        max-width: initial;
      }

      @media screen and (max-height: 540px) {
        position: static;
      }
    }

    &__content {
      @include xy-cell($size: shrink);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        margin: 0;
        padding: 20px;
        width: 90%;
      }
    }

    &__card {
      @include card-container($radius: $control-horizontal-padding);
      margin: 1em;
      max-width: 100%;
      width: pxToRem(380px);

      @media screen and (max-width: $ci-breakpoint-tablet) {
        margin: 0 auto;
      }
    }

    &__header {
      text-align: center;
      padding: 1em;
      @include breakpoint(medium) {
        padding: 3em 1em;
      }
    }

    &__title {
      color: $color-blue-bg-dark;
      font-size: $font-size-18;
      padding-top: .25em;
    }

    &__section {
      @include card-section($padding: none);
    }

    &__logo {
      max-width: 100%;
      width: 250px;
    }

    &__form {
      padding: 0 1em;

      @include breakpoint(medium) {
        padding: 0 4em;
      }
    }

    &__form-wrapper {
      background-color: $color-blue-secondary;
      padding: 3em 1em 1em 1em;
    }

    &__form-title {
      color: $color-blue-bg-dark;
      font-size: $font-size-18;
      padding-bottom: $control-horizontal-padding;
    }

    &__copyright {
      color: $color-blue-header;
      font-size: $font-size-10;
      opacity: .5;
      padding: 3em 1em 0 1em;
    }

    &__button-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__button {
      @include button($expand: true, $background: $color-electric-blue);
      border-radius: $control-horizontal-padding;
    }

    &__loader {
      position: absolute;
      margin: 0 auto;
      height: calc(100% - .70em);
    }

    &__error {
      @include callout($alert-color);
      padding: .5em;
      font-size: $font-size-13;
    }
  }
</style>
