<template>
  <div class="MenuCheckbox">
    <input
      :checked="modelValue"
      type="checkbox"
      @change="$emit('update:modelValue', !modelValue)"
    > {{ label }}
  </div>
</template>

<script>
export default {
  name: 'MenuCheckbox',
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .MenuCheckbox {
    font-size: $font-size-13;
    color: $color-blue-header;

    input {
      margin-right: $padding-small
    }
  }
</style>
