<template>
  <div class="RangeSlider">
    <vue-slider
      v-model="value"
      :tooltip="false"
      :process-style="processStyle"
      :slider-style="sliderStyle"
      :bg-style="bgStyle"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import vueSlider from 'vue-slider-component'
import colors from '@/utils/colorPalette'

export default {
  name: 'RangeSlider',
  components: {
    vueSlider
  },
  props: {
    leftValue: { type: Number, required: true },
    rightValue: { type: Number, required: true }
  },
  data: function () {
    return {
      processStyle: {
        backgroundColor: colors.colorElectricBlue,
        height: '3px'
      },
      sliderStyle: {
        backgroundColor: colors.colorElectricBlue,
        border: '1px solid white',
        borderRadius: '50%',
        boxSizing: 'border-box',
        margin: '-2px'
      },
      bgStyle: {
        backgroundColor: colors.colorGrayDark,
        borderRadius: '6px',
        height: '3px',
        margin: '2px'
      }
    }
  },
  computed: {
    value: {
      get () {
        return [this.leftValue, this.rightValue]
      },
      set ([leftValue, rightValue]) {
        this.updateRange(leftValue, rightValue)
      }
    }
  },
  methods: {
    updateRange: debounce(function (leftValue, rightValue) {
      this.$emit('rangeChange', leftValue, rightValue)
    }, 200)
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';
  .RangeSlider {}
</style>
