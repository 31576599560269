import Base from './base'

export class MarketGrowth extends Base {
  constructor (token) {
    super()
    this.token = `Token ${token}`
  }

  getStores ({ dashboard = 'market-growth', brandId, competitorId }) {
    return this.get(
      `${this.v2}${dashboard}/store_locations/${brandId}/${competitorId}/`,
      this.token
    )
  }
}
