<template>
  <BaseMap
    ref="baseMap"
    :geo-json="processedGeoJson"
    :geo-json-overlay="processedGeoJsonOverlay"
    :selected="selected"
    @mapReady="onMapReady"
  >
    <l-geo-json
      v-if="!markers"
      ref="geolayer"
      :geojson="geoJsonData.geoJson"
      :options="geoJsonOptions"
    />
    <l-geo-json
      v-if="citiesAsPolygons"
      ref="geolayercities"
      :geojson="geoJsonOverlayData.geoJson"
      :options="geoJsonOptions"
    />
    <RankingTableTooltip
      :title="tooltipData.name"
      :display="!!activeLocation"
      :datapoints="tooltipData.data"
      :rank-hidden="!displayRank"
      :pos-class="'RankingTableTooltip--fixed'"
    />
    <dynamic-colour-scale
      :ranges="compareScale"
    />
  </BaseMap>
</template>

<script>
import 'leaflet.markercluster'
import { LGeoJson } from '@vue-leaflet/vue-leaflet'
import chroma from 'chroma-js'
import * as L from 'leaflet'
import get from 'lodash.get'
import { mapGetters } from 'vuex'
import { MarkerClusterGroup } from '../../../../patches/leaflet.markercluster-src'
import BaseMap from './BaseMap'
import DynamicColourScale from './DynamicColourScale'
import {
  getCentreOfFeature,
  getMarkerIcon,
  getClusterMarkerIcon,
  refineGeoJsonToHandle180thMeridian
} from './helpers'
import RankingTableTooltip from '@/components/visual/chart/RankingTableTooltip'
import { sortByMetricMeanAndRank } from '@/utils/data'

export default {
  name: 'RankingMap',
  components: {
    BaseMap,
    LGeoJson,
    RankingTableTooltip,
    DynamicColourScale
  },
  mapObject: null,
  props: {
    geoJson: {
      type: Object,
      default: () => {}
    },
    geoJsonOverlay: {
      type: Object,
      default: () => {}
    },
    choroplethData: {
      type: Array,
      default: () => []
    },
    networks: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    },
    markers: {
      type: Boolean,
      default: false
    },
    zoomed: {
      type: Boolean,
      default: false
    },
    displayRank: {
      type: Boolean,
      default: false
    },
    locationsWithRank: {
      type: Object,
      default: () => {}
    },
    currentCountry: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      newMapObject: null,
      noDataColor: 'DEE4EC',
      noRankColor: 'E4D5BC',
      mapObject: null,
      activeLocation: null,
      mainCluster: null,
      citiesCluster: null,
      geoJsonOptions: {
        style: this.getFeatureStyle,
        onEachFeature: (feature, layer) => {
          layer.on({
            click: elm => this.selectPostcode(elm.target.feature.id),
            mouseover: ({ target }) => {
              target.setStyle({
                weight: 3,
                dashArray: ''
              })

              this.activeLocation = target.feature.id
            },
            mouseout: ({ target }) => {
              target.setStyle({
                weight: 2,
                dashArray: '3'
              })

              this.activeLocation = null
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/ci_networks',
      currentMetric: 'metrics/primaryMetric',
      homeNetwork: 'charts/homeNetwork',
      country: 'location/currentCountry',
      citiesAsPolygons: 'competitive/showPolygons',
      currentLocation: 'location/currentLocation'
    }),
    otherDrawColor () {
      // hard coded Japan colors to avoid overlap with softbank
      if (this.currentCountry === 101) {
        return '04bf33'
      }
      return 'ABBECE'
    },
    homeDrawColor () {
      // hard coded Japan colors to avoid overlap with softbank
      if (this.currentCountry === 101) {
        return '04b5ba'
      }
      return this.homeNetwork.hex_color
    },
    factoredData () {
      const operatorsByLocation = this.choroplethData.reduce((ac, el, i) => {
        if (ac[el.location]) {
          ac[el.location].push(el)
        } else {
          ac[el.location] = [el]
        }
        return ac
      }, {})

      return Object.values(operatorsByLocation).map(arr => {
        if (arr.length === 1) {
          return {
            location: arr[0].location,
            networks: [arr[0].canonical_network_id]
          }
        }

        return arr.reduce((elm, c, i) => {
          elm.networks.push(c.canonical_network_id)
          return elm
        }, {
          location: arr[0].location,
          networks: []
        })
      }).map(item => {
        item.colors = item.networks.map(n => {
          const network = this.networks.find(i => i.canonical_network_id === n)
          return (network && network.hex_color) || 'ccc'
        })

        return item
      })
    },
    overlayMarkers () {
      if (!this.processedGeoJsonOverlay) return []
      return this.processedGeoJsonOverlay.features.map(feature => {
        return {
          id: feature.id,
          centre: getCentreOfFeature(feature)
        }
      })
    },
    markerPoints () {
      if (!this.processedGeoJson) return []
      return this.processedGeoJson.features.map(feature => {
        return {
          id: feature.id,
          centre: getCentreOfFeature(feature)
        }
      })
    },
    tooltipData () {
      if (this.activeLocation) {
        const feature = [
          ...get(this.processedGeoJson, 'features', []),
          ...get(this.processedGeoJsonOverlay, 'features', [])
        ].find(feature => feature.id === this.activeLocation)

        return {
          name: feature.properties.name,
          data: sortByMetricMeanAndRank(
            feature.item.filter(i => this.networks.find(l => l.name_mapped === i.label)),
            this.currentMetric.bigger_is_better
          )
        }
      }

      return {
        name: '',
        data: []
      }
    },
    processedGeoJson () {
      return refineGeoJsonToHandle180thMeridian(this.geoJson, this.geoJson)
    },
    processedGeoJsonOverlay () {
      return refineGeoJsonToHandle180thMeridian(this.geoJsonOverlay, this.geoJson)
    },
    // we need both references to force leaflet reupdate style
    geoJsonData () {
      return {
        geoJson: { ...this.processedGeoJson },
        data: this.factoredData
      }
    },
    geoJsonOverlayData () {
      return {
        geoJson: { ...this.processedGeoJsonOverlay },
        data: this.factoredData
      }
    },
    compareScale () {
      var scale = this.availableNetworks.map((network) => {
        return {
          label: network.name_mapped,
          color: `#${network.hex_color}`,
          type: 'operator'
        }
      })

      scale.map((network, i, scale) => {
        if (network.label === this.homeNetwork.name_mapped) {
          scale.splice(i, 1)
          scale.unshift(network)
        }
      })

      scale.push({
        label: 'draws inc. ' + this.homeNetwork.name_mapped,
        color: chroma(this.getColor(`#${this.homeDrawColor}`)).alpha(0.5).hex(),
        type: 'nonoperator-svg'
      })

      scale.push({
        label: 'other draws',
        color: `#${this.otherDrawColor}`,
        type: 'nonoperator-svg'
      })

      scale.push({
        label: 'no ranks',
        color: `#${this.noRankColor}`,
        type: 'nonoperator-svg'
      })

      scale.push({
        label: 'unavailable',
        color: `#${this.noDataColor}`,
        type: 'nonoperator'
      })

      return scale
    },
    selected () {
      return [
        ...get(this.processedGeoJson, 'features', []),
        ...get(this.processedGeoJsonOverlay, 'features', [])
      ].find(feature => feature.id === this.modelValue)
    }
  },
  watch: {
    choroplethData () {
      this.manageLayers()
    }
  },
  updated () {
    this.manageLayers()
  },
  methods: {
    onMapReady (mapObject) {
      this.$options.mapObject = mapObject
      this.manageLayers()
    },
    getColor (color) {
      if (color === '#null') {
        return `#${this.noDataColor}`
      }
      return color
    },
    getFeatureStyle (feature) {
      const item = this.getDataForLocation(feature.id)
      if (!item) {
        return {
          weight: 1,
          opacity: 1,
          color: 'white',
          dashArray: '',
          fillOpacity: 0.75,
          fillColor: '#' + this.noDataColor
        }
      }

      if (!this.locationsWithRank[feature.id]) {
        return {
          weight: 1,
          opacity: 1,
          color: 'white',
          dashArray: '',
          fillOpacity: 0.75,
          fillColor: `#${this.noRankColor}`
        }
      }

      return {
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.9,
        fillColor: this.elementColor(item)
      }
    },
    getDataForLocation (id) {
      return this.factoredData.find(x => x.location === id)
    },
    getIcon (id) {
      const item = this.factoredData.find(i => i.location === id)
      if (!item) return getMarkerIcon(`#${this.noDataColor}`)
      if (item.colors.length === 1) {
        return getMarkerIcon(`#${item.colors[0]}`)
      }

      return item.networks.includes(this.homeNetwork.canonical_network_id)
        ? getMarkerIcon(chroma.mix(this.getColor(`#${this.homeNetwork.hex_color}`), 'white', 0.6, 'rgb').hex())
        : getMarkerIcon(`#${this.otherDrawColor}`)
    },
    selectPostcode (id) {
      if (id === this.modelValue) {
        this.$emit('update:modelValue', parseInt(this.country.key))
      } else {
        this.$emit('update:modelValue', id)
      }
    },
    manageLayers () {
      if (!this.$options.mapObject) {
        return
      }
      const opensignalGranularities = ['1', '2', '3']
      if (this.citiesAsPolygons) {
        if (this.citiesCluster) {
          this.$options.mapObject.removeLayer(this.citiesCluster)
        }

        if (this.mainCluster) {
          this.$options.mapObject.removeLayer(this.mainCluster)
        }
      } else {
        if (this.markers) {
          this.renewMarkerCluster('mainCluster', this.markerPoints, 1)
        } else if (opensignalGranularities.includes(this.currentLocation.granularityId)) {
          this.renewMarkerCluster('citiesCluster', this.overlayMarkers, 6)
        }
      }
    },
    async renewMarkerCluster (cluster, points, zoomLevel) {
      if (this[cluster]) {
        this.$options.mapObject.removeLayer(this[cluster])
      }


      this[cluster] = new MarkerClusterGroup({
        disableClusteringAtZoom: zoomLevel,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        iconCreateFunction: getClusterMarkerIcon
      })

      points.map(m => {
        this[cluster].addLayer(L.marker(m.centre, {
          icon: this.getIcon(m.id),
          interactive: true,
          zIndexOffset: 1,
          // options available in events
          location: m.id
        }).on('click mouseover mouseout', e => {
          switch (e.type) {
            case 'click':
              this.selectPostcode(e.target.options.location)
              break
            case 'mouseover':
              this.activeLocation = e.target.options.location
              break
            case 'mouseout':
              this.activeLocation = null
              break
          }
        }))
      })

      this.$options.mapObject.addLayer(this[cluster])
    },
    elementColor (item) {
      const isSelectedLocation = !this.zoomed || item.location === this.modelValue
      if (item.colors.length === 1) {
        return !isSelectedLocation
          ? chroma(`#${item.colors[0]}`).alpha(0.1).hex()
          : `#${item.colors[0]}`
      }

      if (item.networks.includes(this.homeNetwork.canonical_network_id)) {
        return !isSelectedLocation
          ? chroma(this.getColor(`#${this.homeDrawColor}`)).alpha(0.1).hex()
          : chroma(this.getColor(`#${this.homeDrawColor}`)).alpha(0.5).hex()
      }

      return !isSelectedLocation
        ? chroma(`#${this.otherDrawColor}`).alpha(0.1).hex()
        : `#${this.otherDrawColor}`
    }
  }
}
</script>
<style lang="scss">
@import 'scss/variables';
@import 'scss/components';

.cluster {
  margin: -0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  line-height: 2rem;
  text-align: center;
  background-color: $color-nav-bg;
  color: $color-nav-item-text;
  cursor: pointer;
}

.Details__mapView {
  .leaflet-map-pane {
    width: 100%;
    height: 100%;
  }

  .RankingTableTooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 0;
  }

  .DynamicColourScale {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;

    &__row {
      margin-bottom: 3px;
    }

    &__value {
      text-align: left;
    }
  }
}
</style>
