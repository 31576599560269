import get from 'lodash.get'
import includes from 'lodash.includes'
import isEmpty from 'lodash.isempty'
import map from 'lodash.map'
import uniq from 'lodash.uniq'
import Vue from 'vue'
import { FREQUENCY_RANGES } from '../../constants/constants'
import {
  V1_CDN_RES_ENDPOINT_LABELS,
  V2_CDN_RES_ENDPOINT_LABELS,
  V1_RESOLUTIONS,
  V2_RESOLUTIONS,
  V1_CDN_RES,
  V2_CDN_RES,
  V1_CDN_RES_LABELS,
  V2_CDN_RES_LABELS
} from '@/constants/cdnRes'
import {
  CPS,
  CPS_LIST,
  CDNS,
  CDNS_LIST,
  METRIC_TYPES,
  AGGREGATIONS
} from '@/constants/constants'
import ROUTES from '@/constants/routes'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'
import { multiChartId } from '@/utils/charts'
import { getBandFrequencyColor } from '@/utils/colors'
import { getChartModifier, getUnit } from '@/utils/metrics'
import {
  chartTitleWithUnits,
  getCustomTitleLabels,
  titleLabels,
  deviceLabel
} from '@/utils/titles'
import {
  getBarsByDate,
  getCpTrends,
  getCdnTrends,
  getCpTrendsPerOperator,
  getCdnTrendsPerOperator,
  getFocusBars,
  getHourlyData,
  getRoundedBuckets,
  getTrendSeries,
  getCdnResTrends,
  getCdnResOpsTrends,
  getCdnResFocus,
  getDevicesFormated,
  getRangesPerOperator
} from '@/utils/viewHelpers'

const types = {
  CDN_RANGE: 'charts/cdnRange',
  CDN_RES_FOCUS: 'charts/cdnResFocus',
  CDN_RES_OPS_TRENDS: 'charts/cdnResOpsTrends',
  CDN_RES_RANGES: 'charts/cdnResRanges',
  CDN_RES_TRENDS: 'charts/cdnResTrends',
  CI_NETWORK_OPERATORS: 'charts/ci_networks',
  COMBINED_CATEGORIES: 'charts/combinedCategories',
  CP_RANGE: 'charts/cpRange',
  GET_BY_CDN_CHARTS: 'charts/getByCdnCharts',
  GET_BY_CP_CHARTS: 'charts/getByCpCharts',
  GET_BY_OPERATOR_CDN_CHARTS: 'charts/getByOperatorCdnCharts',
  GET_BY_OPERATOR_CP_CHARTS: 'charts/getByOperatorCpCharts',
  GET_CDN_FOCUS_CHART: 'charts/getCdnFocusChart',
  GET_CP_FOCUS_CHART: 'charts/getCpFocusChart',
  GET_DISTRIBUTION_CHART_SCALE: 'charts/getDistributionChartScale',
  GET_DISTRIBUTION_CHARTS_BY_CDN_RES: 'charts/getDistributionChartsByCdnRes',
  GET_DISTRIBUTION_CHARTS_BY_OPERATOR: 'charts/getDistributionChartsByOperator',
  GET_HIGHLIGHTS: 'charts/getHighlights',
  GET_PRIMARY_DISTRIBUTION_CHART: 'charts/getPrimaryDistributionChart',
  GET_PRIMARY_HOURLY_CHART: 'charts/getPrimaryHourlyChart',
  GET_RANGES_CHARTS: 'charts/getRangesCharts',
  GET_SECONDARY_PEAK_CHART: 'charts/getSecondaryPeakChart',
  GET_TITLE_LABELS: 'charts/getTitleLabels',
  HIDDEN_NETWORK_IDS: 'charts/hiddenNetworksIds',
  HOME_HIGHLIGHT: 'charts/homeHighlight',
  HOME_NETWORK: 'charts/homeNetwork',
  IS_DISTRIBUTION_GROUPED: 'charts/isDistributionGrouped',
  IS_LOAD_CHART_DATA_ERROR: 'charts/isLoadChartDataError',
  IS_LOAD_CHART_DATA_PENDING: 'charts/isLoadChartDataPending',
  IS_PERFORMANCE_INTELLIGENCE_DATA_LOADED: 'charts/isPerformanceIntelligenceDataLoaded',
  NETWORK_OPERATORS: 'charts/networks',
  ORGANIZATION_END_DATE: 'charts/organizationEndDate',
  ORGANIZATION_START_DATE: 'charts/organizationStartDate',
  PLAIN_DATA: 'charts/plainData',
  SELECTED_NETWORK_OPERATORS: 'charts/selectedNetworkOperators',
  SET_CHART_NETWORK_OPERATOR_IDS: 'charts/SET_CHART_NETWORK_OPERATOR_IDS',
  SET_DISTRIBUTION_GROUPED: 'charts/SET_DISTRIBUTION_GROUPED',
  SET_HIDDEN_NETWORK_OPERATOR_IDS: 'charts/SET_HIDDEN_NETWORK_OPERATOR_IDS',
  SINGLE_NETWORK_CATEGORIES: 'charts/singleNetworkCategories',
  STACKED_DIST: 'charts/stackedDist',
  TITLE_LABELS: 'charts/titleLabels',
}

const rootTypes = {
  AUTH_LOAD_CHART_DATA_ASYNC_FAILURE: 'auth/LOAD_CHART_DATA_ASYNC_FAILURE',
  AUTH_LOAD_CHART_DATA_ASYNC_PENDING: 'auth/LOAD_CHART_DATA_ASYNC_PENDING',
  AUTH_LOAD_CHART_DATA_ASYNC_SUCCESS: 'auth/LOAD_CHART_DATA_ASYNC_SUCCESS',
  AVAILABLE_CDNS: 'metrics/cdns',
  AVAILABLE_CPS: 'metrics/cps',
  CHART_CDN_FOCUS: 'chart/cdnFocus',
  CHART_CDN_FRM: 'chartFrm/cdn',
  CHART_CDN_RES_FOCUS: 'chart/cdnResFocus',
  CHART_CDN_RES_FRM: 'chartFrm/cdnRes',
  CHART_CDN_RES: 'chart/cdnRes',
  CHART_CDN: 'chart/cdn',
  CHART_CP_FOCUS: 'chart/cpFocus',
  CHART_CP_FRM: 'chartFrm/cp',
  CHART_CP: 'chart/cp',
  CHART_DEVICES: 'chart/devices',
  CHART_DIST: 'chart/dist',
  CHART_DISTRIBUTION_CDN_RES: 'chart/distributionCdnRes',
  CHART_DISTRIBUTION: 'chart/distribution',
  CHART_HOURLY: 'chart/hourly',
  CHART_METRIC: 'metrics/primaryMetric',
  CHART_RANGES: 'chart/ranges',
  CHART_TOOLTIP: 'metrics/tooltip',
  CHART_TREND_FRM: 'chartFrm/trend',
  CHART_TREND: 'chart/trend',
  // CHART_UNIT: 'metrics/primaryUnit',
  DISPLAY_FRM: 'chartFrm/displayFrm',
  HERO_CHART: 'hero/chart',
  HERO_HERO: 'hero/hero',
  HERO_METRIC: 'metrics/supportingMetric',
  HERO_UNIT: 'metrics/supportingUnit',
  IS_CDN_RES_V2: 'chart/isCdnResV2',
  LOCATIONS_CURRENT: 'locations/current',
  PERFORMANCE_GET_ADJUSTABLE_SCALE: 'dashboard/getAdjustableScale'
}

const state = () => ({
  loadChartDataPending: false,
  loadChartDataError: false,
  hiddenOperators: [],
  chartOperators: [],
  distributionGrouped: false
})

const chartLoaded = (chartData, date) => {
  return !!chartData.length
}

const findTooltipMessage = (messageKey) => {
  return get(Object.entries(TOOLTIP_MESSAGES).find(([key, message]) => key === messageKey), 1)
}

const getters = {
  [types.IS_LOAD_CHART_DATA_ERROR]: (state, getters, rootState, rootGetters) => state.loadChartDataError,
  [types.IS_LOAD_CHART_DATA_PENDING]: (state, getters, rootState, rootGetters) => state.loadChartDataPending,
  [types.IS_DISTRIBUTION_GROUPED]: (state, getters, rootState, rootGetters) => state.distributionGrouped,
  [types.GET_DISTRIBUTION_CHART_SCALE]: (state, getters, rootState, rootGetters) => {
    if (!getters[types.IS_DISTRIBUTION_GROUPED]) {
      return 1
    }
    return 5
  },
  [rootTypes.DISPLAY_FRM]: (state, getters, rootState, rootGetters) => {
    const metric = rootGetters[rootTypes.CHART_METRIC]
    return metric.has_frm && metric.type !== METRIC_TYPES.ThreeG
  },
  [types.TITLE_LABELS]: (state, getters, rootState, rootGetters) => {
    const isOverview = router.currentRoute.value.name === ROUTES.PerformanceIntelligenceOverview
    const heroMetric = isOverview ? rootGetters[rootTypes.CHART_METRIC] : rootGetters[rootTypes.HERO_METRIC]

    return {
      ...titleLabels(
        rootGetters[rootTypes.LOCATIONS_CURRENT],
        heroMetric,
        rootGetters['dashboard/date'],
        router.currentRoute.value.params.agg,
        rootGetters.dashboardInfo
      ),
      chartTitle: titleLabels(
        rootGetters[rootTypes.LOCATIONS_CURRENT],
        rootGetters[rootTypes.CHART_METRIC],
        rootGetters['dashboard/date'],
        router.currentRoute.value.params.agg,
        rootGetters.dashboardInfo
      )
    }
  },
  [types.GET_TITLE_LABELS]: (state, getters, rootState, rootGetters) => (metric, startDate, endDate) => ({
    ...getCustomTitleLabels(
      rootGetters[rootTypes.LOCATIONS_CURRENT],
      metric,
      startDate,
      endDate,
      router.currentRoute.value.params.agg,
      getters.dashboardInfo
    ),
    chartTitle: getCustomTitleLabels(
      rootGetters[rootTypes.LOCATIONS_CURRENT],
      rootGetters[rootTypes.CHART_METRIC],
      startDate,
      endDate,
      router.currentRoute.value.params.agg,
      rootGetters.dashboardInfo
    )
  }),
  [types.CP_RANGE]: (state, getters, rootState, rootGetters) => CPS_LIST.reduce((acc, label) => {
    const bounds = rootGetters[rootTypes.CHART_CP].reduce((add, item) => {
      if (!item.lci || !item.lci[label]) return add

      add[0] = Math.min(item.lci[label], add[0])
      add[1] = Math.max(item.uci[label], add[1])

      return add
    }, [Infinity, 0])

    acc[0] = Math.min(bounds[0], acc[0])
    acc[1] = Math.max(bounds[1], acc[1])

    return acc
  }, [Infinity, 0]),
  [types.CDN_RANGE]: (state, getters, rootState, rootGetters) => CDNS_LIST.reduce((acc, label) => {
    const bounds = rootGetters[rootTypes.CHART_CDN].reduce((add, item) => {
      if (!item.lci[label]) return add

      add[0] = Math.min(item.lci[label], add[0])
      add[1] = Math.max(item.uci[label], add[1])

      return add
    }, [Infinity, 0])

    acc[0] = Math.min(bounds[0], acc[0])
    acc[1] = Math.max(bounds[1], acc[1])

    return acc
  }, [Infinity, 0]),
  [types.CDN_RES_RANGES]: (state, getters, rootState, rootGetters) => {
    const cdnRes = rootGetters[rootTypes.IS_CDN_RES_V2] ? V2_CDN_RES : V1_CDN_RES
    return cdnRes.reduce((acc, label, index) => {
      const bounds = rootGetters[rootTypes.CHART_CDN_RES].reduce((add, item) => {
        add[0] = Math.min(item.lci[label], add[0])
        add[1] = Math.max(item.uci[label], add[1])

        return add
      }, [Infinity, 0])

      if (index % 2) {
        acc.bounds720[0] = Math.min(bounds[0], acc.bounds720[0])
        acc.bounds720[1] = Math.max(bounds[1], acc.bounds720[1])
      } else {
        acc.bounds360[0] = Math.min(bounds[0], acc.bounds360[0])
        acc.bounds360[1] = Math.max(bounds[1], acc.bounds360[1])
      }

      return acc
    }, {
      bounds360: [Infinity, 0],
      bounds720: [Infinity, 0]
    })
  },
  [types.ORGANIZATION_START_DATE]: (state, getters, rootState, rootGetters) => new Date(rootGetters.dashboardInfo.first_date_available),
  [types.ORGANIZATION_END_DATE]: (state, getters, rootState, rootGetters) => new Date(rootGetters.dashboardInfo.last_date_available),
  [types.GET_SECONDARY_PEAK_CHART]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_TREND].length) return {}

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const data = Object.values(getTrendSeries(
      getters[types.SELECTED_NETWORK_OPERATORS],
      rootGetters[rootTypes.CHART_TREND]
    ))
    const dataFrm = Object.values(getTrendSeries(
      getters[types.SELECTED_NETWORK_OPERATORS],
      rootGetters[rootTypes.CHART_TREND_FRM]
    ))

    return {
      data,
      dataFrm,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_TREND], rootGetters['dashboard/date']),
      title: getChartModifier(metric.key) || 'Trend',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: !get(data, [0]),
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.PLAIN_DATA]: (state, getters, rootState, rootGetters) => {
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']
    return {
      data: rootGetters.chart.filter(a => a.date === selectedDate),
      loaded: chartLoaded(rootGetters.chart, selectedDate),
      title: 'Example Title',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: false,
      xAxisLabel: 'Some other title',
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.COMBINED_CATEGORIES]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DEVICES].length) return { empty: true }

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const data = getDevicesFormated(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_DEVICES], true)
    return {
      data: data.data,
      max: data.max,
      labels: data.labels,
      title: `by ${deviceLabel(metric).modifier}`,
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: !data.labels.length,
      xAxisLabel: deviceLabel(metric).modifier,
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.SINGLE_NETWORK_CATEGORIES]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DEVICES].length) return {}

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const data = getDevicesFormated(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_DEVICES], false)
    const modifier = deviceLabel(metric).modifier

    return {
      data: data.data,
      max: data.max,
      labels: data.labels,
      title: `by ${modifier}`,
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: !Object.values(data.labels)[0] || !Object.values(data.labels)[0].length,
      xAxisLabel: modifier && modifier.charAt(0).toUpperCase() + modifier.slice(1),
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.GET_CP_FOCUS_CHART]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CP_FOCUS].length) return { empty: true }

    const adjustableScale = rootGetters[rootTypes.PERFORMANCE_GET_ADJUSTABLE_SCALE]
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const operators = getters[types.SELECTED_NETWORK_OPERATORS]
    const selectedDate = rootGetters['dashboard/date']

    const { data, max, min } = getFocusBars(operators, selectedDate, rootGetters[rootTypes.CHART_CP_FOCUS], rootGetters[rootTypes.AVAILABLE_CPS])

    return {
      data: Object.values(data),
      labels: rootGetters[rootTypes.AVAILABLE_CPS].map(c => CPS[c]),
      max,
      min: adjustableScale ? min : 0,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CP_FOCUS], selectedDate),
      title: 'By Cloud Platform',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: isEmpty(get(Object.values(data), [0, 'data'], [])),
      xAxisLabel: 'Cloud Platform Name',
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.GET_CDN_FOCUS_CHART]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN_FOCUS].length) return {}

    const adjustableScale = rootGetters[rootTypes.PERFORMANCE_GET_ADJUSTABLE_SCALE]
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const operators = getters[types.SELECTED_NETWORK_OPERATORS]
    const selectedDate = rootGetters['dashboard/date']

    let { data, max, min } = getFocusBars(operators, selectedDate, rootGetters[rootTypes.CHART_CDN_FOCUS], rootGetters[rootTypes.AVAILABLE_CDNS])

    if (!data.length) {
      let focusBars = getFocusBars(operators, rootGetters['dashboard/getLatestDate'](rootGetters[rootTypes.CHART_CDN_FOCUS]), rootGetters[rootTypes.CHART_CDN_FOCUS], rootGetters[rootTypes.AVAILABLE_CDNS])

      data = focusBars.data
      max = focusBars.max
      min = focusBars.min
    }

    return {
      data: Object.values(data),
      labels: rootGetters[rootTypes.AVAILABLE_CDNS].map(c => CDNS[c]),
      max,
      min: adjustableScale ? min : 0,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN_FOCUS], selectedDate),
      title: 'By CDN',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: isEmpty(get(Object.values(data), [0, 'data'], [])),
      xAxisLabel: 'CDN Name',
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.GET_BY_CP_CHARTS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CP].length) return []

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']

    const cpTrends = getCpTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CP])
    const cpTrendsFrm = getCpTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CP_FRM])

    return rootGetters[rootTypes.AVAILABLE_CPS].map((cpKey) => ({
      data: Object.values(cpTrends[cpKey]),
      dataFrm: Object.values(cpTrendsFrm[cpKey]),
      chartId: multiChartId(cpKey),
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CP], selectedDate),
      title: `Trends for ${CPS[cpKey]}`,
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric)
    }))
  },
  [types.GET_BY_CDN_CHARTS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN].length) return []

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']

    const cdnTrends = getCdnTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN])
    const cdnFrmTrends = getCdnTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN_FRM])

    const aggregation = AGGREGATIONS.find(a => a.value === router.currentRoute.value.params.agg)
    return rootGetters[rootTypes.AVAILABLE_CDNS].map((cdnKey) => ({
      data: Object.values(cdnTrends[cdnKey]),
      dataFrm: Object.values(cdnFrmTrends[cdnKey]),
      chartId: multiChartId(cdnKey),
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN], selectedDate),
      title: `Trends for ${CDNS[cdnKey]}`,
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric),
      type: metric.type && metric.type.toUpperCase(),
      cdn: CDNS[cdnKey],
      agg: aggregation.label
    }))
  },
  [types.GET_PRIMARY_HOURLY_CHART]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_HOURLY].length) return { empty: true }

    const adjustableScale = rootGetters[rootTypes.PERFORMANCE_GET_ADJUSTABLE_SCALE]
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const operators = getters[types.SELECTED_NETWORK_OPERATORS]
    const selectedDate = rootGetters['dashboard/date']
    const { data, label, max, min } = getHourlyData(operators, selectedDate, rootGetters[rootTypes.CHART_HOURLY])
    return {
      data: Object.values(data),
      label,
      max,
      min: adjustableScale ? min : 0,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_HOURLY], selectedDate),
      title: getChartModifier(metric.key) || 'Hour of Day',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: isEmpty(get(Object.values(data), [0, 'data'], [])),
      xAxisLabel: 'Hour of Day',
      yAxisLabel: chartTitleWithUnits(metric),
      xAxisMaxTickValue: metric.subtype === 'hourlyvideoexperience' ? 100 : undefined
    }
  },
  [types.GET_PRIMARY_DISTRIBUTION_CHART]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DISTRIBUTION].length) return { empty: true }

    const selectedDate = rootGetters['dashboard/date']
    const { data, label, max } = getRoundedBuckets(
      getters[types.SELECTED_NETWORK_OPERATORS],
      rootGetters[rootTypes.CHART_DISTRIBUTION],
      getters[types.GET_DISTRIBUTION_CHART_SCALE]
    )

    let metric

    if (
      rootGetters[rootTypes.CHART_METRIC].category === 'voice' ||
      rootGetters[rootTypes.CHART_METRIC].category === 'games'
    ) {
      metric = rootGetters[rootTypes.CHART_METRIC]
    } else {
      metric = rootGetters[rootTypes.HERO_METRIC]
    }

    return {
      data: Object.values(data),
      label,
      max,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_DISTRIBUTION], selectedDate),
      title: 'Distribution',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: isEmpty(get(Object.values(data), [0, 'data'], [])),
      xAxisLabel: chartTitleWithUnits(metric),
      xAxisUnit: getUnit(metric),
      yAxisLabel: 'Proportion of devices (%)',
      yAxisMax: max
    }
  },
  [types.GET_DISTRIBUTION_CHARTS_BY_OPERATOR]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DISTRIBUTION].length) return []

    const primaryDistributionChart = getters[types.GET_PRIMARY_DISTRIBUTION_CHART]
    const operators = getters[types.SELECTED_NETWORK_OPERATORS]
    return operators.map(o => {
      const data = primaryDistributionChart.data.filter(d => d.label === o.name_mapped)
      return {
        ...primaryDistributionChart,
        empty: isEmpty(data),
        chartId: multiChartId(o.name_mapped),
        title: `${primaryDistributionChart.title} for ${o.name_mapped}`,
        data: primaryDistributionChart.data.filter(d => d.label === o.name_mapped)
      }
    })
  },
  [types.GET_DISTRIBUTION_CHARTS_BY_CDN_RES]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DISTRIBUTION_CDN_RES].length) return []

    const selectedDate = rootGetters['dashboard/date']
    const loaded = chartLoaded(rootGetters[rootTypes.CHART_DISTRIBUTION_CDN_RES], selectedDate)
    const { data } = getRoundedBuckets(
      getters[types.SELECTED_NETWORK_OPERATORS],
      rootGetters[rootTypes.CHART_DISTRIBUTION_CDN_RES],
      getters[types.GET_DISTRIBUTION_CHART_SCALE],
      true
    )
    const collectiveData = Object.values(data)
    const V2 = rootGetters[rootTypes.IS_CDN_RES_V2]
    const cdnRes = V2 ? V2_CDN_RES : V1_CDN_RES
    return cdnRes
      .filter(o => collectiveData.every(m => m.data[o]))
      .map(o => ({
        max: collectiveData.reduce((ac, m) => Math.max(ac, m.data[o].max), 0),
        loaded,
        empty: collectiveData.reduce((ac, m) => Math.max(ac, m.data[o].data.length), 0) < 1,
        xAxisUnit: rootGetters[rootTypes.HERO_UNIT],
        xAxisLabel: chartTitleWithUnits(rootGetters[rootTypes.HERO_METRIC]),
        yAxisLabel: 'Proportion of devices (%)',
        yAxisMax: collectiveData.reduce((ac, m) => Math.max(ac, m.data[o].max), 0),
        chartId: multiChartId(o),
        title: V2 ? V2_CDN_RES_LABELS[o] : V1_CDN_RES_LABELS[o],
        tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
        data: collectiveData.map(d => ({
          ...d,
          data: d.data[o] ? d.data[o].data : []
        })),
        label: collectiveData.reduce((ac, c) => {
          ac.data = ac.max > c.data[o].labels.length ? ac.data : c.data[o].labels
          ac.max = Math.max(ac.max, c.data[o].labels.length)
          return ac
        }, {
          max: 0,
          data: []
        }).data
      }))
  },
  [types.GET_RANGES_CHARTS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_RANGES].length) return []

    const selectedDate = rootGetters['dashboard/date']
    const operaotrs = getters[types.SELECTED_NETWORK_OPERATORS]
    const aggregation = AGGREGATIONS.find(a => a.value === router.currentRoute.value.params.agg)

    const data = getRangesPerOperator(
      operaotrs,
      rootGetters[rootTypes.CHART_RANGES]
    )

    return Object.keys(data).map(op => ({
      loaded: chartLoaded(rootGetters[rootTypes.CHART_RANGES], selectedDate),
      xAxisUnit: rootGetters[rootTypes.HERO_UNIT],
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(rootGetters[rootTypes.HERO_METRIC]),
      chartId: multiChartId(op),
      title: `Frequency Range trends for ${operaotrs.find(_o => _o.canonical_network_id === Number(op)).name_mapped}`,
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      data: Object.values(data[op]),
      label: FREQUENCY_RANGES,
      agg: aggregation.label
    }))
  },
  [types.HOME_NETWORK]: (state, getters, rootState, rootGetters) => {
    const homeNetwork = getters.dashboardInfo.home_networks && getters.dashboardInfo.home_networks.find(network =>
      network.country_iso3 === rootGetters[rootTypes.LOCATIONS_CURRENT].iso3
    )
    if (homeNetwork) {
      return homeNetwork
    } else {
      if (!getters.dashboardInfo.home_networks) {
        return null
      }
      return getters.dashboardInfo.home_networks.find(network => network.canonical_network_id === -1)
    }
  },
  [types.CI_NETWORK_OPERATORS]: (state, getters, rootState, rootGetters) => {
    const { countryid, location } = router.currentRoute.value.params
    const isNationalLevel = location === countryid
    const isInTrendChart = operator => state.chartOperators.includes(operator.canonical_network_id)
    const isVisible = operator => isNationalLevel || isInTrendChart(operator)
    return [...getters[types.NETWORK_OPERATORS].filter(isVisible)]
  },
  [types.NETWORK_OPERATORS]: (state, getters, rootState, rootGetters) => {
    const homeNetwork = getters[types.HOME_NETWORK] ? [{
      ...getters[types.HOME_NETWORK],
      selected: !includes(state.hiddenOperators, getters[types.HOME_NETWORK].canonical_network_id)
    }] : []
    return [
      ...homeNetwork,
      ...getters.operators.map((operator) => ({
        ...operator,
        selected: !includes(state.hiddenOperators, operator.canonical_network_id)
      })).filter(o =>
        getters[types.HOME_NETWORK]
          ? o.canonical_network_id !== getters[types.HOME_NETWORK].canonical_network_id
          : true
      )
    ]
  },
  [types.SELECTED_NETWORK_OPERATORS]: (state, getters, rootState, rootGetters) => {
    const networkOperators = getters[types.NETWORK_OPERATORS]
    return networkOperators.filter(o => o.selected)
  },
  [types.HIDDEN_NETWORK_IDS]: (state, getters, rootState, rootGetters) => state.hiddenOperators,
  [types.GET_BY_OPERATOR_CP_CHARTS]: (state, getters, rootState, rootGetters) => {
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']
    const ops = getters[types.SELECTED_NETWORK_OPERATORS]
    const opTrends = getCpTrendsPerOperator(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CP])
    const opTrendsFrm = getCpTrendsPerOperator(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CP_FRM])

    return ops && ops.map((op) => ({
      data: Object.values(opTrends[op.canonical_network_id]).filter(item => rootGetters[rootTypes.AVAILABLE_CPS].includes(item.label)),
      dataFrm: Object.values(opTrendsFrm[op.canonical_network_id]).filter(item => rootGetters[rootTypes.AVAILABLE_CPS].includes(item.label)),
      chartId: multiChartId(op.canonical_network_id),
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CP], selectedDate),
      empty: !Object.values(opTrends[op.canonical_network_id]).filter(item => rootGetters[rootTypes.AVAILABLE_CPS].includes(item.label)).length,
      title: `Cloud Platform trends for ${op.name_mapped}`,
      tooltip: findTooltipMessage(metric.subtype) || findTooltipMessage(metric.kind),
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric)
    }))
  },
  [types.GET_BY_OPERATOR_CDN_CHARTS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN].length) return []

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']
    const ops = getters[types.SELECTED_NETWORK_OPERATORS]
    const opTrends = getCdnTrendsPerOperator(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN])
    const opFrmTrends = getCdnTrendsPerOperator(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN_FRM])

    const aggregation = AGGREGATIONS.find(a => a.value === router.currentRoute.value.params.agg)

    return ops && ops.map((op) => ({
      data: Object.values(opTrends[op.canonical_network_id]).filter(item => rootGetters[rootTypes.AVAILABLE_CDNS].includes(item.label)),
      dataFrm: Object.values(opFrmTrends[op.canonical_network_id]).filter(item => rootGetters[rootTypes.AVAILABLE_CDNS].includes(item.label)),
      chartId: multiChartId(op.canonical_network_id),
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN], selectedDate),
      title: `CDN trends for ${op.name_mapped}`,
      tooltip: findTooltipMessage(metric.subtype) || findTooltipMessage(metric.kind),
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric),
      type: metric.type && metric.type.toUpperCase(),
      operator: op.name_mapped,
      agg: aggregation.label
    }))
  },
  [types.CDN_RES_TRENDS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN_RES].length) return []

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']

    const cdnRes = rootGetters[rootTypes.IS_CDN_RES_V2] ? V2_CDN_RES : V1_CDN_RES
    const labels = rootGetters[rootTypes.IS_CDN_RES_V2] ? V2_CDN_RES_LABELS : V1_CDN_RES_LABELS
    const selectedOperators = getters[types.SELECTED_NETWORK_OPERATORS]
    const cdnResTrends = getCdnResTrends(selectedOperators, rootGetters[rootTypes.CHART_CDN_RES], cdnRes)
    const cdnResFrmTrends = getCdnResTrends(selectedOperators, rootGetters[rootTypes.CHART_CDN_RES_FRM], cdnRes)

    const aggregation = AGGREGATIONS.find(a => a.value === router.currentRoute.value.params.agg)
    const _return = cdnRes.map((cdnRes) => {
      let cdnLabel = labels[cdnRes].substr(0, labels[cdnRes].indexOf(' '))
      cdnLabel = cdnLabel === 'Google' ? 'Google Cloud' : cdnLabel
      const resolutionPosition = cdnLabel === 'Google Cloud' ? 2 : 1
      return ({
        data: Object.values(cdnResTrends[cdnRes]),
        dataFrm: Object.values(cdnResFrmTrends[cdnRes]),
        chartId: multiChartId(cdnRes),
        // needs update to not require date
        loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN_RES], selectedDate),
        title: `Trends for ${labels[cdnRes]}`,
        tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
        xAxisLabel: undefined,
        yAxisLabel: chartTitleWithUnits(metric),
        type: metric.type && metric.type.toUpperCase(),
        cdn: cdnLabel,
        resolution: labels[cdnRes].substr(labels[cdnRes].indexOf(' ') + resolutionPosition),
        agg: aggregation.label
      })
    })

    return _return
  },
  [types.CDN_RES_OPS_TRENDS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN_RES].length) return []

    const metric = rootGetters[rootTypes.CHART_METRIC]
    const selectedDate = rootGetters['dashboard/date']
    const ops = getters[types.SELECTED_NETWORK_OPERATORS]
    const V2 = rootGetters[rootTypes.IS_CDN_RES_V2]
    const cdnRes = V2 ? V2_CDN_RES : V1_CDN_RES

    const cdnResOpsTrends = getCdnResOpsTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN_RES], cdnRes, V2)
    const cdnResOpsFrmTrends = getCdnResOpsTrends(getters[types.SELECTED_NETWORK_OPERATORS], rootGetters[rootTypes.CHART_CDN_RES_FRM], cdnRes, V2)

    const aggregation = AGGREGATIONS.find(a => a.value === router.currentRoute.value.params.agg)
    return ops && ops.map((op) => ({
      data: Object.values(cdnResOpsTrends[op.canonical_network_id]),
      dataFrm: Object.values(cdnResOpsFrmTrends[op.canonical_network_id]),
      chartId: multiChartId(op.canonical_network_id),
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN_RES], selectedDate),
      title: `CDN trends for ${op.name_mapped}`,
      tooltip: findTooltipMessage(metric.subtype) || findTooltipMessage(metric.kind),
      xAxisLabel: undefined,
      yAxisLabel: chartTitleWithUnits(metric),
      type: metric.type && metric.type.toUpperCase(),
      operator: op.name_mapped,
      agg: aggregation.label
    }))
  },
  [types.CDN_RES_FOCUS]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_CDN_RES_FOCUS].length) return []

    const adjustableScale = rootGetters[rootTypes.PERFORMANCE_GET_ADJUSTABLE_SCALE]
    const metric = rootGetters[rootTypes.CHART_METRIC]
    const operators = getters[types.SELECTED_NETWORK_OPERATORS]
    const selectedDate = rootGetters['dashboard/date']

    const { data, max, min } = getCdnResFocus(operators, rootGetters[rootTypes.CHART_CDN_RES_FOCUS], rootGetters[rootTypes.IS_CDN_RES_V2])
    const cdns = rootGetters[rootTypes.IS_CDN_RES_V2]
      ? Object.values(V2_CDN_RES_ENDPOINT_LABELS)
      : Object.values(V1_CDN_RES_ENDPOINT_LABELS)
    const res = rootGetters[rootTypes.IS_CDN_RES_V2] ? V2_RESOLUTIONS : V1_RESOLUTIONS

    return {
      data: Object.values(data),
      labels: { cdns, res },
      max,
      min: adjustableScale ? min : 0,
      loaded: chartLoaded(rootGetters[rootTypes.CHART_CDN_RES_FOCUS], selectedDate),
      title: 'Provider and Resolution',
      tooltip: rootGetters[rootTypes.CHART_TOOLTIP],
      empty: isEmpty(get(Object.values(data), [0, 'data'], [])),
      xAxisLabel: 'Provider and Resolution',
      yAxisLabel: chartTitleWithUnits(metric)
    }
  },
  [types.GET_HIGHLIGHTS]: (state, getters, rootState, rootGetters) => {
    const selectedDate = rootGetters['dashboard/date']
    const metric = rootGetters[rootTypes.HERO_METRIC]
    const selectedNetworks = rootGetters[types.SELECTED_NETWORK_OPERATORS]
    const networks = getters[types.NETWORK_OPERATORS]

    const { data, label, max } = getBarsByDate(networks, selectedDate, rootGetters[rootTypes.HERO_HERO])
    return {
      data: networks && networks.map(net => {
        const datum = data.find(d => d.x === net.name_mapped)
        const meanComparison = get(datum, ['comparison', 'mean'])
        const disabled = !includes(map(selectedNetworks, 'name_mapped'), net.name_mapped)
        return {
          ...datum,
          x: net.name_mapped,
          color: net && `#${net.hex_color}`,
          positive: metric.bigger_is_better
            ? meanComparison > 0
            : meanComparison < 0,
          percentage: meanComparison,
          disabled
        }
      }),
      label,
      max,
      metric
    }
  },
  [types.HOME_HIGHLIGHT]: (state, getters, rootState, rootGetters) => {
    return rootGetters[rootTypes.HERO_CHART]
      .find(item => item.canonical_network_id === getters[types.HOME_NETWORK].canonical_network_id)
  },
  [types.STACKED_DIST]: (state, getters, rootState, rootGetters) => {
    if (!rootGetters[rootTypes.CHART_DIST].length) return {}

    const frequencies = uniq(rootGetters[rootTypes.CHART_DIST].reduce((ac, item) => ([...ac, ...Object.keys(item.percent)]), [])).map((f, i) => {
      const parts = f.match(/\d+/g)

      return {
        key: f,
        id: parts[0],
        min: parts[1],
        max: parts[2],
        label: `${parts[0]} (${parts[1]}MHz - ${parts[2]}MHz)`
      }
    })

    const allNetworks = getters[types.SELECTED_NETWORK_OPERATORS].map(n => ({
      ...n,
      percent: get(rootGetters[rootTypes.CHART_DIST].find(item => item.canonical_network_id === n.canonical_network_id), 'percent', {})
    }))

    const networksWithData = allNetworks.filter(networkData => {
      if (!networkData.percent) {
        return false
      }
      const percentages = Object.keys(networkData.percent)
      return percentages && percentages.length
    })

    return {
      labels: networksWithData.map(n => n.name_mapped),
      data: frequencies.map((f) => ({
        data: networksWithData.map(n => n.percent[`${f.key}`]),
        backgroundColor: getBandFrequencyColor(f.max, f.id, 0.8),
        hoverBackgroundColor: getBandFrequencyColor(f.max, f.id, 0.9),
        label: f.label,
        band: f
      })).sort(function (a, b) {
        return b.band.min - a.band.min ? b.band.min - a.band.min : b.band.max - a.band.max
      }),
      tooltip: findTooltipMessage(rootGetters[rootTypes.CHART_METRIC].subtype) || findTooltipMessage(rootGetters[rootTypes.CHART_METRIC].kind)
    }
  },
  [types.IS_PERFORMANCE_INTELLIGENCE_DATA_LOADED]: (state, getters, rootState, rootGetters) => {
    const selectedDate = rootGetters['dashboard/date']
    return selectedDate &&
      (chartLoaded(getters.hero, selectedDate) || chartLoaded(getters.chart, selectedDate))
  }
}

const actions = {
  'toggleOperator': ({ commit, state }, canonicalNetworkId) => {
    const { hiddenOperators } = state
    const operatorSelected = includes(hiddenOperators, canonicalNetworkId)
    const operators = operatorSelected
      ? hiddenOperators.filter((o) => o !== canonicalNetworkId)
      : [...hiddenOperators, canonicalNetworkId]
    commit(types.SET_HIDDEN_NETWORK_OPERATOR_IDS, operators)
  },
  'resetOperators': ({ commit, state }) => {
    commit(types.SET_HIDDEN_NETWORK_OPERATOR_IDS, [])
  },
  'setDistributionGrouped': ({ commit, state }, value) => {
    commit(types.SET_DISTRIBUTION_GROUPED, value)
  },
  'setChartOperators': ({ commit }, operators) => {
    commit(types.SET_CHART_NETWORK_OPERATOR_IDS, operators)
  }
}

const mutations = {
  [rootTypes.AUTH_LOAD_CHART_DATA_ASYNC_PENDING]: (state, getters, rootState, rootGetters) => {
    state.loadChartDataPending = true
    state.loadChartDataError = false
  },
  [rootTypes.AUTH_LOAD_CHART_DATA_ASYNC_SUCCESS]: (state, getters, rootState, rootGetters) => {
    state.loadChartDataPending = false
    state.loadChartDataError = false
  },
  [rootTypes.AUTH_LOAD_CHART_DATA_ASYNC_FAILURE]: (state, getters, rootState, rootGetters) => {
    state.loadChartDataPending = false
    state.loadChartDataError = true
  },
  [types.SET_HIDDEN_NETWORK_OPERATOR_IDS]: (state, hiddenOperators) => {
    state.hiddenOperators = hiddenOperators
  },
  [types.SET_CHART_NETWORK_OPERATOR_IDS]: (state, chartOperators) => {
    state.chartOperators = chartOperators
  },
  [types.SET_DISTRIBUTION_GROUPED]: (state, value) => {
    state.distributionGrouped = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
