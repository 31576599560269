<template>
  <div class="HighlightBar">
    <div class="HighlightBar__highlights">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'HighlightBar',
  components: {}
}
</script>
<style lang="scss">
  @import 'scss/variables';

  .HighlightBar {
    position: relative;
    &__highlights {
      @include xy-grid($direction: horizontal, $wrap: false);
      overflow-x: auto;
    }
  }
</style>
