<template>
  <div
    v-if="itemsData.length"
    class="NationalMetricResults"
  >
    <div class="NationalMetricResults__title">
      National Level | {{ location }}
    </div>

    <div class="NationalMetricResults__content">
      <div
        class="NationalMetricResults__header"
        :class="{
          'NationalMetricResults__header--first': isFirst && !isDraw,
          'NationalMetricResults__header--draw': isDraw && !isFirst,
          'NationalMetricResults__header--firstDraw': isDraw && isFirst,
        }"
      >
        <div
          class="NationalMetricResults__headerIcon"
          :style="{ backgroundImage: 'url(\'' + mainIconSrc + '\')' }"
        />
        <div
          v-if="heroData"
          class="NationalMetricResults__rank"
        >
          {{ rankLabel(heroData.rank) }}
        </div>

        <div
          v-if="isDraw"
          class="NationalMetricResults__draw"
        >
          DRAW
        </div>

        <div
          v-if="heroData"
          class="NationalMetricResults__value"
        >
          {{ heroData.mean }}
        </div>

        <div
          v-if="heroData"
          class="NationalMetricResults__extraInfo"
        >
          <div class="NationalMetricResults__unit">
            {{ unit }}
          </div>
          <div class="NationalMetricResults__confidence">
            {{ heroData.lci }} / {{ heroData.uci }}
          </div>
        </div>
      </div>

      <div class="NationalMetricResults__list">
        <div
          v-for="(item, index) in itemsData"
          :key="item.canonical_network_id"
          class="NationalMetricResults__listItem"
          :style="getListItemClass(index)"
        >
          <OperatorAvatar
            :letter="item.operatorInitial"
            :background-color="item.operatorColor"
          />
          {{ item.value }}
        </div>
      </div>

      <RankingTable
        class="NationalMetricResults__tooltip"
        :bigger-better="metric.bigger_is_better"
        :ranks="itemsData"
        :min-and-max="minAndMax(itemsData)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OperatorAvatar from '../OperatorAvatar'
import { metricMinAndMax } from '../visual/map/helpers'
import RankingTable from './RankingTable'
import { METRIC_TYPE_MAP } from '@/constants/constants'
import router from '@/router'
import { sortAscendent, sortDescendent } from '@/utils/data'
import { getRankLabel } from '@/utils/viewHelpers'

export default {
  name: 'NationalMetricResults',
  components: {
    OperatorAvatar,
    RankingTable
  },
  props: {
  },
  computed: {
    ...mapGetters([
      'nationalStats',
      'locations'
    ]),
    ...mapGetters({
      networks: 'operators',
      national: 'competitive/national',
      metric: 'metrics/primaryMetric',
      unit: 'metrics/primaryUnit',
      homeNetwork: 'charts/homeNetwork',
      selectedPoints: 'dashboard/selectedPoint'
    }),
    itemsData () {
      return this.networks && this.networks.length && this.nationalStats.filter(item => item.metric === router.currentRoute.value.params.metric).map(item => {
        let networkData = this.networks.find(op => op.canonical_network_id === item.canonical_network_id) || {
          hex_color: 'ccc',
          name_mapped: ' '
        }

        return {
          ...item,
          value: this.metric.structure === METRIC_TYPE_MAP.percentage_simple ? item.percentage : item.mean,
          operatorColor: '#' + networkData.hex_color,
          backgroundColor: '#' + networkData.hex_color,
          network: networkData.canonical_network_id,
          operatorInitial: networkData.name_mapped[0],
          operatorName: networkData.name_mapped,
          label: networkData.name_mapped
        }
      }).sort((a, b) => {
        if (a.rank && b.rank) {
          return sortAscendent(a.rank, b.rank)
        } else if (!a.rank && !b.rank) {
          if (this.metric.bigger_is_better) {
            return sortDescendent(a.mean, b.mean)
          }
          return sortAscendent(a.mean, b.mean)
        } else if (!a.rank) {
          return 1
        } else if (!b.rank) {
          return -1
        }
      })
    },
    mainIconSrc () {
      return require('../../assets/icons/' + this.metric.kind + (this.isFirst && !this.isDraw ? '.dark' : '') + '.svg')
    },
    heroData () {
      return this.itemsData.find(item => item.canonical_network_id === this.homeNetwork.canonical_network_id)
    },
    isFirst () {
      return this.heroData && this.heroData.rank === 1
    },
    isDraw () {
      return this.heroData && this.heroData.rank && this.itemsData.filter(item => item.rank === this.heroData.rank).length > 1
    },
    location () {
      return this.locations.find(l => l.key === router.currentRoute.value.params.countryid).name
    }
  },
  methods: {
    getListItemClass (index) {
      if (this.itemsData.length > 4) {
        return {
          flexBasis: 100 / Math.ceil(this.itemsData.length / 2) + '%'
        }
      }
    },
    minAndMax (item) {
      return metricMinAndMax(item, this.metric)
    },
    rankLabel (rank) {
      return getRankLabel(rank)
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  $small-space: 10px;
  $top-rounded-corners: 2px 2px 0 0;

  .NationalMetricResults {
    margin-top: 2*$small-space;

    &:hover {
      .NationalMetricResults__tooltip {
        display: block;
        background-color: #FFFFFF;
      }
    }

    &__title {
      font-size: $font-size-20;
      font-weight: $font-weight-roboto-medium;
      font-style: italic;
      text-transform: capitalize;
      margin-bottom: $small-space;
    }

    &__content {
      position: relative;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: calc($small-space / 2);
      background-image: $default-gradient;
      color: $color-white;
      border-radius: $top-rounded-corners;
      min-height: 37px;

      &--first {
        color: $color-nav-item-text;
        background-image: $winner-gradient;

        .NationalMetricResults__rank {
          color: $color-nav-item-text;
        }
      }
      &--firstDraw {
        background-image: $draw-gradient;

        .NationalMetricResults__rank {
          color: $color-white;
        }
      }
    }

    &__headerIcon {
      margin: 0 calc($small-space / 2);
      height: 26px;
      flex: 0 0 26px;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__rank {
      font-size: $font-size-32;
      font-style: italic;
      margin: 0 calc($small-space / 2);
      color: $gold;
    }

    &__draw {
      font-size: $font-size-18;
      font-style: italic;
      margin: 0 calc($small-space / 2) 0 0;
      padding-top: 9px;
    }

    &__value {
      margin: 0 $small-space * 2;
      font-size: $font-size-32;
      font-weight: 300;
    }

    &__extraInfo {
      font-size: $font-size-12;
      margin: 0 calc($small-space / 2);
    }

    &__unit {
      font-weight: 300;
    }

    &__tooltip {
      position: absolute;
      display: none;
      top: 50%;
      left: 100%;
      width: 100%;
      transform: translate(6px, -50%);
      box-shadow: 0 0 10px $color-nav-bg;
      background-color: $color-nav-bg;
      z-index: 2;
      overflow: hidden;

      @media screen and (max-width: $ci-breakpoint) {
        position: static;
        box-shadow: none;
        transform: initial;
      }
    }

    &__list {
      background-color: $color-white;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $color-blue-bg-light;
      }
    }

    &__listItem {
      flex: 1;
      padding: calc($small-space / 2);
      border-right: 1px solid $color-nav-bg;
      border-bottom: 1px solid $color-nav-bg;
      box-sizing: border-box;

      &:last-child,
      &--lastOnRow {
        border-right: 0;
      }

      .OperatorAvatar {
        width: 20px;
        height: 20px;
        line-height: $font-size-20;
        font-size: $font-size-12;
        margin-right: calc($small-space / 2);
      }
    }
  }
</style>
