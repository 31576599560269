<template>
  <div
    :class="BrandSelectorCssClass"
    class="BrandSelector"
  >
    <div
      :class="{ 'BrandSelector__input--active': isActive }"
      class="BrandSelector__input"
    >
      <span
        v-if="!displayNetwork"
        class="BrandSelector__placeholder"
      >
        Please select
      </span>

      <div
        v-else
        class="BrandSelector__selections"
      >
        <OperatorAvatar
          :key="displayNetwork.name_mapped"
          :letter="displayNetwork.name_mapped[0]"
          :background-color="`#${displayNetwork.hex_color}`"
        />
        <span>{{ displayNetwork.name_mapped }}</span>
      </div>

      <FontAwesomeIcon
        :icon="icons.faChevronRight"
        size="1x"
      />
    </div>
    <ul class="BrandSelector__options">
      <li
        v-for="network in networks"
        :key="network.newtworkLetter"
        :class="{
          'BrandSelector__item--active': isOptionActive(network),
        }"
        class="BrandSelector__item BrandSelector__item--selectable"
        @click="toggleOption(network)"
      >
        <OperatorAvatar
          :letter="network.name_mapped[0]"
          :background-color="`#${network.hex_color}`"
        />
        {{ network.name_mapped }}
      </li>
    </ul>
  </div>
</template>

<script>
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import OperatorAvatar from '@/components/OperatorAvatar'

export default {
  name: 'BrandSelector',
  components: {
    FontAwesomeIcon,
    OperatorAvatar
  },
  props: {
    theme: {
      type: String,
      default: ''
    },
    networks: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: null
    },
    isActive: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      icons: { faChevronRight },
      maxNetworksDisplayed: 7
    }
  },
  computed: {
    displayNetwork () {
      return this.findOperator(this.modelValue)
    },
    BrandSelectorCssClass () {
      if (this.theme) {
        return 'BrandSelector--' + this.theme
      }

      return ''
    }
  },
  methods: {
    findOperator (networkId) {
      return this.networks.find(network => parseInt(networkId) === parseInt(network.canonical_network_id))
    },
    updateValue (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    toggleOption (network) {
      this.updateValue(network.canonical_network_id)
    },
    isOptionActive (network) {
      return this.modelValue === network.canonical_network_id
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

.BrandSelector {
  position: relative;

  .OperatorAvatar {
    margin-right: 10px;
    border: 1px solid #fff;
  }

  &:hover {
    .BrandSelector__input {
        @extend %active;
    }
    .BrandSelector__options {
      display: block;
    }
  }

  &__placeholder {
    flex: 1;
  }

  &__input {
    @extend %controlElement;
    margin-bottom: 0;

    .svg-inline--fa + .svg-inline--fa {
      margin-left: 10px;
    }

    &--active.BrandSelector__input {
      @extend %active;
    }
  }

  &__selections {
    flex: 1;
  }

  &__options {
    display: none;
    position: absolute;
    top: -1px;
    left: 100%;
    width: 100%;
    z-index: 1;

    margin: 0 0 0 1px;
    padding: 0 0 .5rem;
    list-style: none;
  }

  &__item {
    @extend %controlElement;
    justify-content: flex-start;

    &--active.BrandSelector__item {
      @extend %active;
    }

    &:hover {
      @extend %active;
    }

    &--title.BrandSelector__item{
      font-style: italic;

      &:hover {
        @extend %controlElement;
        cursor: default;
      }
    }

    &--home.BrandSelector__item {
      @extend %active;
      background-color: grey;
    }

    &--selectable.BrandSelector__item:hover {
      cursor: pointer;
    }
  }

  &--mg {
    &:hover {
      .BrandSelector__input {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }

    .BrandSelector__input {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }

    .BrandSelector__options {
      background-color: $mg-base;
      padding: 0;
      top: 0;
    }

    .BrandSelector__item {
      @extend %controlElement-ci;
      background-color: $mg-base;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }

      &--title.BrandSelector__item{
        &:hover {
          @extend %controlElement-ci;
        }
      }

      &--active.BrandSelector__item--selectable {
        background: $active-mg;
        color: $mg-text-color;
        &:hover {
          background: $active-mg;
          color: $mg-text-color;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
