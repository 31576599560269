<template>
  <div class="MGMap">
    <template v-if="mapPending">
      <loader-grid size="large" />
    </template>

    <!-- Render map but don't display it until the stores have been loaded -->
    <template v-else>
      <div
        v-show="loadingStores || !geoJson"
        class="MGMap__storesLoader"
      >
        <loader-grid
          size="large"
        />
      </div>
      <base-map
        v-show="!loadingStores || !mapBox || !geoJson"
        ref="baseMap"
        :geo-json="geoJson"
        :max-bounds="mapBox"
        @visibleBoundsChange="setVisibleBounds"
        @mapReady="onMapReady"
      >
        <l-geo-json
          ref="geolayer"
          :geojson="geoJson"
          :options="geoJsonOptions"
        />

        <MapLegend />

        <MapTooltip
          :submarket="activeSubmarket"
        />
        <MapStoreTooltip :store="activeStore" />
      </base-map>
    </template>
  </div>
</template>

<script>
import 'leaflet.markercluster'
import { LGeoJson } from '@vue-leaflet/vue-leaflet'
import chroma from 'chroma-js'
import * as L from 'leaflet'
import { mapGetters, mapActions } from 'vuex'
import { MarkerClusterGroup } from '../../../../patches/leaflet.markercluster-src'
import MapLegend from './MapLegend'
import MapStoreTooltip from './MapStoreTooltip'
import MapTooltip from './MapTooltip'
import LoaderGrid from '@/components/LoaderGrid'
import BaseMap from '@/components/visual/map/BaseMap'
import { getMarkerIcon, getCustomClusterMarkerIcon } from '@/components/visual/map/helpers'
import colors from '@/utils/colorPalette'
import {
  getNetworksFromBrands,
  getStarRatingAsProperties,
  filterRatingsByLocation
} from '@/utils/market-growth'
import { getDeltaColor } from '@/utils/viewHelpers'

export default {
  name: 'MarketGrowthMap',
  components: {
    BaseMap,
    LGeoJson,
    LoaderGrid,
    MapLegend,
    MapTooltip,
    MapStoreTooltip
  },
  mapObject: null,
  props: {
    geoJson: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeSubmarket: null,
      activeStore: null,
      geoJsonOptions: null,
      markerMaxBounds: {},
      loadingStores: true,
      isProgramaticZoom: true
    }
  },
  computed: {
    ...mapGetters([
      'marketGrowthConfig',
      'locations',
      'mapData',
      'shapes',
      'dashboardInfo'
    ]),
    ...mapGetters({
      mapBox: 'marketGrowth/mapBox',
      displayStores: 'marketGrowth/displayStores',
      stores: 'marketGrowth/stores',
      mapPending: 'mapData/chartPending',
      metric: 'metrics/primaryMetricKey',
      starRatings: 'marketGrowth/starRatings'
    }),
    allNetworks () {
      return getNetworksFromBrands(this.dashboardInfo.brands)
    }
  },
  watch: {
    metric () {
      this.geoJsonOptions = this.getGeoJsonOptions()
    },
    mapPending (newValue, oldValue) {
      if (newValue) {
        return
      }

      this.displayMap()
    },
    displayStores (newValue, oldValue) {
      this.displayMap()
    },
    stores () {
      this.loadingStores = false

      if (!this.mapPending) {
        this.displayMap()
      }
    },
    geoJson (v) {
      if (!v) {
        return
      }
      if (!this.activeSubmarket) {
        return
      }
      const sameLocation = v.features.find(feature => feature.item[0].location === this.activeSubmarket.location)
      if (!sameLocation) {
        this.activeSubmarket = null
        return
      }
      this.activeSubmarket(sameLocation.item[0], true)
    }
  },
  mounted () {
    this.geoJsonOptions = this.getGeoJsonOptions()
  },
  methods: {
    ...mapActions([
      'setMarketGrowthMapBBox',
      'setMGParams'
    ]),
    onMapReady (mapObject) {
      this.$options.mapObject = mapObject

      this.displayMap()

      this.setVisibleBounds(mapObject.getBounds())

      this.fitMap()
    },
    displayMap () {
      this.manageLayers()
    },
    getNetworkColor (networkId) {
      const network = this.allNetworks.find(network => network.canonical_network_id === networkId)
      if (!network || !network.hex_color) {
        return `${colors.noDataColor}`
      }
      return `#${network.hex_color}`
    },
    getIcon (networkId) {
      return getMarkerIcon(this.getNetworkColor(networkId))
    },
    manageLayers () {
      if (!this.$options.mapObject || this.loadingStores) {
        return
      }
      if (this.citiesCluster) {
        this.$options.mapObject.removeLayer(this.citiesCluster)
      }
      if (!this.displayStores) {
        this.markerMaxBounds = null
        this.fitMap()
        return
      }

      const markers = this.stores.reduce((filtered, marker) => {
        if (marker.latitude == null || marker.longitude == null) {
          return filtered
        }
        return [...filtered, {
          id: JSON.stringify(marker), // Create marker id
          canonical_network_id: marker.canonical_network_id,
          centre: [marker.latitude, marker.longitude]
        }]
      }, [])

      this.createMarkerCluster('citiesCluster', markers, 6)

      this.fitMap()
    },
    fitMap () {
      this.$nextTick(() => {
        // Fix map size since it's hidden with css at first
        this.$options.mapObject.invalidateSize()
        const bounds = L.latLngBounds(this.$refs.baseMap.getBaseMapBounds())
        if (this.markerMaxBounds) {
          bounds.extend(this.markerMaxBounds)
        }
      })
    },
    async createMarkerCluster (cluster, points, zoomLevel) {

      this[cluster] = new MarkerClusterGroup({
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: true,
        iconCreateFunction: getCustomClusterMarkerIcon
      })

      points.map(m => {
        this[cluster].addLayer(L.marker(m.centre, {
          icon: this.getIcon(m.canonical_network_id),
          hex_color: this.getNetworkColor(m.canonical_network_id),
          interactive: true,
          zIndexOffset: 1,
          location: m.id
        }).on('click mouseover mouseout', e => {
          switch (e.type) {
            case 'mouseover':
              const storeId = e.target.options.location
              const store = this.stores.find(marker => JSON.stringify(marker) === storeId)
              this.activeStore = store
              break
            case 'mouseout':
              this.activeStore = null
              break
          }
        }))
      })

      this.$options.mapObject.addLayer(this[cluster])

      this.markerMaxBounds = this[cluster].getBounds()
    },
    getDataForLocation (id) {
      return this.geoJson.features.find(f => f.item[0].location === id)
    },
    setVisibleBounds (newBounds) {
      this.setMarketGrowthMapBBox(newBounds)
    },
    getGeoJsonOptions () {
      let metric = this.metric || this.marketGrowthConfig.metric

      return {
        style: feature => {
          const locationObject = this.getDataForLocation(feature.id)
          const fillColor = getDeltaColor(metric, locationObject.item[0])

          return {
            weight: 0.25,
            opacity: 1,
            color: '#000',
            fillOpacity: 1,
            fillColor: chroma(fillColor).alpha(0.7)
          }
        },
        onEachFeature: (feature, layer) => {
          layer.on({
            click: ({ target }) => {
              this.selectSubmarket(target.feature.item[0], true)
            },
            mouseover: ({ target }) => {
              this.selectSubmarket(target.feature.item[0])
            },
            mouseout: () => {
              if (this.activeSubmarket && !this.activeSubmarket.sticky) {
                this.activeSubmarket = null
              }
            }
          })
        }
      }
    },
    selectSubmarket (subMarket, sticky = false) {
      this.activeSubmarket = {
        sticky,
        ...subMarket,
        ...getStarRatingAsProperties(filterRatingsByLocation(this.starRatings, subMarket.location)),
        locationData: this.locations.find(l => l.key === subMarket.location.toString())
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import "~leaflet/dist/leaflet.css";

.MGMap {
    width: 100%;
    height: 100%;
    background-color: $mg-offbase;
    position: relative;

    .MGMapLegend {
      position: absolute;
      top: 0;
      right: 0;
    }
    .MGMapTooltip {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: $z-index-map-top-layer;
    }
}

.MGMap__storesLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $mg-offbase;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
