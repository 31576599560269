<template>
  <div>
    <ChartWrapper
      :title="focusChartData.title"
      :picture-meta="exportData"
      :selected-point="selectedPoint"
      :loading="chartPending"
      :empty="focusChartData.empty"
      :unit="chartUnit"
      :tooltip="focusChartData.tooltip"
      :title-labels="titleLabels"
      :show-confidence-rate="showConfidenceRate"
      toggle-layer
      toggle-numbers
      @toggle="chartToggle = !chartToggle"
      @numbers="chartNumbers = !chartNumbers"
    >
      <group-bar-chart
        v-if="focusChartData.loaded"
        :data-set="focusChartData.data"
        :max="focusChartData.max"
        :min="focusChartData.min"
        :labels="focusChartData.labels"
        :x-axis-label="focusChartData.xAxisLabel"
        :y-axis-label="focusChartData.yAxisLabel"
        :rainbow="chartToggle"
        :bar-labels="chartNumbers"
        :show-confidence-rate="showConfidenceRate"
        chart-id="cpfocus"
        show-vertical-labels
      />
    </ChartWrapper>

    <div
      v-for="cpChart in chartData"
      :key="cpChart.chartId"
    >
      <ChartWrapper
        :title="cpChart.title"
        :tooltip="focusChartData.tooltip"
        :picture-meta="exportData"
        :selected-point="cpSelectedPoints[cpChart.chartId]"
        :loading="chartPending"
        :empty="cpChart.empty"
        :show-confidence-rate="showConfidenceRate"
        :title-labels="titleLabels"
        :unit="chartUnit"
        :y-zoom="chartYZoom"
        enable-y-zoom
        :display-frm="displayFrm"
        :chart-data="cpChart"
        @toggleYZoom="chartYZoom = !chartYZoom"
      >
        <line-chart
          v-if="cpChart.loaded"
          :date="date"
          :data-set="cpChart.data"
          :chart-id="cpChart.chartId"
          :x-axis-label="cpChart.xAxisLabel"
          :y-axis-label="cpChart.yAxisLabel"
          :y-axis-unit="cpChart.yAxisUnit"
          :end-date="dashboardInfo.last_date_available"
          :show-confidence-rate="showConfidenceRate"
          :sync-range="syncRange"
          :y-zoom="chartYZoom"
          @point="navigateDate"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GroupBarChart, LineChart, ChartWrapper } from '@/components/visual'
import router from '@/router'
import { getLinechartRange } from '@/utils/charts'

export default {
  name: 'ChartCp',
  components: {
    ChartWrapper,
    GroupBarChart,
    LineChart
  },
  beforeRouteUpdate (to, from, next) {
    if (router.currentRoute.value.params.chart !== to.params.chart) {
      this.chartYZoom = false
    }

    next()
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false,
      chartYZoom: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/getByCpCharts',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      focusChartData: 'charts/getCpFocusChart',
      range: 'charts/cpRange',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint',
      cpSelectedPoints: 'dashboard/cpSelectedPoints',
      displayFrm: 'chartFrm/displayFrm'
    }),
    exportData () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    syncRange () {
      return getLinechartRange(...this.range, 30)
    }
  },
  methods: {
    navigateDate (date) {
      this.$router.push({
        name: router.currentRoute.value.name,
        params: {
          hero: this.hero,
          chart: this.chart,
          location: this.location,
          agg: this.agg,
          date
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
