<template>
  <ul class="PerformanceTabs">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'PerformanceTabs',
  props: { }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';

  .PerformanceTabs {
    @include tabs-container($background: transparent, $border-color: none);
    min-height: 32px;
  }
</style>
