<template>
  <div
    :class="{
      'Card--first': isFirst && !isDraw,
      'Card--draw': isDraw && !isFirst,
      'Card--firstDraw': isDraw && isFirst,
    }"
    class="Card"
  >
    <div>
      <div class="Card__wrapper">
        <div
          v-click-outside="() => { dropdownOpen = false }"
          :class="{'Card__header--expanded': dropdownOpen, 'Card__header--clickable': displayDropdown }"
          class="Card__header"
          @click="displayDropdown ? dropdownOpen = !dropdownOpen : null"
        >
          <div
            class="Card__headerLogo"
            :style="{ backgroundImage: 'url(\'' + mainIconSrc + '\')' }"
          />

          <div class="Card__headerTitle">
            {{ title }}

            <img
              v-if="isAwardMetric"
              class="Card__headerAward"
              src="../../assets/product/award.svg"
            >
          </div>

          <div
            v-if="displayDropdown"
            class="Card__headerChevron"
          >
            <FontAwesomeIcon
              :icon="icons.faChevronDown"
              size="lg"
            />
          </div>

          <div
            v-if="dropdownOpen"
            class="Card__headerDropDown"
          >
            <div
              v-for="metricOption in options"
              :key="metricOption.key"
              class="Card__headerDropDownItem"
              @click="select(metricOption)"
            >
              {{ metricOption.name }}
            </div>
          </div>
        </div>

        <div
          v-if="heroData"
          class="Card__hero"
        >
          <div class="Card__media--left">
            <div class="Card__heroRankLabel">
              RANK
            </div>
            <div class="Card__heroRankPosition">
              {{ rankLabel(heroData.rank) }}
            </div>
            <div
              v-if="isDraw && heroData.rank"
              class="Card__drawLabel"
            >
              DRAW
            </div>
          </div>

          <div class="Card__media--right">
            <div class="pull-left">
              <div class="Card__heroValue">
                {{ heroData.value }}
              </div>
              <div class="Card__unit">
                {{ unit }}
              </div>
            </div>
            <div class="pull-right text-right">
              <div class="Card__period">
                {{ compareToLabel }}
              </div>
              <div class="Card__oldValue">
                {{ heroData.previous || '-' }}
              </div>
              <div class="Card__delta">
                {{ getDelta(heroData.comparisonMean) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!heroData && !loading"
          class="Card__hero__no-data"
        >
          <div v-if="granularity !== '1' && metricKey === 'coverage'">
            <span>Available for National level only</span>
          </div>
          <div v-else>
            <span>No data available</span>
          </div>
        </div>
        <div
          v-if="!heroData && loading"
          class="Card__hero__no-data"
        >
          <span>Loading...</span>
        </div>
      </div>
      <div
        v-if="ranks.length"
        class="Card__content"
        @click="$emit('navigate')"
      >
        <RankingTable
          :bigger-better="biggerBetter"
          :ranks="ranks"
          :min-and-max="minAndMax"
        />
        <div class="Card__linkContent">
          <div>check details</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import RankingTable from './RankingTable'
import { COMPARE_TO, METRIC_TYPES } from '@/constants/constants'
import router from '@/router'
import { sortAscendent, sortDescendent } from '@/utils/data'
import { numberToTwoDecimals } from '@/utils/filters'
import { getRankLabel } from '@/utils/viewHelpers'

export default {
  name: 'Card',
  components: {
    FontAwesomeIcon,
    RankingTable
  },
  props: {
    title: { type: String, default: undefined },
    metricKey: { type: String, default: undefined },
    metricType: { type: String, default: undefined },
    ranks: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    biggerBetter: {
      type: Boolean,
      default: true
    },
    granularity: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    userGroup: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: { faChevronDown },
      dropdownOpen: false,
      compareToOptions: COMPARE_TO
    }
  },
  computed: {
    ...mapGetters({
      homeNetwork: 'charts/homeNetwork',
      chartPending: 'overviewStats/chartPending'
    }),
    loading () {
      return this.isLoading || this.chartPending
    },
    displayDropdown () {
      return this.options.length > 1
    },
    isAwardMetric () {
      if (this.metricKey !== 'availability') {
        return false
      }
      switch (this.metricType) {
        case METRIC_TYPES.All:
          return this.userGroup === 'main'
        case METRIC_TYPES.FiveG:
          return this.userGroup === '5guser'
        case METRIC_TYPES.FourG:
          return this.userGroup === '4guser'
        default:
          return false
      }
    },
    unit () {
      if (!this.options.length) return ''

      switch (this.options[0].units.short) {
        case '0 - 100':
          return '0 - 100 points'
        case '0 - 10':
          return '0 - 10 points'
        case '%':
          return '% of time'
        default:
          return this.options[0].units.short
      }
    },
    minAndMax () {
      const defaltMinMax = {
        min: 0,
        max: 100
      }

      if (!this.options.length) return defaltMinMax

      const variableMinMaxMetrics = ['speed', 'responsiveness']

      if (variableMinMaxMetrics.includes(this.options[0].category)) {
        return {
          min: Math.min(...this.ranks.map(a => a.lci)) * 0.9,
          max: Math.max(...this.ranks.map(a => a.uci)) * 1.1
        }
      }

      if (this.options[0].units.short === '0 - 10') {
        return { min: 0, max: 10 }
      }

      return defaltMinMax
    },
    mainIconSrc () {
      return require('../../assets/icons/' + this.metricKey + (this.isFirst && !this.isDraw ? '.dark' : '') + '.svg')
    },
    isFirst () {
      return this.heroData && this.heroData.rank === 1
    },
    isDraw () {
      const buchet = this.rankBuckets.find(i => i.length && this.heroData && i[0].rank === this.heroData.rank)
      return this.heroData && buchet && buchet.length > 1
    },
    heroData () {
      if (this.homeNetwork) {
        return this.ranks.find(i => i.operatorName === this.homeNetwork.name_mapped)
      } else {
        return this.ranks.find(i => i.canonicalNetworkId === -1) || {}
      }
    },
    rankBuckets () {
      if (!this.ranks.length) return [[]]
      return Object.values(this.ranks.reduce((ac, item) => {
        if (item.rank) {
          if (!ac[item.rank]) {
            ac[item.rank] = []
          }

          ac[item.rank].push(item)
        } else {
          ac['X-' + item.operatorName] = [item]
        }

        return ac
      }, {})).sort((a, b) => {
        if (a[0].rank && b[0].rank) {
          return sortAscendent(a[0].rank, b[0].rank)
        } else if (!a[0].rank && !b[0].rank) {
          if (this.biggerBetter) {
            return sortDescendent(a[0].value, b[0].value)
          }
          return sortAscendent(a[0].value, b[0].value)
        } else if (!a[0].rank) {
          return 1
        } else if (!b[0].rank) {
          return -1
        }
      })
    },
    compareToLabel () {
      return this.compareToOptions.find(item => item.value === router.currentRoute.value.params.compareTo).tileLabel
    }
  },
  methods: {
    select (o) {
      this.$emit('metric', o)
    },
    rankLabel (rank) {
      return getRankLabel(rank)
    },
    rankClass (comparisonRank, baseClass) {
      if (Number.isFinite(comparisonRank)) {
        if (comparisonRank < 0) {
          return baseClass + '--diff ' + baseClass + '--up'
        } else if (comparisonRank > 0) {
          return baseClass + '--diff ' + baseClass + '--down'
        }
      }
    },
    getDelta (value) {
      if (!value) return '-'

      return value < 0 ? `(${value})` : `(+${value})`
    },
    formatNumberTo2Decimals (value) {
      return this.numberToTwoDecimals(value)
    }
  }
}
</script>

<style  lang="scss">
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';

@mixin extraSpace($isAdded: true) {
  $multiplier: 1;

  @if $isAdded {
    $multiplier: 2
  }

  .Card__content {
    padding: (($multiplier - 1) * $small-space) 0;
  }

  .RankingTable__itemWrapper {
    &:first-child {
      .RankingTable__stats {
        &::before {
          top: -$multiplier * ($small-space);
        }
      }
    }

    &:last-child {
      .RankingTable__stats {
        &::before {
          bottom: -$multiplier * ($small-space);
        }
      }
    }
  }
}

// TODO remove
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-right {
  text-align: right;
}

$min-card-size: 335px;
$large-space: 20px;
$small-space: 10px;
$top-rounded-corners: 2px 2px 0 0;
$border-color: #D6DAE6;

@include extraSpace();

.Card {
  padding: $large-space;
  width: 33.33%;
  min-width: $min-card-size;
  box-sizing: border-box;

  @media screen and (max-width: $ci-breakpoint-tablet) {
    padding: $large-space 0;
  }

  &__wrapper {
    background-image: $default-gradient;
    color: $color-white;
    border-radius: $top-rounded-corners;
  }
  &--first {
    .Card__wrapper {
      color: $color-nav-item-text;
      background-image: $winner-gradient;
    }
  }
  &--firstDraw {
    .Card__wrapper {
      background-image: $draw-gradient;
    }
  }
  &__media--left {
    flex: 0 0 120px;
  }
  &__media--right {
    flex: 1;
    padding-left: $small-space;
  }
  &__header {
    display: flex;
    align-items: center;
    background-color: rgba($color-nav-item-text, .2);
    height: 40px;
    position: relative;
    border-radius: $top-rounded-corners;

    &--clickable:hover,
    &--expanded {
      background-color: rgba($color-white, .3);
    }
  }
  &__headerLogo {
    margin: 0 $small-space;
    height: 26px;
    flex: 0 0 26px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__headerTitle {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__headerChevron {
    flex: 0;
    height: 40px;
    padding: 0 $large-space;
    font-size: $font-size-12;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
    box-sizing: border-box;

    .Card__header--expanded & {
      transform: rotate(180deg);
    }
  }
  &__headerDropDown {
    position: absolute;
    top: 40px;
    background-color: $ci-dark-blue;
    width: 100%;
    color: $color-white;
    padding-bottom: 1px;
    font-size: $font-size-14;
    z-index: 12;
  }
  &__headerDropDownItem {
    margin-top: 1px;
    padding-left: 40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba($color-white, .1);

    &:hover {
      background-color: rgba($color-white, .2);
    }
  }
  &__hero {
    display: flex;
    padding: $small-space $large-space;
    height: 60px;
    &__no-data {
      padding: 20px;
      opacity: 0.7;
    }
  }
  &__heroRankLabel {
    font-size: $font-size-12;
    opacity: .7;

    // TODO change to intenal modifier
    .Card--firstDraw &, .Card--first & {
      color: $color-nav-item-text;
    }
  }
  &__heroRankPosition {
    font-size: $font-size-32;
    font-style: italic;
    color: $gold;

    .Card--firstDraw &, .Card--first & {
      color: $color-nav-item-text;
    }
    .Card--draw & {
      font-size: $font-size-28;
    }
  }
  &__heroValue {
    font-size: $font-size-46;
    font-weight: $font-weight-roboto-light;
  }
  &__drawLabel {
    font-size: $font-size-18;
    font-style: italic;

    .Card--firstDraw &, .Card--first & {
      color: $color-nav-item-text;
    }
  }
  &__unit {
    font-size: $font-size-12;
  }
  &__info {
    margin: 5px;
    display: inline;
  }
  &__period {
    font-size: $font-size-12;
    line-height: $font-size-10;
    margin-bottom: $small-space;
    opacity: .7;
    text-transform: uppercase;
  }
  &__oldValue {
    font-size: $font-size-18;
    line-height: $font-size-20;
    margin-bottom: $small-space;
  }
  &__delta {
    font-size: $font-size-14;
    opacity: .7;
    line-height: $font-size-10;
  }
  &__content {
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    position: relative;

    &:hover {
      cursor: pointer;

      .Card__linkContent {
        display: block;
      }
    }
  }

  &__linkContent {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -34px;
    border: 5px solid $winner-gold;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      display: block;
    }

    div {
      background-color: $winner-gold;
      text-align: center;
      padding: 8px 0 3px;
      line-height: 18px;
      position: absolute;
      bottom: 0;
      width: 100%;

      &:before {
        display: inline-block;
        background: url('../../assets/icons/chart.svg') no-repeat 50% 50%/contain;
        width: 18px;
        height: 18px;
        content: "";
        margin: 0 3px -3px 0;
      }
    }
  }

  &__headerAward {
    margin-left: 10px;
    height: 30px;
  }
}

@media screen and (max-width: 1625px) {
  @include extraSpace(false);

  .Card__hero {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .Card {
    &__media--left {
      flex: 1;
    }

    &__media--right {
      flex: 2; //2.2 //1.7
    }
  }
}

@media screen and (max-width: 1525px) {
  @include extraSpace();

  .Card__hero {
    padding-left: $large-space;
    padding-right: $large-space;
  }

  .Card {
    width: 50%;
  }

  .Card__heroValue {
    font-size: $font-size-32;
    line-height: $font-size-52;
  }
}

@media screen and (max-width: 1100px) {
  @include extraSpace(false);

  .Card__hero {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .Card__heroValue {
    font-size: $font-size-32;
    line-height: $font-size-52;
  }
}

@media screen and (max-width: 1023px) {
  @include extraSpace();

  .Card {
    width: 100%;
  }

  .Card__hero {
    padding-left: $large-space;
    padding-right: $large-space;
  }

  .Card__heroValue {
    font-size: $font-size-40;
  }
}

@media screen and (max-width: 740px) {
  @include extraSpace(false);

  .Card__hero {
    padding-left: $small-space;
    padding-right: $small-space;
  }

  .Card__heroValue {
    font-size: $font-size-32;
    line-height: $font-size-52;
  }
}
</style>
