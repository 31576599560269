<template>
  <div
    :style="`maxWidth: ${maxWidth}`"
    class="CustomText"
    @click="e => $emit('click', e)"
  >
    <div class="CustomText__wrapper">
      <div
        :class="textClass"
        class="CustomText__text"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomText',
  props: {
    truncate: { type: Boolean, default: false },
    capitalize: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    maxWidth: { type: String, default: 'auto' }
  },
  computed: {
    textClass () {
      const elementClassName = 'CustomText__text'
      return {
        [`${elementClassName}--truncate`]: this.truncate,
        [`${elementClassName}--capitalize`]: this.capitalize,
        [`${elementClassName}--small`]: this.small
      }
    }
  }
}
</script>

<style lang="scss">
  .CustomText {
    display: flex;

    &__wrapper {
      flex: 1;
      min-width: 0;
    }

    &__text {
      &--truncate {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--capitalize {
        text-transform: capitalize;
      }

      &--small {
        font-size: 0.6rem;
      }
    }
  }
</style>
