export const getNetworksFromBrands = (brands) => {
  const networks = []
  const addBrand = brand => {
    if (networks.some(network => network.canonical_network_id === brand.canonical_network_id)) {
      return
    }
    networks.push(brand)
  }
  brands.forEach(brand => {
    addBrand(brand)
    brand.competitors.forEach(competitor => addBrand(competitor))
  })
  return networks
}

export const getDefaultGranularity = (dashboardInfo) => {
  if (!dashboardInfo || !dashboardInfo.geoconfigs_available) {
    return null
  }
  const geoConfig = dashboardInfo.geoconfigs_available
    .find(geoconfig => geoconfig.id !== dashboardInfo.parent_geocoding)
  if (!geoConfig) {
    return null
  }
  return geoConfig.id
}

export const getSelectableGranularities = (dashboardInfo) => {
  if (!dashboardInfo || !dashboardInfo.geoconfigs_available) {
    return null
  }
  return dashboardInfo.geoconfigs_available.filter(geoconfig => geoconfig.id !== dashboardInfo.parent_geocoding)
}

export const filterRatingsByLocation = (ratings, location) => {
  return ratings.filter(rating => parseInt(rating.location) === parseInt(location))
}

export const getStarRatingAsProperties = (ratings) => {
  const ratingProperties = {}

  ratings.forEach(rating => {
    ratingProperties[rating.canonical_network_id + '-' + rating.category] = ratingProperties[rating.canonical_network_id + '-' + rating.category] || {}
    ratingProperties[rating.canonical_network_id + '-' + rating.category][rating.technology] = rating.stars

    ratingProperties[rating.canonical_network_id + '-' + 'all_stars'] = ratingProperties[rating.canonical_network_id + '-' + 'all_stars'] || {}

    ratingProperties[rating.canonical_network_id + '-' + 'all_stars'][rating.technology] = ratingProperties[rating.canonical_network_id + '-' + 'all_stars'][rating.technology] || null

    if (rating.stars !== null) {
      ratingProperties[rating.canonical_network_id + '-' + 'all_stars'][rating.technology] += rating.stars
    }
  })

  return ratingProperties
}
