<template>
  <div class="MG">
    <MarketGrowthLayout>
      <template #sidebar>
        <MarketGrowthMenu
          :products="products"
          :dashboard-info="dashboardInfo"
          :options="{dashboard: 'market-growth'}"
          :licences="user.licences"
          :dashboards="user.dashboards"
          @signout="signOut"
          @navigate="updateDashboard"
        />
      </template>

      <template #content>
        <router-view />
      </template>
    </MarketGrowthLayout>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import MarketGrowthLayout from '@/components/market-growth/MarketGrowthLayout'
import { MarketGrowthMenu } from '@/components/specialized'
import ROUTES from '@/constants/routes'
import router from '@/router'
import colors from '@/utils/colorPalette'
import { getDefaultGranularity } from '@/utils/market-growth'

export default {
  name: 'MarketGrowth',
  components: {
    MarketGrowthLayout,
    MarketGrowthMenu
  },
  beforeRouteUpdate (to, from, next) {
    this.checkDisclaimer()
    if (from.name === to.name) {
      this.track({
        dashboard: 'market-growth',
        params: to.params,
        url: to.path,
        route: to.name
      })
    }
    next()
  },
  data () {
    return {
      ROUTES: ROUTES
    }
  },
  computed: {
    ...mapGetters([
      'marketGrowthConfig',
      'dashboardInfo',
      'user',
      'locations'
    ]),
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible',
      products: 'auth/getProducts'
    })
  },
  mounted () {
    if (!this.user || !this.user.id) {
      this.setUser().then(res => res && this.checkDisclaimer())
    }
    if (router.currentRoute.value.path === '/' || router.currentRoute.value.path === '/market-growth/' || router.currentRoute.value.path === '/market-growth') {
      this.setUserSettings('market-growth').then(() => {
        // get stored submarkets from local storage
        this.$router.replace({
          name: ROUTES.MarketGrowthOverview,
          params: {
            country: this.locations[0].iso3,
            strategy: this.marketGrowthConfig.strategy
          }
        })
      })
    }
    this.hideMenuIfSmallScreen()
    window.addEventListener('resize', this.hideMenuIfSmallScreen)
  },
  methods: {
    ...mapActions([
      'track',
      'setUser',
      'setUserSettings',
      'checkDisclaimer',
      'resetUser'
    ]),
    ...mapActions({
      toggleMenu: 'dashboard/toggleMenu'
    }),
    hideMenuIfSmallScreen () {
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const isSmallScreen = viewportWidth <= parseInt(colors.ciBreakpoint, 10)
      if ((this.isMenuVisible && isSmallScreen) || (!this.isMenuVisible && !isSmallScreen)) {
        this.toggleMenu(false)
      }
    },
    signOut () {
      this.resetUser()
      this.$router.replace({
        name: ROUTES.Login
      })
    },
    updateDashboard ({
      granularity = getDefaultGranularity(this.dashboardInfo),
      metric = this.marketGrowthConfig.metric,
      brand = this.dashboardInfo.brands[0].canonical_network_id,
      competitor = this.dashboardInfo.brands[0].competitors[0].canonical_network_id,
      strategy = this.marketGrowthConfig.strategy
    }) {
      if (router.currentRoute.value.name === ROUTES.MarketGrowthOverview) {
        this.$router.replace({
          name: ROUTES.MarketGrowthOverview,
          params: {
            strategy
          }
        })
        return
      }

      this.$router.replace({
        name: ROUTES.MarketGrowthDetails,
        params: {
          country: this.locations[0].iso3,
          granularity,
          metric,
          strategy,
          brand,
          competitor
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .MG {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
</style>
