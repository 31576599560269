<template>
  <div>
    <IpTable
      :columns="columns"
      :loading="chartPending"
      :formatted-data="formattedData"
    >
      <template #headerTools>
        <CsvExport @click="exportData" />
      </template>
    </IpTable>
    <div
      v-for="trendChart in trendCharts"
      :key="trendChart.id"
    >
      <ChartWrapper
        :title="getGroupTitle(trendChart)"
        :loading="chartPending"
        :empty="formattedData.length === 0"
        :disable-export="true"
        :title-labels="titleLabels.chartTitle"
        unit="%"
        legend-title="Cloud Providers"
      >
        <stacked-dist
          :data-set="trendChart.data"
          :labels="trendChart.labels"
          y-axis-label="IP utilization by CDN by operator (%)"
          :chart-id="`${trendChart.id}`"
          tooltip-metric="IP Address"
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import CsvExport from '@/components/CsvExport'
import IpTable from '@/components/performance/IpTable'
import { ChartWrapper, StackedDist } from '@/components/visual'
import { CP_LABELS } from '@/constants/constants'
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats'
import router from '@/router'
import { getRangeFromSelectedDate, getSafeDate } from '@/utils/date'
import { exportToCsv } from '@/utils/files'
import { formatIpDataForDistCharts, formatIpTableData, mergeDataByIps } from '@/utils/ip'

export default {
  name: 'ChartCpIp',
  components: {
    IpTable,
    ChartWrapper,
    StackedDist,
    CsvExport
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      date: 'dashboard/date',
      chartData: 'chart/cpip',
      networks: 'charts/networks',
      hiddenNetworkIds: 'charts/hiddenNetworksIds',
      chartPending: 'chart/chartPending',
      primaryMetricKey: 'metrics/primaryMetricKey',
      primaryMetric: 'metrics/primaryMetric',
      titleLabels: 'charts/titleLabels',
      currentLocation: 'locations/current',
      chartMetric: 'chart/metric'
    }),
    dataForSelectedDate () {
      return this.chartData.filter(scan => scan.date === this.date)
    },
    chartTitle () {
      return this.titleLabels.chartTitle
        ? this.titleLabels.chartTitle.fullTitle
        : this.titleLabels.fullTitle
    },
    columns () {
      return [
        {
          label: 'Operator',
          key: 'operator'
        },
        {
          label: 'End Point',
          key: 'endPoint'
        },
        {
          label: 'IP',
          key: 'ip'
        },
        {
          label: '% Of Total Tests',
          key: 'percentageOfTests'
        },
        {
          label: this.metricData.title,
          key: 'value'
        }
      ]
    },
    metricData () {
      return this.getMetricData(this.primaryMetric.subtype)
    },
    formattedData () {
      return formatIpTableData(this.dataForSelectedDate, CP_LABELS, this.getNetwork)
    },

    trendCharts () {
      const [selectedStartDate, selectedEndDate] = getRangeFromSelectedDate(this.date, 30, this.dashboardInfo.last_date_available)
      const trendCharts = formatIpDataForDistCharts(this.chartData, selectedStartDate, selectedEndDate)
      return trendCharts.filter(trendChart => Boolean(this.getGroupTitle(trendChart)))
    }
  },
  methods: {
    ...mapActions([
      'getMetricsData'
    ]),
    getGroupTitle (group) {
      const [canonicalNetworkId, endpoint] = group.id.split('-')
      const network = this.getNetwork(canonicalNetworkId)
      if (!network) {
        return null
      }
      return `${network.name_mapped} - ${CP_LABELS[endpoint]}`
    },
    getNetwork (id) {
      return this.networks.find(network => network.canonical_network_id === parseInt(id))
    },
    getMetricData (metricSybtype) {
      switch (metricSybtype) {
        case 'cpipgamesrtt':
          return { title: 'Mean Packet Delay (in ms)', unit: 'ms' }
        case 'cpipgamespacketloss':
          return { title: 'Mean Packet Loss (in %)', unit: '%' }
        case 'cpipgamesjitter':
          return { title: 'Jitter', unit: '' }
        default:
          return { title: '', unit: '' }
      }
    },
    async exportData () {
      const technology = this.chartMetric.type
      const params = router.currentRoute.value.params
      const responses = await this.getMetricsData({
        metrics: [
          `cpipgamesrtt_${technology}`,
          `cpipgamespacketloss_${technology}`,
          `cpipgamesjitter_${technology}`
        ],
        location: params.location,
        agg: params.agg,
        date: this.date,
        dashboard: 'performance'
      })
      const allMetricsData = []
      responses.forEach(metricData => {
        const formattedData = formatIpTableData(metricData.results, CP_LABELS, this.getNetwork, metricData.metric)
        allMetricsData.push(...formattedData)
      })
      const mergedData = mergeDataByIps(allMetricsData)
      this.exportFormattedData(Object.values(mergedData))
    },
    exportFormattedData (formattedData) {
      if (!formattedData || formattedData.length === 0) {
        return
      }
      const titles = [
        'Operator',
        'End Point',
        'IP',
        '% OF Total Tests',
        'Mean Packet Delay (in ms)',
        'Mean Packet Loss (in %)',
        'Jitter',
        'Date'
      ]
      const exportData = formattedData.map(data => ([
        data.operator,
        data.endPoint,
        data.ip,
        data.percentageOfTests,
        data.cpipgamesrtt || '',
        data.cpipgamespacketloss || '',
        data.cpipgamesjitter || '',
        format(getSafeDate(data.date), API_LONG_DATE_FORMAT)
      ]))
      const date = getSafeDate(formattedData[0].date)
      const fileTitle = 'Games Experience By Cloud Provider IP Address ' + format(date, API_LONG_DATE_FORMAT) + '.csv'
      exportToCsv(fileTitle, [titles, ...exportData])
    }
  }
}
</script>
