<template>
  <div
    ref="CompetitiveOverview"
    class="CompetitiveOverviewLayout"
  >
    <div class="CompetitiveOverviewLayout__header">
      <HtmlImageExport
        class="CompetitiveOverviewLayout__export"
        :reference="$refs.CompetitiveOverview"
        :height="exportHeight"
        :title="exportTitle"
      />
      <slot name="header" />
    </div>
    <div class="CompetitiveOverviewLayout__competitiveTiles">
      <slot name="competitiveTiles" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HtmlImageExport from '@/components/HtmlImageExport'

export default {
  name: 'CompetitiveOverviewLayout',
  components: {
    HtmlImageExport
  },
  props: {
    // We can't get this number any other way because $slots isn't reactive
    numberOfTiles: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'operators'
    ]),
    ...mapGetters({
      date: 'auth/getLastDateAvailable',
      location: 'location/currentLocation'
    }),
    exportHeight () {
      const noTiles = this.numberOfTiles
      const noRows = Math.ceil(noTiles / 3)

      const HEADER = 180 + 50
      const TILE_HERO = noRows * 145
      const ELEMENTS = noRows * this.operators.length * 50
      return HEADER + TILE_HERO + ELEMENTS
    },
    exportTitle () {
      return `CI Overview ${this.location.name} ${this.date.substring(0, 10)}`
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import '~foundation-sites/scss/foundation';
.CompetitiveOverviewLayout {
  padding-top: 30px;

  &__header {
    padding: 0 20px;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      padding: 0;
    }
  }

  &__competitiveTiles {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__export {
    position: absolute;
    right: 45px;
    top: 65px;
  }

  .Card__content .RankingTable {
    overflow: hidden;
  }
}
</style>
