<template>
  <div
    class="loader-grid"
    :class="theme && 'loader-grid--' + theme"
  >
    <div
      :class="classNames"
      class="lds-grid"
    >
      <div /><div /><div /><div /><div /><div /><div /><div /><div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderGrid',
  props: {
    size: { type: String, default: 'medium' }, // ['small', 'large']
    theme: { type: String, default: null }
  },
  computed: {
    classNames () {
      const { size } = this
      return {
        [`lds-grid--${size}`]: size
      }
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';

  .loader-grid {
    &--dark.loader-grid {
      .lds-grid div {
        background: #666;
        animation: lds-grid-dark 1.2s linear infinite;
      }
    }

    .lds-grid {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
      zoom: 0.5;

      &--small { zoom: 0.25 }
      &--medium { zoom: 0.5 }
      &--large { zoom: 2}
    }

    .lds-grid div {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff;
      animation: lds-grid 1.2s linear infinite;
    }
    .lds-grid div:nth-child(1) {
      top: 6px;
      left: 6px;
      animation-delay: 0s;
    }
    .lds-grid div:nth-child(2) {
      top: 6px;
      left: 26px;
      animation-delay: -0.4s;
    }
    .lds-grid div:nth-child(3) {
      top: 6px;
      left: 45px;
      animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(4) {
      top: 26px;
      left: 6px;
      animation-delay: -0.4s;
    }
    .lds-grid div:nth-child(5) {
      top: 26px;
      left: 26px;
      animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(6) {
      top: 26px;
      left: 45px;
      animation-delay: -1.2s;
    }
    .lds-grid div:nth-child(7) {
      top: 45px;
      left: 6px;
      animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(8) {
      top: 45px;
      left: 26px;
      animation-delay: -1.2s;
    }
    .lds-grid div:nth-child(9) {
      top: 45px;
      left: 45px;
      animation-delay: -1.6s;
    }
    @keyframes lds-grid {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
    @keyframes lds-grid-dark {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: 0.25;
      }
    }
  }
</style>
