<template>
  <div
    v-click-outside="() => expanded = false"
    class="SelectInput"
    :class="selectInputCssClass"
  >
    <div class="SelectInput__targetWrapper">
      <div
        class="SelectInput__inputWrapper"
        @mouseenter="isMobile ? null : expanded = !disabled"
        @mouseleave="isMobile ? null : expanded = false"
        @click="expanded = !expanded"
      >
        <span>{{ selectionText }}</span>

        <FontAwesomeIcon
          v-if="!disabled"
          :icon="isMobile ? icons.faChevronDown : icons.faChevronRight"
          size="1x"
        />

        <div
          v-if="expanded"
          class="SelectInput__options"
        >
          <div
            v-for="option in options"
            :key="option.value"
            :class="{'SelectInput__option--active': isActive(option.value)}"
            class="SelectInput__option"
            @click="onOptionSelected($event, option)"
          >
            <template v-if="hasCustomSlot">
              <slot
                name="option"
                :option="option"
              />
            </template>
            <span v-else>
              {{ option.label }}
            </span>
          </div>
        </div>
      </div>

      <slot name="tooltip">
        <CustomTooltip
          v-if="tooltip"
          :message="tooltip"
        >
          <QuestionMark :theme="theme" />
        </CustomTooltip>
      </slot>
    </div>
  </div>
</template>

<script>
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import CustomTooltip from '../tooltip/CustomTooltip'
import QuestionMark from '../tooltip/QuestionMark'

export default {
  name: 'SelectInput',
  components: {
    FontAwesomeIcon,
    CustomTooltip,
    QuestionMark
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    tooltip: {
      type: String,
      default: null
    },
    selectedValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectionText: '',
      expanded: false,
      icons: {
        faChevronDown,
        faChevronRight
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'page/isMobile'
    }),
    hasCustomSlot () {
      return Boolean(this.$slots?.option)
    },
    selectInputCssClass () {
      let cssClasses = {}

      if (this.theme) {
        cssClasses['SelectInput--' + this.theme] = true
      }

      cssClasses['SelectInput--disabled'] = this.disabled

      return cssClasses
    }
  },
  watch: {
    selectedValue () {
      this.setSelected()
    },
    options () {
      this.setSelected()
    }
  },
  mounted () {
    this.setSelected()
  },
  updated () {
    this.setSelected()
  },
  methods: {
    onInputClick () {
      if (!this.disabled) {
        this.expanded = !this.expanded
      }
    },
    onOptionSelected (e, option) {
      e.stopPropagation()
      this.selectionText = option.label
      this.expanded = false
      this.$emit('select', option.value)
    },
    isActive (value) {
      return value === this.selectedValue
    },
    setSelected () {
      this.options.map(option => {
        if (option.value === this.selectedValue) {
          this.selectionText = option.label
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.SelectInput {
  @extend %defaultLabel;
  width: pxToRem($sidebar-width - $sidebar-padding * 2);

  &--disabled {
    opacity: .75;

    .SelectInput__inputWrapper {
      cursor: default;
    }
  }

  &__targetWrapper {
    display: flex;
  }

  &__inputWrapper {
    @extend %controlElement;
  }

  .CustomTooltip {
    margin: 7px 0 0 5px;
  }

  &__options {
    position: absolute;
    top: -1px;
    left: 100%;
    width: 100%;
    margin-left: 1px;
    z-index: 21;
  }

  &__option {
    @extend %controlElement;
    white-space: nowrap;

    &--active.SelectInput__option {
      @extend %active;
    }
  }

  .SelectInput__options {
    top: 0;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      top: 100%;
      left: -1px;
    }
  }

  &--ci {
    .SelectInput__inputWrapper {
      @extend %controlElement-ci;
      margin-bottom: 0;

      &:hover {
        @extend %hover-ci;
      }
    }

    .SelectInput__options {
      background-color: $ci-dark-blue;
    }

    .SelectInput__option {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
      }

      &--active.SelectInput__option {
        @extend %active-ci;

        &:hover {
          @extend %active-ci;
        }
      }
    }
  }

  &--mg {
    .SelectInput__inputWrapper {
      @extend %controlElement-ci;
      margin-bottom: 0;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }
    }

    .SelectInput__options {
      background-color: $mg-base;
    }

    .SelectInput__option {
      @extend %controlElement-ci;
      background-color: $hover-mg;

      &:hover {
        @extend %hover-ci;
        background: $hover-mg;
      }

      &--active.SelectInput__option {
        @extend %active-ci;
        background: $active-mg;
        color: $mg-text-color;

        &:hover {
          @extend %active-ci;
          background: $active-mg;
          color: $mg-text-color;
        }
      }
    }
  }
}
</style>
