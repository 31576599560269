<template>
  <div class="RadioItems">
    <div
      v-if="$slots['top']"
      class="RadioItems__top"
    >
      <slot name="top" />
    </div>
    <div
      v-if="$slots['bottom']"
      class="RadioItems__bottom"
    >
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioItems',
  props: {

  },
  data: function () {
    return {}
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .RadioItems {
    @include xy-grid(vertical, $wrap: true);
    $gutters: 0;
    &__top, &__bottom {
      @include xy-cell(1 of 1, $gutters: $gutters);
    }
  }
</style>
