<template>
  <div class="MGMapLegend">
    <div class="MGMapLegend__header">
      {{ legends[metric].label }}
    </div>

    <div class="MGMapLegend__items">
      <div
        v-for="item in legends[metric].scale"
        :key="item.label"
        class="MGMapLegend__item"
      >
        <div
          class="MGMapLegend__color"
          :style="{ backgroundColor: item.color }"
        />
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MG_METRICS } from '@/constants/constants'

export default {
  name: 'MapLegend',
  data () {
    return {
      legends: MG_METRICS
    }
  },
  computed: {
    ...mapGetters({
      metric: 'metrics/primaryMetricKey'
    })
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.MGMapLegend {
  background: $color-white;
  padding: 10px;
  color: $mg-text-color;
  width: 170px;

  &__header {
    margin-bottom: 20px;
    font-size: $font-size-16;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    font-size: $font-size-12;
  }

  &__color {
    flex: 0 0 30px;
    height: 30px;
    margin-right: 20px;
  }
}
</style>
