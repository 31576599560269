<template>
  <div class="OperatorsSelector">
    <div
      v-for="network in modelValue"
      :key="network.id"
      :class="{ selected: network.selected }"
      class="OperatorsSelector__operator"
      @click="$emit('update:modelValue', network)"
    >
      <span
        :style="{
          backgroundColor: network.color,
          color: getTextColor(network.color)
        }"
        class="OperatorsSelector__visual"
      >{{ network.letter }}</span>
      <span class="OperatorsSelector__label">{{ network.label }}</span>
    </div>
  </div>
</template>

<script>
import { getTextColor } from '@/utils/colors'

export default {
  name: 'OperatorsSelector',
  props: {
    confidence: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      getTextColor
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

$padding: pxToRem(10);
$operatorSpacing: 2px;

.OperatorsSelector {
  @extend %defaultLabel;
  width: 100%;

  &__operator {
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    height: pxToRem(32);
    float: left;
    line-height: pxToRem(22);
    padding: pxToRem(5) pxToRem(7);
    width: calc(50% - 1px);

    &.selected {
      background: #98b0c7;
    }

    &:nth-child(2n+1) {
      margin-right: $operatorSpacing;
    }

    &:nth-child(n+3) {
      margin-top: $operatorSpacing;
    }

    &:nth-child(1) {
      border-top-left-radius: 6px;
    }

    &:nth-child(2) {
      border-top-right-radius: 6px;
    }

    &:nth-child(2n):nth-last-child(1) {
      border-bottom-right-radius: 6px;
    }

    &:nth-child(2n+1):nth-last-child(2) {
      border-bottom-left-radius: 6px;
    }

    // if there is one operator only
    &:nth-child(1):nth-last-child(1) {
      border-radius: 6px;
    }

    // if one operator on last row
    &:nth-child(2n+1):nth-last-child(1) {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      width: 100%;
    }
  }
  &__visual {
    $borderWidth: 1px;
    background: #000;
    border: $borderWidth solid #fff;
    border-radius: 50%;
    display: block;
    float: left;
    height: calc(#{pxToRem(22)} - #{$borderWidth * 2});
    line-height: calc(#{pxToRem(22)} - #{$borderWidth * 2});
    text-align: center;
    width: calc(#{pxToRem(22)} - #{$borderWidth * 2});
  }
  &__label {
    display: block;
    float: left;
    padding-left: pxToRem(8);
    font-size: $font-size-12;
    font-weight: 400;
    letter-spacing: 0.8px;

    .selected & {
      color: #fff;
    }
  }

  &:after {
    clear: both;
    display: block;
    width: 100%;
    height: 0;
    content: '';
  }
}
</style>
