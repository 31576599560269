<template>
  <div class="CorrelationChart">
    <canvas
      :id="chartId"
      :height="height"
      width="400"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import {
  Y_AXIS_BASE_STYLE,
  SCALE_TITLE_STYLE
} from '@/constants/chartjs'

export default {
  name: 'StackedDist',
  components: {
  },
  props: {
    chartId: {
      default: 'distribution-chart',
      type: String
    },
    dataSet: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    yAxisLabel: {
      type: String,
      default: ''
    },
    xAxisLabel: {
      type: String,
      default: ''
    },
    tooltipMetric: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      chart: null,
      height: 450
    }
  },
  computed: {},
  watch: {
    dataSet () {
      const chart = Chart.getChart(this.chartId);

      chart.data.datasets = this.dataSet
      chart.data.labels = this.labels
      chart.update()
    }
  },
  mounted () {
    this.renderChart()
  },
  methods: {
    renderChart () {
      new Chart(this.chartId, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.dataSet
        },
        options: {
          interaction: {
            mode: 'nearest'
          },
          plugins: {
            legend: {
              position: 'right',
              align: 'start',
              labels: {
                boxWidth: 12,
                fontSize: 12,
                padding: 8
              },
              reverse: true
            },
            tooltip: {
              backgroundColor: 'white',
              bodyColor: 'black',
              titleColor: 'black',
              callbacks: {
                label: tooltipItem => {
                  const { formattedValue } = tooltipItem
                  const { label } = this.dataSet[tooltipItem.datasetIndex]
                  return `${this.tooltipMetric} ${label}: ${formattedValue}%`
                }
              }
            }
          },
          height: this.height,
          maintainAspectRatio: false,
          scales: {
            y: {
              ...Y_AXIS_BASE_STYLE,
              ticks: {
                ...Y_AXIS_BASE_STYLE.ticks,
                beginAtZero: true
              },
              max: 100,
              stacked: true,
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.yAxisLabel,
                text: this.yAxisLabel,
                padding: 0
              },
              gridLines: {
                offsetGridLines: false,
                drawTicks: false
              }
            },
            x: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                autoSkip: false
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.xAxisLabel,
                text: this.xAxisLabel,
                padding: 0,
                fontSize: 10
              }
            }

          }
        }
      })
    }
  }
}
</script>
