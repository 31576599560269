<template>
  <div
    v-click-outside="close"
    class="LocationSearch"
    :class="locationSearchCssClass"
  >
    <!-- predictive serach input filter & country selector -->
    <div
      :class="inputActive && 'LocationSearch__filter--active'"
      class="LocationSearch__filter"
    >
      <div
        class="LocationSearch__country-select"
        @click="toggleCountries()"
      >
        <div class="LocationSearch__filter-icon">
          <FontAwesomeIcon
            :icon="icons.faMapMarkerAlt"
            size="1x"
          />
        </div> {{ currentLocation.iso3 }} |
      </div>
      <input
        v-model="localitySearch"
        :placeholder="inputLabel"
        :class="showPlaceholder && 'LocationSearch__input--no-padding'"
        type="text"
        class="LocationSearch__input"
        @click="onClickOnInput"
      >
      <div
        v-if="!inputStart"
        class="LocationSearch__filter-icon-close"
        @click="showOptions()"
      >
        <FontAwesomeIcon
          :icon="icons.faTimes"
        />
      </div>
    </div>

    <!-- structured list -->
    <div
      v-if="showSturcturedList"
      class="LocationSearch__wrapper"
    >
      <div
        v-if="countries.length > 1"
        class="LocationSearch__countries-nav"
        @click="countriesView = true"
      >
        <FontAwesomeIcon
          :icon="icons.faChevronLeft"
          size="1x"
          class="LocationSearch__icon-left"
        />
        All countries list
      </div>
      <div
        class="LocationSearch__country"
        @click="navigate($event, currentCountry)"
      >
        {{ currentCountry.name }}
      </div>
      <div class="LocationSearch__categories-container">
        <div
          v-for="grouping in groupingsOS"
          :key="grouping.id"
          class="LocationSearch__category"
          @click="isMobile ? toggleDropdown(grouping.id) : enableGroupNavigation ? navigateGeocoding($event, grouping, currentCountry) : null"
        >
          {{ grouping.name }}

          <template v-if="!displayTopLocationsOnly">
            <FontAwesomeIcon
              :icon="isMobile ? icons.faChevronDown : icons.faChevronRight"
              size="1x"
              class="LocationSearch__icon-right"
            />
            <div class="LocationSearch__locations-wrapper">
              <div
                class="LocationSearch__locations-container"
                :style="groupingStyles(grouping.id)"
              >
                <div
                  v-for="location in grouping.locations"
                  :key="location.id"
                  class="LocationSearch__list-element"
                  @click="navigate($event, location)"
                >
                  {{ location.name }}
                </div>
              </div>
            </div>
          </template>
        </div>

        <div v-if="groupingsCustom.length">
          <div class="LocationSearch__categoryTitle">
            Select Custom Granularity
          </div>
          <div
            v-for="grouping in groupingsCustom"
            :key="grouping.id"
            class="LocationSearch__category"
            @click="isMobile ? toggleDropdown(grouping.id) : enableGroupNavigation ? navigateGeocoding($event, grouping, currentCountry) : null"
          >
            <OperatorAvatar
              :letter="homeNetwork.name_mapped.substr(0,1)"
              :background-color="'#' + homeNetwork.hex_color"
            /><span class="LocationSearch__labelCustom">Custom:</span>
            {{ grouping.name }}

            <template v-if="!displayTopLocationsOnly">
              <FontAwesomeIcon
                :icon="isMobile ? icons.faChevronDown : icons.faChevronRight"
                size="1x"
                class="LocationSearch__icon-right"
              />
              <div class="LocationSearch__locations-wrapper">
                <div
                  class="LocationSearch__locations-container"
                  :style="groupingStyles(grouping.id)"
                >
                  <div
                    v-for="location in grouping.locations"
                    :key="location.id"
                    class="LocationSearch__list-element"
                    @click="navigate($event, location)"
                  >
                    {{ location.name }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- countries dropdown list -->
    <div
      v-if="countriesView"
      class="LocationSearch__countries-view"
    >
      <div
        v-for="country in countries"
        :key="country.id"
        class="LocationSearch__list-element"
        @click="navigate($event, country)"
      >
        {{ country.name }}
      </div>
    </div>

    <!-- predictive search list -->
    <div
      v-if="userIsSearching"
      class="LocationSearch__filter-list"
    >
      <div
        v-for="loc in filteredLocalities"
        :key="loc.id"
        class="LocationSearch__list-element"
        @click="navigate($event, loc)"
      >
        {{ loc.name }} ({{ groupLabel(...loc.granularity.split('_')) }})
      </div>
      <div
        v-if="!filteredLocalities.length"
        class="LocationSearch__list-message"
      >
        No results
      </div>
    </div>
  </div>
</template>

<script>
import {
  faTimes,
  faMapMarkerAlt
} from '@fortawesome/pro-light-svg-icons'
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { captureException } from '@sentry/browser'
import { mapGetters } from 'vuex'
import OperatorAvatar from '../OperatorAvatar'

export default {
  name: 'LocationSearch',
  components: {
    FontAwesomeIcon,
    OperatorAvatar
  },
  props: {
    displayTopLocationsOnly: {
      type: Boolean,
      default: false
    },
    enableGroupNavigation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: {
        faTimes,
        faChevronLeft,
        faChevronRight,
        faChevronDown,
        faMapMarkerAlt
      },
      inputStart: false,
      showPlaceholder: false,
      inputString: '',
      filteredLocalities: [],
      countriesView: false,
      expandedGroupingId: null
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      countries: 'location/countries',
      currentCountry: 'location/currentCountry',
      allLocations: 'location/allLocations',
      currentLocation: 'location/currentLocation',
      inCountry: 'location/inCountry',
      groupings: 'location/byGroupings',
      groupLabel: 'location/groupLabel',
      homeNetwork: 'charts/homeNetwork',
      isMobile: 'page/isMobile'
    }),
    /*
     * @return { String } input box label
     */
    inputLabel () {
      return this.showPlaceholder ? 'Search for cities and regions' : ''
    },
    inputActive () {
      return this.inputStart || this.countriesView
    },
    showSturcturedList () {
      return !this.countriesView && !this.userIsSearching && this.inputStart
    },
    userIsSearching () {
      return this.inputString.length
    },
    localitySearch: {
      get () {
        if (this.showPlaceholder) return

        if (!this.inputStart) {
          return this.currentLocation.name
        } else {
          return this.inputString
        }
      },
      set (value) {
        if (this.showPlaceholder) {
          this.showPlaceholder = false
        }

        this.inputString = value
        if (this.countriesView) {
          this.filterLocalities(this.allLocations)
        } else {
          this.filterLocalities(this.inCountry)
        }
      }
    },
    groupingsOS () {
      return this.groupings.filter(group => group.id === 2 || group.id === 3)
    },
    groupingsCustom () {
      return this.groupings.filter(group => group.id !== 2 && group.id !== 3)
    },
    locationSearchCssClass () {
      if (this.theme) {
        return 'LocationSearch--' + this.theme
      }

      return ''
    }
  },
  watch: {
    inputActive (value) {
      // in PI we need to update the layout so this interaction can be controled
      // from inside the menu
      // https://jira.opnsgnl.net/browse/DCI-1302
      const element = document.querySelector('.PerformanceLayout__sidebar')
      if (element) {
        if (value) {
          element.style.width = '100%'
        } else {
          element.style.width = '305px'
        }
      }
    }
  },
  methods: {
    filterLocalities (target) {
      let re = ''
      try {
        re = new RegExp(this.inputString.replace(/\./, '\\.'), 'i')
      } catch (e) {
        if (!process.env.VUE_APP_SENTRY_DISABLED) {
          captureException(e)
        } else {
          console.log(e)
        }
      }
      this.filteredLocalities = target.filter(loc => loc.name.match(re))
    },
    showOptions () {
      this.showPlaceholder = true
      this.inputStart = true
      if (this.countriesView) this.countriesView = false
    },
    toggleCountries () {
      if (this.countries.length > 1) this.countriesView = !this.countriesView
    },
    onClickOnInput () {
      if (this.inputStart && this.countriesView) this.countriesView = false
      if (!this.inputStart) this.showOptions()
    },
    close () {
      this.showPlaceholder = false
      this.inputStart = false
      this.filteredLocalities = []
      this.inputString = ''
      this.countriesView = false
      this.expandedGroupingId = null
    },
    navigate (e, location) {
      e.stopPropagation()
      this.close()
      this.$emit('navigate', location.key)
    },
    navigateGeocoding (e, customLocation, currentCountry) {
      e.stopPropagation()
      this.close()
      this.$emit('navigateGeocoding', customLocation.id, currentCountry.key)
    },
    groupingStyles (groupingId) {
      if (!this.isMobile) {
        return {}
      } else {
        return {
          display: this.expandedGroupingId === groupingId ? 'block' : 'none'
        }
      }
    },
    toggleDropdown (groupingId) {
      if (this.expandedGroupingId === groupingId) {
        this.expandedGroupingId = null
      } else {
        this.expandedGroupingId = groupingId
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.LocationSearch {
  padding-bottom: $padding-medium;
  background: transparent;
  position: relative;

  &__filter {
    position: relative;
    background-color: $color-white-background;
    border-radius: 0.25rem;
    color: $control-light-text-color;

    &--active.LocationSearch__filter,
    &:hover {
      @extend %active;
    }
  }

  &__country-select {
    @extend %inputText;
    @extend %inputBox;

    width: 5rem;
    padding-left: 3em;
    cursor: pointer;
  }

  &__input {
    @extend %inputText;
    @extend %inputBox;

    width: calc(100% - 5rem);
    padding-right: 3em;
    color: inherit;
    background: transparent;
    text-overflow: ellipsis;

    &--no-padding {
      padding-right: 0;
    }

    &:focus {
      outline: none;
      color: inherit;
    }
    &::placeholder {
      color: rgba(255,255,255,0.6);
      text-overflow: ellipsis;
    }
  }

  &__filter-icon {
    @extend %inputText;

    font-size: 1.2rem;
    position: absolute;
    left: 0.8em;
  }

  &__filter-icon-close {
    @extend %inputText;

    position: absolute;
    top: 1px;
    right: 1rem;
    font-size: 1rem;
    cursor: pointer;
  }

  &__wrapper {
    @extend %dropDown;
    @extend %menuContainer;
  }

  &__countries-view {
    @extend %dropDown;
    @extend %menuContainer;
    @extend %scrollBox;
  }

  &__categoryTitle {
    @extend %listElement;
    font-style: italic;
    cursor: default;

    &:hover {
      background: inherit;
    }
  }

  &__category {
    @extend %listElement;
    position: relative;

    &--customLabel {
      font-style: italic;
    }

    .OperatorAvatar {
      margin: -3px 10px -3px 0;
    }
  }

  &__locations-wrapper {
    position: absolute;
    width: auto;
    top: 0;
    left: 100%;
    background-color: transparent;
    padding-left: 1px;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      top: 100%;
      left: -1px;
      z-index: 1;
      width: 100%;
    }
  }

  &__locations-container {
    @extend %scrollBox;
    @extend %menuContainer;

    min-width: 275px;
  }

  &__category &__locations-container {
    display: none;
  }

  &__category:hover &__locations-container,
  &__category &__locations-container:hover {
    display: block;
  }

  &__filter-list {
    @extend %dropDown;
    @extend %menuContainer;

    overflow-y: auto;
    max-height: 350px;
  }

  &__countries-nav {
    line-height: 1rem;
    font-size: 0.65rem;
    padding: 0.5rem 1rem;
    color: $control-light-text-color;
  }

  &__country,
  &__countries-nav {
    cursor: pointer;
  }

  &__icon-left {
    padding-right: 1rem;
  }
  &__icon-right {
    display: none;
    float: right;
    height: 1rem;
    font-size: 0.75em;

    @media screen and (max-width: $ci-breakpoint-tablet) {
      display: block;
    }
  }

  &__category &__locations-container {
    display: none;
  }

  &__category:hover &__icon-right {
    display: inline-block;
  }

  &__list-element {
    @extend %listElement;
  }

  &__country {
    @extend %listElement;
    background-color: #CFD9E3;
    &:hover {
      background: #CFD9E3;
      color: $control-light-text-color;
    }
  }

  &__list-message {
    @extend %listElement;
    cursor: default;
    &:hover {
      background: transparent;
      color: $control-light-text-color;
    }
  }

  &--ci {
    .LocationSearch__wrapper,
    .LocationSearch__filter-list,
    .LocationSearch__countries-view,
    .LocationSearch__locations-container {
      background: $ci-dark-blue;
      border-radius: 0;
    }

    .LocationSearch__filter,
    .LocationSearch__list-message,
    .LocationSearch__list-element,
    .LocationSearch__country,
    .LocationSearch__countries-nav,
    .LocationSearch__category,
    .LocationSearch__categoryTitle {
      @extend %controlElement-ci;

      &:hover {
        @extend %hover-ci;
      }
    }
  }
}
</style>
