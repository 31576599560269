<template>
  <ChartWrapper
    :picture-meta="pictureMeta"
    :loading="chartPending"
    :empty="chartData.empty"
    :tooltip="chartData.tooltip"
    :unit="chartUnit"
    :title-labels="titleLabels.chartTitle"
    :show-confidence-rate="showConfidenceRate"
    :title="chartData.title"
  >
    <pre>{{ chartData }}</pre>
  </ChartWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChartWrapper } from '@/components/visual'

export default {
  name: 'ChartJSON',
  components: {
    ChartWrapper
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/plainData',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      currentLocation: 'locations/current',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        networks: this.selectedNetworks,
        product: 'performance'
      }
    }
  }
}
</script>

<style lang="css">
</style>
