<template>
  <div>
    <ChartWrapper
      v-for="network in selectedNetworks"
      :key="network.canonical_network_id"
      :picture-meta="pictureMeta"
      :selected-point="[selectedPoint[multiChartId(network.canonical_network_id)]]"
      :loading="chartPending"
      :empty="!chartData.data[`${network.canonical_network_id}`]"
      :tooltip="chartData.tooltip"
      :unit="chartUnit"
      :title-labels="titleLabels"
      :show-confidence-rate="showConfidenceRate"
      :title="`${network.name_mapped} ${chartData.title}`"
    >
      <group-bar-chart
        v-if="chartData.data[`${network.canonical_network_id}`]"
        :data-set="[chartData.data[`${network.canonical_network_id}`]]"
        :labels="chartData.labels[`${network.canonical_network_id}`]"
        :max="chartData.max"
        :min="0"
        :bar-labels="chartNumbers"
        :show-confidence-rate="showConfidenceRate"
        :y-axis-label="chartData.yAxisLabel"
        :x-axis-label="chartData.xAxisLabel"
        :chart-id="multiChartId(network.canonical_network_id)"
      />
    </ChartWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GroupBarChart, ChartWrapper } from '@/components/visual'
import { multiChartId } from '@/utils/charts'

export default {
  name: 'ChartNetworkByNetwork',
  components: {
    ChartWrapper,
    GroupBarChart
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/singleNetworkCategories',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      currentLocation: 'locations/current',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPointNetwork'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    }
  },
  methods: {
    multiChartId
  }
}
</script>

<style lang="css">
</style>
