<template>
  <div
    :class="negativeMargin && 'CheckBox--negative-margin'"
    class="CheckBox"
  >
    <div
      :class="{ selected: modelValue }"
      class="CheckBox__box"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <div class="CheckBox__checkbox">
        <FontAwesomeIcon
          :icon="faCheck"
        />
      </div>
      <span class="CheckBox__label">{{ label }}</span>
    </div>
    <CustomTooltip
      v-if="tooltip"
      :message="tooltip"
      class="CheckBox__tooltip"
      placement="bottom"
    >
      <QuestionMark />
    </CustomTooltip>
  </div>
</template>

<script>
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CustomTooltip, QuestionMark } from '@/components/tooltip'

export default {
  name: 'CheckBox',
  components: {
    FontAwesomeIcon,
    CustomTooltip,
    QuestionMark
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Placeholder text'
    },
    tooltip: {
      type: String,
      default: null,
      required: false
    },
    negativeMargin: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      faCheck
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.CheckBox {
  @extend %defaultLabel;
  margin-bottom: .5rem;

  &--negative-margin {
    margin-top: -14px;
  }

  &__box {
    float: left;
    height: 2rem;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 5px 7px;
    border: $control-border;
    border-radius: $control-border-radius;
    line-height: pxToRem(22);
    color: $color-nav-item-text;
    background: $color-white-background;
    cursor: pointer;
  }

  &__label {
    padding-left: 7px;
  }

  &__checkbox {
    width: 1rem;
    height: 1rem;
    border-radius: 5px;
    background: $color-brand-blue;
    margin-top: 3px;
    color: transparent;
    text-align: center;
    line-height: 1rem;
    font-size: pxToRem(10);
    float: left;

    .selected & {
      color: #fff;
    }
  }

  &__tooltip {
    top: 1.05rem;
  }

  &:after {
    clear: both;
    display: block;
    width: 100%;
    height: 0;
    content: '';
  }
}
</style>
