<template>
  <div class="Overview">
    <CompetitiveOverviewLayout :number-of-tiles="competitiveTiles.length">
      <template #header>
        <OverviewHeader />
      </template>

      <template #competitiveTiles>
        <CompetitiveTile
          v-for="tile in competitiveTiles"
          :key="tile.metricKey"
          :metric-type="tile.metricType"
          :metric-key="tile.metricKey"
          :bigger-better="tile.biggerBetter"
          :title="tile.title"
          :ranks="tile.ranks"
          :options="tile.options"
          :granularity="tile.granularity"
          :is-loading="tile.loading"
          :user-group="userGroup"
          @metric="changePill"
          @navigate="navigate(tile.metric)"
        />
      </template>
    </CompetitiveOverviewLayout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompetitiveTile from '@/components/comparison/CompetitiveTile'
import {
  CompetitiveOverviewLayout,
  OverviewHeader
} from '@/components/competitive'
import { PILLS, LATEST_STRING, DEFAULT_USER_GROUP, METRIC_TYPES, METRIC_TYPE_MAP } from '@/constants/constants'
import ROUTES from '@/constants/routes'
import router from '@/router'

export default {
  name: 'CompetitiveOverview',
  components: {
    CompetitiveOverviewLayout,
    OverviewHeader,
    CompetitiveTile
  },
  beforeRouteUpdate (to, from, next) {
    // give the $router a way to know about all params
    this.setRouteParam({
      country: this.locations.find(l => l.key === to.params.location).iso3,
      ...to.params
    })

    if (
      to.params.location !== this.$route.params.location ||
      to.params.date !== this.$route.params.date
    ) {
      this.fetchData({
        location: to.params.location,
        date: to.params.date
      })
    }

    next()
  },
  props: {
    location: {
      type: String,
      default: '11'
    },
    compareTo: {
      type: String,
      default: '90days'
    }
  },
  data () {
    return {
      pills: PILLS,
      displayModal: false
    }
  },
  computed: {
    ...mapGetters([
      'locations',
      'metrics',
      'overviewStats',
      'user',
      'dashboardInfo'
    ]),
    ...mapGetters({
      lastDateAvailable: 'auth/getLastDateAvailable',
      orderValues: 'competitive/getOrderedValues',
      defaultMetricType: 'competitive/defaultMetricType',
      currentLocation: 'location/currentLocation',
      currentCountry: 'location/currentCountry',
      userGeocodings: 'location/groupings',
      loadingMetrics: 'overviewStats/loadingMetrics',
      userGroup: 'competitive/userGroup'
    }),
    competitiveTiles () { /* TODO move to ci vuex module */
      let compareTo = this.$route.params.compareTo

      return this.pills
        .filter(p => {
          return this.metrics.find(m => m.key === p.metric) && p.visible
        })
        .map(pill => {
          const selectedMetric = this.metrics.find(m => m.key === pill.metric) || {}

          return {
            metricKey: pill.key,
            metricType: selectedMetric.type,
            metric: selectedMetric.key,
            biggerBetter: selectedMetric.bigger_is_better,
            title: selectedMetric.name,
            options: this.metrics
              .filter(m => m.kind === selectedMetric.kind)
              .filter(m => m.subcategory === this.userGroup),
            granularity: this.currentLocation.granularityId,
            // loading: this.loadingMetrics.includes(pill.metric), // TODO ###
            loading: (this.loadingMetrics || []).includes(pill.metric),
            ranks: this.orderValues(this.overviewStats, selectedMetric).map(p => ({
              lci: p.lci,
              operatorColor: `#${p.operator.hex_color}`,
              operatorInitial: p.operator.name_mapped[0],
              operatorName: p.operator.name_mapped,
              canonicalNetworkId: p.operator.canonical_network_id,
              rank: p.rank,
              uci: p.uci,
              value: selectedMetric.structure === METRIC_TYPE_MAP.percentage_simple ? p.percentage : p.mean,
              previous: p.previous && p.previous[compareTo] && p.previous[compareTo].mean,
              ...(p.comparison && p.comparison[compareTo] ? {
                comparisonRank: p.comparison[compareTo].rank,
                comparisonMean: p.comparison[compareTo].mean
              } : {})
            }))
          }
        })
    }
  },
  watch: {
    defaultMetricType () {
      // Check if user group still applies
      this.setDefaultMetricType()
      this.fetchData({
        location: this.location,
        date: this.$route.params.date
      }).then(() => this.trackRoute('competitive'))
    },
    userGroup () {
      this.setDefaultMetricType()
      this.fetchData({
        location: this.location,
        date: this.$route.params.date
      }).then(() => this.trackRoute('competitive'))
    }
  },
  mounted () {
    this.setRouteParam(this.$route.params)
    this.setDefaultMetricType()
    this.fetchData({
      location: this.location,
      date: this.$route.params.date
    })
  },
  methods: {
    ...mapActions([
      'setOverview',
      'setRouteParam',
      'trackRoute'
    ]),
    fetchData (params) {
      const date = params.date === LATEST_STRING
        ? this.dashboardInfo.last_date_available : params.date
      return this.setOverview({
        location: params.location,
        date,
        metricString: params.metricString || this.pills.map(pill => pill.metric).toString(),
        singleMetric: params.singleMetric || false
      })
    },
    changePill (metric) {
      const pill = this.pills.find(p => p.key === metric.kind)
      pill.metric = metric.key
      pill.show = false

      this.fetchData({
        location: this.$route.params.location,
        date: this.$route.params.date,
        metricString: metric.key,
        singleMetric: true
      })
    },
    navigate (metric) {
      this.$router.push({
        name: ROUTES.CompetitiveDetails,
        params: {
          location: this.location,
          network: 'all',
          country: this.currentLocation.iso3,
          countryid: this.currentCountry.key,
          geocoding: this.currentLocation.granularityId === '1' && this.userGeocodings.length ? `${this.userGeocodings[0].id}` : this.currentLocation.granularityId,
          metric: metric,
          agg: '90days',
          date: this.$route.params.date === LATEST_STRING
            ? this.dashboardInfo.last_date_available : this.$route.params.date
        }
      })
    },
    setDefaultMetricType () {
      this.pills = this.pills.map(pill => {
        pill.visible = false

        let targetMetricType = this.defaultMetricType

        if (pill.key === 'availability' && targetMetricType === 'overall') {
          targetMetricType = METRIC_TYPES.All
        }

        let metricForType = this.metrics.find(metric =>
          metric.type === targetMetricType &&
          metric.kind === pill.key &&
          metric.subcategory === this.userGroup
        )

        if (metricForType) {
          pill = {
            key: metricForType.kind,
            metric: metricForType.key,
            visible: true
          }
        } else if (pill.key === 'coverage' &&
          (targetMetricType === '4g' || targetMetricType === 'overall') &&
          this.userGroup === DEFAULT_USER_GROUP
        ) {
          pill.visible = true
        }

        return pill
      })
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';
  .race-chart {
    position: relative;

    &__camera {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .Overview {
    position: relative;
  }
</style>
