<template>
  <div class="CompetitiveDetailsLayout">
    <div class="CompetitiveDetailsLayout__main">
      <div class="CompetitiveDetailsLayout__operators">
        <slot name="operators" />
      </div>
      <div class="CompetitiveDetailsLayout__charts">
        <slot name="charts" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitiveDetailsLayout',
  components: {},
  props: {}
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .CompetitiveDetailsLayout {
    margin-top: 1rem;
    min-width: $content-min-width;
    @include xy-grid(vertical);

    &__main {
      @include xy-cell(1 of 1);
      @include xy-grid(horizontal);
    }

    &__operators {
      width: 29rem;
      margin: 0 .5rem;
      position: relative;
    }

    &__charts {
      margin: 0 0.5rem 0 1.5rem;
      width: calc(100% - 32rem);
    }
  }

  @media screen and (max-width: $ci-breakpoint) {
    .CompetitiveDetailsLayout {
      min-width: initial;
    }

    .CompetitiveDetailsLayout__main {
      flex-direction: column;
    }
    .CompetitiveDetailsLayout__operators,
    .CompetitiveDetailsLayout__charts {
      width: 100%;
      margin: 0 0 2rem;
      padding: 0 0.5rem;
      box-sizing: border-box;
    }
  }
</style>
