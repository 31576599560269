<template>
  <div
    v-if="areasData.length"
    class="MetricAreaListFocused"
  >
    <div
      v-for="area in areasData"
      :key="area.index"
      :set="stats = getFocusedStats(area.item)"
      class="MetricAreaListFocused__row"
      @click="selectLocation(area)"
    >
      <div class="MetricAreaListFocused__title">
        {{ area.index }}. {{ area.properties.name }}

        <span
          v-if="area.homeArea.rank == null"
          class="noRank"
        />
      </div>
      <div
        v-if="stats"
        class="MetricAreaListFocused__stats"
      >
        <div>
          <span class="MetricAreaListFocused__value">
            {{ stats.value }}
          </span>
          <span class="MetricAreaListFocused__cis">
            {{ stats.lci }} <span v-if="Number.isFinite(stats.lci)">/</span> {{ stats.uci }}
          </span>
        </div>

        <BarChartElement
          :value="stats.value"
          :lci="stats.lci"
          :uci="stats.uci"
          :color="stats.color"
          :min="minAndMax(area).min"
          :max="minAndMax(area).max"
        />
      </div>

      <div
        v-if="!isSingleOperator"
        class="MetricAreaListFocused__tooltip"
      >
        <RankingTable
          :bigger-better="metric.bigger_is_better"
          :ranks="area.item"
          :min-and-max="minAndMax(area)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { metricMinAndMax } from '../visual/map/helpers'
import BarChartElement from './BarChartElement'
import RankingTable from './RankingTable'
import router from '@/router'

export default {
  name: 'MetricAreaListFocused',
  components: {
    BarChartElement,
    RankingTable
  },
  props: {
    areasData: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      focusedNetworkId: 'competitive/focusedNetworkId',
      homeNetwork: 'charts/homeNetwork',
      metric: 'metrics/primaryMetric'
    }),
    isSingleOperator () {
      return router.currentRoute.value.params.network !== 'all'
    }
  },
  methods: {
    selectLocation (area) {
      this.$emit('selectLocation', area.properties.id)
    },
    minAndMax (area) {
      return metricMinAndMax(area.item, this.metric)
    },
    getFocusedStats (items) {
      return items.find(item => item.network === this.focusedNetworkId + '')
    }
  }
}
</script>

<style lang="scss">
  @import 'scss/variables';

  .MetricAreaListFocused {
    &__row {
      cursor: pointer;
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;
      margin-bottom: 1px;
      position: relative;
      clear: both;

      &:hover {
        .MetricAreaListFocused__tooltip {
          display: block;
          overflow: hidden;

          @media screen and (max-width: $ci-breakpoint) {
            border-top: 1px solid $color-sidebar-grey;
          }
        }
      }
    }

    &__title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex: 8;
      padding: 9px 5px;
      font-size: $font-size-14;
      line-height: $font-size-20;
      font-weight: $font-weight-roboto-regular;
      background: $color-white;
    }

    &__stats {
      height: 100%;
      padding: 5px;
      flex: 10;
      border-left: 1px solid $color-sidebar-grey;
      background: $color-white;
    }

    &__value {
      display: inline-block;
      margin-right: 10px;
    }

    &__cis {
      color: $color-sidebar-grey;
      font-size: $font-size-12;
    }

    &__tooltip {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      z-index: 2;
      margin-left: 3px;
      background: $color-sidebar-grey;
      box-shadow: 0 0 10px $color-nav-bg;

      @media screen and (max-width: $ci-breakpoint) {
        position: static;
        box-shadow: none;
        margin: 0;
        flex-basis: 100%;
      }
    }
  }

  .noRank {
     float: right;
      position: relative;
      width: 15px;
      height: 15px;

      &:after {
        content: "x";
        display: block;
        position: absolute;
        background-color: $icon-light-grey;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        line-height: 1.2rem;
        text-align: center;
        bottom: 0px;
        left: -4px;
      }
  }
@media screen and (max-width: $ci-breakpoint) {
  .MetricAreaListFocused--compare {
    .MetricAreaListFocused__row {
      &:hover {
        padding: 6px 0;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }

      }
    }

    .MetricAreaListFocused__tooltip {
      border-top: 1px solid $color-sidebar-grey;
    }
  }
}

</style>
