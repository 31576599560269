<template>
  <div v-if="titleLabels.chartTitle && titleLabels.chartTitle.fullTitle">
    <ChartWrapper
      :picture-meta="pictureMeta"
      :selected-point="selectedPoint"
      :loading="chartPending"
      :empty="rawData.empty"
      :tooltip="chartData.tooltip"
      :unit="chartUnit"
      :title-labels="titleLabels"
      :show-confidence-rate="false"
      title="Distribution"
      legend-disabled
    >
      <StackedDist
        :data-set="chartData.data"
        :labels="chartData.labels"
        :y-axis-label="`${titleLabels.chartTitle.fullTitle}(${chartUnit})`"
        chart-id="distribution"
        tooltip-metric="Frequency Band"
      />
    </ChartWrapper>

    <div
      v-for="operatorChart in operatorCharts"
      :key="operatorChart.chartId"
    >
      <ChartWrapper
        :title="operatorChart.title"
        :title-labels="titleLabels"
        :loading="chartPending"
        :empty="rawData.empty"
        disable-export
      >
        <line-chart
          :date="date"
          :data-set="operatorChart.data"
          :chart-id="operatorChart.chartId"
          :x-axis-label="operatorChart.xAxisLabel"
          :y-axis-label="operatorChart.yAxisLabel"
          :end-date="dashboardInfo.last_date_available"
          show-legend
        />
      </ChartWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChartWrapper, StackedDist, LineChart } from '@/components/visual'

export default {
  name: 'ChartBar',
  components: {
    ChartWrapper,
    StackedDist,
    LineChart
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      date: 'dashboard/date',
      chartData: 'charts/stackedDist',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      rawData: 'chart/dist_all',
      selectedNetworks: 'charts/selectedNetworkOperators',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    },
    frequenciesColors () {
      const frequenciesColors = {}
      this.chartData.data.forEach(item => {
        frequenciesColors[item.band.key] = item.backgroundColor
      })
      return frequenciesColors
    },

    operatorCharts () {
      const networkIds = []
      this.rawData.forEach(item => {
        const networkId = item.canonical_network_id
        if (networkIds.includes(networkId)) {
          return
        }
        networkIds.push(networkId)
      })

      const networkExists = networkId => this.selectedNetworks.find(network => network.canonical_network_id === networkId)

      return networkIds.filter(networkExists).map(networkId => {
        const networkData = this.rawData.filter(item => item.canonical_network_id === networkId)

        const availableBands = []
        networkData.forEach(scan => {
          const scanBands = Object.keys(scan.percent)
          scanBands.forEach(band => {
            if (availableBands.includes(band)) {
              return
            }
            availableBands.push(band)
          })
        })

        const data = availableBands.map(band => {
          const bandData = networkData.map(scan => ({
            x: scan.date,
            y: scan.percent[band]
          }))
          return {
            label: band,
            color: this.frequenciesColors[band],
            data: bandData
          }
        })

        const network = this.selectedNetworks.find(network => network.canonical_network_id === networkId)
        return {
          chartId: `chart-${networkId}`,
          data,
          title: `Trends for ${network.name_mapped}`,
          xAxisLabel: undefined,
          yAxisLabel: '4G Cells detected per Frequency Band (%)'
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
