/* eslint-disable */
(() => {

  if (Array.prototype.flat === undefined) {
    Array.prototype.flat = function () {
      return this.reduce((acc, val) => {
        return Array.isArray(val) ? acc.concat(val.flat()) : acc.concat(val)
      }, [])
    }
  }

  if (!Array.prototype.includes) {
    Array.prototype.includes =  function (elm) {
      return this.indexOf(elm) !== -1
    }
  }

})()
