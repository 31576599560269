import chroma from 'chroma-js'
import { format, eachDayOfInterval } from 'date-fns'
import get from 'lodash.get'
import { PROGRAMMATIC_FORMAT } from '@/constants/dateFormats'
import { stringToColour } from '@/utils/colors'
import { getSafeDate, getShortDate } from '@/utils/date'

const getBackgroundColor = color => chroma(color).alpha(0.8).hex()
const getHoverBackgroundColor = color => chroma(color).alpha(0.9).hex()
const extractFromGroupKey = groupKey => groupKey.split('-')
const ipGroupHasData = (ipGroup) =>
  ipGroup.data && !ipGroup.data.every(element => element === null)

const getGroupKeys = (chartData) => {
  return chartData.reduce((accumulator, scan) => {
    const endPoints = Object.keys(scan.percentage)
    endPoints.forEach(cdn => {
      const groupKey = `${scan.canonical_network_id}-${cdn}`
      if (accumulator.includes(groupKey)) {
        return
      }
      accumulator.push(groupKey)
    })
    return accumulator
  }, [])
}

const getResGroupKeys = (chartData) => {
  return chartData.reduce((accumulator, scan) => {
    const endPoints = Object.keys(scan.percentage)
    endPoints.forEach(cdn => {
      const resolutions = scan.percentage[cdn] ? Object.keys(scan.percentage[cdn]) : []
      resolutions.forEach(resolution => {
        const groupKey = `${scan.canonical_network_id}-${cdn}-${resolution}`
        if (accumulator.includes(groupKey)) {
          return
        }
        accumulator.push(groupKey)
      })
    })
    return accumulator
  }, [])
}

const getIPsForGroupKey = (chartData, groupKey) => {
  const [networkId, cdn, resolution] = extractFromGroupKey(groupKey)
  const groupScans = chartData.filter(scan => scan.canonical_network_id === parseInt(networkId))
  const groupIPs = []
  groupScans.forEach(scan => {
    const endPointData = resolution !== undefined
      ? get(scan, ['percentage', cdn, resolution])
      : get(scan, ['percentage', cdn])
    if (!endPointData) {
      return
    }
    const IPs = Object.keys(endPointData)
    IPs.forEach(IP => {
      if (groupIPs.includes(IP)) {
        return
      }
      groupIPs.push(IP)
    })
  })
  return groupIPs
}

function groupDataByDate (chartData) {
  const datumByDate = {}
  chartData.forEach(datum => {
    const { canonical_network_id: networkId, date } = datum
    if (!datumByDate[networkId]) {
      datumByDate[networkId] = {}
    }
    datumByDate[networkId][format(getSafeDate(date), PROGRAMMATIC_FORMAT)] = datum
  })
  return datumByDate
}

const addGroupId = (groupKey) => ({
  id: groupKey
})

const getFillersGroup = (totalByDate) => ({
  backgroundColor: '#808080cc',
  data: Object.keys(totalByDate).map(date => {
    const percentage = parseFloat((100 - totalByDate[date]).toFixed(2))
    return percentage > 0 ? percentage : 0
  }),
  hoverBackgroundColor: '#808080e6',
  label: 'Others'
})

const getFormattingFunctions = (
  chartData,
  selectedStartDateString,
  selectedEndDateString,
  datumByDate
) => {
  const selectedStartDate = getSafeDate(selectedStartDateString)
  const selectedEndDate = getSafeDate(selectedEndDateString)
  const addGroupLabels = (group) => {
    const labels = eachDayOfInterval({ start: selectedStartDate, end: selectedEndDate }).map(getShortDate)
    return {
      ...group,
      labels
    }
  }
  const addGroupData = group => {
    const [networkId, cdn, resolution] = extractFromGroupKey(group.id)
    const groupIPs = getIPsForGroupKey(chartData, group.id)
    const totalByDate = {}
    const data = groupIPs.map(ip => {
      return {
        backgroundColor: getBackgroundColor(stringToColour(ip)),
        hoverBackgroundColor: getHoverBackgroundColor(stringToColour(ip)),
        label: ip,
        data: eachDayOfInterval({ start: selectedStartDate, end: selectedEndDate }).map(date => {
          const formattedDate = format(date, PROGRAMMATIC_FORMAT)
          if (!totalByDate.hasOwnProperty(formattedDate)) {
            totalByDate[formattedDate] = 0
          }
          const dateData = datumByDate[networkId][formattedDate]
          const value = resolution !== undefined
            ? get(dateData, ['percentage', cdn, resolution, ip])
            : get(dateData, ['percentage', cdn, ip])
          if (!value) {
            totalByDate[formattedDate] += 0
            return null
          }
          // Save it to totals array
          totalByDate[formattedDate] += value
          return value
        })
      }
    })
    data.push(getFillersGroup(totalByDate))

    // Sort by largest to smallest
    const sortedData = data.slice().sort((a, b) => {
      const firstAValue = a.data[0]
      const firstBValue = b.data[0]
      if (firstAValue > firstBValue) {
        return -1
      }
      if (firstAValue < firstBValue) {
        return 1
      }
      return 0
    })

    return {
      ...group,
      data: sortedData.filter(ipGroupHasData)
    }
  }

  return { addGroupLabels, addGroupData }
}

function isThereGroupData (group) {
  if (group.data.length === 1 && group.data[0].label === 'Others') {
    return false
  }
  return true
}

export function formatIpDataForDistCharts (chartData, selectedStartDate, selectedEndDate) {
  const datumByDate = groupDataByDate(chartData)
  const { addGroupData, addGroupLabels } = getFormattingFunctions(chartData, selectedStartDate, selectedEndDate, datumByDate)
  return getGroupKeys(chartData)
    .map(addGroupId)
    .map(addGroupData)
    .map(addGroupLabels)
    .filter(isThereGroupData)
}

export function formatVideoIpDataForDistCharts (chartData, selectedStartDate, selectedEndDate) {
  const datumByDate = groupDataByDate(chartData)
  const { addGroupData, addGroupLabels } = getFormattingFunctions(chartData, selectedStartDate, selectedEndDate, datumByDate)
  return getResGroupKeys(chartData)
    .map(addGroupId)
    .map(addGroupData)
    .map(addGroupLabels)
    .filter(isThereGroupData)
}

export function formatIpTableData (data, endpointLabels, getNetworkData, metricKey, resolution = false) {
  return data.reduce((formattedData, datum) => {
    const network = getNetworkData(datum.canonical_network_id)
    if (!network) {
      return formattedData
    }
    const endPoints = Object.keys(datum.mean)
    endPoints.forEach(endpoint => {
      const endPointData = datum.mean[endpoint]

      if (resolution) {
        const resolutions = Object.keys(endPointData)
        resolutions.forEach(resolution => {
          const resolutionData = datum.mean[endpoint][resolution]
          const IPs = Object.keys(resolutionData)
          IPs.forEach(IP => {
            formattedData.push({
              operator: network.name_mapped,
              endPoint: endpointLabels[endpoint],
              ip: IP,
              date: datum.date,
              percentageOfTests: `${datum.percentage[endpoint][resolution][IP].toFixed(2)}%`,
              value: `${endPointData[resolution][IP]}`,
              metricKey: metricKey || '',
              resolution: resolution
            })
          })
        })
      } else {
        const IPs = Object.keys(endPointData)
        IPs.forEach(IP => {
          const percentage = get(datum, ['percentage', endpoint, IP])
          formattedData.push({
            operator: network.name_mapped,
            endPoint: endpointLabels[endpoint],
            ip: IP,
            date: datum.date,
            percentageOfTests: `${percentage}%`,
            value: `${endPointData[IP]}`,
            metricKey: metricKey || ''
          })
        })
      }
    })
    return formattedData
  }, [])
}

export function mergeDataByIps (data, getMetricData) {
  const generateEntryKey = ({ operator, endPoint, ip, resolution, percentageOfTests }) => {
    if (resolution) {
      return `${operator}_${endPoint}_${ip}_${resolution}`
    }
    return `${operator}_${endPoint}_${ip}_${percentageOfTests}`
  }

  const formattedData = {}
  data.forEach(ipScan => {
    const key = generateEntryKey(ipScan)
    const metricKey = ipScan.metricKey.substr(0, ipScan.metricKey.indexOf('_'))
    const metricUnit = getMetricData ? getMetricData(metricKey).unit : ''
    if (formattedData[key]) {
      formattedData[key][metricKey] = `${parseFloat(ipScan.value).toFixed(2)}${metricUnit}`
    } else {
      formattedData[key] = {
        [metricKey]: `${parseFloat(ipScan.value).toFixed(2)}${metricUnit}`,
        ...ipScan
      }
    }
  })
  return formattedData
}
