<template>
  <div class="KitchenSink">
    <div class="KitchenSink__logo">
      <img src="../assets/logo.svg">
    </div>
    <h1>Kitchen Sink</h1>
    <h2>Global</h2>
    <h3>Typography</h3>
    <div>...</div>
    <h3>Colours</h3>
    <div>...</div>
    <h2>Components</h2>
    <h3>Performance Menu</h3>
    <performance-menu
      :products="products"
      :dashboard-info="dashboardInfo"
      :options="{dashboard: 'performance'}"
      :metric="metric"
      :location="location"
      :locations="locations"
      :categories="polygons"
      :agg="setAgg"
      :date="setDate"
      :networks="networkOperators"
    />
    <h3>Operators &amp; Visualisation com</h3>
    <operators-selector
      :confidence="true"
      :networks="networkOperators"
      style="width:295px;"
    />
    <h3
      class="menuTitle"
      style="width: 295px;"
    >
      <span class="menuTitle__label">Operators &amp; Visualisation</span>
    </h3>
    <div
      class="OperatorsSelector"
    >
      <div class="OperatorsSelector__group">
        <div class="OperatorsSelector__operator selected">
          <span
            class="OperatorsSelector__visual"
            style="backgroundColor: #e11e00; color: #fff;"
          >V</span>
          <span class="OperatorsSelector__label">Vodafone</span>
        </div>
        <div class="OperatorsSelector__operator selected">
          <span
            class="OperatorsSelector__visual"
            style="backgroundColor: #25aeaa; color: #fff;"
          >E</span>
          <span class="OperatorsSelector__label">EE</span>
        </div>
        <div class="OperatorsSelector__operator selected">
          <span
            class="OperatorsSelector__visual"
            style="backgroundColor: #424fc1; color: #fff;"
          >O</span>
          <span class="OperatorsSelector__label">O2</span>
        </div>
        <div class="OperatorsSelector__operator">
          <span
            class="OperatorsSelector__visual"
            style="backgroundColor: #393c41; color: #fff;"
          >3</span>
          <span class="OperatorsSelector__label">3</span>
        </div>
      </div>
      <div class="OperatorsSelector__confidence selected">
        <div class="OperatorsSelector__confidence--checkbox">
          <FontAwesomeIcon :icon="icons.faCheck" />
        </div>
        <span class="OperatorsSelector__confidence--label">View Confidence Intervals</span>
      </div>
    </div>
    <h3>PI Categories</h3>
    <div style="width:295px;">
      <categories-tabs
        v-model="categorie"
      />
    </div>
    <h3>PI Metrics User Experience</h3>
    <metrics-selector
      v-model="metric"
    />
    <div
      class="MetricsSelector"
      style="width: 200px"
    >
      <div
        class="MetricsSelector__technologyTab"
      >
        <div
          :class="{'MetricsSelector__technology--active': true}"
          class="MetricsSelector__technology"
        >
          4G
        </div>
        <div
          :class="{'MetricsSelector__technology--active': false}"
          class="MetricsSelector__technology"
        >
          3G
        </div>
      </div>
      <div
        :class="{'MetricsSelector__metric--expanded': true}"
        class="MetricsSelector__metric"
      >
        <div
          :class="{'MetricsSelector__mainmetric--expanded': true}"
          class="MetricsSelector__mainmetric"
        >
          Speed
        </div>
        <div
          :class="{'MetricsSelector__submetric--active': true}"
          class="MetricsSelector__submetric"
        >
          Download Speed
        </div>
        <div
          :class="{'MetricsSelector__submetric--active': false}"
          class="MetricsSelector__submetric"
        >
          Upload Speed
        </div>
      </div>
      <div
        class="MetricsSelector__metric"
      >
        <div
          :class="{'MetricsSelector__mainmetric--active': false}"
          class="MetricsSelector__mainmetric"
        >
          Latency
        </div>
      </div>
      <div
        :class="{'MetricsSelector__metric--expanded': true}"
        class="MetricsSelector__metric"
      >
        <div
          :class="{'MetricsSelector__mainmetric--expanded': true}"
          class="MetricsSelector__mainmetric"
        >
          Video
        </div>
        <div
          :class="{'MetricsSelector__submetric--active': false}"
          class="MetricsSelector__submetric"
        >
          Video 1
        </div>
        <div
          :class="{'MetricsSelector__submetric--active': false}"
          class="MetricsSelector__submetric"
        >
          Video 2
        </div>
        <div
          :class="{'MetricsSelector__submetric--active': false}"
          class="MetricsSelector__submetric"
        >
          Video 3
        </div>
      </div>
    </div>

    <h3>CustomText</h3>
    <div style="width: 15%">
      <div>truncate</div>
      <div style="background-color: #E2E2E2; padding: 5px">
        <CustomText truncate="">
          {{ text1 }}
        </CustomText>
      </div>
      <br>
      <div>capitalize</div>
      <div style="display: flex; background-color: #E2E2E2; padding: 5px">
        <div style="flex: 1; min-width: 0;">
          <CustomText capitalize>
            {{ text1 }}
          </CustomText>
        </div>
      </div>
    </div>
    <h3>Pill</h3>
    <div>
      <pill
        :value-comparison="pill1.difference"
        :options="pill1.options"
        :previous-value="pill1.previousValue"
        :rank="pill1.rank"
        :rank-comparison="pill1.rankComparison"
        :select-option="() => {}"
        :theme="pill1.theme"
        :title="pill1.title"
        :unit="pill1.unit"
        :value="pill1.value"
        :lci="pill1.lci"
        :uci="pill1.uci"
        metric-key="download"
      />
    </div>
    <h3>Slider</h3>
    <div>
      <range-slider
        :right-value="slider.top"
        :left-value="slider.bottom"
      />
    </div>
    <h3>BarListItem</h3>
    <div>
      <bar-list-item
        v-for="item in barListItems"
        :key="item.name"
        :name="item.name"
        :value="item.value"
        :lci="item.lci"
        :uci="item.uci"
        :min="item.minLci"
        :max="item.maxUci"
        :color="item.color"
        :unit="item.unit"
      />
    </div>
    <h3>ColourScale</h3>
    <div style="position: relative; height: 25px">
      <colour-scale
        :inverted="false"
        :labels="[100, 50, 0]"
      />
    </div>
    <h3>HighlightBar + HighlightCard</h3>
    <div>
      <highlight-bar>
        <highlight-card
          :label="'Label 01'"
          :value="90.2"
          :lci="10.3"
          :uci="99.1"
          :percentage="0.1"
          :positive="false"
          :unit="'Mbps'"
          :color="'orange'"
        />
        <highlight-card
          :label="'Label 02'"
          :value="90.2"
          :lci="10.3"
          :uci="99.1"
          :percentage="-0.4"
          :positive="true"
          :unit="'%'"
          :color="'blue'"
          disabled
        />
        <highlight-card
          :label="'Label 03'"
          :value="undefined"
          :lci="undefined"
          :uci="undefined"
          :percentage="undefined"
          :positive="undefined"
          :unit="undefined"
          :color="undefined"
        />
      </highlight-bar>
    </div>
    <h3>PerformanceTabs*</h3>
    <div style=" background-color: rgba(0,0,0,.2); padding: 1rem">
      <performance-tabs>
        <performance-tabs-title
          v-for="t in tabs"
          :key="t.title"
          :active="t.active"
        >
          {{ t.title }}
        </performance-tabs-title>
      </performance-tabs>
      <performance-tabs-content>
        <performance-tabs-panel
          v-for="t in tabs"
          :key="t.title"
        >
          {{ t.content }}
        </performance-tabs-panel>
      </performance-tabs-content>
    </div>
    <!-- <h3>MultiBarChart</h3>
    TODO: Stop commented components from throwing errors
    <div style="height: 500px; width: 800px;">
      <ChartWrapper
        :picture-meta="{endDate:'23', aggregation:'90d', geography:'hello world'}"
        :loading="false"
        :empty="false"
        title="kitchen chart"
        unit="s"
        toggle-layer
        toggle-numbers
        @toggle="chartToggle = !chartToggle"
        @numbers="chartNumbers = !chartNumbers"
      >
        <multi-bar-chart
          :data-set="multiChartData.data"
          :max="multiChartData.max"
          :min="multiChartData.min"
          :labels="multiChartData.labels"
          :rainbow="chartToggle"
          :bar-labels="chartNumbers"
          x-axis-label="x axis"
          y-axis-label="y axis"
          chart-id="multichart"
          show-vertical-labels
        />
      </ChartWrapper>
    </div>
    <h3>CorrelationChart</h3>
    <div style="width: 800px;">
      <ChartWrapper
        :picture-meta="{ networks: []}"
        :loading="false"
        :empty="false"
        title="CorrelationChart KitchenSink"
      >
        <correlation-chart
          :labels="correlationChart.labels"
          :x-axis-label="correlationChart.yAxisLabel"
          :y-axis-label="correlationChart.xAxisLabel"
          :selected-data-point="correlationChart.selectedDataPoint"
          :colors="correlationChart.colors"
          :data-set="correlationChart.dataSet"
          chart-id="correlation-chart-kitchen-sink"
          @click="handleCorrelationChartClick"
        />
      </ChartWrapper>
      <div v-if="selectedDataPointLabel">
        <strong>Selected</strong>:
        <span :style="{color: correlationChart.selectedDataPoint.backgroundColor}">
          {{ selectedDataPointLabel }}
        </span>
      </div>
    </div> -->
    <h3>Select Growth Strategy</h3>
    <div style="width: 18rem;">
      <SelectStrategy
        v-model="selectedGrowthStrategies"
        :options="growthStrategies"
      />
    </div>
    <h3>Brand and competitors</h3>
    <div>
      <div style="background: black; width: 200px">
        <FieldGroup
          label="Brand"
          theme="mg"
        >
          <brand-selector
            v-model="selectedNetworks"
            :single="true"
            :home-network="homeNetwork"
            :networks="networks"
            theme="mg"
          />
        </FieldGroup>
      </div>
    </div>
    <colour-scales-example />
    <h3>Province Selector</h3>
    <div class="ParametersLayout__aggregation">
      <FieldGroup
        slot="aggregation"
        :disabled="aggregationOptions.length < 2"
        label="Aggregation"
      >
        <SelectCheckboxInput
          v-model="selectedProvinces"
          :options="aggregationOptions"
        />
      </FieldGroup>
    </div>
    <h3>List Header Tooltip Content</h3>
    <div>
      <ListHeaderTooltipContent title="Good availability" />
    </div>
    <h3>RadioItem</h3>
    <div style="width: 25%">
      <radio-items>
        <FieldGroup
          v-for="group in radioItemsGroups"
          :key="group"
          :slot="group"
          :label="`Group: ${group}`"
        >
          <radio-item
            v-for="item in radioButtonsItems[group]"
            :key="item.label"
            :selected="item.selected"
            :name="group"
            @click="handleSelectRadioItem(group, item)"
          >
            <OperatorAvatar
              slot="icon"
              :letter="item.label[0]"
              :background-color="item.backgroundColor"
              size="sm"
              border-light
            />
            {{ item.label }}
          </radio-item>
        </FieldGroup>
      </radio-items>
    </div>
    <!-- <h3>Distribution</h3>
    <div style="width: 300px;">
      <ChartWrapper
        :picture-meta="{}"
        :loading="false"
        :empty="false"
        title="Distribution"
        unit="s"
      >
        <distribution
          :labels="miniData.labels"
          :data-set="miniData.datasets"
          chart-id="distribution-kitchen-sink"
        />
      </ChartWrapper>
    </div> -->
  </div>
</template>

<script>
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BarListItem from '@/components/comparison/BarListItem'
import RadioItem from '@/components/comparison/RadioItem'
import RadioItems from '@/components/comparison/RadioItems'
import CustomText from '@/components/CustomText'
import ColourScalesExample from '@/components/kitchen-sink/ColourScalesExample'
import BrandSelector from '@/components/market-growth/BrandSelector'
import ListHeaderTooltipContent from '@/components/market-growth/ListHeaderTooltipContent'
import SelectStrategy from '@/components/market-growth/SelectStrategy'
import {
  FieldGroup,
  OperatorsSelector
} from '@/components/menu'
import OperatorAvatar from '@/components/OperatorAvatar'
import CategoriesTabs from '@/components/performance/CategoriesTabs'
import HighlightCard from '@/components/performance/HighlightCard'
import HighlightBar from '@/components/performance/HighlightsBar'
import MetricsSelector from '@/components/performance/MetricsSelector'
import PerformanceTabs from '@/components/performance/PerformanceTabs'
import PerformanceTabsContent from '@/components/performance/PerformanceTabsContent'
import PerformanceTabsPanel from '@/components/performance/PerformanceTabsPanel'
import PerformanceTabsTitle from '@/components/performance/PerformanceTabsTitle'
import Pill from '@/components/Pill'
import RangeSlider from '@/components/RangeSlider'
import SelectCheckboxInput from '@/components/SelectCheckboxInput'
import PerformanceMenu from '@/components/specialized/PerformanceMenu'
import {
  ColourScale
} from '@/components/visual'

const random = () => 40 - Math.floor(Math.random() * 81)

export default {
  name: 'KitchenSink',
  components: {
    ListHeaderTooltipContent,
    FieldGroup,
    FontAwesomeIcon,
    OperatorAvatar,
    RadioItems,
    RadioItem,
    ColourScalesExample,
    PerformanceTabsTitle,
    PerformanceTabsPanel,
    PerformanceTabsContent,
    PerformanceTabs,
    HighlightCard,
    HighlightBar,
    ColourScale,
    BarListItem,
    RangeSlider,
    CustomText,
    Pill,
    MetricsSelector,
    CategoriesTabs,
    PerformanceMenu,
    OperatorsSelector,
    SelectCheckboxInput,
    SelectStrategy,
    BrandSelector
  },
  props: {},
  data () {
    // var color = Chart.helpers.color
    const points = 12
    return {
      // Brand selector
      selectedNetworks: [],
      homeNetwork: {
        canonical_network_id: 7220,
        country_iso3: 'ARG',
        name_mapped: 'Movistar',
        hex_color: 'A1C400',
        logo_url_small: 'https://assets.opensignal.com/network_rank/prod/44x44/7220_44x44.png',
        logo_url_large: 'https://assets.opensignal.com/network_rank/prod/66x66/7220_66x66.png' },
      // add .label property
      networks: [{ 'canonical_network_id': 7220,
        'country_iso3': 'ARG',
        'name_mapped': 'Movistar',
        'hex_color': 'A1C400',
        'logo_url_small': 'https://assets.opensignal.com/network_rank/prod/44x44/7220_44x44.png',
        'logo_url_large': 'https://assets.opensignal.com/network_rank/prod/66x66/7220_66x66.png',
        'selected': true },
      { 'canonical_network_id': 7222,
        'country_iso3': 'ARG',
        'name_mapped': 'Personal',
        'hex_color': '0BAFC7',
        'logo_url_small': 'https://assets.opensignal.com/network_rank/prod/44x44/7222_44x44.png',
        'logo_url_large': 'https://assets.opensignal.com/network_rank/prod/66x66/7222_66x66.png',
        'selected': true },
      { 'canonical_network_id': 7224,
        'country_iso3': 'ARG',
        'name_mapped': 'Claro',
        'hex_color': 'BF333B',
        'logo_url_small': 'https://assets.opensignal.com/network_rank/prod/44x44/7224_44x44.png',
        'logo_url_large': 'https://assets.opensignal.com/network_rank/prod/66x66/7224_66x66.png',
        'selected': true },
      { 'canonical_network_id': -1,
        'hex_color': 'C0B5D2',
        'name_mapped': 'Average',
        'selected': true }],
      // Province selector
      selectedProvinces: [],
      aggregation: 'all',
      aggregationOptions: [
        { label: 'AB, Alberta', value: 'AB' },
        { label: 'BC, British Columbia', value: 'BC' },
        { label: 'MB, Manitoba', value: 'MB' },
        { label: 'NB, New Brunswick', value: 'NB' },
        { label: 'NL, Newsoundland and Labrador', value: 'NL' },
        { label: 'NS, Nova Scotia', value: 'NS' },
        { label: 'ON, Ontario', value: 'ON' },
        { label: 'PE, Prince Edward Island', value: 'PE' },
        { label: 'QC, Quebec', value: 'QC' },
        { label: 'SK, Saskatchewan', value: 'SK' }
      ],
      // growth strategy
      selectedGrowthStrategies: [],
      growthStrategies: [
        { label: 'A', value: 'Attack', color: '#BC0000' },
        { label: 'H', value: 'Harvest', color: '#009076' },
        { label: 'D', value: 'Defend', color: 'orange' }, // TODO: Get actual color
        { label: 'O', value: 'Opportunistic', color: 'blue' } // TODO: Get actual color
      ],
      // others
      winnersData: [{
        name_mapped: 'kkk',
        hex_color: 'f2a',
        canonical_network_id: 3,
        mean: 80
      }],
      text1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      pill1: {
        difference: 0.85,
        previousValue: 10.44,
        rank: '2nd',
        rankComparison: -1,
        theme: 'purple',
        title: '4G Download',
        unit: 'Mbps',
        value: 11.29,
        lci: 10.111,
        uci: 12.222,
        options: [
          {
            dashboards: ['competitive', 'performance'],
            name: '3G Download',
            key: 'download_3g',
            type: '3g',
            units: { 'short': 'Mbps', 'full': 'Megabits per second' },
            bigger_is_better: true
          }
        ]
      },
      slider: { bottom: 10, top: 90 },
      selectedRadioItems: { top: undefined, bottom: undefined },
      radioItemsGroups: ['top', 'bottom'],
      correlationChart: {
        selectedDataPoint: undefined,
        dataSet: [{
          label: 'Operator A',
          // borderColor: window.chartColors.red,
          backgroundColor: '#A1C400',
          data: Array.from({ length: points }, (v, k) => ({
            x: random(),
            y: random()
          }))
        }, {
          label: 'Operator B',
          // borderColor: window.chartColors.blue,
          backgroundColor: '#0BAFC7',
          data: Array.from({ length: points }, (v, k) => ({
            x: random(),
            y: random()
          }))
        },
        {
          label: 'Operator C',
          // borderColor: window.chartColors.blue,
          backgroundColor: '#BF333B',
          data: Array.from({ length: points }, (v, k) => ({
            x: random(),
            y: random()
          }))
        }],
        colors: ['#A1C400', '#0BAFC7', '#BF333B'],
        value: ['Movistar', 'Personal', 'Claro'],
        xAxisLabel: 'x label',
        yAxisLabel: 'y label'
      },
      barListItems: [
        {
          name: 'First',
          value: 50,
          lci: 40,
          uci: 60,
          minLci: 1,
          maxUci: 100,
          color: 'red',
          unit: '%'
        },
        {
          name: 'First no lci/uci',
          value: 50,
          lci: undefined,
          uci: undefined,
          minLci: 1,
          maxUci: 100,
          color: 'red',
          unit: '%'
        },
        {
          name: 'Second',
          value: -108.5,
          lci: -114,
          uci: -105.25,
          minLci: -117.5,
          maxUci: -79,
          color: 'green',
          unit: 'dBm'
        },
        {
          name: 'Third',
          value: 10,
          lci: -15,
          uci: 45,
          minLci: -20,
          maxUci: 70,
          color: 'blue',
          unit: 'dBm'
        },
        {
          name: 'Fourth',
          value: 100,
          lci: 100,
          uci: 100,
          minLci: 50,
          maxUci: 102,
          color: 'orange',
          unit: '%'
        }
      ],
      tabs: [
        { title: 'Title A', content: 'Content A' },
        { title: 'Title B', content: 'Content B', active: true },
        { title: 'Title C', content: 'Content C' }
      ],
      chartToggle: false,
      chartNumbers: true,
      setMetric: 'download_lte',
      setCategorie: 'experience',
      icons: {
        faCheck
      },
      products: [
        {
          name: 'performance',
          route: '/',
          label: ['performance', 'inteligence']
        },
        {
          name: 'competitive',
          route: '/',
          label: ['competitive', 'inteligence']
        }
      ],
      dashboardInfo: {
        data_last_updated: '2019-03-10T00:00:00.000Z',
        first_date_available: '2018-05-31T00:00:00.000Z',
        last_date_available: '2019-03-10T00:00:00.000Z'
      },
      setLocation: '123',
      locations: [{
        name: 'two city',
        id: '2'
      }],
      polygons: [],
      setAgg: '90days',
      setDate: '2019-03-10T00:00:00.000Z',
      networkOperators: [
        {
          letter: 'X',
          label: 'Vodafone',
          id: 1234,
          color: '#00aa00',
          selected: true
        },
        {
          letter: 'P',
          label: 'O2',
          id: 1235,
          color: '#aa00aa',
          selected: true
        },
        {
          letter: 'G',
          label: '3',
          id: 1236,
          color: '#00aaaa',
          selected: true
        }
      ]
    }
  },
  computed: {
    metric: {
      get () {
        return this.setMetric
      },
      set (metric) {
        this.setMetric = metric
        console.log(metric)
      }
    },
    categorie: {
      get () {
        return this.setCategorie
      },
      set (cat) {
        this.setCategorie = cat
        console.log(cat)
      }
    },
    agg: {
      get () {
        return this.setAgg
      },
      set (agg) {
        this.setAgg = agg
        console.log(agg)
      }
    },
    date: {
      get () {
        return this.setDate
      },
      set (date) {
        this.setDate = date
        console.log(date)
      }
    },
    location: {
      get () {
        return this.setLocation
      },
      set (location) {
        this.setLocation = location
        console.log(location)
      }
    },
    miniData () {
      return {
        labels: ['Vodafone', 'EE', 'O2', '3'],
        datasets: [{
          label: 'Good',
          data: [50, 20, 30, 50],
          backgroundColor: 'rgba(25, 140, 30, 0.2)',
          borderColor: 'rgba(25, 140, 30, 1)',
          borderWidth: 2
        },
        {
          label: 'Average',
          data: [15, 20, 30, 10],
          backgroundColor: 'rgba(75, 190, 80, 0.2)',
          borderColor: 'rgba(75, 190, 80, 1)',
          borderWidth: 2
        },
        {
          label: 'Bad',
          data: [15, 20, 15, 25],
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 2
        },
        {
          label: 'Horrible',
          data: [20, 40, 25, 15],
          backgroundColor: 'rgba(255, 60, 14, 0.2)',
          borderColor: 'rgba(255, 60, 14, 1)',
          borderWidth: 2
        }]
      }
    },
    multiChartData () {
      const rawData = {
        data: {
          'net_uid_1': {
            data: [
              { lci: 21.89, uci: 31.45, y: 26.34, cdn: 'youtube', res: '360p' },
              { lci: 20, uci: 28, y: 25, cdn: 'akamai', res: '360p' },
              { lci: 21, uci: 25, y: 23, cdn: 'youtube', res: '720p' },
              { lci: 22, uci: 30, y: 25, cdn: 'akamai', res: '720p' }
            ],
            label: 'net_name_1',
            color: '#abc',
            stack: 1
          },
          'net_uid_2': {
            data: [
              { lci: 23.34, uci: 31.11, y: 25.09, cdn: 'youtube', res: '360p' },
              { lci: 20, uci: 26, y: 23, cdn: 'akamai', res: '360p' },
              { lci: 21, uci: 45, y: 22, cdn: 'youtube', res: '720p' },
              { lci: 24, uci: 40, y: 35, cdn: 'akamai', res: '720p' }
            ],
            label: 'net_name_2',
            color: '#cba',
            stack: 2
          },
          'net_uid_3': {
            data: [
              { lci: 24.22, uci: 35.76, y: 21.54, cdn: 'youtube', res: '360p' },
              { lci: 19, uci: 25, y: 24, cdn: 'akamai', res: '360p' },
              { lci: 20, uci: 35, y: 31, cdn: 'youtube', res: '720p' },
              { lci: 22, uci: 25, y: 24, cdn: 'akamai', res: '720p' }
            ],
            label: 'net_name_3',
            color: '#cad',
            stack: 3
          }
        },
        labels: {
          cdns: ['youtube', 'akamai'],
          res: ['360p', '720p']
        },
        min: 0,
        max: 45
      }

      return {
        ...rawData,
        data: Object.values(rawData.data || {})
      }
    },
    selectedDataPointLabel () {
      return JSON.stringify(this.correlationChart.selectedDataPoint && this.correlationChart.selectedDataPoint.data[0])
    },
    radioButtonsItems () {
      const { selectedRadioItems } = this
      const processItem = (name, item) => ({
        ...item,
        selected: (selectedRadioItems[name] && selectedRadioItems[name].label) === item.label
      })
      return {
        top: this.correlationChart.dataSet.map((i) => processItem('top', i)),
        bottom: this.correlationChart.dataSet.map((i) => processItem('bottom', i))
      }
    },
    compactLineChartData () {
      const points = [1, 2, 3, 4, 5, 6, 7]
      const operators = [{ name: 'A', color: '#A1C400' },
        { name: 'B', color: '#0BAFC7' }]
      return {
        labels: points,
        datasets: operators.map(o => ({
          label: `Operator ${o.name}`,
          backgroundColor: o.color,
          borderColor: o.color,
          data: points.map(random),
          fill: false
        }))
      }
    }
  },
  methods: {
    handleCorrelationChartClick (dataPoint) {
      console.log('handleCorrelationChartClick dataPoint=', dataPoint)
      const data = dataPoint && dataPoint.data && dataPoint.data[0]
      if (data) {
        this.correlationChart.selectedDataPoint = dataPoint
        console.log('handleCorrelationChartClick data=', data)
      }
    },
    handleSelectRadioItem (name, item) {
      console.log('handleSelectRadioItem')
      this.selectedRadioItems[name] = item
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';
@import '~foundation-sites/scss/foundation';

.KitchenSink {
  width: $content-min-width;
  margin: 1em auto;
  padding: 1em;
  position: relative;
  &__logo {
     img { width: 200px; }
    text-align: center;
  }
}

.MetricsSelector {
  min-width: 295px;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;

  &__metric {
    position: relative;
    border-radius: 2px;
    &--expanded {
      margin-bottom: 20px;
    }
    &--expanded::after {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      bottom: -5px;
      left: 5px;
      right: 5px;
      border: 1px solid #99B1C8;
      border-radius: 2px;
      z-index: -1;
    }
  }
  &__mainmetric {
    background-color: #FFF;
    cursor: pointer;
    color: #444A59;
    border-radius: 2px;
    padding: 13px 10px 13px 10px;
    margin: 16px 10px 16px 10px;
    &:first-child {
      margin-top: 10px;
    }
    &--active {
      background-color: #3BB1FF;
      color: white;
      cursor: default;
    }
    &--expanded {
      color: white;
      background-color: #99B1C8;
      margin-bottom: 10px;
    }
  }
  &__submetric {
    background-color: #FFF;
    cursor: pointer;
    color: #444A59;
    font-size: 12px;
    border-radius: 2px;
    padding: 10px;
    margin: 3px;
    margin-left: 10px;
    margin-right: 10px;
    &:last-child {
      margin-bottom: 10px;
    }
    &:first-child {
      margin-top: 10px;
    }
    &--active {
      background-color: #3BB1FF;
      color: white;
      cursor: default;
    }
  }
  &__technologyTab {
    margin: 10px;
  }
  &__technology {
    min-width: 90px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    background-color: #FFF;
    border-radius: 2px;
    padding: 10px;
    color: #444A59;

    &--active {
      background-color: #3BB1FF;
      color: white;
      cursor: default;
    }
  }
}

$padding: pxToRem(10);
$operatorSpacing: 2px;

.OperatorsSelector {
  @extend %defaultLabel;
  padding: $padding;

  &__group {
    width: pxToRem(279);
  }
  &__operator {
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    height: pxToRem(32);
    float: left;
    line-height: pxToRem(22);
    padding: pxToRem(5) pxToRem(7);
    width: calc(50% - #{$operatorSpacing} - 2px);

    &.selected {
      background: #98b0c7;
    }

    &:nth-child(2n+1) {
      margin-right: $operatorSpacing;
    }

    &:nth-child(n+3) {
      margin-top: $operatorSpacing;
    }

    &:nth-child(1) {
      border-top-left-radius: 6px;
    }

    &:nth-child(2) {
      border-top-right-radius: 6px;
    }

    &:nth-child(2n):nth-last-child(1) {
      border-bottom-right-radius: 6px;
    }

    &:nth-child(2n+1):nth-last-child(2) {
      border-bottom-left-radius: 6px;
    }

    // if there is one operator only
    &:nth-child(1):nth-last-child(1) {
      border-radius: 6px;
    }

    // if one operator on last row
    &:nth-child(2n+1):nth-last-child(1) {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      width: calc(100% - #{$operatorSpacing} - 4px);
    }
  }
  &__visual {
    $borderWidth: 1px;
    background: #000;
    border: $borderWidth solid #fff;
    border-radius: 50%;
    display: block;
    float: left;
    height: calc(#{pxToRem(22)} - #{$borderWidth * 2});
    line-height: calc(#{pxToRem(22)} - #{$borderWidth * 2});
    text-align: center;
    width: calc(#{pxToRem(22)} - #{$borderWidth * 2});
  }
  &__label {
    display: block;
    float: left;
    padding-left: pxToRem(8);

    .selected & {
      color: #fff;
    }
  }
  &__confidence {
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    height: pxToRem(32);
    float: left;
    line-height: pxToRem(22);
    padding: pxToRem(5) pxToRem(7);
    width: calc(50% - #{$operatorSpacing} - 2px);

    clear:both;
    width: auto;
    margin-top: pxToRem(10);
    border-radius: 6px;

    &--checkbox {
      width: pxToRem(16);
      height: pxToRem(16);
      border-radius: 5px;
      background: $color-brand-blue;
      margin-top: pxToRem(3);
      color: transparent;
      text-align: center;
      line-height: pxToRem(16);
      font-size: pxToRem(10);
      float: left;
    }

    .selected &--checkbox {
      color: #fff;
    }

    &--label {
      padding-left: 7px;
    }
  }

  &:after {
    clear: both;
    display: block;
    width: 100%;
    height: 0;
    content: '';
  }
}

.menuTitle {
  font-size: pxToRem(15);
  text-align: center;
  overflow: hidden;
  margin: 5px 0;
  &__label {
    color: #63758d;
    font-weight: 500;
    display: inline-block;
    padding: 0 pxToRem(9);
    position: relative;
  }

  &__label:after,
  &__label:before {
    display: block;
    width: 1000px;
    height: pxToRem(1);
    content: '';
    background: #758fa5;
    position: absolute;
    bottom: pxToRem(9);
    z-index: -1;
  }

  &__label:after {
    left: 100%;
  }

  &__label:before {
    right: 100%;
  }
}
</style>
