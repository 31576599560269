<template>
  <div
    class="PerformanceLayout"
    :class="{ 'PerformanceLayout--menuCollapsed': !isMenuVisible }"
  >
    <div class="PerformanceLayout__sidebar">
      <slot name="sidebar" />
    </div>
    <div class="PerformanceLayout__main">
      <div class="PerformanceLayout__header">
        <slot name="header" />
      </div>
      <div class="PerformanceLayout__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PerformanceLayout',
  components: {},
  props: {},
  computed: {
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible'
    })
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';
@import 'scss/components';

$main-min-width: 450px;

.PerformanceLayout {
  background-color: $color-blue-bg-light;
  min-height: 100vh;
  display: flex;

  &--menuCollapsed {
    .PerformanceLayout__sidebar {
      margin-left: $sidebar-collapsed-offset;
    }
    .PerformanceLayout__main {
      left: 15px;
    }
  }

  &__sidebar {
    @extend %sideBar;
  }

  &__main {
    @extend %mainContent;
    min-width: $content-pi-min-width;
    overflow: hidden;
    display: flex;
    flex-flow: column
  }

  &__header {
    width: 100%;
    min-width: $content-pi-min-width;
  }

  &__content {
    flex: 1;
  }
}
</style>
