<template>
  <div
    class="StrategyLegend"
  >
    <div class="StrategyLegend__title">
      STRATEGY RECOMMENDATIONS
    </div>

    <div
      v-for="(strategy, key) in strategies"
      :key="key"
      class="StrategyLegend__item"
    >
      <Indicator
        :strategy="key"
        :label="key[0]"
        size="small"
      />
      {{ strategy.label }}
    </div>
  </div>
</template>

<script>
import Indicator from './Indicator'
import { MG_STRATEGIES } from '@/constants/constants'

export default {
  name: 'StrategyLegend',
  components: {
    Indicator
  },
  data () {
    return {
      strategies: MG_STRATEGIES
    }
  }
}
</script>

<style scoped lang="scss">
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .StrategyLegend {
    color: #fff;

    &__title {
      color: rgba($color-white, .7);
      margin-bottom: 20px;
      font-size: $font-size-14;
    }

    &__item {
      overflow: hidden;
      margin-bottom: 5px;
      font-size: $font-size-12;
    }

    .Indicator {
      display: inline-flex;
      align-items: center;
      margin-right: 10px;
    }
  }
</style>
