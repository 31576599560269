<template>
  <div
    v-if="!theme"
    v-click-outside="close"
    class="DashboardSwitch"
  >
    <div
      :class="currentDashboardClass"
      class="DashboardSwitch__button"
      @click.prevent="toggleDropdown"
    >
      <div
        :class="dashboardLogoClass"
        class="DashboardSwitch__dashboard-logo"
      >
        <img
          :alt="hero && hero.label.join(' ')"
          :src="dashboardLogoImgSrc(hero && hero.name)"
        >
      </div>
      <div class="DashboardSwitch__title-wrapper">
        <div class="DashboardSwitch__open-signal-logo">
          <img
            alt="Opensignal Ltd"
            src="../../assets/logo.svg"
          >
        </div>
        <div class="DashboardSwitch__dashboard-title">
          {{ hero && hero.label.join(' ') }}
        </div>
      </div>
      <div class="DashboardSwitch__chevron">
        <FontAwesomeIcon
          :icon="icons.faChevronDown"
          size="xs"
        />
      </div>
    </div>
    <div
      :class="{ 'DashboardSwitch__dropdown--open': dropdownOpen && options}"
      class="DashboardSwitch__dropdown"
    >
      <div
        v-for="option in options"
        :key="option.name"
        class="DashboardSwitch__option"
        @click.prevent="() => { navigate(option.route) }"
      >
        <img
          :alt="hero && hero.label.join(' ')"
          :src="dashboardLogoImgSrc(option.name)"
          class="DashboardSwitch__product-logo"
        >
        {{ option.label.join(' ') }}
      </div>
      <div
        key="userGuide"
        class="DashboardSwitch__option DashboardSwitch__option--link"
        @click="onUserGuideClick()"
      >
        <img
          v-if="!userGuideDownloading"
          alt="User guide"
          src="../../assets/icons/user-guide.svg"
          class="DashboardSwitch__product-logo"
        >
        <loader-grid
          v-if="userGuideDownloading"
          size="small"
          theme="dark"
        />
        Download User guide
      </div>
      <div
        class="DashboardSwitch__option"
        @click.prevent="() => { signOut() }"
      >
        Sign Out
      </div>
    </div>
  </div>
  <div
    v-else
    v-click-outside="close"
    class="DashboardSwitch"
    :class="themeClass"
  >
    <div
      :class="currentDashboardClass"
      class="DashboardSwitch__button"
      @click.prevent="toggleDropdown"
    >
      <div
        :class="dashboardLogoClass"
        class="DashboardSwitch__dashboard-logo"
      >
        <img
          :alt="hero && hero.label.join(' ')"
          :src="dashboardLogoImgSrc(hero && hero.name)"
        >
      </div>
      <div class="DashboardSwitch__title-wrapper">
        <div class="DashboardSwitch__open-signal-logo">
          <img
            alt="Opensignal Ltd"
            src="../../assets/logo-ci.svg"
          >
        </div>
        <div class="DashboardSwitch__dashboard-title">
          {{ hero && hero.label.join(' ') }}
        </div>
      </div>
      <div class="DashboardSwitch__chevron">
        <FontAwesomeIcon
          :icon="icons.faChevronDown"
          size="xs"
        />
      </div>
    </div>
    <div
      :class="{ 'DashboardSwitch__dropdown--open': dropdownOpen && options}"
      class="DashboardSwitch__dropdown"
    >
      <div class="DashboardSwitch__options">
        <div
          v-if="hero && hero.name && hero.name !== 'market-growth'"
          class="DashboardSwitch__optionsSection"
        >
          <div class="DashboardSwitch__optionsSectionTitle">
            Competitive Intelligence Explained
          </div>
          <div
            key="userGuide"
            class="DashboardSwitch__option DashboardSwitch__option--link"
            @click="onUserGuideClick()"
          >
            <img
              v-if="!userGuideDownloading"
              alt="User guide"
              src="../../assets/icons/user-guide-ci.svg"
              class="DashboardSwitch__product-logo"
            >
            <loader-grid
              v-if="userGuideDownloading"
              size="small"
              theme="dark"
            />
            Download User guide
          </div>
        </div>
        <div
          v-if="options.length"
          class="DashboardSwitch__optionsSection"
        >
          <div class="DashboardSwitch__optionsSectionTitle">
            Other products
          </div>
          <div
            v-for="option in options"
            :key="option.name"
            class="DashboardSwitch__option"
            @click.prevent="() => { navigate(option.route) }"
          >
            <img
              :alt="hero && hero.label.join(' ')"
              :src="dashboardLogoImgSrc(option.name)"
              class="DashboardSwitch__product-logo"
            >
            {{ option.label.join(' ') }}
          </div>
        </div>
        <div class="DashboardSwitch__optionsSection">
          <div
            class="DashboardSwitch__option"
            @click.prevent="() => { signOut() }"
          >
            Sign Out
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapGetters } from 'vuex'
import LoaderGrid from '../LoaderGrid'

export default {
  name: 'DashboardSwitch',
  components: {
    FontAwesomeIcon,
    LoaderGrid
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    current: {
      type: String,
      default: ''
    },
    squaredCorners: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dropdownOpen: false,
      icons: {
        faChevronDown
      },
      userGuideDownloading: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'page/isMobile'
    }),
    themeClass () {
      return 'DashboardSwitch--' + this.theme
    },
    currentDashboardClass () {
      return {
        'DashboardSwitch__button--squared-corners': this.squaredCorners
      }
    },
    dashboardLogoClass () {
      return {
        'DashboardSwitch__dashboard-logo--squared-corners': this.squaredCorners
      }
    },
    options () {
      return this.products.filter(p => p.name !== this.current && !p.disabled)
    },
    hero () {
      return this.products.find(p => p.name === this.current)
    }
  },
  methods: {
    ...mapActions([
      'getUserGuide'
    ]),
    ...mapActions({
      toggleMenu: 'dashboard/toggleMenu'
    }),
    close () {
      this.dropdownOpen = false
    },
    toggleDropdown () {
      this.dropdownOpen = !this.dropdownOpen
    },
    dashboardLogoImgSrc (productId) {
      let src
      let postfix = this.theme ? '-' + this.theme : ''

      try {
        src = require(`../../assets/product/${productId}-logo-round${postfix}.svg`)
      } catch (e) {
        src = null
      }
      return src
    },
    onUserGuideClick () {
      if (!this.userGuideDownloading) {
        this.userGuideDownloading = true

        this.getUserGuide(this.hero.name).then(blob => {
          this.userGuideDownloading = false
          this.dropdownOpen = false

          let link = document.createElement('a')
          let date = new Date().toISOString().split('T')[0]

          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.hero.name}_user_guide_${date}.pdf`
          link.click()
        })
      }
    },
    navigate (route) {
      if (this.isMobile) {
        this.toggleMenu()
      }

      this.$emit('navigate', route)
    },
    signOut () {
      if (this.isMobile) {
        this.toggleMenu()
      }

      this.$emit('signout')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@material/elevation';
  @import 'scss/variables';
  @import '~foundation-sites/scss/foundation';

  .DashboardSwitch {
    position: relative;
    $padding: 0.3em;
    transition: $sidebar-transition;
    z-index: $z-index-dashboardSwitch;

    &__dashboard-title {
      color: $color-blue-header;
      font-size: $font-size-8;
      margin-top: -$font-size-8/4;
      font-weight: $font-weight-roboto-medium;
    }

    &__button {
      @include xy-grid($direction: horizontal, $wrap: false);
      align-items: center;
      background-color: $color-blue-bg-lighter;
      border-radius: calc($nav-height / 2) $border-radius-medium $border-radius-medium calc($nav-height/2);
      cursor: pointer;
      height: $nav-height;
      width: 100%;

      &:hover {
        background-color: $white;
      }

      &--squared-corners {
        border-radius: 0;
      }
    }

    &__dashboard-logo {
      $logo-gutter-size: 10px;
      @include xy-cell(shrink, $gutters: $logo-gutter-size);

      &--squared-corners {
        padding-left: calc($logo-gutter-size / 4);
      }

      img {
        max-height: calc(#{$nav-height} - #{$logo-gutter-size});
        vertical-align: middle;
      }
    }

    &__title-wrapper {
      @include xy-cell(auto);
    }

    &__chevron {
      @include xy-cell(shrink);
      color: $color-blue-header;
    }

    &__open-signal-logo {
      img {
        max-width: 100%;
        width: 130px;
      }
    }

    &__dropdown {
      @include dropdown-container;
      border-radius: $control-border-radius;
      width: 100%;
      padding: 0;
      top: 100%;
      max-height: 130px;
      margin-top: 0.2em;
      border: none;
      @include elevation($elevation-4);

      &--open {
        display: block;
        visibility: visible;
      }
    }

    &__option  {
      font-size: $font-size-12;
      font-weight: $font-weight-roboto-regular;
      line-height: $control-height;
      padding: 0 .5rem;
      border-bottom: 1px solid $color-gray-light;
      cursor: pointer;
      background-color: $color-white;

      &:first-child {
        border-radius: $control-border-radius $control-border-radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $control-border-radius $control-border-radius;
        border: none;
      }

      &:hover {
        background-color: $color-blue-bg-lighter;
      }

      &--link {
        img {
          max-height: 20px;
        }
      }

      .loader-grid {
        float: left;
        height: 32px;
        margin: 3px 9px 0 2px;
        height: 20px;
      }
    }
    &__product-logo {
      width: pxToRem(20px);
      vertical-align: middle;
    }

    &--ci {
      margin: (-$sidebar-padding) (-$sidebar-padding) 0 (-$sidebar-padding);
      padding: $sidebar-padding;
      background-color: #6C68B0;

      .DashboardSwitch__button {
        background: transparent;
        height: initial;
      }

      .DashboardSwitch__dashboard-logo {
        margin: 0 $sidebar-padding 0 0;

        img {
          width: 50px;
          height: 50px;
          max-height: initial;
        }
      }

      .DashboardSwitch__open-signal-logo {
        width: 130px;
      }

      .DashboardSwitch__dashboard-title {
        color: rgba($color-white, .7);
        font-size: $font-size-12;
        font-weight: $font-weight-roboto-regular;
      }

      .DashboardSwitch__chevron {
        color: $color-white;
      }

      .DashboardSwitch__dropdown {
        margin: 0;
        left: 0;
        background: $ci-purple;
        border-radius: 0;
        box-shadow: none;
        padding: 0 $sidebar-padding $sidebar-padding;
        box-sizing: border-box;
        max-height: initial;
        color: $color-white;
      }

      .DashboardSwitch__options {
        background-color: $ci-blue;
      }

      .DashboardSwitch__optionsSection {
        padding: $sidebar-padding;
        border-bottom: 1px solid rgba($color-white, .1);

        &:last-child {
          border-bottom: 0;
        }
      }

      .DashboardSwitch__optionsSectionTitle {
        color: rgba($color-white, .7);
        text-transform: uppercase;
        font-size: $font-size-10;
        margin-bottom: $sidebar-padding;
      }

      .DashboardSwitch__option {
        background-color: transparent;
        padding: 0;
        border-bottom: 0;
        line-height: 20px;
        height: 20px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .DashboardSwitch__product-logo {
        margin: -3px 3px 0 0;
      }
    }

    &--mg {
      margin: (-$sidebar-padding) (-$sidebar-padding) 0 (-$sidebar-padding);
      padding: $sidebar-padding;
      background-color: $mg-base;

      .DashboardSwitch__button {
        background: transparent;
        height: initial;
      }

      .DashboardSwitch__dashboard-logo {
        margin: 0 $sidebar-padding 0 0;

        img {
          width: 50px;
          height: 50px;
          max-height: initial;
        }
      }

      .DashboardSwitch__open-signal-logo {
        width: 130px;
      }

      .DashboardSwitch__dashboard-title {
        color: rgba($color-white, .7);
        font-size: $font-size-12;
        font-weight: $font-weight-roboto-regular;
      }

      .DashboardSwitch__chevron {
        color: $color-white;
      }

      .DashboardSwitch__dropdown {
        margin: 0;
        left: 0;
        background: $mg-base;
        border-radius: 0;
        box-shadow: none;
        padding: 0 $sidebar-padding $sidebar-padding;
        box-sizing: border-box;
        max-height: initial;
        color: $color-white;
      }

      .DashboardSwitch__options {
        background-color: $mg-offbase;
      }

      .DashboardSwitch__optionsSection {
        padding: $sidebar-padding;
        border-bottom: 1px solid rgba($color-white, .1);

        &:last-child {
          border-bottom: 0;
        }
      }

      .DashboardSwitch__optionsSectionTitle {
        color: rgba($color-white, .7);
        text-transform: uppercase;
        font-size: $font-size-10;
        margin-bottom: $sidebar-padding;
      }

      .DashboardSwitch__option {
        background-color: transparent;
        padding: 0;
        border-bottom: 0;
        line-height: 20px;
        height: 20px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .DashboardSwitch__product-logo {
        margin: -3px 3px 0 0;
      }
    }
  }
</style>
