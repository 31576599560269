<template>
  <ChartWrapper
    :picture-meta="pictureMeta"
    :selected-point="selectedPoint"
    :loading="chartPending"
    :empty="chartData.empty"
    :tooltip="chartData.tooltip"
    :unit="chartUnit"
    :title-labels="titleLabels"
    :show-confidence-rate="showConfidenceRate"
    :title="chartData.title"
    toggle-layer
    toggle-numbers
    @numbers="chartNumbers = !chartNumbers"
    @toggle="chartToggle = !chartToggle"
  >
    <group-bar-chart
      v-if="!chartPending && !chartData.empty"
      :data-set="Object.values(chartData.data)"
      :labels="chartData.labels"
      :max="chartData.max"
      :min="0"
      :rainbow="chartToggle"
      :bar-labels="chartNumbers"
      :show-confidence-rate="showConfidenceRate"
      :data-start-index="0"
      :y-axis-label="chartData.yAxisLabel"
      :x-axis-label="chartData.xAxisLabel"
      chart-id="bar"
      show-vertical-labels
    />
  </ChartWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { GroupBarChart, ChartWrapper } from '@/components/visual'

export default {
  name: 'ChartCombinedCategories',
  components: {
    ChartWrapper,
    GroupBarChart
  },
  props: {
    chart: {
      type: String,
      default: null
    },
    hero: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    agg: {
      type: String,
      default: '90days'
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chartToggle: false,
      chartNumbers: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInfo'
    ]),
    ...mapGetters({
      chartData: 'charts/combinedCategories',
      chartMetric: 'metrics/primaryMetric',
      chartPending: 'chart/chartPending',
      chartUnit: 'metrics/primaryUnit',
      currentLocation: 'locations/current',
      selectedNetworks: 'charts/selectedNetworkOperators',
      showConfidenceRate: 'dashboard/getConfidenceState',
      titleLabels: 'charts/titleLabels',
      selectedPoint: 'dashboard/selectedPoint'
    }),
    pictureMeta () {
      return {
        ...this.titleLabels,
        product: 'performance'
      }
    }
  }
}
</script>

<style lang="css">
</style>
