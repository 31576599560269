<template>
  <base-map
    ref="baseMap"
    :class="{ rightTooltip: displayTooltipOnTheRight }"
    :geo-json="processedGeoJson"
    :geo-json-overlay="processedGeoJsonOverlay"
    :selected="selected"
  >
    <l-geo-json
      v-if="!markers"
      ref="geolayer"
      :geojson="geoJsonData.geoJson"
      :options="geoJsonOptions"
    />
    <l-geo-json
      v-if="citiesAsPolygons"
      ref="geolayercities"
      :geojson="geoJsonOverlayData.geoJson"
      :options="geoJsonOptions"
    />
    <RankingTableTooltip
      :title="tooltipData.name"
      :display="!!activeLocation"
      :datapoints="tooltipData.data"
      :rank-hidden="!displayRank"
      :pos-class="tooltipClass"
    />
    <dynamic-colour-scale
      :ranges="theme.ranges"
      :unit="theme.unit"
      class="color-scale"
    />
  </base-map>
</template>

<script>
import 'leaflet.markercluster'
import { LGeoJson } from '@vue-leaflet/vue-leaflet'
import chroma from 'chroma-js'
import get from 'lodash.get'
import { mapGetters } from 'vuex'
import BaseMap from './BaseMap'
import DynamicColourScale from './DynamicColourScale'
import {
  getCentreOfFeature,
  getBucketColor,
  getMarkerIcon,
  getCustomClusterMarkerIcon,
  refineGeoJsonToHandle180thMeridian
} from './helpers'
import RankingTableTooltip from '@/components/visual/chart/RankingTableTooltip'
import router from '@/router'
import colors from '@/utils/colorPalette'

export default {
  name: 'ChoroplethMap',
  components: {
    BaseMap,
    LGeoJson,
    RankingTableTooltip,
    DynamicColourScale
  },
  props: {
    geoJson: {
      type: Object,
      default: () => {}
    },
    geoJsonOverlay: {
      type: Object,
      default: () => {}
    },
    choroplethData: {
      type: Array,
      default: () => []
    },
    choroplethCitiesData: {
      type: Array,
      default: () => []
    },
    markers: {
      type: Boolean,
      default: false
    },
    zoomed: {
      type: Boolean,
      default: false
    },
    biggerIsBetter: {
      type: Boolean,
      default: true
    },
    colourScaleLabels: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    },
    displayRank: {
      type: Boolean,
      default: false
    },
    displayTooltipOnTheRight: {
      type: Boolean,
      default: false
    },
    useGeoRankingColours: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noDataColor: 'DEE4EC',
      activeLocation: null,
      mapObject: null,
      mainCluster: null,
      citiesCluster: null,
      geoJsonOptions: {
        style: feature => {
          if (!feature.item || !Number.isFinite(feature.item.value)) {
            return {
              weight: 1,
              opacity: 1,
              color: 'white',
              dashArray: '',
              fillOpacity: 0.75,
              fillColor: '#' + this.noDataColor
            }
          }
          let fillColor

          if (this.useGeoRankingColours) {
            const geography = this.rankedGeographies.find(rankedFeature => rankedFeature.id === feature.id)
            if (geography && geography.item[0]) {
              const item = geography.item[0]
              fillColor = item.color
            } else {
              fillColor = chroma(colors.cityPinGreyColor).alpha(0.3).hex()
            }
          } else {
            fillColor = this.zoomed && feature.id !== parseInt(this.modelValue)
              ? chroma(getBucketColor(feature.item.value, this.theme.ranges)).alpha(0.1).hex()
              : getBucketColor(feature.item.value, this.theme.ranges)
          }
          return {
            weight: 2,
            opacity: 0.7,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.75,
            fillColor
          }
        },
        onEachFeature: (feature, layer) => {
          if (this.getDataForLocation(feature.id)) {
            layer.on({
              click: (e) => {
                this.$emit('click', e.target.feature.id)
                this.selectPostcode(e.target.feature.id)
              },
              mouseover: ({ target }) => {
                target.setStyle({
                  weight: 3,
                  dashArray: ''
                })

                this.activeLocation = target.feature.id
              },
              mouseout: ({ target }) => {
                target.setStyle({
                  weight: 2,
                  dashArray: '3'
                })

                this.activeLocation = null
              }
            })
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      focusedNetworkId: 'competitive/focusedNetworkId',
      rankedGeographies: 'competitive/rankedGeographies',
      homeNetwork: 'charts/homeNetwork',
      currentMetric: 'metrics/primaryMetric',
      theme: 'competitive/metricTheme',
      networks: 'operators',
      country: 'location/currentCountry',
      citiesAsPolygons: 'competitive/showPolygons',
      groupings: 'location/byGroupings'
    }),
    processedGeoJson () {
      return refineGeoJsonToHandle180thMeridian(this.geoJson, this.geoJson)
    },
    processedGeoJsonOverlay () {
      return refineGeoJsonToHandle180thMeridian(this.geoJsonOverlay, this.geoJson)
    },
    tooltipClass () {
      return this.displayTooltipOnTheRight
        ? 'RankingTableTooltip--right'
        : 'RankingTableTooltip--fixed'
    },
    overlayMarkers () {
      if (!this.processedGeoJsonOverlay) return []
      return this.processedGeoJsonOverlay.features.map(feature => {
        return {
          id: feature.id,
          centre: getCentreOfFeature(feature)
        }
      })
    },
    markerPoints () {
      if (!this.processedGeoJson) return []
      return this.processedGeoJson.features.map(feature => {
        return {
          id: feature.id,
          centre: getCentreOfFeature(feature)
        }
      })
    },
    // we need both references to force leaflet reupdate style
    geoJsonData () {
      const networkId = router.currentRoute.value.params.network
        ? router.currentRoute.value.params.network
        : this.focusedNetworkId
      return {
        geoJson: {
          ...this.processedGeoJson,
          features: this.processedGeoJson.features.map(feature => {
            return {
              ...feature,
              // isn't this already here?
              item: feature.item.find(a => a.network === networkId)
            }
          })
        },
        data: this.choroplethData
      }
    },
    geoJsonOverlayData () {
      return {
        geoJson: {
          ...this.processedGeoJsonOverlay,
          features: this.processedGeoJsonOverlay.features.map(feature => {
            return {
              ...feature,
              // isn't this already here?
              item: feature.item.find(a => a.network === router.currentRoute.value.params.network)
            }
          })
        },
        data: this.choroplethCitiesData
      }
    },
    tooltipData () {
      if (this.activeLocation) {
        const feature = [
          ...get(this.processedGeoJson, 'features', []),
          ...get(this.processedGeoJsonOverlay, 'features', [])
        ].find(feature => feature.id === this.activeLocation)

        const network = router.currentRoute.value.params.network
          ? router.currentRoute.value.params.network
          : this.homeNetwork.canonical_network_id

        const networkName = get(
          this.networks.find(n => `${n.canonical_network_id}` === `${network}`),
          'name_mapped',
          'none'
        )

        return {
          name: feature.properties.name,
          data: feature.item.filter(entry => entry.label === networkName)
        }
      }

      return {
        name: '',
        data: []
      }
    },
    selected () {
      return [
        ...get(this.processedGeoJson, 'features', []),
        ...get(this.processedGeoJsonOverlay, 'features', [])
      ].find(feature => feature.id === this.modelValue)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.mapObject = get(this.$refs, ['baseMap', '$children', '0', 'mapObject'])

      this.manageLayers()
    })
  },
  updated () {
    this.manageLayers()
  },
  methods: {
    getIcon (id) {
      const item = this.getDataForLocation(id)

      if (this.useGeoRankingColours) {
        const geography = this.rankedGeographies.find(rankedFeature => rankedFeature.id === id)
        if (geography && geography.item[0]) {
          const rankedItem = geography.item[0]
          return getMarkerIcon(rankedItem.color)
        }
        return getMarkerIcon(colors.cityPinGreyColor)
      }

      return item && getMarkerIcon(
        this.zoomed && item.location !== this.modelValue
          ? chroma(getBucketColor(item.mean, this.theme.ranges)).alpha(0.1).hex()
          : getBucketColor(item.mean, this.theme.ranges)
      )
    },
    getDataForLocation (id) {
      return [...this.choroplethData, ...this.choroplethCitiesData].find(x => x.location === id)
    },
    selectPostcode (id) {
      if (id === this.modelValue) {
        this.$emit('update:modelValue', parseInt(this.country.key))
      } else {
        this.$emit('update:modelValue', id)
      }
    },
    manageLayers () {
      if (this.mapObject) {
        if (this.citiesAsPolygons) {
          if (this.citiesCluster) {
            this.mapObject.removeLayer(this.citiesCluster)
          }

          if (this.mainCluster) {
            this.mapObject.removeLayer(this.mainCluster)
          }
        } else {
          if (this.markers) {
            this.renewMarkerCluster('mainCluster', this.markerPoints, 1)
          } else {
            this.renewMarkerCluster('citiesCluster', this.overlayMarkers, 6)
          }
        }
      }
    },
    renewMarkerCluster (cluster, points, zoomLevel) {
      if (this[cluster]) {
        this.mapObject.removeLayer(this[cluster])
      }

      this[cluster] = window.L.markerClusterGroup({
        disableClusteringAtZoom: zoomLevel,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        iconCreateFunction: getCustomClusterMarkerIcon
      })

      points.map(m => {
        let icon = this.getIcon(m.id)

        if (icon) {
          let hexColor
          const geography = this.rankedGeographies.find(rankedFeature => rankedFeature.id === m.id)
          if (geography && geography.item[0]) {
            const rankedItem = geography.item[0]
            hexColor = rankedItem.color
          }

          this[cluster].addLayer(window.L.marker(m.centre, {
            icon,
            hex_color: this.useGeoRankingColours ? hexColor : undefined,
            interactive: true,
            zIndexOffset: 1,
            // options available in events
            location: m.id
          }).on('click mouseover mouseout', e => {
            switch (e.type) {
              case 'click':
                this.$emit('click', e.target.options.location)
                this.selectPostcode(e.target.options.location)
                break
              case 'mouseover':
                this.activeLocation = e.target.options.location
                break
              case 'mouseout':
                this.activeLocation = null
                break
            }
          }))
        }
      })

      this.mapObject.addLayer(this[cluster])
    }
  }
}
</script>
<style lang="scss">
@import 'scss/variables';
.color-scale {
  top: 11px;
  right: 11px;
  position: absolute;
  z-index: $z-index-ai-left-menu;
}

.rightTooltip {
  .leaflet-control-zoom {
    margin-bottom: 100px !important;
  }
}
</style>
