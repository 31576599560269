<template>
  <div
    class="nav-item"
    @click="$emit('click')"
  >
    <div class="nav-item__icon">
      <slot name="icon">
        <img src="../../assets/icons/home.svg">
      </slot>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'NavigationButton'
}
</script>

<style scoped lang="scss">
@import "scss/variables";

.nav-item {
  height: 50px;
  width: 100%;
  background: $winner-gold;
  text-align: center;
  border-radius: $border-radius-small;
  padding: 5px 0;
  cursor: pointer;
  &__icon {
    font-size: 18px;
    padding: 7px 0 0;
  }
}
</style>
