<template>
  <div class="SimpleBarChart">
    <canvas
      :id="chartId"
      width="400"
      height="400"
    />
    <ranking-table-tooltip
      :pos-class="tooltip.posClass"
      :tooltip-style="tooltip.tooltipStyle"
      :datapoints="tooltip.datapoints"
      :display="tooltip.display"
      :title="tooltip.title"
    />
  </div>
</template>
<script>
import Chart from 'chart.js/auto'
import get from 'lodash.get'
import RankingTableTooltip from './RankingTableTooltip'
import {
  X_AXIS_BASE_STYLE,
  Y_AXIS_BASE_STYLE,
  SCALE_TITLE_STYLE
} from '@/constants/chartjs'
import {
  getTooltipState,
  getDistributionDatapointsByIndex
} from '@/utils/charts'

export default {
  name: 'SimpleBarChart',
  components: { RankingTableTooltip },
  props: {
    chartId: {
      default: 'simple-bar-chart',
      type: String
    },
    labels: {
      type: Array,
      default: () => []
    },
    dataSet: {
      type: Array,
      default: () => []
    },
    xAxisUnit: {
      type: String,
      default: undefined
    },
    xAxisLabel: {
      type: String,
      default: undefined
    },
    yAxisLabel: {
      type: String,
      default: undefined
    },
    yAxisMax: {
      type: Number,
      default: undefined
    },
    percentile: {
      type: Number,
      default: 95
    }
  },
  data () {
    return {
      chart: null,
      tooltip: {
        datapoints: [],
        display: false,
        posClass: '',
        tooltipStyle: {},
        unit: '',
        date: undefined
      }
    }
  },
  computed: {
    formattedDataSet () {
      return this.dataSet.map(set => {
        return {
          label: set.label,
          borderWidth: 0,
          backgroundColor: set.color,
          borderColor: set.color,
          data: set.data,
          hoverBorderWidth: 1.5
        }
      })
    },
    formattedLabels () {
      let labels = this.labels
      if (this.dataSet.length === 1) {
        const lastIndex = this.dataSet[0].data.length - [...this.dataSet[0].data].reverse().findIndex(i => i)
        labels = labels.slice(0, lastIndex)
      }

      return labels.slice(0, this.inPercentile.index + 1)
    },
    inPercentile () {
      const allData = this.dataSet.map(set => set.data)
      if (allData.length === 0) {
        return []
      }
      const data = allData[0].map((v, index) => allData.reduce((acc, col) => (acc + (isNaN(col[index]) ? 0 : col[index])), 0))
      const total = data.reduce((a, v) => a + v, 0)
      const areaPerc = this.percentile / 100
      return data.reduce((a, v, i, arr) => {
        if (a.sum < total * areaPerc) {
          a.sum += v
          a.index = i
          return a
        }

        return a
      }, {
        sum: 0,
        index: 0
      })
    }
  },
  watch: {
    dataSet () {
      const chart = this.getChart()
      if (!chart) return
      chart.data.datasets = this.formattedDataSet
      chart.data.labels = this.formattedLabels
      chart.options.scales.x.title.text = this.xAxisLabel
      chart.update()
    },
    yAxisMax () {
      if (!this.getChart()) return

      this.getChart().options.scales.y.max = this.yAxisMax <= 1 ? this.yAxisMax : Math.ceil(this.yAxisMax + this.yAxisMax / 5)
      this.getChart().update()
    },
    percentile () {
      if (!this.getChart()) return

      this.getChart().data.labels = this.formattedLabels
      this.getChart().update()
    }
  },
  mounted () {
    this.renderChart()
  },
  methods: {
    getChart () {
      return Chart.getChart(this.chartId)
    },
    renderChart () {
      // eslint-disable-next-line no-unused-vars
      const newChart = new Chart(this.chartId, {
        type: 'bar',
        data: {
          labels: this.formattedLabels,
          datasets: this.formattedDataSet
        },
        options: {
          barPercentage: 1,
          animation: {
            duration: 0
          },
          height: 400,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 10,
              right: 20,
              top: 10,
              bottom: 10
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              mode: 'x',
              intersect: false,
              position: 'nearest',
              callbacks: {
                title: function (item) {
                  return item
                }
              },
              external: (context) => {
                const tooltip = context.tooltip
                const tooltipState = getTooltipState(this.getChart(), tooltip)
                const datapoints = getDistributionDatapointsByIndex(this.formattedDataSet, get(tooltip, ['title', 0, 'dataIndex']))

                const { xAxisUnit } = this

                const xLabel = get(tooltip, ['title', 0, 'label'])
                const title = `${xLabel} ${xAxisUnit && xAxisUnit !== '0 - 100' ? `${xAxisUnit} ` : ' '}bin`

                if (datapoints && xLabel) {
                  this.tooltip = {
                    ...this.tooltip,
                    ...tooltipState,
                    datapoints: datapoints.map(d => ({
                      ...d,
                      value: d.value.toFixed(2)
                    })),
                    title: title
                  }
                } else {
                  this.tooltip.display = false
                }
              }
            }
          },
          scales: {
            y: {
              grid: {
                ...Y_AXIS_BASE_STYLE.gridLines,
                drawBorder: false,
                offset: false,
                drawTicks: false
              },
              ticks: {
                ...Y_AXIS_BASE_STYLE.ticks,
                callback: (c, i) => {
                  return i > 0 ? c : ''
                },
                padding: 10,
                ...(!isFinite(this.yAxisMax)
                  ? {}
                  : {
                    max: this.yAxisMax <= 1 ? this.yAxisMax : Math.ceil(this.yAxisMax + this.yAxisMax / 5)
                  })
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.yAxisLabel,
                text: this.yAxisLabel
              }
            },
            x: {
              grid: {
                ...X_AXIS_BASE_STYLE.gridLines,
                display: false,
                drawTicks: false
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.xAxisLabel,
                text: this.xAxisLabel
              },
              ticks: {
                padding: 10,
                color: X_AXIS_BASE_STYLE.ticks.color
              }
            }
          }
        }
      })
    }
  }
}
</script>
