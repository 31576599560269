import chroma from 'chroma-js'
import * as L from 'leaflet'
import { dataValues, sortValues } from '@/utils/data'

const colors = ['#AB4C7D', '#C5787D', '#E5B608', '#7FB982', '#56A67D']

export function normalizeValue (value, min, max) {
  return (value - min) / (max - min)
}

export function getMin (array, key) {
  return Math.min(...dataValues(array, key))
}

export function getMax (array, key) {
  return Math.max(...dataValues(array, key))
}

export function getPercentile (array, percentile, key, biggerIsBetter) {
  if (array.length === 0) return 0

  const valuesList = sortValues(dataValues(array, key), biggerIsBetter)
  const p = percentile / 100

  if (p <= 0) return valuesList[0]
  if (p >= 1) return valuesList[array.length - 1]

  const index = (valuesList.length - 1) * p
  const lower = Math.floor(index)
  const upper = lower + 1
  const weight = index % 1

  if (upper >= valuesList.length) return valuesList[lower]

  return Math.round((valuesList[lower] * (1 - weight) + valuesList[upper] * weight) * 100) / 100
}

export function getColor (value, min, max, reverse = false, median) {
  let colorsInUse = [...colors]

  if (reverse) {
    colorsInUse.reverse()
  }

  if (median && value >= median) {
    return chroma.scale(colorsInUse.slice(3))
      .mode('lch')(normalizeValue(value, median, max))
      .hex()
  } else if (median) {
    return chroma.scale(colorsInUse.slice(0, 2))
      .mode('lch')(normalizeValue(value, min, median))
      .hex()
  }

  return chroma.scale(colorsInUse)
    .mode('lch')(normalizeValue(value, min, max))
    .hex()
}

export function getBucketColor (value, buckets) {
  return buckets.find(b => value >= b.min && value < b.max).color
}

export function getWidth (point, min, max) {
  return `${(point - min + 20) * 100 / (max - min + 20)}%`
}

export function getCentreOfFeature (feature) {
  return [feature.center[1], feature.center[0]]
}

export function geoJsonMeta (geoJson, heightModifier, widthModifier) {
  const west = geoJson.bbox[2] < 180 ? geoJson.bbox[0] : -360 + geoJson.bbox[0]
  const east = geoJson.bbox[2] < 180 ? geoJson.bbox[2] : -360 + geoJson.bbox[2]
  const height = geoJson.bbox[3] - geoJson.bbox[1]
  const width = geoJson.bbox[2] - geoJson.bbox[0]
  const centre = (geoJson.bbox[3] + geoJson.bbox[1]) / 2
  const centreV = (west + east) / 2

  const safeCentre = isFinite(centre) && isFinite(centreV)
    ? [ centre, centreV ] : [ 0, 0 ]

  return {
    center: safeCentre,
    // the modifier is a percentage area added around
    maxBounds: [
      [geoJson.bbox[3] + height * heightModifier, east + width * widthModifier],
      [geoJson.bbox[1] - height * heightModifier, west - width * widthModifier]
    ]
  }
}

export function getMarkerIcon (color) {
  return L.icon({
    iconUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='19' view-box='0 0 15 19'%3E%3Cpath fill='${color.replace(/#/, '%23')}' stroke='white' stroke-width='0.5' d='M 7.5, 19 C -17.5 -6, 32.5 -6, 7.5 19 Z'%3E%3C/path%3E%3C/svg%3E%0A`,
    iconSize: [15 * 1.5, 19 * 1.5],
    iconAnchor: [15, 19 * 1.5]
  })
}

function buildMarker (groupId, groupName, color = '#92a5c2', transform = null) {
  return `
  <g id="${groupId}" data-name="${groupName}" transform="${transform}">
    <path d="M6.783,17.25C-15.734-5.417,29.3-5.417,6.783,17.25Z" transform="translate(425.717 324.75)" fill="${color}" stroke="#fff" stroke-width="0.5"/>
    <path d="M3.5,0A3.5,3.5,0,1,1,0,3.5,3.5,3.5,0,0,1,3.5,0Z" transform="translate(429 327)" fill="#fff"/>
  </g>
`
}

function getMarkerCluster (numberOfPins = 3, colors = ['#92a5c2', '#92a5c2', '#92a5c2']) {
  const selectedColors = []
  // select colors
  colors.forEach(color => {
    if (selectedColors.includes(color)) return
    selectedColors.push(color)
  })
  const getSelectedColor = (index) => {
    if (selectedColors[index]) return selectedColors[index]
    return selectedColors[0]
  }
  // build markers
  let groups = ''
  if (numberOfPins > 1) {
    groups += buildMarker('Group_2', 'Group 2', getSelectedColor(1), 'translate(4 -3)')
  }
  if (numberOfPins > 2) {
    groups += buildMarker('Group_3', 'Group 3', getSelectedColor(2), 'translate(-4 -3)')
  }
  groups += buildMarker('Group_3', 'Group 3', getSelectedColor(0))
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="21.527" height="20.605" viewBox="0 0 21.527 20.605">
      <g id="Group_4" data-name="Group 4" transform="translate(-421.737 -321.75)">
        ${groups}
      </g>
    </svg>
  `
}

export function getCustomClusterMarkerIcon (cluster) {
  const markers = cluster.getAllChildMarkers()
  const colors = markers.map(marker => marker.options.hex_color)
  return window.L.icon({
    iconUrl: `data:image/svg+xml,${encodeURIComponent(getMarkerCluster(colors.length, colors))}`,
    iconSize: [29 * 1.5, 21 * 1.5],
    iconAnchor: [29, 21 * 1.5]
  })
}

export function getClusterMarkerIcon () {
  return window.L.icon({
    iconUrl: `data:image/svg+xml,${encodeURIComponent(getMarkerCluster())}`,
    iconSize: [29 * 1.5, 21 * 1.5],
    iconAnchor: [29, 21 * 1.5]
  })
}

export function metricMinAndMax (list, metric) {
  const defaultMinMax = {
    min: 0,
    max: 100
  }

  if (!list.length) return defaultMinMax

  const variableMinMaxMetrics = ['speed', 'responsiveness']

  if (variableMinMaxMetrics.includes(metric.category)) {
    return {
      min: Math.min(...list.map(a => a.lci)) * 0.9,
      max: Math.max(...list.map(a => a.uci)) * 1.1
    }
  }

  if (list[0].units && list[0].units.short === '0 - 10') {
    return { min: 0, max: 10 }
  }

  if (metric.subtype === 'voicertt' || metric.subtype === 'gamesrtt') {
    return {
      min: 0,
      max: Math.max(...list.map(a => a.uci)) * 1.1
    }
  }
  return defaultMinMax
}
// 180th meridian fixes
function getRefinedLongitude (longitude, crossesTheMeridian) {
  if (longitude <= 0 || !crossesTheMeridian) {
    return longitude
  }
  return longitude - 360
}
function getRefinedBbox (bbox, crossesTheMeridian) {
  const west = getRefinedLongitude(bbox[0], crossesTheMeridian)
  const east = getRefinedLongitude(bbox[2], crossesTheMeridian)
  return [west, bbox[1], east, bbox[3]]
}
function getRefinedCenter (longitudeLatitude, crossesTheMeridian) {
  const [longitude, latitude] = longitudeLatitude
  const newLongitude = getRefinedLongitude(longitude, crossesTheMeridian)
  return [newLongitude, latitude]
}
function updateCoordinatesThatCross180thMeridian (coordinates, crossesTheMeridian) {
  if (!Array.isArray(coordinates)) {
    return
  }
  for (var i = 0; i < coordinates.length; i++) {
    if (Array.isArray(coordinates[i][0])) {
      updateCoordinatesThatCross180thMeridian(coordinates[i], crossesTheMeridian)
    } else {
      coordinates[i][0] = getRefinedLongitude(coordinates[i][0], crossesTheMeridian)
    }
  }
};
/**
 *
 * Leaflet supports using longitudes above and below 180 and -180, thus if the country
 * crosses the antimeridian, we take 360 from every coordinate longitude that isn't negative.
 *
 * Example: -174.76398 to 178.43054, becomes -174.76398 to -181.56946
 *
 * @param {array} geoJson The geoJson you want to refine
 * @param {array} mainGeoJson If the geoJson is inside a larger geoJson bbox, pass the larger geoJson here
 */
export function refineGeoJsonToHandle180thMeridian (geoJson, mainGeoJson) {
  if (!geoJson || !mainGeoJson || !mainGeoJson.bbox) {
    return geoJson
  }
  const geoJsonCopy = JSON.parse(JSON.stringify(geoJson))
  const crossesTheMeridian = mainGeoJson ? mainGeoJson.bbox[2] >= 180 : geoJson.bbox[2] >= 180
  geoJsonCopy.features.forEach(feature => {
    feature.center = getRefinedCenter(feature.center, crossesTheMeridian)
    feature.bbox = getRefinedBbox(feature.bbox, crossesTheMeridian)
    updateCoordinatesThatCross180thMeridian(feature.geometry.coordinates, crossesTheMeridian)
  })
  return geoJsonCopy
}
