<template>
  <LMap
    v-if="geoJson"
    ref="map"
    :options="{
      zoomSnap: 0,
      zoomControl: false,
      preferCanvas: true
    }"
    :max-zoom="19"
    class="map-com ci-map"
    @ready="onLeafletReady"
    @update:bounds="updateBounds"
    @update:zoom="logZoom"
  >
    <LControlZoom
      v-if="leafletReady"
      position="bottomright"
    />
    <!-- uncomment rectangle for bounds debugg -->
    <!-- <l-rectangle
      :bounds="chartMeta.maxBounds"
      :l-style="rectangle.style"
    /> -->
    <div
      v-if="resetLocationEnabled"
      :style="{ right: controlPosition }"
      class="map-expand map-expand--reset-zoom leaflet-bar leaflet-right"
      @click="resetView"
    >
      <a>
        <FontAwesomeIcon
          :icon="icons.resetView"
          size="lg"
        />
      </a>
    </div>
    <div
      v-if="expandEnable"
      :style="{ right: controlPosition }"
      class="map-expand leaflet-bar leaflet-right"
      @click="controlMap"
    >
      <!-- a nesting required to inherit leaflet style -->
      <!-- TODO make a PR to vue leaflet with this  -->
      <a v-if="expanded">
        <FontAwesomeIcon
          :icon="icons.compress"
          size="lg"
        />
      </a>
      <a v-else>
        <FontAwesomeIcon
          :icon="icons.expand"
          size="lg"
        />
      </a>
    </div>

    <slot />

    <template v-if="leafletReady">
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
    </template>

    <colour-scale
      v-if="colourScaleVisible"
      :inverted="colourScaleInverted"
      :labels="colourScaleLabels"
    />
    <svg class="patterns" />
  </LMap>
</template>

<script>
import {
  faExpand,
  faCompress,
  faLocation
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  // // uncomment for bounds debugg
  // LRectangle,
  LMap,
  LTileLayer,
  LControlZoom
} from '@vue-leaflet/vue-leaflet'
import * as L from 'leaflet'
import ColourScale from './ColourScale'
import { geoJsonMeta } from './helpers'

export default {
  name: 'BaseMap',
  components: {
    // LRectangle,
    ColourScale,
    LMap,
    LTileLayer,
    LControlZoom,
    FontAwesomeIcon
  },
  props: {
    geoJson: {
      type: Object,
      default: () => {}
    },
    geoJsonOverlay: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Object,
      default: null
    },
    expandEnable: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    rightUnsafeArea: {
      type: String,
      default: '0px'
    },
    colourScaleVisible: {
      type: Boolean,
      default: false
    },
    colourScaleLabels: {
      type: Array,
      default: () => []
    },
    colourScaleInverted: {
      type: Boolean,
      default: false
    },
    resetLocationEnabled: {
      type: Boolean,
      default: false
    },
    maxBounds: {
      type: Object,
      default: null
    },
    boundsModifiers: {
      type: Object,
      default: () => ({
        width: 0.01,
        height: 0.01
      })
    }
  },
  mapObject: null,
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
      bounds: null,
      mapObject: null,
      icons: {
        expand: faExpand,
        compress: faCompress,
        resetView: faLocation
      },
      // rectangle: {
      //   // [[N,E],[S,W]]
      //   // [[1.617,93.605],[1.049,114.088]]
      //   // bounds: [[2.303, 105.604], [0.363, 102.090]],
      //   bounds: [-78.796186, 49.414384, -123.288161, 42.235693],
      //   style: { color: 'red', weight: 5 }
      // },

      leafletReady: false,
      leafletObject: null,

      visible: false,
    }
  },
  computed: {
    baseMapBounds () {
      return this.selected ? this.selectedMeta.maxBounds : this.chartMeta.maxBounds
    },
    chartMeta () {
      try {
        return geoJsonMeta(this.geoJson, this.boundsModifiers.height, this.boundsModifiers.width)
      } catch (e) {
        return geoJsonMeta(this.geoJsonOverlay, this.boundsModifiers.height, this.boundsModifiers.width)
      }
    },
    selectedMeta () {
      return this.selected && geoJsonMeta(this.selected, this.boundsModifiers.height, this.boundsModifiers.width)
    },
    controlPosition () {
      return this.expanded ? '0' : this.rightUnsafeArea
    }
  },
  watch: {
    expanded () {
      this.repositionZoom()
    },
    rightUnsafeArea () {
      this.repositionZoom()
    },
    selected (newSelected, oldSelected) {
      if (this.$options.mapObject && (!oldSelected || !newSelected || newSelected.id !== oldSelected.id)) {
        this.fitMapBounds()
      }
    }
  },
  errorCaptured () {
    // map is chocking bad and doesn't handle
    // centre reasigning
    return false
  },
  methods: {
    async onLeafletReady() {
      await this.$nextTick();

      if (this.$refs.map) {
        this.leafletObject = this.$refs.map?.leafletObject;
        this.leafletReady = true;

        this.onLoad();
      }
    },
    onLoad () {
      this.repositionZoom()

      this.$nextTick(() => {
        this.$options.mapObject = this.$refs.map.leafletObject

        if (this.$options.mapObject) {
          this.fitMapBounds()
          this.$emit('mapReady', this.$options.mapObject)
        }
      })
    },
    getBaseMapBounds () {
      return this.baseMapBounds
    },
    fitMapBounds () {
      const mapObject = this.$options.mapObject
      if (!mapObject) {
        return
      }
      const bounds = L.latLngBounds(this.baseMapBounds)

      if (this.maxBounds) {
        mapObject.fitBounds(this.maxBounds)
      } else {
        mapObject.fitBounds([bounds.getSouthWest(), bounds.getNorthEast()])
      }
    },
    logZoom (zoom) {
      // uncomment for debugg
      // console.log(zoom)
    },
    updateBounds (newBounds) {
      this.$emit('visibleBoundsChange', newBounds)

      if (!this.bounds) {
        this.bounds = newBounds
        this.$emit('boundsChange', newBounds)
      }

      // if new bounds > chartmeta.maxBpunds [[N,E],[S,W]]

      if ((
        newBounds._northEast.lat <= this.chartMeta.maxBounds[0][0] ||
        newBounds._northEast.lng <= this.chartMeta.maxBounds[0][1] ||
        newBounds._southWest.lat >= this.chartMeta.maxBounds[1][0] ||
        newBounds._southWest.lng >= this.chartMeta.maxBounds[1][1]
      ) && (
        this.bounds._southWest.lat !== newBounds._southWest.lat ||
        this.bounds._southWest.lng !== newBounds._southWest.lng ||
        this.bounds._northEast.lat !== newBounds._northEast.lat ||
        this.bounds._northEast.lng !== newBounds._northEast.lng
      )) {
        this.bounds = newBounds
        this.$emit('boundsChange', newBounds)
      }
    },
    repositionZoom () {
      if (this.expandEnable) {
        document.querySelector('.leaflet-bottom.leaflet-right').style.right = this.controlPosition
      }
    },
    controlMap () {
      if (this.expanded) {
        this.$emit('compress')
      } else {
        this.$emit('expand')
      }
    },
    resetView () {
      const { chartMeta, $refs } = this
      $refs.map.mapObject.setView(chartMeta.center, chartMeta.zoom)
      this.$emit('boundsChange', {
        _northEast: {
          lat: chartMeta.maxBounds[0][0],
          lng: chartMeta.maxBounds[0][1]
        },
        _southWest: {
          lat: this.chartMeta.maxBounds[1][0],
          lng: this.chartMeta.maxBounds[1][1]
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import 'scss/variables';
.map-com {
  height: 100%;
  width: 100%;

  .leaflet-top,
  .leaflet-bottom,
  .leaflet-pane {
    z-index: 0;
  }
}

.map-expand {
  $bottom: 100px;
  position: absolute;
  /* leaflet is in px, must use px */
  bottom: $bottom;
  /* clone of leaflet over nested leaflet-control */
  margin-right: 10px;
  cursor: pointer;
  z-index: $z-index-on-map-layer;
  &--reset-zoom {
    /* leaflet is in px, must use px */
    bottom: $bottom + 42px;
  }
}
</style>
