import colors from '@/utils/colorPalette'

export const GREEN2RED_LTE_3G_AVAILABILITY = {
  unit: '%',
  ranges: [
    { color: colors.colorG2RScale1, min: 98 },
    { color: colors.colorG2RScale2, min: 96, max: 98 },
    { color: colors.colorG2RScale3, min: 94, max: 96 },
    { color: colors.colorG2RScale5, min: 92, max: 94 },
    { color: colors.colorG2RScale6, min: 90, max: 92 },
    { color: colors.colorG2RScale9, max: 90 }
  ]
}

export const GREEN2RED_LTE_RSRQ = {
  unit: 'dB',
  ranges: [
    { color: colors.colorG2RScale1, min: -9 },
    { color: colors.colorG2RScale3, min: -12, max: -9 },
    { color: colors.colorG2RScale5, min: -14, max: -12 },
    { color: colors.colorG2RScale6, min: -16, max: -14 },
    { color: colors.colorG2RScale9, max: -16 }
  ]
}

export const GREEN2RED_LTE_RSSNR = {
  unit: 'dB',
  ranges: [
    { color: colors.colorG2RScale1, min: 20 },
    { color: colors.colorG2RScale3, min: 10, max: 20 },
    { color: colors.colorG2RScale5, min: 0, max: 10 },
    { color: colors.colorG2RScale6, min: -15, max: 0 },
    { color: colors.colorG2RScale9, max: -15 }
  ]
}

export const GREEN2RED_LTE_UL_SPEED = {
  unit: 'Mbps',
  ranges: [
    { color: colors.colorG2RScale1, min: 30 },
    { color: colors.colorG2RScale2, min: 20, max: 30 },
    { color: colors.colorG2RScale3, min: 15, max: 20 },
    { color: colors.colorG2RScale4, min: 10, max: 15 },
    { color: colors.colorG2RScale5, min: 8, max: 10 },
    { color: colors.colorG2RScale6, min: 6, max: 8 },
    { color: colors.colorG2RScale7, min: 4, max: 6 },
    { color: colors.colorG2RScale8, min: 2, max: 4 },
    { color: colors.colorG2RScale9, min: 1, max: 2 },
    { color: colors.colorG2RScale10, max: 1 }
  ]
}

export const GREEN2RED_LTE_RSRP = {
  unit: 'dBm',
  ranges: [
    { color: colors.colorG2RScale1, min: -80 },
    { color: colors.colorG2RScale2, min: -90, max: -80 },
    { color: colors.colorG2RScale3, min: -100, max: -90 },
    { color: colors.colorG2RScale5, min: -110, max: -100 },
    { color: colors.colorG2RScale6, min: -120, max: -110 },
    { color: colors.colorG2RScale9, max: -120 }
  ]
}

export const GREEN2RED_LTE_DL_SPEED = {
  unit: 'Mbps',
  ranges: [
    { color: colors.colorG2RScale1, min: 100 },
    { color: colors.colorG2RScale2, min: 75, max: 100 },
    { color: colors.colorG2RScale3, min: 50, max: 75 },
    { color: colors.colorG2RScale4, min: 30, max: 50 },
    { color: colors.colorG2RScale5, min: 20, max: 30 },
    { color: colors.colorG2RScale6, min: 10, max: 20 },
    { color: colors.colorG2RScale7, min: 4, max: 10 },
    { color: colors.colorG2RScale8, min: 2, max: 4 },
    { color: colors.colorG2RScale9, min: 1, max: 2 },
    { color: colors.colorG2RScale10, max: 1 }
  ]
}

export const GREEN2RED_THRESHOLD_METRICS = {
  unit: '%',
  ranges: [
    { color: colors.colorG2RScale1, max: 1 },
    { color: colors.colorG2RScale3, min: 1, max: 5 },
    { color: colors.colorG2RScale5, min: 5, max: 10 },
    { color: colors.colorG2RScale6, min: 10, max: 20 },
    { color: colors.colorG2RScale9, min: 20 }
  ]
}

export const GREEN2RED_LTE_CELLS_SEEN = {
  unit: '',
  ranges: [
    { color: colors.colorG2RScale1, min: 50 },
    { color: colors.colorG2RScale2, min: 30, max: 50 },
    { color: colors.colorG2RScale3, min: 15, max: 30 },
    { color: colors.colorG2RScale5, min: 10, max: 15 },
    { color: colors.colorG2RScale6, min: 5, max: 10 },
    { color: colors.colorG2RScale9, max: 5 }
  ]
}

const delatRanges1to4 = [
  { negative: false, color: colors.colorG2GScale1, min: 4 },
  { negative: false, color: colors.colorG2GScale2, min: 3, max: 4 },
  { negative: false, color: colors.colorG2GScale3, min: 2, max: 3 },
  { negative: false, color: colors.colorG2GScale5, min: 1, max: 2 },
  { negative: false, color: colors.colorG2GScale6, max: 1 },
  { negative: false, color: colors.colorWhite, min: 0 },
  { negative: true, color: colors.colorG2GScale7, max: 1 },
  { negative: true, color: colors.colorG2GScale9, min: 1, max: 2 },
  { negative: true, color: colors.colorG2GScale10, min: 2, max: 3 },
  { negative: true, color: colors.colorG2GScale11, min: 3, max: 4 },
  { negative: true, color: colors.colorG2GScale12, min: 4 }
]

const delatRanges1to10 = [
  { negative: false, color: colors.colorG2GScale1, min: 10 },
  { negative: false, color: colors.colorG2GScale2, min: 5, max: 10 },
  { negative: false, color: colors.colorG2GScale3, min: 3, max: 5 },
  { negative: false, color: colors.colorG2GScale5, min: 1, max: 3 },
  { negative: false, color: colors.colorG2GScale6, max: 1 },
  { negative: false, color: colors.colorWhite, min: 0 },
  { negative: true, color: colors.colorG2GScale7, max: 1 },
  { negative: true, color: colors.colorG2GScale9, min: 1, max: 3 },
  { negative: true, color: colors.colorG2GScale10, min: 3, max: 5 },
  { negative: true, color: colors.colorG2GScale11, min: 5, max: 10 },
  { negative: true, color: colors.colorG2GScale12, min: 10 }
]

const delatRanges1to20 = [
  { negative: false, color: colors.colorG2GScale1, min: 20 },
  { negative: false, color: colors.colorG2GScale2, min: 10, max: 20 },
  { negative: false, color: colors.colorG2GScale3, min: 4, max: 10 },
  { negative: false, color: colors.colorG2GScale4, min: 2, max: 4 },
  { negative: false, color: colors.colorG2GScale5, min: 1, max: 2 },
  { negative: false, color: colors.colorG2GScale6, max: 1 },
  { negative: false, color: colors.colorWhite, min: 0 },
  { negative: true, color: colors.colorG2GScale7, max: 1 },
  { negative: true, color: colors.colorG2GScale8, min: 1, max: 2 },
  { negative: true, color: colors.colorG2GScale9, min: 2, max: 4 },
  { negative: true, color: colors.colorG2GScale10, min: 4, max: 10 },
  { negative: true, color: colors.colorG2GScale11, min: 10, max: 20 },
  { negative: true, color: colors.colorG2GScale12, min: 20 }
]

const delatRanges1to50 = [
  { negative: false, color: colors.colorG2GScale1, min: 50 },
  { negative: false, color: colors.colorG2GScale2, min: 20, max: 50 },
  { negative: false, color: colors.colorG2GScale3, min: 10, max: 20 },
  { negative: false, color: colors.colorG2GScale5, min: 5, max: 10 },
  { negative: false, color: colors.colorG2GScale6, max: 5 },
  { negative: false, color: colors.colorWhite, min: 0 },
  { negative: true, color: colors.colorG2GScale7, max: 5 },
  { negative: true, color: colors.colorG2GScale9, min: 5, max: 10 },
  { negative: true, color: colors.colorG2GScale10, min: 5, max: 20 },
  { negative: true, color: colors.colorG2GScale11, min: 20, max: 50 },
  { negative: true, color: colors.colorG2GScale12, min: 50 }
]

export const GREEN2GRAY_LTE_3G_AVAILABILITY = {
  unit: '%',
  ranges: delatRanges1to4
}

export const GREEN2GRAY_RSRP = {
  unit: 'dB',
  ranges: delatRanges1to4
}

export const GREEN2GRAY_RSSNR = {
  unit: 'dB',
  ranges: delatRanges1to10
}

export const GREEN2GRAY_LTE_CELLS_SEEN = {
  unit: '',
  ranges: delatRanges1to50
}

export const GREEN2GRAY_UP_SPEED = {
  unit: 'Mbps',
  ranges: delatRanges1to4
}

export const GREEN2GRAY_DL_SPEED = {
  unit: 'Mbps',
  ranges: delatRanges1to20
}

export const GREEN2GRAY_RSRQ = {
  unit: 'dB',
  ranges: delatRanges1to4
}

export const GREEN2GRAY_THRESHOLD_METRICS = {
  unit: '%',
  ranges: delatRanges1to10
}

export const IMPORTANCE = {
  unit: '%',
  ranges: [
    { color: colors.colorG2BScale5, min: 1 },
    { color: colors.colorG2BScale4, min: 0.8, max: 1 },
    { color: colors.colorG2BScale3, min: 0.6, max: 0.8 },
    { color: colors.colorG2BScale2, min: 0.4, max: 0.6 },
    { color: colors.colorG2BScale1, min: 0.2, max: 0.4 },
    { color: colors.colorG2BScale0, max: 0.2 }
  ]
}

export const cdnColorMap = {
  'akamai': colors.colorCdnBlue,
  'cloudfront': colors.colorCdnGreen,
  'google storage': colors.colorCdnPurple,
  'google.com': colors.colorCdnRed,

  // V1 CDNs
  'OPENSIGNAL_360p': colors.colorCdnBlue,
  'OPENSIGNAL_720p': colors.colorCdnGreen,
  'YOUTUBE_360p': colors.colorCdnPurple,
  'YOUTUBE_720p': colors.colorCdnRed,

  // V2 CDNs
  'AKAMAI_360p': colors.colorGreen1,
  'AKAMAI_720p': colors.colorGreen2,
  'AKAMAI_1080p': colors.colorGreen3,
  'AKAMAI_4K': colors.colorGreen4,

  'CLOUDFRONT_360p': colors.colorRed1,
  'CLOUDFRONT_720p': colors.colorRed2,
  'CLOUDFRONT_1080p': colors.colorRed3,
  'CLOUDFRONT_4K': colors.colorRed4,

  'GOOGLECLOUD_360p': colors.colorBlue1,
  'GOOGLECLOUD_720p': colors.colorBlue2,
  'GOOGLECLOUD_1080p': colors.colorBlue3,
  'GOOGLECLOUD_4K': colors.colorBlue4,

  'ec2': colors.colorCdnGreen,
  'gce': colors.colorCdnPurple
}

export const frColorMap = {
  'High': '#5c6bbd',
  'Mid': '#99cb68',
  'Low': '#1cc4d8'
}

export const noDataColor = 'DEE4EC'
