<template>
  <div
    class="FieldGroup"
    :class="fieldGroupCssClass"
  >
    <div
      v-if="label"
      class="FieldGroup__label"
    >
      <span>{{ label }}<span v-if="disabled"> (no other options available)</span></span>
      <CustomTooltip
        v-if="tooltip"
        :message="tooltip"
        placement="bottom"
      >
        <QuestionMark />
      </CustomTooltip>
    </div>
    <div class="FieldGroup__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { CustomTooltip, QuestionMark } from '@/components/tooltip'

export default {
  name: 'FieldGroup',
  components: {
    CustomTooltip,
    QuestionMark
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    }
  },
  computed: {
    fieldGroupCssClass () {
      if (this.theme) {
        return 'FieldGroup--' + this.theme
      }

      return ''
    }
  }
}
</script>

<style scoped lang="scss">
@import 'scss/variables';

.FieldGroup {
  $label-height: pxToRem(16px);
  position: relative;
  padding-bottom: .5em;

  &__label {
    font-size: $font-size-12;
    font-weight: $font-weight-roboto-medium;
    color: $color-blue-separator;
    padding-bottom: .2em;
    height: $label-height;
    line-height: $label-height;
    vertical-align: bottom;
  }

  &--ci,
  &--mg {
    .FieldGroup__label {
     color: rgba($color-white, .7);
    }
  }
}
</style>
