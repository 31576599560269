<template>
  <div class="OverviewHeader">
    <div
      class="OverviewHeader__wrapper"
      :class="{ 'OverviewHeader__wrapper--badge': display5GBadge }"
    >
      <img
        v-show="display5GBadge"
        :src="icons.FiveGIcon"
      >
      <div class="OverviewHeader__title-wrapper">
        <div class="OverviewHeader__title">
          User Experience
        </div>
        <div class="OverviewHeader__subtitle">
          Metrics
        </div>
      </div>
    </div>
    <div class="OverviewHeader__info">
      <span class="OverviewHeader__location">
        {{ granularity }} Level Overview: {{ location.name }}
      </span>
      | 90 day aggregation ending on the day before <span class="OverviewHeader__date"> {{ dates.current }}</span>
      <CustomTooltip
        :message="message"
        placement="top"
      >
        <QuestionMark theme="ci" />
      </CustomTooltip>
      | compared to
      <span v-if="compareToKey !== 'lpr'">previous 90 day aggregation</span>
      <span v-else>last public report</span>
      ending on the day before <span class="OverviewHeader__date">{{ dates.previous }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FiveGIcon from '@/assets/5g.svg'
import CustomTooltip from '@/components/tooltip/CustomTooltip'
import QuestionMark from '@/components/tooltip/QuestionMark'
import { COMPARE_TO, METRIC_TYPES } from '@/constants/constants'
import { TOOLTIP_MESSAGES } from '@/constants/tooltips'
import router from '@/router'
import { getLongDate } from '@/utils/date'

export default {
  name: 'OverviewHeader',
  components: {
    QuestionMark,
    CustomTooltip
  },
  data () {
    return {
      icons: {
        FiveGIcon
      },
      compareToOptions: COMPARE_TO,
      message: TOOLTIP_MESSAGES.competitiveEndDateInfo
    }
  },
  computed: {
    ...mapGetters([
      'overviewStats'
    ]),
    ...mapGetters({
      location: 'location/currentLocation',
      geocoding: 'location/currentGeocoding',
      label: 'location/getSingleLabel',
      defaultMetricType: 'competitive/defaultMetricType'
    }),
    display5GBadge () {
      return this.defaultMetricType === METRIC_TYPES.FiveG
    },
    granularity () {
      return this.label(this.geocoding.granularity)
    },
    compareToKey () {
      return router.currentRoute.value.params.compareTo
    },
    compareToLabel () {
      return this.compareToOptions.find(option => option.value === this.compareToKey).labelHeader
    },
    dates () {
      const reference = this.overviewStats.find(a => a.previous[this.compareToKey])
      if (!reference) return {}

      return {
        current: getLongDate(reference.date),
        previous: getLongDate(reference.previous[this.compareToKey].date)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';

.OverviewHeader {
  padding: 0 20px;

  &__wrapper {
    font-size: $font-size-16;

    &--badge {
      display: flex;
      align-items: center;
      margin-left: -30px;

      .OverviewHeader__title-wrapper {
        margin-left: 5px;
      }
    }
  }

  &__title, &__subtitle {
    font-size: $font-size-40;
    line-height: $font-size-40;
    font-weight: $font-weight-roboto-medium;
    font-style: italic;
  }

  &__title, &__info {
    color: $color-text-dark-blue;
  }

  &__subtitle {
    color: $color-text-gold;
    padding-bottom: 8px;
  }

  &__info {
    font-size: $font-size-16;
  }

  &__location {
    font-size: $font-size-20;
    line-height: $font-size-16;
    font-weight: $font-weight-roboto-regular;
    color: $color-text-gold;
  }

  &__date {
    font-weight: $font-weight-roboto-medium-bold;
    font-style: italic;
  }
}
</style>
